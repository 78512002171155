'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Link } from 'react-router';

import Footer from './../components/Widgets/footer/Footer.react';

import './PrivacyPolicy.scss';
import './Terms.scss';

export default class Terms extends Component {
    static propTypes = {};

    renderCopy() {
        return (
            <div className="eula-content">
<p>IMPORTANT – READ CAREFULLY BEFORE USING APPLICATION</p>

<p>YOU SHOULD CAREFULLY READ THE FOLLOWING TERMS AND CONDITIONS BEFORE USING THIS EATLOVE WEB APPLICATION (“App”). BY REGISTERING TO USE EATLOVE BY CLICKING “SIGN UP”, YOU ARE CONSENTING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, DO NOT ACCESS OR USE THE APP.</p>

<p>Any information that EatLove collects through Your use of this App is subject to the EatLove <Link to="/privacy">Privacy Policy</Link>, which is part of these Terms.</p>

<p>By continuing to use the App, You agree as follows:</p>

<ul>
    <li>You are at least 18 years old or have been legally emancipated;</li>
    <li>You understand and intend that this Agreement is a legally binding agreement and the equivalent of a signed, written contract;</li>
    <li>You will use the App in a manner consistent with applicable laws and regulations, these Terms of Use, as they may be amended by EatLove from time to time; and</li>
    <li>You understand, accept, and have received this Agreement and its Terms, and acknowledge and demonstrate that You can access this Agreement at will via the EatLove web site.</li>
</ul>
<p>If You do not agree with and accept the Terms in this Agreement, please discontinue all further use of the App.</p>

<p>ARBITRATION NOTICE: EXCEPT IF YOU OPT-OUT AND EXCEPT FOR CERTAIN TYPES OF DISPUTES DESCRIBED IN THE DISPUTE RESOLUTION SECTION BELOW, YOU AGREE THAT DISPUTES BETWEEN YOU AND EATLOVE WILL BE RESOLVED BY BINDING, INDIVIDUAL ARBITRATION AND YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE ARBITRATION.  YOU CAN OPT OUT OF THE AGREEMENT TO ARBITRATE BY CONTACTING SUPPORT@EATLOVE.IS WITHIN 30 DAYS OF ACCEPTING THESE TERMS.</p>

<hr />

<h1>Terms of Use</h1>

<p>These Terms of Use (“Terms”) are a legal contract between You (“You/Your” or “User”) and GYFS, Inc. (“Us/We” or “EatLove”) and govern Your use of the EatLove Pro or EatLove Consumer Web Applications (the “Application” or “App”), as described below, and of all the text, data, information, software, graphics, photographs, and more (all of which We refer to as “Materials”) that We may make available to You through the Application.  These Terms apply to individuals who download and/or access the Application.</p>
<h2>MEDICAL ADVICE DISCLAIMER</h2>
<p>The Application is intended to provide a convenient platform whereby You can seek nutrition information and support in order to better enable You to communicate information with Your healthcare providers and be an active participant with those providers in monitoring Your care, tailoring nutrition plans, and assessing outcomes (or, if you are a Provider, to allow you to interact with patients concurrent with the provision of nutrition support and monitoring). The Application and/or any data derived from the Application are in no way intended to replace or supplant the independent clinical judgment of a qualified healthcare professional, and should not be used by qualified healthcare professionals for active patient monitoring whose purpose is to determine the need for immediate clinical action.  If at any time You are concerned about Your care, or You believe or someone else advises You that You have or suspect that You have a serious or life-threatening condition, call 9-1-1 in areas where that service is available, or go to the nearest emergency room.</p>

<p>THE APPLICATION CANNOT AND IS NOT DESIGNED, INTENDED, OR APPROPRIATE TO REPLACE THE RELATIONSHIP YOU HAVE WITH YOUR HEALTH CARE PROFESSIONALS OR TO ADDRESS SERIOUS, EMERGENT, OR LIFE THREATENING MEDICAL CONDITIONS AND SHOULD NOT BE USED IN THOSE CIRCUMSTANCES.</p>

<h3 className="underline">For users of the EatLove Consumer App only</h3>
<p>Any medical advice provided by a provider using information from the Application is based on the personal health data You provide.  If You do not provide complete and accurate personal health information, the medical advice You receive may not be correct.</p>

<p>FURTHER, THIRD PARTY USE OF OUR APPLICATION (e.g., use by a Provider or researcher) IS NOT AN ENDORSEMENT OR RECOMMENDATION OF SUCH THIRD PARTY BY EATLOVE.  THE MEDICAL ADVICE PROVIDED TO YOU BY YOUR HEALTHCARE PROVIDER OR ANY OTHER PROFESSIONAL IS NOT UNDER THE CONTROL OF EATLOVE, NOR IS IT PROVIDED TO OR USED BY EATLOVE.</p>

<p>By accessing and using the App, You accept responsibility for Yourself in Your use of the Application.  You acknowledge that Your relationship for healthcare services is with Your provider(s) and obtaining services from any such providers/clinicians is solely at Your own risk.  By using the Application, You agree to not hold EatLove liable in any way for any malpractice or substandard treatment any such provider(s)/clinician(s) may provide.</p>

<p>EatLove does not confirm the credentials of any healthcare professional using the Application.  EatLove does not validate that any such persons are in good standing with their respective licensure board(s) or that they are using the Application in accordance with laws applicable to the practice of medicine.  It is YOUR responsibility to separately confirm that a healthcare provider is in good standing with his or her respective licensing board(s) and to exercise whatever other due diligence You feel appropriate in selecting and maintaining Your choice of healthcare professionals.</p>

<p>General information available through the Application is provided for general educational purposes only.  Never disregard, avoid, or delay in obtaining medical advice from a physician or other qualified healthcare professional because of something contained in the App.  If You have or suspect that You have an urgent medical problem or condition, please contact a qualified healthcare professional immediately.  THE CONTENT IN THE APP IS NOT AND SHOULD NOT BE CONSIDERED MEDICAL ADVICE OR A SUBSTITUTE FOR INDIVIDUAL MEDICAL ADVICE, DIAGNOSIS, OR TREATMENT.  YOU SHOULD ALWAYS TALK TO YOUR HEALTHCARE PROVIDERS FOR DIAGNOSIS AND TREATMENT, INCLUDING INFORMATION REGARDING WHICH DRUGS, THERAPY, OR OTHER TREATMENT MAY BE APPROPRIATE FOR YOU.</p>
<h2>MODIFICATION</h2>
<p>EatLove reserves the right, at its sole discretion, to modify, discontinue, or terminate the Application or to modify these Terms, at any time and without prior notice. IF WE MODIFY THESE TERMS, WE WILL POST A LINK TO THE MODIFIED TERMS IN THE APPLICATION. WE WILL ALSO NOTIFY YOU VIA EMAIL. YOU CAN STORE THIS AGREEMENT AND/OR ANY AMENDED VERSION(S) DIGITALLY, PRINT IT, OR SAVE IT IN ANY OTHER WAY. ANY CHANGES TO THESE TERMS WILL BE EFFECTIVE IMMEDIATELY UPON PROVIDING NOTICE, AND SHALL APPLY TO ALL INFORMATION WE MAINTAIN, USE, AND DISCLOSE. IF YOU CONTINUE TO USE THE APPLICATION FOLLOWING SUCH NOTICE, YOU ARE AGREEING TO THOSE CHANGES. If the modified Terms are not acceptable to You, You agree to immediately stop using the Application.</p>

<p>Certain features of the Application (and Your access to certain Materials) may be subject to different terms and conditions.  By using such features or any part thereof, You agree to be bound by the additional terms of use applicable to such features.  In the event that any such additional terms conflict with these Terms, the additional terms will govern with respect to such features.</p>
<h2>REGISTERED USERS</h2>
<p>Access to and use of the Application is limited to individuals who are at least 18 years of age and residents of the United States.  You must register to create an account (“User Account”) and become a “Registered User” to use the Application.  To register, You must provide Your name, Your email address, and other information specified in the registration form (“Registration Data”).  By registering, and in consideration of the use of the Application, You represent and warrant: (A) the Registration Data that You provide about Yourself is true, accurate, current, and complete; (B) You are at least 18 years of age; and (C) You will maintain and promptly update the Registration Data to keep it at all times true, accurate, current, and complete.  You may change or correct information from Your account by logging into Your User Account directly and making the desired changes.  You agree not to register for a User Account on behalf of an individual other than Yourself unless You are authorized to bind such person to these Terms.  By registering another person, You hereby represent that You are authorized to do so.</p>

<p>Use of the Application is void where prohibited by law or otherwise.  By using the Application, You represent and warrant that You have the right, authority, and capacity to agree to and abide by these Terms and that You are not prohibited from using the Application.  You understand that Your use of the Application may involve or require the transmission of significant amounts of data.  You are solely responsible for all data charges that may be assessed by Your wireless carrier or Internet service provider or that may otherwise arise from Your use of the Application.</p>
<h2>FEES</h2>
<p>The key features of our Service require a fee-based subscription (all fee-based subscriptions are referred to as “Subscription Services”). When you sign up for one of these options, you are committing to the subscription term you have chosen. Unfortunately, we are not able to offer prorated refunds at this time. If you purchase Subscription Services, you understand that your subscription is personal to you and the members of your household, and you may not transfer or make available your account name and password to others. Any distribution by you of your account name and password may result in (1) cancellation of your subscription without refund and (2) additional charges based on unauthorized use. Subscription Services may change, without prior notice. From time to time, we may offer certain special promotions or offers associated with our products and services. Any and all promotions and offers may be discontinued at any time, without prior notice, in our sole discretion.</p>

<p>Your Subscription Services term will automatically renew at the end of your current term, and we will automatically bill the credit card we have on file for you, unless you have elected not to renew before the next billing cycle. You can always find your billing information in the “My Account” page. Annual subscribers will be sent a reminder email two weeks before the next billing cycle. You may elect not to renew your Subscription Services at any time from your account page. Our automated system allows you to cancel your renewal at any time in your account by clicking on “Cancel Subscription.” Your cancellation must be done by you, the subscriber, through our Website and cannot be cancelled via an email request. When you cancel, you will still be able to access our meal plans through the end of the billing cycle that you have already paid for. There will be no refunds for late cancellation.</p>

<p>Electing not to renew your Subscription Services is your only remedy with respect to any such dispute that you may have with us. Please remember that if we determine, in our sole discretion, that you are not complying with any terms of conditions in these Terms of Use, our Privacy Policy, or other terms and conditions herein, we reserve the right to terminate your Subscription Services. Upon any termination or cancellation of your Subscription Services, we may immediately deactivate or delete your subscription service related features and content.</p>
<h2>PAYMENT</h2>
<p>Prices for all Subscription Services are in United States Dollars and include all applicable taxes, unless expressly stated otherwise. To the extent permissible under law, you are responsible for any applicable taxes, whether or not they are listed on your receipt or statement. If you purchase Subscription Services, you agree to pay, using a valid credit card or other acceptable form of payment, the applicable fees and taxes (if any) represented to you at the time of purchase. We reserve the right, upon prior notice to you, to change the amount of any fees and to institute new fees, effective at the end of your current subscription period.</p>

<p>All authorized charges will be billed to your designated credit card account (or other payment method) on the terms described in the specific offer. By using EatLove and agreeing to these Terms of Use, you also agree to be bound by our third-party payment processor’s terms of use and privacy policy. Payments are nonrefundable and there are no refunds for partially used Subscription Services. All subscription plans are set to renew automatically at the start of each pay period. You can review this date in your account info. EatLove's third party company will charge your credit card at the then-current renewal rate using the credit card information we have on file for your account. If your current card on file fails for any reason we will automatically try to charge the card again 24 hours later. If the card fails a second time we will notify you via email, at which point you have 48 hours (“the grace period”) to update your account info using a valid credit card. If the “grace period” expires without a valid credit card on file, your subscription will be automatically cancelled which revokes access to premium service areas of EatLove. Please contact the EatLove customer support at subscription@eatlove.is or visit your account info page to cancel your automatic renewal or change your payment method. When cancelling automatic renewal of services, your Subscription Services will remain active until the end of the current pay cycle date, at which time the Subscription Services will expire.</p>

<p>You are responsible for all transactions (one-time, recurring, and refunds) processed through our App and/or our third-party payment processing service. EatLove is not liable for loss or damage from errant or invalid transactions processed with the third-party payment processing service. This includes transactions that were not processed due to a network communication error, or any other reason. If you process a transaction, it is your responsibility to verify that the transaction was successfully processed. You understand and agree to not hold EatLove liable for any adverse effects that actions (whether intentional or unintentional) on the part of our third-party payment processing service may cause. We cannot accept, and hereby exclude to the fullest extent permitted by applicable law, any liability arising out of or in connection with your use of our third-party payment processing service.</p>

<p>Following any cancellation, however, you will continue to have access to our Service through the end of your current billing period. At any time, and for any reason, we may provide a refund, discount, or other consideration to some or all of our members ("Credits"). The amount and form of such Credits, and the decision to provide them, are at our sole and absolute discretion. The provision of Credits in one instance does not entitle you to Credits in the future for similar instances, nor does it obligate us to provide Credits in the future, under any circumstance.</p>
<h3>Security</h3>
<p>The Application is designed to require users to provide a valid, working e-mail address and password to access and use the Application and Materials.  Upon registering, You will create a password.  Your e-mail address and the password are, collectively, Your “User Credentials.”  You are solely responsible for (A) maintaining the strict confidentiality of Your User Credentials, (B) not allowing another person to use Your User Credentials to access the Application, (C) any and all damages or losses that may be incurred or suffered as a result of any activities that occur under Your User Credentials.  You agree to immediately notify EatLove in writing by email of any unauthorized use of Your User Credentials or any other compromise of the security of Your User Account.  EatLove is not and shall not be liable for any harm arising from or relating to the theft of Your User Credentials and/or any resulting access to Your Personal Information, Your disclosure of Your User Credentials, or the use of Your User Credentials by another person or entity.  Any attempt to obtain unauthorized access or to exceed authorized access to the Application or Materials shall be considered a trespass and computer fraud and abuse, punishable under provincial, state, and federal laws.  EatLove hereby notifies You that any or all communications with this App can and will be monitored, captured, recorded, and transmitted to the authorities as deemed necessary by EatLove in its sole discretion and without further notice, if legally permissible.  Notwithstanding the foregoing, as a Registered User, You remain responsible for Your individual compliance with any additional applicable notification requirements related to the unauthorized use of User Credentials or Registered User Personal Information.</p>

<p>Business Associate Agreement (APPLICABLE TO PROVIDERS ONLY).  You acknowledge and agree that You are a Covered Entity and EatLove is a Business Associate under HIPAA. Both You and EatLove commit to compliance with HIPAA and the Business Associate Agreement executed by You as a condition of your use of the Application. The Business Associate Agreement is hereby incorporated into this Agreement.</p>
<h2>THIRD PARTIES AND THIRD PARTY SITES</h2>
<p>Certain areas of the App or features of the Application may take You to websites or applications that are not EatLove websites or applications (collectively, “Third-Party Sites”), and, if applicable, allow You to configure Your privacy settings in Your Third-Party Site account to permit Your activities and information from the Application to be shared with contacts in Your Third-Party Site account. In certain situations, You may be transferred to a Third-Party Site through a link, but it may appear that You are still in the EatLove App.  In any case, You acknowledge and agree that the Third-Party Sites may have different privacy policies and terms and conditions and/or user guides and business practices than EatLove, and that Your use of such Third-Party Sites is governed by the respective Third-Party Site privacy policy and terms and conditions and/or user guides.  EatLove provides links to Third-Party Sites to You as a convenience, and EatLove does not verify, make any representations, or take responsibility for such Third-Party Sites, including, without limitation, the truthfulness, accuracy, quality, or completeness of the content, application, links displayed, and/or any other activities conducted on or through such Third-Party Sites.  YOU AGREE THAT EATLOVE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS, APPLICATION, INFORMATION, RESOURCES, AND/OR CONTENT AVAILABLE ON OR THROUGH ANY THIRD-PARTY SITES AND/OR THIRD-PARTY DEALINGS OR COMMUNICATIONS, OR FOR ANY HARM RELATED THERETO, OR FOR ANY DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH YOUR USE OR RELIANCE ON THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD-PARTY.  Any reference in the Application to any product, service, publication, opinion, institution, organization of any third-party entity, individual, or other content or information does not constitute or imply EatLove’s endorsement or recommendation.</p>

<p>EATLOVE MAY USE THIRD PARTY WEBSITES, THIRD PARTY HELP DESK SYSTEMS AND OTHER THIRD PARTY SERVICES TO PROVIDE CERTAIN SUPPORT SERVICES AND ONLINE FORUMS TO YOU. SUCH THIRD PARTY WEBSITES, THIRD PARTY HELP DESK SYSTEMS, ONLINE FORUMS AND OTHER THIRD PARTY SERVICES MAY NOT MEET THE REQUIREMENTS FOR THE PROTECTION OF PROTECTED HEALTH INFORMATION (AS DEFINED IN THE BUSINESS ASSOCIATE AGREEMENT) SET FORTH IN APPLICABLE LAWS AND REGULATIONS, INCLUDING, WITHOUT LIMITATION, HIPAA AND THE HEALTH INFORMATION TECHNOLOGY FOR ECONOMIC AND CLINICAL HEALTH ACT. YOU SHOULD NOT, NOR SHOULD YOU CAUSE ANY OTHER INDIVIDUAL OR ENTITY TO, SUBMIT ANY PROTECTED HEALTH INFORMATION (A) TO EATLOVE OUTSIDE OF APPLICATION, INCLUDING BUT NOT LIMITED TO EMAIL TRANSMISSIONS, SUBMISSIONS TO ANY ONLINE FORUM OR CHAT ROOM MADE AVAILABLE BY EATLOVE TO ITS CUSTOMERS, AND SUBMISSIONS THROUGH ANY SUPPORT WEBSITE, PORTAL, OR ONLINE HELP DESK OR SIMILAR SERVICE MADE AVAILABLE BY EATLOVE; OR (B) DIRECTLY TO ANY THIRD PARTY INVOLVED IN THE PROVISION OF AN ONLINE FORUM, EMAIL, SUPPORT WEBSITE, ONLINE HELP DESK OR OTHER SERVICE DESCRIBED IN (A), ABOVE.  REPEATED VIOLATIONS OF THIS REPRESENTATION BY YOU WILL ENTITLE EATLOVE, IN OUR SOLE DISCRETION, TO RESTRICT YOUR ACCESS TO SUCH THIRD PARTY SERVICES AND/OR TAKE ANY OTHER ACTIONS REQUIRED OR PERMITTED BY THE BUSINESS ASSOCIATE AGREEMENT IN CONNECTION WITH SUCH VIOLATIONS.</p>
<h2>PRIVACY</h2>
<p>EatLove respects the information You provide to Us.  Please see our <Link to="/privacy">Privacy Policy</Link> for an explanation of how We collect and handle Your personal information.  By clicking on the “Agree” or “I Accept” button, or accessing or using the Application, or by downloading or uploading any content from or through the Application, You acknowledge and agree to the provisions of the Privacy Policy and affirm that the Privacy Policy forms a part of these Terms.</p>
<h3>Content Ownership</h3>
<p>EatLove does not claim ownership of any Personal Information or other content that You enter into the Application. Unless otherwise explicitly stated herein or in EatLove’s Privacy Policy or the Business Associate Agreement, You agree that any information, data, opinions, messages, comments, photos, videos, graphics, sounds, and other content or material that You submit, upload, post, or otherwise make available on or through the Application (each a “Submission”) provided by You in connection with this Application is provided on a non-proprietary basis. By providing Submissions to EatLove through the App, You hereby grant to EatLove a non-exclusive, perpetual, irrevocable, royalty-free, fully paid-up, worldwide license (including the right to sublicense through multiple tiers) to use, reproduce, process, adapt, publicly perform, publicly display, modify, prepare derivative works, publish, transmit, and distribute each of Your Submissions, or any portion thereof, in any form, medium or distribution method now known or hereafter existing, known or developed, and authorize others to use the Submissions.  We may modify or adapt Your Submissions in order to transmit, display, or distribute them over computer networks and in various media and/or make changes to the Submissions as necessary to conform and adapt them to any requirements or limitations of any networks, devices, applications, or media.  Notwithstanding the preceding, EatLove agrees to use any Personal Information and/or health information contained in any of Your Submissions in accordance with EatLove’s Privacy Policy.</p>

<p>You agree to pay for all royalties, fees, damages, and any other monies owing any person by reason of any Submissions posted by You to or through this Application. By submitting such content to EatLove, You represent that You are entitled to submit it to EatLove for use for the purpose of the Application, without subjecting EatLove to payment of fees or other limitations.  Further, subject to restrictions in the Business Associate Agreement and Privacy Policy, You grant EatLove the right to de-identify or anonymize any of Your content and to use or disclose such de-identified information for purposes of EatLove’s management, operations, or administration of its business and the Application.</p>
<h2>RIGHTS AND RESTRICTIONS</h2>
<p>Subject to Your compliance with these Terms, EatLove grants You a limited, personal, non-exclusive, non-transferable license to view, use, and display any Materials for the purposes for which the App was created.  You have no right to sublicense the license rights granted in this section. You will not use, copy, adapt, modify, prepare derivative works based upon, distribute, license, sell, transfer, publicly display, publicly perform, transmit, stream, broadcast, or otherwise exploit the Application or Materials, except as expressly permitted in these Terms.  No licenses or rights are granted to You by implication or otherwise under any intellectual property rights owned or controlled by EatLove or its licensors, except for the licenses and rights expressly granted in these Terms.</p>
<h2>SUBMISSIONS</h2>
<p>You have full responsibility for each Submission You make, including its legality, reliability, and appropriateness. You may not upload, post, or otherwise make available through the Application any material protected by copyright, trademark, or any other proprietary right without the express permission of the owner of such copyright, trademark, or other proprietary right owned by a third-party, and the burden of determining whether any material is protected by any such right is on You. You represent and warrant that no portion of any Submission or any other content you upload or otherwise direct to be stored within or made available by the App infringes or misappropriates any third party's intellectual property rights. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, violation of contract, privacy or publicity rights, or any other harm resulting from any Submission that You make.</p>

<p>When You provide Submissions You agree that those Submissions shall not be in violation of the “Unauthorized Activities” paragraph below and further described in the Acceptable Use Policy.  Those prohibitions do not require EatLove to monitor, police, or remove any Submissions or other information submitted by You or any other user.</p>
<h2>UNAUTHORIZED ACTIVITIES</h2>
<p>EatLove will have the right to investigate and prosecute violations of its Acceptable Use Policy, which is hereby incorporated into these Terms of Use, to the fullest extent of the law.  EatLove may involve and cooperate with law enforcement authorities in prosecuting users who violate these Terms.  You acknowledge that EatLove has no obligation to monitor Your access to or use of the Application or Materials or to review or edit any Materials or Submissions, but EatLove has the right to do so for the purpose of operating the Application, to ensure Your compliance with these Terms and the Privacy Policy, and/or to comply with applicable law or the order or requirement of a court, administrative agency, or other governmental body.  EatLove reserves the right, at any time and without prior notice, to remove or disable access to any Materials or Submissions that EatLove, in its sole discretion, considers to be in violation of these Terms, the Privacy Policy, or otherwise harmful to the Application.</p>
<h2>COPYRIGHT POLICY</h2>
<p>EatLove respects copyright law and expects its users to do the same.  It is EatLove’s policy to terminate, in appropriate circumstances, Registered Users or other account holders who are believed to have infringed the rights of copyright holders.  Note: Per the U.S. Copyright Office, copyright law does not protect recipes that are mere listings of ingredients, nor does it protect other mere listings of ingredients such as those found in formulas, compounds, or prescriptions. Copyright protection may, however, extend to substantial literary expression—a description, explanation, or illustration, for example—that accompanies a recipe or formula or to a combination of recipes, as in a cookbook.</p>

<h3 className="underline">Digital Millennium Copyright Act (DMCA) Notifications for intellectual property infringement.</h3>
<p>If you believe that your work is the subject of copyright infringement and/or trademark infringement and appears in our Application, please provide EatLove’s designated agent the following information:</p>

<ul>
    <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
    <li>Identification of the copyrighted and/or trademarked work claimed to have been infringed, or, if multiple works at a single online site are covered by a single notification, a representative list of such works at that site.</li>
    <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled via the App, and information reasonably sufficient to permit EatLove to locate such material.</li>
    <li>Information reasonably sufficient to permit EatLove to contact you as the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which you may be contacted.</li>
    <li>A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright and/or trademark owner, its agent, or the law.</li>
    <li>A statement that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>
</ul>

<p>EatLove’s agent for notice of claims of copyright or trademark infringement on this App can be reached at:</p>

<address>
GYFS Inc.<br />
530 Divisadero St., #214<br />
San Francisco, CA 94117<br />
Attn: EatLove DMCA Agent<br />
Email: support@eatlove.is<br />
</address>

<p>Please also note that for copyright infringements under Section 512(f) of the Copyright Act, any person who knowingly materially misrepresents that material or activity is infringing may be subject to criminal prosecution for perjury and civil penalties, including monetary damages, court costs and attorneys' fees.</p>

<h3 className="underline">Submitting a DMCA Counter-Notification</h3>
<p>We will notify you that we have removed or disabled access to copyright-protected material that you provided, if such removal is pursuant to a valid DMCA take-down notice that we have received.  If you receive such notice from us, you may provide us with a counter-notification in writing to EatLove designated agent that includes all of the following information:</p>
<ul>
    <li>Your physical or electronic signature;</li>
    <li>Identification of the material that has been removed or to which access has been disabled, and the location at which the material appeared before it was removed or access to it was disabled;</li>
    <li>A statement from you under the penalty of perjury, that you have a good faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and</li>
    <li>Your name, physical address and telephone number, and a statement that you consent to the jurisdiction of a court for the judicial district in which your physical address is located, or if your physical address is outside of the United States, for any judicial district in which EatLove may be located, and that you will accept service of process from the person who provided notification of allegedly infringing material or an agent of such person.</li>
</ul>
<h3 className="underline">Termination of Repeat Infringers</h3>
<p>EatLove reserves the right, in its sole discretion, to terminate the account or access of any user of our Application who is the subject of repeated DMCA or other infringement notifications.</p>

<p>Please note that this procedure is exclusively for notifying EatLove and its affiliates that your copyrighted material has been infringed. The preceding requirements are intended to comply with EatLove’s rights and obligations under the DMCA, including 17 U.S.C. §512(c), but do not constitute legal advice. It may be advisable to contact an attorney regarding your rights and obligations under the DMCA and other applicable laws.</p>

<p>We may give notice of a claim of copyright infringement to our users by means of a general notice on our Website, electronic mail to a User’s email address in our records, or by written communication sent by first-class mail to a User’s address in our records, as determined in our sole discretion. In accordance with the DMCA and other applicable law, EatLove has adopted a policy of terminating, in appropriate circumstances and at EatLove’s sole discretion, members who are deemed to be repeat infringers. EatLove may also at its sole discretion limit access to the Website and Service and/or terminate the accounts of any Users who infringe any intellectual property rights of others, whether or not there is any repeat infringement.</p>
<h2>TERMINATION AND ACCOUNT CANCELLATION</h2>
<p>If You breach any of these Terms, EatLove will have the right to suspend or disable Your User Account or terminate these Terms, at its sole discretion and without prior notice to You.  EatLove reserves the right to revoke Your access to and use of the Application and Materials at any time, with or without cause.</p>
<h2>WARRANTY DISCLAIMERS</h2>
<p>EXCEPT AS EXPRESSLY PROVIDED HEREIN, EATLOVE MAKES NO WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. EATLOVE HEREBY SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING ANY WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.</p>

<p>THE APPLICATION AND MATERIALS ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED.  WITHOUT LIMITING THE FOREGOING, EATLOVE EXPLICITLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT, NON-INFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.  EATLOVE MAKES NO WARRANTY THAT THE APPLICATION OR MATERIALS WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS.  EATLOVE MAKES NO WARRANTY REGARDING THE QUALITY OF ANY PRODUCTS, APPLICATION, OR MATERIALS ACCESSED OR PURCHASED THROUGH THE APPLICATION OR THE ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS, OR RELIABILITY OF ANY CONTENT OBTAINED THROUGH THE APPLICATION.</p>

<p>YOU UNDERSTAND AND AGREE THAT YOUR USE, ACCESS, DOWNLOAD, OR OTHERWISE OBTAINING INFORMATION, MATERIALS, OR DATA THROUGH THE APP FROM A SOURCE OTHER THAN EATLOVE IS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR AND YOUR USER’S PROPERTY OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL OR DATA.</p>

<p>EATLOVE IS IN NO WAY ACTING AS A MEDICAL PROVIDER WITH RESPECT TO ANY PATIENT OR PROVIDER. YOU ACKNOWLEDGE AND AGREE THAT THE TREATMENTS, PROCEDURES, INFORMATION, MEDICATIONS, PRODUCTS, RECIPES, AND OTHER MATTERS REFERENCED BY THE APP ARE NOT INTENDED AS A RECOMMENDATION OR ENDORSEMENT OF ANY COURSE OF TREATMENT, PROCEDURE, INFORMATION, PRODUCT, DIET, OR MEDICATION AND THAT THE ULTIMATE RESPONSIBILITY FOR DIAGNOSING AND TREATING ANY PATIENT RESTS WITH THE PROVIDER.</p>

<p>EATLOVE MAKES NO GUARANTIES, REPRESENTATIONS OR WARRANTIES OF ANY KIND REGARDING THE COMPLETENESS OR ACCURACY OF THE NUTRITION INFORMATION PROVIDED WITHIN THE APP. YOU ARE SOLELY RESPONSIBLE FOR YOUR ONGOING CARE. THE NUTRITION INFORMATION IS NOT INTENDED AS MEDICAL ADVICE. EATLOVE DISCLAIMS LIABILITY FOR ANY DAMAGES OF ANY NATURE WHATSOEVER, DIRECTLY OR INDIRECTLY, RESULTING FROM YOUR USE OF OR RELIANCE ON ANY INFORMATION PROVIDED BY IN OR THROUGH THE APP.</p>

<p>YOU ARE SOLELY RESPONSIBLE FOR ALL OF YOUR COMMUNICATIONS AND INTERACTIONS WITH THE APPLICATION AND WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE APPLICATION (E.G., PROVIDERS OR RESEARCHERS). YOU UNDERSTAND THAT EATLOVE DOES NOT TAKE RESPONSIBILITY FOR SCREENING OR INQUIRY INTO THE BACKGROUND OF ANY USERS OF THE APPLICATION, INCLUDING, WITHOUT LIMITATION, PROVIDERS AND RESEARCHERS, NOR DOES EATLOVE VERIFY OR TAKE RESPONSIBILITY FOR THE STATEMENTS OF ANY SUCH USERS OF THE APPLICATION.  EATLOVE MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE CONDUCT OF USERS OF THE APPLICATION.</p>

<p>EATLOVE CANNOT ALWAYS FORESEE OR ANTICIPATE TECHNICAL OR OTHER DIFFICULTIES THAT MAY RESULT IN FAILURE TO OBTAIN DATA OR LOSS OF DATA, PERSONALIZATION SETTINGS, OR OTHER SERVICE INTERRUPTIONS. EATLOVE CANNOT ASSUME RESPONSIBILITY FOR THE TIMELINESS, ACCURACY, DELETION, NON-DELIVERY OR FAILURE TO STORE ANY USER DATA, COMMUNICATIONS, OR PERSONALIZATION SETTINGS.</p>

<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, EATLOVE ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY:</p>
<ul>
    <li>ERRORS, MISTAKES OR INACCURACIES OF CONTENT;</li>
    <li>INJURY, ILLNESS, OR OTHER HEALTH ISSUES RESULTING FROM THE CONSUMPTION OF INGREDIENTS TO WHICH YOU OR A MEMBER OF YOUR HOUSEHOLD IS OR MAY BE ALLERGIC.</li>
    <li>PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SERVICE;</li>
    <li>UNAUTHORIZED ACCESS TO OR USE OF EATLOVE’S SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN;</li>
    <li>INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SERVICE;</li>
    <li>PROGRAMS, BUGS, VIRUSES, TROJAN HORSES, DATA OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD-PARTY;</li>
    <li>ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE THROUGH OUR SERVICE; AND/OR</li>
    <li>USER CONTENT OR THE DEFAMATORY, OFFENSIVE OR ILLEGAL CONDUCT OF ANY THIRD-PARTY</li>
</ul>
<p>YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF YOUR ACCESS TO AND USE OF THE APPLICATION AND MATERIALS REMAINS WITH YOU.  NEITHER EATLOVE NOR ANY OTHER PERSON OR ENTITY INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE APPLICATION OR MATERIALS WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE PRODUCTS OR APPLICATIONS, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE APPLICATION OR MATERIALS, OR FROM ANY COMMUNICATIONS, INTERACTIONS, OR MEETINGS WITH OTHER USERS OF THE APPLICATION OR OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS A RESULT OF YOUR USE OF THE APPLICATION, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT EATLOVE HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED IN MEETING ITS ESSENTIAL PURPOSE.</p>

<p>The APP is controlled and operated from facilities in the United States. EatLove makes no representations OR WARRANTIES that our Website and Service is appropriate or available for use in other locations. Those who access or use our Service from other jurisdictions do so at their own volition and are entirely responsible for compliance with local law, including but not limited to export and import regulations.</p>
<h2>INDEMNITY</h2>
<p>You agree to defend, indemnify, and hold EatLove, their officers, directors, employees, and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, proceedings, or demands including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with Your access to or use of the Application or Materials, Your violation of these Terms, or any negligent or wrongful conduct by You or related to Your User Account by You or any other person accessing this Application or information through Your User Account.</p>

<h2>LIMITATION OF LIABILITY</h2>
<p>IF YOU ARE DISSATISFIED WITH THE APPLICATION, THE MATERIALS, OR THESE TERMS, YOUR REMEDY IS TO DISCONTINUE USING THEM.  YOU ACKNOWLEDGE THAT IF YOU USE THE APPLICATION DURING OR IN RELATION TO AN EMERGENT, SERIOUS, OR LIFE THREATENING CONDITION, SUCH USE IS AT YOUR SOLE RISK.  EATLOVE IS NOT LIABLE TO YOU OR ANY PERSON FOR ANY DECISION MADE OR ACTION TAKEN IN RELIANCE UPON THE GENERAL EDUCATIONAL INFORMATION INCLUDED IN THE APPLICATION.  EATLOVE IS NOT LIABLE TO ANY USER OR PERSON FOR ANY HARM CAUSED BY THE NEGLIGENCE OR MISCONDUCT OF ANY THIRD PARTY, WHETHER OR NOT RELYING UPON INFORMATION COLLECTED, GENERATED, OR STORED VIA THE APPLICATION.</p>

<p>BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.  IN SUCH STATES, EATLOVE’S LIABILITY IS LIMITED AND WARRANTIES ARE EXCLUDED TO THE GREATEST EXTENT PERMITTED BY LAW, BUT IN NO EVENT SHALL EATLOVE’s AND ITS PRESENT AND FORMER SUBSIDIARIES’, AFFILIATES’, DIRECTORS’, OFFICERS’, EMPLOYEES’, AND AGENTS’ AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THE APP, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) OR UNDER ANY OTHER THEORY OF LIABILITY, EXCEED THE AMOUNTS ACTUALLY PAID BY AND DUE FROM YOU PURSUANT TO THESE TERMS OF USE DURING THE ONE (1) YEAR PERIOD IMMEDIATELY PRECEDING THE DATE THE CAUSE OF ACTION AROSE.</p>
<p>You waive the right to bring or assert any claim against us relating to any dealings with any Grocery Store whose products are offered on our Website, and release us from any and all liability for or relating to any interactions or dealings with such Grocery Stores.</p>
<p>ANY CLAIM ARISING FROM THE USAGE OF THE APPLICATION OR MATERIALS MUST BE BROUGHT WITHIN ONE (1) YEAR OF THE EVENT FROM WHICH THE CLAIM AROSE.
PROPRIETARY RIGHTS NOTICES</p>
<p>All trademarks, service marks, logos, trade names, and any other proprietary designations of EatLove used herein are trademarks or registered trademarks of EatLove.  Any other trademarks, service marks, logos, trade names, and any other proprietary designations are the trademarks or registered trademarks of their respective parties.</p>
<h2>FEEDBACK</h2>
<p>We welcome and encourage You to provide feedback, comments, and suggestions for improvements to the Application or Materials (“Feedback”).  You may submit Feedback by e-mailing Us at support@eatlove.is.  You acknowledge and agree that if You submit any Feedback to Us, You hereby grant to Us a non-exclusive, worldwide, perpetual, irrevocable, fully-paid, royalty-free, sub-licensable, and transferable license under any and all intellectual property rights that You own or control to use, copy, modify, create derivative works based upon, and otherwise exploit the Feedback for any purpose.</p>
<h2>DISPUTE RESOLUTION, GOVERNING LAW, & VENUE</h2>
<p>These Terms shall be governed, construed, and performed in accordance with the laws of the State of California, without regard to conflict of law principles.</p>

<p>You agree that any dispute between You and EatLove arising out of or relating to these Terms, including the Acceptable Use Policy will be governed by the dispute resolution procedure outlined below.  We want to address Your concerns without needing a formal legal case, so We have included a tiered dispute resolution process.</p>

<p>Before filing a claim against EatLove, You agree to try to first contact us and make a good faith, sustained effort to resolve the Dispute informally by contacting support@eatlove.is.. email.  If a dispute is not resolved within 15 days after submission, we each agree to resolve any claim, dispute, or controversy (excluding claims for injunctive or other equitable relief) arising out of or in connection with or relating to these Terms of Use by binding arbitration as set forth below:</p>

<p>For any claim (excluding claims for injunctive or other equitable relief) under these Terms of Use where the total amount of the award sought is less than $10,000 USD, the parties agree to resolve the dispute through binding non-appearance-based arbitration. The party electing such arbitration shall initiate the arbitration through an established alternative dispute resolution ("ADR") provider mutually agreed upon by the parties. The ADR provider and the parties must comply with the following rules:</p>
<ol>
    <li>the arbitration shall be conducted by telephone, online and/or be solely based on written submissions, as selected by the party initiating the arbitration;</li>
    <li>the arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise mutually agreed by the parties; and</li>
    <li>any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.</li>
</ol>
<p>For any claim (excluding claims for injunctive or other equitable relief) under these Terms of Use where the total amount of the award sought is $10,000 USD or more, the parties agree to resolve the dispute through by the American Arbitration Association ("AAA") under the Commercial Arbitration Rules and Supplementary Procedures for Consumer Related Disputes then in effect for the AAA, except as provided herein. Unless you and EatLove agree otherwise, the arbitration will be conducted in San Francisco County, California. Each party will be responsible for paying any AAA filing, administrative and arbitrator fees in accordance with AAA rules, except that EatLove will pay for your reasonable filing, administrative, and arbitrator fees if your claim for damages does not exceed $75,000 and is non-frivolous (as measured by the standards set forth in Federal Rule of Civil Procedure 11(b)). The award rendered by the arbitrator shall include costs of arbitration, reasonable attorneys' fees and reasonable costs for expert and other witnesses, and any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction. Nothing in this section shall prevent either party from seeking injunctive or other equitable relief from the courts, including but not limited to matters related to data security, intellectual property or unauthorized access to or use of the Service.</p>

<p>Exceptions to Agreement to Arbitrate: EatLove may bring a lawsuit solely for injunctive relief to stop unauthorized use or abuse of the Application or Materials or infringement of intellectual property rights (for example, trademark, trade secret, copyright, or patent rights) without first engaging in the informal dispute-resolution process described above.</p>

<p>YOU MAY ONLY RESOLVE DISPUTES WITH EATLOVE ON AN INDIVIDUAL BASIS, AND MAY NOT BRING A CLAIM AS A PLAINTIFF OR A CLASS MEMBER IN A CLASS, CONSOLIDATED, OR REPRESENTATIVE ACTION.  CLASS ARBITRATIONS, CLASS ACTIONS, PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER ARBITRATIONS ARE NOT ALLOWED UNDER THESE TERMS OF USE.</p>

<p>Notwithstanding the above, You can decline or “opt out” of the alternative dispute resolution process described above by contacting support@eatlove.is within 30 days of first accepting these Terms and stating that You (first and last name) decline this dispute resolution process.</p>

<p>YOU UNDERSTAND AND AGREE THAT, BY NOT OPTING-OUT OF THE ALTERNATIVE DISPUTE RESOLUTION PROCESS DESCRIBED, YOU WAIVE ANY RIGHT TO JURY TRIAL TO WHICH YOU MAY OTHERWISE BE ENTITLED IN CONNECTION WITH ANY ACTION OR LITIGATION IN ANY WAY ARISING OUT OF OR RELATED TO THESE TERMS OF USE, INCLUDING THE ACCEPTABLE USE POLICY.</p>

<p>Furthermore, you agree that any cause of action must commence within one (1) year after the underlying issue first arose, otherwise you waive any right to bring such cause of action and it is permanently barred.</p>

<p>If You opt-out of the dispute resolution process described in this section, or if any matter is otherwise determined not to be subject to such dispute resolution process, You hereby submit to the exclusive jurisdiction of any state or federal court sitting in the State of California within twenty-five (25) miles of San Francisco, California, in any legal proceeding arising out of or relating to these Terms.  You agree that any and all claims and matters arising out of these Terms, unless subject to the dispute resolution process described above, may be heard and determined in any such court, and You hereby waive any right to object to such filing on grounds of improper venue, forum non-conveniens, or other venue-related grounds, unless such objection asserts that the claim or matter in dispute is subject to determination through the dispute resolution process described above.</p>
<h2>ENTIRE AGREEMENT</h2>
<p>These Terms, including the Acceptable Use Policy, the Privacy Policy, and any other terms incorporated herein by reference, constitute the entire and exclusive understanding and agreement between EatLove and You regarding the Application and Materials, and these Terms supersede and replace any and all prior oral or written understandings or agreements between EatLove and You regarding the Application and Materials.</p>
<h2>ASSIGNMENT</h2>
<p>You may not assign or transfer these Terms, by operation of law or otherwise, without EatLove’s prior written consent.  Any attempt by You to assign or transfer these Terms, without such consent, will be null and of no effect.  EatLove may assign or transfer these Terms, at its sole discretion, without restriction.  Subject to the foregoing, these Terms will bind and inure to the benefit of the parties, their successors, and permitted assigns.</p>
<h2>NOTICES</h2>
<p>Any notices or other communications permitted or required hereunder, including those regarding modifications to these Terms, will be in writing and given: (i) by EatLove via email (in each case to the address that You provide); (ii) written or hard copy notice or (ii) by posting conspicuously to the App, as determined by GYFS Inc. in its sole discretion.  We reserve the right to determine the form and means of providing notifications to you, provided that you may opt out of certain means of notification as described in these Terms of Use and our Privacy Policy. For notices made by e-mail, the date of receipt will be deemed the date on which such notice is transmitted.</p>
<h2>FTC Disclosure</h2>
<p>EatLove aims to provide unbiased content on our App and as part of our Service. However, we want to disclose that: (i) we may receive free products from marketers that we may sometimes review or discuss as part of our Service; (ii) we may run advertisements on our sites concerning some of those products or companies that sell them (and other products sold by such companies) for which we may sometimes receive compensation, and (iii) we may receive compensation if you click one of the links on our Website and purchase a product or service from a third party.</p>
<h2>GENERAL</h2>
<p>EatLove shall be deemed solely based in the state of California. The App shall be deemed a passive service that does not give rise to personal jurisdiction over EatLove, either specific or general, in jurisdictions other than the state of California. The failure of EatLove to enforce any right or provision of these Terms will not constitute a waiver of future enforcement of that right or provision.  The waiver of any such right or provision will be effective only if in writing and signed by a duly authorized representative of EatLove.  Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise.  If, for any reason, a court of competent jurisdiction finds any provision of these Terms invalid or unenforceable, that provision will be enforced to the maximum extent permissible and the other provisions of these Terms will remain in full force and effect.</p>
<h2>CONTACTING EATLOVE</h2>
<p>Please feel free to contact Us if You have any questions about EatLove’s Terms of Use and/or any other documents referenced herein.  You may contact Us at support@eatlove.is or at our mailing address:</p>
<address>
    GYFS Inc.<br />
    530 Divisadero St., #214<br />
    San Francisco, CA 94117<br />
    Attn: EatLove DMCA Agent<br />
</address>


<h3>DATA SECURITY OFFICER: Andrew Moenk <a href="mailto:andrew@eatlove.is">&lt;andrew@eatlove.is&gt;</a></h3>

<h2>GDPR Data Processing Addendum</h2>

<p>This EatLove Data Processing Addendum forms part of, and is subject to the provisions of, the EatLove Terms of Use. Capitalized terms that are not defined in this Data Processing Addendum have the meanings set forth in the Terms of Use.</p>

<p>In the course of providing the Services to Customer pursuant to the Terms of Use (“Underlying Agreement”), EatLove may Process Personal Data, identified in <span className="underline">Attachment A</span>, on behalf of Customer and the Parties agree to comply with the following provisions with respect to any Personal Data, each acting reasonably and in good faith. This DPA shall not replace any comparable or additional rights relating to Processing of Customer Data contained in the Underlying Agreement.</p>

<ol className="roman">
    <li>
        <em>Definitions</em>
        <ol className="letters">
            <li>The following terms used in this DPA will have the same meaning as those terms in the GDPR: Personal data, Processing, Restriction of processing, Profiling, Pseudonymisation, Filing system, Controller, Processor, Recipient, Third party, Consent, Personal data breach, Genetic data, Biometric data, Data concerning health, Main establishment, Representative, Enterprise, Group of undertakings, Binding corporate rules, Supervisory authority, Supervisory authority concerned, Cross-border processing, Relevant and reasoned objection, Information society service, International organization. A change to the Privacy Laws which modifies any defined term, or which alters the regulatory citation for the definition will be deemed incorporated into this DPA.</li>
            <li>“GDPR” means the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</li>
            <li>“Sub-processor” means any Processor engaged by EatLove to perform Services for or on behalf of EatLove that involve the use or disclosure of Personal Data.</li>
        </ol>
    </li>

    <li><em>Status of Parties.</em>The parties acknowledge and agree that with regard to the Processing of Personal Data, Customer is the Controller, EatLove is the Processor and that EatLove will engage Sub-processors pursuant to the requirements set forth below.</li>
    <li><em>Customer Obligations</em>. Customer shall, in its use of the Services, Process Personal Data in accordance with the requirements of Data Protection Laws and Regulations. For the avoidance of doubt, Customer’s instructions for the Processing of Personal Data shall comply with Data Protection Laws and Regulations. Customer shall have sole responsibility for the accuracy, quality, and legality of Personal Data and the means by which Customer acquired Personal Data.</li>
    <li><em>Personal Data Inventory</em>. The subject-matter of Processing of Personal Data, the duration of the Processing, the nature and purpose of the Processing, the types of Personal Data, the Sub-Processors, and categories of Data Subjects Processed under this DPA are further specified in <span className="underline">Attachment A</span>.</li>
    <li>
        <em>EatLove Obligations.</em> EatLove shall:
        <ol className="letters">
            <li>Treat Personal Data as Confidential Information and shall only Process Personal Data on behalf of and in accordance with Customer’s documented instructions for the following purposes: (i) Processing in accordance with the Agreement and applicable Order Form(s); (ii) Processing initiated by Users in their use of the Services; and (iii) Processing to comply with other documented reasonable instructions provided by Customer (e.g., via email) where such instructions are consistent with the terms of the Agreement.</li>
            <li>Provide notice to Customer before engaging a Sub-Processor that creates, receives, maintains, accesses, or transmits Personal Data on behalf of EatLove.</li>
            <li>Ensure that any agent, including a Sub-processor, that creates, receives, maintains, accesses, or transmits Personal Data on behalf of EatLove agrees in writing to substantially similar restrictions and conditions that apply to Customer with respect to such information;</li>
            <li>Ensure that persons authorized to process the Personal Data on behalf of EatLove are (i) informed of the confidential nature of the Personal Data, (ii) have received appropriate training on their responsibilities, (iii) granted minimum necessary access to Personal Data; and (iv) have executed written confidentiality agreements;</li>
            <li>Taking into account the state of the art, the costs of implementation and the nature, scope, context and purposes of Processing as well as the risk of varying likelihood and severity for the rights and freedoms of natural persons, implement all security measures to achieve a level of security appropriate to that risk, including, as appropriate, the measures referred to in GDPR Article 32(1);</li>
            <li>To the extent legally permitted, promptly notify Customer if EatLove receives a request from a Data Subject to exercise the Data Subject's right of access, right to rectification, restriction of Processing, erasure (“right to be forgotten”), data portability, object to the Processing, or its right not to be subject to an automated individual decision making (“Data Subject Request”). Taking into account the nature of the Processing, EatLove shall assist Customer by appropriate technical and organizational measures, insofar as this is possible, for the fulfilment of Customer’s obligation to respond to a Data Subject Request under Data Protection Laws and Regulations. In addition, to the extent Customer, in its use of the Services, does not have the ability to address a Data Subject Request, EatLove shall, upon Customer’s request, provide commercially reasonable efforts to assist Customer in responding to such Data Subject Request, to the extent EatLove is legally permitted to do so and the response to such Data Subject Request is required under Data Protection Laws and Regulations. To the extent legally permitted, Customer shall be responsible for any costs arising from EatLove’s provision of such assistance.</li>
            <li>Maintain appropriate technical and organizational measures for protection of the security (including protection against unauthorized or unlawful Processing and against accidental or unlawful destruction, loss or alteration or damage, unauthorized disclosure of, or access to, Customer Data), confidentiality and integrity of Customer Data, as set forth in the Personal Data Inventory. EatLove regularly monitors compliance with these measures. EatLove will not materially decrease the overall security of the Services during the term of the Underlying Agreement.</li>
            <li>Return or delete Customer Data, or require deletion of all other copies of Personal Data processed by any Sub-Processor, at the choice of the Customer (i) after the end of the provision of services relating to processing or (ii) upon written request by Customer.</li>
        </ol>
    </li>

    <li>
        <em>Sub-Processors.</em>
        <ol className="letters">
            <li>Customer acknowledges and agrees that EatLove may engage third-party Sub-processors in connection with the provision of the Services. A current list of EatLove’s sub-processors is included in the Personal Data Inventory (Attachment A).  </li>
            <li>Customer may object to EatLove’s use of a new Sub-processor by notifying EatLove promptly in writing within ten (10) business days after receipt of EatLove’s notice in accordance with the mechanism set out in Section V(b). </li>
            <li>In the event Customer objects to a new Sub-processor, as permitted in the preceding sentence, EatLove will use reasonable efforts to make available to Customer a change in the Services or recommend a commercially reasonable change to Customer’s configuration or use of the Services to avoid Processing of Personal Data by the objected-to new Sub- processor without unreasonably burdening the Customer. </li>
            <li>If EatLove is unable to make available such change within a reasonable period of time, which shall not exceed thirty (30) days, Customer may terminate the Underlying Agreement for convenience by providing written notice to EatLove. EatLove will refund Customer any prepaid fees following the effective date of termination with respect to such terminated Services, without imposing a penalty for such termination on Customer.</li>
            <li>EatLove shall be liable for the acts and omissions of its Sub-processors to the same extent EatLove would be liable if performing the services of each Sub-processor directly under the terms of this DPA, except as otherwise set forth in the Agreement.</li>
        </ol>
    </li>

    <li><em>Indemnification.</em> Notwithstanding any limitation on damages or liability or any indemnification obligations contained in the Underlying Agreements between the Parties, each Party agrees to indemnify and defend, and hold harmless the other Party, its affiliates, and any of its or their officers, directors, attorneys, agents or employees, from all claims, costs, settlement fees, attorneys’ fees, losses, damages, liabilities and penalties arising from or connected with the breach by the indemnifying Party or any of its officers, directors, agents, Subcontractors or employees, of its obligations under this Agreement. This provision shall survive the termination or expiration of this DPA.</li>

    <li><em>Limitation of Liability.</em> Each Party’s liability, taken together in the aggregate, arising out of or related to this DPA, whether in contract, tort or under any other theory of liability, is subject to the applicable “Limitation of Liability” section of the Underlying Agreement, and any reference in such section to the liability of a party means the aggregate liability of that party under the Underlying Agreement.</li>

    <li><em>Data Protection Impact Assessment.</em> Upon Customer’s request, EatLove shall provide Customer with reasonable cooperation and assistance needed to fulfil Customer’s obligation under the GDPR to carry out a data protection impact assessment related to Customer’s use of the Services, to the extent Customer does not otherwise have access to the relevant information, and to the extent such information is available to EatLove. EatLove shall provide reasonable assistance to Customer in the cooperation or prior consultation with the Supervisory Authority in the performance of its tasks relating to this Section, to the extent required under the GDPR.</li>

    <li>Data Transfer. EatLove shall not transfer data outside of the U.S. without advance, written consent from Customer.</li>

    <li>
        <em>Breach.</em>
        <ol className="letters">
            <li>In the case of a personal data breach, EatLove shall without undue delay and, where feasible, not later than 72 hours after having become aware of it, notify the Customer of any personal data breach to the Customer. </li>
            <li>
                The notification referred to in (XI)(a) above shall at least:
                <ol className="lower-roman">
                    <li>describe the nature of the personal data breach including where possible, the categories and approximate number of data subjects concerned and the categories and approximate number of personal data records concerned;</li>
                    <li>communicate the name and contact details of the data protection officer or other contact point where more information can be obtained;</li>
                    <li>describe the likely consequences of the personal data breach;</li>
                    <li>describe the measures taken or proposed to be taken by the controller to address the personal data breach, including, where appropriate, measures to mitigate its possible adverse effects.</li>
                </ol>
            </li>

            <li>Notwithstanding the foregoing, if such breach is unlikely to result in a risk to the rights and freedoms of natural persons, or EatLove has taken mitigating steps to ensure that the high risk to the rights and freedoms of data subjects is no longer likely to materialize, EatLove shall not be obligated to comply with (XI)(a)-(b), above.</li>
        </ol>
    </li>

    <li><em>Audit Rights.</em> EatLove shall make available to Customer all information necessary to demonstrate compliance with GDPR and shall contribute to audits, including inspections, conducted by the Customer of its auditors where mandated in writing by the Customer.  EatLove shall retain full discretion regarding which information is necessary to demonstrate such compliance.</li>

    <li><em>Severability.</em> All rights and restrictions contained herein may be exercised and shall be applicable and binding only to the extent that they do not violate any applicable laws and are intended to be limited to the extent necessary so that they will not render this DPA illegal, invalid or unenforceable. If any term of this DPA shall be held to be illegal, invalid or unenforceable by a court of competent jurisdiction, it is the intention of the Parties that the remaining terms hereof shall constitute their agreement with respect to the subject matter hereof, and all such remaining terms shall remain in full force and effect.</li>

    <li><em>Order of Precedence.</em> This DPA does not include GDPR requirements that are already required by HIPAA—this DPA shall be considered together with the Business Associate Agreement and, where GDPR is implicated, both shall form a part of the Underlying Agreement. In the event of a conflict between the Business Associate Agreement and the DPA, the requirement that is most protective of individual privacy shall prevail. Nothing in this DPA reduces EatLove’s obligations under the Underlying Agreement or the Business Associate Agreement. In the event of any conflict or inconsistency between this DPA and the standard clauses of the Underlying Agreement, the Underlying Agreement shall prevail.</li>
</ol>

<p><span className="underline">Attachment A</span></p>

<p className="underline center">PERSONAL DATA INVENTORY</p>

<p><em>This Attachment A includes certain details of the Processing of Company Personal Data as required by Article 28(3) GDPR.</em></p>

<p className="underline">Subject matter and duration of the Processing of Customer Personal Data</p>

<p>The subject matter and duration of the Processing of the Company Personal Data are set out in the Underlying Agreement and this DPA.</p>

<p className="underline">The nature and purpose of the Processing of Customer Personal Data</p>

<p>EatLove uses your personal data for the purposes of building meal plans, grocery lists and advanced prep scheduled. The nature of the personal information stored is anthropometrics for you and your family, health conditions, food avoidances and allergies</p>

<p className="underline">The types of Customer Personal Data to be Processed</p>

<p>Personal data collected and stored includes:</p>
<ul>
    <li>Name and Email address</li>
    <li>Birthdate</li>
    <li>Sex</li>
    <li>Weight, level of activity, goal weight</li>
    <li>Family members names, optional birthdate, sex, weight, and level of activity</li>
</ul>

<p className="underline">The categories of Data Subject to whom the Customer Personal Data relates</p>

<p>N/A</p>

<p className="underline">Sub-Processors</p>
<ul>
    <li>Amazon Web Services (hosts the EatLove services)</li>
    <li>Google Suites (used by EatLove employees to help us manage your data)</li>
    <li>Stripe (for paying customers)</li>
</ul>

<p className="underline">The obligations and rights of Customer</p>

<p>The obligations and rights of Customer are set out in the Underlying Agreement and this DPA.</p>

            </div>
        );
    }

    render = () => {
        return(
            <div>
                <div className="privacy-policy terms-conditions">
                    <div className="privacy-policy-contents terms-conditions-contents">
                        {this.renderCopy()}
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
