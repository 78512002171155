'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import AuthStore from '../../stores/AuthStore';
import { getConfig } from '../../utils/Env';

// Paths that are handled by the app, no need to download meta for them.
const ignorePrefix = [
    '/preferences',
    '/nutrition',
    '/groceries',
    '/log',
    '/messages',
    '/meals',
    '/new-account',
    '/my-account',
    '/recover-account',
    '/accept-invite',
    '/accept-patient-invite',
    '/menus',
    '/recipes',
    '/collections',
    '/search',
    '/preferences',
    '/favorites',
    '/publisher',
    '/admin',
    '/apps-and-devices',
    '/fitbit',
    '/oauth'
];

const _pageMetaCache = {};

export default class PageMeta extends Component {
    static propTypes = {
    };

    static contextTypes = {
        location: PropTypes.object,
        launch_page: PropTypes.object,
    };

    static childContextTypes = {
        page: PropTypes.object,
        isPageLoading: PropTypes.bool,
        isPageLoaded: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        // Kinda hardcoded for now, we might need to update this later as we support different subdomains.
        let host = getConfig('self_host').indexOf('pro.') !== -1
                 ? 'pro.eatlove.is'
                 : 'www.eatlove.is';

        const { launch_page } = context;

        this.state = {
            page: launch_page,
            isPageLoading: false,
            isPageLoaded: false,

            host,
            pathname: context.location.pathname,

            loadedPathname: null,
        };
    }

    getChildContext = () => {
        const { page, isPageLoaded, isPageLoading } = this.state;

        return {
            page, isPageLoaded, isPageLoading,
        };
    }

    componentDidMount = () => {
        this.syncPageMeta();
    }

    shouldSyncMeta = (pathname) => {
        if (ignorePrefix.find(p => pathname.indexOf(p) === 0)) {
            return false;
        }

        // Is the og:url value currently correct? Maybe we just loaded the page.
        let ogUrl = null;
        const metas = document.getElementsByTagName('meta');

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('property') === 'og:url') {
                ogUrl = metas[i].getAttribute('content');
            }
        }

        const { host } = this.state;

        if (ogUrl === 'https://' + host + pathname) {
            return false;
        }

        return true;
    }

    syncPageMeta = () => {
        const { pathname, loadedPathname } = this.state
        const { launch_page } = this.context;

        if (pathname != loadedPathname && this.shouldSyncMeta(pathname)) {
            this.loadPageMeta(pathname);
        } else if (launch_page && launch_page.url_path === pathname) {
            this.setState({page: launch_page, loadedPathname: pathname});
        } else if (pathname != loadedPathname) {
            this.setState({page: null, loadedPathname: pathname});
        }
    }

    loadPageMeta = async (path) => {
        const { host } = this.state;

        const request = {
            url: getConfig('recipe_api') + '/pages',
            query: {host, path}
        };

        this.setState({isPageLoading: true, isPageLoaded: false});

        const response = await AuthStore.fetch(request, {}, true);

        if (response && response.elements && response.elements[0]) {
            this.setState({page: response.elements[0], loadedPathname: path, isPageLoaded: true, isPageLoading: false});
        } else {
            this.setState({page: null, isPageLoaded: true, loadedPathname: path, isPageLoading: false});
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { location } = nextContext;
        const { pathname } = this.state;

        if (pathname != location.pathname) {
            this.setState({pathname: location.pathname}, this.syncPageMeta);
        }
    }

    render() {
        let { page } = this.state;
        const { children } = this.props;

        return (
            <section {...this.props}>
                {page ?
                    <Helmet title={page.title} page={[
                        {name: "description", content: page.description},
                        {name: "twitter:card", content: "summary_large_image"},
                        {name: "twitter:site", content: "@eatloveis"},
                        {name: "twitter:title", content: page.title},
                        {name: "twitter:description", content: page.description},
                        {name: "twitter:creator", content: "@eatloveis"},
                        {name: "twitter:image", content: page.image},
                        {property: "og:title", content: page.title},
                        {property: "og:type", content: page.page_type},
                        {property: "og:url", content: 'https://' + page.url_host + page.url_path},
                        {property: "og:image", content: page.image},
                        {property: "og:description", content: page.description},
                        {property: "og:site_name", content: "EatLove"},
                        {property: "fb:admins", content: "183500112,71105676,1352875613"},
                        {property: "fb:app_id", content: getConfig('fb_app_id')}
                    ]} />
                : null}
                {children}
            </section>
        );
    }
}
