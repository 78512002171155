'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import moment from 'moment';
import indexBy from 'lodash.indexby';
import uuid from 'uuid';
import AvoidancesModal from './AvoidancesModal.react';
import EnergyEstimatorModal from './EnergyEstimatorModal.react';
import Select from '../../pro/components/Widgets/Select.react'
import ConfigWarning from '../Widgets/ConfigWarning.react';
import TaxonomyStore from '../../stores/TaxonomyStore';
import TaxonomyActions from '../../actions/TaxonomyActions';
import UserStore from '../../stores/UserStore';
import Analytics from '../../utils/Analytics';
import { fetchDocumentsById } from '../../utils/Content';
import { calculateNearestPortionSize}  from '../../tables/portions';
import allDiets from '../../tables/diets';
import '../MyAccount/PreferencesForm.scss';
import './TryItOutForm.scss';
import { getConfig } from '../../utils/Env';
import AuthStore from '../../stores/AuthStore';
import NutritionPatternStore from '../../stores/NutritionPatternStore';
import NutritionPatternActions from '../../actions/NutritionPatternActions';

function conditionSortCompare(a, b) {

    if (a.name === 'General Healthy Diet') return -1;
    if (b.name === 'General Healthy Diet') return 1;

    return a.name.localeCompare(b.name);
}


export default class TryItOutForm extends Component {

    static contextTypes = {
        router: PropTypes.object,
        confirm: PropTypes.func,
        showUpgradeForm: PropTypes.func,
    };

    static propTypes = {
        conditions: PropTypes.array,
        storageKey: PropTypes.string,
        planSize: PropTypes.object,
        tryItMode: PropTypes.string,
        ctaText: PropTypes.string,
    };

    static defaultProps = {
        storageKey: 'onboarding-entries2',
        shopping_freq: 7,
        tryItMode: 'consumer',
    };

    constructor(props) {
        super(props);

        const memory = store.get(props.storageKey) || {};
        const user = UserStore.getUser();

        let {
            name = '',
            conditions = [],
            diets = [],
            avoidances = [],
            exclude_foods = [],
            adultsInFamily = 1,
            childrenInFamily = 0,
            units_mode = 'english',
            weight_kg = 81.65,
            height_cm = 167,
            target_energy_kcal = 2000,
            portion = 1,
            activity_level,
            goal_weight_kg,
            weekly_goal_kcal,
            gender = "male",
            birthdate = moment().subtract(25, 'year').format('YYYY-MM-DD'),
            age,
            shopping_freq = props.shopping_freq,
            skill_level = 'Beginner',
            due_date = null,
            fetus_count = null,
            rd_or_hp = false,
            completed = ['conditions', 'nutrition', 'taste', 'meals'],
            preferences = user.preferences || {}
        } = memory;

        if (birthdate && !age) {
            age = moment().diff(birthdate, 'years');
        }

        this.state = {
            user,
            name,
            conditions,
            diets,
            avoidances,
            exclude_foods,
            adultsInFamily,
            childrenInFamily,
            units_mode,
            target_energy_kcal,
            portion,
            activity_level,
            goal_weight_kg,
            weekly_goal_kcal,
            weight_kg,
            height_cm,
            gender,
            birthdate,
            age, // really the same, both need to be updated simultaneously
            shopping_freq,
            skill_level,
            due_date,
            fetus_count,
            rd_or_hp,
            completed,
            foods: {},
            preferences,
            nutritionPatterns: NutritionPatternStore.getNutritionPatterns(),
            categories: TaxonomyStore.getCategories(),
            taxonomy: TaxonomyStore.getTaxonomy(),
            taxonomyIndex: TaxonomyStore.getUuidIndex(),
            keyIndex: TaxonomyStore.getKeyIndex(),
            loading: NutritionPatternStore.isLoading(),
        };

    }

    componentDidMount = () => {
        const {nutritionPatterns, conditions} = this.state;

        if (!conditions.length && nutritionPatterns.length) {
            this.setDefaultConditions(nutritionPatterns);
        }

        Analytics.loadTryItOut();
        TaxonomyActions.ensureLoaded();
        NutritionPatternActions.load();
        NutritionPatternStore.addChangeListener(this.onNutritionPatternStoreChange);
        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);
        this.syncAssets();
    }

    componentWillUnmount = () => {
        NutritionPatternStore.removeChangeListener(this.onNutritionPatternStoreChange);
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
    }

    onTaxonomyStoreChange = () => {
        this.setState({
            categories: TaxonomyStore.getCategories(),
            taxonomy: TaxonomyStore.getTaxonomy(),
            taxonomyIndex: TaxonomyStore.getUuidIndex(),
            keyIndex: TaxonomyStore.getKeyIndex(),
        });
    }

    onNutritionPatternStoreChange = async () => {
        const {conditions} = this.state;
        const nutritionPatterns = NutritionPatternStore.getNutritionPatterns();

        if (!conditions.length && nutritionPatterns.length) {
            this.setDefaultConditions(nutritionPatterns);
        }

        this.setState({
            nutritionPatterns,
            loading: NutritionPatternStore.isLoading(),
        })
    }

    setDefaultConditions = async (nutritionPatterns) => {
        const {preferences, conditions, gender, birthdate, weight_kg, target_energy_kcal} = this.state;
        const { location = {} } = this.props;
        const hasQueryCondition = location.query && location.query.condition;
        let queryCondition = null;

        if (hasQueryCondition) {
            queryCondition = nutritionPatterns.find((pattern) => pattern.name === location.query.condition);
        }

        if (!conditions.length || queryCondition) {
            const defaultNutritionPattern = queryCondition ?? nutritionPatterns[0];
            const newPreferences = await this.resolveMealTypes(
                {gender, birthdate, weight_kg, target_energy_kcal},
                defaultNutritionPattern
            );
            this.setState({
                conditions: [{name: defaultNutritionPattern.name, uuid: defaultNutritionPattern.uuid}],
                preferences: {...preferences, ...newPreferences}
            });
        }
    }

    resolveMealTypes = async (patient, pattern, calculate_implicit = true) => {

        // If we can't resolve this profile, don't bother.
        if (!pattern?.links?.resolve) {
            return;
        }

        const query = {
            gender: patient.gender,
            birthdate: patient.birthdate,
            weight_kg: patient.weight_kg,
            target_energy_kcal: patient.target_energy_kcal,
            calculate_implicit: calculate_implicit,
        };
    
        let response;
        this.setState({loading: true});

        try {
            response = await AuthStore.fetch({url: getConfig('users_api') + pattern.links.resolve.href, query});
        } catch(error){
            this.setState({error});
        }

        if (!response?.preferences?.length) {
            this.setState({loading: false});
            return;
        }
    
        const preferences = response.preferences[0];

        if(preferences?.available_eating_patterns) {
            delete preferences.available_eating_patterns;
        }

        this.setState({preferences: {...this.state.preferences, ...preferences}, loading: false});

        return preferences;
    }

    syncAssets = async () => {
        const { exclude_foods, nutritionPatterns, conditions, gender, birthdate, weight_kg, target_energy_kcal, preferences } = this.state;

        const docs = await fetchDocumentsById(exclude_foods);

        const foods = indexBy(docs, 'uuid');

        this.setState({foods});
    }

    syncAndSaveToLocal = async () => {
        this.saveToLocal();
        this.syncAssets();
    }

    saveToLocal = () => {
        const {
            user,
            name,
            conditions,
            diets,
            avoidances,
            exclude_foods,
            adultsInFamily,
            childrenInFamily,
            units_mode,
            target_energy_kcal,
            portion,
            activity_level,
            goal_weight_kg,
            weekly_goal_kcal,
            weight_kg,
            height_cm,
            gender,
            birthdate,
            age,
            shopping_freq,
            skill_level,
            due_date,
            fetus_count,
            rd_or_hp,
            completed,
            preferences
        } = this.state;

        const memory = {
            name,
            conditions,
            diets,
            avoidances,
            exclude_foods,
            adultsInFamily,
            childrenInFamily,
            units_mode,
            target_energy_kcal,
            portion,
            activity_level,
            goal_weight_kg,
            weekly_goal_kcal,
            weight_kg,
            height_cm,
            gender,
            birthdate,
            age,
            shopping_freq,
            skill_level,
            due_date,
            fetus_count,
            rd_or_hp,
            completed,
            preferences
        };

        store.set(this.props.storageKey, memory, new Date().getTime() + 1000 * 3600 * 24 * 7);
    }

    closeModal = (success) => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, query, hash } = location;

        const { generate } = query;

        delete query.energy;
        delete query.avoidances;
        delete query.generate;

        router.push({pathname, query, hash});

        if (success === true && !generate) {
            this.setState({flash: 0}, this.tickFlash);
        } else if (success === true && generate) {

            this.getMealPlan(true);
        }
    }

    showAvoidancesModal = () => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, query, hash } = location;

        query.avoidances = 1;

        router.push({pathname, query, hash});

        Analytics.openTryItOutAvoidances();
    }

    showEnergyEstimator = (genAfterDone) => {
        const { router } = this.context;
        const { location } = this.props;
        const { pathname, query, hash } = location;

        query.energy = 1;

        if (genAfterDone === true) {
            query.generate = 1;
        }

        router.push({pathname, query, hash});

        Analytics.openTryItOutEnergy();
    }

    onChangeConditions = async (conditionUuid) => {
        const {nutritionPatterns, preferences} = this.state;
        const pattern = nutritionPatterns.find((pattern) => pattern.uuid == conditionUuid);
        let { avoidances, gender, birthdate, weight_kg, target_energy_kcal } = this.state;
        const { showUpgradeForm } = this.context;

        const user = UserStore.getUser();
        const { premium_conditions } = user && user.capabilities || {};

        if (pattern.tags.includes('Premium') && !premium_conditions && user) {
            showUpgradeForm({feature: 'premium_conditions'});
            return;
        }

        // Is this a pregnancy or breast feeding condition?
        if (['Pregnancy', 'Lactation'].includes(pattern.name)) {
            gender = 'female';
        }

        const newPreferences = await this.resolveMealTypes(
            {gender, birthdate, weight_kg, target_energy_kcal},
            pattern
        );

        this.setState({conditions: [{name: pattern.name, uuid: pattern.uuid}], preferences: {...preferences, ...newPreferences}, avoidances, gender}, this.saveToLocal);
    }

    onChangeDiet = (dietName) => {
        let { conditions, categories, nutritionPatterns } = this.state;
        const diet = dietName !== 'none'
                   ? allDiets.filter(d => d.name === dietName && !d.unselectable)[0]
                   : {avoidances: []};

        let diets      = [diet.name].filter(v => v);
        let avoidances = [];

        conditions = conditions.map(name => nutritionPatterns.find(c => c.name === name))
                               .filter(v => v);

        categories.forEach(category => {
            if (!(category.diets_avoiding && category.diets_avoiding.length > 0)) {
                return;
            }

            if (category.diets_avoiding.includes(diet.name) && !avoidances.includes(category.category_key)) {
                avoidances.push(category.category_key);
            }
        });

        // Add all selected conditions avoidances to the avoidance list as well.
        conditions.forEach(condition => avoidances = avoidances.concat(condition.avoidances));

        // Filter out duplicates
        avoidances = avoidances.filter((avoidance, i) => avoidances.indexOf(avoidance) === i);

        this.setState({diets, avoidances}, this.saveToLocal);
    }

    setAvoidances = (diets, avoidances, exclude_foods) => {
        this.setState({diets, avoidances, exclude_foods}, this.syncAndSaveToLocal);
    }

    onAddAvoidance = (avoid) => {
        const { avoidances } = this.state;

        if (avoidances.includes(avoid)) {
            return;
        }

        avoidances.push(avoid);

        this.setState({avoidances}, this.saveToLocal);
    }

    onRemoveAvoidance = (avoid) => {
        let { diets, avoidances } = this.state;

        avoidances = avoidances.filter(a => a != avoid);

        // Filter out any diets where all the avoidances aren't in the avoidance list
        diets = diets.filter(dietName => {
            const diet = allDiets.filter(diet => diet.name == dietName)[0];

            return diet && diet.avoidances.filter(avoid => avoidances.includes(avoid)).length === diet.avoidances.length;
        });

        this.setState({diets, avoidances}, this.saveToLocal);
    }

    onChangeCalories = (target_energy_kcal) => {
        const portion = calculateNearestPortionSize(target_energy_kcal, this.portionResolution()) || 1;

        this.setState({target_energy_kcal, portion: portion ? portion : 1}, this.saveToLocal);
    }

    portionResolution = () => {
        return this.state.user.portion_resolution ? this.state.user.portion_resolution : 0.25;
    }

    onChangeAdults = (adultsInFamily) => {
        this.setState({adultsInFamily}, this.saveToLocal);
    }

    onChangeChildren = (childrenInFamily) => {
        this.setState({childrenInFamily}, this.saveToLocal);
    }

    onChangeEnergy = (newState) => {
        this.setState(newState, this.saveToLocal);
    }

    generateFamilyMembers = (memberCount, prefix, template = {}, startAt = 1) => {
        let i = startAt; // Starts at 1 because the user is considered the first family member
        let members = [];
        for (; i < memberCount; i++) {
            members.push({
                uuid: uuid.v4(),
                name: prefix + (i + 1),
                ...template,
            });
        }

        return members;
    }

    getProfileFromState = () => {
        const { user, conditions, diets, avoidances, exclude_foods, birthdate, gender, weight_kg, height_cm, units_mode, goal_weight_kg, weekly_goal_kcal,
                activity_level, target_energy_kcal, portion, due_date, fetus_count, adultsInFamily, childrenInFamily, completed, shopping_freq, skill_level, preferences } = this.state;

        // We need the alternate user to inherit these two fields from the currently logged in user
        const { hide_nutrition = false, inhibit_swap = false } = user || {};

        const adults = this.generateFamilyMembers(
            adultsInFamily,
            'Adult #',
            {type: 'adult', portion: 1} // 29 year old adults
        );

        const children   = this.generateFamilyMembers(
            childrenInFamily,
            'Child #',
            {type: 'child', portion: 0.5}, // 7 year old children
            0, // children are not a first member of the family
        );

        // Create the "alternate" user. This user is a fake user that is stored temporarily in local storage
        const altUser = {
            conditions,
            preferences: {
                ...preferences,
                diets,
                avoidances,
                exclude_foods,
                leftovers_enabled: true,
                max_leftover_days: 1,
                equipment: ['Oven', 'Stovetop', 'Microwave', 'Blender'],
                skill_level,
            },
            family: adults.concat(children),
            birthdate,
            gender,
            weight_kg,
            height_cm,
            target_energy_kcal,
            portion,
            due_date,
            fetus_count,
            activity_level,
            goal_weight_kg,
            weekly_goal_kcal,
            units_mode,
            completed,
            hide_nutrition,
            inhibit_swap,
            portion_resolution: user.preferences.portion_resolution,
            shopping_freq,
            role: 'admin'
        };

        return altUser;
    }

    renderAvoidancesModal = () => {
        const { location, tryItMode } = this.props;
        const { avoidances } = this.state;

        if (!location.query.avoidances) {
            return null;
        }

        return (
            <AvoidancesModal profile={this.getProfileFromState()}
                closeModal={this.closeModal}
                setAvoidances={this.setAvoidances}
                tryItMode={tryItMode} />
        );
    }

    renderEnergyEstimatorModal = () => {
        const {
            conditions,
            target_energy_kcal,
            portion,
            activity_level,
            height_cm,
            weight_kg,
            goal_weight_kg,
            units_mode,
            due_date,
            fetus_count,
            diets,
            avoidances,
            birthdate,
            gender,
            completed,
            nutritionPatterns,
            preferences
        } = this.state;

        const { location, tryItMode } = this.props;

        if (!location.query.energy) {
            return null;
        }

        return <EnergyEstimatorModal
            estimatorGeneratesPlan={location.query.generate ? true : false}
            diets={diets} avoidances={avoidances}
            conditions={conditions}
            target_energy_kcal={target_energy_kcal}
            portion={portion}
            activity_level={activity_level}
            height_cm={height_cm}
            weight_kg={weight_kg}
            goal_weight_kg={goal_weight_kg}
            units_mode={units_mode}
            due_date={due_date}
            fetus_count={fetus_count}
            birthdate={birthdate}
            gender={gender}
            completed={completed}
            nutritionPatterns={nutritionPatterns}
            preferences={preferences}
            tryItMode={tryItMode}
            resolve={this.resolveMealTypes}
            onChangeValues={this.onChangeEnergy}
            closeModal={this.closeModal}
            portion_resolution={this.portionResolution()}/>
    }

    render = () => {
        const { user, conditions, diets, gender, age, shopping_freq, skill_level, avoidances, exclude_foods, target_energy_kcal, adultsInFamily, childrenInFamily,
                nutritionPatterns, keyIndex, foods, loading } = this.state;
        const { tryItMode, needCalorieEstimate, working } = this.props;

        if (loading || !conditions.length || !nutritionPatterns.length) {
            return (
                <div className='try-it-out-loader'>
                    <i className='icon-spinner2'/>
                </div>
            );
        }

        const condition = conditions[0].uuid;
        const diet = diets[0] || 'none';
        const { premium_conditions } = user && user.capabilities || {};
        const conditionOpts = nutritionPatterns.map(c => {
                                        return({
                                        label: <span>
                                                    {tryItMode === 'pro' ? c.name : (c.consumer_name || c.name)}
                                                    {!premium_conditions && c.tags.includes('Premium') && user ?
                                                        <i className="icon-lock" />
                                                    : null}
                                                </span>,
                                        value: c.uuid
                                     })});

        const dietOpts = allDiets.filter(d => !d.disabled && !d.unselectable)
                                 .map(d => ({label: d.name, value: d.name}));
        dietOpts.unshift({value: 'none', label: 'Eat Most Things'})

        const caloriesOpts = [];
        let calories = 1200;
        for (; calories <= 2600; calories += 100) {
            caloriesOpts.push({label: calories + ' calories', value: calories});
        }

        if (caloriesOpts.filter(co => co.value == target_energy_kcal).length == 0) {
            let label = target_energy_kcal + ' calories (calculated for you)',
                value = target_energy_kcal;

            if (tryItMode === 'pro') {
                label = target_energy_kcal + ' calories ('+ age + ' yr old ' + gender + ')';
            }

            caloriesOpts.unshift({label, value});
        }

        let adultOpts = [], childOpts = [], i = 0;
        for(i = 0; i < 10; i++) {
            if (i > 0) {
                adultOpts.push({
                    label: i + (i === 1 ? ' adult' : ' adults'),
                    value: i,
                });
            }

            childOpts.push({
                label: i + (i === 1 ? ' child' : ' children'),
                value: i,
            });
        }

        const skillLevelOpts = [
            {value: '', label: 'Any'},
            {value: 'Beginner', label: 'Beginner'},
            {value: 'Intermediate', label: 'Intermediate'},
            {value: 'Advanced', label: 'Advanced'},
        ];

        const planSizeOpts = [
            {value: 1, label: '1 day'},
            {value: 3, label: '3 days'},
            {value: 5, label: '5 days'},
            {value: 7, label: '7 days'},
        ];

        return (
            <div className="try-it-form preferences-form">
                <div className="with-label conditions">
                    <label>Health Condition or Lifestyle</label>
                    <Select options={conditionOpts} value={condition}
                        onChange={this.onChangeConditions} disabled={working} />
                </div>

                <div className="with-label diets">
                    <label>Choose Diet</label>
                    <Select options={dietOpts} value={diet}
                        onChange={this.onChangeDiet} disabled={working} />

                    <div className="selected-avoidances">
                        {avoidances.map(avoid => keyIndex && keyIndex[avoid] ? (
                             <button className="avoid-btn" key={avoid} onClick={() => this.onRemoveAvoidance(avoid)}>
                                No {keyIndex[avoid].title}
                            </button>
                        ) : null)}
                        {exclude_foods.map(uuid => foods && foods[uuid] ? (
                             <button className="avoid-btn" key={uuid} onClick={() => this.onRemoveExcludeFood(uuid)}>
                                No {foods[uuid].pretty_name || foods[uuid].name}
                            </button>
                        ) : null)}
                        <button onClick={working ? null : this.showAvoidancesModal}  className="add-avoidances-btn">
                            add food avoidances
                        </button>
                    </div>

                </div>

                {!needCalorieEstimate || ( needCalorieEstimate && !needCalorieEstimate.includes(condition)) ?
                    <div className="with-label calories">
                        <label>Calories per Day</label>
                        <Select options={caloriesOpts} value={target_energy_kcal}
                            onChange={this.onChangeCalories} disabled={working}/>

                        <footer>
                            <button onClick={working ? null : this.showEnergyEstimator} className="calc-energy-btn">
                                {tryItMode === 'pro' ? 'calculate calorie needs' : null}
                                {tryItMode === 'consumer' ? 'calculate your calorie needs' : null}
                            </button>
                        </footer>
                    </div>
                : null}

                <ConfigWarning profile={this.getProfileFromState()} />

                <div className="with-label adults">
                    <label>Family Size</label>
                    <Select options={adultOpts} value={adultsInFamily} showAbove={true}
                        onChange={this.onChangeAdults} disabled={working} />
                </div>

                <div className="with-label children">
                    <label>children 12 and under</label>
                    <Select options={childOpts} value={childrenInFamily} showAbove={true}
                        onChange={this.onChangeChildren} disabled={working} />
                </div>

                    <div className="with-label skill-level">
                        <label>Max Recipe Complexity</label>
                        <Select value={skill_level}
                            showAbove={true}
                            placeholder="Skill Level"
                            options={skillLevelOpts}
                            onChange={skill_level => this.setState({ skill_level, dirty: true })}>
                            <p>Max Recipe Complexity</p>
                        </Select>
                    </div>

                    <div className="with-label plan-size">
                        <label>Meal Plan Size</label>
                        <Select value={shopping_freq}
                            showAbove={true}
                            placeholder="Plan Size"
                            options={planSizeOpts}
                            onChange={shopping_freq => this.setState({ shopping_freq, dirty: true })}>
                            <p>Plan Size Preference</p>
                        </Select>
                    </div>

                {this.renderAvoidancesModal()}
                {this.renderEnergyEstimatorModal()}
            </div>
        );
    }

}
