'use strict';

import { Component } from 'react';
import Analytics from '../../../../utils/Analytics';
import { FoodEditorModalContent } from '../../../Foods/Modals/FoodEditorModal';
import './CreateCustom.scss';

export default class CreateCustom extends Component {

    componentDidMount = () => {
        Analytics.startCreateCustomMeal({'Context': 'Add/Swap'});
    }

    onSaveFood = (food) => {
        const { onSelectFood } = this.props;

        Analytics.savedCustomFood({
            'Context': 'Add/Swap',
            'Food UUID': food.uuid
        });

        onSelectFood(food);
    }

    render() {
        const { closeModal } = this.props;

        return (
            <FoodEditorModalContent
                bodyClassName="el-modal1-body-container"
                closeModal={closeModal}
                onSaveFood={this.onSaveFood}
                {...this.props} />
        );
    }
}
