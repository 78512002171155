'use strict';

import keymirror from 'keymirror';

const TaxonomyConstants = keymirror({
    TAXONOMY_ENSURE_LOADED: null,
    TAXONOMY_UPSERT: null,
    TAXONOMY_DELETE: null,
    TAXONOMY_HYDRATE: null,
});

export default TaxonomyConstants;
