'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import store from 'store';
import moment from 'moment';
import uuidGen from 'uuid';
import omit from 'lodash.omit';
import debounce from 'lodash.debounce';
import Helmet from 'react-helmet';
import Modal from 'react-modal';

import RecipeCard from '../Search/Results/RecipeCard.react';
import FoodCard from '../Search/Results/FoodCard.react';
import FavoriteButton from '../Widgets/FavoriteButton.react';
import CollectionBanner from './Editor/CollectionBanner.react';

import UserStore from '../../stores/UserStore';
import BoardStore from '../../stores/BoardStore';
import BoardActions from '../../actions/BoardActions';
import AuthStore from '../../stores/AuthStore';

import { getConfig } from '../../utils/Env';
import { inquire } from '../../utils/Enforcer';
import { getAssetsForMeals } from '../../utils/Meals';
import { fetchDocumentsById } from '../../utils/Content';
import Analytics from '../../utils/Analytics';

import './Viewer.scss';

export default class CollectionViewer extends Component {

    static propTypes = {
        closeModal: PropTypes.func,
    };

    static contextTypes = {
        location: PropTypes.object,
        router: PropTypes.object,
        showLoginForm: PropTypes.func,

        showRecipeDetails: PropTypes.func,
        showFoodDetails: PropTypes.func,
        addSwapContext: PropTypes.object,
    };

    static childContextTypes = {
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        merchants: PropTypes.object,
        brands: PropTypes.object,
        locations: PropTypes.object,
        showRecipeDetails: PropTypes.func,
        howFoodDetails: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const user = UserStore.getUser();

        this.state = {
            collection: null,
            user,
            isModalOpen: false,

            profile: user,
        };
    }

    getChildContext = () => {
        const { assets = {} } = this.state;
        const { recipes = {}, details = {}, foods = {}, merchants = {}, brands = {}, locations = {} } = assets;

        return {
            recipes,
            details,
            foods,
            merchants,
            brands,
            locations,

        };
    }

    onUserStoreChange = () => {
        const { user } = this.state;
        const { uuid } = this.props;

        const newlyLoggedInUser = UserStore.getUser();

        if (!user && newlyLoggedInUser && uuid) {
            this.setState({user: newlyLoggedInUser}, () => this.addToFavorites());
        }
    }

    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
        this.loadCollection().then(collection => {
            const { user } = this.state;
            const { uuid } = this.props;

            if (user && uuid) {
                this.addToFavorites();
            }
        });

    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    addToFavorites = () => {
        const { uuid } = this.props;
        const { collection } = this.state;
        const { router, location } = this.context;
        const { query } = location;

        query.clid = uuid;
        query.types = "collection";

        const lastBoard = BoardStore.getDefaultBoard();
        const newItem = {
            resource_id: uuid,
            resource_type: "collection",
        };

        if (lastBoard.links) {
            // Board already exists, just add to it.
            BoardActions.addToBoard(lastBoard, [newItem]);
        } else {
            lastBoard.contents = lastBoard.contents || [];
            lastBoard.contents.push(newItem);

            // Board does not exist, create a whole new one
            BoardActions.upsertBoards([lastBoard]);
        }

        router.push({
            pathname: '/favorites',
            query
        });           
    }


    createEmptyCollection = () => {
        return {
            title: '',
            items: [],
            tags: [],
        };
    }

    loadCollection = () => {
        const { uuid, collection } = this.props;

        if (collection) {
            this.setState({collection}, this.syncAssets);

            return Promise.resolve(collection);
        }

        if (!uuid) {
            let newCollection = this.createEmptyCollection();

            this.setState({collection: newCollection}, this.syncAssets);

            return Promise.resolve(collection);
        }

        this.setState({loading: true});

        // First, inquire to see if we have write and publish permission.
        const inquiries = [
            {action: 'publish', resource: uuid},
            {action: 'write',   resource: uuid},
        ];

        return new Promise((accept, reject) => {
            inquire(inquiries).then(decisions => {
                if (!decisions) {
                    this.setState({canWrite: false});
                    return;
                }

                let canPublish = decisions.filter(d => d.action == 'publish')[0].decision === 'permit';
                let canWrite   = decisions.filter(d => d.action == 'write')[0].decision === 'permit';

                this.setState({canPublish, canWrite}, () => {
                    this.loadCollectionData(uuid).then(accept, reject).catch(reject);
                });
            });
        });

    }

    getBoardContentByUuid = (uuid) => {
        const boards = BoardStore.getBoardsByResourceId(uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === uuid
            );

            return carry.concat(bcs);
        }, []);

        return contents[0] ? contents[0] : null;
    }

    loadCollectionData = async (uuid) => {
        const { isPro } = this.context;
        const { canWrite } = this.state;

        const first = await fetchDocumentsById([uuid]);
        const collection = JSON.parse(JSON.stringify(first[0])); // deeeeep copy

        if (!(collection && collection.type == 'collection')) {
            this.setState({error: 'cannot edit, not a collection', collection: null});

            return;
        }

        // Immediately analyze the collection, so we're for sure we're showing correct nutrition and allergen information.
        this.setState({collection, loading: false}, this.syncAssets);

        const traits = {};

        const boardContent = this.getBoardContentByUuid(uuid);
        if (boardContent && boardContent.author_name) {
            traits['Recommended By'] = boardContent.author_name;
        }

        Analytics.openCollection(collection, traits);

        return collection;
    }

    syncAssets = async () => {
        const { collection } = this.state;

        if (!collection) {
            return this.setState({assets: {}});
        }

        const assets = await getAssetsForMeals(collection.items);

        this.setState({assets, synced: true});

        return assets;
    }

    closeModal = () => {
        const { closeModal } = this.props;

        closeModal();
    }


    onClickRecipeCard = (recipe) => {
        const { user } = this.state;
        const { onSelectRecipe } = this.props;
        const { showRecipeDetails } = this.context;

        if (!user) {
            return this.setState({isModalOpen: true});
        }

        if (showRecipeDetails) {
            showRecipeDetails(recipe);
        }
    }

    onClickFoodCard = (food) => {
        const { user } = this.state;
        const { onSelectFood } = this.props;
        const { showFoodDetails } = this.context;

        if (!user) {
            return this.setState({isModalOpen: true});
        }

        if (showFoodDetails) {
            showFoodDetails(food);
        }
    }

    renderCollectionItem = (meal, i) => {
        const { assets, user } = this.state;
        const { onSelectRecipe, onSelectFood } = this.props;
        const { addSwapContext } = this.context;

        if (meal.recipe_uuid && assets && assets.recipes && assets.recipes[meal.recipe_uuid]) {
            return (
                <li key={i} className="result-container">
                    <RecipeCard recipe={assets.recipes[meal.recipe_uuid]} onClickRecipeCard={() => this.onClickRecipeCard(assets.recipes[meal.recipe_uuid])} />

                    {onSelectRecipe ? <footer>
                        <button onClick={() => onSelectRecipe(assets.recipes[meal.recipe_uuid], addSwapContext.participants)}>select</button>
                    </footer> : null}
                </li>
            );
        }

        if (meal.food_uuid && assets && assets.foods && assets.foods[meal.food_uuid]) {
            return (
                <li key={i} className="result-container">
                    <FoodCard food={assets.foods[meal.food_uuid]} onClickFoodCard={() => this.onClickFoodCard(assets.foods[meal.food_uuid])} />

                    {onSelectFood ? <footer>
                        <button onClick={() => onSelectFood(assets.foods[meal.food_uuid])}>select</button>
                    </footer> : null}
                </li>
            );
        }
    }

    onClickSignIn = () => {
        const { showLoginForm } = this.context;

        showLoginForm();
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    renderSignInModal = () => {
        const { isModalOpen } = this.state;
        const { uuid } = this.props;

        if (!isModalOpen) {
            return;
        }

        const proUrl = 'https://' + getConfig('pro_host') + '/favorites?types=collection&clid=' + uuid;
        const consumerUrl = 'https://' + getConfig('self_host') + '/favorites?types=collection&clid=' + uuid;

        return (
            <Modal isOpen={true}
                className="el-modal el-modal3 el-modal3-fixed"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Open Collection"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal3-container collection-sign-in-modal ">
                    <header>
                        <h2>Sign in to view the collection</h2>
                    </header>
                    <footer>
                        <a className="el-medium-btn el-medium-btn el-raspberry-btn" onClick={this.onClickSignIn}>Sign In EatLove</a>
                        <a className="el-medium-btn el-medium-btn el-raspberry-outline-btn" target="_blank" href={proUrl} >Sign In EatLove PRO (for professionals)</a>
                    </footer>
                </div>
            </Modal>
        );
    }

    render() {
        const { loading, synced, collection, assets, user } = this.state;
        const { closeModal } = this.props;

        const openCollectionBtn = !user ?
            <button className="el-medium-btn el-medium-wide-btn el-raspberry-btn" onClick={() => this.setState({isModalOpen: true})}>OPEN THE COLLECTION</button>
        : null;

        return (
            <div className="collection-viewer">
                {!synced ? <div className="loading"><p>loading...</p><i className="icon-spinner2" /></div> : null}

                {collection && synced ?
                    <div>
                        <Helmet title={`${collection.title} | EatLove`} />
                        <h2>{collection.title} <FavoriteButton collection={collection} /> {openCollectionBtn}</h2>
                        
                        <CollectionBanner collection={collection} />

                        <ul className="global-search-results">
                            {collection.items ?
                                collection.items.map(this.renderCollectionItem)
                            : null}
                        </ul>
                    </div>
                : null}
                {this.renderSignInModal()}
            </div>
        );
    }
}
