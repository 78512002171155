'use strict';

export default [
    "General Healthy Diet",
    "Overweight\/Obesity",
    "Eat More Veggies",
    "Increase Energy",
    "Muscle Gain",
    "Endurance",
    "Zone Diet",
];
