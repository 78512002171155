'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import moment from 'moment';

import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import ImgResized from '../../Widgets/ImgResized.react';

import './LeftoverOffsetsModal.scss';

export default class LeftoverOffsetsModal extends Component {
    static propTypes = {
        offsets: PropTypes.array,
        ctaTitle: PropTypes.string,
        closeModal: PropTypes.func.isRequired,

        onSelectLeftoverOffsets: PropTypes.func.isRequired
    };

    static defaultProps = {
        ctaTitle: 'Save Meal',
    };

    constructor(props) {
        super(props);

        this.state = {
            selected: props.offsets.slice(),
        };
    }

    toggleOffset = (offset) => {
        let { selected } = this.state;

        let index = selected.indexOf(offset);

        if (index === -1) {
            selected.push(offset);
        } else {
            selected.splice(index, 1);
        }

        this.setState({selected});
    }

    onClickCta = () => {
        const { selected } = this.state;
        const { onSelectLeftoverOffsets, params} = this.props;

        const mealType = params?.meal?.meal;

        onSelectLeftoverOffsets(selected, mealType);
    }

    render() {
        const { offsets, ctaTitle, closeModal } = this.props;
        const { selected } = this.state;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Overwrite with leftovers"
                className="log-portions-modal"
                overlayClassName="feed-modal-overlay log-portions-overlay"
                closeTimeoutMS={250}>
                <div className="log-portions-modal-container leftover-offsets-container">
                    <header>
                        <button className="close-modal-btn" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <section className="log-portions-body preferences-form">
                        <p>This meal will have leftovers. Which meals would you like to replace with these leftovers?</p>
                        <ul>
                            {offsets.map((offset, i) => (
                                <li key={i}>
                                    <button className="checkbox-btn"
                                        data-selected={selected.includes(offset)}
                                        onClick={() => this.toggleOffset(offset)}>
                                        {offset instanceof moment ? offset.format('dddd, MMM Do') : `Day ${offset + 1}`}
                                    </button>
                                </li>
                            ))}

                            <li>
                                <button className="checkbox-btn" data-selected={selected.length == 0}
                                    onClick={() => this.setState({selected: []})}>
                                    none
                                </button>
                            </li>
                        </ul>
                    </section>

                    <footer>
                        <button className="cancel-btn" onClick={closeModal}>cancel</button>
                        <button className="ok-btn" onClick={this.onClickCta}>{ctaTitle}</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
