'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import UserStore from '../../../stores/UserStore';
import UserActions from '../../../actions/UserActions';
import Analytics from '../../../utils/Analytics';

import './Agreement.scss';

const AGREEMENT_VERSION = '10.2017';

export default class BusinessAssociatesAgreement extends Component {

    static contextTypes = {
        confirm: PropTypes.func,
    };

    static propTypes = {
        onAccept: PropTypes.func,
        onCancel: PropTypes.func,
        actionRequiredText: PropTypes.node,
    };

    static defaultProps = {
        actionRequiredText: <span>EatLove is a HIPAA-compliant company. In order to protect you and patients, we require all dietitians to sign our Business Associates Agreement.<br /> <em>You will not incur a fee until you subscribe.</em></span>,
    };

    constructor(props) {
        super(props);

        this.state = {
            working: false,
        };
    }

    componentDidMount = () => {
        Analytics.viewAgreement(AGREEMENT_VERSION);
    }

    getAgreementVersion = () => {
        return AGREEMENT_VERSION;
    }

    onCancelAgreement = () => {
        const { onCancel } = this.props;

        onCancel && onCancel();
    }

    onAcceptAgreement = () => {
        const { onAccept } = this.props;
        const { confirm } = this.context;
        const user = UserStore.getUser();

        confirm(
            'I agree to the EatLove Business Associates Agreement',
            async () => {
                Analytics.acceptAgreement(AGREEMENT_VERSION);

                this.setState({working: true});

                await UserActions.updateSpecificMeta({
                    agreement_datetime: moment().format(),
                    agreement_version: AGREEMENT_VERSION,
                    agreement_signature: user.name,
                });

                onAccept && onAccept();

                this.setState({working: false});
            },
            reject => this.setState({working: false}),
            {
                titleText: 'Business Associates Agreement',
                acceptText: 'Agree',
                rejectText: 'Cancel',
            }
        );
    }

    render() {
        const { working } = this.state;
        const { actionRequiredText, onCancel } = this.props;
        const user = UserStore.getUser();

        return (
            <div className="accept-license editor-modal-container">
                <div className="editor-scrollable">
                    <p className="why-required">{actionRequiredText}</p>
                    <h1>Business Associate Agreement</h1>


        <p>This BUSINESS ASSOCIATE ADDENDUM (&ldquo;<span className="bold underline">BAA</span>&rdquo;) is entered into by and between <span className="c10 c15">GYFS, Inc./EatLove, </span>a Delaware Corporation, having its principal place of business at 530 Divisadero St. #214 San Francisco, CA 94117 (&ldquo;<span className="bold underline">Business Associate</span>&rdquo;) and the undersigned customer (&ldquo;<span className="bold underline">Customer</span>&rdquo;) and is effective as of the last date appearing on the signature block below (the &ldquo;<span className="bold underline">BAA Effective Date</span>&rdquo;). Business Associate and Customer are referred to herein collectively, as the &ldquo;<span className="bold underline">Parties</span>&rdquo; and individually, as a &ldquo;<span className="bold underline">Party</span>.&rdquo;</p>
        <p className="recitals-heading">Recitals</p>
        <ol>
            <li><em>(A)</em> Business Associate provides certain software technology services, used by clinicians to assist their patients in nutrition and meal planning, which may involve the creation, receipt, maintenance, access, transmission, Use, or Disclosure of PHI (as defined below) by Business Associate.</li>
            <li><em>(B)</em> The Parties desire to ensure that their respective rights and responsibilities reflect applicable legal requirements relating to the protection of confidentiality and security of PHI in accordance with federal and state laws, to the extent that state laws are more restrictive, including, the Health Insurance Portability and Accountability Act of 1996 (&ldquo;<span className="bold underline">HIPAA</span>&rdquo;), as amended by the Health Information Technology for Economic and Clinical Health Act (&ldquo;<span className="bold underline">HITECH</span>&rdquo;) provisions of the American Recovery and Reinvestment Act of 2009, and Title I of the Genetic Information Nondiscrimination Act of 2008, and any regulations promulgated thereunder, including the Privacy Rule, Security Rule, and Breach Notification Rule, as such laws and regulations may be amended from time to time (collectively, the &ldquo;<span className="bold underline">HIPAA Rules</span>&rdquo;, together with HIPAA and HITECH as the &ldquo;<span className="bold underline">Privacy Laws</span>&rdquo;).</li>
            <li><em>(C)</em> To comply with the Privacy Laws, the Parties must enter into an agreement that governs the creation, receipt, maintenance, access, transmission, Use, and Disclosure of the PHI by Business Associate in the course of performing the Services in connection with the Underlying Agreement.</li>
        </ol>
        <p>NOW, THEREFORE, the Parties agree as follows:</p>





        <h2>Definitions.</h2>
        <h3>General Statement.</h3>
        <p> The following terms used in this BAA will have the same meaning as those terms in the HIPAA Rules: Administrative Safeguards, Availability, Breach, Business Associate, Confidentiality, Covered Entity, Data Aggregation, Designated Record Set, Disclosure, Electronic Protected Health Information (&ldquo;<span className="bold underline">EPHI</span>&rdquo;), Health Care Operations, Individual, Individually Identifiable Health Information, Integrity, Minimum Necessary, Physical Safeguards, Protected Health Information (&ldquo;<span className="bold underline">PHI</span>&rdquo;), Required by Law, Secretary, Security Incident, Subcontractor, Technical Safeguards, Unsecured PHI, Uses and Disclosures, and Workforce. A change to the Privacy Laws which modifies any defined term, or which alters the regulatory citation for the definition will be deemed incorporated into this BAA.</p>

        <h3>Breach Notification Rule</h3>
        <p> means Part 2, Subtitle D of HITECH and Notification in the Case of Breach of Unsecured Protected Health Information at 45 C.F.R. Part 164 Subpart D.</p>

        <h3>Privacy Rule</h3>
        <p> means the standards for Privacy of Individually Identifiable Health Information at 45 C.F.R. Part 160 and Subparts A and E of Part 164.</p>

        <h3>Security Rule</h3>
        <p> means the Security Standards for the Protection of Electronic Protected Health Information at 45 C.F.R. Part 160 and Subparts A and C of Part 164.</p>





        <h2>Status of Parties.</h2>
        <p>Business Associate hereby acknowledges and agrees that Customer is either a &ldquo;Business Associate&rdquo; of Customer&rsquo;s client or a &ldquo;Covered Entity&rdquo; and that Business Associate is either a &ldquo;Subcontractor&rdquo; or &ldquo;Business Associate&rdquo; of Customer, as such quoted terms are defined in the HIPAA Rules.</p>





        <h2>Scope.</h2>
        <p>This BAA is applicable to Business Associate&rsquo;s creation, receipt, maintenance, access, transmission, Use, or Disclosure of PHI, in any form or medium, including EPHI, in Business Associate&rsquo;s capacity as a &ldquo;Business Associate&rdquo; or &ldquo;Subcontractor,&rdquo; as applicable, of Customer. Capitalized terms used in this BAA that are not otherwise defined in this BAA have the meaning set forth in the Master Terms.</p>







        <h2><em>4.</em> Business Associate Obligations.</h2>
        <h3>Data Use and Disclosure.</h3>
        <p className="subsection-heading indent2">Limits on Use and Disclosure.</p>
        <ol>
            <li><em>(a)</em>Except as otherwise provided by this BAA, Business Associate may only Use and Disclose the PHI to perform the Services for, or on behalf of, Customer as set forth in the Underlying Agreement, <span className="italic">provided that</span>such Use or Disclosure, if made by the Customer, would not violate the HIPAA Rules. Business Associate agrees to make Uses, Disclosures and requests for PHI consistent with the Privacy Rule&rsquo;s Minimum Necessary requirements. Additionally, Business Associate may:</li>
            <li><em>(b)</em>Use PHI for its own proper management and administration or to carry out its legal responsibilities; and</li>
            <li><em>(c)</em>Disclose PHI for its own proper management and administration or to fulfill any of its legal responsibilities, <span className="italic">provided that</span> (i) Disclosures are Required by Law, or (ii) Business Associate obtains reasonable assurances from the third party to whom the information is disclosed that such PHI will be (x) held secure and confidential as provided pursuant to this BAA and will only be used or further disclosed for the purpose which it was disclosed to such third party or as may otherwise be Required by Law, and (y) that such third party agrees to notify Business Associate of any Breach involving Unsecured PHI or Security Incidents which result in a Use or Disclosure of EPHI that becomes known to such third party.</li>
        </ol>

        <p className="subsection-heading indent2">Data Aggregation.</p>
        <p> Pursuant to the Underlying Agreement, Business Associate may Use PHI in its possession to provide Data Aggregation services relating to the Health Care Operations of Customer or, if Customer is a Business Associate, for the Covered Entity on whose behalf Customer is acting.</p>
        <p className="subsection-heading indent2">De-Identify PHI.</p>
        <p> Customer agrees and understands that Business Associate desires to continually improve its Services and that these improvements are made for the benefit of Customer. Accordingly, Business Associate may de-identify PHI for process and service improvement and management, product development, and other similar business purposes and may, from time to time, work with third-parties that may assist Business Associate in such endeavors. Business Associate agrees that any PHI Used or Disclosed by Business Associate in connection with such endeavors will be in a de-identified form as set forth and prescribed in (a) the standards for statistical and scientific de-identification as set forth in Section 164.514(b)(1) of the Privacy Rule, or (b) the safe harbor de-identification of patient information contained in Section 164.514(b)(2) of the Privacy Rule (collectively with (a), the &ldquo;<span className="bold underline">De-Identified Data</span>&rdquo;), prior to such Use or Disclosure.</p>
        <h3>Safeguards and Third Parties.</h3>
        <p> Business Associate will use appropriate Administrative, Physical and Technical Safeguards pursuant to the HIPAA Rules to prevent the Use or Disclosure of PHI other than pursuant to the terms and conditions of this BAA, the Underlying Agreement, or as Required by Law and to reasonably and appropriately protect the Confidentiality, Integrity, and Availability of PHI that Business Associate creates, receives, maintains, accesses, or transmits, on behalf of Customer. Business Associate further agrees to ensure that any agent, including a Subcontractor that creates, receives, maintains, accesses, or transmits PHI on behalf of Business Associate agrees in writing to substantially similar restrictions and conditions that apply to Business Associate with respect to such information.</p>
        <h3>Reporting Unauthorized Uses, Breaches, and Security Incidents.</h3>
        <p> Business Associate will notify Customer in writing, within 15 business days after becoming aware of: (a) any Use or Disclosure of PHI that is not authorized by this BAA or the Underlying Agreement; (b) any Breach involving Unsecured PHI; or (c) any Security Incident that results in a Use or Disclosure of EPHI in violation of this BAA or the Underlying Agreement. For Security Incidents that do not result in a Use or Disclosure of EPHI in violation of this BAA or the Underlying Agreement, this <span className="underline">Section 4.3</span> (<span className="italic">Reporting Unauthorized Uses, Breaches, and Security Incidents</span>) will be deemed as notice to Customer that Business Associate periodically receives unsuccessful attempts for unauthorized access, Use, Disclosure, modification or destruction of information or interference with the general operation of Business Associate&rsquo;s information systems and the Services and, even if such events are defined as a Security Incident under the HIPAA Rules, Business Associate will not provide any further notice regarding such unsuccessful attempts.</p>
        <h3>Mitigation.</h3>
        <p> Business Associate will mitigate, to the extent practicable, any harmful effect that is known to Business Associate of a Use or Disclosure of PHI by Business Associate, its agents or Subcontractors, if any, in violation of the requirements of this BAA, the Underlying Agreement, or the Privacy Laws. Business Associate will further take prompt action to mitigate, to the extent practicable, harmful effects of Security Incidents and Breaches involving Unsecured PHI, <span className="italic">provided that</span> such harmful effects are known to Business Associate. </p>
        <h3>Sanctions.</h3>
        <p> Business Associate will apply appropriate sanctions against any Workforce member, agent, or Subcontractor who uses or discloses PHI in violation of the Underlying Agreement, this BAA, or the Privacy Laws. </p>
        <h3>Access to PHI.</h3>
        <p> In the event that the PHI in Business Associate&rsquo;s possession constitutes a Designated Record Set, Business Associate will promptly, but no more than 15 business days, make available to Customer access to the PHI so that Customer (or, if Customer is a Business Associate, the Covered Entity on whose behalf Customer is acting) may meet the requirements of 45 C.F.R. &sect; 164.524. As between Business Associate and Customer, Customer is responsible for responding to Individuals&rsquo; request for access to PHI. In the event any Individual delivers a request for access to PHI directly to Business Associate, Business Associate will promptly forward such request to Customer.</p>
        <h3>Amendment of PHI.</h3>
        <p> In the event that the PHI in Business Associate&rsquo;s possession constitutes a Designated Record Set, Business Associate will promptly, but no more than 15 business days, make available to Customer access to the PHI so that Business Associate (or, if Customer is a Business Associate, the Covered Entity on whose behalf Customer is acting) may incorporate any amendment(s) to the PHI in accordance with 45 C.F.R. &sect; 164.526. As between Business Associate and Customer, Customer is responsible for responding to Individuals&rsquo; request for amendment of PHI. In the event any Individual delivers a request for amendment of PHI directly to Business Associate, Business Associate will promptly forward such request to Customer.</p>
        <h3>Accounting of Disclosures of PHI.</h3>
        <p> Business Associate will document all Disclosures of the PHI and such other information related to the Disclosure of PHI as may reasonably be necessary for Customer (or, if Customer is a Business Associate, the Covered Entity on whose behalf Customer is acting) to respond to any request by an Individual for an accounting of Disclosures of PHI in accordance with 45 C.F.R. &sect; 164.528. Within 15 business days of notice by Customer to Business Associate that Customer has received a request for an accounting of Disclosures of PHI regarding an Individual, Business Associate will make available to Customer information to permit Customer (or, if Customer is a Business Associate, the Covered Entity on whose behalf Customer is acting) to respond to the request for an accounting of Disclosures of PHI. In the event any Individual delivers a request for an accounting of Disclosures of PHI directly to Business Associate, Business Associate will promptly forward such request to Customer.</p>
        <h3>Restrictions.</h3>
        <p> Business Associate will comply with any restrictions on Disclosure of PHI requested by an Individual and agreed to by Customer in accordance with 45 C.F.R. &sect; 164.522 and HITECH &sect; 13405(a).</p>
        <h3>Availability of Books and Records.</h3>
        <p> To the extent required by law, and subject to applicable attorney-client privileges, Business Associate will make its internal practices, books, and records available to the Secretary for purposes of determining Customer&rsquo;s compliance with the Privacy Laws.</p>












        <h2><em>5.</em>Obligations of Customer.</h2>
        <h3>Security of PHI.</h3>
        <p> Customer agrees and understand that the security of PHI requires the reasonable cooperation of both Parties. Accordingly, Customer will use commercially reasonable efforts to secure the Customer-side environment, by, for example, training Workforce members, securing and using strong passwords, using secure connections, and other similar Customer-side Administrative, Physical, and Technical Safeguards.</p>
        <h3>Notice of Privacy Practices.</h3>
        <p> Customer will notify Business Associate, in writing, of any limitation(s) in the Customer&rsquo;s (or, if Customer is a Business Associate, the Covered Entity on whose behalf Customer is acting) Notice of Privacy Practices, to the extent that such limitation may affect Business Associate.</p>
        <h3>Restrictions.</h3>
        <p> Customer will notify Business Associate, in writing, of any restriction to the Use or Disclosure of PHI that Customer has agreed to or must comply with in accordance with 45 C.F.R. &sect; 164.522 and HITECH &sect; 13405(a), to the extent that such restriction may affect Business Associate.</p>
        <h3>Changes in Authorization.</h3>
        <p> Customer will notify Business Associate of any changes in, or revocation of, the permission by an Individual to Use or Disclose of such Individual&rsquo;s PHI, to the extent that such changes may affect Business Associate.</p>
        <h3>Compliance with Laws.</h3>
        <p> Customer will not request Business Associate to view Customer&rsquo;s PHI or to Use or Disclose PHI in any manner that would not be permissible under the Privacy Laws if done by Customer.</p>
        <h3>End Users.</h3>
        <p> Customer agrees and understands that Customer and not Business Associate, is responsible for managing whether Customer&rsquo;s end users are authorized to access, share, Disclose, create, and Use PHI and Business Associate will have no obligations relating thereto.</p>











        <h2><em>6.</em>Term and Termination.</h2>
        <h3>Term.</h3>
        <p> The term of this BAA will be effective as of the BAA Effective Date and will terminate when all of the PHI is destroyed or returned to Customer, or, if it is not feasible to return or destroy the PHI, protections are extended to such information, in accordance with this BAA.</p>
        <h3>Termination.</h3>
        <p> Either Party may terminate this BAA in the event of a material breach of this BAA by the other Party. The termination will be effective 30 calendar days after a Party provides written notice of the material breach to the other Party and the Party receiving notice of the breach (a) has failed to remedy such breach, or (b) has failed to take substantial steps, to the reasonable satisfaction of the Party that provided notice, to remedy such breach. The termination will be effective immediately upon written notice in the event the Party providing notice reasonably believes that cure of the material breach is not feasible. A Party&rsquo;s option to have cured a material breach of this BAA will not be construed as a waiver of any other rights such Party has under this BAA, by operation of law, or in equity.</p>
        <h3>Effect of Termination.</h3>
        <p> Upon the termination of this BAA or the Underlying Agreement for any reason, Business Associate will return to Customer all PHI created, received or maintained by Business Associate or, at Business Associate&rsquo;s reasonable direction, destroy all PHI received from Customer that Business Associate maintains in any form, recorded on any medium, or stored in any storage system. This provision will apply to PHI that is in the possession of Business Associate, its agents or Subcontractors, if any. Business Associate and Customer will remain bound by the provisions of this BAA, even after termination of the Underlying Agreement or this BAA, until all PHI has been returned or otherwise destroyed as provided in this <span className="underline">Section 6.3</span> (<span className="italic">Effect of Termination</span>). Notwithstanding the foregoing, Customer agrees and understands that the return of PHI stored in backup media is not feasible and that such PHI will be destroyed in the normal course of Business Associate&rsquo;s data management activities. Business Associate will not retain any copies of PHI, except as permitted herein, permitted by the Underlying Agreement, Required by Law, as may reasonably be necessary to comply with business recordkeeping requirements, or otherwise agreed to by the Parties in writing. Termination of this BAA will not relieve Customer of any monetary obligations set forth in the Underlying Agreement.</p>
        <h3>Termination of Underlying Agreement.</h3>
        <p> If the Underlying Agreement is terminated for any reason, this BAA will also terminate.</p>











        <h2><em>7.</em>General Terms.</h2>
        <h3>Regulatory References.</h3>
        <p> A reference in this BAA to a section of the Privacy Laws, or the regulations issued thereunder, means the section or regulation as in effect or as amended, and for which compliance is required. </p>
        <h3>Amendment; Waiver.</h3>
        <p> This BAA may be amended or supplemented only by a writing that refers explicitly to this BAA and that is signed by both Parties. The Parties agree to amend this BAA as required to comply with any changes in laws, rules or regulations that affect the privacy and security of PHI and the Business Associate&rsquo;s duties under the Underlying Agreement or this BAA. No delay or failure of either Party to exercise any right or remedy available hereunder, at law or in equity, will act as a waiver of such right or remedy, and any waiver will not waive any subsequent right, obligation, or default.</p>
        <h3>Entire Agreement.</h3>
        <p> This BAA, together with the Underlying Agreement, contain the entire understanding between the Parties hereto and will supersede any other oral or written agreements, discussions and understandings of every kind and nature, with respect to the subject matter hereof.</p>
        <h3>Order of Precedence.</h3>
        <p> Any ambiguity in this BAA will be resolved to permit Business Associate to comply with the Privacy Laws. If any express term of this BAA conflicts with the Underlying Agreement, then this BAA, if applicable, will control as to that term, but only to the extent of an express ambiguity. The Underlying Agreement will control in all other instances, including, without limitation, remedies, limitation of liability, limitation of remedies, warranties, disclaimer of warranties, governing law, venue, and relationship of the Parties.</p>
        <h3>No Third Party Beneficiaries.</h3>
        <p> Nothing express or implied in this BAA is intended to confer, nor will anything herein confer, upon any person other than Customer, Business Associate, or their respective successors or permitted assigns, any rights, remedies, obligations or liabilities whatsoever. </p>
        <h3>Survival.</h3>
        <p> The rights and obligations contained in <span className="underline">Sections 4.3</span> (<span className="italic">Reporting Unauthorized Uses, Breaches, and Security Incidents</span>), <span className="underline">4.4</span> (<span className="italic">Mitigation</span>), <span className="underline">4.8</span> (<span className="italic">Accounting of Disclosures of PHI</span>), <span className="underline">4.10</span> (<span className="italic">Availability of Books and Records</span>), <span className="underline">5.6</span> (<span className="italic">End Users</span>), <span className="underline">6.3</span> (<span className="italic">Effect of Termination</span>), and 7 (<span className="italic">General Terms</span>) will survive the termination of this BAA.</p>
        <h3>Notices.</h3>
        <p> All notices that either Party may desire or be required to give to the other will be in writing and will be delivered by overnight courier or by priority mail by a recognized express mail vendor to the other Party at the address set forth in the signature page or such other address as a Party may provide. Notice delivered by facsimile or e-mail will be confirmed by overnight courier or by priority mail.</p>
        <h3>Severability.</h3>
        <p> If any provision of this BAA is determined by a court of competent jurisdiction to be invalid, void, or unenforceable, the remaining provisions hereof will continue in full force and effect.</p>
        <h3>Counterparts.</h3>
        <p> This BAA may be executed in counterparts, each of which will be deemed an original, and all of which will constitute one binding agreement and may be delivered by electronic mail or fax.</p>




        <div className="signatures">
            <div className="signature provider">
                <h4>EatLove</h4>

                <ul>
                    <li>Signature: <img src="https://static.chewba.info/images/a0e2d850-b4d4-4ea4-b8c8-5894a89af135.jpg" /></li>
                    <li>Name: Monique Nadeau</li>
                    <li>Title: Founder & CEO</li>
                    <li>Date: {moment().format('MM/DD/YYYY')}</li>
                </ul>
            </div>

            <div className="signature customer">
                <h4>Customer</h4>
                <ul>
                    <li>Signature:</li>
                    <li>Name: {user.name}</li>
                    <li>Title: </li>
                    <li>Date: {moment().format('MM/DD/YYYY')}</li>
                </ul>
            </div>
        </div>

                    <a target="_blank" href="https://static.chewba.info/assets/eatlove-business-associates-agreement-10.2017.pdf">
                        <button className="sub-action-btn">Download PDF</button>
                    </a>

                </div>

                <footer className="agreement-footer">
                    {onCancel ? <button onClick={this.onCancelAgreement} className="decline-btn">Decline</button> : null}
                    {!onCancel ? <Link to="/"><button className="decline-btn">Decline</button></Link> : null}
                    <button onClick={this.onAcceptAgreement} disabled={working} className="agree-btn">{working ? 'working...' : 'Accept'}</button>
                </footer>

            </div>
        );
    }
}
