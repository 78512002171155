import * as Sentry from '@sentry/react';

export const ScreenService = {
    keepAwake: async () => {
        try {
            // await KeepAwake.keepAwake();
        } catch (error) {
            Sentry.captureException(error);
        }
    },
    allowSleep: async () => {
        try {
            // await KeepAwake.allowSleep();
        } catch (error) {
            Sentry.captureException(error);
        }
    },
};
