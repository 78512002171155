'use strict'

export default {
    walmart: {
        name: 'Walmart',
        logo: 'https://static.chewba.info/images/walmart-logo.svg',
    },

    amazon: {
        name: 'Amazon',
        logo: 'https://static.chewba.info/images/amazon-fresh-logo-2.png',
    },
    wholefoods: {
        name: 'Wholefoods',
        logo: 'https://static.chewba.info/images/wholefoods-logo.svg',
    },
    instacart: {
        name: 'Instacart',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
    },
    kroger: {
        name: 'Kroger',
        logo: 'https://static.chewba.info/images/kroger-logo.svg',
        via: 'instacart',
        countries: ['US'],
    },
    wegmans: {
        name: 'Wegmans',
        logo: 'https://static.chewba.info/images/wegmans-logo.svg',
        via: 'instacart',
        countries: ['US'],
    },
    safeway: {
        name: 'Safeway',
        logo: 'https://static.chewba.info/images/safeway-logo.svg',
        via: 'instacart',
        countries: ['US'],
        instacart: {
            store_id: 'safeway-usa',
            current_retailer_id: 1,
        },
    },
    aldi: {
        name: 'Aldi',
        logo: 'https://static.chewba.info/images/aldi-logo.svg',
        via: 'instacart',
        countries: ['US'],
    },
    publix: {
        name: 'Publix',
        logo: 'https://static.chewba.info/images/publix-logo.svg',
        via: 'instacart',
        countries: ['US'],
        instacart: {
            store_id: 'Publix-instacart',
            current_retailer_id: 57,
        },
    },
    target: {
        name: 'Target',
        logo: 'https://static.chewba.info/images/target-logo.svg',
        countries: ['US'],
        via: 'instacart',
    },
    sunbasket: {
        name: 'Sunbasket',
        logo: 'https://static.chewba.info/images/sunbasket-logo.svg',
    },
    sprouts: {
        name: 'Sprouts',
        logo: 'https://static.chewba.info/images/446e85d5-bbb0-4a18-a60e-96976ae524e1.png',
        via: 'instacart',
        countries: [],
        instacart: {
            store_id: 'sprouts-usa',
            current_retailer_id: 279,
        },
    },
    raleys: {
        name: 'Raleys',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        countries: [],
        instacart: {
            store_id: '38',
            current_retailer_id: 291,
        },
    },

    loblaws: {
        name: 'Loblaws',
        logo: 'https://static.chewba.info/images/d145e3e7-218a-4585-a122-2deb6d84a5c7.png',
        via: 'instacart',
        countries: ['CA'],
    },

    metro: {
        name: 'Metro',
        logo: 'https://static.chewba.info/images/metro-logo.svg',
        via: 'instacart',
        countries: ['CA'],
    },

    walmart_instacart: {
        name: 'Walmart',
        logo: 'https://static.chewba.info/images/walmart-logo.svg',
        via: 'instacart',
        countries: ['CA'],
    },


    costco: {
        name: 'Costco',
        logo: 'https://static.chewba.info/images/costco-logo.svg',
        via: 'instacart',
        countries: ['US', 'CA'],
        instacart: {
            store_id: 'costco-usa',
            current_retailer_id: 5,
        },
    },

    giant_tiger: {
        name: 'Giant Tiger',
        logo: 'https://static.chewba.info/images/9f1915ae-1158-40cf-911e-ca331d41a80c.jpg',
        via: 'instacart',
        countries: ['CA'],
    },
    natures_emporium: {
        name: 'Nature\'s Emporium',
        logo: 'https://static.chewba.info/images/857d901e-18f4-4f36-b52c-4369d4202fff.jpg',
        via: 'instacart',
        countries: ['CA'],
    },
    galleria: {
        name: 'Galleria Supermarket',
        logo: 'https://static.chewba.info/images/41cafa97-2112-4071-aeb9-cc90b9a8ca2f.jpg',
        via: 'instacart',
        countries: ['CA'],
    },
    dollarama: {
        name: 'Dollarama',
        logo: 'https://static.chewba.info/images/dollarama-logo.svg',
        via: 'instacart',
        countries: ['CA'],
    },

    smartandfinal: {
        name: 'Smart and Final',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        countries: [],
        instacart: {
            store_id: 'smartandfinal-usa',
            current_retailer_id: 105,
        },
    },
    samsclub: {
        name: 'Sam\'s Club',
        logo: 'https://static.chewba.info/images/sams-club-logo.svg',
        via: 'instacart',
        countries: ['US'],
        instacart: {
            store_id: 'samsclub-usa',
            current_retailer_id: 352,
        },
    },
    pavilions: {
        name: 'Pavilions',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        instacart: {
            store_id: 'pavilions-usa',
            current_retailer_id: 37,
        },
    },
    vons: {
        name: 'Vons',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        instacart: {
            store_id: 'vons-usa',
            current_retailer_id: 290,
        },
    },
    northgate_market: {
        name: 'Northgate Market',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        instacart: {
            store_id: '20',
            current_retailer_id: 1352,
        },
    },
    gelsons: {
        name: 'Gelsons',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        instacart: {
            store_id: '',
            current_retailer_id: 63,
        },
    },
    stater_bros_market: {
        name: 'Stater Bros. Market',
        logo: 'https://static.chewba.info/images/instacart-logo.png',
        via: 'instacart',
        instacart: {
            store_id: '206',
            current_retailer_id: 161,
        },
    },
};
