'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import allNutrients from '../../../../tables/nutrients';
import { getStartTimeForMeal } from '../../../../utils/Meals';
import './Summary.scss';

export default class Summary extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    hasMinMax = (min, max) => {
        const fMin = parseFloat(min),
              fMax = parseFloat(max);

        const sMin = (String(min) || '').trim(),
              sMax = (String(max) || '').trim();

        return {
            min: sMin.length > 0 && typeof fMin === 'number' && !isNaN(fMin),
            max: sMax.length > 0 && typeof fMin === 'number' && !isNaN(fMax),
        }
    }

    calcPercents = (nutrNo, envelope = {}) => {

        const {patient} = this.props;
        const {preferences, target_energy_kcal} = patient;

        let per_min = null,
            per_max = null,
            percentage = '';

        const min = envelope.min;
        const max = envelope.max;
        const kcal_min = (preferences.daily_totals[208] && preferences.daily_totals[208].min) || target_energy_kcal;
        const kcal_max = (preferences.daily_totals[208] && preferences.daily_totals[208].max) || target_energy_kcal;

        let cpg = allNutrients[nutrNo].calories_per_gram;
        const has = this.hasMinMax(min, max);

        if (has.min && has.max) {
            per_min = parseFloat(min) * cpg / kcal_min;
            per_max = parseFloat(max) * cpg / kcal_max;

            percentage = Math.round((per_min + per_max) / 2 * 100);
        } else if (has.min && !has.max) {
            per_min = parseFloat(min) * cpg / kcal_min;
            per_max = null;

            percentage = Math.round(per_min * 100);
        } else if (!has.min && has.max) {
            per_min = null;

            percentage = Math.round(per_max * 100);
        }

        return percentage;
    }

    isDirty = () => {
        return this.state.dirty;
    }

    mutate = (patient) => {

        if (!patient.completed.includes('summary')) {
            patient.completed.push('summary');
        }

        return patient;
    }

    render() {
        const { patient } = this.props;
        const { conditions, preferences} = patient;
        const dailyTotals = preferences.daily_totals || {};
        const mealTypes = preferences.meal_types || [];
        const calories = dailyTotals["208"].min || "";
        const protein = this.calcPercents(203, dailyTotals[203]);
        const carbs = this.calcPercents(205, dailyTotals[205]);
        const fats = this.calcPercents(204, dailyTotals[204]);
        const conditionName = conditions[0].name;

        return (
            <div className="summary">
                <div className='prescription'>
                    <label>Health Condition:</label>
                    <span>{conditionName}, {calories} calories (
                        {protein ? `${protein}% pro` : '--'},
                        {' '}
                        {carbs ? `${carbs}% carbs` : '--'},
                        {' '}
                        {fats ? `${fats}% fat` : '--'})
                    </span>
                </div>
                <div className='meal-details'>
                    {mealTypes.map((mealType) => {
                        const startTime = getStartTimeForMeal(mealType.begin);
                        return (
                            <div>
                                <label>{mealType.name}:</label>
                                <span>{startTime}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}
