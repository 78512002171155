'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import goodSources from '../../../tables/good-sources';

export default class GoodSource extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
    };

    toggleGoodSource = (key) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};

        if (params.filters[key]) {
            delete params.filters[key];
        } else {
            params.filters[key] = {gte: goodSources[key].v};
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;

        return (
            <div className="global-search-tag-group">
                <ul>
                    {Object.keys(goodSources).map(key => {
                        return (
                            <li key={key}>
                                <label className="global-search-checkbox">
                                    <input type="checkbox" checked={typeof params.filters[key] === 'object'}
                                           onChange={() => this.toggleGoodSource(key)} />
                                    <span>{goodSources[key].label}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
