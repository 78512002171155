'use strict';

import store from 'store';
import moment from 'moment';

import { fetchVirtualPlan } from '../../utils/Plans';

const RECENT_EDIT_KEY = 'recent-edits';
const RECENT_EDIT_TTL = 1209600; // 2 weeks (in seconds)

/**
 * Removes dead virtual plans from the list. Run each time.
 */
export function pruneDeadPlans(recents) {
    recents = recents || {};
    recents.plans = recents.plans || [];

    // This removes virtual meal plans that have expired and are no longer in memory
    recents.plans = recents.plans.filter(p => !p.virtual || fetchVirtualPlan(p.uuid));

    // This removes recently edited items older than 2 days
    recents.plans = recents.plans.filter(p => moment().subtract(2, 'day').isBefore(p.ts));

    return recents;
}

export function addRecentlyEditedPlan(plan, patient = null) {
    // Add ourselves at the top of the recently edited list
    const recents = store.get(RECENT_EDIT_KEY) || {plans: []};

    // If our plan is already in the list, remove it.
    recents.plans = recents.plans.filter(p => p.uuid !== plan.uuid);

    const recent = {uuid: plan.uuid, ts: moment().format()};

    if (!(plan.links && plan.links.self)) {
        recent.virtual = true;
    }

    if (patient) {
        recent.patient = patient.uuid;
    }

    // Prepend ourselves onto the front of the list
    recents.plans.unshift(recent);

    // And store back into localstorage.
    store.set(RECENT_EDIT_KEY, pruneDeadPlans(recents), moment().toDate().getTime() + RECENT_EDIT_TTL);
}

export function removeRecentlyEditedPlan(plan) {
    // Add ourselves at the top of the recently edited list
    const recents = store.get(RECENT_EDIT_KEY) || {plans: []};

    // If our plan is already in the list, remove it.
    recents.plans = recents.plans.filter(p => p.uuid !== plan.uuid);

    // And store back into localstorage.
    store.set(RECENT_EDIT_KEY, pruneDeadPlans(recents), moment().toDate().getTime() + RECENT_EDIT_TTL);
}

export function getRecentByPlan(plan) {
    const recents = pruneDeadPlans(store.get(RECENT_EDIT_KEY));

    if (!(recents && recents.plans)) {
        return false;
    }

    const found = recents.plans.filter(p => p.uuid === plan.uuid)[0] || false;

    return found ? true : false;
}

export function getRecentByPatient(patient) {
    const recents = pruneDeadPlans(store.get(RECENT_EDIT_KEY));

    if (!(recents && recents.plans)) {
        return [];
    }

    const myRecents = recents.plans.filter(p => p.patient === patient.uuid);

    return myRecents;
}
