const isSameArray = (a, b) => {
    return a.length === b.length && a.every((v, i) => v === b[i]);
};

export const isSameValue = (a, b) => {
    if (Array.isArray(a)) {
        return isSameArray(a, b);
    }

    if (a instanceof Date) {
        return new Date(a).getTime() == new Date(b).getTime();
    }

    return a == b;
};

export const isValidURL = (string) => {
    try {
        new URL(string);
        return true;
    } catch (e) {
        return false;
    }
};
