'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './NutritionPrescription.scss';
import allNutrients from '../../../tables/nutrients';
import nutrKeys from '../../../tables/nutrient-order';

export default class NutritionPrescription extends Component {
    static propTypes = {
    };

    renderPrescription = (prescription, key) => {
        const fodmapNutrNos = ['OLF', 'OLG', 'FRC', 'LAC', 'PLY', 'MAN', 'SOR'];

        const nutrNos = Object.keys(prescription.envelope).sort((a, b) => {
            const aI = nutrKeys.indexOf(a),
                  bI = nutrKeys.indexOf(b);

            if (aI > bI) return 1;
            if (aI < bI) return -1;
            return 0;
        });

        return (
            <div className="prescription-part" key={key}>
                <h5>{prescription.meal_type == 'all-day' ? 'Daily Totals' : (prescription.meal_type + ' Limits')}</h5>

                <ul className="nutrients-list">
                    {nutrNos.map(nutrNo => {
                        if (!allNutrients[nutrNo]) {
                            return null;
                        }

                        const nutrient = allNutrients[nutrNo];
                        const { min, max } = prescription.envelope[nutrNo];

                        if (fodmapNutrNos.includes(nutrNo)) {
                            if (max >= 0 && max <= 2) {
                                return (
                                    <li key={nutrNo}>
                                        <span>REDUCE <em>{nutrient.NutrDesc}</em></span>
                                    </li>
                                );
                            }

                            return (
                                <li key={nutrNo}>
                                    <span>REINTRODUCE <em>{nutrient.NutrDesc}</em></span>
                                </li>
                            );
                        }

                        if (typeof min === 'undefined' && typeof max === 'undefined') {
                            return (
                                <li key={nutrNo}>
                                    <span>Tracking <em>{nutrient.NutrDesc}</em> </span>
                                </li>
                            );
                        }

                        if ((typeof min === 'undefined' || min == 0) && max === 0) {
                            return (
                                <li key={nutrNo}>
                                    <span>NO <em>{nutrient.NutrDesc}</em> </span>
                                </li>
                            );
                        }

                        if ((typeof min === 'undefined' || min == 0) && max > 0) {
                            return (
                                <li key={nutrNo}>
                                    <span><em>{nutrient.NutrDesc}</em>: </span>
                                    {max} {nutrient.Units} MAX
                                </li>
                            );
                        }

                        if ((typeof max === 'undefined' || max === 0) && min > 0) {
                            return (
                                <li key={nutrNo}>
                                    <span><em>{nutrient.NutrDesc}</em>: </span>
                                    {min} {nutrient.Units} MIN
                                </li>
                            );
                        }

                        return (
                            <li key={nutrNo}>
                                <span><em>{nutrient.NutrDesc}</em>: </span>
                                {min} - {max} {nutrient.Units}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        const { patient, prescriptions } = this.props;

        if (patient && !(prescriptions && prescriptions.length)) {
            return (
                <div className="patient-nutrition-prescription">
                    <h3>No nutrition prescription has been made</h3>
                </div>
            );
        }

        return (
            <div className="patient-nutrition-prescription">
                <p><em>Portion: </em> {patient.portion}</p>
                {patient && patient.next_update ?
                    <p><em>Expires: </em> {moment(patient.next_update).format('MMM Do YYYY')}</p>
                : null}

                {prescriptions.map(this.renderPrescription)}
            </div>
        );
    }
}
