'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import './Footer.scss';
import { getConfig } from '../../../utils/Env';

export default class Footer extends Component {
    static contextTypes = {
        showLoginForm: PropTypes.func,

        hideRetrieveInvite: PropTypes.bool,
    };

    showRetrieveInvite = () => {
        const { showLoginForm } = this.context;

        return showLoginForm({
            defaultMode: 'retrieve-invite',
        });
    }

    render = () => {
        const { hideRetrieveInvite } = this.props;

        return (
            <footer className="site-footer hide-on-print">
                <div className="footer-container">
                    <header>
                        <div className="logo-container">
                            <img src="https://static.chewba.info/images/header-logo-white-2017-07-03.png" />
                            <span></span>
                            <p className="tagline">Find Health Through Food</p>
                        </div>
                    </header>

                    <div className="sections">
                        {!hideRetrieveInvite ?
                            <section className="section retrieve-invite">
                                <p>Referred by your gym, fitness or nutrition professional?</p>
                                <button className="retrieve-invite2-btn" onClick={this.showRetrieveInvite}>Retrieve Invitation</button>
                            </section>
                        : null}

                        <section className="section about">
                            <ul>
                                <li><em>ABOUT</em></li>
                                <li><Link to="/blog">EatLove Blog</Link></li>
                                <li><a href="/about">About Us</a></li>
                            </ul>
                        </section>

                        <section className="section support">
                            <ul>
                                <li><em>SUPPORT</em></li>
                                <li><a href="https://intercom.help/eatlove">FAQ</a></li>
                                <li><a href="mailto:support@eatlove.is">Contact Us</a></li>
                            </ul>
                        </section>

                        <section className="section for-professionals">
                            <ul>
                                <li><em>EATLOVE FOR PROFESSIONALS</em></li>
                                <li><Link to="/pro/medical">Medical & Dietetics Private Practice</Link></li>
                                <li><Link to="/pro/healthplan-payers-employers">Health Plans, Employers & Payers</Link></li>
                                <li><Link to="/pro/fitness">Fitness and Sports Teams</Link></li>
                                <li><Link to="/pro/medical-enterprise">Medical Groups and Health Systems</Link></li>
                                <li><Link to="/pro/retailers">Retailers</Link></li>
                                <li><Link to="/pro/food-industry">Restaurants & Food Industry</Link></li>
                                <li><Link to="/pro/api">Developer API</Link></li>
                            </ul>

                            <a href="mailto:support@eatlove.is"><button className="contact-sales2-btn">Contact Sales</button></a>
                        </section>

                        <section className="section legal">
                            <ul>
                                <li><em>LEGAL</em></li>
                                <li><Link to="/terms">Terms & Conditions</Link></li>
                                <li><Link to="/privacy">Privacy Statement</Link></li>
                            </ul>
                        </section>
                    </div>

                    <ul className="social-media">
                        <li>
                            <a href="https://www.facebook.com/EatLove-470948183063093/timeline/" target="_blank">
                                <i className="icon-facebook">
                                    <span className="assistive-text">Our Facebook Page</span>
                                </i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/eatloveis" target="_blank">
                                <i className="icon-twitter">
                                    <span className="assistive-text">Our Twitter Page</span>
                                </i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/eatlove.pro/" target="_blank">
                                <i className="icon-instagram">
                                    <span className="assistive-text">Our Instagram Page</span>
                                </i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.pinterest.com/eatloveis/" target="_blank">
                                <i className="icon-pinterest">
                                    <span className="assistive-text">Our Pinterest Page</span>
                                </i>
                            </a>
                        </li>
                        <li>
                            <a href="mailto:support@eatlove.is?subject=EatLove - contact us">
                                <i className="icon-email1">
                                    <span className="assistive-text">Contact Us</span>
                                </i>
                            </a>
                        </li>
                    </ul>

                    <footer>
                        <h6>Copyright {moment().format('YYYY')} EatLove&copy; All Rights Reserved</h6>
                    </footer>
                </div>
            </footer>
        );
    }
};
