
// unfortunately we have to overwrite the modal styles.
// leaving these empty strings is a hack that let's us write css that won't be overwritten
export default {
    overlay : {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: '100%',
        height: '100%',
        backgroundImage : 'url(https://static.chewba.info/images/overlay-dust-dark-30.png)',
        backgroundRepeat : 'repeat',
        backgroundSize : '4px',
        backgroundColor   : 'rgba(25, 25, 25, 0.75)',
        zIndex: 20
    },
    content : {
        position: 'fixed',
        top: '',
        left: '',
        right: '',
        bottom: '',
        backgroundColor: '#fff',
        overflow: 'hidden',
        border: 0,
        outline: 'none',
        padding: 0,
        zIndex: 21
    },
    largeSquareModal : {
        overlay : {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundColor   : 'rgba(25, 25, 25, 0.75)',
            zIndex: 10
        },
        content : {
            position: 'fixed',
            top: '',
            left: '',
            right: '',
            bottom: '',
            background: 'transparent',
            overflow: 'hidden',
            border: 0,
            borderRadius: '0',
            boxShadow: '1px 1px 5px 1px rgba(0,0,0,.3)',
            outline: 'none',
            padding: 0,
            zIndex: 11
        }
    },
    confirmDialog : {
        overlay : {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundImage : 'url(https://static.chewba.info/images/overlay-dust-dark-30.png)',
            backgroundRepeat : 'repeat',
            backgroundSize : '4px',
            backgroundColor   : 'rgba(25, 25, 25, 0.75)',
            zIndex: 20
        },
        content : {
            position: 'fixed',
            top: '',
            left: '',
            right: '',
            bottom: '',
            background: '#fff',
            overflow: 'hidden',
            // overflow: 'visible',
            border: 0,
            outline: 'none',
            padding: 0,
            zIndex: 22
        },
    },
    minimal: {
        overlay : {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            // backgroundColor: 'rgba(25, 25, 25, 0.75)',
            backgroundColor: 'none',
            zIndex: 20
        },
        content : {
            position: 'fixed',
            zIndex: 22
        },
    },

    minimalOverlay: {
        overlay : {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(25, 25, 25, 0.75)',
            // background: 'linear-gradient(top, rgba(0,0,0,1) 0%, rgba(0,0,0,0.65) 30%)',
            zIndex: 20
        },
        content : {
            position: 'fixed',
            zIndex: 22
        },
    },

}
