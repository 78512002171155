'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Preferences extends Component {
    static propTypes = {
        filterName: PropTypes.string,
    };

    static defaultProps = {
        filterName: 'preferences',
    };

    static contextTypes = {
        profile: PropTypes.object,
    };

    togglePreferences = () => {
        const { params, onChangeParams, filterName } = this.props;
        const { profile } = this.context;

        if (params.filters[filterName]) {
            delete params.filters[filterName];
            params.filters['!ingredient_tags'] = params.filters['!ingredient_tags']?.filter(avoid => !profile.preferences?.avoidances?.includes(avoid));
            params.filters['tags'] = params.filters['tags']?.filter(diet => !profile.preferences?.diets?.includes(diet));
        } else {
            // Add the preferences and any condition avoidances to the search
            params.filters[filterName] = profile ? profile.preferences : false;
            params.filters['tags'] = params.filters['tags'] || [];
            params.filters['!ingredient_tags'] = params.filters['!ingredient_tags'] || [];

            profile.preferences.diets.forEach(diet => {
                if (!params.filters['tags'].includes(diet))  {
                    params.filters['tags'].push(diet);
                }
            });
            profile.preferences.avoidances.forEach(avoid => {
                if (!params.filters['!ingredient_tags'].includes(avoid))  {
                    params.filters['!ingredient_tags'].push(avoid);
                }
            });
        }

        onChangeParams(params);
    }

    render() {
        const { profile } = this.context;
        const { children, params, filterName } = this.props;

        if (!profile?.completed?.includes('nutrition')) {
            return <span />
        }

        return (
            <span className="global-search-hideable-filter my-library-toggle">
                <label className="global-search-checkbox">
                    <input type="checkbox" checked={params.filters[filterName] ? true : false}
                           onChange={this.togglePreferences}/>
                    <span>{children}</span>
                </label>
            </span>
        );
    }
}
