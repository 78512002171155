'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LeftoversEnabled extends Component {
    clearSizes = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.sizes;
        delete params.filters.servings;

        onChangeParams(params, true);
    }

    render() {
        const { params = {} } = this.props

        if (!(params.filters && params.filters.sizes)) {
            return <span />;
        }

        return (
            <div className="param-item" onClick={this.clearSizes}>
                Best Family Size <i className="icon-close-x" />
            </div>
        );
    }
}
