'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { getResizedUrl } from '../../utils/Image';

const errorImage = 'https://static.chewba.info/images/5107d70f-d00e-4fc2-b3b9-1030e883fcd7.jpg';

export default class ImgResized extends Component {
    static propTypes = {
        src: PropTypes.string,
        noImgGeometry: PropTypes.bool,
    };

    static defaultProps = {
        src: '',
        noImgGeometry: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            error: false,
            loaded: false,
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.src !== this.props.src) {
            const { onUnload } = this.props;
            onUnload && onUnload();
        }
    }


    onError = () => {
        this.setState({error: true});
    }

    onLoad = (ev) => {
        this.setState({loaded: true});

        const { onLoad } = this.props;

        onLoad && onLoad(ev);
    }

    getNode = () => {
        return this.refs.image;
    }

    render() {
        const { src, className, width, height, debug, noImgGeometry, ...rest } = this.props;
        const { loaded, error } = this.state;

        const extProps = {};

        if (width && !noImgGeometry) {
            extProps.width = width;
        }

        if (height && !noImgGeometry) {
            extProps.height = height;
        }

        return (
            <img {...rest} {...extProps} onError={this.onError} onLoad={this.onLoad}
                data-original={src}
                ref="image" className={classNames(className, loaded ? 'img-loaded' : '')}
                src={getResizedUrl(error ? errorImage : src, width, height, debug)} />
        );
    }
}
