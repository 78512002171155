'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import LocalStorage from 'store';
import assign from 'object-assign';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import moment from 'moment';
import chunk from 'lodash.chunk';

import AuthStore from './AuthStore';
import UserStore from './UserStore';
import UserConstants from '../constants/UserConstants';
import PlanConstants from '../constants/PlanConstants';

const CHANGE_EVENT = 'change';
const LOCALSTORAGE_KEY = 'plan-store-delta-queue';

let _store = {
    plans: [],
    _indexByUuids: {},

    dateStart: null,
    dateEnd: null,
};

let _synchronizing = false;

function processLoadResponse(response) {
    if (!(response && response.elements)) {
        return;
    }

    response.elements.forEach(plan => {
        // Is this meal currently dirty in the stack? Don't overwrite it.
        const existingPlan = _store.plans.filter(p => p.uuid === plan.uuid)[0];

        // Don't reload dirty meals, they're currently dirty
        // or the server hasn't gotten to them yet
        if (existingPlan && existingPlan.updating) {
            return;
        }

        // Is this record currently being updated? Use that copy instead. Server will be updated later.
        const deltaQueue = (LocalStorage.get(LOCALSTORAGE_KEY) || []);
        const queuedUpdate = deltaQueue.filter(qplan => qplan.uuid === plan.uuid)[0];

        if (queuedUpdate) {
            plan = queuedUpdate;
        }

        if (typeof _store._indexByUuids[plan.uuid] == 'number') {
            // Update the record
            _store.plans[_store._indexByUuids[plan.uuid]] = plan;
        } else if (existingPlan) {
            let i = _store.plans.indexOf(existingPlan);

            if (i != -1) { // This should never not be true
                _store.plans[i] = plan;
                _store._indexByUuids[plan.uuid] = i;
            }
        } else {
            // Store the record in the store and update the ID index.
            let len = _store.plans.push(plan);

            _store._indexByUuids[plan.uuid] = len - 1;
        }
    });
}

function loadPlansByDateRange(dateStart, dateEnd) {

    const url = UserStore.getLinks().plans;
    const query = {
            startDate: moment(dateStart).format('YYYY-MM-DD'),
            endDate: moment(dateEnd).format('YYYY-MM-DD'),
    };

    return AuthStore.fetch({url, query}).then(processLoadResponse);
}

function updateIndex() {
    const idx = {};

    _store.plans.forEach((plan, i) => {
        idx[plan.uuid] = i;
    });

    _store._indexByUuids = idx;
}

function sendPlansToServer(plans) {
    if (_synchronizing) {
        return;
    }

    _synchronizing = true;

    // Unset the dirty flag on all these right away (so if they change again
    // before we get a response back, they'll be updated again on the next bounce)
    plans.forEach(p => p.updating = true);

    return AuthStore.fetch(UserStore.getLinks().plans, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=collection/plan/1'},
        body: JSON.stringify(plans.map(plan => omit(plan, 'links'))),
    }).then(
        (results) => {
            // This must be turned off before firing the secondary syncs (because
            // they'll turn it back on). Thank gods for single threaded JS.
            _synchronizing = false;

            plans.forEach(p => delete p.updating);

            // Remove these plans from the delta queue, we don't want to send that update again
            let deltaQueue = LocalStorage.get(LOCALSTORAGE_KEY) || [];
            plans.forEach(plan => {
                // We want to remove the FIRST instance of the plan in the queue, not any more than that.
                const qplan = deltaQueue.filter(qplan => qplan.uuid === plan.uuid);

                if (qplan) {
                    deltaQueue.splice(deltaQueue.indexOf(qplan), 1);
                }
            });
            LocalStorage.set(LOCALSTORAGE_KEY, deltaQueue);


            // Are there any dirty plan left in the queue? Immediately flush them (do not wait for debounce)
            realSynchronizePlans();
        },
        (error) => {
            _synchronizing = false;

            // We're no longer updating the meal, remove that flag.
            plans.forEach(p => delete p.updating);

            // Retry once after 5 seconds.
            setTimeout(realSynchronizePlans, 5000);
        }
    );
}

function deletePlansByUuid(uuids) {
    // Remove the plans item from the server too
    return AuthStore.fetch(UserStore.getLinks().plans, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json; schema=multi/delete/1'},
        body: JSON.stringify({uuids}),
    });
}

function realSynchronizePlans() {
    let dirtyPlans = (LocalStorage.get(LOCALSTORAGE_KEY) || []);

    if (dirtyPlans.length > 0) {
        sendPlansToServer(dirtyPlans);
    }
}


function ensureDateRangeLoaded(newDateStart, newDateEnd) {
    newDateStart = moment(newDateStart);
    newDateEnd   = moment(newDateEnd);

    // Is new date start BEFORE our current date start? Load from new date start to old date start.
    // Is new date end AFTER our current date end? Load from old date end to new date end.
    const needNewDateStart = !_store.dateStart || (_store.dateStart && newDateStart.isBefore(_store.dateStart, 'day')),
          needNewDateEnd   = !_store.dateEnd || (_store.dateEnd && newDateEnd.isAfter(_store.dateEnd, 'day'));

    if (needNewDateStart && needNewDateEnd) {
        // Reload everything between the new date start to end.
        _store.dateStart = newDateStart;
        _store.dateEnd = newDateEnd;

        return loadPlansByDateRange(newDateStart, newDateEnd);
    } else if (needNewDateStart && !needNewDateEnd) {
        const oldDateStart = _store.dateStart;

        _store.dateStart = newDateStart;

        // Reload everything from new date start to old date start
        return loadPlansByDateRange(newDateStart, oldDateStart);
    } else if (!needNewDateStart && needNewDateEnd) {

        const oldDateEnd = _store.dateEnd;

        _store.dateEnd = newDateEnd;

        // Reload everything from old date end to new date end
        return loadPlansByDateRange(oldDateEnd, newDateEnd);
    }

    return null;
}

const synchronizePlans = debounce(realSynchronizePlans, 1000);

var PlanStore = assign({}, EventEmitter.prototype, {
    getPlans: function() {
        return _store.plans;
    },

    isRangeLoaded: function(dateStart, dateEnd) {
        if (!_store.dateStart || !_store.dateEnd) {
            return false;
        }

        return _store.dateStart.isSameOrBefore(dateStart, 'day') &&
               _store.dateEnd.isSameOrAfter(dateEnd, 'day');
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
});

export default PlanStore;

AppDispatcher.register((payload) => {
    let plans = [];
    let promise = null;

    switch (payload.action.actionType) {
        case UserConstants.USER_COMPLETE_LOGIN:
            plans = payload.action.plans;

            if (plans) {
                const weekAgo = moment().subtract(1, 'week'),
                      inThreeWeeks = moment().add(3, 'week');

                _store.dateStart = _store.dateStart || weekAgo;
                _store.dateEnd = _store.dateEnd || inThreeWeeks;

                // Strip out any plans that might have been deleted on another device
                _store.plans = _store.plans.filter(plan => !(
                    (moment(plan.date_start).isBetween(weekAgo, inThreeWeeks, 'day')) ||
                    (moment(plan.date_end).isBetween(weekAgo, inThreeWeeks, 'day'))
                ))

                processLoadResponse({elements: plans});
                PlanStore.emitChange();
            }
            break;

        case UserConstants.USER_LOGOUT:
            _store.loaded = false;
            _store.plans = [];
            PlanStore.emitChange();
            break;

        case PlanConstants.PLANS_LOAD_BY_DATE:
            loadPlansByDateRange(payload.action.dateStart, payload.action.dateEnd).then(() => {
                PlanStore.emitChange();
            });
            break;

        case PlanConstants.PLANS_ENSURE_DATE_RANGE:
            promise = ensureDateRangeLoaded(
                payload.action.dateStart,
                payload.action.dateEnd
            );

            // If a change was made, a promise will be returned
            if (promise) {
                promise.then(() => {
                    PlanStore.emitChange();
                });
            }
            break;

        case PlanConstants.PLANS_UPSERT:
            plans = payload.action.plans;

            // And add them to the delta queue if they're not there already
            let deltaQueue = (LocalStorage.get(LOCALSTORAGE_KEY) || [])
            plans.forEach(plan => {
                // Find this plan by UUID in the deltaQueue
                const qplan = deltaQueue.filter(qplan => qplan.uuid === plan.uuid)[0];

                // Append to queue if: not already queued OR queued and already sent to server
                if (!qplan || (qplan && qplan.updating)) {
                    deltaQueue.push(plan);
                } else {
                    // Otherwise just update the existing entry (to be extra sure its in there)
                    const i = deltaQueue.indexOf(qplan);
                    deltaQueue[i] = plan;
                }
            });
            LocalStorage.set(LOCALSTORAGE_KEY, deltaQueue);

            // Prepend any new items to the plans store
            _store.plans = plans.filter(p => _store.plans.indexOf(p) == -1)
                                .concat(_store.plans);

            // Update the index
            updateIndex();

            // Emit an event change
            PlanStore.emitChange();

            // And trigger a sync to the server
            synchronizePlans();
            break;

        case PlanConstants.PLANS_DELETE:
            plans = payload.action.plans;

            const uuids = plans.map(p => p.uuid);

            // Remove these from our list
            _store.plans = _store.plans.filter(m => uuids.indexOf(m.uuid) == -1);

            // Update the index
            updateIndex();

            // Emit an event change
            PlanStore.emitChange();

            // Tell the server they're gone
            deletePlansByUuid(uuids);
            break;

        case PlanConstants.PLANS_FEED_REGEN:
            _store.plans = [];
            _store._indexByUuids = {};

            PlanStore.emitChange();
            break;

        // Meal freshening comes from the Grocery list resource.
        case PlanConstants.PLANS_HYDRATE:
            plans = payload.action.plans;

            // We'll use the index so be sure it's up-to-date
            updateIndex();

            plans.forEach(plan => {
                if (typeof _store._indexByUuids[plan.uuid] == 'number') {
                    _store.plans[_store._indexByUuids[plan.uuid]] = plan;
                } else {
                    _store.plans.push(plan);
                }
            });

            // Update the index once more
            updateIndex();
            // Emit an event change
            PlanStore.emitChange();

            break;
    }
});
