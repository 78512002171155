'use strict';

export function priceFormat(amount, showDec = false) {
    let dollars = Math.floor(amount / 100);
    let cents   = amount - dollars * 100;

    let total = dollars;

    if (cents > 0) {
        total += cents / 100;

        showDec = true;
    }

    return showDec ? total.toFixed(2) : total;
}

export function getInvoiceReceiptNumber(invoice) {
    const { charge } = invoice;

    if (invoice.receipt_number) {
        return invoice.receipt_number;
    }

    if (charge && charge.refunds && charge.refunds.data) {
        return charge.refunds.data.map(r => r.receipt_number).filter(v => v)[0] || '';
    }

    return '';
}

export function getInvoiceDiscount(invoice) {
    return {
        discount: invoice.total - invoice.subtotal - invoice.tax,
        coupon: (invoice.discount && invoice.discount.coupon) || false,
    };
}

export function getInvoiceBreakdown(invoice) {
    const { charge } = invoice;

    const receiptNumber = getInvoiceReceiptNumber(invoice, charge);

    if (charge && charge.amount_refunded > 0) {
        return {
            'mode': 'refund',
            'receipt_number': receiptNumber,
            'total': invoice.total,
            'amount_refunded': charge.amount_refunded,
            'adjusted_total': charge.amount - charge.amount_refunded,
        }
    }

    const { discount, coupon } = getInvoiceDiscount(invoice);

    // If there's no charge associated with this invoice, then we are simply previewing an invoice
    if (!charge) {
        return {
            mode: 'preview',
            total: invoice.total,
            tax: invoice.tax,
            subtotal: invoice.subtotal,
            amount_due: invoice.amount_due,
            discount,
            coupon,
        };
    }

    const breakdown = {
        mode: 'default',
        receipt_number: receiptNumber,
        subtotal: invoice.subtotal,
        total: invoice.total,
        tax: invoice.tax,
        paid: charge.amount,
        discount,
        coupon,
    };

    return breakdown;
}

export function getInvoiceChargeCard(invoice) {
    const { charge } = invoice;

    const map = {
        'Visa'            : {'type': 'visa',       'w': 36},
        'American Express': {'type': 'amex',       'w': 45},
        'MasterCard'      : {'type': 'mastercard', 'w': 75},
        'Discover'        : {'type': 'discover',   'w': 57},
        'JCB'             : {'type': 'jcb',        'w': 19},
        'Diners Club'     : {'type': 'diners',     'w': 20},
    };

    if (!(charge && charge.source && charge.source.brand)) {
        return false;
    }

    return map[charge.source.brand]
         ? map[charge.source.brand]
         : false;
}
