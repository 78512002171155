'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import EstimatorModal from '../Admin/Combos/EstimatorModal.react';
import UserStore from '../../stores/UserStore';
import allNutrients from '../../tables/nutrients';
import nutrientOrder from '../../tables/nutrient-order';
import './NutrientFilters.scss';

const nutrientsToFilter = [
    "208", // calories
    "203", // protein
    "204", // fat
    "205", // carbs
    "FRU", // fruit
    "VEG", // non-starchy vegetables
    "DAI", // dairy
    "ASG", // added sugar
    "307", // sodium
    "291", // fiber
    "606", // saturated fat
    "605", // trans fat
    "301", // calcium
    "303", // iron
    "305", // phosphorus
    "306", // potassium
    "401", // vitamin c
    "418", // vitamin b-12
    "435", // folate, dfe
    "304", // magnesium
    "404", // thiamin
    "406", // niacin
    "645", // monounsaturated fat
    "646", // polyunsaturated fat


    "OLF", // FODMAP Oligos-fructans
    "OLG", // FODMAP Oligos-GOS
    "FRC", // FODMAP Fructose
    "LAC", // FODMAP Lactose
    "MAN", // FODMAP Mannitol
    "SOR", // FODMAP Sorbitol

    "619", // Alpha-linolenic acid (undifferentiated)
    "621", // Omega-3 (DHA)
    "629", // Omega-3 (EPA)
    "851", // Omega-3 (ALA)
    "OM3", // Omega-3 (DHA+EPA)
    "OMA", // Omega-3 (DHA+EPA+ALA)

    "618", // Linoleic acid (undifferentiated)
    "672", // Omega 6 (Eicosadienoic acid)
    "675", // Omega 6 (Linoleic acid)
    "685", // Omega 6 (Gamma-linoleic acid)
    "853", // Omega 6 (Eicosatrienoic acid)
    "855", // Omega 6 (Eicosatetraenoic acid)

    "WHG", // whole grains
    "HLF", // healthy fats
    "LPR", // Healthy Proteins
    "LFY", // Leafy Greens
    "BER", // Berries
    "NUT", // Nuts/Seeds

    "221", // Alcohol,
    "NTC", // Net Carbs
].sort((a, b) => {
    if (nutrientOrder.indexOf(a) < nutrientOrder.indexOf(b)) return -1;
    if (nutrientOrder.indexOf(a) > nutrientOrder.indexOf(b)) return 1;
    return 0;
});

export default class NutrientFilters extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
        nutrientWhitelist: PropTypes.array,
        defaultShowAll: PropTypes.bool,
        enableEstimator: PropTypes.bool,
    };

    static defaultProps = {
        defaultShowAll: false,
        enableEstimator: false,
        nutrientWhitelist: [],
    };

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    }

    constructor(props) {
        super(props);

        let filters = props.params.filters || {};
        const user = UserStore.getUser();

        const typeable = {};

        nutrientsToFilter.forEach(nutrNo => {
            const nutrient = allNutrients[nutrNo];

            if (!filters[nutrient.Filter]) {
                typeable[nutrient.Filter] = {gte: '', lte: ''};
                return;
            }

            const { lte, gte } = filters[nutrient.Filter];

            typeable[nutrient.Filter] = {
                gte: typeof gte !== 'undefined' ? String(gte) : '',
                lte: typeof lte !== 'undefined' ? String(lte) : '',
            };
        });

        this.state = {
            typeable,
            params: props.params,
            showAll: props.defaultShowAll,
            conditions: [],
            activity_level: null,
            height_cm: '',
            weight_kg: '',
            units_mode: user ? user.units_mode : 'english',
            due_date: null,
            fetus_count: null,
            birthdate: null,
            gender: null,
            target_energy_kcal: null,
            mealType: null,
            preferences: {},
            mealTypes: []
        };

        this.resetInhibit = debounce(this.resetInhibit, 500);
    }

    inhibitUpdates = false;

    resetInhibit = () => {
        this.inhibitUpdates = false;
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (this.inhibitUpdates) {
            return;
        }

        let filters = nextProps.params.filters || {};
        const typeable = {};

        nutrientsToFilter.forEach(nutrNo => {
            const nutrient = allNutrients[nutrNo];

            if (!filters[nutrient.Filter]) {
                typeable[nutrient.Filter] = {gte: '', lte: ''};
                return;
            }

            const { lte, gte } = filters[nutrient.Filter];

            typeable[nutrient.Filter] = {
                gte: typeof gte !== 'undefined' ? String(gte) : '',
                lte: typeof lte !== 'undefined' ? String(lte) : '',
            };
        });

        this.setState({params: nextProps.params, typeable});
    }

    componentWillUnmount = () => {
    }

    onFieldFilterChange = (value, field, operator) => {
        const { typeable, params } = this.state;

        typeable[field][operator] = value;

        // Initialize structure if not already initialized
        params.filters = params.filters || {};
        params.filters[field] = params.filters[field] || {};

        if (value.length == 0) {
            // delete the value from the params object
            delete params.filters[field][operator];
        } else {
            // Convert to a double & add to the filters object
            params.filters[field][operator] = parseFloat(value);
        }

        // If the field object is empty, remove it too.
        if (Object.keys(params.filters[field]).length == 0) {
            delete params.filters[field];
        }

        this.inhibitUpdates = true;
        this.resetInhibit();
        this.setState({params: params, typeable: typeable}, this.updateParent);
    }

    updateParent = () => {
        const { onChangeParams } = this.props;

        onChangeParams(this.state.params);
    }

    closeModal = (success) => {
        this.setState({isEstimatorOpen: false});
    }

    openEstimator = () => {
        this.setState({isEstimatorOpen: true});
    }

    onChangeEnergy = (profile, mealType) => {
        const { params } = this.state;

        if (!mealType) {
            return;
        }

        Object.keys(allNutrients).forEach(nutrNo => {
            const nutrient = allNutrients[nutrNo];

            if (!nutrient.Filter) {
                return;
            }

            delete params.filters[nutrient.Filter];

            if (!mealType.envelope[nutrNo]) {
                return;
            }

            const range = mealType.envelope[nutrNo];
            const min = range.min ?? range.implicit ? range.implicit.min : null;
            const max = range.max ?? range.implicit ? range.implicit.max : null;

            if (typeof min === 'number') {
                params.filters[nutrient.Filter] = params.filters[nutrient.Filter] || {};
                params.filters[nutrient.Filter].gte = min;
            }

            if (typeof max === 'number') {
                params.filters[nutrient.Filter] = params.filters[nutrient.Filter] || {};
                params.filters[nutrient.Filter].lte = max;
            }
        });

        this.setState({
            params,
            mealType,
            ...profile
        }, this.updateParent);
    }

    renderTypeableRange(field) {
        const { typeable } = this.state;

        return (
            <div className="typeable-range">
                <label>
                    Min:
                    <input type="text"
                        value={typeable[field].gte || ''}
                        onChange={(ev) => this.onFieldFilterChange(ev.target.value, field, 'gte')} />
                </label>

                <label>
                    Max:
                    <input type="text"
                        value={typeable[field].lte || ''}
                        onChange={(ev) => this.onFieldFilterChange(ev.target.value, field, 'lte')} />
                </label>
            </div>
        );
    }

    renderEnergyEstimatorModal = () => {
        const {
            conditions,
            activity_level,
            height_cm,
            weight_kg,
            units_mode,
            due_date,
            fetus_count,
            birthdate,
            gender,
            target_energy_kcal,
            mealType,
            isEstimatorOpen,
        } = this.state;

        if (!isEstimatorOpen) {
            return null;
        }

        return <EstimatorModal
            conditions={conditions}
            activity_level={activity_level}
            height_cm={height_cm}
            weight_kg={weight_kg}
            units_mode={units_mode}
            due_date={due_date}
            fetus_count={fetus_count}
            birthdate={birthdate}
            gender={gender}
            target_energy_kcal={target_energy_kcal}
            mealType={mealType}
            onChangeValues={this.onChangeEnergy}
            closeModal={this.closeModal} />
    }

    render() {
        const { showAll } = this.state;
        const { enableEstimator, nutrientWhitelist } = this.props;

        return (
            <div className="nutrient-range-filters-container" data-showall={showAll}>
                <div className="nutrient-range-filters">
                    {nutrientsToFilter.filter(nutrNo => !nutrientWhitelist.length || (nutrientWhitelist.length && nutrientWhitelist.includes(nutrNo)))
                                      .map(nutrNo => {
                        const nutrient = allNutrients[nutrNo];

                        return (
                            <div key={nutrNo} className="nutrient-filter">
                                <label data-long={nutrNo == "VEG"}>{nutrient.NutrDesc} {nutrient.Units ? <em>({nutrient.Units})</em> : null}</label>
                                {this.renderTypeableRange(nutrient.Filter, parseFloat)}
                            </div>
                        );
                    })}

                    {enableEstimator ?
                        <button className="sub-action-btn" onClick={this.openEstimator}>open calculator</button>
                    : null}
                </div>

                <footer>
                    <button onClick={() => this.setState({showAll: !showAll})}>
                        {!showAll
                        ? <span>see all</span>
                        : <i className="icon-chevron-up" />}
                    </button>


                </footer>

                {this.renderEnergyEstimatorModal()}
            </div>
        );
    }
}
