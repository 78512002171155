'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Plugins } from '@capacitor/core';
import './CopyToClipboard.scss';

export default class CopyToClipboard extends Component {
    static propTypes = {
        value: PropTypes.string,
        btnCopyClassName: PropTypes.string,
        btnCopiedClassName: PropTypes.string,
        onClick: PropTypes.func
    };

    static defaultProps = {
        btnCopyClassName: 'copy',
        btnCopiedClassName: 'copy copied',
    };

    static contextTypes = {
        iPad: PropTypes.bool,
        iPhone: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            copied: false,
        };

        this.debounceResetCopied = debounce(this.resetCopied, 2500);
    }

    resetCopied = () => {
        this.setState({copied: false});
    }

    // Copy textarea, pre, div, etc.
    realCopyToClipboard = (el) => {
        const { iPad, iPhone } = this.context;
        const { value } = this.props;

        // iOS cordova app
        if (window.cordova && Plugins && Plugins.Clipboard) {
            Plugins.Clipboard.write({string: value})

            this.setState({copied: true}, this.debounceResetCopied);
            return;
        }

        // IE
        if (document.body.createTextRange) {
            var textRange = document.body.createTextRange();
            textRange.moveToElementText(el);
            textRange.select();
            textRange.execCommand("Copy");

            return true;
        }

        if (!window.getSelection && document.createRange) {
            return false;
        }

        // non-IE
        el.readOnly = false; // iOS will not select in a read only form element
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range); // Does not work for Firefox if a textarea or input

        if (el.nodeName == "TEXTAREA" || el.nodeName == "INPUT") {
            el.select(); // Firefox will only select a form element with select()
        }

        if (el.setSelectionRange && (iPad || iPhone)) {
            el.setSelectionRange(0, 999999); // iOS only selects "form" elements with SelectionRange
        }

        let result = false;
        if (document.queryCommandSupported("copy")) {
            result = document.execCommand('copy');
        }

        el.readOnly = true; // Restore previous readOnly status

        return result;
    }

    copyToClipboard = () => {
        const { onClick } = this.props;
        onClick && onClick();

        try {
            this.realCopyToClipboard(this.refs.input);

            this.setState({copied: true}, this.debounceResetCopied);
        } catch (err) {
        }
    }

    render = () => {
        const { value, inputClassName, btnCopyClassName, btnCopiedClassName } = this.props;
        const { copied } = this.state;

        return (
            <div className="copy-to-clipboard">
                <input className={inputClassName} readOnly type="text" ref="input" onClick={this.copyToClipboard} value={value || ''} />
                <button onClick={this.copyToClipboard} className={copied ? btnCopiedClassName : btnCopyClassName}>
                    {copied ? 'copied!' : 'copy'}
                </button>
            </div>
        );
    }
}
