'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../Dropdown.react';
import Expander from '../Expander.react';
import MealTypesFilter from '../Filters/MealTypes.react';
import FilterUnavailable from '../Filters/FilterUnavailable.react';

import allTags from '../../../tables/tags';

export default class MealTypes extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
        button: PropTypes.string,
    };

    static defaultProps = {
        isExpander: false,
        button: 'Dish Types',
    };

    render() {
        const { params, onChangeParams, isExpander, button } = this.props;
        const group = allTags.mealTypes;

        const filterTags = (params.filters && params.filters.tags) || [];
        const selectedTags = filterTags.filter(tag => group.tags.includes(tag));
        let badge = <em data-active={false} />;

        if (selectedTags.length > 0) {
            badge = <em data-active={true}>{selectedTags.length}</em>;
        }

        const filterBtn = (
            <span className="global-search-filter-btn">
                {button} {badge}
            </span>
        );

        const Component = isExpander ? Expander : Dropdown;

        return (
            <span className="global-search-hideable-filter" data-active={true}>
                <Component button={filterBtn}>
                    <MealTypesFilter {...this.props} tags={group.tags} />
                </Component>
            </span>
        );
    }
}
