'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Popup.scss';

export default class Popup extends Component {
    static propTypes = {
        positionClassName: PropTypes.string,
        defaultClassName: PropTypes.string,
        containerClassName: PropTypes.string,
        dropdownBtnClass: PropTypes.string,
        defaultDropdownBtnClass: PropTypes.string,

        onShowDropdown: PropTypes.func,
        onHideDropdown: PropTypes.func,
        isDefaultVisible: PropTypes.bool,
    };

    static defaultProps = {
        defaultClassName: 'el-popup',
        positionClassName: 'el-popup-top-center',
        containerClassName: 'el-popup-container',

        defaultDropdownBtnClass: 'dropdown-btn',

        isDefaultVisible: false,
        alwaysKeepOpen: false,
    };

    static childContextTypes = {
        toggleMenu: PropTypes.func,
        closeMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: props.isDefaultVisible,
            isDropdownClosing: false,
        };
    }

    static getDerivedStateFromProps(props, state) {

        if (props.isDropdownVisible == null) {
            return {};
        }

        if (props.isDropdownVisible !== state.isDropdownVisible) {
            return {
                isDropdownVisible: props.isDropdownVisible
            };
        }
        
        return {};
    }

    getChildContext = () => {
        return {
            toggleMenu: this.toggleDropdown,
            closeMenu: this.closeDropdown,
        };
    }

    listenForEvents = () => {
        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (this.state.isDropdownVisible) {
            window.addEventListener(`mousedown`, this.onOuterAction);
            window.addEventListener(`touchstart`, this.onOuterAction);
        } else {
            window.removeEventListener(`mousedown`, this.onOuterAction);
            window.removeEventListener(`touchstart`, this.onOuterAction);
        }
    }

    closingTimeout = null;

    closeDropdown = (timeout = false) => {
        const { onHideDropdown } = this.props;

        if (timeout === false) {
            this.setState({isDropdownVisible: false, isDropdownClosing: false}, this.listenForEvents);
            onHideDropdown && onHideDropdown();
        } else {
            this.setState({isDropdownClosing: true}, () => {
                if (this.closingTimeout) {
                    clearTimeout(this.closingTimeout);
                }

                this.closingTimeout = setTimeout(() => this.closeDropdown(false), timeout);
            });
        }
    }

    openDropdown = () => {
        if (this.closingTimeout) {
            clearTimeout(this.closingTimeout);
            this.closingTimeout = null;
        }

        this.setState({isDropdownVisible: true, isDropdownClosing: false}, this.listenForEvents);
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.dropdown.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeDropdown(0);
        }
    }

    toggleDropdown = () => {
        const { isDropdownVisible } = this.state;
        const { onShowDropdown, onHideDropdown } = this.props;
        if (isDropdownVisible) {
            this.closeDropdown(0);
        } else {
            this.openDropdown();
        }

        this.setState({isDropdownVisible: !isDropdownVisible, isDropdownClosing: false});

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isDropdownVisible) {
            onShowDropdown && onShowDropdown();
        } else {
            onHideDropdown && onHideDropdown();
        }
    }

    realizeContainer = (container) => {
        this.container = container;
    }

    realizeDropdown = (dropdown) => {
        this.dropdown = dropdown;
    }

    onClickButton =  (ev) => {
        const { onClick } = this.props;
        if (onClick) {
            return onClick(ev);
        }

        return this.toggleDropdown();
    }

    render() {
        let { isDropdownVisible } = this.state;
        const { button, children, className, positionClassName, defaultClassName, dropdownBtnClass,
                containerClassName, buttonTitle, defaultDropdownBtnClass, alwaysKeepOpen } = this.props;

        return (
            <span className={classNames(className, positionClassName, defaultClassName)}
                data-state={isDropdownVisible || alwaysKeepOpen}
                data-closing={false}
                data-hide-chevron={true}
                ref={this.realizeContainer}>
                <button className={classNames(defaultDropdownBtnClass, dropdownBtnClass)} onClick={this.onClickButton} title={buttonTitle}>
                    {button}
                </button>

                <div className="popup-dropdown" ref={this.realizeDropdown}>
                    <div className={containerClassName}>
                        <div className="triangle"></div>
                        <div className="triangle2"></div>
                        <div className="popup-content">
                            {!alwaysKeepOpen ?
                                <button className="close-btn" onClick={() => this.closeDropdown(0)}>
                                    <i className="icon-close-x" />
                                </button> : null}
                            {children}
                        </div>
                    </div>
                </div>
            </span>
        );
    }
}
