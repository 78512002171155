'use strict';

import keymirror from 'keymirror';

const MealConstants = keymirror({
    MEALS_LOAD_BY_DATE: null,
    MEALS_LOAD_BY_IDS: null,
    MEALS_UPSERT: null,
    MEALS_DELETE: null,
    MEALS_CLEAR: null,
    MEALS_ENSURE_DATE_RANGE: null,
    MEALS_HYDRATE: null,
    MEALS_FEED_REGEN: null,
    MEALS_UPSERT_WITHOUT_QUEUE: null
});

export default MealConstants;
