'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import debounce from 'lodash.debounce';

import './SearchKeywords.scss';

export default class SearchKeywords extends Component {
    static contextTypes = {
        isMobile: PropTypes.bool,
    };

    static propTypes = {
        placeholder: PropTypes.string,
        className: PropTypes.string,

        termsProp: PropTypes.string,

        defaultSortBy: PropTypes.string,
        defaultSortOrder: PropTypes.string,
    };

    static defaultProps = {
        placeholder: 'Search by keyword',
        className: 'search-keywords-filter',

        termsProp: 'terms',
        defaultSortBy: 'published',
        defaultSortOrder: '',
    };

    constructor(props) {
        super(props);

        const { params, termsProp } = props;

        this.state = {
            value: params[termsProp] || '',
        };

        this.debounceUpdateParent = debounce(this.updateParent, 500);
        this.resetInhibitUpdate = debounce(this.resetInhibitUpdate, 1000);
    }

    inhibitUpdate = false;

    resetInhibitUpdate = () => {
        this.inhibitUpdate = false;
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.inhibitUpdate) {
            return;
        }

        const { params, termsProp } = nextProps;

        // On publisher dashboard, if user cleared the terms, just clear them immediately
        if (!params[termsProp] && this.state.value) {
            this.setState({value: ''});
        }

        if (params[termsProp] && params[termsProp] !== this.state.value) {
            this.setState({value: params[termsProp]});
        }
    }

    updateParent = () => {
        const { params, termsProp, onChangeParams, defaultSortBy, defaultSortOrder } = this.props;

        const newParams = { ...params };

        newParams[termsProp] = this.state.value;

        if (newParams[termsProp]) {
            newParams.sort_by = 'relevance';
            delete newParams.sort_order;
        } else {
            newParams.sort_by = defaultSortBy;

            if (defaultSortOrder) {
                newParams.sort_order = defaultSortOrder;
            } else {
                delete newParams.sort_order; // use the default
            }
        }

        onChangeParams(newParams, true);
    }

    onFocus = (ev) => {
        const { onFocus } = this.props;

        onFocus && onFocus(ev);

        if (typeof this.input?.select === 'function') {
            this.input.select();
        }
    }

    onBlur = (ev) => {
        const { onBlur } = this.props;

        onBlur && onBlur(ev);
    }


    input = null

    realizeInput = (el) => {
        const { isMobile } = this.context;

        if (el && !this.input && !isMobile) {
            el.focus();
        }

        this.input = el;
    }

    onKeyUp = (ev) => {
        if (ev.key === 'Enter' && this.input) {
            this.input.blur();
            this.updateParent();
        }
    }

    onChangeSearch = (ev) => {
        const value = ev.target.value || '';

        this.setState({value}, () => {
            // don't update the parent on single or double charecter terms, but we do want to update if they clear the search field
            if (value.length > 0 && value.length <= 2) {
                return;
            }

            this.inhibitUpdate = true;
            this.resetInhibitUpdate();
            this.debounceUpdateParent();
        });
    }

    clearTerms = () => {
        this.setState({value: ''}, () => {
            this.updateParent();
            this.input && this.input.focus();
        });
    }

    render() {
        const { value } = this.state;
        const { placeholder, className } = this.props;

        return (
            <div className={className} data-has-terms={value ? true : false}>
                <input type="search" value={value} ref={this.realizeInput}
                    onChange={this.onChangeSearch}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onKeyUp={this.onKeyUp}
                    placeholder={placeholder} />

                <button className="clear-search-btn" onClick={this.clearTerms}><i className="icon-close-x" /></button>
            </div>
        );
    }
}
