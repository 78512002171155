'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class PlanMealCounts extends Component {

    static propTypes = {
        plan: PropTypes.object,
    };

    renderMealCounts = () => {
        const { plan } = this.props;

        let content = [];

        if (plan.breakfasts > 0) {
            content.push(plan.breakfasts + (plan.breakfasts === 1 ? ' b\'fast' : ' b\'fasts'));
        }

        if (plan.lunches > 0) {
            content.push(plan.lunches + (plan.lunches === 1 ? ' lunch' : ' lunches'));
        }

        if (plan.dinners > 0) {
            content.push(plan.dinners + (plan.dinners === 1 ? ' dinner' : ' dinners'));
        }

        //if (plan.others > 0) {
        //    content.push(plan.others + (plan.others === 1 ? ' other' : ' others'));
        //}

        return content.join(' • ');
    }

    render() {
        return (
            <h6>
                {this.renderMealCounts()}
            </h6>
        );
    }
}
