'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class PlanSizeParams extends Component {
    static propTypes = {
    };

    removePlanSize = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.breakfasts;
        delete params.filters.lunches;
        delete params.filters.dinners;

        onChangeParams(params);
    }


    getDayCount = (params) => {
        const { filters = {} } = params;

        let fs = [
            filters.breakfasts,
            filters.lunches,
            filters.dinners
        ]
        .filter(v => v && v.gte > 0)[0];

        return fs ? fs.gte : false;
    }

    smartList = (array) => {
        if (array.length == 1) {
            return array.join('');
        }

        const last = array.pop();

        return array.join(', ') + ' and ' + last;
    }

    render() {
        const { params } = this.props;
        const dayCount = this.getDayCount(params);
        const mealTypesIncluded = [];

        if (!dayCount) {
            return <span />
        }

        if (params.filters && params.filters.breakfasts && params.filters.breakfasts.gte > 0) {
            mealTypesIncluded.push(dayCount == 1 ? 'Breakfast' : 'Breakfasts');
        }

        if (params.filters && params.filters.lunches && params.filters.lunches.gte > 0) {
            mealTypesIncluded.push(dayCount == 1 ? 'Lunch' : 'Lunches');
        }

        if (params.filters && params.filters.dinners && params.filters.dinners.gte > 0) {
            mealTypesIncluded.push(dayCount == 1 ? 'Dinner' : 'Dinners');
        }

        return (
            <div className="param-item" onClick={this.removePlanSize}>
                {dayCount} day plans with {this.smartList(mealTypesIncluded)} <i className="icon-close-x" />
            </div>
        );
    }
}
