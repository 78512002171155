'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './TastePreferences.scss';
import '../../components/MyAccount/PreferencesForm.scss';
import allTags from '../../tables/tags';


export default class TastePreferences extends Component {
    static propTypes = {
        user: PropTypes.object,
        preferences: PropTypes.object,
        family: PropTypes.array,
        onChangePreferences: PropTypes.func,
        hideHeaderText: PropTypes.bool,
        showCuisine: PropTypes.bool,
    };

    static contextTypes = {
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    }

    onChangePrefersMilk = (milk) => {
        const { preferences, onChangePreferences } = this.props;

        preferences.prefer_milks = preferences.prefer_milks || [];

        if (!preferences.prefer_milks.includes(milk)) {
            preferences.prefer_milks.push(milk);
        } else {
            preferences.prefer_milks.splice(preferences.prefer_milks.indexOf(milk), 1);
        }
        onChangePreferences(null, preferences);
    }

    onChangePrefersRice = (rice) => {
        const { preferences, onChangePreferences } = this.props;

        preferences.prefer_rices = preferences.prefer_rices || [];

        if (!preferences.prefer_rices.includes(rice)) {
            preferences.prefer_rices.push(rice);
        } else {
            preferences.prefer_rices.splice(preferences.prefer_rices.indexOf(rice), 1);
        }

        onChangePreferences(null, preferences);
    }

    onChangePrefersCuisines = (cuisine) => {
        const { preferences, onChangePreferences } = this.props;

        preferences.prefer_cuisines = preferences.prefer_cuisines || [];

        if (!preferences.prefer_cuisines.includes(cuisine)) {
            preferences.prefer_cuisines.push(cuisine);
        } else {
            preferences.prefer_cuisines.splice(preferences.prefer_cuisines.indexOf(cuisine), 1);
        }

        onChangePreferences(null, preferences);
    }

    render() {
        const { isPro } = this.context;
        const { preferences, hideHeaderText, showCuisine, user} = this.props;
        const { cuisine } = allTags;
        const { avoidances = [], prefer_milks = [], prefer_rices = [], prefer_cuisines =[] } = preferences;
        const sectionHeadingClass = !isPro ? "section-heading" : null;

        return (
            <div className="preferences-form taste-preferences">
                {!hideHeaderText ? <p>Let us know your preferences and we'll do our best to accommodate your requests. At EatLove, we want you to try new foods!</p> : null}
                {showCuisine ? <h3 className={sectionHeadingClass}>Cuisine</h3> : null}
                {showCuisine ? <p className="subtext" data-pro={isPro}>Select one or more cuisine preferences.</p> : null}
                {showCuisine ?
                    <p className="subtext" data-pro={isPro}>
                        {isPro && user ? `${user.first_name || user.name} may be shown other cuisines when availability is limited.` : "You may be shown other cuisines when availability is limited."}
                    </p>
                : null}
                {showCuisine ?
                    <div className="preference-group">
                        {cuisine.tags.map((tag, key) => {
                            return (
                                <button key={key} className="toggle-btn" data-active={prefer_cuisines.includes(tag)} onClick={() => this.onChangePrefersCuisines(tag)}>
                                    {tag}
                                </button>
                            );
                        })}
                    </div>
                 : null}

                <h3 className={sectionHeadingClass}>Milk</h3>
                <p className="subtext" data-pro={isPro}>Select one or more milk preferences.</p>
                <p className="subtext" data-pro={isPro}>
                    {isPro && user ? `${user.first_name || user.name} may be shown other milks when availability is limited.` : "You may be shown other milks when availability is limited."}
                </p>
                <div className="preference-group">
                    {!avoidances.includes('milk') ?
                        <button className="toggle-btn" data-active={prefer_milks.includes('Cow (Whole)')} onClick={() => this.onChangePrefersMilk('Cow (Whole)')}>
                            Cow (Whole)
                        </button>
                    : null}
                    {!avoidances.includes('milk') ?
                        <button className="toggle-btn" data-active={prefer_milks.includes('Cow (Low Fat)')} onClick={() => this.onChangePrefersMilk('Cow (Low Fat)')}>
                            Cow (Low Fat)
                        </button>
                    : null}
                    {!avoidances.includes('milk') ?
                        <button className="toggle-btn" data-active={prefer_milks.includes('Cow (Skim)')} onClick={() => this.onChangePrefersMilk('Cow (Skim)')}>
                            Cow (Skim)
                        </button>
                    : null}
                    {!avoidances.includes('treenuts') ?
                        <button className="toggle-btn" data-active={prefer_milks.includes('Almond Milk')} onClick={() => this.onChangePrefersMilk('Almond Milk')}>
                            Almond
                        </button>
                    : null}
                    <button className="toggle-btn" data-active={prefer_milks.includes('Rice Milk')} onClick={() => this.onChangePrefersMilk('Rice Milk')}>
                        Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_milks.includes('Coconut Milk')} onClick={() => this.onChangePrefersMilk('Coconut Milk')}>
                        Coconut
                    </button>
                    <button className="toggle-btn" data-active={prefer_milks.includes('Oat Milk')} onClick={() => this.onChangePrefersMilk('Oat Milk')}>
                        Oat
                    </button>
                    <button className="toggle-btn" data-active={prefer_milks.includes('Hemp Milk')} onClick={() => this.onChangePrefersMilk('Hemp Milk')}>
                        Hemp
                    </button>
                    <button className="toggle-btn" data-active={prefer_milks.includes('Cashew Milk')} onClick={() => this.onChangePrefersMilk('Cashew Milk')}>
                        Cashew
                    </button>
                    <button className="toggle-btn" data-active={prefer_milks.includes('A2 Milk')} onClick={() => this.onChangePrefersMilk('A2 Milk')}>
                        A2
                    </button>
                    {!avoidances.includes('soy') ?
                        <button className="toggle-btn" data-active={prefer_milks.includes('Soy Milk')} onClick={() => this.onChangePrefersMilk('Soy Milk')}>
                            Soy
                        </button>
                    : null}
                </div>

                <h3 className={sectionHeadingClass}>Rice</h3>
                <p className="subtext" data-pro={isPro}>Select one or more rice preferences.</p>
                <p className="subtext" data-pro={isPro}>
                    {isPro && user ? `${user.first_name || user.name} may be shown other rice when availability is limited.` : "You may be shown other rice when availability is limited."}
                </p>
                <div className="preference-group">
                    <button className="toggle-btn" data-active={prefer_rices.includes('White Rice')} onClick={() => this.onChangePrefersRice('White Rice')}>
                        White Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_rices.includes('Brown Rice')} onClick={() => this.onChangePrefersRice('Brown Rice')}>
                        Brown Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_rices.includes('Basmati Rice')} onClick={() => this.onChangePrefersRice('Basmati Rice')}>
                        Basmati Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_rices.includes('Wild Rice')} onClick={() => this.onChangePrefersRice('Wild Rice')}>
                        Wild Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_rices.includes('Arborio Rice')} onClick={() => this.onChangePrefersRice('Arborio Rice')}>
                        Arborio Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_rices.includes('Jasmine Rice')} onClick={() => this.onChangePrefersRice('Jasmine Rice')}>
                        Jasmine Rice
                    </button>
                    <button className="toggle-btn" data-active={prefer_rices.includes('Black Rice')} onClick={() => this.onChangePrefersRice('Black Rice')}>
                        Black Rice
                    </button>
                </div>
            </div>
        );
    }
}
