'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import { getDemographic } from '../../../../utils/Nutrition';
import { roundForHumans } from '../../../../utils/Math';
import { getNutrientsToDisplay } from '../../../utils/Patients';
import NutritionInfo from '../../../../components/Products/NutritionInfo.react';

import allNutrients from '../../../../tables/nutrients';
import allRecommendations from '../../../../tables/recommendations';
import nutrKeys from '../../../../tables/nutrient-order';

import './MenuStats.scss';

export default class MenuStats extends Component {

    static contextTypes = {
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            isExpanded: false,
        }
    }

    render = () => {
        const { isExpanded } = this.state;
        const { plan, averages, user, profile, showGroceries, showNutrition } = this.props;

        return (
            <div className="plan-editor-stats" data-showall={isExpanded} data-collapsible={true}>
                <div>

                    <div>
                        <button className="sub-action-btn view-daily-nutrition-btn"
                            onClick={showNutrition}>
                            Daily Nutrition Stats
                        </button>

                        {user ?
                            <button className="sub-action-btn preview-groceries-btn"
                                onClick={showGroceries}>
                                Preview Grocery List
                            </button>
                        : null}

                    </div>
                </div>

                <NutritionInfo nutrients={averages} profile={{...profile, portion: 1}} />

                <section className="other-insights">
                    <div className="insight">
                        <em>{Math.round(plan.hands_on_time / 60)}<span>min</span></em>
                        <p>daily avg prep time</p>
                    </div>

                    <div className="insight">
                        <em>${(plan.avg_cost_per_serving || 0).toFixed(2)}</em>
                        <p>Per serving avg cost</p>
                    </div>

                    <div className="insight groceries" onClick={user ? showGroceries : null}>
                        <em>{plan.total_fresh}</em>
                        <p># of fresh ingredients</p>
                    </div>

                    <div className="insight groceries" onClick={user ? showGroceries : null}>
                        <em>{plan.total_pantry}</em>
                        <p># of pantry ingredients</p>
                    </div>

                    <div className="insight">
                        <em>{Math.round((1 - plan.efficiency_score) * 100)}%</em>
                        <p>Produce <br />Waste</p>
                    </div>
                </section>
                
            </div>
        );
    }
}
