'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import TaxonomyConstants from '../constants/TaxonomyConstants';

const TaxonomyActions = {
    ensureLoaded: (language = 'en') => {
        setTimeout(() => {
            AppDispatcher.handleViewAction({actionType: TaxonomyConstants.TAXONOMY_ENSURE_LOADED, language});
        });
    },

    upsertCategory: function(category, language = 'en') {
        AppDispatcher.handleViewAction({
            actionType: TaxonomyConstants.TAXONOMY_UPSERT,
            category, language
        });
    },

    deleteCategory: function(category, language = 'en') {
        AppDispatcher.handleViewAction({
            actionType: TaxonomyConstants.TAXONOMY_DELETE,
            category, language
        });
    },
};

export default TaxonomyActions;
