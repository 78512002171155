'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './PercentEntry.scss';

export default class PercentEntry extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        requiresUpgrade: PropTypes.bool,
    };

    static defaultProps = {
        disabled: false,
        requiresUpgrade: false
    };

    static contextTypes = {
        showUpgradeForm: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: false,
        };
    }

    container = null;
    dropdown = null;

    realizeContainer = (container) => {
        this.container = container;
    }

    realizeDropdown = (dropdown) => {
        this.dropdown = dropdown;
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.dropdown.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeDropdown();
        }
    }

    closeDropdown = () => {
        this.setState({isDropdownVisible: false});

        if (!process.browser) {
            return;
        }

        window.removeEventListener('mousedown', this.onOuterAction);
        window.removeEventListener('touchstart', this.onOuterAction);
        window.removeEventListener('keyup', this.onOuterAction);
    }

    coolDown = false;

    toggleDropdown = (immediate) => {
        const { isDropdownVisible } = this.state;

        if (immediate !== true && this.coolDown) {
            return;
        }

        this.coolDown = true;
        setTimeout(() => this.coolDown = false, 1500);

        this.setState({isDropdownVisible: !isDropdownVisible});

        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isDropdownVisible) {
            window.addEventListener('mousedown', this.onOuterAction);
            window.addEventListener('touchstart', this.onOuterAction);
            window.addEventListener('keyup', this.onOuterAction);

            this.refs.input.focus();
        } else {
            window.removeEventListener('mousedown', this.onOuterAction);
            window.removeEventListener('touchstart', this.onOuterAction);
            window.removeEventListener('keyup', this.onOuterAction);
        }
    }

    resolveConflict = (condition, value) => {
        const { resolveConflict } = this.props;

        resolveConflict(condition, value);

        this.closeDropdown();
    }

    renderDropdown = () => {
        const { isDropdownVisible } = this.state;

        return (
            <div className="drop-down" ref={this.realizeDropdown}>
                <button className="close-btn" onClick={() => this.toggleDropdown(true)}>
                    <i className="icon-close-x" />
                    <span className="assistive-text">Close Modal</span>
                </button>

                <h6>Calories and CHO/PRO/FAT</h6>

                <p>Plans can only be built when macro limits are balanced with calorie limits. To keep calories balanced, all macro percentages must add up to 100%.</p>
                <p>If you lower one, you must increase the others by the same amount and vice-versa.</p>
            </div>
        );
    }

    render() {
        const { showUpgradeForm } = this.context;
        const { isDropdownVisible } = this.state;
        const { value, className, onChange, mode, modeToggle, isError, disabled, requiresUpgrade } = this.props;

        return (
            <div className={classNames("entry-container percent-entry", className)}
                data-dropdown={isDropdownVisible}
                data-mode={mode}
                ref={this.realizeContainer}
                onClick={requiresUpgrade ? () => showUpgradeForm({feature: 'edit_micros'}) : this.toggleDropdown}>
                <input type="number" value={value}
                    ref="input" disabled={disabled}
                    className="numeric-entry"
                    data-error={isError}
                    min={0}
                    step={mode === 'g/kg' ? 0.1 : 1}
                    onFocus={this.toggleDropdown}
                    onChange={ev => onChange(ev.target.value)} />

                <span className="mode-entry-suffix">%</span>
                <button className="mode-toggle" onClick={modeToggle}
                    title={mode === 'kcal' ? 'Click to switch to g/kg' : 'Click to switch to %kcal'}>
                    {mode}
                </button>
                {this.renderDropdown()}
            </div>

        );
    }
}
