'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import ImgResized from '../../Widgets/ImgResized.react';
import testimonials from '../../../tables/testimonials';

export default class TestimonialContainer extends Component {
    static propTypes = {
        title: PropTypes.string,
        practice_type: PropTypes.string,
        mobileVersion: PropTypes.bool,
    };

    static defaultProps = {
        title: "Here is why people love Eatlove PRO premium features",
    };

    constructor(props) {
        super(props);
        this.shuffleArray(testimonials[props.practice_type]);
        testimonials[props.practice_type].splice(2);
    }

    //Fisher-Yates
    shuffleArray = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    render() {
        const { title, mobileVersion, practice_type } = this.props;

        const testimonialList = testimonials[practice_type];

        return (
           <div className={(mobileVersion ? "mobile-" : "") + "side-testimonial-container"}>
               <h2>{title}</h2>
               <div>
               {testimonialList.map((testimonial, index) =>
                   {return (
                        <div key={index}>
                            {testimonial.text.map((paragraph, index) => <p key={index}>{paragraph}</p>)}
                            <span>
                                <ImgResized className="avatar" src={testimonial.user.photo} width="154" height="154"/>
                                <div className="dietitian-title">
                                  <p>{testimonial.user.name}</p>
                                  <p>{testimonial.user.location}</p>
                                </div>
                            </span>
                        </div>
                   )}
               )}
               </div>
            </div>
        );
    }
}
