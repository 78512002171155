'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './FodmapEntryModal.scss';

import allNutrients from '../../../../tables/nutrients';

const fodmapNutrNos = [
    'FRC',
    'LAC',
    'MAN',
    'SOR',
    'OLG',
    'OLF',
    // 'PLY',
];

export default class NutrientEditor extends Component {

    constructor(props) {
        super(props);

        const { servingSize, values, available } = props;

        const fodmaps = {};

        // determine the warning level for each amount
        fodmapNutrNos.forEach(nutrNo => {
            let size = servingSize, level = 0;

            if (available[nutrNo] && values[nutrNo] == 0) {
                size = servingSize;
                level = 0;
            } else if (available[nutrNo] && values[nutrNo] > 0) {
                level = 1;
                size = servingSize * level / values[nutrNo];
            } else if (!available[nutrNo]) {
                level = 0;
                size = '';
            }

            fodmaps[nutrNo] = {size, level};
        });

        this.state = {
            fodmaps,
        };
    }

    onChangeNutrientValue = (nutrNo, ev) => {
        const { fodmaps } = this.state;

        fodmaps[nutrNo].size = ev.target.value;

        if (fodmaps[nutrNo].size && !fodmaps[nutrNo].level) {
            fodmaps[nutrNo].level = 1;
        } else if (!fodmaps[nutrNo].size && fodmaps[nutrNo].level) {
            fodmaps[nutrNo].level = 0;
        }

        this.setState({fodmaps});
    }

    toggleLevel = (nutrNo) => {
        const { fodmaps } = this.state;
        const { servingSize } = this.props;

        if (fodmaps[nutrNo].level == 0 && typeof fodmaps[nutrNo].size === 'string' && !fodmaps[nutrNo].size) {
            fodmaps[nutrNo].size = servingSize;
            fodmaps[nutrNo].level = 0;
        } else if (fodmaps[nutrNo].level == 0) {
            fodmaps[nutrNo].level = 1;
        } else if (fodmaps[nutrNo].level == 1) {
            fodmaps[nutrNo].level = 2;
        } else if (fodmaps[nutrNo].level == 2) {
            fodmaps[nutrNo].level = 0;
        }

        this.setState({fodmaps});
    }

    apply = () => {
        const { onComplete, values, available, servingSize } = this.props;
        const { fodmaps } = this.state;

        // overwrite the actual nutrient amount for each fodmap
        fodmapNutrNos.forEach(nutrNo => {
            if (typeof fodmaps[nutrNo].size === 'string' && fodmaps[nutrNo].size.length == 0) {
                available[nutrNo] = false;
            } else if (fodmaps[nutrNo].size > 0) {
                values[nutrNo] = fodmaps[nutrNo].level / fodmaps[nutrNo].size * servingSize;
                available[nutrNo] = true;
            } else if (fodmaps[nutrNo].size == 0) {
                values[nutrNo] = 0;
                available[nutrNo] = true;
            }
        });

        onComplete(values, available);
    }

    render = () => {
        const { food, closeModal, servingUnit, servingSize } = this.props;
        const { fodmaps } = this.state;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Household Member Editor"
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal2-container fodmap-entry-modal-container">
                    <header>
                        <h2>Enter FODMAPs: <em>{food.name} ({servingSize}{servingUnit} per serving)</em></h2>
                    </header>
                    <div className="el-modal-body-container el-modal2-body-container el-fonts">
                        {fodmapNutrNos.map(nutrNo => {
                            return (
                                <div key={nutrNo} className="el-labeled-input fodmap-entry">
                                    <label>{allNutrients[nutrNo].NutrDesc}</label>

                                    <input type="number" value={fodmaps[nutrNo].size} onChange={(ev) => this.onChangeNutrientValue(nutrNo, ev)} /> {servingUnit}

                                    <button className="level-btn" data-level={fodmaps[nutrNo].level} onClick={() => this.toggleLevel(nutrNo)}>&nbsp;</button>
                                </div>
                            );
                        })}
                    </div>

                    <footer>
                        <button className="el-modal-cancel-btn" onClick={closeModal}>close</button>
                        <button className="el-modal-ok-btn" onClick={this.apply}>save</button>
                    </footer>
                </div>
            </Modal>
        )
    }
}
