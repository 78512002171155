'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import moment from 'moment';
import { Link } from 'react-router';

import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import UserActions from '../../actions/UserActions';
import ChatStore from '../../stores/ChatStore';
import StripePaymentCardEntry from './StripePaymentCardEntry.react';
import Invoice from './Invoice.react';

import PermitPicker from './UpgradeMembership/PermitPicker.react';
import TestimonialContainer from './UpgradeMembership/TestimonialContainer.react';
import ImgResized from '../Widgets/ImgResized.react';

import { getConfig, getMref } from '../../utils/Env';

import allFeatures from '../../tables/features';

import Analytics from '../../utils/Analytics';

import './UpgradeMembershipModal.scss';

export default class UpgradeMembershipModal extends Component {
    static propTypes = {
        feature: PropTypes.string,
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
        viewportWidth: PropTypes.number,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const user = UserStore.getUser();

        this.state = {
            user,

            loading: false,
            permits: null,
            validPermits: null, // the subset of permits that satisfy the feature requested.
            selected: null,
            mode: 'offers',
            customer: null, // stripe customer
            resourceCounts: null,
            training_count: 0,
            coupon: '',
            addCouponMode: false,
        };
    }

    componentDidMount = () => {
        this.loadAssets();
    }

    onAddCoupon = () => {
        const { preview, cardError, selected, stripeToken, coupon, referred_by, training_count } = this.state;
        this.loadInvoicePreview(selected, stripeToken, coupon, referred_by, training_count)
        this.setState({addCouponMode: false, coupon: ''});
    }

    onChangeCoupon = (ev) => {
        this.setState({coupon: ev.target.value});
    }

    setAddCouponMode = () => {
        this.setState({addCouponMode: true})
    }

    onChangeCard = (permit) => {
        this.setState({mode: 'card-capture'});
    }

    getPatientCount = (user) => {
        const { practice, dietitian } = user;

        if (!(practice && dietitian && practice.links && practice.links.patients)) {
            return;
        }

        // We don't want any data back, we just want the total number of patients in this practice
        return new Promise((accept, reject) => {
            AuthStore.fetch({url: getConfig('users_api') + practice.links.patients.href, query: {size: 0}}).then(
                response => accept(response.total),
                error => reject(error),
            );
        });
    }

    getDietitianCount = (user) => {
        const { practice, dietitian } = user;

        if (!(practice && dietitian && practice.links && practice.links.patients)) {
            return;
        }

        return new Promise((accept, reject) => {
            AuthStore.fetch({url: getConfig('users_api') + practice.links.dietitians.href, query: {size: 0}}).then(
                response => accept(response.total),
                error => reject(error),
            );
        });
    }

    loadAssets = async () => {
        const { user } = this.state;
        const { feature } = this.props;

        if (!user) {
            // we can't load permits for non-users
            return this.setState({permits: null, loading: false, customer: null});
        }

        try {
            this.setState({loading: 'offers'});

            if (!user.capabilities || !user.capabilities.purchase_membership) {
                Analytics.unableToPurchase({'Feature': feature});
                this.setState({loading: false});
                return;
            }

            const resourceCounts = {
                create_patient: await this.getPatientCount(user),
                create_dietitian: await this.getDietitianCount(user),
            };

            // Are we requesting adding another one of our limited resources? Add one to that resource count.
            if (typeof resourceCounts[feature] === 'number') {
                resourceCounts[feature]++;
            }

            const { permits, validPermits, invalidPermits } = await this.loadPermits(resourceCounts);
            const { customer, subscription, card } = await this.loadCustomer();
            const { referral, defaultMref } = await this.loadReferral();

            if (permits.length > 0) {
                Analytics.showUpgrade({
                    "Valid Permits": validPermits.length,
                    "Invalid Permits":  invalidPermits.length,
                    "Feature": feature,
                });
            } else {
                Analytics.noMembershipsAvailable();
            }

            this.setState({
                permits,
                validPermits,
                customer, subscription, card,
                resourceCounts,
                referral, defaultMref,
                loading: false,
            });
        } catch (error) {
            this.setState({error: (error && error.message) || error || 'unknown error', loading: false});
        }
    }

    loadCustomer = async () => {
        const { user } = this.state;
        const { links: { subscription } } = user;
        let customer;

        try {
            const response = await AuthStore.fetch(getConfig('users_api') + subscription.href);
            customer = response.customer;
        } catch (error) {
            return {customer: null, subscription: null, card: null};
        }

        if (!customer) {
            return {customer: null, subscription: null, card: null};
        }

        let card = customer.default_source && customer.sources
                 ? customer.sources.data.find(c => c.id === customer.default_source)
                 : null;

        let subscriptionObject = (customer.subscriptions && customer.subscriptions.data && customer.subscriptions.data[0]);

        return { customer, subscription: subscriptionObject, card };
    }

    loadReferral = async () => {
        const { user } = this.state;

        const defaultMref = (user && user.practice && user.practice.referred_by) ||
                            (user && user.merchant_ref) ||
                            (getMref());

        if (!defaultMref) {
            return {defaultMref, referral: null};
        }

        const url = getConfig('users_api') + '/referrers',
              query = {mref: defaultMref}

        try {
            const referral = await AuthStore.fetch({url, query}, null, true);

            return { referral, defaultMref };
        } catch (error) {
            return {defaultMref, referral: null};
        }
    }

    loadPermits = async (resourceCounts) => {
        const { user } = this.state;
        const { feature } = this.props;

        const { capabilities } = user;

        const request = {
            url: getConfig('users_api') + '/permits',
            query: {
                upt: user.practice_type,
            },
        };

        // Get all permits
        const results = await AuthStore.fetch(request);

        // Which permits allow the feature the user has requested?
        const permits = results.elements;

        permits.forEach(permit => {
            if (!permit.price_usd) {
                return;
            }

            permit.price_monthly_usd = permit.price_usd / (
                (permit.billing_period === 'monthly' && 1) ||
                (permit.billing_period === 'quarterly' && 3) ||
                (permit.billing_period === 'yearly' && 12)
            );
        })

        // Sort by which ones have the least monthly cost.
        permits.sort((a, b) => {
            if ( a.price_monthly_usd && !b.price_monthly_usd) return -1;
            if (!a.price_monthly_usd &&  b.price_monthly_usd) return  1;
            if (!a.price_monthly_usd && !b.price_monthly_usd) return  0;

            if (a.price_monthly_usd > b.price_monthly_usd) return  1;
            if (a.price_monthly_usd < b.price_monthly_usd) return -1;

            return 0;
        });

        const isValidPermit = (permit) => {
            // Perhaps we've ran out of a limited resource? What plans are available that have more than we
            // currently do?.
            if (resourceCounts.create_patient > 1 &&
                !(permit.create_patient && ['limited', 'limited_active'].includes(permit.create_patient) &&
                  permit.create_patient_limit >= resourceCounts.create_patient) && !(typeof permit.create_patient_limit === "undefined" && permit.reate_patient === "active")) {
                return false;
            }

            if (resourceCounts.create_dietitian > 1 &&
                !(permit.create_dietitian && ['limited', 'limited_active'].includes(permit.create_dietitian) &&
                  permit.create_dietitian_limit >= resourceCounts.create_dietitian) && !(typeof permit.create_dietitian_limit === "undefined" && permit.create_dietitian === "active")) {
                return false;
            }

            if (resourceCounts[feature] > 1 && allFeatures[feature] &&
                !(permit[feature] && ['limited', 'limited_active'].includes(permit[feature]) &&
                  permit[allFeatures[feature].limits] > resourceCounts[feature])) {
                return false;
            }

            // We also don't want to promote a permit if its feature is set to cooldown or expired,
            if (permit[feature] && !['all', 'active', 'limited', 'limited_active'].includes(permit[feature])) {
                return false;
            }

            return true;
        };

        const validPermits = permits.filter(permit => isValidPermit(permit));
        const invalidPermits = permits.filter(permit => !isValidPermit(permit));

        return { permits, validPermits, invalidPermits };
    }

    renderContactSupportBody = () => {
        return (
            <div className="upgrade-membership contact-support">
                <h1>Please contact us</h1>
                <p>
                    We’d like to help you find the right membership plan to fit your needs. Please reach out to us by sending us an email at <a target="_blank" href="mailto:support@eatlove.is">support@eatlove.is</a>
                </p>
            </div>
        );
    }

    onSelectPermit = (permit) => {
        // First, check to see if we need to capture a credit card first. This will be in the customer object.
        const { customer, card } = this.state;

        if (permit.billing_period) {
            if (!card) {
                this.setState({mode: 'card-capture', selected: permit});
            } else {
                this.loadInvoicePreview(permit);
            }

            return;
        }

        // If we have a card already, we don't need to capture a stripeToken, so
        // we can go ahead and load our invoice preview directly.
        this.setState({selected: permit}, this.onSubmitConfirmUpgrade);
    }

    loadInvoicePreview = async (permit, stripeToken = null, couponToAdd = null, reference = null, total_training = null) => {
        const { user, training_count, coupon, referred_by } = this.state;

        const subscription = permit.practice_subscription_plan || permit.user_subscription_plan;
        const training = total_training !== null ? total_training : training_count
        const coupon_name = couponToAdd || coupon;
        const reference_name = reference || referred_by;

        const url = UserStore.getLinks().subscription;
        const request = {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=create/subscription/1'},
            body: JSON.stringify({subscription, stripeToken, coupon: coupon_name, referred_by: reference_name, preview: true, training}),
        };

        this.setState({
            loading: 'invoice-preview',
            stripeToken,
            cardError: null,
            cardCaptureWorking: false
        });

        try {
            // Get our invoice preview from the server
            const { customer, preview, invoices} =  await AuthStore.fetch(url, request);

            const card = customer.default_source && customer.sources
                     ? customer.sources.data.find(c => c.id === customer.default_source)
                     : null;

            this.setState({
                selected: permit,
                loading: false,
                mode: 'invoice-preview',
                preview,
                card,
                customer,
                stripeToken: null,
                training_count:  training,
                coupon: coupon_name,
                referred_by: reference_name
            });
        } catch (error) {
            const cardError = (error && error.message) || error || 'unknown error';
            this.setState({loading: false, cardError});

            Analytics.createSubscriptionFailed(subscription, coupon, cardError);
        }
    }

    onSubmitCardCapture = async (response, coupon, referred_by) => {
        const { selected } = this.state;
        const subscription = selected.practice_subscription_plan || selected.user_subscription_plan;

        if (response && response.error) {
            this.setState({
                cardError: response.error.message,
                cardCaptureWorking: false,
            });

            Analytics.createSubscriptionFailed(subscription, coupon, response.error.message);

            return;
        }

        this.loadInvoicePreview(selected, response.token.id, coupon, referred_by);
    }

    onSubmitConfirmUpgrade = async () => {
        const { user, selected, stripeToken, coupon, referred_by, training_count } = this.state;
        const subscription = selected.practice_subscription_plan || selected.user_subscription_plan;
        const training = training_count;

        const url = UserStore.getLinks().subscription;
        const request = {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=create/subscription/1'},
            body: JSON.stringify({subscription, stripeToken, coupon, referred_by, training }),
        };

        try {
            this.setState({loading: 'subscribing', cardError: null})

            // Make the requested subscription change on the server.
            const response =  await AuthStore.fetch(url, request);
            const { customer, invoices } = response;
            const invoice_confirmation = invoices && invoices.data.length ? invoices.data[0] : null;

            this.setState({
                loading: false,
                mode: 'upgrade-confirmed',
                customer,
                invoices,
                invoice: invoice_confirmation,
                user: response.user,
                training_count: training,
                stripeToken: null
            })

            UserActions.subscribe(response.user);
            Analytics.createSubscription(response.user, subscription, coupon);
        } catch (error) {
            this.setState({loading: false, cardError: (error && error.message) || error || 'unknown error'});

            Analytics.createSubscriptionFailed(subscription, coupon, response.error.message);
        }
    }

    renderPermitPickerBody = () => {
        const { feature, closeModal } = this.props;
        const { user, validPermits, permits, subscription } = this.state;

        return <PermitPicker user={user} subscription={subscription} closeModal={closeModal} feature={feature} permits={validPermits} onSelectPermit={this.onSelectPermit} />
    }

    renderSignupNeededBody = () => {
        return (
            <div className="upgrade-membership account-needed">
                <p>You need to sign up for EatLove first.</p>

                <p>@todo - can we put the sign-up form here? If yes, we'll need to subscribe to the UserStore</p>
            </div>
        );
    }

    renderContactYourPro = (user, my_dietitian) => {
        const { feature } = this.props;
        const { practice, practice_type = 'consumer' } = user;

        const isChatOnline = ChatStore.getStatus() === 'online';

        return (
            <div className="upgrade-membership contact-your-pro">
                <h2>Please contact your {my_dietitian.title || (practice_type === 'dietetics' ? 'dietitian' : 'coach')} to unlock {allFeatures[feature].access_name || feature}.</h2>

                <div className="my-dietitian">
                    {my_dietitian.managing_mode === 'individual' ? <section className="dietitian">
                        {my_dietitian.image
                            ? <ImgResized src={my_dietitian.image} width={200} height={200}/>
                            : <i className={my_dietitian.gender === 'female' ? "icon-female2" : "icon-male2"} />
                        }

                        <section className="dietitian-info">
                            <p className="dietitian-name">{my_dietitian.name}{my_dietitian.credentials ? ', ' : null} <em>{my_dietitian.credentials}</em></p>
                        </section>

                        <section className="practice-info">
                            {my_dietitian.name !== practice.name ?
                                <p className="practice-name">{practice.name}</p>
                            : null}
                            {my_dietitian.email !== 'redacted' ?
                                <p className="address">{[practice.address1, practice.address2, practice.city, practice.state].filter(v => v).join(', ')}</p>
                            : null}
                        </section>

                        {my_dietitian.email !== 'redacted' ?
                            <section className="dietitian-contact">
                                {my_dietitian.mobile_number ?
                                    <p className="phone"><a href="tel:{mobile_number}">{my_dietitian.mobile_number}</a></p>
                                : null}

                                {isChatOnline ?
                                    <p className="chat-link"><Link to="/messages">send message</Link></p>
                                : null}

                                {!isChatOnline ?
                                    <p className="chat-link"><a href={"mailto:" + my_dietitian.email}>send message</a></p>
                                : null}
                            </section>
                        : null}
                    </section> : null}
                </div>
            </div>
        );
    }

    renderContactYourAdmin = () => {
        const { feature } = this.props;

        return (
            <div className="upgrade-membership contact-your-admin">
                Please contact your EatLove PRO administrator to access {feature}.
            </div>
        );
    }

    renderInvoicePreviewBody = () => {
        const { preview, cardError, selected, stripeToken, coupon, referred_by, training_count, addCouponMode, card } = this.state;

        return (
            <div className="upgrade-membership card-capture">
                <Invoice invoice={preview}
                    permit={selected}
                    allowAddOnHourly={selected.hourly_training && selected.hourly_training > 0}
                    isConfirmation
                    addTraining={() => this.loadInvoicePreview(selected, stripeToken, coupon, referred_by, training_count + 1)}
                    removeTraining={() => this.loadInvoicePreview(selected, stripeToken, coupon, referred_by, 0)}
                    onConfirmPurchase={this.onSubmitConfirmUpgrade}
                    cardError={cardError}
                    coupon={coupon}
                    addCouponMode={addCouponMode}
                    setAddCouponMode={this.setAddCouponMode}
                    onChangeCoupon={this.onChangeCoupon}
                    onChangeCard={this.onChangeCard}
                    creditCard={card}/>
            </div>
        );
    }

    renderCardCaptureBody = () => {
        const { user, selected, referral, defaultMref, cardError, cardCaptureWorking } = this.state;

        let denominator;
        switch(selected.billing_period) {
            case 'monthly':
                denominator = 'Month';
                break;
            case 'quarterly':
                denominator = 'Quarter';
                break;
            case 'yearly':
                denominator = 'Year';
                break;
            default:
                denominator = selected.billing_period;
        }

        return (
            <div className="upgrade-membership card-capture">
                {selected ?
                    <div>
                        <h1>{selected.name}</h1>

                        {selected.price_usd ?
                            <span className="subscription-price"><p>${selected.price_usd.toFixed(0)}</p><p>/{denominator}</p></span>
                        : null}
                    </div>
                : null}

                <StripePaymentCardEntry working={cardCaptureWorking} cardError={cardError}
                    onStartRequest={() => this.setState({cardCaptureWorking: true})}
                    showReferralField={true}
                    defaultReferralCode={defaultMref}
                    referral={referral}
                    responseHandler={this.onSubmitCardCapture}
                    submitText="Next: Review Purchase" />
            </div>
        );
    }

    renderSubscriptionConfirmedBody = () => {
        const { closeModal } = this.props;
        const { invoice, cardError, selected, stripeToken, coupon, referred_by } = this.state;

        return (
            <div className="upgrade-membership card-capture">
                <Invoice invoice={invoice}
                    onConfirmPurchase={closeModal}
                    permit={selected}
                    isConfirmed={true}
                    confirmText="Close"
                    cardError={cardError} />
            </div>
        );
    }

    renderLoadingBody = (loading) => {
        if (loading === 'offers') {
            return (
                <div className="upgrade-membership loading offers">
                    <h1 className="permit-picker-title">Upgrade to access EatLove PRO premium features</h1>
                    <ul>
                        <li>Print high quality recipes as patient handouts</li>
                        <li>Professionally photographed recipes </li>
                        <li>5500+ Dietitian Approved Recipes</li>
                    </ul>
                    <div className="permit-card-frame"/>
                    <div className="permit-card-frame"/>

                </div>
            );
        } else if (loading === 'invoice-preview') {
            return (
                <div className="upgrade-membership loading invoice-preview">
                    <div className="logo-frame"/>
                    <h1> $100 payment to EatLove</h1>
                    <div className="invoice-section-frame"/>
                    <div className="invoice-section-frame"/>
                    <div className="add-on-frame"/>
                    {/*<div className="footer-frame"/>*/}
                </div>
            );
        } else if (loading === 'subscribing') {
            return (
                <div className="upgrade-membership loading subscribing">
                    <div className="logo-frame"/>
                    <h1> $100 payment to EatLove</h1>
                    <div className="invoice-section-frame"/>
                    <div className="invoice-section-frame"/>
                    {/*<div className="footer-frame"/>*/}
                </div>
            );
        }
    }

    renderModalBody = () => {
        const { user, loading, validPermits, mode } = this.state;
        const { feature } = this.props;

        if (!user) {
            return this.renderSignupNeededBody();
        }

        if (loading) {
            return this.renderLoadingBody(loading);
        }

        if (!(user.capabilities && user.capabilities.purchase_membership)) {
            const { dietitian, my_dietitian } = user;

            // Some users are both patients and dietitians. They are dietitians first.
            if (dietitian) {
                return this.renderContactYourAdmin();
            }

            if (my_dietitian) {
                return this.renderContactYourPro(user, my_dietitian);
            }

            return this.renderContactSupportBody();
        }

        switch(mode) {
            case 'card-capture':
                return this.renderCardCaptureBody();

            case 'invoice-preview':
                return this.renderInvoicePreviewBody();

            case 'upgrade-confirmed':
                return this.renderSubscriptionConfirmedBody();

            default:
        }

        if (!(validPermits && validPermits.length)) {
            return this.renderContactSupportBody();
        }

        return this.renderPermitPickerBody();
    }

    render() {
        const { isMobile, viewportWidth,isPro } = this.context;
        const { closeModal } = this.props;
        const { user, loading,  permits, mode,addCouponMode,cardCaptureWorking, coupon } = this.state;
        const shouldDisplayTestimonials = (!loading || loading === 'offers') && mode === 'offers'  && !isMobile  && viewportWidth > 1050;
        const shouldNotCenter = (!loading || loading === 'offers') && !isMobile && mode === 'offers';
        const confirmText = mode === 'invoice-preview' ? 'Confirm Purchase' : 'Close';

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Upgrade Membership"
                className="feed-modal upgrade-membership-modal"
                overlayClassName="feed-modal-overlay"
                closeTimeoutMS={250}>
                <div className="feed-modal-container preferences-form upgrade-membership-modal-container" data-long={shouldNotCenter} onClick={closeModal}>
                    <header onClick={(ev) => ev.stopPropagation()}>
                        <div className="top-half-circle no-curve">&nbsp;</div>
                        <div className="header-container">
                        {isMobile ? <span className="header-close-btn" onClick={closeModal}>
                            <p>Upgrade Membership</p>
                        </span> : null}
                        </div>
                    </header>
                    <div className="modal-scroll-container upgrade-container"
                        data-footer={mode !== 'offers' && mode !== 'card-capture'}
                        data-loading={loading && loading.length >= 1}
                        data-off-center={shouldNotCenter}
                        onClick={(ev) => ev.stopPropagation()}
                    >
                        {!isMobile && mode !== 'upgrade-confirmed' ? <span className="close-btn" >
                            <i className="feather feather-x" onClick={closeModal}/>
                        </span> : null}
                        <section className="inner-slider upgrade-slider" data-loading={loading && loading.length >= 1} data-footer={mode !== 'offers' && mode !== 'card-capture'} data-long={shouldNotCenter}>
                            {this.renderModalBody()}
                        </section>

                        {!loading && mode !== 'offers' && mode !== 'card-capture' ?
                            <footer>
                                {!(addCouponMode && coupon.length) && mode !== 'card-capture' ?
                                    <button onClick={mode === 'invoice-preview' ? this.onSubmitConfirmUpgrade : closeModal}>
                                        {confirmText}
                                    </button>
                                : null}
                                {mode === 'invoice-preview' && addCouponMode && coupon.length ?
                                    <button onClick={this.onAddCoupon}>Add Coupon</button>
                                : null}
                            </footer>
                        : null}
                    </div>
                    {shouldDisplayTestimonials ? <TestimonialContainer practice_type={user.my_dietitian ? 'consumer' : user.practice_type} mobileVersion={false}/> : null}
                </div>
            </Modal>
        );
    }
}
