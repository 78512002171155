'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';

import modalStyle from '../../jsx-styles/modals';
import Analytics from '../../utils/Analytics';

import './ReportIssue.scss';

export default class ReportIssue extends Component {
    static propTypes = {
    };

    static defaultProps = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.state = {
            issueText: '',
            errorMsg: null,
        };
    }

    onReportIssueSubmit = () => {
        const { issueText } = this.state;
        const { location, recipe, food } = this.props;

        if (!issueText) {
            return this.setState({errorMsg: 'Please enter some information on what the problem is.'});
        }

        if (issueText && issueText.length <= 10) {
            return this.setState({errorMsg: 'Please describe the problem in as much detail as you can.'});
        }

        this.setState({isReportSubmitted: true, errorMsg: ''});

        const content = location || recipe || food;

        Analytics.reportIssue({
            "Issue Text": issueText,
            "Content Type": content ? content.type : null,
            "Content UUID": content ? content.uuid : null
        });
    }

    onReportIssue = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false, isReportSubmitted: false});
    }

    renderModal = () => {
        const { isModalOpen, isReportSubmitted, issueText, errorMsg } = this.state;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                style={modalStyle.confirmDialog}
                contentLabel="Report Issue"
                className="modal-confirmation"
                closeTimeoutMS={250}>

                {!isReportSubmitted ?
                <section className="modal-main">
                    <div className="report-issue-form">
                        <h2>We're sorry!</h2>

                        <p>Deepest, most humblest apologies for our error. <br />Please tell us what's wrong below.</p>

                        <textarea value={issueText} onChange={ev => this.setState({issueText: ev.target.value})} maxLength="5000"
                            placeholder="Enter details of the issue here. Please include instructions on what to change if possible." />
                        {errorMsg ? <p className="error-msg">{errorMsg}</p> : null}
                    </div>

                    <footer>
                        <button className="reject" onClick={this.closeModal}>Cancel</button>
                        <button className="accept" onClick={this.onReportIssueSubmit}>Send Report</button>
                    </footer>
                </section> :

                <section className="modal-main">
                    <div className="report-issue-form">
                        <h2>Thank you!</h2>

                        <p>An EatLove dietitian will review your feedback as soon as possible!</p>
                    </div>

                    <footer>
                        <button className="accept" onClick={this.closeModal}>Close</button>
                    </footer>
                </section>}

            </Modal>
        );

    }

    render() {
        const { className } = this.props;

        return (
            <span>
                <a href="mailto:support@eatlove.is?body=Report Issue" className={classNames("report-issue-btn el-text-btn", className)}>Report Issue</a>
                {this.renderModal()}
            </span>
        );
    }
}
