'use strict';

import moment from 'moment';
import allMealKitBrands from '../tables/meal-kit-brands';

const DEFAULT_HOUR_DEADLINE = 12;
const DEFAULT_WEEKDAY_DEADLINE = 3; //Wednesday
const DEFAULT_TIME_ZONE = 'America/Los_Angeles';

export const isSunbasketFood = (food) => {
    return !!(food && food.brand_uuid && allMealKitBrands[food.brand_uuid]?.provider == 'sunbasket');
}

/**
 * Returns the order deadline for a meal to be scheduled on a given date.
 */
export const getDeadline = (date, timezone = DEFAULT_TIME_ZONE) => {
    const mealDate = moment(date).tz(DEFAULT_TIME_ZONE, true);
    const daysToAdd = ((7 - moment(mealDate).clone().weekday()) % 7 + DEFAULT_WEEKDAY_DEADLINE) % 7;
    const subtractWeekDays = moment(mealDate).weekday() > DEFAULT_WEEKDAY_DEADLINE ? 2 : 1;
    return moment(mealDate).clone()
        .add(daysToAdd, 'days')
        .subtract(subtractWeekDays, 'weeks')
        .tz(DEFAULT_TIME_ZONE)
        .hour(DEFAULT_HOUR_DEADLINE).minute(0).seconds(0).tz(timezone);
}

export const hasDeadlineExpired = (date, timezone = DEFAULT_TIME_ZONE) => {
    const deadline = getDeadline(date, timezone);
    return moment().tz(timezone).isAfter(deadline);
}

export const getNextDeadline = (timezone = DEFAULT_TIME_ZONE, keepSystemTimezone = false) => {
    const currentDate = moment().tz(timezone).tz(DEFAULT_TIME_ZONE);

    const daysUntilDeadline = DEFAULT_WEEKDAY_DEADLINE - currentDate.weekday();
    const isBeforeDeadline = daysUntilDeadline > 0 || (daysUntilDeadline === 0 && currentDate.hour() < DEFAULT_HOUR_DEADLINE);

    const deadline = currentDate.clone();

    if (isBeforeDeadline) {
        deadline.add(daysUntilDeadline, 'days');
    } else {
        deadline.add(1, 'week').add(daysUntilDeadline, 'days');
    }

    return deadline
        .tz(DEFAULT_TIME_ZONE)
        .hour(DEFAULT_HOUR_DEADLINE)
        .minute(0)
        .seconds(0).tz(keepSystemTimezone ? moment.tz.guess() : timezone);
}

export const getDeadlineDateFormatted = (deadline, referenceDate) => {
    const nextDeadline = deadline.clone().add(1, 'week');

    const isDateOrderToday = referenceDate.format('YYYY-MM-DD') == deadline.format('YYYY-MM-DD');
    const timeInfo = getTimeFormatted(deadline);

    if (referenceDate.isBefore(deadline)) {
        if (isDateOrderToday) {
            return 'Today, ' + timeInfo;
        }

        if (referenceDate.clone().add(1, 'day').format('YYYY-MM-DD') == deadline.format('YYYY-MM-DD')) {
            return 'Tomorrow, ' + timeInfo;
        }

        return deadline.format('ddd, MMM D, ') + timeInfo;
    }

    return ((isDateOrderToday) ? 'next ' : '') + nextDeadline.format('ddd, MMM D, ') + timeInfo;
}

export const getTimeFormatted = (dateTimeMoment) => {
    if (!dateTimeMoment || !moment.isMoment(dateTimeMoment)) {
        return false;
    }

    return (dateTimeMoment.hour() == 12) ? 'Noon' : dateTimeMoment.format('hh a');
}

export const getSunbasketSubtotalForMeals = (meals, {foods = {}} = {}) => {
    let subtotal = 0;

    meals.filter(meal => {
        if (!meal.food_uuid || (meal.food_uuid && !foods[meal.food_uuid])) {
            return false;
        }

        const food = foods[meal.food_uuid];

        if (!isSunbasketFood(food) || !food.mapped_from?.length) {
            return false;
        }

        // Lets just assume the first mapped item will have the correct price in it.
        const lineItemCost = food.mapped_from[0].price * meal.scaling;

        subtotal += lineItemCost;

        return true;
    });

    return subtotal;
}
