'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import UserStore from '../../stores/UserStore';

import Popup from './Popup.react';
import RecipeListItem from '../../components/Recipes/RecipeListItem.react';
import CopyToClipboard from '../Dashboard/Header/CalendarSync/CopyToClipboard.react';

import { getConfig } from '../../utils/Env';
import Analytics from '../../utils/Analytics';

import './SharePopup.scss';
import './RecommendPopup.scss';

export default class RecommendPopup extends Component {
    static propTypes = {
        recipes: PropTypes.array,
    };

    render = () => {
        const { recipes, onClickItem, className, positionClassName } = this.props;

        return  (
            <Popup positionClassName={positionClassName} className={classNames("share-popup recommend-popup", className)} button={<><i className="feather feather-heart" /><em>recommend</em></>}>
                <div className="share-popup-content">
                    {recipes?.map((recipe, index) => {
                        return (
                            <div key={index} onClick={() => onClickItem(recipe.uuid)}>
                               <RecipeListItem  key={index} recipe={recipe}/>
                           </div>
                        );
                    })}
                </div>
            </Popup>
        );
    }
}
