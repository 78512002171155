'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import SearchKeywords from '../Filters/SearchKeywords.react';
import DocumenTypeExpander from '../Dropdowns/DocumentType.react';
import MealTypesExpander from '../Dropdowns/MealTypes.react';
import AvoidancesExpander from '../Dropdowns/Avoidances.react';
import SkillLevelExpander from '../Dropdowns/SkillLevel.react';
import CaloriesExpander from '../Dropdowns/Calories.react';
import GoodSourceExpander from '../Dropdowns/GoodSource.react';
import TotalTimeExpander from '../Dropdowns/TotalTime.react';
import PrepTimeExpander from '../Dropdowns/PrepTime.react';
import TagGroupExpander from '../Dropdowns/TagGroup.react';
import SortExpander from '../Dropdowns/Sort.react';
import LibraryFilter from '../Filters/Library.react';
import PreferencesFilter from '../Filters/Preferences.react';
import UnpublishedFilter from '../Filters/Unpublished.react';
import NutrientFilters from '../NutrientFilters.react';
import IngredientsFilter from '../Dropdowns/Ingredients.react';
import LanguageFilter from '../Filters/LanguageFilter.react';

import MerchantExpander from '../Dropdowns/Merchant.react';
import Analytics from '../../../utils/Analytics';
import UserStore from '../../../stores/UserStore';

import allTags from '../../../tables/tags';

import './AdvancedFilters.scss';

export default class AdvancedFilters extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
        hideLibraryFilter: PropTypes.bool,
   };

    static contextTypes = {
        profile: PropTypes.object,
        isPublisher: PropTypes.bool,
        isAddSwap: PropTypes.bool,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            // Make a deep copy of the original parameters
            initialParams: JSON.parse(JSON.stringify(props.params)),
        };
    }

    componentDidMount = () => {
        const { contextName } = this.props;
        Analytics.advancedSearch({"Context": contextName});
    }

    cancel = () => {
        const { initialParams } = this.state;
        const { onChangeParams, closeModal } = this.props;

        onChangeParams(initialParams);
        closeModal();
    }

    render() {
        const { isPublisher, isAddSwap, isPro } = this.context;
        const { params, allowedTypes, onChangeParams, total, loading, closeModal, hideLibraryFilter, showTypePicker } = this.props;
        const { hide_nutrition = false } = UserStore.getUser();

        const modalStyles = {
            overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                backgroundImage: 'url(https://static.chewba.info/images/overlay-dust-dark-30.png)',
                backgroundRepeat: 'repeat',
                backgroundSize: '4px',
                backgroundColor: 'rgba(25, 25, 25, 0.75)',
                zIndex: 10,
            },
            content : {
            }
        };

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Advanced Search"
                className="modal-parent global-search-advanced-filters-modal"
                style={modalStyles}
                closeTimeoutMS={250}>

                <div className="global-search-advanced-filters">
                    <header>
                        <button onClick={closeModal}>
                            <i className="icon-chevron-left" />
                            <span className="assistive-text">Close Modal</span>
                        </button>

                        <h1>Filter Results</h1>
                    </header>

                    <div className="filters-scroll-container">
                        <section className="inner-slider">
                            <h2>INCLUDE</h2>

                            {isPublisher ?
                                <LanguageFilter params={params} onChangeParams={onChangeParams} />
                            : null}

                            {isAddSwap          ? <SearchKeywords params={params} onChangeParams={onChangeParams} /> : null}
                            {!hideLibraryFilter ? <LibraryFilter  params={params} onChangeParams={onChangeParams} /> : null}

                            {isPublisher ?
                                <UnpublishedFilter  params={params} onChangeParams={onChangeParams} />
                            : null}

                            {!isPublisher && !isPro ?
                                <PreferencesFilter params={params} onChangeParams={onChangeParams}>
                                    Smart Choice
                                </PreferencesFilter>
                            : null}

                            {isPublisher ?
                                <PreferencesFilter params={params} onChangeParams={onChangeParams} filterName="strict_preferences">
                                    Smart Choice
                                </PreferencesFilter>
                            : null}

                            {!showTypePicker ?
                                <DocumenTypeExpander  isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            : null}
                            {!hide_nutrition ? <SortExpander         isExpander={true} params={params} onChangeParams={onChangeParams} /> : null}
                            <MealTypesExpander    isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <AvoidancesExpander   isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <IngredientsFilter       isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <SkillLevelExpander   isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.cuisine} />
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.equipment}  onlyWorksOn="recipe" />
                            <MerchantExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            {!hide_nutrition ? <CaloriesExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} /> : null}
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.planMisc}   onlyWorksOn="plan" />
                            <TagGroupExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} singleSelect={true} group={allTags.recipeMisc} onlyWorksOn="recipe" translateTagMap={{'Basic': 'Simple Recipes'}} />
                            <GoodSourceExpander   isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <TotalTimeExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />
                            <PrepTimeExpander     isExpander={true} params={params} onChangeParams={onChangeParams} allowedTypes={allowedTypes} />

                            {!hide_nutrition ? <NutrientFilters params={params} onChangeParams={onChangeParams} /> : null}
                        </section>
                    </div>

                    <footer>
                        {loading === true ? <p>loading...</p> : null}
                        {loading !== true ? <p>Filter Results: <em>{total} listings</em></p> : null}

                        <button className="cancel" onClick={this.cancel}>Cancel</button>
                        <button className="apply-filters" onClick={closeModal}>Apply</button>
                    </footer>
                </div>

            </Modal>
        );
    }
}
