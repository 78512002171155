'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './TagGroup.scss';
import './Checkbox.scss';

export default class TagGroup extends Component {
    static propTypes = {
        translateTagMap: PropTypes.object,
    };

    static contextTypes = {
        stripRecipeOnlyFilters: PropTypes.func,
        stripPlanOnlyFilters: PropTypes.func,
    };

    toggleTag = (tag) => {
        const { tags, params, onChangeParams, onlyWorksOn, singleSelect } = this.props;
        const { stripRecipeOnlyFilters, stripPlanOnlyFilters } = this.context;

        params.filters.tags = params.filters.tags || [];

        // Is the tag already active? We want to remove it if so.
        var i = params.filters.tags.indexOf(tag);

        // Is this a single-select? Strip out our tags from filters first if so.
        if (singleSelect) {
            params.filters.tags = (params.filters.tags || []).filter(tag => !tags.includes(tag));
        }

        const oldTags = params.filters.tags.slice(); // make a copy of tags

        if (i == -1) {
            // Does this type only work for a specific document type?
            if (onlyWorksOn) {
                params.types = [onlyWorksOn];

                if (onlyWorksOn === 'plan') {
                    stripRecipeOnlyFilters(params);
                } else if (onlyWorksOn === 'recipe') {
                    stripPlanOnlyFilters(params);
                }
            }

            params.filters.tags.push(tag);
        } else if (!singleSelect) {
            params.filters.tags.splice(i, 1);
        }

        onChangeParams(params, oldTags);
    }

    render() {
        const { tags, params, singleSelect, translateTagMap } = this.props;
        let filters = params.filters || {tags: []};
        let filterTags = filters.tags || [];

        return (
            <div className="global-search-tag-group">
                <ul>
                    {tags.map( tag => {
                        return (
                            <li key={ tag } data-tag={tag.split(' ').join('-')}>
                                <label className={singleSelect ? "global-search-checkbox global-search-radio" : "global-search-checkbox"}>
                                    <input type="checkbox" checked={filterTags.includes(tag)}
                                           onChange={e => this.toggleTag(tag)} />
                                    <span>{(translateTagMap && translateTagMap[tag]) || tag}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
