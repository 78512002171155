'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import AuthStore from '../../stores/AuthStore';

import './FileUploadPopUp.scss';

import { getConfig } from '../../utils/Env';
import Popup from '../Widgets/Popup.react';

const sizeLimt = 10000000;

export default class FileUploadPopUp extends Component {
    static propTypes = {
        onUploadSuccess: PropTypes.func,
        extraFormProps: PropTypes.object,
        accept: PropTypes.string,
        capture: PropTypes.bool,

        protection: PropTypes.string,
        endpoint: PropTypes.string,
        positionClassName: PropTypes.string,
    };

    static defaultProps = {
        accept: "image/jpeg,image/png,image/gif",
        capture: false,
        button: 'Upload image',
        uploading: 'Uploading...',
        extraFormProps: {},
        protection: 'private',
        endpoint: '/files',
        className: "file-upload",
        buttonClassName: "default-file-upload-btn",
        positionClassName: "el-popup-top-left",
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            progress: false,
            error: null,
            showPopUp: false,
        };
    }

    onChangeFile = (ev) => {
        const { protection, endpoint, onUploadSuccess, onUploadError } = this.props;
        const { confirm } = this.context;
        const formData = new FormData(this.form);
        const files =  formData.getAll('file');
        const file = files.find((element) => element?.name?.length > 0);
        formData.set('file', file);

        if (ev.target.files && ev.target.files[0] && ev.target.files[0].size > sizeLimt) {
            confirm(
                "Image file size is too large, please scale the image down and try again.",
                () => false,
                null,
                {acceptText: 'Close', rejectText: '', titleText: 'Unable to upload file'}
            );
            return;
        }

        ev.preventDefault();

        formData.append('protection', protection);

        this.setState({isUploading: true, error: null, showPopUp: false});

        AuthStore.fetch(getConfig('users_api') + endpoint, {method: 'POST', body: formData}).then(
            response => {
                this.setState({error: null, isUploading: false});

                onUploadSuccess && onUploadSuccess(response);
            },
            error => {
                this.setState({
                    isUploading: false,
                    error: error && error.message || 'unknown error'
                });

                const errorMessage = error && error.message || 'unknown error occurred'

                if (onUploadError) {
                    onUploadError && onUploadError(errorMessage);
                } else {
                    confirm(
                        errorMessage,
                        () => false,
                        null,
                        {acceptText: 'Close', rejectText: '', titleText: 'Unable to upload file'}
                    );
                }
            }
        );
    }

    togglePopup = () => {
        const { showPopUp } = this.state;
        this.setState({showPopUp: !showPopUp});
    }

    render() {
        const { className, buttonClassName, button, uploading, accept, capture, positionClassName } = this.props;
        const { isUploading, showPopUp } = this.state;

        return (
            <Popup isDropdownVisible={showPopUp} positionClassName={positionClassName} className={className} button={<button className={buttonClassName} onClick={() => this.togglePopup()} onBlur={() => this.setState({showPopUp: false})}>
                {isUploading ? <span className="upload-bar-background">
                    <span className="upload-bar-fill" />
                </span>
                    : null}
                <span className="uploading-text" data-uploading={isUploading}>{isUploading ? uploading : button}</span>
            </button>}>
                <form className={className} data-uploading={isUploading} ref={node => this.form = node} onSubmit={(ev) => ev.preventDefault()}>
                    <div className='photo-options-container'>
                        <button className='photo-options'>
                            Photo Library
                            <i className='feather feather-image' />
                            {!isUploading ?
                                <input onChange={this.onChangeFile} type="file" name="file"
                                    accept={"image/*"} data-testid="file-upload-photo-library"/>
                                : null}
                        </button>
                        <button className='photo-options'>
                            Take Photo
                            <i className='feather feather-camera' />
                            {!isUploading ?
                                <input onChange={this.onChangeFile} type="file" name="file"
                                    accept={"image/*"} capture data-testid="file-upload-take-photo"/>
                                : null}
                        </button>
                    </div>
                </form>
            </Popup>
        );
    }
}
