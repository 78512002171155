'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import ImgResized from '../Widgets/ImgResized.react';

import '../Recipes/RecipeListItem.scss';



export default class RecipeListItem extends Component {

    static propTypes = {
        recipe: PropTypes.object,
    };

    render = () => {
        const { recipe } = this.props;

        if (!recipe) {
            return <span />
        }

        return (
            <li className="grocery-meal-item recipe-list-item">
                <ImgResized src={recipe.image} width={120} height={120} />
                <div className="meal-info">
                    <p className="recipe-title">{recipe.title}</p>
                </div>
                <button>Recommend</button>
            </li>
        );
    }
}
