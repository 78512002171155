'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { pluralize, singularize } from 'inflected';
import { DragSource, DropTarget } from 'react-dnd';

import SmartFraction from '../../Widgets/SmartFraction.react';
import FoodUnitsSelector from '../../Foods/FoodUnitsSelector.react';
import Combobox from '../../../pro/components/Widgets/Combobox.react';
import { isSingular, roundForHumans } from '../../../utils/Math';
import NutritionInfoModal from '../../Nutrition/Modals/NutritionInfoModal.react';

import './EditIngredient.scss';

const ingredientTarget = {
    hover(props, monitor) {
        var item = monitor.getItem();

        if (item.ingredient != props.ingredient) {
            props.moveIngredient(item.ingredient, props.ingredient);
        }
    }
};

const ingredientSource = {
    beginDrag(props) {
        return { ingredient: props.ingredient };
    },
    endDrag(props, monitor, component) {
        if (monitor.didDrop()) {
            // props.autosave();
            // console.log('@todo - we should save here');
        }
    },
    isDragging(props, monitor) {
        return props.ingredient === monitor.getItem().ingredient;
    }
};

@DropTarget('ingredient', ingredientTarget, connect => ({
    connectDropTarget: connect.dropTarget(),
}))
@DragSource('ingredient', ingredientSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
}))
export default class EditIngredient extends Component {

    static propTypes = {
        ingredient: PropTypes.object,
        onChangeIngredient: PropTypes.func,
        onRemoveIngredient: PropTypes.func,
    };

    static defaultProps = {
    };

    static contextTypes = {
        foods: PropTypes.object,
        subrecipes: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            ...this.getStateFromProps(props, context),

            isModalOpen: false,
            showNutritionModal: false,
        };
    }

    openModal = () => {
        this.setState({isModalOpen: true, ...this.getStateFromProps(this.props, this.context)});
    }

    UNSAFE_componentWillReceiveProps = (newProps, newContext) => {
        this.setState(this.getStateFromProps(newProps, newContext));
    }

    getStateFromProps = (props, context) => {
        const { ingredient } = props;
        const { amount, unit_of_measure } = ingredient.measurement || {};

        const ingredientCopy = JSON.parse(JSON.stringify(ingredient));

        const verified = (ingredientCopy.food && ingredientCopy.food.verified) ||
                         (ingredientCopy.recipe && ingredientCopy.recipe.verified);

        const newState = {
            verified,
            ingredient: ingredientCopy,
            grams: ingredient.grams,
            milliliters: ingredient.milliliters,
        };

        return newState;
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    onSave = () => {
        const {onChangeIngredient} = this.props;
        const { ingredient } = this.state;

        if (ingredient.measurement.amount <= 0) {
            this.setState({error: 'Please enter a valid amount.'});
            return;
        }
        onChangeIngredient(ingredient);
        this.setState({isModalOpen: false});
    }

    disableSaveBtn = (value) => {
        this.setState({disableSaveBtn: value});
    }

    onChangeFoodUnit = (portion, unit_of_measure, amount, grams, milliliters, unit) => {
        const { ingredient, error } = this.state;

        if (error) {
            this.setState({error: null});
        }

        ingredient.measurement = {amount, unit_of_measure};
        ingredient.grams = grams;
        ingredient.milliliters = milliliters;

        ingredient.food = ingredient.food || {};

        if (unit) {
            ingredient.food.unit_amount = unit.amount;
            ingredient.food.unit_grams = unit.grams;
            ingredient.food.unit_milliliters = unit.milliliters;
            ingredient.food.unit_description = unit.description;
        }

        this.setState({ingredient: ingredient});
    }

    onChangeAmountText = (ev) => {
        const { ingredient } = this.state;

        ingredient.measurement.amount = ev.target.value;

        this.setState({ingredient: ingredient});
    }

    onChangeUnitText = (ev) => {
        const { ingredient } = this.state;

        ingredient.measurement.unit_of_measure = ev.target.value;

        this.setState({ingredient: ingredient});
    }

    onRemoveIngredient = () => {
        this.closeModal();

        const { onRemoveIngredient } = this.props;

        onRemoveIngredient();
    }

    onChangePrepStepTerms = (terms) => {
        const { ingredient } = this.state;

        ingredient.prep_step = terms;

        this.setState({ingredient: ingredient});
    }

    onSelectPrepStepOption = (option) => {
        const { ingredient } = this.state;

        ingredient.prep_step = option;

        this.setState({ingredient: ingredient});

        this.refs.prep_step.setTypeAhead(option);
    }

    renderModal = () => {
        const { isModalOpen, disableSaveBtn } = this.state;
        const { ingredient, onSwapIngredient } = this.props;
        const { foods } = this.context;

        const { measurement = {} } = ingredient;
        const { amount } = measurement;

        if (!isModalOpen) {
            return;
        }

        const food = ingredient.food && foods[ingredient.food.uuid];

        const prepStepOpts = (food && (food.advance_prep || []).map(prep => ({
            label: prep.prep_step,
            value: prep.prep_step,
        }))) || [];

        return (
            <Modal isOpen={true}
                className="edit-timings-modal"
                overlayClassName="feed-modal-overlay edit-timings-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Edit recipe timings"
                closeTimeoutMS={250}>
                <div className="edit-timings-modal-container edit-ingredient-modal-container recipe-form recipe-editor">
                    <div className="edit-timings-modal-body">
                        <div className="with-label ingredient-name">
                            <p> Ingredient: {food ? (food.pretty_name || food.name) : ingredient.ingredient}</p>
                        </div>

                        {food ?
                            <FoodUnitsSelector food={food}
                                meal={{logged_portion: 1, logged_unit: ingredient.food.unit_description, logged_amount: amount}}
                                onChangeAmount={this.onChangeFoodUnit} disableSaveBtn={this.disableSaveBtn}/>
                        : null}

                        {food ?
                            <div className="with-label">
                                <label className="prep-label">Prep step</label>
                                <em className="optional-text">(optional)</em>
                                <Combobox options={prepStepOpts} value={ingredient.prep_step} defaultValue={ingredient.prep_step}
                                    onSelectOption={this.onSelectPrepStepOption} onChangeTerms={this.onChangePrepStepTerms}
                                    ref="prep_step" />
                            </div>
                        : null}

                        {(!food && ingredient.food) ?
                            <div className="food-loading"><i className="icon-spinner2" /></div>
                        : null}

                        {ingredient.recipe ?
                            <p className="warning"><em>Foundational Recipe</em><br />Very sorry, but at this time, this ingredient cannot be edited. You can replace it with another food however.</p>
                        : null}

                        {(!food && !ingredient.food && !ingredient.recipe) ?
                            <p className="warning"><em>Ingredient Not Found</em><br />You can still add it, but its nutrition information won't be calculated.</p>
                        : null}
                    </div>

                    <footer>
                        <button className="delete-btn" onClick={() => onSwapIngredient(ingredient)}>swap</button>
                        <button className="delete-btn" onClick={this.onRemoveIngredient}>remove</button>
                        <button className="done-btn" disabled={disableSaveBtn} onClick={this.onSave}>done</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    showNutrition = () => {
        const { ingredient } = this.props;
        this.setState({showNutritionModal: ingredient});
    }

    onCloseNutritionInfoModal = () => {
        this.setState({showNutritionModal: null});
    }

    renderNutritionInfoModal = () => {
        const { showNutritionModal } = this.state;
        const { ingredient, profile } = this.props;
        const { recipes, foods } = this.context;

        if (!showNutritionModal) {
            return;
        }

        const contents = {...recipes, ...foods};
        let content;
        let nutrients = [];

        if (ingredient.recipe) content = contents[ingredient.recipe.uuid];
        if (ingredient.food) content = contents[ingredient.food.uuid];

        if(content?.nutrients) {
            Object.keys(content?.nutrients?.values).forEach(nutrNo => {
                if (content.serving_unit === "g") {
                    nutrients[nutrNo] = content.nutrients.values[nutrNo] / content.grams_per_serving * ingredient.grams;
                } else if (content.serving_unit === "ml") {
                    nutrients[nutrNo] = content.nutrients.values[nutrNo] / content.milliliters_per_serving * ingredient.milliliters;
                }
            });
        }

        return (
            <NutritionInfoModal
                subtitle={ingredient.ingredient}
                profile={profile}
                nutrients={nutrients}
                onClose={this.onCloseNutritionInfoModal} />
        );
    }

    render = () => {
        const { ingredient, profile, isDragging, connectDropTarget, connectDragSource } = this.props;
        let { amount, unit_of_measure } = ingredient.measurement || {};

        const { preferences = {}, hide_nutrition  } = profile;

        if (unit_of_measure) {
            unit_of_measure = isSingular(amount) ? singularize(unit_of_measure) : pluralize(unit_of_measure);
        }

        return connectDropTarget(
            <div className="ingredient" data-dragging={isDragging}>
                {connectDragSource(
                    <p>{ingredient.ingredient}{ingredient.prep_step ? <span>, {ingredient.prep_step}</span> : null}</p>
                )}

                {!hide_nutrition ? (
                    <button className="nutrition-info-btn" onClick={() => this.showNutrition()}>
                        <i className="icon-analyze" />
                    </button>
                ) : null}

                <div className="select-container" onClick={this.openModal}>
                    <p className="value">
                        {profile.units_mode === 'metric'
                            ? roundForHumans(amount)
                            : <SmartFraction acceptedDenominators={[2,3,4,5,6,8]} value={amount} />} {unit_of_measure}
                    </p>

                    <button className="chevron">
                        <i className="icon-chevron-down" />
                    </button>
                </div>

                {this.renderModal()}
                {this.renderNutritionInfoModal()}
            </div>
        );
    }
}
