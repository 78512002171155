'use strict';

import { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';

import TastePreferences from '../../Patients/TastePreferences.react';
import Conditions from '../../Patients/Conditions.react';
import NutritionPrescription from '../../Patients/NutritionPrescription.react';
import FamilyMembers from '../../Patients/FamilyMembers.react';

import modalStyles from '../../../../jsx-styles/modals';
import './ProfileModal.scss';
import '../../Modals/Modals.scss';

export default class ProfileModal extends Component {
    static contextTypes = {
        showEditFamilyMembers: PropTypes.func,
    };

    render() {
        const { closeModal, profile } = this.props;
        const { showEditFamilyMembers } = this.context;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="dialog-form-modal customizer-pick-date-modal"
                style={modalStyles.largeSquareModal}
                contentLabel="Meal Plan Groceries"
                closeTimeoutMS={250}>

                <div className="modal-container profile-modal-container">
                    <header>
                        <button onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">
                        <h2>Current Nutrition Profile: <em>{profile.type}</em></h2>

                        <h3>Health Condition / Lifestyle</h3>
                        <Conditions patient={profile} />

                        <h3>Taste Preferences</h3>
                        <TastePreferences patient={profile} force={true} />

                        <h3>Nutrition Prescription</h3>
                        <NutritionPrescription patient={profile} prescriptions={profile.prescriptions} />

                        <h3>Family Members <button className="el-medium-btn el-raspberry-btn" onClick={showEditFamilyMembers}>edit</button> </h3>
                        <FamilyMembers profile={profile} />

                        <footer className="modal-action-footer">
                            <button className="ok-btn" onClick={closeModal}>close</button>
                        </footer>
                    </div>
                </div>
            </Modal>
        );
    }
}
