'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import './SubscriptionRequired.scss';

export default class SubscriptionRequired extends Component {
    static propTypes = {
    };

    render() {
        return (
            <div className="plan-editor-subscription-required">
                <p>We&apos;re very sorry, but your account has expired. In order to schedule this meal plan on EatLove, please purchase a membership.</p>
                <p>Plan your week with premium meals from the best cookbook authors and nutrition analysis from registered dietitians.</p>
            </div>
        );
    }
}
