'use strict';

import store from 'store';
import indexBy from 'lodash.indexby';

const tests = [
];

export function parseHashParams(params) {
    params = params.substr(1); // Skip the # at the begining

    let tests = params.split(';').map(p => {
        if (p.indexOf('=') == -1) {
            return false;
        }

        let t = p.split('=');

        return {
            name: t[0],
            group: t[1],
        };
    }).filter(v => v);

    return indexBy(tests, 'name');
}

export function getTestGroup(testName) {
    const definition = tests.filter(t => t.name === testName)[0];

    if (!definition) {
        return;
    }

    let testGroup;

    // Pre-sort the user into a group defined by the hash parameter
    if (process.browser && window.location.hash) {
        let selections = parseHashParams(window.location.hash);

        if (selections[definition.name] && definition.groups[selections[definition.name].group]) {
            testGroup = selections[definition.name].group;
        }
    }

    // Pre-sort the user into a group they've already been entered in
    if (!testGroup) {
        testGroup = store.get(definition.name + '-test-group');
    }

    // Now if test group is STILL not defined, we can pick based upon the percentages
    if (!testGroup) {
        let rando = Math.random() * 100, i = 0;

        // Generate a random number between 0 and 100. If the number is higher than the last value
        // but lower than the last value plus the current value, we have our test group.
        let groupNames = Object.keys(definition.groups)
        groupNames.forEach(group => {
            if (!testGroup && rando > i && rando < (i + definition.groups[group].percent)) {
                testGroup = group;
            }

            i += definition.groups[group].percent;
        });

        // If for some reason we're still not defined (unlikely, but possible), we just use the last
        // test group.
        if (!testGroup) {
            testGroup = groupNames[groupNames.length - 1];
        }
    }

    // Store the users sorting into localstorage
    store.set(definition.name + '-test-group', testGroup);

    return testGroup;
}

export function initializeTests() {
    // Loop through all tests and initialize them so we can build identity parameters for them later
    tests.forEach(test => {
        getTestGroup(test.name);
    });
}

export function serializeTestGroups() {
    return tests.map(test => {
        let tg = getTestGroup(test.name);

        return test.name + '=' + getTestGroup(test.name);
    });
}

export default {
    initializeTests
};