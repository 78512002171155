'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Fraction } from 'fractional';
import { roundForHumans } from '../../utils/Math';

export default class SmartFraction extends Component {
    static propTypes = {
        value: PropTypes.number,
        showZero: PropTypes.bool,
        numerator: PropTypes.number,
        denominator: PropTypes.number,
        unStyle: PropTypes.bool,
        acceptedDenominators: PropTypes.array,
    }

    reduceFraction(numerator, denominator) {
        var a = numerator;
        var b = denominator;
        var c;
        while (b) {
            c = a % b; a = b; b = c;
        }
        return [numerator / a, denominator / a];
    }


    returnEntity = (numerator, denominator) => {

        if (this.props.unStyle) {
            return `${numerator}/${denominator}`;
        }

        return "<sup>"+numerator+"</sup>&frasl;<sub>"+denominator+"</sub>";
    }

    render = () => {
        let numerator, denominator;
        var rendered = [];
        var fractionEntity;
        var twoDigits;

        if (!this.props.numerator || !this.props.denominator) {
            // Limit our resolution to 1/1000s (bonus: also filters against XSS)
            var amount = Math.round(this.props.value * 1000) / 1000;

            // Extract the fractional component
            var whole = Math.floor(amount);
            var fractional = amount - whole;



            if (amount == 0 && !this.props.showZero) {
                return <span/>;
            } else if (amount == 0 && this.props.showZero) {
                return <span>0</span>;
            }

            if (Math.round(fractional * 100) == 33) {
                fractional = 1/3; // add some more precision
            }

            if (Math.round(fractional * 100) == 66 ||
                Math.round(fractional * 100) == 67) {
                fractional = 2/3; // add some more precision
            }

            if (fractional >= .95) {
                fractional = 0;
                whole += 1;
            }

            // If we don't have a fractional amount, then just return the whole amount
            if (fractional == 0) {
                return <span>{whole.toString()}</span>
            }

            if (whole > 0) {
                rendered.push(whole);
            }

            if (Math.round(fractional * 100) == 33) {
                // rendered.push('1/3');
                fractionEntity = this.returnEntity(1,3);
                numerator = 1;
                denominator = 3;
            } else if (Math.round(fractional * 100) == 67 || Math.round(fractional * 100) == 66) {
                // rendered.push('2/3');
                fractionEntity = this.returnEntity(2,3);
                numerator = 2;
                denominator = 3;
            } else if (Math.round(fractional * 1000) == 167 || Math.round(fractional * 1000) == 166) {
                // rendered.push('1/6');
                fractionEntity = this.returnEntity(1,6);
                numerator = 1;
                denominator = 6;
            } else if (Math.round(fractional * 100) == 83) {
                // rendered.push('5/6');
                fractionEntity = this.returnEntity(5,6);
                numerator = 5;
                denominator = 6;
            } else {
                // Convert the fractional using the fractional library
                try {
                    var fractional = new Fraction(fractional);
                    fractionEntity = this.returnEntity(fractional.numerator, fractional.denominator);
                    numerator = fractional.numerator;
                    denominator = fractional.denominator;
                    // rendered.push(fractional.numerator.toString() + '/' + fractional.denominator.toString());
                } catch(e) {
                    // Sometimes Fractional library throws ugly exceptions :(
                }
            }            
        } else {
            const reducedFraction = this.reduceFraction(this.props.numerator, this.props.denominator);
            fractionEntity = this.returnEntity(reducedFraction[0], reducedFraction[1]);
            numerator = reducedFraction[0];
            denominator = reducedFraction[1];
        }

        if (this.props.acceptedDenominators?.length && !this.props.acceptedDenominators.includes(denominator)) {
                return (
                    <span>{roundForHumans(this.props.value)}</span>
                );                
        }

        return (
            <span className="smart-fraction" dangerouslySetInnerHTML={{__html: rendered.join(' ') + fractionEntity }} />
        );
    }
}
