'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import indexBy from 'lodash.indexby';
import Combobox from '../../pro/components/Widgets/Combobox.react';
import AuthStore from '../../stores/AuthStore';
import UserStore from '../../stores/UserStore';
import TaxonomyActions from '../../actions/TaxonomyActions';
import TaxonomyStore from '../../stores/TaxonomyStore';
import allDiets from '../../tables/diets';
import { getConfig } from '../../utils/Env';
import { updateCachedDocuments, fetchDocumentsById } from '../../utils/Content';
import './AvoidanceSelect.scss';

export default class AvoidanceSelect extends Component {
    static propTypes = {
        profile: PropTypes.object.isRequired,
        onChangeProfile: PropTypes.func.isRequired,
        rd_override: PropTypes.bool,
    };

    static defaultProps = {
        rd_override: false,
    }

    static contextTypes = {
        isMobile: PropTypes.bool,
        isPro: PropTypes.bool,
        confirm: PropTypes.func,
        showUpgradeForm: PropTypes.func,
        nutritionPatterns: PropTypes.array
    };

    constructor(props, context) {
        super(props, context);
        const { profile } = props;
        const { language, preferences: { diets = [], avoidances = [], exclude_foods = [] } } = profile;
        const dietsToShow = allDiets.filter(diet => {
            if (diet.disabled) {
                return false;
            }

            return true;
        });

        this.state = {
            user: UserStore.getUser(),
            diets,
            avoidances,
            exclude_foods,
            dietsToShow,
            allergensToShow: [],
            alert: null,
            showSearch: false,
            avoidOptions: [],
            avoidResults: {},
            assets: {},
            loading: TaxonomyStore.isLoaded(language) ? false : true,
            categories: TaxonomyStore.getCategories(language),
            taxonomy: TaxonomyStore.getTaxonomy(language),
            keyIndex: TaxonomyStore.getKeyIndex(language),
        };

        this.resetInhibitUpdate = debounce(this.resetInhibitUpdate, 1500);
        this.searchForAvoids = debounce(this.searchForAvoids, 750);
    }

    inhibitUpdate = false

    resetInhibitUpdate = () => {
        this.inhibitUpdate = false;
    }

    componentDidMount = () => {
        const { profile } = this.props;

        this.syncAssets();

        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);
        TaxonomyActions.ensureLoaded(profile.language);
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onTaxonomyStoreChange = () => {
        const { profile } = this.props;

        this.setState({
            loading: TaxonomyStore.isLoading(profile.language),
            categories: TaxonomyStore.getCategories(profile.language),
            taxonomy: TaxonomyStore.getTaxonomy(profile.language),
            keyIndex: TaxonomyStore.getKeyIndex(profile.language),
        }, this.syncAssets);
    }

    onUserStoreChange = () => {
        this.setState({user: UserStore.getUser()});
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (this.inhibitUpdate) {
            return;
        }

        const { diets = [], avoidances = [], exclude_foods = [] } = nextProps.profile.preferences;

        this.setState({diets, avoidances, exclude_foods}, this.syncAssets);
    }

    updateParent = () => {
        const { diets, avoidances, exclude_foods } = this.state;
        const { profile, onChangeProfile } = this.props;

        profile.preferences.diets = diets;
        profile.preferences.avoidances = avoidances;
        profile.preferences.exclude_foods = exclude_foods;

        this.inhibitUpdate = true;
        onChangeProfile(profile);
        this.resetInhibitUpdate();
    }

    syncAssets = async () => {
        const { exclude_foods, categories } = this.state;

        const allergensToShow = (categories || []).filter(category => {
            if ((category.tags || []).includes('Common Avoidance')) {
                return true;
            }
            return false;
        }).sort((a, b) => a.title.localeCompare(b.title));

        const docs = await fetchDocumentsById(exclude_foods);
        const assets = indexBy(docs, 'uuid');

        this.setState({assets, allergensToShow});
    }

    syncAssetsAndUpdateParent = () => {
        this.syncAssets();
        this.updateParent();
    }

    onChangeAvoids = (newAvoids, oldAvoids) => {
        const avoidsAdded   = newAvoids.filter(avoid => oldAvoids.indexOf(avoid) == -1),
              avoidsRemoved = oldAvoids.filter(avoid => newAvoids.indexOf(avoid) == -1);

        let { diets, keyIndex } = this.state;

        // If we removed an avoidance, see if that avoidance is one of the diets avoidances
        if (avoidsRemoved.length) {
            // Is this diet listed in any of the category.diets_avoiding? If so, remove that diet
            // from the list of diets (e.g.: you cannot be vegetarian and eat chicken)
            avoidsRemoved.forEach(key => {
                const category = keyIndex[key];

                if (!category) {
                    return;
                }

                diets = diets.filter(diet => !(category.diets_avoiding || []).includes(diet));
            })
        }

        this.setState({diets, avoidances: newAvoids, hasSetNoAvoidance: false}, this.updateParent);
    }


    toggleDiet = (diet) => {
        const { profile = {}, rd_override } = this.props;
        const { confirm, nutritionPatterns } = this.context;
        const { categories, user } = this.state;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        let diets      = [diet.name];
        let avoidances = [];

        categories.forEach(category => {
            if (!(category.diets_avoiding && category.diets_avoiding.length > 0)) {
                return;
            }

            if (category.diets_avoiding.includes(diet.name) && !avoidances.includes(category.category_key)) {
                avoidances.push(category.category_key);
            }
        });

        let matchingNutritionPatterns = profile.conditions.map(({uuid}) => nutritionPatterns.find((pattern) => pattern.uuid === uuid));

        if (user && profile.conditions.length && matchingNutritionPatterns.length === 0) {
            profile.conditions.forEach(async (condition) => {
                const nutritionPattern = await AuthStore.fetch({
                    url: getConfig('users_api') + '/nutrition-patterns'+ `/${condition.uuid}`
                });
                avoidances = avoidances.concat(nutritionPattern.avoidances);
            });
        } else {
            // Add all selected conditions avoidances to the avoidance list as well.
            matchingNutritionPatterns.forEach((nutritionPattern) => avoidances = avoidances.concat(nutritionPattern.avoidances));
        }

        // Filter out duplicates
        avoidances = avoidances.filter((avoidance, i) => avoidances.indexOf(avoidance) === i);

        this.setState({diets, avoidances, hasSetNoAvoidance: false}, this.updateParent);
    }

    toggleAvoidance = (tag) => {
        let { avoidances } = this.state;
        const { profile = {}, rd_override } = this.props;
        const { confirm } = this.context;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        // Make a copy of avoidances before we mutate
        const oldAvoids = avoidances.slice();

        var i = avoidances.indexOf(tag);

        if (i == -1) {
            avoidances.push(tag);
        } else {
            avoidances.splice(i, 1);
        }

        this.onChangeAvoids(avoidances, oldAvoids);
    }

    toggleExcludeFood = (uuid) => {
        const { exclude_foods } = this.state;

        const { profile = {}, rd_override } = this.props;
        const { confirm } = this.context;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        if (exclude_foods.includes(uuid)) {
            exclude_foods.splice(exclude_foods.indexOf(uuid), 1);
        } else if (exclude_foods.length < 54) {
            exclude_foods.push(uuid);
        } else {
            return confirm(
                'Avoiding too many foods will prevent our nutrition intelligence engine from being able to recommend a meal plan for you. You cannot avoid any more foods.',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        this.setState({exclude_foods}, this.syncAssetsAndUpdateParent);
    }

    showSearch = () => {
        this.setState({showSearch: true});
    }

    hideSearch = () => {
        this.setState({showSearch: false});
    }

    searchForAvoids = async () => {
        const { profile } = this.props;
        const { user, avoidTerms, avoidances, exclude_foods, keyIndex } = this.state;
        const { capabilities = {} } = user;

        // Don't re-search if nothing serious has changed
        if (avoidTerms === this.state.resultAvoidTerms) {
            return;
        }

        const excludeUuids = exclude_foods.concat(
            avoidances.map(key => keyIndex[key]).filter(v => v).map(d => d.uuid)
        );

        const params = {
            language: profile.language || 'en',
            terms: avoidTerms,
            types: ['category', 'food'],
            filters: {
                product_type: 'Food',
                '!tags': ['Unavoidable', 'Common Avoidance'],
                '!ingredient_tags': avoidances,
                '!uuid': excludeUuids,
            },
            sort_by: 'avoidances',
            size: 35,
        };

        const response = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }, true);

        updateCachedDocuments(response.elements);

        // Sort the categories at the top above the foods
        response.elements = response.elements.sort((a, b) => {
            if (a.type === 'category' && b.type === 'food')     return -1;
            if (a.type === 'food'     && b.type === 'category') return 1;
            return 0;
        })

        const avoidOptions = response.elements.map(result => ({
            label: <span className="result-item">
                        <i className="icon-add-circle-outline" />
                        {result.pretty_name || result.name || result.title}
                        {!capabilities.advanced_avoidances ? <i className="icon-lock" /> : null }
                   </span>,
            value: result.uuid,
        }));

        this.setState({
            avoidOptions,
            avoidResults: indexBy(response.elements, 'uuid'),
            resultAvoidTerms: avoidTerms,
            noOptionsFound: avoidOptions.length == 0,
        });
    }

    onChangeAvoidTerms = (avoidTerms) => {
        avoidTerms = (avoidTerms || '').trim();

        if (!avoidTerms) {
            this.setState({avoidOptions: []});
            return;
        }

        this.setState({avoidTerms}, this.searchForAvoids);
    }

    onSelectAvoidOption = (uuid) => {
        const { user, avoidOptions, avoidances, exclude_foods, avoidResults } = this.state;
        const { profile, rd_override } = this.props;
        const { showUpgradeForm, confirm } = this.context;
        const { capabilities = {} } = user || {};

        if (!capabilities.advanced_avoidances) {
            showUpgradeForm({feature: 'advanced_avoidances'});
            return;
        }
        if (!(avoidResults && avoidResults[uuid])) {
            return;
        }

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        if (exclude_foods.length >= 54) {
            return confirm(
                <div>
                    <p>Avoiding too many foods will prevent our nutrition intelligence engine from being able to recommend a meal plan for you.</p>
                    <p>You cannot avoid any more foods.</p>
                </div>,
                accept => false, reject => false, {rejectText: ''}
            );
        }

        if (avoidResults[uuid].type === 'food' && !exclude_foods.includes(uuid)) {
            exclude_foods.push(uuid);
        } else if (avoidResults[uuid].type === 'category' && !avoidances.includes(avoidResults[uuid].category_key)) {
            avoidances.push(avoidResults[uuid].category_key);
        }

        this.setState({
            avoidances,
            exclude_foods,
            avoidOptions: avoidOptions.filter(d => d.value !== uuid),
            resultAvoidTerms: '',
        }, this.syncAssetsAndUpdateParent);

        this.refs.combo.clearTypeAhead();
    }

    onClickEatsMostThings = () => {
        const { profile, rd_override } = this.props;
        const { confirm } = this.context;

        const { inhibit_change_avoidances = false } = profile || {};

        if (inhibit_change_avoidances && !rd_override) {
            return confirm(
                'To make changes to your diets or avoidances, please contact your managing health care professional',
                accept => false, reject => false, {rejectText: ''}
            );
        }

        this.setState({diets: [], avoidances: [], exclude_foods: [], hasSetNoAvoidance: true}, this.syncAssetsAndUpdateParent);
    }

    render() {
        const {
            diets,
            avoidances,
            exclude_foods,
            hasSetNoAvoidance,
            showSearch,
            avoidOptions,
            noOptionsFound,
            dietsToShow,
            allergensToShow,
            loading,
            categories,
            keyIndex,
        } = this.state;

        const { isPro } = this.context;

        if (loading) {
            return (
                <div className="avoidances-selector">
                    <div className="avoidances-select-loader">
                        <i className="icon-spinner2" />
                    </div>
                </div>
            );
        }

        return (
            <div className="avoidances-selector">

                <section className="choose-diets">
                    <h3>Diet</h3>
                    <p>Select a diet below and we will only show recipes that match</p>

                    <ul className="select-bubbles">
                        <li><button onClick={this.onClickEatsMostThings} data-active={hasSetNoAvoidance ? true : false}>Eats All Things</button></li>
                        {dietsToShow.map((diet, i) => {
                            return (
                                <li key={i}>
                                    <button data-active={diets.includes(diet.name)} onClick={() => this.toggleDiet(diet)}>{diet.name}</button>
                                </li>
                            );
                        })}
                    </ul>
                </section>

                <section className="choose-allergens">
                    <h3>Allergies &amp; Avoidances</h3>
                    <p>Select household allergies and avoidances and we won't show recipes with them.</p>

                    <ul className="select-bubbles">
                        {allergensToShow.map((category, i) => (
                            <li key={i}>
                                <button data-active={avoidances.includes(category.category_key)} onClick={() => this.toggleAvoidance(category.category_key)}>
                                    {category.title}
                                </button>
                            </li>
                        ))}
                        {avoidances.map(key => {
                            if (keyIndex[key] && (keyIndex[key].tags || []).includes('Common Avoidance')) {
                                return;
                            }
                            return (
                                <li key={key}><button data-active={true} onClick={() => this.toggleAvoidance(key)}>
                                    {keyIndex && keyIndex[key] && keyIndex[key].title}
                                </button></li>
                            );
                        })}
                        {exclude_foods.map((uuid, i) => {
                            if (!assets[uuid]) {
                                return;
                            }

                            return (
                                <li key={i}><button data-active={true} onClick={() => this.toggleExcludeFood(uuid)}>
                                    {assets[uuid].pretty_name || assets[uuid].name || assets[uuid].title}
                                </button></li>
                            );
                        })}

                    </ul>
                </section>
                <section className="choose-dislikes">
                    <div className="add-avoided" data-show-search={showSearch} data-is-pro={isPro}>
                        <button onClick={this.showSearch} className="sub-action-btn">
                            <i className="icon-plus-thin" /> add more
                        </button>

                        <div className="with-label">
                            <Combobox showAbove onChangeTerms={this.onChangeAvoidTerms} onSelectOption={this.onSelectAvoidOption} options={avoidOptions}
                                placeholder="Enter food avoidance" ref="combo">
                                {noOptionsFound ? <p>No foods found. Please try refining your search</p> : null}
                            </Combobox>
                        </div>
                    </div>
                </section>
            </div>
        );

    }
}
