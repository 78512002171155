'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Loading.scss';

export default class Loading extends Component {

	static defaultProps = {
		loadingMessage: "Searching foods and recipes..."
	}

	static propTypes = { 
		loadingMessage: PropTypes.node
	}

    render() {
    	const { loadingMessage } = this.props

        return (
            <div className="global-search-loading">
                <h2>{loadingMessage}</h2>
                <i className="icon-spinner"/>
            </div>
        );
    }
}
