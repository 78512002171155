'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './PublishingTools.scss';
import { getConfig } from '../../../../utils/Env';

export default class PublishingTools extends Component {
    static propTypes = {
    };

    static contextTypes = {
        location: PropTypes.object,
    };

    render() {
        const { plan, saving, dirty, onSavePlan, onShowProfile } = this.props;
        const { location } = this.context;

        return (
            <div className="plan-editor-publishing-tools">
                {!saving && (!(plan.links && plan.links.self) || dirty) ?
                    <button className="sub-action-btn save-plan" onClick={onSavePlan}>save meal plan</button>
                : null}

                {saving ?
                    <button className="sub-action-btn save-plan">saving <i className="icon-spinner" /></button>
                : null}

                {onShowProfile ?
                    <button className="sub-action-btn show-profile" onClick={onShowProfile}>show nutrition profile</button>
                : null}
            </div>
        );
    }
}
