'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import PlanEditor from '../Plans/Editor.react';

import modalStyles from '../../../jsx-styles/modals';
import './CustomizerModal.scss';
import './Modals.scss';

export default class CustomizerModal extends Component {
    static propTypes = {
        uuid: PropTypes.string,
        closeModal: PropTypes.func,
        location: PropTypes.object.isRequired,
        patient: PropTypes.object,
        preferences: PropTypes.object,
        recommendations: PropTypes.object,
        prescriptions: PropTypes.array,
        showPdfPrint: PropTypes.bool,
        onSavePlan: PropTypes.func,
    };

    render() {

        const { uuid, closeModal, location, patient, showAddToPlanner, showPdfPrint, editableParticipants, onSavePlan } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="fullscreen-modal meal-plan-customizer-modal"
                style={modalStyles.largeSquareModal}
                contentLabel="Meal Plan"
                closeTimeoutMS={250}>

                <div className="fullscreen-modal-container meal-plan-customizer-modal-container">
                    <header>
                        <button onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <PlanEditor uuid={uuid}
                        location={location}
                        patient={patient}
                        showAddToPlanner={showAddToPlanner}
                        showPdfPrint={showPdfPrint}
                        editableParticipants={editableParticipants}
                        closeModal={closeModal}
                        onSavePlan={onSavePlan}
                        showConsumerFavoriteButton={true} />
                </div>
            </Modal>
        );
    }
}
