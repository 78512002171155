'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

export default class IngredientsParams extends Component {
    static propTypes = {
    };

    removeIngredients = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.total_ingredients;

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;

        if (!(params.filters && params.filters.total_ingredients)) {
            return <span />
        }

        const { gte, lte } = params.filters.total_ingredients;

        let label = (gte + ' to ' + lte);

        if (lte == 5) {
            label = '5 or Fewer';
        }

        return (
            <div className="param-item" onClick={this.removeIngredients}>
                Total Ingredients: {label} <i className="icon-close-x" />
            </div>
        );
    }
}
