'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import UserStore from '../../stores/UserStore';
import { getKmBetweenCoordinates, roundForHumans } from '../../utils/Math';

import './LocationInfo.scss';

export default class LocationInfo extends Component {
    static propTypes = {
        location: PropTypes.object,
    };

    render() {
        const { location, brand, currentLocation } = this.props;

        let url = null;

        if (location.website) {
            url = new URL(location.website);
        }

        let orderUrl = null;

        const order_online_url = location.order_online_url || (brand && brand.order_online_url);

        if (order_online_url) {
            orderUrl = new URL(order_online_url);
        }

        const directionsUrl = [
            'https://www.google.com/maps/dir/?api=1',
            location.google_place_id
                ? ('&destination_place_id=' + encodeURIComponent(location.google_place_id))
                : ('&destination=' + encodeURIComponent(`${location.title} ${location.address1} ${location.city}, ${location.state} ${location.postal_code}`)),
        ].filter(v => v).join('');

        return (
            <section className="location-info">
                <div className="col1">
                    <h2>{location.title}</h2>
                    {location.telephone ?
                        <p><a href={`tel:${location.telephone}`}>{location.telephone}</a></p>
                    : null}

                    {order_online_url ?
                        <p>Take-out & Delivery Info: <a target="_blank" href={order_online_url}>{orderUrl.host}</a></p>
                    : null}
                </div>

                <div className="col3">
                    <a href={directionsUrl} target="_blank">
                        <p>{location.address1}</p>
                        <p>{location.city}, {location.state} {location.postal_code}</p>
                    </a>

                    {url ?
                        <p><a target="_blank" href={location.website}>{url.host}</a></p>
                    : null}
                </div>
            </section>
        );
    }
}

