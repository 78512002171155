'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import AuthStore from '../../stores/AuthStore';

import './FileUpload.scss';

import { getConfig } from '../../utils/Env';
import Analytics from '../../utils/Analytics';

const sizeLimt = 50000000;

export default class FileUpload extends Component {
    static propTypes = {
        onUploadSuccess: PropTypes.func,
        extraFormProps: PropTypes.object,
        accept: PropTypes.string,
        capture: PropTypes.bool,

        protection: PropTypes.string,
        endpoint: PropTypes.string,
    };

    static defaultProps = {
        accept: "image/jpeg,image/png,image/gif",
        capture: false,
        button: 'Upload image',
        uploading: 'Uploading...',
        extraFormProps: {},
        protection: 'private',
        endpoint: '/files',
        className: "file-upload",
        buttonClassName: "default-file-upload-btn",
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            progress: false,
            error: null,
        };
    }

    componentDidUpdate = (prevProp, prevState) => {
        const { isUploading } = this.state;
        const { onUploading } = this.props;
        if (onUploading && prevState.isUploading !== isUploading) {
            onUploading(isUploading);
        }
    }

    onChangeFile = (ev) => {
        const { protection, endpoint, onUploadSuccess, onUploadError } = this.props;
        const { confirm } = this.context;
        const formData = new FormData(this.form);


        if (ev.target.files && ev.target.files[0] && ev.target.files[0].size > sizeLimt) {
            Analytics.fileUploadFailed(ev.target.files[0].size);
            confirm(
                "Sorry, we couldn't upload your file. The image size is too large. Please resize it and try again. Maximum file size: 50MB.",
                () => false,
                null,
                {acceptText: 'Close', rejectText: '', titleText: 'Unable to upload file'}
            );
            return;
        }

        ev.preventDefault();

        formData.append('protection', protection);

        this.setState({isUploading: true, error: null});

        AuthStore.fetch(getConfig('users_api') + endpoint, {method: 'POST', body: formData}).then(
            response => {
                this.setState({error: null, isUploading: false});

                onUploadSuccess && onUploadSuccess(response);
            },
            error => {
                this.setState({
                    isUploading: false,
                    error: error && error.message || 'unknown error'
                });

                const errorMessage = error && error.message || 'unknown error occurred'

                if (onUploadError) {
                    onUploadError && onUploadError(errorMessage);
                } else {
                    confirm(
                        errorMessage,
                        () => false,
                        null,
                        {acceptText: 'Close', rejectText: '', titleText: 'Unable to upload file'}
                    );
                }
            }
        );
    }

    render() {
        const { className, buttonClassName, button, uploading, accept, capture, doNotOpen, hideProgressBar} = this.props;
        const { isUploading } = this.state;

        return (
            <form className={className} data-uploading={isUploading} ref={node => this.form = node} onSubmit={(ev) => ev.preventDefault()}>
                <button className={buttonClassName} onSubmit={(ev) => ev.preventDefault()}>
                    {(isUploading && !hideProgressBar) ? <span className="upload-bar-background">
                                <span className="upload-bar-fill"/>
                             </span>
                    : null}
                    <span className="uploading-text" data-uploading={isUploading}>{(isUploading && !hideProgressBar) ? <>{uploading}<i className="icon-spinner" /></> : button}</span>
                </button>

                {(!isUploading && !doNotOpen) ?
                    <input onChange={this.onChangeFile} type="file" name="file"
                        accept={accept} capture={capture} data-testid="file-upload-input"/>
                : null}
            </form>
        );
    }
}
