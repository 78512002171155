'use strict';

import React, { Component } from 'react';
import FoodEditor from '../../Editor.react';
import PropTypes from 'prop-types';
import Alert from '../../../Widgets/Alert/Alert.react';
export default class FoodEditorModalContent extends Component {
    static propTypes = {
        handleBarcodeRetryCount: PropTypes.func,
        resetBarcodeRetryCount: PropTypes.func,
        uuid: PropTypes.string,
        profile: PropTypes.object,
        introMessage: PropTypes.string,
        defaultBarcode: PropTypes.string,
        retryBarcodeScan: PropTypes.func,
        onSaveFood: PropTypes.func,
        setSaving: PropTypes.func,
        closeModal: PropTypes.func,
        bodyClassName: PropTypes.string,
        food: PropTypes.object,
        displayError: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            okTextBtn: 'Save Food',
            cancelTextBtn: 'Cancel',
            saving: false        };
    }


    componentDidMount = () => {
        const { introMessage } = this.props;

        this.setState({ okTextBtn: introMessage ? 'Create a Food' : 'Save Food' });
        this.setState({ cancelTextBtn: introMessage ? 'Retry' : 'Cancel' });
    };

    okAction = (foodEditorRef) => {
        const { closeModal } = this.props;

        if (!foodEditorRef) {
            return;
        }

        if (foodEditorRef.state?.screen == 'intro') {
            this.setState({ okTextBtn: 'Save Food', cancelTextBtn: 'Cancel' });
            return foodEditorRef.setState({ screen: 'editor' });
        }

        return foodEditorRef.state.dirty ? foodEditorRef.onSaveFood() : closeModal();
    };

    openCustomScreenModal = (foodEditorRef) => {
        const { closeModal, resetBarcodeRetryCount } = this.props;
        resetBarcodeRetryCount();
        if (foodEditorRef.state?.screen == 'intro') {
            this.setState({ okTextBtn: 'Save Food', cancelTextBtn: 'Cancel' });
            return foodEditorRef.setState({ screen: 'editor' });
        }

        return foodEditorRef.state.dirty ? foodEditorRef.onSaveFood() : closeModal();
    }

    getCurrentEditorScreen = ()=>{
        if(this.props.displayError)
            {
                return "editor";
            }
        return "intro";
    }

    getOkTextButton  = () => {
        if(this.getCurrentEditorScreen() ==="editor")
            {
               return "Save Food";
            }
        return "Create a Food";
    }

    getCancelTextButton = () => {
        if(this.getCurrentEditorScreen()=== "intro")
            {
                return "Cancel"
            }
        return "Retry";
    }

    cancelAction = (foodEditorRef) => {

        const { closeModal , handleBarcodeRetryCount } = this.props;

         if (foodEditorRef && foodEditorRef.state?.screen == 'intro') {
            handleBarcodeRetryCount();
            }
            else{
                return closeModal();
            }
       

    };


    setSaving = (isSaving) => {
        this.setState({ saving: isSaving });
    };

    render() {
        const {
            uuid = null,
            food = null,
            profile = null,
            introMessage = null,
            defaultBarcode = '',
            bodyClassName = 'el-modal1-body-container',
            closeModal = () => {},
            onSaveFood = () => {},
            retryBarcodeScan = () => {},
            showReportIssue = null,
            displayError,
        } = this.props;
        const { okTextBtn, cancelTextBtn, saving } = this.state;
        const foodEditorRef = React.createRef();

        return (
            <>
                <div className={`el-modal-body-container ${bodyClassName} el-fonts`}>
                    <FoodEditor
                        ref={foodEditorRef}
                        uuid={food ? food.uuid : uuid ? uuid : null}
                        profile={profile}
                        introMessage={introMessage}
                        closeModal={closeModal}
                        defaultBarcode={defaultBarcode}
                        onSaveFood={onSaveFood}
                        retryBarcodeScan={retryBarcodeScan}
                        showReportIssue={showReportIssue}
                        setSaving={this.setSaving}
                    />
                </div>

                {foodEditorRef && !displayError && (
                    <footer>
                        <div>
                            {closeModal ? (
                                <button
                                    className="el-modal-cancel-btn"
                                    onClick={(evt) => this.cancelAction(foodEditorRef.current)}
                                >
                                    {cancelTextBtn}
                                </button>
                            ) : null}

                            <button
                                disabled={saving}
                                className="el-modal-ok-btn"
                                onClick={(evt) => this.okAction(foodEditorRef.current)}
                            >
                                {okTextBtn}
                            </button>
                        </div>
                    </footer>
                )}
                {this.props.displayError && (
                    <footer>
                        <Alert type="error" buttonText="Ok" onClickButton={()=> {this.openCustomScreenModal(foodEditorRef.current)}}>
                        We couldn't find this food item in our database. Please take a moment to enter the details for accurate logging and nutrition calculations.
                            </Alert>
                            
                    </footer>
                )}
            </>
        );
    }
}
