'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import { Link } from 'react-router';
import moment from 'moment';
import store from 'store';
import cookie from 'cookie-monster';

import './CookieConsentBanner.scss';
import AuthStore from '../../stores/AuthStore';

const CONSENT_STORE_KEY = 'cookie-consent';

export default class CookieConsentBanner extends Component {
    static contextTypes = {
        location: PropTypes.object,
    }

    constructor(props, context) {
        super(props, context);

        this.state = {
            isVisible: this.shouldShowCookieBanner(context.location),
            drawer: false,
        };
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({drawer: true}));
    }

    shouldShowCookieBanner = (location) => {
        // Never show the banner on the wrapped app
        if (window.cordova) {
            return false;
        }

        // Don't show the banner on a few pages
        if (location && location.pathname.includes('/accept-invite')) {
            return false;
        }

        // Do we have a localstore flag indicating we've set the cookie?
        if (store.get(CONSENT_STORE_KEY)) {
            // Ensure the cookie is set too
            cookie.setItem(CONSENT_STORE_KEY, '1', {expires: moment().add(10, 'years').toDate().toUTCString()});
            return false;
        }

        if (cookie.getItem(CONSENT_STORE_KEY)) {
            // Why didn't we have the localstorage copy? who cares, set it
            store.set(CONSENT_STORE_KEY, true);
            return false;
        }

        if (AuthStore.getStorage() == 'memory') {
            return false;
        }

        return true;
    }

    closeModal = () => {
        this.setState({drawer: false}, () => {
            setTimeout(() => {
                // Set both cookie and localstorage
                store.set(CONSENT_STORE_KEY, true);
                cookie.setItem(CONSENT_STORE_KEY, '1', {expires: moment().add(10, 'years').toDate().toUTCString()});
                this.setState({isVisible: false});
            }, 350);
        });
    }

    render = () => {
        const { isVisible, drawer } = this.state;

        if  (!isVisible) {
            return <span />;
        }

        return (
            <div className="cookie-consent-banner" data-drawer={drawer}>
                <div className="inner-container">
                    <p>EatLove uses cookies to provide you with a great user experience. By using EatLove, you accept our use of cookies. <Link to="/privacy">Learn more</Link> about the information we collect.</p>

                    <footer><button onClick={this.closeModal}>Accept</button></footer>
                </div>
            </div>
        );
    }
}
