'use strict';

export function scrollTextFieldIntoView(ev) {
    const { target } = ev || {};

    if (!(target && typeof target.scrollIntoViewIfNeeded === 'function')) {
        return;
    }

    setTimeout(() => {
        target.scrollIntoViewIfNeeded({block: 'center', inline: 'center', behavior: 'smooth'});
    }, 750);
}
