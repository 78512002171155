'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';

import { roundForHumans } from '../../../utils/Math';
import ImgResized from '../../Widgets/ImgResized.react';

import './CardAbstract.scss';

export default class BrandCard extends Component {
    static propTypes = {
        brand: PropTypes.object,
    };

    static contextTypes = {
        onSelectBrand: PropTypes.func,
        showBrandDetails: PropTypes.func,
    };

    onClick = () => {
        const { onSelectBrand, showBrandDetails } = this.context;
        const { brand } = this.props;

        if (onSelectBrand) {
            onSelectBrand(brand);
        } else if (showBrandDetails) {
            showBrandDetails(brand);
        }
    }

    render = () => {
        const { brand } = this.props;

        const categoryIcons = {
            'Restaurant': 'icon-store-bought-meals thumb',
            'Grocery Store': 'icon-cart3 thumb',
            'Caterer': 'icon-restaurant-meals thumb',
            'Meal Kit': 'icon-silverware thumb',
            'Ready Made Meals': 'icon-silverware thumb',
            'Food Brand': 'icon-restaurant-meals thumb',
        };

        return (
            <li className="results-mini-card" onClick={this.onClick}>
                {brand.image
                    ? <ImgResized className="thumb" src={brand.image} width={100} height={100} />
                    : <i className={categoryIcons[brand.category] || "icon-store-bought-meals thumb"} />
                }
                <span className="title">{brand.title}</span>
                <span className="cals">{brand.category}</span>
            </li>
        );
    }
}
