import moment from 'moment';
import UserStore from '../stores/UserStore';
import energyFormulas from './EnergyCalculator';
import { calculateNearestPortionSize } from '../tables/portions';

const mifflin = energyFormulas.filter((f) => f.prop === 'mifflin')[0];

export const calculateTargetEnergy = (profile) => {
    const {
        gender,
        pregnant,
        lactating,
        birthdate,
        weight_kg,
        height_cm,
        activity_level,
        goal_weight_kg,
        portion_resolution,
        fetus_count,
        due_date,
    } = profile;

    let age = 32,
        ageDays = 32 * 365;

    if (!(gender && birthdate && weight_kg && height_cm && activity_level)) {
        return { target_energy_kcal: null, portion: 1 };
    }

    if (moment(birthdate).isValid()) {
        age = moment().diff(birthdate, 'year');
        ageDays = moment().diff(birthdate, 'day');
    }

    const params = {
        gender,
        weightKg: weight_kg,
        heightCm: height_cm,
        activity: activity_level,
        age,
        ageDays,
    };
    let target_energy_kcal = Math.round(mifflin.compute(params));

    if (target_energy_kcal === false) {
        return { target_energy_kcal: null, portion: 1 };
    }

    if (gender === 'female' && pregnant) {
        const weeksPregnant = moment().diff(moment(due_date).subtract(42, 'weeks'), 'weeks');

        // First Trimester - NO ADDITIONAL CALORIE NEEDED
        // Second Trimester
        if (weeksPregnant >= 14 && weeksPregnant <= 27) {
            // 340 kcal for the first baby, and 175 kcal for each additional
            target_energy_kcal += 340 + (fetus_count - 1) * 175;
        }

        // Third Trimester
        if (weeksPregnant >= 28) {
            target_energy_kcal += 450 + (fetus_count - 1) * 225;
        }
    } else if (goal_weight_kg) {
        if (goal_weight_kg > weight_kg * 1.025) {
            // Gain weight
            target_energy_kcal += 500;
        } else if (goal_weight_kg < weight_kg * 0.975) {
            // Lose weight
            target_energy_kcal -= 500;
        }
    }

    if (gender === 'female' && lactating) {
        target_energy_kcal += 500;
    }

    if (age > 12 && target_energy_kcal < 1200) {
        target_energy_kcal = 1200;
    } else if (age <= 12 && target_energy_kcal < 900) {
        target_energy_kcal = 900;
    }

    const { standard_calories = 2000, inhibit_deadzone = false } =
        UserStore.getUser()?.features?.energy_estimator || {};

    const portion = calculateNearestPortionSize(
        target_energy_kcal,
        portion_resolution,
        standard_calories,
        inhibit_deadzone
    );

    return { target_energy_kcal, portion: portion ? portion : 1 };
};
