'use strict';

import AuthStore from '../stores/AuthStore';
import { getConfig } from '../utils/Env';

export function inquire(inquiries) {
    return AuthStore.fetch(getConfig('users_api') + '/decider', {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=decider/inquiry/1'},
        body: JSON.stringify(inquiries),
    }, true).then(json => json.elements);
}

export function isValidEmail(email) {
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    return re.test(String(email).toLowerCase());
}

export default {
    inquire,
    isValidEmail,
};
