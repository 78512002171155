'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import indexBy from 'lodash.indexby';

import { getDietsFromTags } from '../../../utils/Diets';

import { fetchDocumentsById } from '../../../utils/Content';
import TaxonomyStore from '../../../stores/TaxonomyStore';
import TaxonomyActions from '../../../actions/TaxonomyActions';

import './TastePreferences.scss';

export default class TastePreferences extends Component {
    static propTypes = {
        patient: PropTypes.object,
        force: PropTypes.bool,
    };
    constructor(props) {
        super(props);
        this.state = {
            foods: {},
            keyIndex: TaxonomyStore.getKeyIndex(),
        };
    }

    componentDidMount = () => {
        TaxonomyActions.ensureLoaded();
        TaxonomyStore.addChangeListener(this.onTaxonomyStoreChange);

        this.loadExcludeFoods();
    }

    componentWillUnmount = () => {
        TaxonomyStore.removeChangeListener(this.onTaxonomyStoreChange);
    }

    onTaxonomyStoreChange = () => {
        this.setState({keyIndex: TaxonomyStore.getKeyIndex()});
    }

    loadExcludeFoods = async () => {
        const { preferences = {} } = this.props.patient;
        const { exclude_foods = [] } = preferences;

        const documents = await fetchDocumentsById(exclude_foods);
        const foods = indexBy(documents, 'uuid');

        this.setState({foods});
    }

    render() {
        const { foods, keyIndex } = this.state;
        const { patient, force } = this.props;
        const { preferences = {}, completed = [] } = patient;
        const { avoidances, exclude_foods, prefer_cuisines = [], avoid_cuisines = [] } = (preferences || {});

        if (!completed.includes('taste') && !force) {
            return (
                <div className="patient-nutrition-prescription">
                    <h3>No taste preferences set</h3>
                </div>
            );
        }

        const diets = getDietsFromTags(preferences.diets || []).map(d => d.name);

        return (
            <div className="patient-nutrition-prescription patient-taste-preferences">
                <ul>
                    <li><em>Diets</em>: {diets.join(', ') || 'no special diets'}</li>
                    <li><em>Avoidances</em>: {avoidances.map(key => keyIndex[key] || {title: key}).map(v => v.title).join(', ')}</li>
                    <li><em>Excluded Foods</em>: {exclude_foods.map(uuid => foods[uuid]).filter(v => v).map(food => food.name).join(', ')}</li>
                </ul>
            </div>
        );
    }
}
