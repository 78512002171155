'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import ImgResized from '../Widgets/ImgResized.react';
import styles from './Merchant.scss';

export default class Merchant extends Component {

    static propTypes = {
       merchant: PropTypes.object,
    };

    render = () => {
        if (!this.props.merchant) {
            return <span />
        }
        // EatLove menus are the only menus that don't have an image
        let altPublisherImage = false;
        if (this.props.merchant === 'EatLove') {
            altPublisherImage = true;
        }

        return (
            <section className="publisher-block">
                <div className="inner-container">
                    <section className="publisher-container" data-alt={altPublisherImage}>
                        <div>
                            <ImgResized src={this.props.merchant.image} width={200} height={200} />
                        </div>
                        <div>
                            <h3>by {this.props.merchant.name}</h3>
                            <p>
                                {this.props.merchant.description}
                            </p>
                        </div>
                    </section>
                </div>
            </section>
        );
    }
}
