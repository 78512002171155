'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { pluralize, singularize } from 'inflected';
import moment from 'moment';

import AuthStore from '../../../../../stores/AuthStore';
import UserStore from '../../../../../stores/UserStore';
import BoardStore from '../../../../../stores/BoardStore';
import MealStore from '../../../../../stores/MealStore';

import { updateCachedDocuments } from '../../../../../utils/Content';
import { getAssetsForMeals, getPrimaryMeal } from '../../../../../utils/Meals';
import { getNutrientsForMeals } from '../../../../../utils/Nutrition';
import { isSingular, roundForHumans } from '../../../../../utils/Math';
import { getConfig } from '../../../../../utils/Env';

import SmartFraction from '../../../../Widgets/SmartFraction.react';
import ImgResized from '../../../../Widgets/ImgResized.react';

import './FrequentlyUsed.scss';

export default class FrequentlyUsed extends Component {

    static contextTypes = {
        addSwapContext: PropTypes.object,
        user: PropTypes.object,
        isMobile: PropTypes.bool,

        onSelectRecipe: PropTypes.func,
        onSelectFood: PropTypes.func,
        onSelectFrequentlyUsed: PropTypes.func.isRequired,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            assets: {},
            mode: 'foods',
            loading: true,
            showAll: false,
            popularFoods: [],
        };
    }

    componentDidMount = () => {
        this.loadFrequentlyUsed();
    }

    hydrateFrequentlyUsed = (results, user, assets) => {
        let frequentlyUsed = [];
        const contents = {...assets.recipes, ...assets.foods};

        results.elements.forEach(frequentMeal => {
            frequentMeal.primary = getPrimaryMeal(frequentMeal.items, assets.recipes, assets.foods);

            frequentMeal.items.forEach(frequentItem => {
                const { content, titles, photos } = getPrimaryMeal([frequentItem], assets.recipes, assets.foods);

                if (!content) {
                    return;
                }

                if (!frequentItem.logged_amount && content.default_unit) {
                    const { description, amount, grams, milliliters } = content.default_unit || {};

                    Object.assign(frequentItem, {
                        logged_unit: description,
                        logged_amount: amount,
                        logged_grams: grams,
                        logged_milliliters: milliliters,
                    });
                } else if (!frequentItem.logged_amount && content.type === 'recipe' && content.milliliters_per_serving) {
                    Object.assign(frequentItem, {
                        logged_amount: 1,
                        logged_unit: 'serving',
                        logged_milliliters: content.milliliters_per_serving,
                    });
                }

                frequentItem.primary = {content, titles, photos};
                frequentItem.nutrients = getNutrientsForMeals([frequentItem], contents, user.portion);
                frequentItem.isBoarded = BoardStore.getBoardsByResourceId(content.uuid).length > 0
            });

            frequentMeal.nutrients = getNutrientsForMeals(frequentMeal.items, contents, user.portion);

            frequentlyUsed.push(frequentMeal);
        });

        return frequentlyUsed;
    }

    getPopularFoods = (user, mealType) => {
        const mealTypeMap = {'Dinner': 'Main Dish'}

        const params = {
            language: user.language,
            types: ['food'],
            filters: {
                'tags': ['Frequently Used', mealTypeMap[mealType.main_dish] || mealType.main_dish],
                '!ingredient_tags': user.preferences.avoidances,
            },
            include_merchants: user?.features?.source_libraries || null
        }

        return AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        });
    }

    unshiftRecentMeals = (results, mealType) => {
        // We don't care about anything that's not our current meal type and isn't fresh food.
        const allMeals = MealStore.getMeals().filter(meal => meal.meal === mealType.name && ['fresh', 'food'].includes(meal.meal_type) && !meal.deleted);

        // Add contemporary dishes
        const startDate = moment().subtract(2, 'day');
        const endDate = moment().add(5, 'day');
        const rawContemporary = allMeals.filter(meal => startDate.isSameOrBefore(meal.date, 'day') && endDate.isSameOrAfter(meal.date, 'day'));

        const contemporary = rawContemporary.reduce((distinctContemporary, meal) => {
            if (!distinctContemporary.some(distinctMeal =>  ((meal.food_uuid && meal.food_uuid === distinctMeal.food_uuid) || (meal.recipe_uuid && meal.recipe_uuid === distinctMeal.recipe_uuid)))) {
                distinctContemporary.push(meal);
            }
            return distinctContemporary;
        },[]);

        const index = {};
        results.elements.forEach(used => {
            const key = used.items.map(item => item.recipe_uuid || item.food_uuid)
                                  .filter(v => v)
                                  .sort((a, b) => a.localeCompare(b))
                                  .join(',');

            index[key] = used;
        });

        // Group contemporary's by their meal slot
        const collector = {};
        contemporary.sort((a, b) => moment(b.date).valueOf() - moment(a.date).valueOf())
                    .forEach(meal => {

            // Don't add a meal if it was already in the results list.
            if ((meal.recipe_uuid && results.elements.find(used => used.items.find(item => item.recipe_uuid === meal.recipe_uuid))) ||
                (meal.food_uuid && results.elements.find(used => used.items.find(item => item.food_uuid === meal.food_uuid)))) {
                return;
            }

            const slot = meal.date + '-' + meal.meal;

            collector[slot] = collector[slot] || {meal: meal.meal, last_used: meal.date, items: []};
            collector[slot].items.push(meal);
        });

        Object.keys(collector).forEach(slot => {
            const key = collector[slot].items.map(item => item.recipe_uuid || item.food_uuid)
                                             .filter(v => v)
                                             .sort((a, b) => a.localeCompare(b))
                                             .join(',');
            if (!key) {
                return;
            }

            if (index[key]) {
                return;
            }

            const used = {
                meal: mealType.name,
                last_used: collector[slot].last_used,
                items: collector[slot].items,
            };

            results.elements.unshift(used);
            index[key] = used;
        });

        // Add recently used
        if (moment().isAfter('2020-10-20')) {
            const fiveDaysAgo = moment().subtract(5, 'day');
            let recentlyAdded = allMeals.filter(meal => !meal.plan_uuid && fiveDaysAgo.isBefore(meal.created)).slice(0, 10)
                                        .sort((a, b) => moment(b.created).valueOf() - moment(a.created).valueOf());

            recentlyAdded.forEach((recent) => {
                const key = recent.recipe_uuid || recent.food_uuid;

                if (!key) {
                    return;
                }

                // Don't add a meal to the results list more than once.
                if ((recent.recipe_uuid && results.elements.find(used => used.items.find(item => item.recipe_uuid === recent.recipe_uuid))) ||
                    (recent.food_uuid && results.elements.find(used => used.items.find(item => item.food_uuid === recent.food_uuid))) ||
                    index[key]) {
                    return;
                }

                const used = {
                    meal: recent.meal,
                    last_used: recent.date,
                    items: [{
                        ...recent,
                    }]
                };

                results.elements.unshift(used);
                index[key] = used;
            });
        }

        return results;
    }

    loadFrequentlyUsed = async () => {
        const { user } = this.context;
        const { mealType } = this.props;
        let { mode } = this.state;

        const query = {meal: mealType.name};
        let results = await AuthStore.fetch({url: UserStore.getLinks().frequentlyUsed, query});

        results = this.unshiftRecentMeals(results, mealType);

        const meals = results.elements.reduce((all, freq) => {
            const itemsToAdd = freq.items.filter(item => !all.find(used =>
                (item.food_uuid && used.food_uuid && item.food_uuid == used.food_uuid) ||
                (item.recipe_uuid && used.recipe_uuid && item.recipe_uuid == used.recipe_uuid)
            ))
            return all.concat(itemsToAdd)
        }, []);

        const foods = meals.filter(meal => meal.meal_type === 'food');
        const foodUuids = foods.map(meal => meal.food_uuid).filter(v => v);
        const assets = await getAssetsForMeals(meals);

        const frequentlyUsed = this.hydrateFrequentlyUsed(results, user, assets);

        if (frequentlyUsed.length <= 0) {
            mode = 'foods';
        }

        let popularFoods = await this.getPopularFoods(user, mealType);

        updateCachedDocuments(popularFoods.elements);

        // Strip any logged or recently used foods from our popular foods (so we don't show them twice)
        popularFoods.elements = popularFoods.elements.filter(pf => !foodUuids.includes(pf.uuid));

        this.setState({frequentlyUsed, meals, assets, mode, loading: false, popularFoods: popularFoods.elements});
    }

    renderPopularFood = (food, i) => {
        const { user, onSelectFood } = this.context;
        const { hide_nutrition = false } = user;

        let calories = (food.nutrients && food.nutrients.values && food.nutrients.values[208]) || 0;
        let serving_size = food.serving_unit === 'ml' ? food.milliliters_per_serving : food.grams_per_serving;
        let servingDescription = 'serving';

        if (food.default_unit && serving_size) {
            let { description, amount, grams, milliliters } = food.default_unit;

            calories = food.serving_unit === 'ml'
                     ? (calories / serving_size * milliliters)
                     : (calories / serving_size * grams);

            description = isSingular(amount) ? singularize(description) : pluralize(description)
            description = description || 'item';

            servingDescription = <span><SmartFraction value={amount} showZero={true} /> {description}</span>
        }

        return (
            <li key={i} data-food={food.brand_name && food.brand_name.length > 0} onClick={() => onSelectFood(food)}>
                {food.image
                    ? <ImgResized className="thumb" src={food.image} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{food.brand_name ? <em>{food.brand_name}</em> : null}{food.pretty_name || food.name}</span>
                {!hide_nutrition ? <span className="cals">{roundForHumans(calories)} kcal per {servingDescription}</span> : null}
            </li>
        );
    }


    onSelectItem = (items, assets) => {
        const { onSelectFrequentlyUsed } = this.context;

        onSelectFrequentlyUsed(items, assets);
    }

    renderFrequentMeal = ({primary = {}, nutrients = {}, items = []}, i) => {
        const { assets } = this.state;
        const { user: { preferences } } = this.context;
        const { content, titles, photos } = primary;
        const { hide_nutrition = false } = preferences;

        let calories = nutrients[208] || 0;
        let servingDescription = 'serving';

        if (!content) {
            return;
        }

        if (items.length == 1) {
            let { logged_amount, logged_unit, logged_grams, logged_milliliters } = items[0];

            if (logged_amount) {
                logged_unit = isSingular(logged_amount) ? singularize(logged_unit) : pluralize(logged_unit)
                logged_unit = logged_unit || 'item';

                servingDescription = <span><SmartFraction value={logged_amount} showZero={true} /> {logged_unit}</span>
            }
        }

        return (
            <li key={i} data-food={content.brand_name && content.brand_name.length > 0} onClick={() => this.onSelectItem(items, assets)}>
                {photos[0]
                    ? <ImgResized className="thumb" src={photos[0].url} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{content.brand_name ? <em>{content.brand_name}</em> : null}{titles.join(' + ')}</span>
                {!hide_nutrition ?
                    <span className="cals">{roundForHumans(calories)} kcal per {servingDescription}</span>
                : null}
            </li>
        );
    }

    renderTypePicker = () => {
        const { meals, assets, mode, frequentlyUsed, popularFoods } = this.state;

        const typePicks = [];

        if ((meals && meals.filter(meal => meal.meal_type == 'food').length > 0) || (popularFoods && popularFoods.length > 0)) {
            typePicks.push(<li key="foods"><button data-active={mode === "foods"} onClick={() => this.setState({mode: 'foods'})}>foods</button></li>);
        }

        if (frequentlyUsed && frequentlyUsed.length > 0) {
            typePicks.push(<li key="meals"><button data-active={mode === "meals"} onClick={() => this.setState({mode: 'meals'})}>meals</button></li>);
        }

        if (assets.recipes && Object.keys(assets.recipes).length > 0) {
            typePicks.push(<li key="recipes"><button data-active={mode === "recipes"} onClick={() => this.setState({mode: 'recipes'})}>recipes</button></li>);
        }

        if (typePicks.length > 1) {
            return <ul className="type-picker">{typePicks}</ul>
        }
    }


    getFrequentlyUsedArray = () => {
        const { frequentlyUsed, meals, mode} = this.state

        let resultArray = [];

        if (mode === 'foods' && meals && meals.length) {
            resultArray = meals.filter(meal => meal.meal_type === 'food');

        } else if (mode === 'meals') {
            resultArray = frequentlyUsed;

        } else if (mode === 'recipes' && meals && meals.length) {
            resultArray = meals.filter(m => m.meal_type === 'fresh');
        }

        return resultArray || [];
    }

    render = () => {
        const { frequentlyUsed, mode, popularFoods, showAll, loading } = this.state;
        const { isMobile } = this.context;

        const numItemsToDisplay = isMobile ? 5 : 15;

        const results = this.getFrequentlyUsedArray();
        const primaryItemsToShow = showAll || results.length === 0 ? results : results.slice(0,numItemsToDisplay);
        const remainingItemsCount = Math.max(numItemsToDisplay - results.length, 0);

        const popularFoodsToShow = showAll || !popularFoods || popularFoods.length === 0 ? popularFoods : popularFoods.slice(0, remainingItemsCount);
        const totalItemCount = mode === 'foods' ? primaryItemsToShow.length +  popularFoodsToShow.length : primaryItemsToShow.length;

        if (loading) {
            return (
                <div  className="frequently-used" >
                    <h3>Frequently Used</h3>
                    <div className="frequently-used-loading">
                        <i className="icon-spinner"/>
                    </div>
                </div>
            );
        }

        return (
            <div className="frequently-used">

                {(frequentlyUsed && frequentlyUsed.length > 0) || (popularFoods && popularFoods.length > 0) ?
                    <div>
                        <h3>Frequently Used</h3>

                        {this.renderTypePicker()}

                        {mode === 'foods' ?
                            <ul className="frequent-list">
                                {primaryItemsToShow.map((meal, i) => {
                                    return meal.primary ? this.renderFrequentMeal({items: [meal], nutrients: meal.nutrients, primary: meal.primary}, i) : null;
                                })}
                                {popularFoodsToShow ? popularFoodsToShow.map(this.renderPopularFood) : null}
                            </ul>
                        : null}

                        {mode === 'meals' ?
                            <ul className="frequent-list">
                                {primaryItemsToShow.map((fused, i) => this.renderFrequentMeal(fused, i))}
                            </ul>
                        : null}

                        {mode === 'recipes' ?
                            <ul className="frequent-list">
                                {primaryItemsToShow.filter(m => m.meal_type === 'fresh').map((meal, i) => {
                                    return this.renderFrequentMeal({items: [meal], nutrients: meal.nutrients, primary: meal.primary}, i);
                                })}
                            </ul>
                        : null}
                        {!showAll && totalItemCount >= numItemsToDisplay ?
                            (<span className="show-more-toggle" onClick={() => this.setState(prevState => ({showAll: !prevState.showAll}))}>
                                <button className="sub-action-btn">Show more</button>
                                <i className="icon-chevron-down"></i>
                            </span>)
                        : null}
                    </div>
                : null}
            </div>
        );
    }
}
