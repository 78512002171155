'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './FilterUnavailable.scss';

export default class FilterUnavailable extends Component {
    static propTypes = {
    };

    static contextTypes = {
        stripRecipeOnlyFilters: PropTypes.func,
        stripPlanOnlyFilters: PropTypes.func,
    };

    includeNeededType = () => {
        const { needDocType, params, onChangeParams } = this.props;
        const { stripRecipeOnlyFilters, stripPlanOnlyFilters } = this.context;

        if (!params.types.includes(needDocType)) {
            params.types.push(needDocType);

            if (needDocType === 'plan') {
                stripRecipeOnlyFilters(params);
            } else {
                stripPlanOnlyFilters(params);
            }
        }

        onChangeParams(params, true);
    }

    render() {
        const { needDocType } = this.props;

        return (
            <div className="global-search-filter-unavailable">
                <p>These filters only work on {needDocType == 'recipe' ? 'recipes' : 'meal plans'}</p>

                <footer>
                    <button onClick={this.includeNeededType}>Include {needDocType == 'recipe' ? 'recipes' : 'meal plans'}</button>
                </footer>
            </div>
        );
    }
}
