'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import ImgResized from '../Widgets/ImgResized.react';

import { getLoggedServingsOfRecipe } from '../../utils/Meals';
import { roundForHumans, smartCeil } from '../../utils/Math';
import MealStore from '../../stores/MealStore';
import SmartFraction from '../Widgets/SmartFraction.react';
import FoodUnitsSelector from '../DailyLog/FoodUnitsSelectorModal.react';

import './HowMuchToEat.scss';

export default class HowMuchToEat extends Component {

    static propTypes = {
       uuid: PropTypes.string,
       meal:  PropTypes.object,
       recipe: PropTypes.object,
       participants: PropTypes.array,
       scaling: PropTypes.number,
       factor: PropTypes.number,
       profile: PropTypes.object,
       onRemoveParticipant: PropTypes.func,
       surplus: PropTypes.bool,
       isLeftover: PropTypes.bool,
       dishNumber: PropTypes.number,
       onChangePortionAmount: PropTypes.func,

       width: PropTypes.number,
       height: PropTypes.number,
    };

    static defaultProps = {
        width: 500,
        height: 500,
        factor: 1,
    }

    static contextTypes = {
        viewportWidth: PropTypes.number,
        isPro: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const thetaStart = 2 * Math.PI * Math.random();
        const meals = MealStore.getMeals();

        const meal = props.meal;

        this.state = {
            thetaStart, // start theta off somewhere random
            meals,
            meal,

            slices: this.getRecipePortionSlices(
                props.profile, props.participants, props.recipe, props.scaling, props.factor, props.width, props.height, thetaStart, props.uuid, meals, props.surplus, meal
            ),
        };
    }

    componentDidMount = () => {
        MealStore.addChangeListener(this.onMealStoreChange);
    }

    onMealStoreChange = () => {
        const meals = MealStore.getMeals();
        this.setState({meals});
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.setState({slices: this.getRecipePortionSlices(
            nextProps.profile, nextProps.participants, nextProps.recipe, nextProps.scaling, nextProps.factor,
            nextProps.width, nextProps.height, this.state.thetaStart, nextProps.uuid, this.state.meals, nextProps.surplus, nextProps.meal
        )});
    }

    getRecipePortionSlices = (profile, participants, recipe, scaling, factor, width, height, thetaStart, uuid, meals, surplus, meal) => {
        if (!participants || !recipe) {
            return null;
        }

        const servings = recipe.servings * factor * (scaling || 1);

        const colors = [
            {slice: '#be3b8b', text: '#434243'},
            {slice: '#0f8ebf', text: '#212226'},
            {slice: '#0b9669', text: '#e6e7ea'},
            {slice: '#ff7735', text: '#e6e7ea'},
            {slice: '#FFA579', text: '#434243'},
            {slice: '#62EBA7', text: '#434243'},
            {slice: '#EB959E', text: '#434243'},
            {slice: '#B4DEFF', text: '#434243'},
            {slice: '#F8D692', text: '#434243'},
            //{slice: '#000000', text: '#ffffff'},
        ];

        let portions = [];

        // First, add the patient a single serving
        const cx = width / 2;
        const cy = height / 2;
        const radius = width / 2 * 1.00;
        const tradius = radius * 0.75; // text position radius
        const step = 1 / servings * 2 * Math.PI;
        let theta = thetaStart;
        let needed = 0;
        let portion_unit = profile.units_mode === 'metric' ? 'g' : 'oz';
        const roundUpToNearestServing = false;
        const loggedServings = getLoggedServingsOfRecipe(meal, recipe, roundUpToNearestServing);

        participants.forEach(member => {
            const isUser = member.uuid === profile.uuid;
            needed += isUser && loggedServings ? loggedServings : member.portion;
            const memberPortion = isUser && loggedServings ? loggedServings : member.portion;

            // Does this profile use metric or english (and do we have a calculated mass)?
            let portion_size = null;

            if (profile.units_mode === 'metric' && recipe.grams_per_serving) {
                portion_size = memberPortion * recipe.grams_per_serving
            }  else if (recipe.grams_per_serving) {
                portion_size = (memberPortion * recipe.grams_per_serving) / 28.3495;
            }

            let portion = {
                name: member.name || 'Family Member',
                portion_size: portion_size,
                portion_unit,

                circle: step * memberPortion >= 2 * Math.PI,

                rx: radius,
                ry: radius,

                // Long arc, if the arc is greater than 180deg, then the arc is "long"
                la: step * memberPortion > Math.PI ? 1 : 0,

                // Arc's circle center X and Y
                cx: cx,
                cy: cy,

                // Arc start position
                sx: cx + radius * Math.cos(theta),
                sy: cy + radius * Math.sin(theta),

                // Arc end position
                ex: cx + radius * Math.cos(theta + step * memberPortion),
                ey: cy + radius * Math.sin(theta + step * memberPortion),

                // Text position
                tx: cx + tradius * Math.cos(theta + step / 2 * memberPortion),
                ty: cy + tradius * Math.sin(theta + step / 2 * memberPortion) + 10,

                color: colors[portions.length % colors.length],
                text: Math.round(1 / servings * 100 * memberPortion) + '%',
                numerator: memberPortion,
                denominator: servings,
                member,
            };

            portions.push(portion);

            theta += step * memberPortion;
        });

        const remaining = servings - needed;
        const noLeftovers = (meals.filter(meal => meal.parent_uuid === uuid) || []).length == 0;
        const noLeftoverTag = recipe.tags.includes("No Leftovers");

        if (remaining) {
            let remaining_size = profile.units_mode === 'metric'
                             ? remaining * recipe.grams_per_serving
                             : (remaining * recipe.grams_per_serving) / 28.3495;

            let leftoverName = "Leftovers";

            if (noLeftovers && noLeftoverTag) {
                leftoverName = "Remaining Servings";
            }

            if (surplus) {
                leftoverName = "Food Surplus";
            }

            portions.push({
                name: leftoverName,
                portion_size: remaining_size,
                portion_unit,

                circle: remaining * step >= 2 * Math.PI, // This should NEVER be true...

                rx: radius,
                ry: radius,

                la: (step * remaining) > Math.PI ? 1 : 0,

                cx: cx,
                cy: cy,

                sx: cx + radius * Math.cos(theta),
                sy: cy + radius * Math.sin(theta),

                ex: cx + radius * Math.cos(theta + step * remaining),
                ey: cy + radius * Math.sin(theta + step * remaining),

                tx: cx + tradius * Math.cos(theta + step * remaining / 2),
                ty: cy + tradius * Math.sin(theta + step * remaining / 2) + 10,

                color: {slice: '#a8acbe', text: '#434243'},
                text: Math.round(remaining / servings * 100) + '%',
                amount: remaining / servings,
                numerator: remaining,
                denominator: servings,
            });
        }

        return portions;
    }

    onChangePortionAmount = (logged_portion, logged_unit, logged_amount, logged_grams, logged_milliliters, unit) => {
        const {onChangePortionAmount, meal} = this.props;
        onChangePortionAmount(meal, logged_portion, logged_unit, logged_amount, logged_grams, logged_milliliters)
    }

    renderSmartFraction = (numerator, denominator) => {

        if (numerator.toString().length < 3 && denominator.toString().length < 3) {
            return <SmartFraction unStyle={true} numerator={numerator} denominator={denominator}/>
        }

        const value = smartCeil(numerator/denominator) > 0 ? smartCeil(numerator/denominator) : numerator/denominator;

        return <SmartFraction unStyle={true} value={value}/>
    }

    render = () => {
        const { recipe, onRemoveParticipant, children, onClick, openEditMealServingsModal, surplus, profile, isLeftover, dishNumber, onChangePortionAmount } = this.props;
        const { slices, meal } = this.state;
        const { viewportWidth, isPro } = this.context;

        const portion_unit = profile.units_mode === 'metric' ? 'g' : 'oz';

        const totalAmount = slices.reduce( (total, member) =>{
            return total + member.portion_size;
        }, 0);

        if (!slices || !recipe.servings) {
            return <span />
        }

        const hasLeftovers = slices.filter(e => e.name === 'Leftovers' || e.name === 'Food Surplus').length > 0

        const disclaimerTextLeftover = `* Following food safety guidelines, it is best to consume cooked food and leftovers between 3-4 days. 
                                It is recommended that anything consumed after these guidelines be frozen until to ready to eat`;

        const disclaimerTextSurplus = `** Food Surplus: Depending on our recipes, the number of servings, and specific portion sizes for who is eating, it is possible to have a surplus of food after leftovers are assigned to family members. This food surplus serving can used as needed to reduce food waste.`

        return (
            <section className="how-much-to-eat">
                <div className="chart">
                    <svg  viewBox="0 0 500 500">
                    {slices.map((p, i) => {
                        if (p.circle) {
                            return (
                                <g key={i}>
                                    <circle cx={p.cx} cy={p.cy} r={p.rx} stroke="white" strokeWidth="10" fill={p.color.slice} />
                                    <text textAnchor="middle" x={p.cx} y={p.cx} fill={p.color.text}>{p.text}</text>
                                </g>
                            );
                        }

                        return (
                            <g key={i}>
                                <path d={`M ${p.sx} ${p.sy} A ${p.rx} ${p.rx} 0 ${p.la} 1 ${p.ex} ${p.ey} L ${p.cx} ${p.cy}`}
                                    stroke="white" strokeWidth="10" fill={p.color.slice}
                                    strokeLinecap="round">
                                </path>
                                <path d={`M ${p.cx} ${p.cy} L ${p.sx} ${p.sy}`}
                                    stroke="white" strokeWidth="10" fill="none"
                                    strokeLinecap="round">
                                </path>
                                <text textAnchor="middle" x={p.tx} y={p.ty} fill={p.color.text}>{p.text}</text>
                            </g>
                        );
                    })}
                    </svg>
                    {recipe.grams_per_serving ?
                        <p><em>{isLeftover ? "Total Leftover Amount: " : "Total Yield: "}</em> {`${roundForHumans(totalAmount)}${portion_unit}`}</p>
                    : null}
                </div>

                <ul className="portion-key">
                    <li>{isLeftover ? "leftover portion sizes for:" : "portion sizes for:"}</li>
                    <li>{dishNumber ? `Dish ${dishNumber}: ` : null} {recipe.title}</li>
                    {slices.map((p, i) => {
                        const isUser = profile.uuid && p?.member?.uuid === profile.uuid;
                        return (
                            <li key={i} data-removable={p.member && onRemoveParticipant ? true : false}
                                >
                                <div className="key-color" className="key-color" style={{ backgroundColor: p.color.slice }}></div>
                                <div>
                                    <span className="member-name">{p.name}</span>
                                    {" • "}
                                    {p.numerator != 1 || p.denominator != 1 ? this.renderSmartFraction(p.numerator, p.denominator) : null}
                                    {p.numerator != 1 || p.denominator != 1 ? ` of total ${p.portion_size ? "(" : ""}` : `Full amount ${p.portion_size ? "(" : ""}`}
                                    {p.portion_size ? <span>{roundForHumans(p.portion_size)}{p.portion_unit}</span> : null}
                                    {p.portion_size ? ")" : null}
                                    {p.member && onRemoveParticipant ? <i className="icon-trash-can2" title={`Remove ${p.name} from this meal`}/> : null}
                                    {p.name == "Food Surplus" && surplus ? "**" : ""}
                                    {p.name == "Leftovers" && !surplus ? "*" : ""}
                                    {isUser && meal?.logged_amount && (!isPro || meal.id) && p.portion_size ? <FoodUnitsSelector buttonText="edit" profile={profile} food={recipe} meal={meal} onChangeAmount={onChangePortionAmount ? this.onChangePortionAmount : null} /> : null}
                                </div>
                            </li>
                        );
                    })}
                    {children ? <li>{children}</li> : null}
                    <button className="el-medium-btn el-wide-btn el-grayish-blue-outline-btn" onClick={onClick}>change who's eating</button>
                    {(hasLeftovers || isLeftover) && viewportWidth >= 750 ?
                        <p className="leftovers-disclaimer">{disclaimerTextLeftover}</p>
                    : null}
                    {hasLeftovers && viewportWidth >= 750 && surplus ?
                        <p className="surplus-disclaimer">{disclaimerTextSurplus}</p>
                    : null}

                </ul>
                {(hasLeftovers || isLeftover) && viewportWidth < 750 ?
                    <p className="leftovers-disclaimer">{disclaimerTextLeftover}</p>
                : null}
                {hasLeftovers && viewportWidth < 750 && surplus ?
                    <p className="surplus-disclaimer">{disclaimerTextSurplus}</p>
                : null}

            </section>
        );
    }
}
