'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import allTags from '../../../../tables/tags';

import './MultiSelect.scss';

export default class CuisinePreferences extends Component {
    static propTypes = {
    };

    constructor(props) {
        super(props);

        const { patient = {} } = props;

        this.state = {
            love: (patient.preferences && patient.preferences.prefer_cuisines || []).slice(0),
            hate: (patient.preferences && patient.preferences.avoid_cuisines || []).slice(0),

            dirty: false,
        };
    }

    toggleTag = (tag) => {
        const { love, hate } = this.state;

        const iLove = love.indexOf(tag);
        const iHate = hate.indexOf(tag);

        // Already in the love array. Remove it from love and add it to hate.
        if (iLove !== -1) {
            love.splice(iLove, 1);
            hate.push(tag);

            this.setState({love, hate});
            return;
        }

        // Already in the hate array. Just remove it from hate.
        if (iHate !== -1) {
            hate.splice(iHate, 1);

            this.setState({hate, dirty: true});
            return;
        }

        // Add to the love array;
        love.push(tag);
        this.setState({love, dirty: true});
    }

    isDirty = () => {
        return this.state.dirty;
    }

    mutate = (patient) => {
        const { love, hate } = this.state;

        patient.preferences = patient.preferences || {};

        patient.preferences.prefer_cuisines = love;
        patient.preferences.avoid_cuisines = hate;

        return patient;
    }

    render() {
        const { patient } = this.props;
        const { love, hate } = this.state;
        const { cuisine } = allTags;

        return (
            <div className="patient-multi-select cuisine-preferences">
                <p>Click once on the cuisines {patient.first_name} likes and twice on the dislikes.</p>

                <ul>
                    {cuisine.tags.map(tag => {
                        const state = love.includes(tag)
                                    ? 'love'
                                    : (hate.includes(tag) ? 'hate' : '');

                        return (
                            <li key={tag} onClick={() => this.toggleTag(tag)} data-state={state}>
                                <span>{tag}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
