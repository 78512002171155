'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import Popup from '../Widgets/Popup.react';
import Calendar from '../Widgets/CalendarWidget.react';

import './DateSelector.scss';

import modalStyles from '../../jsx-styles/modals';

export default class DateSelector extends Component {
    static propTypes = {
        date: PropTypes.object,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        showAbove: PropTypes.bool,
    };

    static defaultProps = {
        className: "search-type-popover date-selector-popover",
        placeholder: 'Choose date',
        showAbove: false,
    }

    static contextTypes = {
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            drawer: false,
        };
    }

    closeModal = () => {
        this.setState({drawer: false}, () => {
            setTimeout(() => this.setState({isModalOpen: false}), 500);
        });
    }

    openModal = () => {
        this.setState({isModalOpen: true, drawer: false}, () => {
            this.setState({drawer: true});
        });
    }

    renderSearchTypeModal = (searchTypes) => {
        const { isModalOpen, drawer } = this.state;

        if (!isModalOpen) {
            return null;
        }

        return (
            <Modal isOpen={isModalOpen}
                closeModal={this.closeModal}
                className={drawer ? "search-types-modal animating" : "search-types-modal"}
                contentLabel="Choose meal type options"
                style={modalStyles.minimal}
                closeTimeoutMS={250}>
                <div className="search-types-modal-container" data-drawer={drawer}>
                    <button className="close-modal-btn" onClick={this.closeModal}>
                        <i className="icon-chevron-down" />
                    </button>
                    {searchTypes}
                </div>
            </Modal>
        );
    }

    onChangeCalendar = (date) => {
        const { onChange } = this.props;

        this.refs.popover && this.refs.popover.closeDropdown();

        onChange && onChange(moment(date));
    }

    onPrevDate = () => {
        let { date } = this.props;

        date = date || moment();

        this.onChangeCalendar(moment(date).subtract(1, 'day'));
    }

    onNextDate = () => {
        let { date } = this.props;

        date = date || moment();

        this.onChangeCalendar(moment(date).add(1, 'day'));
    }

    render() {
        const { date, onChange, className, placeholder, min, max, showAbove } = this.props;

        let dateFormatted = placeholder;

        if (date) {
            if (moment().subtract(1, 'day').isSame(date, 'day')) {
                dateFormatted = 'Yesterday, ' + date.format('MMM Do');
            } else if (moment().isSame(date, 'day')) {
                dateFormatted = 'Today, ' + date.format('MMM Do');
            } else if (moment().add(1, 'day').isSame(date, 'day')) {
                dateFormatted = 'Tomorrow, ' + date.format('MMM Do');
            } else {
                dateFormatted = date.format('dddd, MMM Do');
            }
        }

        const button = (<span>{dateFormatted}<i className="icon-chevron-down" /></span>);

        const calProps = {};

        if (min) {
            calProps.minDate = min.toDate();
        }

        if (max) {
            calProps.maxDate = max.toDate();
        }

        const calendar = (
            <div className="calendar">
                <Calendar value={date ? date.toDate() : null}
                    onChange={this.onChangeCalendar}
                    formatShortWeekday
                    {...calProps} />
            </div>
        );

        return (
            <div className={className}>
                <div className="date-select-container">
                    <button className="prev-date-btn-wrapper" disabled={date && min && date.isSame(min, 'day')} onClick={this.onPrevDate}>
                    <span className="prev-date-btn" ><i className="icon-chevron-left" /></span>
                    </button>
                    <Popup button={button} positionClassName={showAbove ? 'el-popup-bottom-center' : 'el-popup-top-center'} ref="popover">
                        {calendar}
                    </Popup>
                    <button className="next-date-btn-wrapper" disabled={date && max && date.isSame(max, 'day')} onClick={this.onNextDate}>
                    <span className="next-date-btn" ><i className="icon-chevron-right" /></span>
                    </button>
                </div>
            </div>
        );
    }
}
