'use strict';

import { Component } from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import UserStore from '../../stores/UserStore';
import { getQueryFromParams } from '../../utils/Search';
import { getConfigurationIssues, generateProfileKey, profileKeyComparator, getMealSearchParamsForProfile } from '../../pro/utils/Patients';
import './ConfigWarning.scss';
import allMainDishes from '../../tables/meal-types';
import AuthStore from '../../stores/AuthStore';
import { getConfig } from '../../utils/Env';

export default class ConfigWarning extends Component {
    static propTypes = {
        // Technically, a patient object will work too.
        profile: PropTypes.object,
        className: PropTypes.string,
    };

    static defaultProps = {
        profile: UserStore.getUser(),
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: true
        };

        this.debounceSyncAssets = debounce(this.syncAssets, 750);

        // Don't re-run analysis for three seconds after inhibited
        this.debounceResetInhibitAnalysis = debounce(this.resetInhibitAnalysis, 3000);
    }

    inhibitAnalysis = false

    resetInhibitAnalysis = () => {
        this.inhibitAnalysis = false;
    }

    componentDidMount = () => {
        this.syncAssets();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        this.debounceSyncAssets();
    }

    syncAssets = async () => {
        const { profile } = this.props;

        if (this.inhibitAnalysis) {
            this.debounceSyncAssets();
            return;
        }

        this.inhibitAnalysis = true;

        if (!profile) {
            return this.debounceResetInhibitAnalysis();
        }

        // Has the profile changed since the last time we analyzed it? If not, then we don't need to do anything.
        const { profile_key } = this.state;
        const { isParticipantsChanged = true, isProfileChanged = true } = profile_key ? profileKeyComparator(profile, profile_key) : {};

        if (!(isProfileChanged || isParticipantsChanged)) {
            return this.debounceResetInhibitAnalysis();
        }

        this.setState({loading: true});

        const newProfileKey = generateProfileKey(profile)

        const { warnings, errors } = getConfigurationIssues(profile);

        // If there are errors, set them immediately and no need to continue.
        if (errors.length) {
            this.setState({warnings: [], errors, profile_key: newProfileKey, loading: false}, () => this.debounceResetInhibitAnalysis());
            return;
        }


        const qualifying = {};

        for (const mealType of profile.preferences.meal_types) {
            if (mealType.main_dish === 'Snack') {
                continue;
            }

            const mainDish = allMainDishes.find((obj) => obj.mainDish === mealType.main_dish);

            const params = getMealSearchParamsForProfile(mealType, profile);

            params.size = 0; // we don't need results, we just want to know the total

            const response = await AuthStore.fetch(getConfig('recipe_api') + '/search', {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
                body: JSON.stringify(params),
            }, true);

            qualifying[mealType.name] = {
                total: response.total,
                params,
            };

            if (response.total && response.total < mainDish.threshold) {
                warnings.push(`Please note, the selected nutrition prescription combined with food avoidances may result in a reduced variety of ${mealType.name.toLowerCase()} options.`);
            } else if (!response.total) {
                errors.push(`No ${mealType.name.toLowerCase()} could be found that match your profile, we will not be able to build a meal plan matching your profile.`);
            }
        }

        this.setState({qualifying, warnings, errors, profile_key: newProfileKey, loading: false});


        this.inhibitAnalysis = false;
    }

    render = () => {
        const { className, profile } = this.props;
        const { warnings, errors, qualifying, loading } = this.state;

        const rendering = [];

        if (errors?.length) {
            rendering.push(
                <ul className={classNames('config-error', className)} key="errors">
                    <li>{errors[0]}</li>
                </ul>
            );
        }

        if (warnings?.length) {
            rendering.push(
                <ul className={classNames('config-warning', className)} key="warnings">
                    <li>{warnings[0]}</li>
                </ul>
            );
        }

        if (qualifying && profile && profile.role === 'admin') {
            rendering.push(
                <ul className="matching-dishes" key="matching">
                    {Object.keys(qualifying).map((mealType, i) => {
                        return (
                            <li key={i}>
                                <Link to={{pathname: '/search', query: getQueryFromParams(qualifying[mealType].params)}}>
                                    <em>{qualifying[mealType].total}</em> {mealType}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            );
        }

        return (
            <div className="profile-config-warning">
                {loading ? <i className='icon-spinner2'/> : rendering}
            </div>
        );
    }
}
