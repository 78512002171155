'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import AvoidanceSelect from '../../Preferences/AvoidanceSelect.react';
import allDiets from '../../../tables/diets';

import './Avoidances.scss';

export default class Avoidances extends Component {
    onChangeProfile = (profile) => {
        const { params, onChangeParams } = this.props;

        params.filters['!ingredient_tags'] = profile.preferences.avoidances;
        params.filters['!foods'] = profile.preferences.exclude_foods;

        onChangeParams(params, false);
    }

    render() {
        const { params } = this.props;
        const filterTags = params.filters['!ingredient_tags'] || [];

        const profile = {
            conditions: [],
            preferences: {
                diets: params.filters.tags || [],
                avoidances: params.filters['!ingredient_tags'] || [],
                exclude_foods: params.filters['!foods'] || [],
            },
        };

        return (
            <div className="global-search-avoidances">
                <AvoidanceSelect profile={profile} onChangeProfile={this.onChangeProfile} />
            </div>
        );
    }
}
