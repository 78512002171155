'use strict';

export default [
    {
        prop: 'mifflin',
        label: 'Mifflin',
        activity_levels: [
            {label: 'Sedentary',        value: 1.2, sub_label: ' little or no exercise and desk job'},
            {label: 'Low active',       value: 1.3, sub_label: ' light exercise 1-3 days per week'},
            {label: 'Active',           value: 1.5, sub_label: ' moderate exercise 3-5 days per week'},
            {label: 'Very active',      value: 1.7, sub_label: ' hard exercise 6-7 days per week'},
            {label: 'Extremely active', value: 1.9, sub_label: ' hard daily exercise and physical job'},
        ],
        compute: ({gender, weightKg, heightCm, age, activity = 1}) => {
            activity = parseFloat(activity);

            if (!weightKg || !heightCm || !age || !activity) {
                return false;
            }

            if (gender === 'male') {
                return (10 * weightKg + 6.25 * heightCm - 5 * age + 5) * activity;
            }

            if (gender === 'female') {
                return (10 * weightKg + 6.25 * heightCm - 5 * age - 161) * activity;
            }

            return false;
        },
    },
    {
        prop: 'kcal_kg',
        label: 'kcal/kg',
        showMinMax: true,
        compute: ({weightKg, kcalPerKg}) => {
            if (!weightKg || !kcalPerKg) {
                return false;
            }

            return weightKg * kcalPerKg;
        }
    },
    {
        prop: 'schofield',
        label: 'Schofield (Pedi)',
        maxAge: 30,
        compute: ({gender, weightKg, heightCm, age}) => {
            if (!gender || !weightKg || !heightCm || !age) {
                return false;
            }

            if (gender === 'male') {
                if (age >= 0 && age < 3) {
                    return 0.167 * weightKg + 15.174 * heightCm - 617.6;
                }

                if (age >= 3 && age < 10) {
                    return 19.59 * weightKg + 1.303 * heightCm + 414.9;
                }

                if (age >= 10 && age < 18) {
                    return 16.25 * weightKg + 1.372 * heightCm + 515.5;
                }

                if (age >= 18 && age <= 30) {
                    return 15.057 * weightKg - 0.1 * heightCm + 705.8;
                }

                return false;
            }

            if (gender === 'female') {
                if (age >= 0 && age < 3) {
                    return 16.252 * weightKg + 10.232 * heightCm - 413.5;
                }

                if (age >= 3 && age < 10) {
                    return 16.969 * weightKg + 1.618 * heightCm + 371.2;
                }

                if (age >= 10 && age < 18) {
                    return 8.365 * weightKg + 4.65 * heightCm + 200.0;
                }

                if (age >= 18 && age <= 30) {
                    return 13.623 * weightKg + 2.83 * heightCm + 98.2;
                }

                return false;
            }

            return false;
        }
    },
    {
        prop: 'dri_eer',
        label: 'DRI EER (Pedi)',
        maxAge: 18,
        activity_levels: [
            {label: 'Sedentary',        value: 'sedentary'},
            {label: 'Low active',       value: 'low-active'},
            {label: 'Active',           value: 'active'},
            {label: 'Very active',      value: 'very-active'},
        ],
        compute: ({gender = 'male', weightKg, heightCm, ageDays, age, activity = 1}) => {
            const activityTable = {
                'male':   {'sedentary': 1, 'low-active': 1.13, 'active': 1.26, 'very-active': 1.42},
                'female': {'sedentary': 1, 'low-active': 1.16, 'active': 1.31, 'very-active': 1.56},
            };

            // Physical activity multiplier
            const paMult = (activityTable[gender] && activityTable[gender][activity]) || 1;

            if (!gender || !weightKg || !heightCm || !ageDays) {
                return false;
            }

            const heightM = heightCm / 100;

            if (gender ==='male') {
                if (ageDays >= 0 && ageDays < 3 * 31) { // 0-3 months
                    return (89 * weightKg - 100) + 175;
                }

                if (ageDays >= 3 * 31 && ageDays < 365 / 2) { // 4-6 months
                    return (89 * weightKg - 100) + 56;
                }

                if (ageDays >= 365 / 2 && ageDays < 365) {
                    return (89 * weightKg - 100) + 22;
                }

                if (ageDays >= 365 && ageDays < 365 * 3) {
                    return (89 * weightKg - 100) + 20;
                }

                if (ageDays >= 365 * 3 && ageDays < 9 * 365) {
                    return 88.5 - (61.9 * age) + paMult * (26.7 * weightKg + 903 * heightM) + 20;
                }

                if (ageDays >= 9 * 365 && age <= 18) {
                    return 88.5 - (61.9 * age) + paMult * (26.7 * weightKg + 903 * heightM) + 25;
                }

                return false;
            }

            if (gender === 'female') {
                if (ageDays >= 0 && ageDays < 3 * 31) { // 0-3 months
                    return (89 * weightKg - 100) + 175;
                }

                if (ageDays >= 3 * 31 && ageDays < 365 / 2) { // 4-6 months
                    return (89 * weightKg - 100) + 56;
                }

                if (ageDays >= 365 / 2 && ageDays < 365) {
                    return (89 * weightKg - 100) + 22;
                }

                if (ageDays >= 365 && ageDays < 365 * 3) {
                    return (89 * weightKg - 100) + 20;
                }

                if (ageDays >= 365 * 3 && ageDays < 8 * 365) {
                    return 135.3 - (30.8 * age) + paMult * (10 * weightKg + 934 * heightM) + 20;
                }

                if (ageDays >= 8 * 365 && age < 18 * 365) {
                    return 135.3 - (30.8 * age) + paMult * (10 * weightKg + 934 * heightM) + 25;
                }

                return false;
            }

            return false;
        }
    },
    {
        prop: 'who',
        label: 'WHO Equations (Pedi)',
        maxAge: 18,
        compute: ({gender, weightKg, age}) => {

            if (!gender || !weightKg) {
                return false;
            }

            if (gender === 'male') {
                if (age >= 0 && age < 3) {
                    return 60.9 * weightKg - 54;
                }

                if (age >= 3 && age < 10) {
                    return 22.7 * weightKg + 495;
                }

                if (age >= 10 && age <= 18) {
                    return 17.5 * weightKg + 651;
                }
            }

            if (gender === 'female') {
                if (age >= 0 && age < 3) {
                    return 61.0 * weightKg - 51;
                }

                if (age >= 3 && age < 10) {
                    return 22.5 * weightKg + 499;
                }

                if (age >= 10 && age <= 18) {
                    return 12.2 * weightKg + 746;
                }
            }

            return false;
        }
    }
];
