'use strict';

const MAX_PORTION_SIZE = 10;
const STATIC_PORTION_SIZE = 1;
const STATIC_PORTION_SIZE_UPPER_LIMIT_kCAL = 2400;
const STATIC_PORTION_SIZE_LOWER_LIMIT_kCAL = 1200;

import { isSingular } from '../utils/Math';

export default [
    {label: '½ Serving',           value: 0.5,  min:    0, max: 1199},
    {label: 'Single Serving (1)',  value: 1,    min: 1200, max: 2599},
    {label: 'Large Serving (1½)',  value: 1.5,  min: 2600, max: 3499},
    {label: 'Double Serving (2)',  value: 2,    min: 3500, max: 5199},
    {label: 'Triple Serving (3)',  value: 3,    min: 5200, max: 6500},
];

export const getPortionSizes = (portionResolution, recommendedPortion = null) => {
    let total = 0;
    const portionSizes = [];
    while(total < MAX_PORTION_SIZE ) {
        total+=portionResolution;
        portionSizes.push({label: `${total} ` + (isSingular(total) ? 'Serving' : 'Servings'), value: total});
    }

    return portionSizes.map(option => {
        if (!!recommendedPortion && option.value === recommendedPortion) {
            option.sub_label = 'Recommended';
        }
        return option;
    });
}

export const calculateNearestPortionSize = (kcal, portionResolution, standardKcal = 2000, inhibitDeadzone = false) => {
    if (inhibitDeadzone == false &&
        kcal >= STATIC_PORTION_SIZE_LOWER_LIMIT_kCAL &&
        kcal <= STATIC_PORTION_SIZE_UPPER_LIMIT_kCAL) {
        return STATIC_PORTION_SIZE;
    }

    const availablePortionSizes = getPortionSizes(portionResolution).map(data => data.value);
    const approxServingSize = kcal / standardKcal;
    let nearestPortionSize = availablePortionSizes[0];
    let diff = Math.abs(nearestPortionSize - approxServingSize);
    availablePortionSizes.forEach(portionSize => {
        if(Math.abs(approxServingSize-portionSize) < diff) {
            diff = Math.abs(approxServingSize-portionSize);
            nearestPortionSize = portionSize;
        }
    });
    return nearestPortionSize;
}
