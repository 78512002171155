'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import RecipeEditor from '../../../Recipes/Editor.react';
import SavedRecipes from '../../../Recipes/SavedRecipes.react';

import Analytics from '../../../../utils/Analytics';
import platform from 'platform';

import './UseOwnRecipe.scss';

export default class UseOwnRecipe extends Component {

    static propTypes = {
        profile: PropTypes.object,

        closeModal: PropTypes.func,
    };

    static contextTypes = {
        onSelectRecipe: PropTypes.func,
        isMobile: PropTypes.bool,
        addSwapContext: PropTypes.object,
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            mode: 'create',
        };
    }

    componentDidMount = () => {
        Analytics.startUseOwnRecipe({'Context': 'Add/Swap'});
    }

    onSaveRecipe = (recipe) => {
        const { onSelectRecipe, addSwapContext } = this.context;

        Analytics.savedOwnRecipe({
            'Context': 'Add/Swap',
            'Recipe UUID': recipe.uuid,
            'Recipe Name': recipe.title
        });

        onSelectRecipe(recipe, addSwapContext.participants);
    }

    setCreateMode = () => {
        this.setState({mode: 'create'}, this.resetSwitcherScroll);
    }

    setSavedMode = () => {
        this.setState({mode: 'saved'}, this.resetSwitcherScroll);
    }

    switcher = null

    resetSwitcherScroll = () => {
        if (!this.switcher) {
            return;
        }

        this.switcher.scrollLeft = 0
    }

    realizeModeSwitcher = (el) => {
        if (el) {
            el.scrollLeft = 0;
        }

        this.switcher = el;
    }

    render() {
        const { mode } = this.state;
        const { profile, closeModal } = this.props;
        const { isMobile } = this.context;

        return (
            <div className="add-swap-search-use-own-recipe">
                <header>
                    <div className="toggle-btn-group">
                        <button data-active={mode === 'create'} onClick={this.setCreateMode}>New Recipe</button>
                        <button data-active={mode === 'saved'} onClick={this.setSavedMode}>My Recipes</button>
                    </div>
                </header>

                <div className="mode-switcher" data-transition={!(platform.name === 'Safari' && !isMobile) ? 'true' : 'false'} data-position={mode === 'saved' ? 1 : 0} ref={this.realizeModeSwitcher}>
                    <div className="tile">
                        <RecipeEditor profile={profile}
                            closeModal={closeModal}
                            onSaveRecipe={this.onSaveRecipe} />
                    </div>

                    <div className="tile">
                        {mode === 'saved' ? <SavedRecipes /> : null}
                    </div>
                </div>
            </div>
        );
    }
}
