'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../Dropdown.react';
import Expander from '../Expander.react';
import GoodSourceFilter from '../Filters/GoodSource.react';
import FilterUnavailable from '../Filters/FilterUnavailable.react';

import goodSources from '../../../tables/good-sources';

export default class GoodSource extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;

        let badge = <em data-active={false} />;

        // Find our activated good sources list
        const selectedGoodSources = Object.keys(goodSources).filter(key => {
            return params.filters && typeof params.filters[key] === 'object';
        });

        if (selectedGoodSources.length > 0) {
            badge = <em data-active={true}>{selectedGoodSources.length}</em>
        }

        const button = (
            <span className="global-search-filter-btn">
                Good Source of {badge}
            </span>
        );

        // If we're not filtering for plans, then the plan size filter is not active
        const isFilterAvailable = params.types.includes('recipe');
        const Component = isExpander ? Expander : Dropdown;

        if (!isFilterAvailable) {
            return <span />
        }

        return (
            <span className="global-search-hideable-filter" data-active={isFilterAvailable}>
                <Component button={button}>
                    {isFilterAvailable ?
                        <GoodSourceFilter params={params} onChangeParams={onChangeParams} />
                    : <FilterUnavailable needDocType="recipe" params={params} onChangeParams={onChangeParams} />}
                </Component>
            </span>
        );
    }
}
