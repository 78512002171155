'use strict';

import keymirror from 'keymirror';

const BoardConstants = keymirror({
    BOARD_LOAD: null,
    BOARD_UPSERT: null,
    BOARD_DELETE: null,
    BOARD_ADD_ITEM: null,
    BOARD_REMOVE_ITEM: null,
    UPDATE_NOSYNC: null,
});

export default BoardConstants;
