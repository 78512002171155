'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getConfigurationIssues } from '../../../utils/Patients';

import AvoidanceSelect from '../../../../components/Preferences/AvoidanceSelect.react';
import TastePreferences from '../../../../components/Preferences/TastePreferences.react';
import CuisinePreferences from '../../../../pro/components/Patients/Editor/CuisinePreferences.react';

import './DietPreferences.scss';
import './PatientForm.scss';

export default class DietPreferences extends Component {
    static propTypes = {
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const { patient = {} } = props;

        this.state = {
            diets: (patient.preferences && patient.preferences.diets || []).slice(0),
            avoidances: (patient.preferences && patient.preferences.avoidances || []).slice(0),
            exclude_foods: (patient.preferences && patient.preferences.exclude_foods || []).slice(0),
            prefer_milks: (patient.preferences && patient.preferences.prefer_milks || []).slice(0),
            prefer_rices: (patient.preferences && patient.preferences.prefer_rices || []).slice(0),
            prefer_cuisines: (patient.preferences && patient.preferences.prefer_cuisines || []).slice(0),
            dirty: false,
        };
    }

    isDirty = () => {
        return this.state.dirty;
    }

    mutate = (patient) => {
        const { diets, avoidances, exclude_foods, prefer_milks, prefer_rices, prefer_cuisines } = this.state;

        patient.preferences = patient.preferences || {};

        patient.preferences.diets = diets;
        patient.preferences.avoidances = avoidances;
        patient.preferences.exclude_foods = exclude_foods;
        patient.preferences.prefer_milks = prefer_milks;
        patient.preferences.prefer_rices = prefer_rices;
        patient.preferences.prefer_cuisines = prefer_cuisines;

        if (!patient.completed.includes('taste')) {
            patient.completed.push('taste');
        }

        return patient;
    }

    onChangeProfile = (profile) => {
        this.setState({
            diets: profile.preferences.diets,
            avoidances: profile.preferences.avoidances,
            exclude_foods: profile.preferences.exclude_foods,
            dirty: true,
        });
    }

    onChangePreferences = (user = null, preferences = null) => {
        const { prefer_milks, prefer_rices, prefer_cuisines } = preferences;

        this.setState({prefer_milks, prefer_rices, prefer_cuisines, dirty: true});
    }

    render() {
        const { patient } = this.props;
        const { diets, avoidances, exclude_foods, prefer_milks, prefer_rices, prefer_cuisines } = this.state;

        const profile = {
            ...patient,
            preferences: {
                ...patient.preferences,
                diets,
                avoidances,
                exclude_foods,
                prefer_milks,
                prefer_rices,
                prefer_cuisines,
            }
        };

        // Get configuration issues for the current selection
        const { warnings } = getConfigurationIssues(profile);

        return (
            <div className="diet-preferences patient-form">
                <AvoidanceSelect profile={profile} onChangeProfile={this.onChangeProfile} rd_override />
                <TastePreferences user={profile} preferences={profile.preferences}
                    onChangePreferences={this.onChangePreferences} hideHeaderText={true} showCuisine={true} />
                {warnings.length > 0 ?
                    <p data-active={true} className="warning-msg">{warnings[0]}</p>
                : null}
            </div>
        );
    }
}
