'use strict'

// This file was generated by chewba/bin/import-dris.php on 
// See https://docs.google.com/spreadsheets/d/13fsnAQpVmUgM7ve5Vlo0NGci8XHzmzAREXiGLB-bBDY/edit#gid=614672165 to make changes
// bin/import-dris.php -s '13fsnAQpVmUgM7ve5Vlo0NGci8XHzmzAREXiGLB-bBDY' -t 'DRIs'  | tee docs/js/tables/recommendations.js

module.exports = {
    'all-ages': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 50,
        '204': 78,
        '205': 275,
        '208': 2000,
        '269': 50,
        '291': 28,
        '301': 1300,
        '303': 18,
        '304': 420,
        '305': 1250,
        '306': 2600,
        '307': 2300,
        '309': 11,
        '312': 0.9,
        '313': 4,
        '315': 2.3,
        '317': 55,
        '318': 3000,
        '320': 900,
        '323': 15,
        '324': 800,
        '401': 90,
        '404': 1.2,
        '405': 1.3,
        '406': 16,
        '410': 5,
        '415': 1.7,
        '435': 400,
        '418': 2.4,
        '421': 550,
        '430': 120,
        '601': 300,
        '606': 20,
        'ASG': 50,
        'OLF': 2,
        'OLG': 2,
        'FRC': 2,
        'LAC': 2,
        'SOR': 2,
        'MAN': 2,
        '645': 14,
        '646': 14,
        'BIO': 30,
        'CHL': 2300,
        'CHR': 35,
        'MBD': 45
    },
    'male-0-6-mo': {
        'FRU': 1,
        'VEG': 1,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 9.1,
        '205': 60,
        '291': 0,
        '301': 200,
        '303': 0.27,
        '304': 30,
        '305': 100,
        '306': 400,
        '307': 120,
        '309': 2,
        '312': 0.2,
        '313': 0.01,
        '315': 0.003,
        '317': 15,
        '318': 1333.333333,
        '320': 400,
        '323': 4,
        '324': 400,
        '401': 40,
        '404': 0.2,
        '405': 0.3,
        '406': 2,
        '410': 1.7,
        '415': 0.1,
        '435': 65,
        '418': 0.4,
        '421': 125,
        '430': 2,
        'ASG': 0,
        '646': 4.4
    },
    'female-0-6-mo': {
        'FRU': 1,
        'VEG': 1,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 9.1,
        '205': 60,
        '291': 0,
        '301': 200,
        '303': 0.27,
        '304': 30,
        '305': 100,
        '306': 400,
        '307': 120,
        '309': 2,
        '312': 0.2,
        '313': 0.01,
        '315': 0.003,
        '317': 15,
        '318': 1333.333333,
        '320': 400,
        '323': 4,
        '324': 400,
        '401': 40,
        '404': 0.2,
        '405': 0.3,
        '406': 2,
        '410': 1.7,
        '415': 0.1,
        '435': 65,
        '418': 0.4,
        '421': 125,
        '430': 2,
        'ASG': 0,
        '646': 4.4
    },
    'male-6-12-mo': {
        'FRU': 1,
        'VEG': 1,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 11,
        '205': 95,
        '291': 0,
        '301': 260,
        '303': 11,
        '304': 75,
        '305': 275,
        '306': 700,
        '307': 370,
        '309': 3,
        '312': 0.22,
        '313': 0.5,
        '315': 0.6,
        '317': 20,
        '318': 1666.666667,
        '320': 500,
        '323': 5,
        '324': 400,
        '401': 50,
        '404': 0.3,
        '405': 0.4,
        '406': 4,
        '410': 1.8,
        '415': 0.3,
        '435': 80,
        '418': 0.5,
        '421': 150,
        '430': 2.5,
        'ASG': 0,
        '646': 4.6
    },
    'female-6-12-mo': {
        'FRU': 1,
        'VEG': 1,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 11,
        '205': 95,
        '291': 0,
        '301': 260,
        '303': 11,
        '304': 75,
        '305': 275,
        '306': 700,
        '307': 370,
        '309': 3,
        '312': 0.22,
        '313': 0.5,
        '315': 0.6,
        '317': 20,
        '318': 1666.666667,
        '320': 500,
        '323': 5,
        '324': 400,
        '401': 50,
        '404': 0.3,
        '405': 0.4,
        '406': 4,
        '410': 1.8,
        '415': 0.3,
        '435': 80,
        '418': 0.5,
        '421': 150,
        '430': 2.5,
        'ASG': 0,
        '646': 4.6
    },
    'male-1-3-yr': {
        'FRU': 1,
        'VEG': 1,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 13,
        '205': 130,
        '291': 19,
        '301': 700,
        '303': 7,
        '304': 80,
        '305': 460,
        '306': 2000,
        '307': 1000,
        '309': 3,
        '312': 0.34,
        '313': 0.7,
        '315': 1.2,
        '317': 20,
        '318': 1000,
        '320': 300,
        '323': 6,
        '324': 600,
        '401': 15,
        '404': 0.5,
        '405': 0.5,
        '406': 6,
        '410': 2,
        '415': 0.5,
        '435': 150,
        '418': 0.9,
        '421': 200,
        '430': 30,
        'ASG': 25.2,
        '646': 7
    },
    'female-1-3-yr': {
        'FRU': 1,
        'VEG': 1,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 13,
        '205': 130,
        '291': 19,
        '301': 700,
        '303': 7,
        '304': 80,
        '305': 460,
        '306': 2000,
        '307': 1000,
        '309': 3,
        '312': 0.34,
        '313': 0.7,
        '315': 1.2,
        '317': 20,
        '318': 1000,
        '320': 300,
        '323': 6,
        '324': 600,
        '401': 15,
        '404': 0.5,
        '405': 0.5,
        '406': 6,
        '410': 2,
        '415': 0.5,
        '435': 150,
        '418': 0.9,
        '421': 200,
        '430': 30,
        'ASG': 25.2,
        '646': 7
    },
    'male-4-8-yr': {
        'FRU': 1.5,
        'VEG': 1.5,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 19,
        '205': 130,
        '291': 25,
        '301': 1000,
        '303': 10,
        '304': 130,
        '305': 500,
        '306': 2300,
        '307': 1200,
        '309': 5,
        '312': 0.44,
        '313': 1,
        '315': 1.5,
        '317': 30,
        '318': 1333.333333,
        '320': 400,
        '323': 7,
        '324': 600,
        '401': 25,
        '404': 0.6,
        '405': 0.6,
        '406': 8,
        '410': 3,
        '415': 0.6,
        '435': 200,
        '418': 1.2,
        '421': 250,
        '430': 55,
        'ASG': 25.2,
        '646': 10
    },
    'female-4-8-yr': {
        'FRU': 1.5,
        'VEG': 1.5,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 19,
        '205': 130,
        '291': 25,
        '301': 1000,
        '303': 10,
        '304': 130,
        '305': 500,
        '306': 2300,
        '307': 1200,
        '309': 5,
        '312': 0.44,
        '313': 1,
        '315': 1.5,
        '317': 30,
        '318': 1333.333333,
        '320': 400,
        '323': 7,
        '324': 600,
        '401': 25,
        '404': 0.6,
        '405': 0.6,
        '406': 8,
        '410': 3,
        '415': 0.6,
        '435': 200,
        '418': 1.2,
        '421': 250,
        '430': 55,
        'ASG': 25.2,
        '646': 10
    },
    'male-9-13-yr': {
        'FRU': 1.5,
        'VEG': 2.5,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 34,
        '205': 130,
        '291': 31,
        '301': 1300,
        '303': 8,
        '304': 240,
        '305': 1250,
        '306': 2500,
        '307': 2400,
        '309': 8,
        '312': 0.7,
        '313': 2,
        '315': 1.9,
        '317': 40,
        '318': 2000,
        '320': 600,
        '323': 11,
        '324': 600,
        '401': 45,
        '404': 0.9,
        '405': 0.9,
        '406': 12,
        '410': 4,
        '415': 1,
        '435': 300,
        '418': 1.8,
        '421': 375,
        '430': 60,
        'ASG': 25.2,
        '646': 12
    },
    'male-14-18-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 52,
        '205': 130,
        '291': 38,
        '301': 1300,
        '303': 11,
        '304': 410,
        '305': 1250,
        '306': 3000,
        '307': 2400,
        '309': 11,
        '312': 0.89,
        '313': 3,
        '315': 2.2,
        '317': 55,
        '318': 3000,
        '320': 900,
        '323': 15,
        '324': 600,
        '401': 75,
        '404': 1.2,
        '405': 1.3,
        '406': 16,
        '410': 5,
        '415': 1.3,
        '435': 400,
        '418': 2.4,
        '421': 550,
        '430': 75,
        'ASG': 25.2,
        '646': 16
    },
    'male-19-30-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 56,
        '205': 130,
        '291': 38,
        '301': 1000,
        '303': 8,
        '304': 400,
        '305': 700,
        '306': 3400,
        '307': 2400,
        '309': 11,
        '312': 0.9,
        '313': 4,
        '315': 2.3,
        '317': 55,
        '318': 3000,
        '320': 900,
        '323': 15,
        '324': 600,
        '401': 90,
        '404': 1.2,
        '405': 1.3,
        '406': 16,
        '410': 5,
        '415': 1.3,
        '435': 400,
        '418': 2.4,
        '421': 550,
        '430': 120,
        '601': 300,
        'ASG': 37.8,
        '646': 17
    },
    'male-31-50-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 56,
        '205': 130,
        '291': 38,
        '301': 1000,
        '303': 8,
        '304': 420,
        '305': 700,
        '306': 3400,
        '307': 2400,
        '309': 11,
        '312': 0.9,
        '313': 4,
        '315': 2.3,
        '317': 55,
        '318': 3000,
        '320': 900,
        '323': 15,
        '324': 600,
        '401': 90,
        '404': 1.2,
        '405': 1.3,
        '406': 16,
        '410': 5,
        '415': 1.3,
        '435': 400,
        '418': 2.4,
        '421': 550,
        '430': 120,
        '601': 300,
        'ASG': 37.8,
        '646': 17
    },
    'male-51-70-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 56,
        '205': 130,
        '291': 30,
        '301': 1000,
        '303': 8,
        '304': 420,
        '305': 700,
        '306': 3400,
        '307': 2400,
        '309': 11,
        '312': 0.9,
        '313': 4,
        '315': 2.3,
        '317': 55,
        '318': 3000,
        '320': 900,
        '323': 15,
        '324': 600,
        '401': 90,
        '404': 1.2,
        '405': 1.3,
        '406': 16,
        '410': 5,
        '415': 1.7,
        '435': 400,
        '418': 2.4,
        '421': 550,
        '430': 120,
        '601': 300,
        'ASG': 37.8,
        '646': 14
    },
    'male-70+yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 56,
        '205': 130,
        '291': 30,
        '301': 1200,
        '303': 8,
        '304': 420,
        '305': 700,
        '306': 3400,
        '307': 2400,
        '309': 11,
        '312': 0.9,
        '313': 4,
        '315': 2.3,
        '317': 55,
        '318': 3000,
        '320': 900,
        '323': 15,
        '324': 800,
        '401': 90,
        '404': 1.2,
        '405': 1.3,
        '406': 16,
        '410': 5,
        '415': 1.7,
        '435': 400,
        '418': 2.4,
        '421': 550,
        '430': 120,
        '601': 300,
        'ASG': 37.8,
        '646': 14
    },
    'female-9-13-yr': {
        'FRU': 1.5,
        'VEG': 2,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 34,
        '205': 130,
        '291': 26,
        '301': 1300,
        '303': 8,
        '304': 240,
        '305': 1250,
        '306': 2300,
        '307': 2400,
        '309': 8,
        '312': 0.7,
        '313': 2,
        '315': 1.6,
        '317': 40,
        '318': 2000,
        '320': 600,
        '323': 11,
        '324': 600,
        '401': 45,
        '404': 0.9,
        '405': 0.9,
        '406': 12,
        '410': 4,
        '415': 1,
        '435': 300,
        '418': 1.8,
        '421': 375,
        '430': 60,
        'ASG': 25.2,
        '646': 10
    },
    'female-14-18-yr': {
        'FRU': 1.5,
        'VEG': 2.5,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 46,
        '205': 130,
        '291': 26,
        '301': 1300,
        '303': 15,
        '304': 360,
        '305': 1250,
        '306': 2300,
        '307': 2400,
        '309': 9,
        '312': 0.89,
        '313': 3,
        '315': 1.6,
        '317': 55,
        '318': 2333.333333,
        '320': 700,
        '323': 15,
        '324': 600,
        '401': 65,
        '404': 1,
        '405': 1,
        '406': 14,
        '410': 5,
        '415': 1.2,
        '435': 400,
        '418': 2.4,
        '421': 400,
        '430': 75,
        'ASG': 25.2,
        '646': 11
    },
    'female-19-30-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 46,
        '205': 130,
        '291': 25,
        '301': 1000,
        '303': 18,
        '304': 310,
        '305': 700,
        '306': 2600,
        '307': 2400,
        '309': 8,
        '312': 0.9,
        '313': 3,
        '315': 1.8,
        '317': 55,
        '318': 2333.333333,
        '320': 700,
        '323': 15,
        '324': 600,
        '401': 75,
        '404': 1.1,
        '405': 1.1,
        '406': 14,
        '410': 5,
        '415': 1.3,
        '435': 400,
        '418': 2.4,
        '421': 425,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 12
    },
    'female-31-50-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 46,
        '205': 130,
        '291': 25,
        '301': 1000,
        '303': 18,
        '304': 320,
        '305': 700,
        '306': 2600,
        '307': 2400,
        '309': 8,
        '312': 0.9,
        '313': 3,
        '315': 1.8,
        '317': 55,
        '318': 2333.333333,
        '320': 700,
        '323': 15,
        '324': 600,
        '401': 75,
        '404': 1.1,
        '405': 1.1,
        '406': 14,
        '410': 5,
        '415': 1.3,
        '435': 400,
        '418': 2.4,
        '421': 425,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 12
    },
    'female-51-70-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 46,
        '205': 130,
        '291': 21,
        '301': 1200,
        '303': 8,
        '304': 320,
        '305': 700,
        '306': 2600,
        '307': 2400,
        '309': 8,
        '312': 0.9,
        '313': 3,
        '315': 1.8,
        '317': 55,
        '318': 2333.333333,
        '320': 700,
        '323': 15,
        '324': 600,
        '401': 75,
        '404': 1.1,
        '405': 1.1,
        '406': 14,
        '410': 5,
        '415': 1.5,
        '435': 400,
        '418': 2.4,
        '421': 425,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 11
    },
    'female-70+yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 46,
        '205': 130,
        '291': 21,
        '301': 1200,
        '303': 8,
        '304': 320,
        '305': 700,
        '306': 2600,
        '307': 2400,
        '309': 8,
        '312': 0.9,
        '313': 3,
        '315': 1.8,
        '317': 55,
        '318': 2333.333333,
        '320': 700,
        '323': 15,
        '324': 800,
        '401': 75,
        '404': 1.1,
        '405': 1.1,
        '406': 14,
        '410': 5,
        '415': 1.5,
        '435': 400,
        '418': 2.4,
        '421': 425,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 11
    },
    'female-pregnant-14-18-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 71,
        '205': 175,
        '291': 28,
        '301': 1300,
        '303': 27,
        '304': 400,
        '305': 1250,
        '306': 2600,
        '307': 2400,
        '309': 12,
        '312': 1,
        '313': 3,
        '315': 2,
        '317': 60,
        '318': 2500,
        '320': 750,
        '323': 15,
        '324': 600,
        '401': 80,
        '404': 1.4,
        '405': 1.4,
        '406': 18,
        '410': 6,
        '415': 1.9,
        '435': 600,
        '418': 2.6,
        '421': 450,
        '430': 75,
        'ASG': 25.2,
        '646': 13
    },
    'female-pregnant-19-30-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 71,
        '205': 175,
        '291': 28,
        '301': 1000,
        '303': 27,
        '304': 350,
        '305': 700,
        '306': 2900,
        '307': 2400,
        '309': 11,
        '312': 1,
        '313': 3,
        '315': 2,
        '317': 60,
        '318': 2566.666667,
        '320': 770,
        '323': 15,
        '324': 600,
        '401': 85,
        '404': 1.4,
        '405': 1.4,
        '406': 18,
        '410': 6,
        '415': 1.9,
        '435': 600,
        '418': 2.6,
        '421': 450,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 13
    },
    'female-pregnant-31-50-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 71,
        '205': 175,
        '291': 28,
        '301': 1000,
        '303': 27,
        '304': 360,
        '305': 700,
        '306': 2900,
        '307': 2400,
        '309': 11,
        '312': 1,
        '313': 3,
        '315': 2,
        '317': 60,
        '318': 2566.666667,
        '320': 770,
        '323': 15,
        '324': 600,
        '401': 85,
        '404': 1.4,
        '405': 1.4,
        '406': 18,
        '410': 6,
        '415': 1.9,
        '435': 600,
        '418': 2.6,
        '421': 450,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 13
    },
    'female-lactation-14-18-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 71,
        '205': 210,
        '291': 29,
        '301': 1300,
        '303': 10,
        '304': 360,
        '305': 1250,
        '306': 2500,
        '307': 2400,
        '309': 13,
        '312': 1.3,
        '313': 3,
        '315': 2.6,
        '317': 70,
        '318': 4000,
        '320': 1200,
        '323': 19,
        '324': 600,
        '401': 115,
        '404': 1.4,
        '405': 1.6,
        '406': 17,
        '410': 7,
        '415': 2,
        '435': 500,
        '418': 2.8,
        '421': 550,
        '430': 75,
        'ASG': 25.2,
        '646': 13
    },
    'female-lactation-19-30-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 71,
        '205': 210,
        '291': 29,
        '301': 1000,
        '303': 9,
        '304': 310,
        '305': 700,
        '306': 2800,
        '307': 2400,
        '309': 12,
        '312': 1.3,
        '313': 3,
        '315': 2.6,
        '317': 70,
        '318': 4333.333333,
        '320': 1300,
        '323': 19,
        '324': 600,
        '401': 120,
        '404': 1.4,
        '405': 1.6,
        '406': 17,
        '410': 7,
        '415': 2,
        '435': 500,
        '418': 2.8,
        '421': 550,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 13
    },
    'female-lactation-31-50-yr': {
        'FRU': 2,
        'VEG': 3,
        'WHG': 3,
        'LPR': 3,
        'HLF': 3,
        '203': 71,
        '205': 210,
        '291': 29,
        '301': 1000,
        '303': 9,
        '304': 320,
        '305': 700,
        '306': 2800,
        '307': 2400,
        '309': 12,
        '312': 1.3,
        '313': 3,
        '315': 2.6,
        '317': 70,
        '318': 4333.333333,
        '320': 1300,
        '323': 19,
        '324': 600,
        '401': 120,
        '404': 1.4,
        '405': 1.6,
        '406': 17,
        '410': 7,
        '415': 2,
        '435': 500,
        '418': 2.8,
        '421': 550,
        '430': 90,
        '601': 300,
        'ASG': 25.2,
        '646': 13
    }
};
