'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import classNames from 'classnames';

import './DrawerMenu.scss';

export default class DrawerMenu extends Component {
    static propTypes = {
        modalClassName: PropTypes.string,
        isOpen: PropTypes.bool,

        onOpenMenu: PropTypes.func,
        onCloseMenu: PropTypes.func,
        checkForOnlyOneSwapOption: PropTypes.func,
    };

    static defaultProps = {
        isOpen: true,
    };

    constructor(props) {

        super(props);

        this.state = {
            isModalOpen: false,
            drawer: false,
        };
    }

    closeModal = (immediate = false) => {
        return new Promise((accept, reject) => {
            if (immediate === true) {
                this.setState({isModalOpen: false, drawer: false}, accept);
                return;
            }

            this.setState({drawer: false}, () => {
                setTimeout(() => {
                    this.setState({isModalOpen: false}, accept);
                }, 250);
            });
        });
    }

    toggleDrawer = () => {
        if (this.state.isModalOpen) {
            return this.closeModal();
        }

        const { onOpenMenu, checkForOnlyOneSwapOption } = this.props;

        if (checkForOnlyOneSwapOption && checkForOnlyOneSwapOption()) {
            return;
        }

        onOpenMenu && onOpenMenu();

        // Always ensure that the drawer is opened AFTER the modal is realized
        this.setState({isModalOpen: true, drawer: false}, () => {
            setTimeout(() => this.setState({drawer: true}));
        });
    }

    renderModal = () => {
        const { drawer } = this.state;
        const { children, menuTitle, modalClassName } = this.props;

        return (
            <Modal isOpen={this.state.isModalOpen}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className={classNames("drawer-menu-modal", modalClassName, drawer ? 'drawer-modal-after-open' : null)}
                overlayClassName={classNames("drawer-menu-modal-overlay", drawer ? 'drawer-overlay-after-open' : null)}
                contentLabel="Drawer menu"
                closeTimeoutMS={500}>
                <div className="drawer-menu-modal-container" onClick={this.toggleDrawer}>
                    <header>
                        {menuTitle ? <h3>{menuTitle}</h3> : null}
                        <button className="menu-item cancel-btn">
                            Cancel <i className="icon-close-x" />
                        </button>
                    </header>
                    <div className="inner-container">
                        <div className="child-container">{children}</div>
                    </div>
                </div>
            </Modal>
        );
    }

    render() {
        const { drawer } = this.state;
        const { button, closeModal, className } = this.props;

        return (
            <>
                <button onClick={this.toggleDrawer} className={classNames("drawer-menu-btn", className)}>{button}</button>
                {this.renderModal()}
            </>
        );
    }
}
