'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import NutrientEntry from './NutrientEntry.react';
import PercentEntry from './PercentEntry.react';
import allNutrients from '../../../../../tables/nutrients';
import { roundForHumans } from '../../../../../utils/Math';
import allConditions from '../../../../../tables/conditions';

const macroNutrNos = ['203', '204', '205'];

import './NutrientEditor.scss';

export default class NutrientEditor extends Component {
    static propTypes = {
        nutrNo: PropTypes.string,
        isLocked: PropTypes.bool,
        showPercents: PropTypes.bool,
        alwaysEditable: PropTypes.bool,
    };

    static defaultProps = {
    };

    static contextTypes = {
        user: PropTypes.object,
        viewportWidth: PropTypes.number,
    };

    inhibitParentUpdate = false;

    constructor(props) {
        super(props);

        const { range } = props;

        let { mode, min, max, per_min, per_max, percentage, spread } = this.computePercentAndSpreadFromRange(range);

        this.state = {
            mode,
            min,
            max,
            per_min,
            per_max,
            percentage,
            effectivePercentage: percentage,
            spread,
            minRecent: false,
            maxRecent: false,
            pMinRecent: false,
            pMaxRecent: false,
        };

        this.resetParentInhibit = debounce(this.resetParentInhibit, 500);
        this.resetMinRecent = debounce(this.resetMinRecent, 2000);
        this.resetMaxRecent = debounce(this.resetMaxRecent, 2000);
        this.resetPminRecent = debounce(this.resetPminRecent, 2000);
        this.resetPmaxRecent = debounce(this.resetPminRecent, 2000);

        this.resetPercentRecent = debounce(this.resetPercentRecent, 2000);
        this.copyEffectivePercentage = debounce(this.copyEffectivePercentage, 2000);
    }

    copyEffectivePercentage = () => {
        this.setState({percentage: this.state.effectivePercentage});
    }

    resetMinRecent = () => {
        this.setState({minRecent: false});
    }

    resetMaxRecent = () => {
        this.setState({maxRecent: false});
    }

    resetPminRecent = () => {
        this.setState({pMinRecent: false});
    }

    resetPmaxRecent = () => {
        this.setState({pMaxRecent: false});
    }

    resetPercentRecent = () => {
        this.setState({perRecent: false});
    }

    computePercentAndSpreadFromRange = (range) => {
        const { nutrNo, kcal_min, kcal_max } = this.props;

        let mode = 'kcal';
        let percentage = '';

        let { g_per_kg_min = '', g_per_kg_max = '',
              per_min = '', per_max = '' } = range;

        let min = range?.min || range?.implicit?.min || '';
        let max = range?.max || range?.implicit?.max || '';

        let spread = 0.05;

        const has = this.hasMinMax(min, max);

        if (macroNutrNos.includes(nutrNo)) {
            per_min = allNutrients[nutrNo].calories_per_gram * min / kcal_min;
            per_max = allNutrients[nutrNo].calories_per_gram * max / kcal_max;
        }

        if (g_per_kg_min || g_per_kg_max) {
            mode = 'g/kg';
            per_min = g_per_kg_min;
            per_max = g_per_kg_max;

            if (g_per_kg_min && g_per_kg_max) {
                percentage = Math.round((g_per_kg_min + g_per_kg_max) / 2 * 10) / 10;
                spread = (g_per_kg_max - g_per_kg_min) / 2;
            } else if (g_per_kg_min && !g_per_kg_max) {
                percentage = Math.round(g_per_kg_min * 10) / 10;
            } else if (!g_per_kg_min && g_per_kg_max) {
                percentage = Math.round(g_per_kg_max * 10) / 10;
            }
        } else {
            if (has.min && has.max) {
                percentage = Math.round((per_min + per_max) / 2 * 100);
                spread = (per_max - per_min) / 2;
            } else if (has.min && !has.max) {
                percentage = Math.round(per_min * 100);
            } else if (!has.min && has.max) {
                percentage = Math.round(per_max * 100);
            }
        }

        min = String(min);
        max = String(max);

        return { mode, min, max, per_min, per_max, percentage, spread };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { nutrNo, range, kcal_min, kcal_max } = nextProps;

        let newState = {};
        let callback;

        let { mode, min, max, per_min, per_max, percentage, spread } = this.computePercentAndSpreadFromRange(range);

        // Do we have different values than our current state? Update.
        if (!this.inhibitParentUpdate &&
            (this.state.min != min ||
             this.state.max != max ||
             this.state.per_min != per_min ||
             this.state.per_max != per_max)) {
            newState = {...newState, mode, min, max, per_min, per_max, percentage, spread, effectivePercentage: percentage};
        }

        // Do we also have a per_min or per_max? Then we need to update our values, and our parent.
        if (macroNutrNos.includes(nutrNo) &&
            mode === 'kcal' &&
            (kcal_min != this.props.kcal_min || kcal_max !== this.props.kcal_max) &&
            (per_min || per_max)) {
            const cpg = allNutrients[nutrNo].calories_per_gram;

            if (per_min && kcal_min != this.props.kcal_min) {
                newState.min = roundForHumans(kcal_min * per_min / cpg);
            }

            if (per_max && kcal_max != this.props.kcal_max) {
                newState.max = roundForHumans(kcal_max * per_max / cpg);
            }

            callback = this.updateParent;
        }

        // We don't have to worry about recalculating when mode = g/kg because weight
        // will never change while this form is visible.

        if (Object.keys(newState).length > 0) {
            this.setState(newState, callback);
        }
    }

    resetParentInhibit = () => {
        this.inhibitParentUpdate = false;
    }

    updateParent = () => {
        const { min, max, mode, per_min, per_max } = this.state;
        const { nutrNo, onChangeNutrient } = this.props;

        const range = {};

        const has = this.hasMinMax(min, max);

        if (has.min) {
            range.min = parseFloat(min);
        }

        if (has.max){
            range.max = parseFloat(max);
        }

        if (macroNutrNos.includes(nutrNo)) {
            if (mode === 'kcal') {
                if (per_min) {
                    range.per_min = per_min;
                }

                if (per_max) {
                    range.per_max = per_max;
                }
            } else {
                if (per_min) {
                    range.g_per_kg_min = per_min;
                }

                if (per_max) {
                    range.g_per_kg_max = per_max;
                }
            }
        }

        this.inhibitParentUpdate = true;
        this.resetParentInhibit();
        onChangeNutrient(nutrNo, range);
    }

    hasMinMax = (min, max) => {
        const fMin = parseFloat(min),
              fMax = parseFloat(max);

        const sMin = (String(min) || '').trim(),
              sMax = (String(max) || '').trim();

        return {
            min: sMin.length > 0 && typeof fMin === 'number' && !isNaN(fMin),
            max: sMax.length > 0 && typeof fMin === 'number' && !isNaN(fMax),
        }
    }

    onChangePercentage = (value) => {
        const { mode, spread } = this.state;
        const { nutrNo, kcal_min, kcal_max, weight_kg, range, onChangeNutrient } = this.props;

        let { min, max } = this.state;

        let minRecent = false, maxRecent = false;

        let per_min, per_max;

        const percentage = parseFloat(value);
        let effectivePercentage = value;

        // Only update our values if there is a valid number in the percentage
        if (typeof percentage !== 'number' || isNaN(percentage)) {
            this.setState({percentage: value});
            return;
        }

        const has = this.hasMinMax(min, max);

        if (mode === 'kcal') {
            const cpg = allNutrients[nutrNo].calories_per_gram;

            if (has.min && has.max) {
                per_min = (percentage / 100) - spread;
                per_max = (percentage / 100) + spread;

                if (per_min < 0) {
                    per_min = 0;
                    per_max = spread * 2;
                }

                if (per_max > 1) {
                    per_max = 1;
                    per_min = 1 - (spread * 2);
                }

                effectivePercentage = Math.round((per_min + per_max) / 2 * 100);
                min = String(Math.round(kcal_min * per_min / cpg));
                max = String(Math.round(kcal_max * per_max / cpg));
                minRecent = true; maxRecent = true;
            } else if (has.min && !has.max) {
                per_min = percentage / 100;
                per_max = null;

                min = String(Math.round(kcal_min * per_min / cpg));
                minRecent = true; maxRecent = false;
            } else if (!has.min && has.max) {
                per_min = null;
                per_max = percentage / 100;

                max = String(Math.round(kcal_max * per_max / cpg));
                minRecent = false; maxRecent = true;
            }
        } else if (mode === 'g/kg') {

            if (has.min && has.max) {
                per_min = percentage - spread;
                per_max = percentage + spread;

                if (per_min < 0) {
                    per_min = 0;
                    per_max = spread * 2;
                }

                effectivePercentage = Math.round((per_min + per_max) / 2 * 100) / 100;
                min = String(Math.round(per_min * weight_kg));
                max = String(Math.round(per_max * weight_kg));
                minRecent = true; maxRecent = true;
            } else if (has.min && !has.max) {
                per_min = percentage;
                per_max = null;

                min = String(Math.round(per_min * weight_kg));
                minRecent = true; maxRecent = false;
            } else if (!has.min && has.max) {
                per_min = null;
                per_max = percentage;

                max = String(Math.round(per_max * weight_kg));
                minRecent = false; maxRecent = true;
            }
        }

        this.setState({effectivePercentage, percentage: value, per_min, per_max, min, max, minRecent, maxRecent}, this.updateParent);
        this.copyEffectivePercentage();
        this.resetMinRecent();
        this.resetMaxRecent();
    }

    calcPercents = (min, max, mode) => {
        const { nutrNo, weight_kg, kcal_min, kcal_max, showPercents } = this.props;
        const percents = {};

        // If we're not a macro, don't calculate them.
        if (!(showPercents && macroNutrNos.includes(nutrNo) && allNutrients[nutrNo])) {
            return {percentage: '', spread: null, per_min: null, per_max: null};
        }

        let per_min = null,
            per_max = null,
            percentage = '',
            spread = null;

        let cpg = allNutrients[nutrNo].calories_per_gram;
        const has = this.hasMinMax(min, max);

        if (mode === 'kcal') {
            if (has.min && has.max) {
                per_min = parseFloat(min) * cpg / kcal_min;
                per_max = parseFloat(max) * cpg / kcal_max;

                percentage = Math.round((per_min + per_max) / 2 * 100);
                spread = (per_max - per_min) / 2;
            } else if (has.min && !has.max) {
                per_min = parseFloat(min) * cpg / kcal_min;
                per_max = null;

                percentage = Math.round(per_min * 100);
                spread = null;
            } else if (!has.min && has.max) {
                per_min = null;
                per_max = parseFloat(max) * cpg / kcal_max;

                percentage = Math.round(per_max * 100);
                spread = null;
            }
        } else {
            if (has.min && has.max) {
                per_min = parseFloat(min) / weight_kg;
                per_max = parseFloat(max) / weight_kg;

                percentage = Math.round((per_min + per_max) / 2 * 10) / 10;
                spread = (per_max - per_min) / 2;
            } else if (has.min && !has.max) {
                per_min = parseFloat(min) / weight_kg;
                per_max = null;

                percentage = Math.round(per_min * 10) / 10;
                spread = null;
            } else if (!has.min && has.max) {
                per_min = null;
                per_max = parseFloat(max) / weight_kg;

                percentage = Math.round(per_max * 10) / 10;
                spread = null;
            }
        }

        return {
            percentage, spread, per_min, per_max
        };
    }

    onChangeMin = (min) => {
        const { max, mode } = this.state;
        const { nutrNo, range, onChangeNutrient } = this.props;

        const { percentage, spread, per_min, per_max } = this.calcPercents(min, max, mode);

        this.setState({min, per_min, per_max, percentage, spread, perRecent: true}, this.updateParent);
        this.resetPercentRecent();
    }

    onChangeMax = (max, resolve) => {
        const { min, mode } = this.state;
        const { nutrNo, range, onChangeNutrient } = this.props;

        const { percentage, spread, per_min, per_max } = this.calcPercents(min, max, mode);

        this.setState({max, per_min, per_max, percentage, spread, perRecent: true}, this.updateParent);
        this.resetPercentRecent();
    }

    modeToggle = () => {
        let { mode, min, max } = this.state;
        const { nutrNo, weight_kg, kcal_min, kcal_max } = this.props;

        if (!weight_kg) {
            return;
        }

        let percentage, spread, per_min, per_max;

        const has = this.hasMinMax(min, max);

        if (mode === 'kcal') {
            mode = 'g/kg';

            // Convert to g/kg
            if (has.min && has.max) {
                per_min = parseFloat(min) / weight_kg;
                per_max = parseFloat(max) / weight_kg;

                percentage = String(Math.round((per_min + per_max) / 2 * 10) / 10);
                spread = (per_max - per_min) / 2;
            } else if (has.min && !has.max) {
                per_min = parseFloat(min) / weight_kg;
                per_max = null

                percentage = String(Math.round(per_min * 10) / 10);
                spread = null;
            } else if (!has.min && has.max) {
                per_min = null;
                per_max = parseFloat(max) / weight_kg;

                percentage = String(Math.round(per_max * 10) / 10);
                spread = null;
            }

        } else {
            mode = 'kcal';
            const cpg = allNutrients[nutrNo].calories_per_gram;

            if (has.min && has.max) {

                per_min = parseFloat(min) * cpg / kcal_min;
                per_max = parseFloat(max) * cpg / kcal_max;

                percentage = String(Math.round((per_min + per_max) / 2 * 100));
                spread = (per_max - per_min) / 2;
            } else if (has.min && !has.max) {
                per_min = parseFloat(min) * cpg / kcal_min;
                per_max = null;

                percentage = String(Math.round(per_min * 100));
                spread = null;
            } else if (!has.min && has.max) {
                per_min = null;
                per_max = parseFloat(max) * cpg / kcal_max;

                percentage = String(Math.round(per_max * 100));
                spread = null;
            }
        }

        this.setState({mode, percentage, spread, per_min, per_max}, this.updateParent);
    }

    resolveConflictMin = (conflict, min) => {
        this.onChangeMin(min, conflict.condition);
    }

    resolveConflictMax = (conflict, max) => {
        this.onChangeMax(max, conflict.condition);
    }

    render() {
        const { patient, conflict, showPercents, nutrNo, onSetMin, onSetMax, isError, mealType, alwaysEditable } = this.props;
        const { mode, min, max, percentage, g_per_kg, per_min, per_max, g_per_kg_max, g_per_kg_min } = this.state;
        const { user, viewportWidth } = this.context;
        const { capabilities, practice_type } = user || {};
        const nutrient = allNutrients[nutrNo];

        if (!nutrient) {
            return <span />;
        }

        const fodmapMax = mealType === 'all-day' ? 2 : 0.5;

        let macrosDisabled = !alwaysEditable && !capabilities.edit_macros,
            microsDisabled = !alwaysEditable && !capabilities.edit_micros;

        if (practice_type === 'fitness') {
            const conditionNames = (patient.conditions || []).map(cd => cd.name);
            const isLifestyle = conditionNames.length > 0
                              ? allConditions.find(cd => cd.name === conditionNames[0]).lifestyle
                              : false;
        }

        if (viewportWidth < 600 && nutrNo == '208') {
            return (
                <div className="nutrient-editor nutrient-two-lines" key={nutrNo}>

                    <div className="col-2">
                        <label>{nutrient.NutrDesc}: {macrosDisabled ? <i className="icon-lock" /> : null}</label>

                        <NutrientEntry value={min} nutrient={nutrient}
                            label="min"
                            isError={isError}
                            className="minimum"
                            practice_type={practice_type}
                            disabled={macrosDisabled}
                            requiresUpgrade={macrosDisabled}
                            onChange={value => this.onChangeMin(value)} />
                    </div>

                    <div className="col-3">
                        <span className="separator">-</span>
                    </div>

                    <div className="col-4">
                        <NutrientEntry value={max} nutrient={nutrient}
                            label="max"
                            isError={isError}
                            className="maximum"
                            practice_type={practice_type}
                            disabled={macrosDisabled}
                            requiresUpgrade={macrosDisabled}
                            onChange={value => this.onChangeMax(value)} />
                    </div>

                    <div className="col-5">
                        <span className="unit"/>
                    </div>
                </div>
            );
        }


        // Calories has a special format
        if (nutrNo == '208') {
            return (
                <div className="nutrient-editor calories">

                <div className="col-1">
                    <label>{nutrient.NutrDesc}: {macrosDisabled ? <i className="icon-lock" /> : null}</label>
                </div>

                <div className="col-2">

                    <NutrientEntry value={min} nutrient={nutrient}
                        label="min"
                        isError={isError}
                        className="minimum"
                        practice_type={practice_type}
                        disabled={macrosDisabled}
                        requiresUpgrade={macrosDisabled}
                        onChange={value => this.onChangeMin(value)} />
                </div>

                <div className="col-3">

                    <span className="separator">-</span>

                </div>

                <div className="col-4">

                    <NutrientEntry value={max} nutrient={nutrient}
                        label="max"
                        isError={isError}
                        className="maximum"
                        practice_type={practice_type}
                        disabled={macrosDisabled}
                        requiresUpgrade={macrosDisabled}
                        onChange={value => this.onChangeMax(value)} />

                </div>

                <div className="col-5">
                    <span className="unit"/>
                </div>

                </div>
            );
        }

        const fodmapNutrNos = ['OLF', 'OLG', 'FRC', 'LAC', 'PLY', 'MAN', 'SOR'];
        if (fodmapNutrNos.includes(nutrNo)) {
            let mode = 'reintroduce';

            if (typeof max === 'string' && max.length === 0) {
                mode = 'reintroduce';
            } else if (max <= fodmapMax) {
                mode = 'reduce';
            }

            return (
                <div className="nutrient-editor fodmap">
                    <label>{nutrient.NutrDesc}:</label>

                    <div className="fodmap-mode" data-mode={mode} data-disabled={microsDisabled}>
                        <button className="reduce-btn" onClick={microsDisabled ? null : () => this.onChangeMax(fodmapMax)}>reduce</button>
                        <button className="reintroduce-btn" onClick={microsDisabled ? null : () => this.onChangeMax('')}>reintroduce</button>
                    </div>
                </div>
            );
        }

        // Protein, Carbs, and Fat have a special entry as well.
        if (showPercents && (nutrNo == '203' || nutrNo == '204' || nutrNo == '205')) {
            const { percents, modes, perRecent, pMinRecent, pMaxRecent, minRecent, maxRecent } = this.state;

            return (
                <div className="nutrient-editor macro-nutrient">

                    <div className="col-1">
                        <label>{nutrient.NutrDesc}: {macrosDisabled ? <i className="icon-lock" /> : null}</label>
                    </div>


                    <div className="col-2">


                        <PercentEntry value={percentage}
                            onChange={value => this.onChangePercentage(value)}
                            className={perRecent ? "percentage updated" : "percentage"}
                            mode={mode}
                            isError={isError}
                            practice_type={practice_type}
                            disabled={macrosDisabled}
                            requiresUpgrade={macrosDisabled}
                            modeToggle={this.modeToggle} />

                    </div>

                    <div className="col-3">

                        <span className="separator">=</span>

                    </div>

                    <div className="col-4">

                        <NutrientEntry value={min} nutrient={nutrient}
                            label="min" conflict={conflict}
                            isError={isError}
                            className={minRecent ? "minimum2 updated" : "minimum2"}
                            resolveConflict={this.resolveConflictMin}
                            practice_type={practice_type}
                            disabled={macrosDisabled}
                            requiresUpgrade={macrosDisabled}
                            onChange={value => this.onChangeMin(value)} />

                        <span className="separator">-</span>

                        <NutrientEntry value={max} nutrient={nutrient}
                            label="max" conflict={conflict}
                            isError={isError}
                            practice_type={practice_type}
                            disabled={macrosDisabled}
                            requiresUpgrade={macrosDisabled}
                            className={maxRecent ? "maximum2 updated" : "maximum2"}
                            resolveConflict={this.resolveConflictMax}
                            onChange={value => this.onChangeMax(value)} />

                    </div>

                    <div className="col-5">

                        <span className="unit">{nutrient.Units}</span>
                    </div>
                </div>
            );
        }

        if (viewportWidth < 600) {
            return (
                <div className="nutrient-editor nutrient-two-lines" key={nutrNo}>

                    <div className="col-2">
                        <label>{nutrient.NutrDesc}: {microsDisabled && practice_type != 'fitness' ? <i className="icon-lock" /> : null}</label>

                        <NutrientEntry value={min} nutrient={nutrient}
                            label="min" conflict={conflict}
                            className="minimum"
                            isError={isError}
                            practice_type={practice_type}
                            disabled={microsDisabled}
                            requiresUpgrade={microsDisabled && practice_type != 'fitness'}
                            resolveConflict={this.resolveConflictMin}
                            onChange={value => this.onChangeMin(value)} />

                    </div>

                    <div className="col-3">

                        <span className="separator">-</span>

                    </div>

                    <div className="col-4">

                        <NutrientEntry value={max} nutrient={nutrient}
                            label="max" conflict={conflict}
                            className="maximum"
                            isError={isError}
                            practice_type={practice_type}
                            disabled={microsDisabled}
                            requiresUpgrade={microsDisabled && practice_type != 'fitness'}
                            resolveConflict={this.resolveConflictMax}
                            onChange={value => this.onChangeMax(value)} />
                    </div>

                    <div className="col-5">

                        <span className="unit">{nutrient.Units}</span>

                    </div>
                </div>
            );
        }

        return (
            <div className="nutrient-editor" key={nutrNo}>

                <div className="col-1">

                    <label>{nutrient.NutrDesc}: {microsDisabled && practice_type != 'fitness' ? <i className="icon-lock" /> : null}</label>

                </div>

                <div className="col-2">

                    <NutrientEntry value={min} nutrient={nutrient}
                        label="min" conflict={conflict}
                        className="minimum"
                        isError={isError}
                        practice_type={practice_type}
                        disabled={microsDisabled}
                        requiresUpgrade={microsDisabled && practice_type != 'fitness'}
                        resolveConflict={this.resolveConflictMin}
                        onChange={value => this.onChangeMin(value)} />

                </div>

                <div className="col-3">

                    <span className="separator">-</span>

                </div>

                <div className="col-4">

                    <NutrientEntry value={max} nutrient={nutrient}
                        label="max" conflict={conflict}
                        className="maximum"
                        isError={isError}
                        practice_type={practice_type}
                        disabled={microsDisabled}
                        requiresUpgrade={microsDisabled && practice_type != 'fitness'}
                        resolveConflict={this.resolveConflictMax}
                        onChange={value => this.onChangeMax(value)} />
                </div>

                <div className="col-5">

                    <span className="unit">{nutrient.Units}</span>

                </div>
            </div>
        );
    }
}
