'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Expander.scss';

export default class Expander extends Component {
    static propTypes = {
        isDefaultExpanded: PropTypes.bool,
        isCollapsible: PropTypes.bool,
        onEdit: PropTypes.func,
    };

    static defaultProps = {
        isDefaultExpanded: false,
        isCollapsible: true,
    };

    static childContextTypes = {
        isExpanderVisible: PropTypes.bool,
        closeMenu: PropTypes.func,
        toggleMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isExpanderVisible: props.isDefaultExpanded,
        };
    }

    getChildContext = () => {
        return {
            isExpanderVisible: this.state.isExpanderVisible,
            closeMenu: this.closeExpander,
            toggleMenu: this.toggleExpander,
        };
    }

    toggleExpander = () => {
        const { isCollapsible, onToggle } = this.props;
        const { isExpanderVisible } = this.state;

        if (!isCollapsible) {
            return;
        }

        onToggle && onToggle(!isExpanderVisible);

        this.setState({isExpanderVisible: !isExpanderVisible});
    }

    closeExpander = () => {
        this.setState({isExpanderVisible: false});
    }

    render() {
        const { isExpanderVisible } = this.state;
        const { button, onEdit, secondary, isCollapsible, className } = this.props;

        return (
            <div className={classNames("pro-expander-container", className)} data-state={isExpanderVisible} data-collapsible={isCollapsible}>
                <button className="expander-btn" onClick={this.toggleExpander}>
                    {button}
                </button>

                {onEdit ?
                    <button className="edit-btn" onClick={onEdit}><i className="icon-pencil2" /></button>
                : null}

                {secondary}

                <div className="expander-expander">
                    <div className="expander-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
