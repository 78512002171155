'use strict';

import store from 'store';
import moment from 'moment';
import cookie from 'cookie-monster';

import { getTestGroup } from './ABTest';

const _env = {
};

export function  getConfig(key) {
    return _env[key];
}

export function  setConfig(key, value) {
    _env[key] = value;

    return value;
}

export function  cleanupLocalStorage() {

    // Remove old entries
    // ------------------
    // dashboard-featured-plans2-*
    // user-store-preferences
    //
    //
    // Keep most recent 100
    // --------------------
    // patient-recommended-plans-
    // menu-edited-
    //
    //
    // Add Expirations
    // ---------------
    // recent-edits

    let keysToRemove = [], edited = [], recommended = [];

    // ensure expired records are gone
    store.each((val, key) => {
        let ret = null, exp = null;

        // If the value was expired automatically, we don't need to do anything else.
        if (!(ret = store.get(key))) {
            return;
        }

        // We can ignore our expiration meta. This is part of a plugin, and if we remove
        // their associated key, the meta records also get deleted
        if (key.match(/__storejs_expire_mixin_/)) {
            return;
        }

        // Keys to remove
        if (key.match(/dashboard-featured-plans2-/) ||
            key.match(/user-store-preferences/)) {
            keysToRemove.push(key);
        }

        if (key.match(/patient-recommended-plans-/)) {
            exp = store.get('__storejs_expire_mixin_' + key);
            recommended.push({key, val, exp});
        }

        if (key.match(/menu-edited-/)) {
            exp = store.get('__storejs_expire_mixin_' + key);
            edited.push({key, val, exp});
        }

        // See if there's old A/B test group entries that can be cleaned up.
        // All our current A/B tests will return a value from getTestGroup. Deprecated tests will return undefined.
        var testGroupKey = key.match(/(.*)-test-group/);
        if (testGroupKey) {
            var testGroup = getTestGroup(testGroupKey[1]);
            if (!testGroup) {
                keysToRemove.push(key);
            }
        }
    });

    const sortByExp = (a, b) => {
        if (a.exp < b.exp) return 1;
        if (a.exp > b.exp) return -1;
        return 0;
    }

    recommended.sort(sortByExp);
    edited.sort(sortByExp);

    // Slice off all but the most recent 100 items in each list.
    if (recommended.length > 100) {
        recommended.slice(100 - recommended.length).forEach(rec => keysToRemove.push(rec.key));
    }

    if (edited.length > 25) {
        edited.slice(25 - edited.length).forEach(rec => keysToRemove.push(rec.key));
    }

    keysToRemove.forEach(key => store.remove(key));
}

export function setMref(mref) {
    if (!mref) {
        store.remove('merchant-ref');
        cookie.setItem('merchant-ref', null);
        return;
    }

    store.set('merchant-ref', mref);
    cookie.setItem('merchant-ref', mref, {
        expires: moment().add(10, 'years').toDate().toUTCString(),
        domain: getConfig('www_host').replace('www.', '.'),
        path: '/',
        secure: true,
    });
}

export function getMref() {
    const inStore = store.get('merchant-ref');
    const inCookie = cookie.getItem('merchant-ref');

    return inStore || inCookie;
}
