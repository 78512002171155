'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Unpublished extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
    };

    toggleUnpublished = () => {
        const { params, onChangeParams } = this.props;

        if (params.admin_list) {
            delete params.admin_list;
        } else {
            params.admin_list = true;
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;

        return (
            <span className="global-search-hideable-filter my-library-toggle">
                <label className="global-search-checkbox">
                    <input type="checkbox" checked={params.admin_list === true}
                           onChange={this.toggleUnpublished} />
                    <span>Include Unpublished</span>
                </label>
            </span>
        );
    }
}
