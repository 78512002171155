'use strict';
import './MealCardAlertOverlay.scss';

const MealCardAlertOverlay = ({
    title,
    subtitle,
    inhibitOptions,
    confirmOnClick,
    cancelOnClick,
    clearOnClick,
    confirmText="Yes, I Purchased It",
    cancelText="No, Change Meal",
    clearText="Clear Meal"
}) => {
    return (
        <div className="container-prompt-overlay">
            <div className="container">
                <div className="content">
                    <div className="header">
                        <i className={'icon-clock'} />
                        <div className="titles">
                            <div className='title'>{title}</div>
                            <div className='subtitle'>{subtitle}</div>
                        </div>
                    </div>
                    {!inhibitOptions ? (
                        <div className="options">
                            <button
                                className={"el-medium-btn el-wide-btn el-light-raspberry-outline-btn"}
                                onClick={confirmOnClick}>
                                    {confirmText}
                            </button>
                            <button
                                className={"el-medium-btn el-wide-btn el-light-raspberry-outline-btn"}
                                onClick={cancelOnClick}>
                                    {cancelText}
                            </button>
                            <button
                                className={"el-wide-btn el-link-raspberry-btn"}
                                onClick={clearOnClick}>
                                    {clearText}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default MealCardAlertOverlay;
