'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import '../RecommendModal.scss';
import ImgResized from '../../../../components/Widgets/ImgResized.react';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';
import CreatePatientModal from '../../Patients/Editor/Modals/CreatePatientModal.react';

import modalStyles from '../../../../jsx-styles/modals';

export default class AddRecipient extends Component {

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    };

    constructor(props,context) {
        super(props, context);

        this.state = {
            selectedRecipients: [],
            creatingPatient: false,
        };

        this.debounceSearch =  debounce(props.executeSearch, 350);
    }

    componentDidMount = () => {
        this.props.executeSearch('');
    }


    onClickRecipient = (patient) => {
        const { selectedRecipients } = this.state;
        var selectedRecipientsResult = selectedRecipients;

        if (selectedRecipients.find(p => p.uuid === patient.uuid)) {
            selectedRecipientsResult = selectedRecipients.filter(p => p.uuid !== patient.uuid);
        } else{
            selectedRecipientsResult.push(patient);
        }
        this.setState({selectedRecipients: selectedRecipientsResult});
    }

    onSaveRecipient = (patient) => {
        const { prependNewPatient} = this.props;
        const { selectedRecipients } = this.state;
        var selectedRecipientsResult = selectedRecipients;

        if (!selectedRecipients.find(p => p.uuid === patient.uuid)) {
            selectedRecipientsResult.push(patient);
            prependNewPatient(patient);
        }
        this.setState({selectedRecipients: selectedRecipientsResult});
    }

    renderResults = () => {
        const {  executeSearch, results } = this.props;
        const { selectedRecipients} = this.state
        return (
                <div className="add-recipient-container">
                    {selectedRecipients.length >= 1 && (<div className="recipients-added">
                        {selectedRecipients.map((patient, index)=>
                            <span key={index}>
                                <div>
                                    {patient.image ? <ImgResized width={45} height={45} src={patient.image} className="avatar" /> : null}
                                    {!patient.image ? <i className={patient.gender === 'male' ? 'icon-male2' : 'icon-female2'} />: null}
                                    <p className="recipient-label" key={index}>{patient.first_name} {patient.last_name}</p>
                                </div>
                                <span className="dot"><i className="icon-close-x x-symbol" onClick={() => this.onClickRecipient(patient)} /></span>
                            </span>
                        )}
                    </div>)}
                    <div className={"add-recipient-list"} data-has-recipients={selectedRecipients.length >= 1}>
                        {results.length >= 1 && results.map((patient, index)=>
                            <div className="recipient-row-container" key={index} onClick={() => this.onClickRecipient(patient)}>
                                {patient.image ? <ImgResized width={45} height={45} src={patient.image} className="avatar" /> : null}
                                {!patient.image ? <i className={patient.gender === 'male' ? 'icon-male2' : 'icon-female2'} />: null}
                                <p className="recipient-row" key={index}>{patient.first_name} {patient.last_name}</p>
                                <i className={selectedRecipients.find(p => p.uuid === patient.uuid) ? "icon-check-circle" : "icon-radio-unchecked"} />
                            </div>
                        )}
                    </div>
                </div>
       );
    }

    renderLoadingIcon = () => {
        return (
            <div className="loading-spinner-container">
                <h1 className="loading-spinner-header" >Loading...</h1>
                <i className="icon-spinner2 loading-spinner" />
            </div>
        )
    }

    toggleCreatingPatient = () => {
        const { router, location } = this.context;
        const { pathname, query, hash } = location;
        delete query.patientId;
        delete query.page;

        this.setState({creatingPatient: !this.state.creatingPatient}, () => router.push({pathname, query, hash}));
    }

    renderAddRecipientsModal = () => {
        const { closeModal, loading} = this.props;
        const { selectedRecipients } = this.state
        const doneButtonClass = "el-medium-btn el-raspberry-btn" + (selectedRecipients.length === 0 ? " hide-elment" : "")

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className={"el-modal el-modal2 recommend-modal"}
                contentLabel="Edit Patient"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal2-container recommend-modal-container">
                    <div>
                        <div className="add-recipient-header">
                            <button className="el-medium-btn el-grayish-blue-outline-btn" onClick={() => closeModal()}>
                                Cancel
                                <span className="assistive-text">Close Modal</span>
                            </button>
                            <div className="add-recipient-header-title">
                                <p>Choose Recipients</p>
                            </div>
                            <button className={doneButtonClass} onClick={() => closeModal(selectedRecipients)}>
                                Done
                            </button>
                        </div>
                        <div>
                            <div className="keyword-search">
                                <header>
                                    <input className="recipient-keyword-search" type="text" placeholder="Search" onChange={(e) => this.debounceSearch(e.target.value)}/>
                                </header>
                            </div>
                            <button className="create-patient-btn sub-action-btn" onClick={this.toggleCreatingPatient}>
                                <i className="icon-plus-thin" /> NEW PATIENT
                            </button>
                        </div>
                    </div>
                    {!loading ? this.renderResults() : this.renderLoadingIcon()}
                </div>
            </Modal>
        );
    }

    onFinishPatient = async (patient, populatePatientPlans) =>{
        const { router, confirm } = this.context;
        if (patient.practice_type !== 'dietetics') {
            this.onSaveRecipient(patient)
            this.toggleCreatingPatient();
            return;
        }

        await populatePatientPlans(patient);
        this.onSaveRecipient(patient)
        this.toggleCreatingPatient();
        return;
    }

    render = () => {
        const { closeModal, loading} = this.props;
        const { selectedRecipients, creatingPatient } = this.state
        const doneButtonClass = "create-patient-btn" + (selectedRecipients.length === 0 ? " hide-elment" : "")

        return (
           <div>
                {creatingPatient ?
                    <CreatePatientModal closeModal={this.toggleCreatingPatient}
                        onSavePatient={this.onSaveRecipient}
                        onFinishPatient={this.onFinishPatient}/>
                : this.renderAddRecipientsModal()}
           </div>
        );
    }
}