'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import Select from './Select.react';

import './DateSelector.scss';

function range(start, stop, inc = null) {
    inc = inc || (start > stop ? -1 : 1);

    const ar = [];

    var i = start;

    while (true) {
        ar.push(i);

        if (i === stop) {
            break;
        }

        i += inc;
    }

    return ar;
}

export default class DateSelector extends Component {
    static propTypes = {
        startTabIndex: PropTypes.number,
        label: PropTypes.string,
        delta_years: PropTypes.number,
        initialDate: PropTypes.any,
        disabled: PropTypes.bool,
        defaultClassName: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        labelClassName: PropTypes.string,
        selectClassName: PropTypes.string,
    };

    static defaultProps = {
        defaultClassName: 'date-selector-container',
        labelClassName: 'with-label',
        selectClassName: 'select-container',
        startTabIndex: 1,
        label: 'Birthdate',
        delta_years: -130,
        disabled: false,
    };

    static contextTypes = {};

    constructor(props) {
        super(props);

        // Figure out our initial day/month/years
        let month = '',
            day = '',
            year = '';

        if (props.initialDate) {
            const initialDate = moment(props.initialDate);

            month = initialDate.month() + 1; // overcome zero-index (why does moment do this?)
            day = initialDate.date();
            year = initialDate.year();
        }

        this.state = {
            month,
            day,
            year,
        };
    }

    updateParent = () => {
        const { onChange } = this.props;
        const { month, day, year } = this.state;

        // We can render a date without a day, but not a month or year
        if (!month || !year) {
            return;
        }

        const date = moment({ year, month: month - 1, day });

        onChange(date.format('YYYY-MM-DD'));
    };

    onChangeMonth = (month) => {
        this.setState({ month }, this.updateParent);
    };

    onChangeDay = (day) => {
        this.setState({ day }, this.updateParent);
    };

    onChangeYear = (year) => {
        this.setState({ year }, this.updateParent);
    };

    render() {
        const { label, delta_years, disabled, className, defaultClassName, labelClassName, selectClassName } =
            this.props;
        const { month, day, year } = this.state;
        let tabIndex = this.props.startTabIndex;

        const monthOpts = [
            { value: 1, label: '01 - January', hint: 'january' },
            { value: 2, label: '02 - February', hint: 'february' },
            { value: 3, label: '03 - March', hint: 'march' },
            { value: 4, label: '04 - April', hint: 'april' },
            { value: 5, label: '05 - May', hint: 'may' },
            { value: 6, label: '06 - June', hint: 'june' },
            { value: 7, label: '07 - July', hint: 'july' },
            { value: 8, label: '08 - August', hint: 'august' },
            { value: 9, label: '09 - September', hint: 'september' },
            { value: 10, label: '10 - October', hint: 'october' },
            { value: 11, label: '11 - November', hint: 'november' },
            { value: 12, label: '12 - December', hint: 'december' },
        ];

        // Get days in month
        const date = moment();
        if (day) date.date(day);
        if (month) date.month(month - 1);
        if (year) date.year(year);

        const daysInMonth = date.daysInMonth();

        const dayOpts = range(1, daysInMonth).map((day) => {
            return { value: day, label: String('00' + day).slice(-2) };
        });

        const currentYear = moment().year();

        const yearOpts = range(currentYear, currentYear + delta_years).map((year) => {
            return { value: year, label: year };
        });

        return (
            <div data-testid="date-selector" className={classNames(defaultClassName, className)}>
                <div className={classNames(labelClassName, 'month')} data-testid="date-month">
                    <label>{label}</label>
                    <Select
                        value={month}
                        placeholder="Month"
                        options={monthOpts}
                        disabled={disabled}
                        tabIndex={tabIndex++}
                        defaultClassName={selectClassName}
                        data-error={this.props['data-error']}
                        onChange={this.onChangeMonth}
                    ></Select>
                </div>

                <div className={classNames(labelClassName, 'day')} data-testid="date-day">
                    <label>&nbsp;</label>
                    <Select
                        value={day}
                        placeholder="Day"
                        options={dayOpts}
                        disabled={disabled}
                        tabIndex={tabIndex++}
                        defaultClassName={selectClassName}
                        data-error={this.props['data-error']}
                        onChange={this.onChangeDay}
                    ></Select>
                </div>

                <div className={classNames(labelClassName, 'year')} data-testid="date-year">
                    <label>&nbsp;</label>
                    <Select
                        value={year}
                        placeholder="Year"
                        options={yearOpts}
                        disabled={disabled}
                        tabIndex={tabIndex++}
                        defaultClassName={selectClassName}
                        data-error={this.props['data-error']}
                        onChange={this.onChangeYear}
                    ></Select>
                </div>
            </div>
        );
    }
}
