'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './Paginator.scss';

export default class Paginator extends Component {
    static propTypes = {
        buttonText: PropTypes.string,
        className: PropTypes.string,
    };

    static defaultProps = {
        buttonText: 'show more',
        className: 'browser-pager',
    };

    render() {
        const { loading, loadingMore, total, results, resultsParams, buttonText,
                showMore, className } = this.props;

        if (loading) {
            return <span />;
        }

        if (!showMore || total <= results.length || results.length >= 500) {
            return <span />;
        }

        let disabled = loadingMore ? true : false;

        return (
            <button disabled={disabled} className={className} onClick={showMore}>
                {!loadingMore ? buttonText : <span>loading...<i className="icon-spinner2" /></span>}
            </button>
        );
    }
}
