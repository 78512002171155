'use strict';

export default [
    {name: 'Newest', field: 'published'},
    {name: 'Relevance', field: 'relevance'},
    {name: 'Least Cost', field: 'avg_cost_per_serving'},
    {name: 'Least Prep Time', field: 'hands_on_time', type: 'recipe'},
    {name: 'Most Fruits', field: 'fruits'},
    {name: 'Most Veggies', field: 'vegetables'},
    {name: 'Highest Protein', field: 'protein'},
    {name: 'Highest Fiber', field: 'fiber'},
    {name: 'Highest Calcium', field: 'calcium'},
    {name: 'Highest Iron', field: 'iron'},
    //{name: 'Vitamin A', field: 'vitamin_a'},
    //{name: 'Vitamin C', field: 'vitamin_c'},
    {name: 'Highest Vitamin D', field: 'vitamin_d'},
    //{name: 'Vitamin E', field: 'vitamin_e'},
    //{name: 'Vitamin B-6', field: 'vitamin_b6'},
    {name: 'Highest Vitamin B-12', field: 'vitamin_b12'},
    {name: 'Highest Folate', field: 'folate'},
    {name: 'Highest Potassium', field: 'potassium', order: 'desc'},
    {name: 'Least Potassium',   field: 'potassium', order: 'asc'},
    {name: 'Highest Healthy Fats', field: 'healthy_fats'},
    //{name: 'Phosphorus', field: 'phosphorus'},
    //{name: 'Magnesium', field: 'magnesium'},
    //{name: 'Zinc', field: 'zinc'},
    {name: 'Least Added Sugar', field: 'added_sugar'},
    {name: 'Least Carbohydrates', field: 'carbohydrates', order: 'asc'},
    {name: 'Least Sodium', field: 'sodium', order: 'asc'},

    {name: 'Fewest Fresh Ingredients', field: 'total_fresh', type: 'plan'},
    {name: 'Best Efficiency Score', field: 'efficiency_score', type: 'plan'},
    {name: 'Ideals', field: 'true_ideal', publisherOnly: true}
];
