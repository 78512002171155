'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import PostalCodeInput from '../Input/PostalCodeInput.react';
import Alert from '../Widgets/Alert/Alert.react';

import './PostalCodeSelector.scss';

export default class PostalCodeSelector extends Component {
    static propTypes = {
        modalTitle: PropTypes.node,
        saveButtonText: PropTypes.string,
    }

    static defaultProps = {
        modalTitle: 'Change Postal Code',
        saveButtonText: 'Save'
    }

    render = () => {
        const { postalCode, onChangePostalCode, isModalOpen, closeModal, onSavePostalCode, alertMsg,
                modalTitle, children, working, saveButtonText } = this.props;

        return (
            <Modal isOpen={isModalOpen} onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Confirm your Postal Code"
                className="postal-code-modal el-modal el-modal3 el-modal3-centered"
                overlayClassName="feed-modal-overlay"
                closeTimeoutMS={250}>
                <div className="postal-code-modal-container el-modal-container el-modal3-container">
                    <header>
                        <h2>{modalTitle}</h2>

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal3-body-container postal-code-form">
                        {children}

                        <PostalCodeInput
                            value={postalCode}
                            onChange={onChangePostalCode}
                            onEnterKeyDown={onSavePostalCode}
                            autoFocus
                            showError />

                        {alertMsg ?
                            <Alert type="warning">{alertMsg}</Alert>
                        : null}
                    </div>

                    <footer>
                        <button disabled={working} className="el-modal-ok-btn" onClick={onSavePostalCode}>
                            {working ? <i className="icon-spinner2" /> : null}
                            {saveButtonText}
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
