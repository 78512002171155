'use strict';

import { Component } from 'react';
import './EmptyEnvelopeEditor.scss';

export default class EmptyEnvelopeEditor extends Component {
    static propTypes = {
    };

    render() {
        return (
            <div className="empty-envelope-editor">
                No nutrients here yet. Add some above!
            </div>
        );
    }
}
