"use strict";

import React from "react";
import { Calendar } from "react-multi-date-picker";
import gregorian from "react-date-object/calendars/gregorian";
import gregorian_en from "react-date-object/locales/gregorian_en";

import "./CalendarWidget.scss";

const Toolbar = ({
    state,
    handleChange,
    position,
    className,
    handleFocusedDate,
}) => {
    const deselect = () => {
        const selectedDate = range || multiple ? [] : null;

        handleChange(selectedDate, { ...state, selectedDate });
        handleFocusedDate();
    };

    const {
        range,
        multiple,
        date: { locale },
    } = state;

    const classNames = ["rmdp-toolbar", position];

    return (
        <div className={`${classNames.join(" ")} ${className}`}>
            <button className="el-flat-pill-toggle-btn" onClick={deselect}>
                Clear selection
            </button>
        </div>
    );
};

const CalendarWidget = ({
    value,
    onChange,
    working,
    minDate,
    maxDate,
    ref = null,
    formatShortWeekday = false,
    selectRange = false,
    selectMultipleRange = false,
    allowPartialRange = false,
    multiple = false,
    withToolbar = false,
}) => {
    const weekDayNames = formatShortWeekday
        ? ["S", "M", "T", "W", "T", "F", "S"]
        : null;

    return (
        <div className="custom-calendar">
            <Calendar
                className="rmdp-react-calendar"
                ref={ref}
                value={value}
                onChange={(selectedDates) => {
                    const result = Array.isArray(selectedDates)
                        ? selectedDates.map((p) =>
                              Array.isArray(p)
                                  ? p.map((q) => q.toDate())
                                  : p.toDate()
                          )
                        : selectedDates.toDate();

                    onChange && onChange(result);
                }}
                format="YYYY-MM-DD"
                minDate={minDate}
                maxDate={maxDate}
                weekDays={weekDayNames}
                locale={gregorian_en}
                calendar={gregorian}
                monthYearSeparator=","
                sort
                multiple={multiple || selectMultipleRange}
                showOtherDays
                range={selectRange || selectMultipleRange}
                rangeHover={selectRange || selectMultipleRange}
                mapDays={({ date }) => {
                    return {
                        disabled: working,
                        className: "rmdp-react-calendar__tile",
                        onClick: () => {},
                        children: (
                            <div style={{ display: "flex", fontSize: "16px" }}>
                                <div style={{ textAlign: "start" }}>
                                    {date.format("D")}
                                </div>
                            </div>
                        ),
                    };
                }}
                plugins={withToolbar ? [<Toolbar position="top" />] : []}
            />
        </div>
    );
};

export default CalendarWidget;
