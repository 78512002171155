import { Component } from 'react';
import PropTypes from 'prop-types';
import Select from '../../Widgets/Select.react';
import moment from 'moment';


export default class MealTypesInfo extends Component {

    constructor(props) {
        super(props);

        const { mealType } = this.props;

        const {
            name,
            keywords,
            max_time,
            max_cost,
            main_dish,
            begin,
            end,
            participants,
        } = mealType;

        this.state = {
            name,
            keywords,
            max_time,
            max_cost,
            main_dish,
            begin,
            end,
            participants,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { onChange, mealType } = this.props;
        const updatedMealType = {...mealType, ...this.state};
        if(this.state !== prevState) {
            onChange(updatedMealType);
        }
    }

    generateTimeOptions = () => {
        const options = [];
        const pad = (num) => (num < 10 ? '0' : '') + num;
    
        for (let h = 0; h < 24; h++) {
            for (let m = 0; m < 60; m += 15) {
                const hour = h % 12 === 0 ? 12 : h % 12;
                const ampm = h < 12 ? 'AM' : 'PM';
                const label = `${pad(hour)}:${pad(m)} ${ampm}`;
                const value = h * 60 + m;
                options.push({ value, label });
            }
        }
        return options;
    };

    deduceTimeForMealType = (startTimeInMinutes) => {
        const midnightTime = moment('00:00', 'HH:mm');
        const currentTime = midnightTime.add(startTimeInMinutes, 'minutes');
        const formattedCurrentTime = (startTimeInMinutes % 60 === 0) ? currentTime.format('h a') : currentTime.format('h:mm a');
        return formattedCurrentTime;
    }

    toggleParticipants = (participant) => {
        const { participants } = this.state;
        let updatedParticipants;
        if(participants.includes(participant)) {
            updatedParticipants = participants.filter((uuid) => uuid !== participant);
        } else {
            updatedParticipants = [...participants, participant];
        }
        this.setState({participants: updatedParticipants});
    }

    render() {
        const {
            name,
            keywords,
            max_time,
            max_cost,
            main_dish,
            begin,
            end,
            participants,
        } = this.state;

        const { patient, error } = this.props;
        const { uuid, first_name, family } = patient;
        const allParticipants = [{uuid, name: first_name}].concat(family.map((member) => ({uuid: member.uuid, name: member.name})));

        const maxTimeOptions = [
            {label: 'No limit', value: null},
            {label: 'Under 15 Minutes', value: 900},
            {label: 'Under 30 Minutes', value: 1800},
            {label: 'Under 45 Minutes', value: 2700},
            {label: 'Under 1 Hour', value: 3600},
        ];

        const maxCostMinimumValue = {
            'Breakfast': 3.25,
            'Lunch': 5,
            'Snacks': 3,
            'Dinner': 5
        }

        const tyepOptions = [
            {value: "Breakfast", label: "Breakfast"},
            {value: "Lunch", label: "Lunch"},
            {value: "Snack", label: "Snack"},
            {value: "Dinner", label: "Dinner"},
        ]

        const timeOptions = this.generateTimeOptions();

        return (
            <div className="meal-type-info">
                <div className="with-label max-time">
                    <label>Max Time:</label>
                    <Select
                        disableTypeAhead={true}
                        value={max_time || null}
                        options={maxTimeOptions}
                        onChange={(value) => this.setState({ max_time: value })}
                    />
                </div>
                <div className="with-label max-cost" data-error={error === `${name}-max-cost`}>
                    <label>Max Cost:</label>
                    <div>
                        <span>$ </span>
                        <input
                            type="number"
                            value={max_cost}
                            min={maxCostMinimumValue[main_dish] || 5}
                            max={99999}
                            step={0.5}
                            placeholder="No Limit"
                            onChange={(event) => {
                                const value = parseFloat(event.target.value);
                                const max_cost = value == NaN ? null : value;
                                this.setState({ max_cost });
                            }}
                        />
                    </div>
                </div>
                <div className="with-label time">
                    <label>Time:</label>
                    <Select
                        value={begin}
                        options={timeOptions}
                        onChange={(value) => this.setState({ begin: value })}
                    />
                    {"  -  "}
                    <Select
                        value={end}
                        options={timeOptions}
                        onChange={(value) => this.setState({ end: value })}
                    />
                </div>
                <div className="with-label type">
                    <label>Type:</label>
                    <Select
                        value={main_dish}
                        options={tyepOptions}
                        onChange={(value) => this.setState({ type: value })}
                    />
                </div>
                <div className="with-label keywords">
                    <label>Keywords:</label>
                    {keywords || "--"}
                </div>
                <div className="with-label participants">
                    Who is eating this meal ?
                    {allParticipants?.map((member) => (
                        <div className="participant">
                            {member.name}
                            <button
                                className="el-switch1"
                                data-active={
                                    participants?.includes(member.uuid)
                                }
                                onClick={() =>
                                    this.toggleParticipants(member.uuid)
                                }
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}