'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import Alert from '../../Widgets/Alert/Alert.react';

import allTags from '../../../tables/tags';

import './EditTags.scss';

export default class EditTags extends Component {

    static propTypes = {
        recipe: PropTypes.object,

        allowedTags: PropTypes.array,
        sectionTitle: PropTypes.node,
        sectionText: PropTypes.node,

        onChangeRecipe: PropTypes.func,
    };

    static defaultProps = {
    }

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
        };
    }

    openTagsModal = () => {
        const { recipe } = this.props;

        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    toggleTag = (tag) => {
        const { recipe, onChangeRecipe } = this.props;

        recipe.extratags = recipe.extratags || [];

        if (recipe.extratags.includes(tag)) {
            recipe.extratags.splice(recipe.extratags.indexOf(tag), 1);
        } else {
            recipe.extratags.push(tag);
        }

        onChangeRecipe(recipe, true);
    }

    renderModal = () => {
        const { isModalOpen } = this.state;
        const { recipe, allowedTags, sectionTitle, sectionText } = this.props;
        const { extratags = [] } = recipe;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Edit recipe tags"
                closeTimeoutMS={250}>
                <div className="el-modal-container el-modal2-container">
                    <div className="el-modal-body-container el-modal2-body-container">
                        {sectionText ? <Alert className="info">{sectionText}</Alert> : null}

                        <section className="el-labeled-input">
                            {sectionTitle ? <label>{sectionTitle}</label> : null}

                            <div>
                                {allowedTags.map(tag => (
                                    <button key={tag} className="el-toggle-btn" data-active={extratags.includes(tag)} onClick={() => this.toggleTag(tag)}>{tag}</button>
                                ))}
                            </div>
                        </section>

                    </div>

                    <footer>
                        <button className="el-modal-ok-btn" onClick={this.closeModal}>done</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    render = () => {
        const { recipe, allowedTags } = this.props;
        const tags = (recipe.extratags || []).filter(tag => allowedTags.includes(tag));

        return (
            <section className="edit-recipe-tags">
                <div className="select-container" onClick={this.openTagsModal}>
                    {tags.length > 0 ? <p className="value">{tags.join(', ')}</p> : null}
                    {tags.length == 0 ? <p className="placeholder">select</p> : null}

                    <button className="chevron">
                        <i className="icon-down-arrow" />
                    </button>
                </div>

                {this.renderModal()}
            </section>
        );
    }
}
