'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './PasswordValidationList.scss';

export default class PasswordValidationList extends Component {
    static contextTypes = {
        closeMenu: PropTypes.func,
    };

    render() {
        const { validations } = this.props;

        return (
            <div className='el-validation-list'>
                <p className='el-validation-title'>Password should contain:</p>
                {validations && validations.filter(p => p.showErrorRealTime).map((validation, index) => (
                    <div key={index} className="el-password-validations">
                        <i className={"feather " + (validation.isValid ? "feather-check" : "feather-x")} />
                        <p>{validation.message}</p>
                    </div>
                ))}
            </div>
        );
    }
}
