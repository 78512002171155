'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import classNames from 'classnames';

import LoginForm from '../../Widgets/LoginForm.react';

import './LoginModal.scss';

export default class LoginModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,

        offModalCta: PropTypes.node,

        defaultSignUp: PropTypes.bool,
        modalClassName: PropTypes.string,
        modalContainerClassName: PropTypes.string,
    };

    static defaultProps = {
        defaultSignUp: false,
        offModalCta: '',
        modalClassName: '',
        modalContainerClassName: '',
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    scrollable = null;

    realizeScrollable = (el) => {
        this.scrollable = el;
    }

    scrollToTop = () => {
        if (!this.scrollable) {
            return;
        }

        this.scrollable.scrollTop = 0;
    }

    render() {
        const { closeModal, offModalCta, loginFormOptions, modalClassName, modalContainerClassName } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Login"
                className={classNames("login-modal", modalClassName)}
                overlayClassName="login-modal-overlay"
                closeTimeoutMS={250}>
                <div className="login-form-scroll-container" ref={this.realizeScrollable} onClick={closeModal}>
                    <div className={classNames("login-modal-container", modalContainerClassName)} onClick={ev => ev.stopPropagation()}>
                        <LoginForm {...loginFormOptions}
                            closeModal={closeModal}
                            scrollToTop={this.scrollToTop} />
                    </div>
                </div>
            </Modal>
        );
    }
}
