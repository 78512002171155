'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import BoardStore from '../../../../stores/BoardStore';
import ImgResized from '../../../../components/Widgets/ImgResized.react';

import './DietitianBanner.scss';

export default class DietitianBanner extends Component {

    render() {
        const { plan, maxOffset } = this.props;

        const boards = BoardStore.getBoardsByResourceId(plan.uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === plan.uuid
            );

            return carry.concat(bcs);
        }, []);

        const content = contents[0];

        if (!content) {
            return <span />;
        }

        return (
            <div className="editor-dietitian-banner">
                {content.author_image ? <ImgResized debug={true} src={content.author_image} width="50" height="50" /> : null}
                <span>Your {maxOffset+1}-day meal plan from <em>{content.author_name || 'Your Dietitian'}</em></span>
                {content.notes ? <p>&ldquo;{content.notes}&rdquo;</p> : null}
            </div>
        );
    }
}
