'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';

import ImgResized from '../../Widgets/ImgResized.react';

import { roundForHumans } from '../../../utils/Math';

import './CardAbstract.scss';

export default class ComboCard extends Component {
    static propTypes = {
        combo: PropTypes.object,
    };

    static contextTypes = {
        addSwapContext: PropTypes.object,
        user: PropTypes.object,

        onSelectCombo: PropTypes.func,
        showComboDetails: PropTypes.func,
    };

    onClick = async () => {
        const { onSelectCombo, showComboDetails, addSwapContext } = this.context;
        const { combo, organicRanking, searchRanking, resultType, searchTerm, sortBy, sortOrder } = this.props;

        if (onSelectCombo) {
            const documents = await fetchDocumentsById([combo.main_dish, combo.side_dish]);

            let mainDish, sideDish;

            documents.forEach(doc => {
                if (combo.main_dish === doc.uuid) {
                    mainDish = doc;
                }

                if (combo.side_dish === doc.uuid) {
                    sideDish = doc;
                }
            });

            onSelectCombo(combo, mainDish, sideDish, addSwapContext.participants, {organicRanking, searchRanking, resultType, term: searchTerm, sortBy, sortOrder});
        } else if (showComboDetails) {
            showComboDetails(combo, {organicRanking, searchRanking, resultType, term: searchTerm, sortBy, sortOrder});
        }
    }

    render = () => {
        const { combo } = this.props;
        const { user } = this.context;
        const { hide_nutrition = false } = user;

        let calories = (combo.nutrients && combo.nutrients.values && combo.nutrients.values[208]) || 0;

        return (
            <li className="results-mini-card" onClick={this.onClick}>
                {combo.main_image
                    ? <ImgResized className="thumb" src={combo.main_image} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{combo.main_title} <em>+</em> {combo.side_title}</span>

                {!hide_nutrition ?
                    <span className="cals">{roundForHumans(calories)} kcal per serving (main+side)</span>
                : null}
            </li>
        );
    }
}
