'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

export default class MyLibrary extends Component {
    clearLibrary = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.library;

        onChangeParams(params, true);
    }

    render() {
        const { params = {} } = this.props

        if (!(params.filters && params.filters.library)) {
            return <span />;
        }

        return (
            <div className="param-item" onClick={this.clearLibrary}>
                From My Favorites <i className="icon-close-x" />
            </div>
        );
    }
}
