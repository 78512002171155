'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import './PasswordField.scss';
import PasswordValidationList from './PasswordValidationList.react';

export default class PasswordField extends Component {
    static contextTypes = {
        closeMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            showPassword1: false,
            showPassword2: false,
        };

        this.debounceValidate = debounce(() => this.validate(), 200);
    }

    componentDidMount() {
        this.debounceValidate();
    }

    validate = () => {
        const { onErrors, minCapitals, minLength, minLowercase, minNumbers, minSymbols, symbols } = this.props;
        const { password1, password2 } = this.state;

        // clear error list
        let errors = [];

        // length
        const minLengthRequired = minLength || 5;
        errors.push({
            'showErrorRealTime': minLength || false,
            'field': 'password1',
            'isValid': !!(password1 && (password1.length >= minLengthRequired)),
            'message': `At least ${minLengthRequired} characters`
        });

        // capitals
        minCapitals &&
        errors.push({
            'showErrorRealTime': true,
            'field': 'password1',
            'isValid': !!(password1 && (password1.match(/[A-Z]/g) || []).length >= minCapitals),
            'message': `At least ${minCapitals} capital(s) letter(s)`
        });

        // lowercases
        minLowercase &&
        errors.push({
            'showErrorRealTime': true,
            'field': 'password1',
            'isValid': !!(password1 && (password1.match(/[a-z]/g) || []).length >= minLowercase),
            'message': `At least ${minLowercase} lowercase(s) letter(s)`
        });

        // numbers
        minNumbers &&
        errors.push({
            'showErrorRealTime': true,
            'field': 'password1',
            'isValid': !!(password1 && (password1.match(/[0-9]/g) || []).length >= minNumbers),
            'message': `At least ${minNumbers} number(s)`
        });

        // symbols
        symbols && minSymbols &&
        errors.push({
            'showErrorRealTime': true,
            'field': 'password1',
            'isValid': !!(password1 && (password1.match(new RegExp('[' + symbols + ']', 'g')) || []).length >= minSymbols),
            'message': `At least ${minSymbols} symbol(s)`
        });

        // password match
        errors.push({
            'showErrorRealTime': false,
            'field': 'password2',
            'isValid': !!(password2 && password1 == password2),
            'message': 'Passwords do not match'
        });

        this.setState({errors});

        onErrors && onErrors(errors);
    }

    handleClickShowPassword1 = () => {
        const { showPassword1 } = this.state;
        this.setState({ showPassword1: !showPassword1 });
    };

    handleChangePassword1 = (e) => {
        const { setFieldValue } = this.props;
        //Call validate here to handle autofill e.g. LastPass
        this.setState({password1: e.target.value}, this.debounceValidate);
        setFieldValue && setFieldValue(e.target.value);
    }

    handleClickShowPassword2 = () => {
        const { showPassword2 } = this.state;
        this.setState({ showPassword2: !showPassword2 });
    };

    handleChangePassword2 = (e) => {
        //Call validate here to handle autofill e.g. LastPass
        this.setState({password2: e.target.value}, this.debounceValidate);
    }

    render() {
        const { className, showValidations, disabled, passwordLabel, confirmLabel, autoComplete, showFieldDataError } = this.props;
        const { errors, showPassword1, showPassword2 } = this.state;

        const effectiveClassName =  className || "el-labeled-input";

        return (
            <div className='password-field'>
                <div className={effectiveClassName}
                    data-error={showFieldDataError && effectiveClassName == "el-labeled-input" && errors.some(p => !p.isValid)}>
                    <label>{passwordLabel || 'Enter new password'}</label>
                    <input ref='password'
                        type={showPassword1 ? 'text' : 'password'}
                        data-error={showFieldDataError && effectiveClassName != "el-labeled-input" && errors.some(p => !p.isValid)}
                        placeholder="Enter password"
                        onChange={this.handleChangePassword1} disabled={disabled}
                        autoComplete={autoComplete || ''} />
                    <i className={'el-input-icon feather ' + (showPassword1 ? 'feather-eye-off' : 'feather-eye')} onClick={this.handleClickShowPassword1} />
                </div>

                <div className={effectiveClassName}
                    data-error={showFieldDataError && effectiveClassName == "el-labeled-input" && errors.filter(p => p.field == 'password2').some(p => !p.isValid)}>
                    <label>{confirmLabel || 'Confirm new password'}</label>
                    <input ref='confirmPassword'
                        type={showPassword2 ? 'text' : 'password'}
                        data-error={showFieldDataError && effectiveClassName != "el-labeled-input" && errors.filter(p => p.field == 'password2').some(p => !p.isValid)}
                        onChange={this.handleChangePassword2}
                        disabled={disabled}
                        autoComplete={autoComplete || ''} />
                    <i className={'el-input-icon feather ' + (showPassword2 ? 'feather-eye-off' : 'feather-eye')} onClick={this.handleClickShowPassword2} />
                </div>

                {showValidations && errors.some(p => p.showErrorRealTime) && <PasswordValidationList validations={errors} />}
            </div>
        );
    }
}
