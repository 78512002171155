'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';

import ImgResized from '../../Widgets/ImgResized.react';

import { roundForHumans } from '../../../utils/Math';

import './CardAbstract.scss';

export default class RecipeCard extends Component {
    static propTypes = {
        collection: PropTypes.object,
    };

    static contextTypes = {
        onSelectCollection: PropTypes.func,
        showCollectionDetails : PropTypes.func,
        user: PropTypes.object,
    };

    onClick = () => {
        const { onSelectCollection, showCollectionDetails } = this.context;
        const { collection } = this.props;

        if (onSelectCollection) {
            onSelectCollection(collection);
        } else if (showCollectionDetails) {
            showCollectionDetails(collection);
        }
    }

    render = () => {
        const { collection } = this.props;
        const { user, onSelectCollection } = this.context;
        const { hide_nutrition = false } = user;

        let calories = (collection.nutrients && collection.nutrients.values && collection.nutrients.values[208]) || 0;

        const count = (collection.items || []).length;

        return (
            <li className="results-mini-card" onClick={this.onClick}>
                {collection.image
                    ? <ImgResized className="thumb" src={collection.image} width={100} height={100} />
                    : <i className="icon-logo3 thumb" />
                }
                <span className="title">{collection.title}</span>

                {!hide_nutrition ?
                    <span className="cals">{count} {count == 1 ? 'recipe' : 'recipes'} </span>
                : null}
            </li>
        );
    }
}
