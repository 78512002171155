'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../pro/components/Widgets/Select.react';

import { getDurationFromSeconds } from '../../utils/Duration';

import './DurationSelector.scss';

export default class DurationSelector extends Component {
    static propTypes = {
        duration: PropTypes.number,
        onChange: PropTypes.func,
    };

    static defaultProps = {
    };

    constructor(props) {
        super(props);

        const { hours, minutes } = getDurationFromSeconds(props.duration);

        this.state = {
            hours: hours ? hours : null,
            minutes: minutes ? minutes: null,
        };
    }

    updateParent = () => {
        const { onChange } = this.props;
        const { hours, minutes } = this.state;

        const seconds = (hours || 0) * 3600 + (minutes || 0) * 60;

        onChange(seconds);
    }

    onChangeHours = (hours) => {
        this.setState({hours}, this.updateParent);
    }

    onChangeMinutes = (minutes) => {
        this.setState({minutes}, this.updateParent);
    }

    render() {
        const { recipe } = this.props;
        const { hours, minutes } = this.state;

        const hourOpts = [
            {value: 0, label: '-'},
            {value: 1, label: '1 hour'},
            {value: 2, label: '2 hours'},
            {value: 3, label: '3 hours'},
            {value: 4, label: '4 hours'},
            {value: 5, label: '5 hours'},
            {value: 6, label: '6 hours'},
            {value: 7, label: '7 hours'},
            {value: 8, label: '8 hours'},
            {value: 9, label: '9 hours'},
            {value: 10, label: '10 hours'},
            {value: 11, label: '11 hours'},
            {value: 12, label: '12 hours'},
        ];

        if (hours !== null && !hourOpts.find(o => o.value === hours)) {
            hourOpts.push({value: hours, label: `${hours} hours`});
            hourOpts.sort((a, b) => a.value - b.value);
        }

        const minuteOpts = [
            {value: 0, label: '-'},
            {value: 5, label: '5 minutes'},
            {value: 15, label: '15 minutes'},
            {value: 20, label: '20 minutes'},
            {value: 30, label: '30 minutes'},
            {value: 45, label: '45 minutes'},
        ];

        if (minutes !== null && !minuteOpts.find(o => o.value === minutes)) {
            minuteOpts.push({value: minutes, label: `${minutes} minutes`});
            minuteOpts.sort((a, b) => a.value - b.value);
        }

        return (
            <div className="duration-selector">
                <Select className="select-hours" options={hourOpts} value={hours} placeholder="hrs" onChange={this.onChangeHours} />
                <Select className="select-minutes" options={minuteOpts} value={minutes} placeholder="mins" onChange={this.onChangeMinutes} />
            </div>
        );
    }
}
