'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import './IngredientWarning.scss';

export default class IngredientWarning extends Component {
    static propTypes = {
        tags: PropTypes.array,
        avoid: PropTypes.array,
    };

    static defaultProps = {
        tags: [],
        avoid: [],
    }

    constructor(props) {
        super(props);

        this.state = {
            isWarningVisible: false,
        };
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.popover) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.popover.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeWarning();
        }
    }

    closeWarning = () => {
        this.setState({isWarningVisible: false});
    }

    toggleWarning = () => {
        const { isWarningVisible } = this.state;

        this.setState({
            isWarningVisible: !isWarningVisible
        });

        // If we're not in browser, ignore
        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isWarningVisible) {
            window.addEventListener(`mousedown`, this.onOuterAction);
            window.addEventListener(`touchstart`, this.onOuterAction);
        } else {
            window.removeEventListener(`mousedown`, this.onOuterAction);
            window.removeEventListener(`touchstart`, this.onOuterAction);
        }
    }

    realizeContainer = (container) => {
        this.container = container;
    }

    realizePopover = (popover) => {
        this.popover = popover;
    }

    render() {
        const { isWarningVisible } = this.state;

        return (
            <span className="ingredient-warning" data-visible={isWarningVisible} ref={this.realizeContainer}>
                <abbr onClick={this.toggleWarning} title={"Contains foods you're avoiding"}>
                    <i className="icon-warning" />
                </abbr>
                <div className="ingredient-warning-popover" ref={this.realizePopover}>
                    <p>Contains foods</p>
                    <p>avoided in your <Link to="/preferences/avoidances">taste profile</Link></p>
                </div>
            </span>
        );
    }
}
