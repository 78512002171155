'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import TagGroup from './TagGroup.react';

export default class MealTypes extends Component {
    static propTypes = {
    };

    static contextTypes = {
        setParamsToRecipeOnly: PropTypes.func,
    };

    onChangeParams = (params, oldTags) => {
        const { setParamsToRecipeOnly } = this.context;
        const { onChangeParams } = this.props;

        // Did we select any tag? Limit the results to just recipes
        if (oldTags.length < params.filters.tags.length && setParamsToRecipeOnly) {
            setParamsToRecipeOnly(params);
        }

        onChangeParams(params, oldTags);
    }

    render() {
        return (
            <TagGroup {...this.props} translateTagMap={{'Main Dish': 'Dinner'}} onChangeParams={this.onChangeParams} />
        );
    }
}
