'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import SmartFraction from '../../../../components/Widgets/SmartFraction.react';
import ImgResized from '../../../../components/Widgets/ImgResized.react';
import CalendarWidget from '../../../../components/Widgets/CalendarWidget.react';

import modalStyles from '../../../../jsx-styles/modals';
import '../../../../components/Planner/Modals/MealRescheduleModal.scss';
import './PickDateModal.scss';

export default class PickDateModal extends Component {
    static propTypes = {
        dateStart: PropTypes.object,
        onPickDate: PropTypes.func,
        closeModal: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            date: props.dateStart ? moment(props.dateStart) : moment(),
        };
    }

    onChangeDate = (date) => {
        this.setState({date: moment(date)});
    }

    onPickDate = () => {
        const { onPickDate } = this.props;
        const { date } = this.state;

        onPickDate && onPickDate(date);
    }

    render() {
        const { date } = this.state;
        const { closeModal } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="meals-reschedule-modal customizer-pick-date-modal"
                style={modalStyles.largeSquareModal}
                contentLabel="Meal Plan Groceries"
                closeTimeoutMS={250}>

                <div className="edit-meal-modal-container meals-reschedule-modal-container customizer-pick-date-container">
                    <header>
                        <button onClick={closeModal} className="close-btn">
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <section className="modal-scroll-container">
                        <h2>Please choose a date to start this meal plan on.</h2>

                        <CalendarWidget minDate={new Date()}
                            value={date.toDate()}
                            onChange={this.onChangeDate} />
                    </section>

                    <footer className="modal-action-footer">
                        <p>Meal Plan will be scheduled for <strong>{date.format('dddd MMMM Do')}</strong></p>
                        <button className="ok-btn" onClick={this.onPickDate}>Schedule</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
