'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import UserConstants from '../constants/UserConstants';
import AuthStore from '../stores/AuthStore';
import UserStore from '../stores/UserStore';
import omit from 'lodash.omit';

import { getConfig } from '../utils/Env';

export default {
    subscribe(newUser) {
        const user = {
            ...UserStore.getUser(),
            ...newUser,
        };

        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_SUBSCRIBE,
            user,
        });
    },

    updateSpecificMeta(meta) {
        // omit safe mode properties from preferences
        const { preferences = {}, ...postBody } = meta;
        const { ...prefsRest} = preferences;

        // Any preferences to include? Don't include the preferences object if not.
        if (Object.keys(prefsRest).length) {
            postBody.preferences = prefsRest;
        }

        return AuthStore.fetch(UserStore.getLinks().self, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=user/1'},
            body: JSON.stringify(postBody),
        }).then(
            success => {
                const user = {
                    ...UserStore.getUser(),
                    ...success,
                };

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_UPDATE_META,
                    user,
                });
            }
        ).catch((error) => {
            throw error;
        });
    },

    changePassword(password, extras = {}) {
        const user = {
            ...UserStore.getUser(),
            ...extras,
            is_password_set: true,
        };

        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_UPDATE_META,
            user,
        });

        return AuthStore.fetch(UserStore.getLinks().self, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=user/1'},
            body: JSON.stringify({password, ...extras}),
        }).then(
            response => {
                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_UPDATE_META,
                    user: response,
                });

                return response;
            }
        );
    },

    synchronizeUser() {
        // Get everything about this user. We'll hydrate the stores with the embedded data.
        const usersUrl = UserStore.getLinks().self + '?embed=family,boards,meals,plans,groceries,capabilities,location';

        return AuthStore.fetch(usersUrl).then(
            user => {
                const {
                    preferences = {diets: [], avoidances: []},
                    family = [],
                    boards = [],
                    meals = [],
                    plans = [],
                    groceries = [],
                    capabilities = {},
                    location = {},
                } = user;

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_COMPLETE_LOGIN,
                    user, capabilities, family, boards, meals, plans, groceries, location
                });
            },
            error => {

            }
        );
    },

    generateICalendarURL() {
        return AuthStore.fetch(UserStore.getLinks().self + '/generate-icalendar-url', {
            method: 'POST',
            headers: {},
        }).then(
            response => {
                const user = UserStore.getUser();
                const href = response.message.substring(getConfig('users_api').length);

                user.links.icalendar = {href};

                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_UPDATE_META,
                    user,
                });

                return response;
            },
            error => false,
        );
    },

    changeEmail(email) {
        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_CHANGE_EMAIL,
            email,
        });

        return AuthStore.fetch(UserStore.getLinks().self, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=user/1'},
            body: JSON.stringify({email}),
        });
    },

    syncProfileKey() {
        return AuthStore.fetch(UserStore.getLinks().syncProfileKey, {method: 'POST',})
                        .then(
            response => {
                AppDispatcher.handleViewAction({
                    actionType: UserConstants.USER_HYDRATE_PROFILE_KEY,
                    profileKey: response.profile_key,
                });
            },
        );
    },

    updateCurrentLocation(lat, lon) {
        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_HYDRATE_LOCATION,
            lat, lon,
        });
    },

    hydrateProfileKey(profileKey) {
        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_HYDRATE_PROFILE_KEY,
            profileKey,
        });
    },

    setAlternateUser(altUser) {
        AppDispatcher.handleViewAction({
            actionType: UserConstants.USER_SET_ALTERNATE_USER,
            altUser
        });
    },

    sendConfirmationEmail({redirect = null}) {
        const links = UserStore.getLinks();

        return AuthStore.fetch(links.confirmation, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=send/confirm/1'},
            body: JSON.stringify({redirect}),
        });
    },

    sendWelcomeEmail() {
        const links = UserStore.getLinks();

        return AuthStore.fetch(links.welcome, {method: 'POST'});
    }

};
