'use strict';

import React, { Component } from 'react';
import Modal from 'react-modal';
import RecipeEditor from '../Editor.react';
import PropTypes from 'prop-types';

export default class RecipeEditorModal extends Component {

    static propTypes = {
        profile: PropTypes.object,
        uuid: PropTypes.string,
        title: PropTypes.string,
        onSave: PropTypes.func,
        onClose: PropTypes.func,
        saveBtnText: PropTypes.string
    };

    constructor(props) {
        super(props);
    }

    render() {
        const { onClose, uuid, profile, onSave, title, saveBtnText } = this.props;

        return (
            <Modal isOpen={true}
            onRequestClose={onClose}
            closeModal={onClose}
            className="feed-modal edit-meal-modal"
            overlayClassName="feed-modal-overlay"
            contentLabel="Edit Recipe Details">
                <div className="edit-meal-modal-container">
                    <header onClick={(ev) => ev.stopPropagation()}>
                        <div className="top-half-circle no-curve">&nbsp;</div>
                        <div className="header-container">
                            <button className="close-modal-btn" onClick={onClose}>
                                <i className="icon-arrow-left" />
                            </button>
                            <h1 className="food-edit-header">{title}</h1>
                        </div>
                    </header>

                    <div className="modal-scroll-container">
                        <div className="inner-slider">
                            <RecipeEditor 
                                uuid={uuid}
                                profile={profile}
                                closeModal={onClose}
                                onSaveRecipe={onSave}
                                saveDirtyBtnText={saveBtnText} />
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}