'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import uuidGen from 'uuid';
import moment from 'moment';

import MealDraggable from '../../../../components/Dashboard/Grid/MealDraggable.react';

import Select from '../../Widgets/Select.react';

import '../../../../components/Planner/Modals/ConfirmOverwriteModal.scss';

export default class ConfirmOverwriteModal extends Component {

    static contextTypes = {
        isMobile: PropTypes.boolean,
    }

    static propTypes = {
        closeModal: PropTypes.func.isRequired,

        onModifyMeals: PropTypes.func.isRequired
    };

    static defaultProps = {
        ctaTitle: 'Save Meal',
    };

    constructor(props) {
        super(props);

        const overlaps = props.overlaps.map(overlap => ({...overlap, action: 'replace'}));

        this.state = {
            overlaps,
        };
    }

    setOverlapAction = (i, action) => {
        const { overlaps } = this.state;

        overlaps[i].action = action;

        this.setState({overlaps});
    }

    generateMealId() {
        return uuidGen.v4().substring(0, 8);
    }

    onSaveReschedule = () => {
        const { overlaps } = this.state;
        const { meals, clears, offset, mealType, onModifyMeals, closeModal } = this.props;

        let dirtyMeals = [], mealsToDelete = [], leftoverOffsets = clears.slice(0);

        overlaps.forEach((overlap) => {
            if (overlap.action === 'swap') {
                // Loop through all destMeals and set their mealType/offset to the value from sourceMeals[0],
                // add each meal to the dirtyMeals array
                overlap.destMeals.forEach(meal => {
                    meal.meal = overlap.sourceMeals[0].meal;
                    meal.offset = overlap.sourceMeals[0].offset;

                    if (dirtyMeals.indexOf(meal) === -1) {
                        dirtyMeals.push(meal);
                    }
                });
            } else if (overlap.action === 'replace') {
                // Loop through each destMeal and add it to the mealsToDelete array
                overlap.destMeals.forEach(meal => {
                    meal.meal = overlap.sourceMeals[0].meal;
                    meal.offset = overlap.sourceMeals[0].offset;

                    if (mealsToDelete.indexOf(meal) === -1) {
                        mealsToDelete.push(meal);
                    }
                });
            } else if (overlap.action === 'combine') {
                // Don't do anything with destMeals, we're going to leave them alone.
            }

            // Loop through sourceMeals and if not a leftover, set the mealType/offset to the overlap mealType/offset
            // and then add to dirtyMeals array
            overlap.sourceMeals.forEach(meal => {
                if (meal.meal_type === 'leftover') {
                    if (!leftoverOffsets.includes(meal.offset)) {
                        leftoverOffsets.push(parseInt(meal.offset));
                    }
                    return;
                }

                meal.meal = overlap.mealType;
                meal.offset = overlap.offset;

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });

            // Add to leftoverOffsets
            leftoverOffsets.push(overlap.offset);
        });

        meals.forEach(meal => {
            meal.meal = mealType;
            meal.offset = offset;

            if (dirtyMeals.indexOf(meal) === -1) {
                dirtyMeals.push(meal);
            }
        });

        onModifyMeals(dirtyMeals, mealsToDelete, leftoverOffsets);
        closeModal(true);
    }

    onSaveRepeat = () => {
        const { overlaps } = this.state;
        const { clears } = this.props;
        const { onModifyMeals, closeModal } = this.props;

        let dirtyMeals = [], mealsToDelete = [];

        overlaps.forEach(({action, destMeals, sourceMeals, mealType, offset}) => {
            if (action === 'replace') {
                // Loop through each destMeal and add it to the mealsToDelete array
                destMeals.forEach(meal => {
                    if (mealsToDelete.indexOf(meal) === -1) {
                        mealsToDelete.push(meal);
                    }
                });
            } else if (action === 'combine') {
                // Don't do anything with destMeals, we're going to leave them alone.
            }

            sourceMeals.forEach(meal => {
                meal.meal = mealType;
                meal.offset = offset;

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });
        });

        clears.forEach(({sourceMeals, mealType, offset}) => {
            sourceMeals.forEach(meal => {
                meal.meal = mealType;
                meal.offset = offset;

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });
        });

        onModifyMeals(dirtyMeals, mealsToDelete);

        closeModal(true);
    }

    sortOverlaps = (a, b) => {
        const properOrder = ['Breakfast', 'Lunch', 'Snack', 'Dinner'];
        if (a.offset != b.offset) {
            return a.offset - b.offset;
        }

        // #2 Sort by meal type
        let ai = properOrder.indexOf(a.mealType),
            bi = properOrder.indexOf(b.mealType);

        return ai - bi;
    }

    render() {
        const { isMobile } = this.context;
        const { isRepeatMeal, closeModal } = this.props;
        const { overlaps } = this.state;

        const imageDimensions = isMobile ? {"width": 75, "height": 75} : {"width": 150, "height": 150};

        const actionOpts = [
            !isRepeatMeal ? {value: 'swap', label: 'SWAP with'} : null,
            {value: 'replace', label: 'REPLACE with'},
            {value: 'combine', label: 'COMBINE with'},
        ].filter(v => v);

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Overwrite with leftovers"
                className="log-portions-modal"
                overlayClassName="feed-modal-overlay log-portions-overlay"
                closeTimeoutMS={250}>
                <div className="log-portions-modal-container leftover-offsets-container confirm-overwrite-container">
                    <header>
                        <button className="close-modal-btn" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>

                        <h2>What should we do with your previously scheduled {overlaps.length > 1 ? 'meals' : 'meal'}?</h2>
                    </header>

                    <section className="confirm-overwrite-form preferences-form">
                        <ul className="overlap-list">
                            {overlaps.sort(this.sortOverlaps).map((overlap, i) => (
                                <li key={i}>

                                    <div className="meals-container">
                                        <h4>{overlap.mealType}, Day {overlap.offset + 1}</h4>
                                        <MealDraggable mode="future" meals={overlap.destMeals}
                                            imageDimensions={imageDimensions} disableDnD={true} disableGrocery={true} disableControls={true} />

                                        <Select options={actionOpts} value={overlap.action}
                                            onChange={action => this.setOverlapAction(i, action)} />

                                        <span><i className="icon-chevron-left" /></span>

                                        <MealDraggable mode="future" meals={overlap.sourceMeals}
                                            imageDimensions={imageDimensions} disableDnD={true} disableGrocery={true} disableControls={true} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </section>

                    <footer>
                        <button className="cancel-btn" onClick={closeModal}>cancel</button>
                        <button className="ok-btn" onClick={isRepeatMeal ? this.onSaveRepeat : this.onSaveReschedule}>Save</button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
