'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import HouseholdMemberContent from './HouseholdMemberContent.react';

import './HouseholdMemberModal.scss';


export default class HouseholdMemberModal extends Component {
    static propTypes = {
        member: PropTypes.object,
        profile: PropTypes.object,
    };

    render() {
        const { member, profile, closeModal, onChange, onDeleteFamilyMember } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Household Member Editor"
                className="el-modal el-modal2 household-member-modal"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}>
                <section className="el-modal-container el-modal2-container edit-meal-modal-container household-member-modal-container">
                    <HouseholdMemberContent
                        member={member}
                        profile={profile}
                        onDeleteFamilyMember={onDeleteFamilyMember}
                        closeModal={closeModal}
                        onChange={onChange} />
                </section>
            </Modal>
        );
    }
}
