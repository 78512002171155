'use strict';

import keymirror from 'keymirror';

export default keymirror({
    GROCERIES_LOAD: null,
    GROCERIES_DELETE: null,
    GROCERIES_UPSERT: null,
    GROCERIES_REFRESH_START: null,
    GROCERIES_REFRESH_STOP: null,
    GROCERIES_FEED_REGEN: null,
    GROCERIES_HYDRATE: null,
});
