'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import InvoiceHeader from './InvoiceHeader.react';
import InvoiceBody from './InvoiceBody.react';



import { priceFormat, getInvoiceBreakdown, getInvoiceChargeCard } from '../../utils/Invoice';

import './Invoice.scss';

export default class Invoice extends Component {
    static propTypes = {
        invoice: PropTypes.object,
        onChangeCoupon: PropTypes.func,
        onChangeCard: PropTypes.func,
        setAddCouponMode: PropTypes.func,
        addCouponMode: PropTypes.bool,
        confirmText: PropTypes.node,
        addTraining: PropTypes.func,
        removeTraining: PropTypes.func,
        allowAddCoupon: PropTypes.bool,
        allowAddOnHourly: PropTypes.bool,
        isConfirmed: PropTypes.bool,
        hideConfirmationButton: PropTypes.bool,
        isReceipt:  PropTypes.bool,
        creditCard: PropTypes.object,
        cardError: PropTypes.any,
        coupon:  PropTypes.string,
    };

    static defaultProps = {
        allowAddCoupon: false,
        allowAddOnHourly: false,
        hideConfirmationButton: false,
        isConfirmed: false,
        isReceipt: false,
        cardError: null,
        confirmText: 'Confirm Purchase',
        coupon: '',
    };

    constructor(props) {
        super(props);

    }

    render = () => {
        const { invoice, working, cardError, onChangeCoupon, onChangeCard, setAddCouponMode, addCouponMode, addTraining, removeTraining, closeModal, isConfirmed, allowAddOnHourly, permit,isReceipt, coupon, creditCard } = this.props;
        const { charge } = invoice || {};
        const breakdown = invoice ? getInvoiceBreakdown(invoice) : null;
        const card = invoice ? getInvoiceChargeCard(invoice) : null;

        return (
            <div>
                <div className="stripe-invoice">
                    <header>

                        {isConfirmed  ?
                           <div className="green-circle">
                               <i className="feather feather-check" />
                           </div>
                        : <i className="logo icon-logo2"><span className="path1" /><span className="path2" /></i>}
                       {invoice  ?
                           <InvoiceHeader
                            isConfirmed={isConfirmed}
                            isReceipt={isReceipt}
                            invoice={invoice}
                            cardError={cardError}
                            />
                       :
                          <div>
                              <h1>Thank you! Your membership has been updated.</h1>
                          </div>
                      }
                    </header>
                    {invoice  ?
                    <InvoiceBody
                        permit={permit}
                        isConfirmed={isConfirmed}
                        invoice={invoice}
                        addTraining={addTraining}
                        removeTraining={removeTraining}
                        allowAddOnHourly={allowAddOnHourly}
                        onChangeCoupon={onChangeCoupon}
                        onChangeCard={onChangeCard}
                        setAddCouponMode={setAddCouponMode}
                        addCouponMode={addCouponMode}
                        coupon={coupon}
                        creditCard={creditCard}
                    />
                    : null}
                </div>

            </div>
        );
    }
}
