'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import platform from 'platform';

import SpringHero from '../../../../components/MealDetails/SpringHero.react';
import RecipeIngredients from '../../../../components/Modules/RecipeIngredients.react';
import FoodIngredients from '../../../../components/Modules/FoodIngredients.react';
import RecipeInstructions from '../../../../components/Modules/RecipeInstructions.react';
import RecipeEditor from '../../../../components/Recipes/Editor.react';
import RecipeToPdfButton from '../../../../components/Recipes/RecipeToPdfButton.react';
import MyTabs from '../../../../components/Widgets/Tabs.react';
import NutritionInfo from '../../../../components/Products/NutritionInfo.react';
import HowMuchToEat from '../../../../components/Recipes/HowMuchToEat.react';
import Dropdown from '../../../../pro/components/Widgets/Dropdown.react';
import RecommendModal from '../../../../pro/components/Modals/RecommendModal.react';
import Merchant from '../../../../components/Recipes/Merchant.react';
import Popup from '../../../../components/Widgets/Popup.react';
import SharePopup from '../../../../components/Widgets/SharePopup.react';
import RecommendPopup from '../../../../components/Widgets/RecommendPopup.react';
import LocationInfo from '../../../../components/Products/LocationInfo.react';
import AddToCollectionButton from '../../../../components/Recipes/AddToCollectionButton.react';
import Alert from '../../../../components/Widgets/Alert/Alert.react';
import FoodUnitsSelector from '../../../../components/DailyLog/FoodUnitsSelectorModal.react';
import SwapMenu from '../../../../components/Dashboard/SwapMenu.react';

import UserStore from '../../../../stores/UserStore';
import AuthStore from '../../../../stores/AuthStore';

import { ScreenService } from '../../../../utils/ScreenService';
import { toHuman } from '../../../../utils/Duration';
import { getConfig } from '../../../../utils/Env';
import {
    mealSortCompare,
    getParticipantsForMeal,
    getPrimaryMeal,
    getLoggedServingsOfRecipe,
} from '../../../../utils/Meals';
import { getNutrientsForMeals } from '../../../../utils/Nutrition';
import { isSunbasketFood } from '../../../../utils/Sunbasket';

import './MealDetails.scss';
import '../../../../components/FeedModals.scss';
import MealTitle from '../../../../components/Meals/MealTitle.react';
import MultiSharePopup from '../../../../components/Widgets/MultiSharePopUp.react';

const sections = ['first', 'second', 'third', 'fourth', 'fifth'];

export default class MealDetails extends Component {
    static propTypes = {
        profile: PropTypes.object,

        hidePrintBtn: PropTypes.bool,
    };

    static defaultProps = {
        hidePrintBtn: false,
    };

    static contextTypes = {
        confirm: PropTypes.func,
        router: PropTypes.object,
        isMobile: PropTypes.bool,
        isCordova: PropTypes.bool,
        isPro: PropTypes.bool,
        breakpoint47em: PropTypes.bool,
        location: PropTypes.object,

        plan: PropTypes.object,
        meals: PropTypes.array,
        recipes: PropTypes.object,
        details: PropTypes.object,
        foods: PropTypes.object,
        merchants: PropTypes.object,
        locations: PropTypes.object,
        brands: PropTypes.object,
        mismatches: PropTypes.object,

        groceries: PropTypes.array,

        editMealBatches: PropTypes.func,
        onModifyMeals: PropTypes.func,
        onRemoveMeals: PropTypes.func,
        startAddMeal: PropTypes.func,
        startReplaceMeal: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const profile = this.getProfileFromProps(props, context);
        const meals = this.getMealsFromProps(props, context);

        this.loadPlanAssets(context.plan.uuid);

        this.state = {
            user: UserStore.getUser(),
            profile,
            ...this.getSyncState(props, context, meals, profile),
            dishIndex: 0,
            editMealModal: null,
            hasBeenRecommended: false,
        };

        // this.handleScroll = debounce(this.handleScroll, 50);
    }

    componentDidMount = () => {
        ScreenService.keepAwake();
        UserStore.addChangeListener(this.onUserStoreChange);
    };

    componentWillUnmount = () => {
        ScreenService.allowSleep();
        UserStore.removeChangeListener(this.onUserStoreChange);
    };

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const profile = this.getProfileFromProps(nextProps, nextContext);
        const meals = this.getMealsFromProps(nextProps, nextContext);

        let { dishIndex } = this.state;

        if (!meals[dishIndex]) {
            this.scrollToDishIndex(0);
        }

        this.setState(this.getSyncState(nextProps, nextContext, meals, profile));
    };

    onUserStoreChange = () => {
        this.setState({ user: UserStore.getUser() });
    };

    getMealsFromProps = (props, context) => {
        return props.meals;
    };

    getProfileFromProps = (props, context) => {
        return props.profile || context.profile;
    };

    getSyncState = (props, context, meals, profile) => {
        const { offset = 0, mealType = 'Dinner' } = props || this.props;
        const { mismatches, recipes, foods } = context || this.context;
        const deviations = (mismatches && mismatches[offset] && mismatches[offset][mealType]) || [];
        const allDayMismatch = (mismatches && mismatches[offset] && mismatches[offset]['all-day']) || [];
        let isMismatched = deviations.length > 0;
        let isOverLimits = deviations.filter((miss) => miss.value > miss.max).length > 0;
        let isDailyMismatched = allDayMismatch.length > 0;
        let isDailyOverLimit = allDayMismatch.filter((miss) => miss.value > miss.max).length > 0;

        meals.sort((a, b) => mealSortCompare(a, b, true));

        const primary = getPrimaryMeal(meals, recipes, foods);

        if (!meals.length || !primary?.primary) {
            return { meals: [], offset, mealType, primary: null, participants: [], profile };
        }

        return {
            meals,
            offset,
            mealType,
            primary,
            participants: getParticipantsForMeal(primary.primary, profile),
            profile,

            deviations,
            isMismatched,
            isOverLimits,
            isDailyMismatched,
            isDailyOverLimit,
        };
    };

    showEditMealServings = () => {
        const { editMealBatches } = this.context;
        const { primary } = this.state;

        editMealBatches && editMealBatches(primary.primary);
    };

    closeModal = () => {
        const { closeModal } = this.props;
        closeModal();
    };

    scrollToDishIndex = (dishIndex) => {
        const { meals } = this.state;

        if (!(meals && meals[dishIndex])) {
            return;
        }

        this.setState({ dishIndex });
    };

    editSingleMeal = (meal, ev) => {
        const { dishIndex } = this.state;
        let i = this.state.meals.indexOf(meal);

        if (i !== dishIndex) {
            return this.scrollToDishIndex(i);
        }

        ev && ev.stopPropagation();

        this.setState({ editMealModal: meal });
    };

    deleteSingleMeal = (meal, ev) => {
        const { confirm, onRemoveMeals, recipes } = this.context;
        const { dishIndex } = this.state;
        let i = this.state.meals.indexOf(meal);

        if (i !== dishIndex) {
            return this.scrollToDishIndex(i);
        }

        ev && ev.stopPropagation();

        const pm = getPrimaryMeal([meal], recipes);

        confirm(
            <div>
                <p>
                    Remove <em>{pm.titles.join(', ')}</em> from {meal.meal}?
                </p>
            </div>,
            (accept) => {
                // Move to the next dish
                if (i > 1) {
                    this.scrollToDishIndex(i - 1);
                } else {
                    this.scrollToDishIndex(0);
                }

                onRemoveMeals([meal]);
            },
            (reject) => false
        );
    };

    onStartRecommend = (recipe_uuid) => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        query.recommend = recipe_uuid;

        router.push({ pathname, hash, query });
    };

    closeRecommendModal = () => {
        const { location, router } = this.context;
        const { pathname, hash, query } = location;

        delete query.recommend;

        router.push({ pathname, hash, query });
    };

    closeEditMealModal = () => {
        this.setState({ editMealModal: null });
    };

    onSaveRecipe = (meal, recipe, details) => {
        const { profile } = this.state;
        const { onModifyMeals } = this.context;

        meal.recipe_uuid = recipe.uuid;
        meal.details_uuid = details.uuid;
        // Reset logged grams to default in case recipe grams_per_serving changed
        meal.logged_grams = recipe.grams_per_serving * profile.portion;
        meal.logged_milliliters = recipe.milliliters_per_serving * profile.portion;

        // If the recipe & details UUID changed, this should trigger a full sync.
        onModifyMeals([meal]);

        this.closeEditMealModal();
    };

    loadPlanAssets = (uuid) => {
        AuthStore.fetch(getConfig('recipe_api') + '/plans/' + uuid + '?embed=assets', null, true).then(
            (plan) => {
                this.setState({ hasBeenRecommended: true });
            },
            (error) => {
                this.setState({ hasBeenRecommended: false });
            }
        );
    };

    renderMealControlButtons = (meal, content, canEditSingleMeal = true) => {
        const { meals, user, profile } = this.state;
        const { inhibit_swap = false, rd_override = false } = profile || {};
        const { hideEditBtns } = this.props;
        const buttons = [];

        if (hideEditBtns || (inhibit_swap && !rd_override)) {
            return;
        }

        if (user?.role === 'admin' && meal.food_uuid) {
            buttons.push(
                <a
                    key="admin"
                    target="_blank"
                    rel="noreferrer"
                    href={'https://' + getConfig('www_host') + `/admin/foods/${meal.food_uuid}`}
                >
                    <button className="edit-meal-btn">
                        <i className="feather feather-box" />
                        <em>admin</em>
                    </button>
                </a>
            );
        }

        if (user?.role === 'admin' && meal.recipe_uuid) {
            buttons.push(
                <a
                    key="admin"
                    target="_blank"
                    rel="noreferrer"
                    href={'https://' + getConfig('www_host') + `/publisher/recipe/${meal.recipe_uuid}`}
                >
                    <button className="edit-meal-btn">
                        <i className="feather feather-box" />
                        <em>admin</em>
                    </button>
                </a>
            );
        }

        if (content.type == 'recipe') {
            buttons.push(<AddToCollectionButton key="collection" recipe={content} />);
        }

        if (canEditSingleMeal) {
            buttons.push(
                <button key="edit" className="edit-meal-btn" onClick={(ev) => this.editSingleMeal(meal, ev)}>
                    <i className="feather feather-edit" />
                    <em>Edit</em>
                </button>
            );
        }

        if (meals.length > 1) {
            buttons.push(
                <button key="delete" className="delete-dish-btn" onClick={(ev) => this.deleteSingleMeal(meal, ev)}>
                    <i className="feather feather-trash" />
                    <em>Remove</em>
                </button>
            );
        }

        return <div className="meal-control-btns">{buttons}</div>;
    };

    renderRecipe = (meal, recipe) => {
        const { meals, profile, dishIndex } = this.state;
        const { recipes, details, merchants, editMealBatches } = this.context;

        const merchant = recipe.merchant && merchants[recipe.merchant.uuid];
        let participants = getParticipantsForMeal(meal, profile);
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        let parent = null;
        let hasChild = false;

        if (meal.meal_type == 'leftover') {
            parent = this.context?.plan?.items.find((item) => item.id === meal.parent);
        }

        if (meal.meal_type !== 'leftover') {
            hasChild = !!(this.context?.plan?.items.find(item => item.parent === meal.id));
        }

        // How much waste servings will this option produce?
        const totalServings = recipe.servings * meal.scaling;
        const totalDays = Math.floor(totalServings / neededPerMeal) || 1;

        let surplus = !hasChild && !parent;

        const eatThisMuchTabs = meals
            .map((meal, i) => {
                const dish = getPrimaryMeal([meal], recipes);

                if (!(dish && dish.recipe)) {
                    return null;
                }

                let factor = 1;
                let scaling = meal.scaling;

                if (parent) {
                    scaling = parent.scaling;
                    const daysPassed = meal.offset - parent.offset;
                    if (daysPassed >= 1) {
                        const remainingServingsDenominator = scaling * (meal.servings || recipe.servings || 1);
                        const remainingServingsNumerator = remainingServingsDenominator - neededPerMeal * daysPassed;
                        factor = remainingServingsNumerator
                            ? remainingServingsNumerator / remainingServingsDenominator
                            : 1;
                    }

                    if (totalDays - daysPassed == 1) {
                        surplus = true;
                    }
                }

                return (
                    <section key={i} data-title={'Dish ' + (i + 1)}>
                        <HowMuchToEat
                            uuid={meal.uuid}
                            meal={meal}
                            profile={profile}
                            recipe={dish.recipe}
                            participants={participants}
                            factor={factor}
                            scaling={scaling}
                            surplus={surplus}
                            onClick={() => editMealBatches && editMealBatches(meal)}
                            isLeftover={!!parent}
                            onChangePortionAmount={this.onChangeAmount}
                        />
                    </section>
                );
            })
            .filter((v) => v);

        return (
            <>
                <div className={classNames('details-section', sections[this.section++])}>
                    <header>
                        <div className="section-title">
                            {meal.meal_type === 'leftover' && parent ? (
                                <div className="leftover-badge">Leftover from Day {parseInt(parent.offset) + 1}</div>
                            ) : null}
                            <div className="t2 with-merchant">
                                {merchant ? (
                                    <Dropdown
                                        className="merchant-dropdown"
                                        button={
                                            <>
                                                {meals.length > 1 ? <span>DISH {dishIndex + 1}:&nbsp;</span> : null}
                                                <span>Recipe by</span> <em>{merchant.name}</em>{' '}
                                                <i className="feather feather-info" />
                                            </>
                                        }
                                    >
                                        <Merchant merchant={merchant} />
                                    </Dropdown>
                                ) : meals.length > 1 ? (
                                    <span>DISH {dishIndex + 1}</span>
                                ) : null}
                            </div>
                        </div>

                        {this.renderMealControlButtons(meal, recipe, true)}
                    </header>

                    <div className="section-body">
                        <MealTitle
                            item={recipe}
                            meal={meal}
                            nutrientsIndex={dishIndex + 1}
                            readOnly
                        />

                        <RecipeIngredients
                            recipe={recipe}
                            details={details[recipe.details]}
                            subdetails={details}
                            scaling={meal.scaling}
                            profile={profile}
                        />
                    </div>
                </div>

                <div className={classNames('details-section', sections[this.section++])}>
                    <header>
                        <div className="section-title">
                            <p className="t2">
                                {meals.length > 1 ? <>Dish {dishIndex + 1}&nbsp;</> : null}Instructions
                            </p>
                        </div>
                    </header>

                    <div className="section-body">
                        <RecipeInstructions
                            recipe={recipe}
                            details={details[recipe.details]}
                            subdetails={details}
                            user={profile}
                        />
                    </div>
                </div>

                <div className={classNames('details-section', sections[this.section++])}>
                    <header>
                        <div className="section-title">
                            <p className="t2">How Much to Eat</p>
                        </div>
                    </header>

                    <div className="section-body">
                        {eatThisMuchTabs && eatThisMuchTabs.length > 1 ? (
                            <MyTabs defaultTab="Dish 1" className="pill-tabs">
                                {eatThisMuchTabs}
                            </MyTabs>
                        ) : (
                            eatThisMuchTabs[0]
                        )}
                    </div>
                </div>
            </>
        );
    };

    renderFood = (meal, food) => {
        const { isPro, locations, brands } = this.context;
        const { meals, profile, dishIndex } = this.state;

        const location = (locations && locations[meal.location_uuid]) || null;
        const brand = (food.brand_uuid && brands && brands[food.brand_uuid]) || null;
        const currentLocation = UserStore.getLocation();

        const canEditFood = profile.uuid === food.owner && food.status !== 'live';

        const dishTitles = {
            'Restaurant Dish': (
                <>
                    <span>Order from</span> <em>{food.brand_name}</em>
                </>
            ),
            'Ready Made Meal': food.brand_name ? (
                <>
                    <span>Ready Made Meal by</span> <em>{food.brand_name}</em>
                </>
            ) : (
                'Ready Made Meal'
            ),
            'Brand Name Food': <span>Store Bought Item</span>,
            Food: <></>,
            UGC: <span>Custom Food</span>,
            'Meal Kit': food.brand_name ? (
                <>
                    <span>Meal kit by</span> <em>{food.brand_name}</em>
                </>
            ) : (
                'Meal kit'
            ),
        };

        let dishTitle = dishTitles[food.product_type] || food.product_type;

        let foodPrice = null;

        if (food.mapped_from && food.servings) {
            const mf = food.mapped_from.find((mf) => mf.price);

            if (mf) {
                foodPrice = mf.price / food.servings;
            }
        }

        return (
            <>
                <section className={classNames('details-section', sections[this.section++])}>
                    {isPro && isSunbasketFood(food) ? (
                        <Alert type="question" iconType="feather feather-alert-circle">
                            <p className="t3">
                                <strong>This is a sample Sunbasket meal.</strong>
                                <br />
                                <br />
                            </p>
                            <p className="t3">
                                This exact meal may or may not be available in your{' '}
                                {profile.practice_type === 'dietetics' ? "patient's" : "client's"} area. Once you enable
                                Sunbasket meals for your {profile.practice_type === 'dietetics' ? 'patient' : 'client'}{' '}
                                via their {profile.practice_type === 'dietetics' ? 'Patient' : 'Client'} Preferences
                                Settings, these meals will be automatically recommended to your{' '}
                                {profile.practice_type === 'dietetics' ? 'patient' : 'client'} based on timing,
                                availability, and, most importantly, their{' '}
                                {profile.practice_type === 'dietetics' ? 'nutrition prescription' : 'nutrition profile'}
                                .
                            </p>
                        </Alert>
                    ) : null}

                    <header>
                        <div className="section-title">
                            <p className="t2">
                                {meals.length > 1 ? <span>DISH {dishIndex + 1}: </span> : null}
                                {dishTitle}
                            </p>
                        </div>

                        {this.renderMealControlButtons(meal, food, canEditFood)}
                    </header>

                    <div className="section-body">
                        <h5 className="meal-title">
                            {food.pretty_name || food.name}
                            {foodPrice ? (
                                <>
                                    <br />${foodPrice} / serving
                                </>
                            ) : null}
                        </h5>

                        {food.description ? <p className="t3 description">{food.description}</p> : null}

                        {food.ingredients ? (
                            <>
                                <p className="p3">This meal includes the following ingredients:</p>
                                <FoodIngredients food={food} profile={profile} />
                            </>
                        ) : null}

                        {food.modifications ? (
                            <p className="t3 modifications">
                                <em>Recommended modifications:</em> {food.modifications}
                            </p>
                        ) : null}

                        {food.nutrition_disclaimer ? (
                            <Alert type="warning" title="Nutrition Disclaimer">
                                {food.nutrition_disclaimer}
                            </Alert>
                        ) : null}

                        {food.gtin_upc?.length ? (
                            <p className="t3">
                                <em>Barcode:</em> {food.gtin_upc.join(', ')}
                            </p>
                        ) : null}
                    </div>
                </section>

                {location ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">Restaurant Information</p>
                            </div>
                        </header>

                        <div className="section-body">
                            <LocationInfo brand={brand} location={location} currentLocation={currentLocation} />
                        </div>
                    </div>
                ) : null}

                {food.preparation ? (
                    <div className={classNames('details-section', sections[this.section++])}>
                        <header>
                            <div className="section-title">
                                <p className="t2">Dish {meals.length === 1 ? null : dishIndex + 1} Instructions</p>
                            </div>
                        </header>

                        <div className="section-body">
                            <RecipeInstructions recipe={food} details={food} user={profile} />
                        </div>
                    </div>
                ) : null}
            </>
        );
    };

    getMealAndLeftovers = (meal) => {
        const { plan } = this.context;
        let parent = null;
        let allMeals = [];

        if (meal.meal_type == 'leftover') {
            parent = plan.items.find((item) => item.id === meal.parent);
        } else {
            parent = meal;
        }

        allMeals = plan.items.filter((item) => item.parent === parent.id && !item.deleted);
        allMeals.unshift(parent);

        return allMeals;
    };

    getScaling = (meal) => {
        const { recipes } = this.context;
        const { profile } = this.state;

        let allMeals = this.getMealAndLeftovers(meal);

        if (meal.recipe_uuid) {
            const recipe = recipes[meal.recipe_uuid];
            const participants = getParticipantsForMeal(meal, profile);
            const totalDays = allMeals.length;
            const loggedServings = getLoggedServingsOfRecipe(meal, recipe);
            const primaryUserMealServingsNeeded = allMeals.reduce(
                (sum, meal) => sum + (loggedServings || profile.portion),
                0
            );
            const otherUsersMealServingsNeededPerDay = participants.reduce(
                (sum, participant) => sum + (participant.uuid == profile.uuid ? 0 : participant.portion),
                0
            );
            const mealServingsNeeded = otherUsersMealServingsNeededPerDay * totalDays + primaryUserMealServingsNeeded;

            return Math.ceil(mealServingsNeeded / recipe.servings);
        }

        return meal.scaling || 1;
    };

    onChangeAmount = (meal, portion, unit, amount, grams, milliliters) => {
        const { onModifyMeals } = this.context;

        const editPortionsMealId = meal.id;

        let allMeals = this.getMealAndLeftovers(meal);

        allMeals.forEach((meal) => {
            if (meal.id === editPortionsMealId || meal.parent === editPortionsMealId) {
                meal.logged_portion = portion;
                meal.logged_amount = amount;
                meal.logged_unit = unit;
                meal.logged_grams = grams;
                meal.logged_milliliters = milliliters;
                meal.scaling = this.getScaling(meal);
            }
        });

        onModifyMeals(allMeals);
    };

    renderNutritionTabs = () => {
        const { dishIndex, profile, meals } = this.state;
        const { recipes, foods } = this.context;

        const { hide_nutrition = false, rd_override = false } = profile || {};

        if (!(meals && meals.length)) {
            return;
        }

        if (hide_nutrition && rd_override !== true) {
            return;
        }

        const allContent = { ...recipes, ...foods };
        const combinedNutrients = getNutrientsForMeals(meals, allContent, profile.portion, null);

        const nutrientTabs = [];

        nutrientTabs.push(
            <section key="combined" data-title="Combined">
                <NutritionInfo profile={{ ...profile, portion: 1 }} nutrients={combinedNutrients} />
            </section>
        );

        meals.forEach((meal, i) => {
            const dish = getPrimaryMeal([meal], recipes, foods);

            if (!dish) {
                return null;
            }

            let dishNutrients = getNutrientsForMeals([meal], allContent, profile.portion, null);

            const onChangePortionAmount = (
                logged_portion,
                logged_unit,
                logged_amount,
                logged_grams,
                logged_milliliters,
                unit
            ) => {
                this.onChangeAmount(meal, logged_portion, logged_unit, logged_amount, logged_grams, logged_milliliters);
            };

            nutrientTabs.push(
                <section key={i} data-title={'Dish ' + (i + 1)}>
                    {dish.food || (dish.recipe && meal.logged_amount && meal.id) ? (
                        <div className="nutrition-portion">
                            <p className="t2">
                                Showing nutrition for {profile.name || `${profile.first_name} ${profile.last_name}`}:{' '}
                            </p>
                            <FoodUnitsSelector
                                food={dish.food || dish.recipe}
                                meal={meal}
                                onChangeAmount={onChangePortionAmount}
                                replaceChevronText="edit"
                                howMuchQuestion="Show nutrition for:"
                            />
                        </div>
                    ) : null}
                    <NutritionInfo profile={{ ...profile, portion: 1 }} nutrients={dishNutrients} />
                </section>
            );
        });

        const { content } = getPrimaryMeal([meals[dishIndex]], recipes, foods);

        return (
            <div className={classNames('details-section', sections[this.section++])}>
                <header>
                    <div className="section-title">
                        <p className="t2">Nutrition Information</p>
                    </div>

                    {content && this.section == 1
                        ? this.renderMealControlButtons(meals[dishIndex], content, true)
                        : null}
                </header>

                <div className="section-body">
                    {nutrientTabs && nutrientTabs.length > 2 ? (
                        <MyTabs defaultTab="Combined" className="pill-tabs" htmlRef="nutrientTabs">
                            {nutrientTabs}
                        </MyTabs>
                    ) : (
                        nutrientTabs[1]
                    )}
                </div>
            </div>
        );
    };

    renderMealDetails = () => {
        const { dishIndex, meals } = this.state;
        const { recipes, foods } = this.context;

        if (!(meals && meals[dishIndex])) {
            return (
                <div className="nutrition-summary">
                    <div className="summary-container">Loading...</div>
                </div>
            );
        }

        const meal = meals[dishIndex];
        const dish = getPrimaryMeal([meal], recipes, foods);
        const { primary, content } = dish;

        if (!(primary && content)) {
            return (
                <div className="nutrition-summary">
                    <div className="summary-container">Loading...</div>
                </div>
            );
        }

        if (content.type === 'recipe') {
            return this.renderRecipe(meal, content);
        }
        if (content.type === 'food') {
            return this.renderFood(meal, content);
        }
    };

    renderEditMealServingsButton = () => {
        const { editMealBatches, plan } = this.context;
        const { primary, meals, participants } = this.state;
        const { recipes } = this.context;

        if (!primary?.content) {
            return;
        }

        let totalTime = 0;

        meals.forEach((meal) => {
            totalTime =
                recipes[meal.recipe_uuid] &&
                recipes[meal.recipe_uuid].total_time &&
                recipes[meal.recipe_uuid].total_time > totalTime
                    ? recipes[meal.recipe_uuid].total_time
                    : totalTime;
        });

        let editMealServings = <div className="edit-meal-servings">&nbsp;</div>;

        if (primary.content.servings) {
            let dishes = [];

            if (primary.dishes) {
                dishes.push(primary.dishes > 1 ? primary.dishes + ' dishes' : '1 dish');
            }

            if (primary.beverages) {
                dishes.push(primary.beverages > 1 ? primary.beverages + ' beverages' : '1 beverage');
            }
            const totalParticipants = participants?.length || 1;
            const totalLeftovers = plan.items.filter(
                (item) => item.meal_type == 'leftover' && item.parent === primary.primary.id
            ).length;

            editMealServings = (
                <div className="edit-meal-servings" onClick={() => editMealBatches && editMealBatches(primary.primary)}>
                    <section className="serves">
                        <span>Serves</span>{' '}
                        <span>
                            {totalParticipants} {totalParticipants === 1 ? 'person' : 'people'}
                        </span>
                    </section>

                    {totalLeftovers > 0 ? (
                        <section className="serves leftovers">
                            <span>Leftovers</span>
                            <span>
                                {totalLeftovers} {totalLeftovers === 1 ? 'day' : 'days'}
                            </span>
                        </section>
                    ) : null}

                    <section className="timings">
                        <p className="dish-count">{dishes.join(', ')}</p>
                        {totalTime ? (
                            <p>
                                {toHuman(totalTime, true)}
                                <br />
                                prep/cook
                            </p>
                        ) : null}
                    </section>
                </div>
            );
        }

        return editMealServings;
    };

    renderControlBar = () => {
        const { startAddMeal, startReplaceMeal, plan } = this.context;
        const { profile, primary, dishIndex, meals, isMismatched, isOverLimits, hasBeenRecommended } = this.state;
        const { isPro, isMobile, recipes } = this.context;

        if (!primary) {
            return;
        }

        const { inhibit_swap = false, rd_override = false } = profile;

        // If we've met our nutrition needs OR we've gone over, default to the search foods & recipes engine
        // instead of smart choices engine.
        let addOpts = !isMismatched || isOverLimits ? { defaultMode: 'browser' } : {};

        const recipesInThisMeal = meals.map((m) => recipes[m.recipe_uuid]).filter((v) => v);

        const swapMenu =
            meals.length == 1 ? (
                <SwapMenu className="el-toolbar-btn" icon="icon-swap" primary={primary.primary} />
            ) : (
                <Popup
                    positionClassName="el-popup-top-right"
                    className="el-toolbar-popup swap-popup"
                    button={
                        <>
                            <i className="icon-swap" /> SWAP
                        </>
                    }
                >
                    <h6>Swap</h6>
                    <SwapMenu className="swap-popup-btn" primary={primary.primary} button={<>Entire Meal</>} />

                    {meals.map((meal, i) => (
                        <SwapMenu
                            key={i}
                            className="swap-popup-btn"
                            primary={meal}
                            options={{ replace_single: true }}
                            button={
                                <>
                                    DISH {i + 1}: <em>{primary.titles[i]}</em>
                                </>
                            }
                        />
                    ))}
                </Popup>
            );

        return (
            <div className={classNames('control-bar-container el-toolbar1', !isMobile ? 'el-toolbar1-dark' : null)}>
                {primary.recipe ? (
                    <RecipeToPdfButton
                        className="el-toolbar-popup"
                        positionClassName="el-popup-top-left"
                        button={
                            <>
                                <i className="feather feather-printer" /> <em>print</em>
                            </>
                        }
                        recipe={primary.recipe}
                        meal={primary.primary}
                        plan={hasBeenRecommended ? plan : null}
                    />
                ) : null}

                <MultiSharePopup className="el-toolbar-popup" meals={meals} />

                {!inhibit_swap && startAddMeal ? (
                    <button
                        className="el-toolbar-btn swap-menu-btn"
                        onClick={() => startAddMeal(primary.primary.offset, primary.primary.meal)}
                    >
                        <i className="feather feather-plus" /> <em>add</em>
                    </button>
                ) : null}

                {(!inhibit_swap || rd_override) && startReplaceMeal ? swapMenu : null}

                {isPro && recipesInThisMeal?.length > 0 ? (
                    <RecommendPopup
                        className="el-toolbar-popup"
                        positionClassName="el-popup-top-right"
                        onClick={
                            recipesInThisMeal.length == 1
                                ? () => this.onStartRecommend(recipesInThisMeal[0].uuid)
                                : null
                        }
                        onClickItem={this.onStartRecommend}
                        recipes={recipesInThisMeal}
                    />
                ) : null}
            </div>
        );
    };

    renderRecommendModal = () => {
        const { location, recipes } = this.context;
        const { profile } = this.state;

        if (!location.query.recommend || location.query.swapRecipe) {
            return;
        }

        return (
            <RecommendModal
                patient={profile}
                recipe={recipes[location.query.recommend]}
                closeModal={this.closeRecommendModal}
            />
        );
    };

    renderScrollHero = () => {
        const { breakpoint47em, isCordova, isMobile } = this.context;
        const { meals, dishIndex } = this.state;

        const { recipes, brands, foods, startReplaceMeal, onRemoveMeals } = this.context;
        const { profile } = this.state;
        const { hideEditBtns } = this.props;

        let isSafariMobile = false;

        if (isMobile && !isCordova && platform.name === 'Safari') {
            isSafariMobile = true;
        }

        return (
            <SpringHero
                meals={meals}
                dishIndex={dishIndex}
                profile={profile}
                hideEditBtns={hideEditBtns}
                recipes={recipes}
                brands={brands}
                foods={foods}
                useSimpleSwap={true}
                breakpoint47em={breakpoint47em}
                startReplaceMeal={startReplaceMeal}
                editSingleMeal={this.editSingleMeal}
                deleteSingleMeal={this.deleteSingleMeal}
                scrollToDishIndex={this.scrollToDishIndex}
                onRemoveMeals={onRemoveMeals}
                isSafariMobile={isSafariMobile}
            />
        );
    };

    renderEditMealModal = () => {
        const { profile, editMealModal } = this.state;

        if (!editMealModal) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeEditMealModal}
                closeModal={this.closeEditMealModal}
                className="feed-modal edit-meal-modal"
                overlayClassName="feed-modal-overlay"
                contentLabel="Edit Recipe Details"
            >
                <div className="edit-meal-modal-container">
                    <header onClick={(ev) => ev.stopPropagation()}>
                        <div className="top-half-circle no-curve">&nbsp;</div>
                        <div className="header-container">
                            <button className="close-modal-btn" onClick={this.closeEditMealModal}>
                                <i className="icon-arrow-left" />
                            </button>
                            <h1 className="food-edit-header">{editMealModal.recipe_uuid ? 'Edit Recipe' : null}</h1>
                        </div>
                    </header>
                    <div className="modal-scroll-container">
                        <div className="inner-slider">
                            {editMealModal.recipe_uuid ? (
                                <RecipeEditor
                                    uuid={editMealModal.recipe_uuid}
                                    profile={profile}
                                    closeModal={this.closeEditMealModal}
                                    onSaveRecipe={(recipe, details) =>
                                        this.onSaveRecipe(editMealModal, recipe, details)
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    section = 0;

    render() {
        const { isMobile } = this.context;
        const { offset, mealType } = this.state;
        const { closeModal } = this.props;

        this.section = 0;

        return (
            <span>
                <Modal
                    isOpen={true}
                    onRequestClose={closeModal}
                    closeModal={closeModal}
                    className="el-modal el-modal1"
                    overlayClassName="el-modal-overlay"
                    contentLabel="Meal Details"
                >
                    <div className="el-modal-container el-modal1-container content-details-modal-container meal-details">
                        <header>
                            <button className="el-modal-back-btn" onClick={this.closeModal}>
                                <span>Back</span>
                            </button>

                            <h2>Meal Information</h2>

                            {isMobile ? (
                                <>
                                    {this.renderEditMealServingsButton()}

                                    <div className="el-modal-controls el-modal1-controls">
                                        <header>{this.renderControlBar()}</header>
                                    </div>
                                </>
                            ) : null}
                        </header>

                        <div className="el-modal-body-container el-modal1-body-container el-fonts">
                            {!isMobile ? (
                                <div className="details-header">
                                    <h6>
                                        Day {parseInt(offset) + 1}, <em>{mealType}</em>
                                    </h6>
                                    {this.renderEditMealServingsButton()}
                                    <header>{this.renderControlBar()}</header>
                                </div>
                            ) : null}

                            {this.renderScrollHero()}

                            <div className="meal-details">
                                {this.renderMealDetails()}
                                {this.renderNutritionTabs()}
                            </div>
                        </div>
                    </div>

                    {this.renderEditMealModal()}
                    {this.renderRecommendModal()}
                </Modal>
            </span>
        );
    }
}
