'use strict';

export function toSeconds(duration) {
    if (!duration) {
        return 0;
    }

    duration = duration.trim();

    // Convert stuff
    const conversions = {
        ' seconds': 's',
        ' secs': 's',
        ' minutes': 'm',
        ' min': 'm',
        ' hr': 'h',
        ' hrs': 'h',
    };

    Object.keys(conversions).forEach(replace => {
        duration = duration.replace(replace, conversions[replace]);
    });

    let time = 0; // Count in seconds of the time specified by duration

    duration = duration.split(' ');

    for (var i in duration) {
        var c = duration[i];
        var match;

        if (!(match = c.match(/([0-9]+)([smhdMY]{1})/))) {
            continue; // ignore unknown components
        }

        switch(match[2]) {
            case 's':
                time += parseInt(match[1]);
                break;

            case 'm':
                time += parseInt(match[1]) * 60;
                break;

            case 'h':
                time += parseInt(match[1]) * 3600;
                break;

            case 'd':
                time += parseInt(match[1]) * 86400;
                break;

            default:
                break;
        }
    }

    return time;
};

export function toHuman(seconds, verbose, showZero) {
    if (seconds === 0 && showZero) {
        return verbose ? '0 seconds' : '0s';
    }

    if (!seconds) {
        return '';
    };

    var normal = [];

    var days, hours, minutes;

    // How many days do we have in time?
    if ((days = Math.floor(seconds / 86400))) {
        seconds -= days * 86400;
        normal.push(days.toString() + (verbose ? (days == 1 ? ' day' : ' days') : 'd'));
    }

    if ((hours = Math.floor(seconds / 3600))) {
        seconds -= hours * 3600;
        normal.push(hours.toString() + (verbose ? (hours == 1 ? ' hour' : ' hours') : 'h'));
    }

    if ((minutes = Math.floor(seconds / 60))) {
        seconds -= minutes * 60;
        normal.push(minutes.toString() + (verbose ? (minutes == 1 ? ' minute' : ' mins') : 'm'));
    }

    if (seconds) {
        normal.push(Math.round(seconds).toString() + (verbose ? (seconds == 1 ? ' second' : ' sec') : 's'));
    }

    return normal.join(' ');
}

export function getDurationFromSeconds(seconds) {
    var days, hours, minutes;

    if ((days = Math.floor(seconds / 86400))) {
        seconds -= days * 86400;
    }

    if ((hours = Math.floor(seconds / 3600))) {
        seconds -= hours * 3600;
    }

    if ((minutes = Math.floor(seconds / 60))) {
        seconds -= minutes * 60;
    }

    return { days, hours, minutes, seconds };
}
