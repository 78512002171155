'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';

import UserActions from '../../../actions/UserActions';
import UserStore from '../../../stores/UserStore';
import AuthStore from '../../../stores/AuthStore';

import { getConfig } from '../../../utils/Env';

import './SetPassword.scss';
import PasswordField from '../PasswordField.react';
import PasswordValidationList from '../PasswordValidationList.react';

export default class SetPassword extends Component {
    static contextTypes = {
        closeMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const user = UserStore.getUser();

        this.state = {
            user,
            password: '',
            working: false,
            errors: [],
            alert: false,
            customizations: {},
            showConfirm: false,
            showFieldDataError: false
        };

        user && this.loadCustomizations();
    }

    componentDidMount() {
        const user = UserStore.getUser();
        user && this.loadCustomizations();
    }

    loadCustomizations = () => {
        const { user } = this.state;

        const keys = user.dietitian ? [
            'password::dietitian::expires-after',
            'password::dietitian::min-lowercase', 'password::dietitian::min-length', 'password::dietitian::min-symbols',
            'password::dietitian::symbols', 'password::dietitian::min-capitals', 'password::dietitian::min-numbers',
        ] : [
            'password::patient::expires-after',
            'password::patient::min-lowercase', 'password::patient::min-length', 'password::patient::min-symbols',
            'password::patient::symbols', 'password::patient::min-capitals', 'password::patient::min-numbers'
        ];

        AuthStore.fetch(getConfig('users_api') + '/customizations', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=custom/keys/1'},
            body: JSON.stringify({keys}),
        }).then(
            response => {
                const customizations = {};

                response.elements.forEach(custom => {
                    if (custom.value) {
                        customizations[custom.key.split('::').at(-1).replace('-', '_')] = custom.value;
                    }
                });

                this.setState({customizations});
            },
            error => false, // if it failed nothing we can do. Let it go.
        )
    }

    getExpiredMessage = () => {
        const { customizations } = this.state;
        return 'To protect your account, your password must be changed' + ((customizations.expires_after) ? ' every ' + customizations.expires_after + ' days.!' : '');
    };

    onChangePassword = (password) => {
        this.setState({password});
    }

    onPasswordErrors = (errors) => {
        this.setState({errors});
    }


    onSave = (e) => {
        e.preventDefault();
        const { password, errors } = this.state;

        if (errors.some(p => !p.isValid)) {
            if(errors.filter(p => !p.showErrorRealTime).some(p => !p.isValid)) {
                const errorMessage = errors.filter(p => !p.isValid)[0].message;
                this.setState({alert: errorMessage.includes('Password') ? errorMessage : "Passwords must be " + errorMessage.toLowerCase()});
            }
            this.setState({showFieldDataError: true});
            return;
        }

        this.setState({working: true});

        UserActions.changePassword(password).then(
            response => {this.onClose()},
            error => {this.setState({working: false, alert: error.message})}
        );
    }

    onClose = () => {
        const { closeMenu } = this.context;
        const { onClose } = this.props;

        this.setState({working: false});

        onClose && onClose();
        closeMenu && closeMenu();
    }

    render() {
        const { title, subTitle, showTerms, expired } = this.props;
        const { showFieldDataError, working, customizations, errors, alert } = this.state;

        const user = UserStore.getUser();

        return (
            <>
                <div className="set-password-form">
                    {working ?
                        <div className="working">
                            <h3>Saving your password...</h3>

                            <i className="icon-spinner" />
                        </div>
                    : null}

                    <div className="password-header">
                        <h3>{title != null ? title : 'Choose a new password'}</h3>
                        {subTitle && <p>{subTitle}</p>}
                        {expired && <p>{this.getExpiredMessage()}</p>}
                    </div>

                    <form>
                        <input type="hidden" value={user && user.email} autoComplete="username" />

                        <div className="el-modal-body-container el-modal3-body-container el-form el-fonts">
                            <PasswordField
                                setFieldValue={this.onChangePassword}
                                onErrors={this.onPasswordErrors}
                                showValidations={false}
                                showFieldDataError={showFieldDataError}
                                minLength={customizations.min_length}
                                minCapitals={customizations.min_capitals}
                                minLowercase={customizations.min_lowercase}
                                minNumbers={customizations.min_numbers}
                                minSymbols={customizations.min_symbols}
                                symbols={customizations.symbols}
                            />
                            {alert && <p className="error-msg">{alert}</p>}
                        </div>

                        {errors.some(p => p.showErrorRealTime) && <PasswordValidationList validations={errors} />}
                    </form>
                </div>

                <footer>
                    <button
                        className="el-modal-ok-btn el-medium-btn el-raspberry-btn"
                        onClick={this.onSave}
                        disabled={errors.some(p => p.showErrorRealTime) && errors.filter(p => p.showErrorRealTime).some(p => !p.isValid)}>change password
                    </button>
                </footer>

                {showTerms && <p>By using this site you agree to our <Link to="/terms">Terms & Conditions</Link> and <Link to="/privacy">Privacy Policy</Link></p>}
            </>
        );
    }
}
