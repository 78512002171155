'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import modalStyles from '../../../jsx-styles/modals';
import PrivacyPolicy from '../../../pages/PrivacyPolicy.react';

import './PrivacyModal.scss';
import '../../FeedModals.scss';

export default class PrivacyModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
    };

    render() {
        const { closeModal } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Privacy Policy"
                className="feed-modal privacy-policy-modal"
                overlayClassName="feed-modal-overlay">

                <div className="feed-modal-container privacy-policy-container">
                    <header>
                        <div className="top-half-circle">&nbsp;</div>
                        <div className="header-container">
                            <button className="close-modal-btn" onClick={closeModal}>
                                <i className="icon-chevron-left" />
                                EatLove Privacy Policy
                            </button>
                        </div>
                    </header>

                    <div className="modal-scroll-container">
                        <PrivacyPolicy />
                    </div>
                </div>

            </Modal>
        );
    }
}
