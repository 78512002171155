'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Select from '../../Widgets/Select.react';
import Popup from '../../../../components/Widgets/Popup.react';
import { isPatientChild } from '../../../utils/Patients';

import './MealPreferences.scss';
import './PatientForm.scss';

export default class MealPreferences extends Component {
    static contextTypes = {
        user: PropTypes.object,
    };

    static propTypes = {
    };

    constructor(props) {
        super(props);

        const { patient  = {}} = props;
        const { preferences = {} } = patient;

        // Use whichever the first of them is that has a non-zero value
        const shopping_freq = patient.shopping_freq || 3;

        this.state = {
            leftovers_enabled: !!preferences.leftovers_enabled,
            max_leftover_days: preferences.max_leftover_days,
            limit_tags: preferences.limit_tags || [],
            shopping_freq,
            skill_level: preferences.skill_level,
            meal_kit_providers: preferences.meal_kit_providers || [],
            dirty: false,
        };
    }

    isDirty = () => {
        return this.state.dirty;
    }

    validate = () => {
    }

    mutate = (patient) => {
        const {
            shopping_freq, skill_level, leftovers_enabled,
            max_leftover_days, limit_tags, meal_kit_providers,
        } = this.state;

        patient.shopping_freq = shopping_freq;

        patient.preferences.limit_tags = limit_tags;
        patient.preferences.leftovers_enabled = !!leftovers_enabled;
        patient.preferences.max_leftover_days = max_leftover_days;
        patient.preferences.skill_level = skill_level;
        patient.preferences.meal_kit_providers = meal_kit_providers || [];
        patient.completed = patient.completed || [];

        if (!patient.completed.includes('meals')) {
            patient.completed.push('meals');
        }

        // @todo - need to store family record here as well.

        return patient;
    }

    onChangeLeftovers = (value) => {
        let { leftovers_enabled, max_leftover_days } = this.props;

        if (value === 'disabled') {
            leftovers_enabled = false;
        } else {
            leftovers_enabled = true;
            max_leftover_days = value;
        }

        this.setState({leftovers_enabled, max_leftover_days});
    }

    onToggleSunbasket = () => {
        let { meal_kit_providers } = this.state;

        if ((meal_kit_providers || []).includes('sunbasket')) {
            meal_kit_providers = [];
        } else {
            meal_kit_providers = ['sunbasket'];
        }

        this.setState({meal_kit_providers, dirty: true});
    }

    toggleLimitTag = (tag) => {
        const { limit_tags } = this.state;

        if (limit_tags.includes(tag)) {
            limit_tags.splice(limit_tags.indexOf(tag), 1);
        } else {
            limit_tags.push(tag);
        }

        this.setState({limit_tags, dirty: true});
    }

    render() {
        const { user } = this.context;
        const { patient } = this.props;
        const {
            skill_level, shopping_freq, limit_tags,
            leftovers_enabled, max_leftover_days,
            meal_kit_providers
        } = this.state;

        let tabIndex = 1;

        const skillLevelOpts = [
            {value: '', label: 'Any'},
            {value: 'Beginner', label: 'Beginner'},
            {value: 'Intermediate', label: 'Intermediate'},
            {value: 'Advanced', label: 'Advanced'},
        ];

        const planSizeOpts = [
            {value: 1, label: '1 day'},
            {value: 3, label: '3 days'},
            {value: 5, label: '5 days'},
            {value: 7, label: '7 days'},
        ];

        const leftoverOpts = [
            {label: 'No leftovers', value: 0},
            {label: '1 day max leftovers', value: 1},
            {label: '2 day max leftovers (recommended)', value: 2},
            {label: '3 day max leftovers', value: 3},

            {label: 'Disable Leftovers Engine (high food waste, recommended only for highly restricted diets)', value: 'disabled'},
        ];

        let leftoverOption = max_leftover_days;

        if (!leftovers_enabled) {
            leftoverOption = 'disabled';
        }

        return (
            <div className="meal-preferences patient-form">
                <div className="with-label skill-level">
                    <label>{isPatientChild(patient) ? 'Cooking Skill of Parent or Guardian' : 'Cooking Skill Level'}</label>
                    <Select value={skill_level}
                        placeholder="Skill Level"
                        options={skillLevelOpts}
                        tabIndex={tabIndex++}
                        onChange={skill_level => this.setState({skill_level, dirty: true})}>
                        <p>Cooking Skill Level</p>
                    </Select>
                </div>

                {user && user.practice_type === 'dietetics' ?
                    <div className="with-label plan-size">
                        <label>Meal Plan Size</label>
                        <Select value={shopping_freq}
                            placeholder="Plan Size"
                            options={planSizeOpts}

                            tabIndex={tabIndex++}
                            onChange={skill_level => this.setState({ skill_level, dirty: true })}>
                            <p>Cooking Skill Level</p>
                        </Select>
                    </div>
                : null}

                <div className="with-label leftovers-enabled">
                    <label>Leftovers:</label>
                    <Select value={leftoverOption}
                        options={leftoverOpts}
                        showAbove={false}
                        onChange={this.onChangeLeftovers}>
                        <p>Use Leftovers</p>
                    </Select>
                </div>

                <div className="basic-limit-tag">
                    <label>Simple Recipes Only</label>
                    <button className="el-switch1" data-active={limit_tags.includes('Basic')} onClick={() => this.toggleLimitTag('Basic')}
                        title="A focus on short and simplified recipes with less than 9 ingredients" />
                </div>

                <div className="section-sunbasket el-fonts">
                    <p className="t5"><em>INCLUDE MEAL KITS AND READY MADE MEALS</em></p>

                    <div className='toggle-option-sunbasket'>
                        <label>Sunbasket (US Only)</label>

                        <Popup className="el-popup-dark el-popup-sunbasket-tooltip"
                            positionClassName="el-popup-top-left-center"
                            button={<i className="icon-info" />}
                            buttonTitle="">
                            <p className="t6">Sunbasket Meal Delivery service offers meal kits with easy-to-follow directions and ready-made meals featuring the best seasonal ingredients. Average price per serving: $14.75.</p>

                            <br />

                            <p className="t6">If enabled, Sunbasket meals will be recommended by LENA through {patient.first_name}&apos;s in app meal feed.  Please note, Sunbasket meals will not be available for use in the collections and meal plans you share with {patient.first_name} due to availability limitations.</p>
                        </Popup>

                        <button className="el-switch1" data-active={meal_kit_providers.includes('sunbasket')} onClick={this.onToggleSunbasket} />
                    </div>
                </div>
            </div>
        );
    }
}
