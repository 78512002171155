'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import './EditInstructions.scss';

export default class EditInstructions extends Component {

    static propTypes = {
        group: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const instructions = this.getInstructionsTextFromGroup(props.group);

        this.state = {
            instructions,
        };

    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {

        const instructions = this.getInstructionsTextFromGroup(nextProps.group);

        this.setState({instructions});
    }


    getInstructionsTextFromGroup = (group) => {
        return (group.items || []).map(item => item.text).join("\n") || "";
    }

    updateGroupFromInstructions = () => {
        const { group, onChangeInstructions } = this.props;
        let { instructions } = this.state;

        const items = [];
        group.items = group.items || [];

        // Split up by new line, trim whitespace from each line and remove empty lines.
        instructions = instructions.split("\n")
                                   .map(line => line.trim())
                                   .filter(line => line.length > 0);

        instructions.forEach((text, i) => {
            // re-use old group items so timings & temperatures, which aren't editable here, are preserved if possible.
            const item = group.items[i] || {};

            item.text = text;

            items.push(item);
        });

        group.items = items;

        onChangeInstructions(group);
    }

    onChangeInstructions = (ev) => {
        this.setState({instructions: ev.target.value});
    }

    render = () => {
        const { instructions } = this.state;

        return (
            <section className="with-label edit-instructions">
                <textarea value={instructions} onChange={this.onChangeInstructions} onBlur={this.updateGroupFromInstructions}/>
            </section>
        );
    }
}
