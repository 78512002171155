'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import './AddNutrients.scss';
import './RemoveNutrients.scss';
import modalStyles from '../../../../../jsx-styles/modals';
import allNutrients from '../../../../../tables/nutrients';

export default class RemoveNutrients extends Component {

    static contextTypes = {
        showUpgradeForm: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            search: '',
            selectedNutrients: [],
        };
    }

    showModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false, isConfirmRemove: false, selectedNutrients: []});
    }

    toggleNutrient = (nutrNo) => {
        const { selectedNutrients } = this.state;

        const i = selectedNutrients.indexOf(nutrNo);

        if (i === -1) {
            selectedNutrients.push(nutrNo);
        } else {
            selectedNutrients.splice(i, 1);
        }

        this.setState({selectedNutrients});
    }

    matchFilters = (nutrNo) => {
        const { currentNutrients = [] } = this.props;
        const { search } = this.state;

        return currentNutrients.includes(nutrNo)
    }

    renderChildrenInModal = (children, {accept, cancel, title = 'Remove Nutrients'}) => {
        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                className="add-remove-nutrients-modal"
                contentLabel="Add/Remove Nutrients"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="add-remove-nutrients-container remove-nutrients-container editor-modal-container">
                    <header className="modal-header">
                        <h2>{title}</h2>
                        <button className="close-btn" onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    {children}

                    <footer>
                        <button className="cancel" onClick={cancel}>Cancel</button>
                        <button className="accept" onClick={accept}>Remove</button>
                    </footer>
                </div>
            </Modal>
        );
    }

    renderRemoveNutrientsModal = () => {
        const { isModalOpen, isConfirmRemove, search, selectedNutrients } = this.state;
        const { currentNutrients = [], onRemoveNutrients } = this.props;

        if (!isModalOpen) {
            return null;
        }

        const alphaSortCmp = (a, b) => allNutrients[a].NutrDesc.localeCompare(allNutrients[b].NutrDesc);

        const nutrients = Object.keys(allNutrients)
                                .filter(this.matchFilters)
                                .sort(alphaSortCmp);

        if (isConfirmRemove) {
            return this.renderChildrenInModal(
                <div className="confirm-remove">
                    <i className="icon-trash-can" />
                    <h2>Confirm Removal of:</h2>

                    {selectedNutrients.map(nutrNo => {
                        return <span key={nutrNo}>{allNutrients[nutrNo].NutrDesc}</span>
                    })}
                </div>,
                {
                    accept: () => onRemoveNutrients(selectedNutrients) || this.closeModal(),
                    cancel: () => this.setState({isConfirmRemove: false}),
                    title: '',
                }
            );
        }

        return this.renderChildrenInModal(
            <div className="editor-scrollable">
                <ul>
                    {nutrients.map(nutrNo => {
                        return (
                            <li key={nutrNo}>
                                <label className="global-search-checkbox">
                                    <input type="checkbox" checked={selectedNutrients.includes(nutrNo)}
                                        onChange={e => this.toggleNutrient(nutrNo)} />
                                    <span>{allNutrients[nutrNo].NutrDesc}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>,
            {
                accept: () => selectedNutrients.length > 0 && this.setState({isConfirmRemove: true}),
                cancel: this.closeModal,
            }
        );
    }

    render() {
        const { showUpgradeForm } = this.context;

        return (
            <span className="add-remove-nutrients remove-nutrients">
                <button className="nutrient-btn" onClick={this.showModal}>
                    Remove Nutrient
                </button>

                {this.renderRemoveNutrientsModal()}
            </span>
        );
    }
}
