'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../Dropdown.react';
import Expander from '../Expander.react';
import DocumentTypeFilter from '../Filters/DocumentType.react';

import './DocumentType.scss';

export default class DocumentType extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, allowedTypes, onChangeParams, isExpander, autoClose } = this.props;

        const availableTypes = {
            recipe: 'Recipes',
            combo: 'Main + Side Dishes',
            plan: 'Full Meal Plans',
            food: 'Ingredients',
        };

        // If there is only one option, we don't need to render this dropdown at all.
        if (allowedTypes.length == 1) {
            return <span />
        }

        // render the document type filter (for meal plans + recipes, just meal plans, just recipes)
        const button = (
            <span className="global-search-filter-btn document-types-filter-btn">
                Food Type
            </span>
        );

        const Component = isExpander ? Expander : Dropdown;

        return (
            <Component button={button}>
                <DocumentTypeFilter allowedTypes={allowedTypes} params={params} onChangeParams={onChangeParams} autoClose={autoClose} />
            </Component>
        );
    }
}
