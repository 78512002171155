"use strict";

import { getConfig } from "./Env";

export function sentryBeforeSend(event, hint) {
    const { originalException, captureContext } = hint;

    const ignoreMessages = [
        "Load failed",
        "Connection is closing",
        "InvalidStateError: The object is in an invalid state.",
        "The object is in an invalid state.",
        "service-unavailable - No module is handling this query",
        "null is not an object (evaluating 't.socket.write')",
        "The operation couldn’t be completed. (kCLErrorDomain error 1.)",
        "InvalidStateError: The object is in an invalid state.",
        "TimeoutError: No error message",
        "Extension context invalidated.",
        "WebSocket ECONNERROR wss://chat.eatlove.is:5443/ws",
        "t.socket is null",
        "An attempt was made to use an object that is not, or is no longer, usable",
        "Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.",
        "Cannot read properties of null (reading 'write')",
        "remote-server-not-found - DNS lookup failed: timeout",
        "null is not an object (evaluating 'e.target.closest')",
        "null has no properties"
    ];

    if (ignoreMessages.includes(originalException?.message) || !originalException?.message) {
        return false;
    }

    // Ignore errors fetching from any of our own services.
    const ignoreDescriptions = [
        "Fetching data from " + getConfig("users_api") + "/",
        "Fetching data from https://" + getConfig("www_host") + "/",
        "Fetching data from https://" + getConfig("pro_host") + "/",
        "Fetching data from " + getConfig("recipe_api") + "/",
    ];

    const { description = "" } = captureContext || {};

    if (
        typeof description?.startsWith === "function" &&
        ignoreDescriptions.some((prefix) => description.startsWith(prefix))
    ) {
        return false;
    }

    return event;
}
