'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { DragSource, DropTarget } from 'react-dnd';
import debounce from 'lodash.debounce';
import uuidGen from 'uuid';
import SkipMenu from '../../../../components/Dashboard/SkipMenu.react';
import SwapMenu from '../../../../components/Dashboard/SwapMenu.react';
import PresentMenu from '../../../../components/Dashboard/PresentMenu.react';
import AuthStore from '../../../../stores/AuthStore';
import { getConfig } from '../../../../utils/Env';
import { fetchDocumentsById } from '../../../../utils/Content';
import Analytics from '../../../../utils/Analytics';
import { isSunbasketFood } from '../../../../utils/Sunbasket';
import MealTypes from '../../../../constants/MealTypes';
import ImgResized from'../../../../components/Widgets/ImgResized.react';
import { getPrimaryMeal, getParticipantsForProfileByMealType, getMealTypeInfoByName } from '../../../../utils/Meals';

import './MealDraggable.scss';

@DragSource(
    MealTypes.MEAL,
    {
        beginDrag(props) {
            return { meals: props.meals };
        },
        endDrag(props, monitor, component) {
        },
        isDragging(props, monitor) {
            return props.meals === monitor.getItem().meals;
        },
        canDrag(props) {
            return !props.disableDnD;
        }
    },
    (connect, monitor) => ({
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    })
)
export default class MealDraggable extends Component {
    static propTypes = {
        meals: PropTypes.array,
        hideIcons: PropTypes.bool,
        disableDnd: PropTypes.bool,
        profile: PropTypes.object,
    };

    static contextTypes = {
        recipes: PropTypes.object,
        foods: PropTypes.object,

        onModifyMeals: PropTypes.func.isRequired,
        getDefaultAddSwapSettings: PropTypes.func.isRequired,
        getLeftoverOffsetOverlaps: PropTypes.func.isRequired,
        showMealDetails: PropTypes.func,
        editMealBatches: PropTypes.func,
        onRemoveMeals: PropTypes.func,
        startReplaceMeal: PropTypes.func,
        leftoversEnabled: PropTypes.bool,
        isMobile: PropTypes.bool,

    };


    constructor(props) {
        super(props);

        this.state = {
            usedRecipeUuids: [],
            loading: false,
            imageLoaded: true,
            canUndoRefresh: false,
        };

        this.debounceSearch = debounce(this.search, 350);
        this.interval = null;

    }

    setUndoRefresh = (timeleft) => {
        const { lastRefreshedMeals } = this.state;
        this.interval = setInterval(()=>{
          if(timeleft <= 0){
            this.setState({canUndoRefresh: false, cachedMeals: lastRefreshedMeals})
          }
          timeleft -= 1;
        }, 1000);        
    }


    componentDidUpdate = (prevProps, prevState) => {
        const { meals } = this.props;
        const { usedRecipeUuids, imageLoaded, loading, canUndoRefresh } = this.state;

        if (prevState.canUndoRefresh && !canUndoRefresh) {
            clearInterval(this.interval);
        }

        if (meals.length && !usedRecipeUuids.length) {
            const { recipe} = this.getPrimaryMeal(meals);
            if (recipe) {
                this.setState({usedRecipeUuids: [recipe.uuid]})
            }
        }

        if(loading && !prevState.imageLoaded && imageLoaded) {
            this.setState({loading: false});

        }
    }

    getPrimaryMeal = (meals) => {
        const { recipes, foods } = this.context;

        return getPrimaryMeal(meals, recipes, foods);
    }

    undoDelete = () => {
        const { meals } = this.props;
        const { onModifyMeals } = this.context;

        if (!onModifyMeals || !meals) {
            return;
        }

        onModifyMeals(meals);
    }

    renderControls = (primary, meals) => {
        const { hideIcons } = this.props;
        const { canUndoRefresh } = this.state;

        if (hideIcons) {
            return null;
        }

        if (primary.deleted) {
            return (
                <footer>
                    <button onClick={this.undoDelete} className="undo-delete">undo delete</button>
                </footer>
            );
        }

        if (canUndoRefresh) {
            return (
                <footer className="card-controls">
                    <button onClick={this.onUndoRefresh} className="drawer-menu-btn">undo</button>
                </footer>
            );
        }

        return (
            <footer>
                <SkipMenu meals={meals} primary={primary} />
                <SwapMenu primary={primary} />
                <PresentMenu date={primary.offset} mealType={primary.meal} meals={meals} primary={primary} />
            </footer>
        );
    }

    getStubParams = (profile, defaultTags = [], defaultAvoids = [],
                     defaultExcludes = [], extraFilters = {}, ideals = null, envelope = null) => {

        let params = {
            language: profile.language || 'en',
            types: ['recipe', 'combo'],
            filters: {},
            size: 1,
            sort_by: 'ideal_smart_choices',
            sort_params: {},
            include_library: true,
        };

        const conditions = (profile.conditions || []).map(cd => cd.name);
    
        params.filters["avg_cost_per_serving"] = envelope.max_cost;

        const alwaysExclude = ["Exclude from Virtual", "Ready Made Meal"];

        Object.assign(params.filters, {
            tags: defaultTags,
            '!tags': alwaysExclude.concat(defaultExcludes || []),
            '!ingredient_tags': defaultAvoids,
        });

        Object.assign(params.filters, extraFilters);

        if (ideals && Object.keys(ideals).length > 0) {
            params.sort_params.ideals = ideals;
        }

        if (conditions && conditions.length > 0) {
            params.sort_params.conditions = conditions;
        }

        if (envelope && Object.keys(envelope).length > 0) {
            params.sort_params.envelope = envelope;
        }

        if (defaultAvoids && defaultAvoids.length) {
            params.sort_params.avoidances = defaultAvoids;
        }

        return params;
    }

    search = (params, participants) => {
        const { onModifyMeals, startReplaceMeal, getLeftoverOffsetOverlaps }  = this.context;
        const { usedRecipeUuids, cachedMeals} = this.state;
        let { meals, mealType, offset, profile } = this.props;

        const url = '/search-uniques';
        const { primary: oldPrimary} = this.getPrimaryMeal(meals);
        const { clears: leftoverOffsets } = getLeftoverOffsetOverlaps(oldPrimary, oldPrimary.scaling, participants, offset, mealType);     

        this.setState({loading: true});

        AuthStore.fetch(getConfig('recipe_api') + url, {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify(params),
        }, true).then( async (results) => {
            // successful response
            if (results.elements.length) {
                const result = results.elements[0];
                const mealsToCache = cachedMeals ? cachedMeals : (meals || []).filter(meal => !meal.deleted);

                if (result.type == 'combo') {
                    const mainRecipe = await fetchDocumentsById([result.main_dish]);
                    const sideRecipe = await fetchDocumentsById([result.side_dish]);

                    const scaling = Math.ceil((oldPrimary.scaling * (oldPrimary.servings ?? 1) / mainRecipe[0].servings));
                    const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
                    const totalMainDays = Math.floor(scaling * mainRecipe[0].servings / neededPerMeal);
                    const totalSideServingsNeeded = totalMainDays * neededPerMeal;
                    const sideScaling = Math.ceil(totalSideServingsNeeded / sideRecipe[0].servings)

                    const main = {
                        id: uuidGen.v4().substring(0, 8),
                        meal_type: 'fresh',
                        meal: mealType,
                        offset: offset,
                        recipe_uuid: mainRecipe[0].uuid,
                        details_uuid: mainRecipe[0].details,
                        recipe_title: mainRecipe[0].title,
                        recipe_image: mainRecipe[0].image,
                        scaling: scaling,
                        participants: participants.map(p => p.uuid || '').join(','),
                    };

                    const side = {
                        id: uuidGen.v4().substring(0, 8),
                        meal_type: 'fresh',
                        meal: mealType,
                        offset: offset,
                        recipe_uuid: sideRecipe[0].uuid,
                        side_dish: true,
                        details_uuid: sideRecipe[0].details,
                        recipe_title: sideRecipe[0].title,
                        recipe_image: sideRecipe[0].image,
                        scaling: sideScaling,
                        participants: participants.map(p => p.uuid || '').join(','),
                    };

                    onModifyMeals([main, side], meals, leftoverOffsets, [mainRecipe[0], sideRecipe[0]]);

                    this.setState( prevState => ({
                        usedRecipeUuids: [...prevState.usedRecipeUuids, result.main_dish],
                        canUndoRefresh: true,
                        cachedMeals: mealsToCache,
                        leftoverOffsets: leftoverOffsets,
                        lastRefreshedMeals: [main, side]
                    }))  
                } else {
                    const scaling = Math.ceil((oldPrimary.scaling * (oldPrimary.servings ?? 1) / result.servings));

                    let side_dish = ['Breakfast Side Dish', 'Lunch Side Dish', 'Side Dish'].filter(t => (result.tags || []).includes(t)).length > 0;

                    let meal = {
                        id: uuidGen.v4().substring(0, 8),
                        meal: mealType,
                        offset: offset,
                        meal_type: 'fresh',
                        recipe_uuid: result.uuid,
                        details_uuid: result.details,
                        recipe_title: result.title,
                        recipe_image: result.image,
                        scaling,
                        participants: participants.map(p => p.uuid || '').join(','),
                        side_dish,
                    };

                    onModifyMeals([meal], meals, leftoverOffsets, [result])

                    this.setState( prevState => ({
                        usedRecipeUuids: [...prevState.usedRecipeUuids, result.uuid],
                        canUndoRefresh: true,
                        cachedMeals: mealsToCache,
                        leftoverOffsets: leftoverOffsets,
                        lastRefreshedMeals: [meal]
                    }))                
                }
                Analytics.refreshMeal();
                clearInterval(this.interval);
                this.setUndoRefresh(5);
              // If after previous successes no response start cycling through same meals again
            } else if (usedRecipeUuids.length > 1) {
                this.setState({usedRecipeUuids: [usedRecipeUuids[usedRecipeUuids.length - 1]]}, () => this.refreshMeal())
                // If no response on first attempt open smart choices
            } else {
                this.setState({loading: false});
                const { primary } = this.getPrimaryMeal(meals);
                startReplaceMeal(primary);
                Analytics.refreshMealFallback();
            }


        }, (error) => null);
    }


    refreshMeal = (event) => {
        if (event) {
            event.stopPropagation();    
        }
        
        const { getDefaultAddSwapSettings } = this.context;
        const { meals, mealType, offset, profile} = this.props;
        const { usedRecipeUuids} = this.state;
        
        const mealTypeInfo = getMealTypeInfoByName(item.mealType, item.date, preferences);
        const mealTypeTag = mealTypeInfo.main_dish;
        const envelope = mealTypeInfo.envelope;

        const participants = getParticipantsForProfileByMealType(profile, mealType);
        let { defaultAvoids, defaultExcludes, defaultTags, extraFilters, ideals} = getDefaultAddSwapSettings(participants, offset, mealType, meals);

        extraFilters["!uuid"] = usedRecipeUuids;
        extraFilters["!main_dish"] = usedRecipeUuids;
        extraFilters["!side_dish"] = usedRecipeUuids;
        extraFilters["tags"] = [mealTypeTag];

        const params = this.getStubParams(profile, defaultTags, defaultAvoids, defaultExcludes, extraFilters, ideals, envelope);

        this.debounceSearch(params, participants);
    }

    onUndoRefresh = () => {
        const { meals } = this.props;
        const { cachedMeals, leftoverOffsets} = this.state;
        const { onModifyMeals} = this.context;
        const mealsToDelete = (meals || []).filter(meal => !cachedMeals.find(cachedMeal => cachedMeal.id === meal.id));
        onModifyMeals(cachedMeals, mealsToDelete, leftoverOffsets);
        this.setState({canUndoRefresh: false, cachedMeals: null, leftoverOffsets: null});
    }

    render() {
        const { meals, disableDnD, isDragging, connectDragSource } = this.props;
        const { isMobile, showMealDetails, recipes, foods } = this.context;
        const { loading, imageLoaded } = this.state;

        const { primary, recipe, food, titles, photos } = this.getPrimaryMeal(meals);

        // If we don't have a primary meal, don't render anything.
        if (!primary) {
            return <span />
        }

        let imageProps = {width: 200, height: 200}, showCardAsImage = false;

        const setCardImage = (cardImage) => {
            imageProps.src = cardImage;
        }

        if (['fresh'].includes(primary.meal_type) && photos[0]) {
            setCardImage(photos[0].url);
            showCardAsImage = true;
        }

        if (primary.meal_type === 'leftover' && photos[0]) {
            setCardImage(photos[0].url);
            showCardAsImage = true;
        }

        if (photos[0] && photos[0].isDeletable) {
            setCardImage(photos[0].url);
            showCardAsImage = true;
        }
        
        return connectDragSource(
            <div>
                <div data-dragging={isDragging}
                    data-dnd-disabled={disableDnD}
                    data-deleted={primary.deleted}
                    data-meal-type={primary.meal_type}
                    data-is-mobile={isMobile}
                    className="customizer-meal-draggable" title={recipe && recipe.title}>

                    {showCardAsImage
                        ? <ImgResized onLoad={() => this.setState({imageLoaded: true})} onUnload={() => this.setState({imageLoaded: false})} onClick={() => showMealDetails(meals)} className="meal-image" {...imageProps} />
                        : <div onClick={() => showMealDetails(meals)} className="meal-image" {...imageProps}></div>}

                    {loading ?
                        <div key="loading" data-loading={true} className="image-overlay-text text-over-image">
                            <i className="feather feather-loader" />
                        </div>
                    : null}
                    {!loading ? 
                        (<div className="meal-info" onClick={() => showMealDetails(meals)}>
                        <div>
                            <p className="fix-ios-overflow-bug">
                                {primary.meal_type === 'leftover' ? <em>LEFTOVERS</em>  : null}
                                {primary ? titles.join(' + ') : 'Loading...'}
                            </p>
                        </div>
                        {primary && primary.meal_type !== 'leftover' && !(primary.food_uuid && isSunbasketFood(food)) && !disableDnD ?
                                <i className="feather feather-refresh-ccw" onClick={e => this.refreshMeal(e)}></i>
                        : null}
                    </div>)
                 : null}
                    {!loading ? this.renderControls(primary, meals) : null}
                </div>
            </div>
        );
    }
}
