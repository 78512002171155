"use strict";

import { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import FileUpload from "../../Input/FileUpload.react";
import ImgResized from "../../Widgets/ImgResized.react";

import AuthStore from "../../../stores/AuthStore";

import { getConfig } from "../../../utils/Env";

import "./EditImage.scss";
import FileUploadPopUp from "../../Input/FileUploadPopUp.react";

export default class EditImage extends Component {
    static propTypes = {
        onChangeImage: PropTypes.func.isRequired,
        imageLabel: PropTypes.string,
        image: PropTypes.string,
        uploading: PropTypes.string,
        className: PropTypes.string,
        preload: PropTypes.bool,
        showFooter: PropTypes.bool,
        workingScanningLabel: PropTypes.bool,
        popupPosition: PropTypes.string,
        popupClassName: PropTypes.string,
        showPopup: PropTypes.bool,
    };

    static defaultProps = {
        imageLabel: "",
        uploading: "Uploading...",
        preload: false,
        showFooter: true,
        popupPosition: "el-popup-top-left",
        showPopup: false,
    };

    static contextTypes = {
        confirm: PropTypes.func,
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        this.state = {
            isImageModalOpen: false,
            isUploadModalOpen: false,
            fileUploading: false,
        };
    }

    openImageModal = (ev) => {
        this.setState({ isImageModalOpen: true });

        ev.preventDefault();
    };

    openUploadModal = (ev) => {
        this.setState({ isUploadModalOpen: true });

        ev.preventDefault();
    };

    closeImageModal = () => {
        this.setState({ isImageModalOpen: false });
    };

    closeUploadModal = () => {
        this.setState({ isUploadModalOpen: false });
    };

    onChangeImage = ({ url }) => {
        const { onChangeImage } = this.props;

        onChangeImage(url);

        this.closeUploadModal();
    };

    removeImage = () => {
        const { onChangeImage } = this.props;

        onChangeImage(null);

        this.closeImageModal();
    };

    performUpload = (formData) => {
        const { confirm } = this.context;

        this.setState({ isUploading: true });
        formData.append("protection", "public");

        AuthStore.fetch(getConfig("users_api") + "/files", { method: "POST", body: formData }).then(
            (response) => {
                this.setState({ error: null, isUploading: false });

                this.onChangeImage(response);
            },
            (error) => {
                this.setState({
                    isUploading: false,
                    error: (error && error.message) || "unknown error",
                });

                const errorMessage = (error && error.message) || "unknown error occurred";

                confirm(errorMessage, () => false, null, {
                    acceptText: "Close",
                    rejectText: "",
                    titleText: "Unable to upload file",
                });
            },
        );
    };

    uploadPastedFile = (items) => {
        const { confirm } = this.context;
        const item = Array.from(items).find((item) => item.type.startsWith("image/"));

        if (!item) {
            confirm(
                "Apologies, but I don't recognize what you pasted. Please try again.",
                (accept) => false,
                (reject) => false,
                { rejectText: "" },
            );

            return false;
        }

        const blob = item.getAsFile();

        const formData = new FormData();
        formData.append("file", blob);

        return this.performUpload(formData);
    };

    uploadDroppedFile = (files) => {
        const { confirm } = this.context;

        const file = Array.from(files).find((file) => file.type.startsWith("image/"));

        if (!file) {
            confirm(
                "Apologies, but only image files are allowed. Please try again with an image file.",
                (accept) => false,
                (reject) => false,
                { rejectText: "" },
            );

            return false;
        }

        const formData = new FormData();
        formData.append("file", file);

        return this.performUpload(formData);
    };

    onPaste = (ev) => {
        const { onChangeImage } = this.props;
        const { isUploading } = this.state;
        const { confirm } = this.context;

        if (isUploading) {
            return;
        }

        const clipboardData = ev.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData("Text");
        const items = clipboardData.items;

        // Is the pastedData a static URL?
        if (pastedData) {
            if (
                pastedData.startsWith(getConfig("static_url_prefix")) ||
                pastedData.startsWith("https://static.chewba.info/")
            ) {
                this.onChangeImage({ url: pastedData });
                return;
            }

            // @todo Maybe this is an image URL to some other URL?
            confirm(
                "Sorry, I don't recognize what you pasted. Please try again.",
                (accept) => false,
                (reject) => false,
                { rejectText: "" },
            );
        } else if (items?.length) {
            this.uploadPastedFile(items);
        }

        ev.preventDefault();
    };

    onDrop = (ev) => {
        const { isUploading } = this.state;
        if (isUploading) {
            return;
        }

        this.uploadDroppedFile(ev.dataTransfer.files);

        ev.preventDefault();
    };

    onDragOver = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        return false;
    };

    renderUploadModal = () => {
        const { isUploadModalOpen, isUploading, fileUploading } = this.state;

        if (!isUploadModalOpen) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={this.closeUploadModal}
                contentLabel="Upload Google Sheet"
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <section className="el-modal-container el-modal3-container">
                    <header>
                        <button className="el-modal-close-x" onClick={this.closeUploadModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal3-body-container el-fonts">
                        <div
                            className="edit-recipe-upload-form"
                            onPaste={this.onPaste}
                            onDrop={this.onDrop}
                            onDragOver={this.onDragOver}
                        >
                            {isUploading ? <i className="icon-spinner2" /> : <i className="feather feather-upload" />}

                            {!isUploading ? (
                                <FileUpload
                                    className="file-upload extra-large-upload-btn footer-upload-btn"
                                    button="Browse"
                                    buttonClassName="el-medium-btn el-medium-wide-btn el-gray-btn"
                                    protection="public"
                                    onUploadSuccess={this.onChangeImage}
                                    onUploading={(value) => this.setState({fileUploading: value})}
                                />
                            ) : null}

                            <p className="t2">
                                {fileUploading ? null : !isUploading ? (
                                    <>Paste or drop your image here</>
                                ) : <>Please wait, uploading your image</>}
                            </p>
                        </div>
                    </div>
                </section>
            </Modal>
        );
    };

    renderScanningModal = () => {
        const { workingScanningLabel, stopScanning } = this.props;
        const { isMobile } = this.context;

        if (!workingScanningLabel || isMobile) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                onRequestClose={false}
                contentLabel="Scanning Modal"
                className="el-modal el-modal3 el-modal3-centered"
                overlayClassName="el-modal-overlay"
                closeTimeoutMS={250}
            >
                <section className="el-modal-container el-modal3-container">
                    <header>
                        <button className="el-modal-close-x" onClick={() => stopScanning()}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal3-body-container el-fonts">
                        <div
                            className="edit-recipe-upload-form"
                        >
                            <i className="feather feather-upload" />
                            <button className="el-medium-btn el-medium-wide-btn el-gray-btn scanning-text">
                                <span className="upload-bar-background">
                                        <span className="upload-bar-fill" />
                                </span>
                                <span className="uploading-text">Analyzing Nutritional Data...</span>
                            </button>
                            <p className="t2">
                            </p>
                        </div>
                    </div>
                </section>
            </Modal>
        );
    };

    renderImageModal = () => {
        const { isImageModalOpen } = this.state;
        const { image, showFooter } = this.props;

        if (!isImageModalOpen) {
            return;
        }

        return (
            <Modal
                isOpen={true}
                className="el-modal el-modal3 el-modal3-photo-lightbox"
                overlayClassName="el-modal-overlay"
                onRequestClose={this.closeImageModal}
                closeModal={this.closeImageModal}
                contentLabel="Edit recipe image"
                closeTimeoutMS={250}
            >
                <div className="el-modal-container el-modal3-container edit-image-modal-container">
                    <header>
                        <div className="close-x-circle">
                            <button className="el-modal-close-x" onClick={this.closeImageModal}>
                                <i className="icon-x" />
                            </button>
                        </div>
                    </header>
                    <div className="el-modal-body-container el-modal3-photo-lightbox-body-container edit-image-modal-body">
                        <img className="recipe-image-full" src={image} />
                    </div>

                    {showFooter ?
                        <footer>
                            <button
                                className="el-medium-btn el-medium-wide-btn el-grayish-blue-outline-btn"
                                onClick={this.removeImage}
                            >
                                delete
                            </button>

                            <button className="el-medium-btn el-medium-wide-btn el-gray-btn" onClick={this.openUploadModal}>
                                replace
                            </button>

                            <button
                                className="el-medium-btn el-medium-wide-btn el-raspberry-btn "
                                onClick={this.closeImageModal}
                            >
                                done
                            </button>
                        </footer>
                    : null}
                </div>
            </Modal>
        );
    };

    cacheImage = async (url) => {
        const promise = new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = resolve();
            img.onerror = reject();
        });

        await Promise.race([promise]);
    }

    render = () => {
        const { image, uploading, imageLabel, className, preload, workingScanningLabel, popupPosition, lastUploaded, showPopup, popupClassName } = this.props;
        const { isUploading } = this.state;
        const { isMobile } = this.context;
        const cameraClass = className || "icon-camera";

        if (preload) {
            this.cacheImage(image);
        }

        return (
            <div className={"edit-recipe-image" + (imageLabel.length > 0 ? " with-image-label" : "")}>
                {
                    isMobile && lastUploaded && workingScanningLabel ?
                        <div className="scanning-text">
                            <span className="uploading-text">analyzing</span>
                            <span className="upload-bar-background">
                                <span className="upload-bar-fill" />
                            </span>
                        </div> : image ? (
                            <a href={image} target="_blank">
                                <ImgResized onClick={this.openImageModal} src={image} width={124} height={124} />
                            </a>
                        ) : null
                }

                {!image && !isMobile && !isUploading ? (
                    <button
                        className="new-image-btn"
                        onClick={this.openUploadModal}
                        onDrop={this.onDrop}
                        onDragOver={this.onDragOver}
                    >
                        {imageLabel.length > 0 ? (
                            <span>
                                <i className={cameraClass} />
                                <p>{imageLabel}</p>
                            </span>
                        ) : (
                            <i className={cameraClass} />
                        )}
                    </button>
                ) : null}

                {!image && !isMobile && isUploading ? (
                    <button className="new-image-btn">
                        <i className="icon-spinner2" />
                    </button>
                ) : null}

                {!image && isMobile && !showPopup ? (
                    <FileUpload
                        className={"file-upload"}
                        uploading={uploading}
                        button={
                            imageLabel.length > 0 ? (
                                <span>
                                    <i className={cameraClass} />
                                    <p>{imageLabel}</p>
                                </span>
                            ) : (
                                <i className={cameraClass} />
                            )
                        }
                        protection="public"
                        onUploadSuccess={this.onChangeImage}
                    />
                ) : null}

                {!image && isMobile && showPopup ? (
                        <FileUploadPopUp
                            className={popupClassName}
                            positionClassName={popupPosition}
                            uploading={uploading}
                            button={
                                imageLabel.length > 0 ? (
                                    <span>
                                        <i className={cameraClass} />
                                        <p>{imageLabel}</p>
                                    </span>
                                ) : (
                                    <i className={cameraClass} />
                                )
                            }
                            protection="public"
                            onUploadSuccess={this.onChangeImage}
                            workingScanningLabel={workingScanningLabel}
                        />
                ) : null}

                {this.renderImageModal()}
                {this.renderUploadModal()}
                {this.renderScanningModal()}
            </div>
        );
    };
}
