'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import modalStyles from '../../../jsx-styles/modals';
import Terms from '../../../pages/Terms.react';

import './TermsModal.scss';
import '../../FeedModals.scss';

export default class TermsModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
    };

    render() {
        const { closeModal } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                contentLabel="Terms of Service"
                className="feed-modal privacy-policy-modal"
                overlayClassName="feed-modal-overlay">
                <div className="feed-modal-container terms-of-service-modal">
                    <header>
                        <div className="top-half-circle">&nbsp;</div>
                        <div className="header-container">
                            <button className="close-modal-btn" onClick={closeModal}>
                                <i className="icon-chevron-left" />
                                EatLove Terms of Use
                            </button>
                        </div>
                    </header>

                    <div className="modal-scroll-container">
                        <Terms />
                    </div>
                </div>
            </Modal>
        );
    }
}
