'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import DrawerMenu from '../../pro/components/Widgets/DrawerMenu.react';
import Analytics from '../../utils/Analytics';
import { getMealInfo } from '../../utils/Meals';

import './SkipMenu.scss';

export default class SkipMenu extends Component {

    static contextTypes = {
        recipes: PropTypes.object,
        foods: PropTypes.object,
        user: PropTypes.object,

        startReplaceMeal: PropTypes.func,
        onRemoveMeals: PropTypes.func,
    };

    onOpenMenu = () => {
        const { meals, primary } = this.props;
        const { recipes, foods } = this.context;

        const mealInfo = getMealInfo(primary, recipes, foods);

        Analytics.startClearMeal({
            'Meal UUIDs': meals.map(m => m.uuid),
            'Meal Type': primary.meal,
            'Date': primary.date,
            'Meal Name': mealInfo.mealName,
            'Product Type': mealInfo.productType,
            'Brand Name': mealInfo.brandName,
        });
    }

    onSkipMeal = () => {
        const { meals, primary } = this.props;
        const { recipes, foods, onRemoveMeals } = this.context;

        onRemoveMeals(meals);

        const mealInfo = getMealInfo(primary, recipes, foods);
        Analytics.clearMeal({
            'Meal UUIDs': meals.map(m => m.uuid),
            'Meal Type': primary.meal,
            'Date': primary.date,
            'Meal Name': mealInfo.mealName,
            'Product Type': mealInfo.productType,
            'Brand Name': mealInfo.brandName,
        });
    }

    render() {
        const { meals, primary } = this.props;
        const { startReplaceMeal, onRemoveMeals, user } = this.context;
        const { inhibit_add_swap_modes = [] } = user?.preferences || {};

        const addSwapModes = ['restaurants', 'favorites', 'browser']
        const allowedModes = addSwapModes.filter((mode) => !inhibit_add_swap_modes?.includes(mode));

        const renderMenuItems = {
            'restaurants': <button key="restaurants" className="menu-item eat-out-instead" onClick={() => startReplaceMeal(primary, {defaultMode: 'restaurants'})}>
                Eat out instead <i className="icon-location-pin" />
            </button>,
            'favorites': <button key="favorites" className="menu-item favorites" onClick={() => startReplaceMeal(primary, {defaultMode: 'favorites'})}>
                Choose from favorites <i className="icon-heart2" />
            </button>,
            'browser': <button key="browser" className="menu-item search" onClick={() => startReplaceMeal(primary, {defaultMode: 'browser'})}>
                Search Foods and Recipes <i className="icon-search5" />
            </button>,
        };

        return (
            <DrawerMenu key="skip" button={<span>clear</span>}
                menuTitle={allowedModes.length ? "Try These Options" : "Confirm Clearing this meal?"} className="skip-menu-btn" modalClassName="feed-skip-menu" onOpenMenu={this.onOpenMenu}>
                <div className="feed-menu-content">
                    {allowedModes.map((mode) => {
                        return renderMenuItems[mode]
                    })}
                    <button className="skip-meal-btn" onClick={this.onSkipMeal}>
                        {allowedModes.length ? "No thanks, clear meal" : "Yes, clear meal"}
                    </button>
                </div>
            </DrawerMenu>
        );
    }
}
