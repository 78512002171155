'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../Dropdown.react';
import Expander from '../Expander.react';
import CaloriesFilter from '../Filters/Calories.react';
import FilterUnavailable from '../Filters/FilterUnavailable.react';

export default class Calories extends Component {
    static propTypes = {
        isExpander: PropTypes.bool,
    };

    static defaultProps = {
        isExpander: false,
    };

    render() {
        const { params, onChangeParams, isExpander } = this.props;

        let badge = <em data-active={false} />;

        if (params && params.filters && params.filters.calories) {
            badge = <em data-active={true}>1</em>
        }

        const button = (
            <span className="global-search-filter-btn">
                Calories {badge}
            </span>
        );

        // If we're not filtering for plans, then the plan size filter is not active
        const isFilterAvailable = params.types.includes('recipe');
        const Component = isExpander ? Expander : Dropdown;

        if (!isFilterAvailable) {
            return <span />
        }

        return (
            <span className="global-search-hideable-filter" data-active={isFilterAvailable}>
                <Component button={button}>
                    {isFilterAvailable ?
                        <CaloriesFilter params={params} onChangeParams={onChangeParams} />
                    : <FilterUnavailable needDocType="recipe" params={params} onChangeParams={onChangeParams} />}
                </Component>
            </span>
        );
    }
}
