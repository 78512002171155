'use strict';

export default [

    // Macro Nutrients
    '208', // Calories (kcal)
    '204', // Total Fat (g)
    '606', // Saturated Fat (g)
    '605', // 605 Trans Fat (g)
    '601', // Cholesterol (mg)
    '307', // Sodium (mg)
    '205', // Carbohydrates (g)
    '291', // Fiber (g)
    'FLU', // Fluids (ml)

    // More Macros and Macro breakdowns
    '269', // Sugar (g)
    'ASG', // Sugar (Added)
    '203', // Protein (g)
    '646', // 646 Polyunsaturated Fat (g)
    '645', // Monounsaturated Fat (g)
    'SFB', // Soluable Fiber (g)
    'IFB', // Insoluable Fiber (g)

    // Micro Nutrients
    '324', // Vitamin D - % x 400 IU (1 IU = 0.025 mcg)* (use 324)
    '301', // Calcium
    '303', // Iron - % x 18 mg
    '306', // Potassium (mg)

    '318', // Vitamin A - % x 5,000 IU (1 IU = 0.3 mg RAE) (use 318)
    '415', // Vitamin B6 - % x 2 mg
    '418', // Vitamin B12 - % x 6 ug
    '401', // Vitamin C - % x 60 mg
    '323', // Vitamin E - % x 30 IU (1 IU = 0.67 mg)
    '430', // Vitamin K
    '404', // Thiamin - % x 1.5 mg
    '405', // Riboflavin - % x 1.7 mg
    '406', // Niacin - % x 20 mg
    '435', // Folate (Folic Acid) - % x 400 ug
    'BIO', // Biotin - % x 300 ug
    '410', // Pantothenic Acid - % x 10 mg
    '305', // Phosphorus - % x 1,000 mg
    '304', // Magnesium - % x 400 mg
    '309', // Zinc - % x 15 mg
    '317', // Selenium - % x 70 ug
    '315', // Manganese - % x 2 mg
    'MBD', // Molybdenum - % x 75 ug

    'CHL', // Chloride - % x 3,400 mg
    '421', // Choline, Total
    '312', // Copper - % x 2 mg (use 312)
    'IOD', // Iodine - % x 150 ug
    'CHR', // Chromium

    // Fruits & Vegetable
    'FRU', // fruits
    'VEG', // non-starchy vegetables
    'SVG', // starchy vegetables
    'WHG', // whole grains
    'HLF', // healthy fats
    'LPR', // lean proteins
    'DAI', // dairy servings
    'LFY', // leafy greens
    'BER', // berries
    'NUT', // nuts/seeds


    // FODMAPs
    'OLF', // Oligos-fructans
    'OLG', // Oligos-GOS
    'FRC', // Fructose
    'LAC', // Lactose
    'PLY', // Polyols
    'MAN', // Mannitol
    'SOR', // Sorbitol


    // Omega 3s
    '621', // Omega-3 (DHA)
    '629', // Omega-3 (EPA)
    '851', // Omega-3 (ALA)
    'OM3', // DHA+EPA
    'OMA', // DHA+EPA+ALA

    // Omega 6s
    '672', // Omega 6 (Eicosadienoic acid)
    '675', // Omega 6 (Linoleic acid)
    '685', // Omega 6 (Gamma-linoleic acid)
    '853', // Omega 6 (Eicosatrienoic acid)
    '855', // Omega 6 (Eicosatetraenoic acid)


    // Sugars & Alcohols
    '221', // Alcohol
    'SAL', // Sugar Alcohol
    '210', // Sucrose
    '211', // Glucose
    '214', // Maltose
    '213', // Lactose (g)
    '287', // Galactose


    // Derived Values
    'RAL', // Potential Renal Acid Load
    'NTC', // Net Carbs (g)


    // Amino Acids
    '454', // Betaine
    '501', // Tryptophan
    '502', // Threonine
    '503', // Isoleucine
    '504', // Leucine
    '505', // Lysine
    '506', // Methionine
    '507', // Cystine
    '508', // Phenylalanine
    '509', // Tyrosine
    '510', // Valine
    '511', // Arginine
    '512', // Histidine
    '513', // Alanine
    '514', // Aspartic acid
    '515', // Glutamic acid
    '516', // Glycine
    '517', // Proline
    '518', // Serine
    '521', // Hydroxyproline

    // Other Fatty Acids
    '607', // Butyric acid
    '608', // Caproic acid
    '609', // Caprylic acid
    '610', // Capric acid
    '611', // Lauric acid
    '612', // Myristic acid
    '613', // Palmitic acid
    '614', // Stearic acid
    '615', // Arachidic acid
    '617', // Oleic acid (undifferentiated)
    '618', // Linoleic acid (undifferentiated)
    '619', // Alpha-linolenic acid (undifferentiated)
    '620', // Arachidonic acid (undifferentiated)
    '624', // Behenic acid
    '625', // Myristoleic acid
    '626', // Palmitoleic acid
    '627', // Stearidonic acid
    '628', // Eicosenoic acid
    '630', // Erucic acid (undifferentiated)
    '653', // Margaric acid
    '654', // Lignoceric acid
    '662', // Trans-palmitoleic acid
    '670', // Conjugated Linoleic Acids
    '671', // Nervonic acid
    '673', // Palmitoleic acid
    '674', // Oleic acid
    '676', // Erucic acid
    '687', // Heptadecenoic acid
    '693', // Trans-monoenoic Fat
    '695', // Trans-polyenoic Fat
    '696', // Tridecanoic acid
    '697', // Pentadecenoic acid


    // Other crap we don't care about.
    '207',
    '209',
    '212',
    '255',
    '257',
    '262',
    '263',
    '268',
    '313',
    '319',
    '320',
    '321',
    '322',
    '325',
    '326',
    '328',
    '334',
    '337',
    '338',
    '341',
    '342',
    '343',
    '344',
    '345',
    '346',
    '347',
    '417',
    '428',
    '429',
    '431',
    '432',
    '573',
    '578',
    '631',
    '636',
    '638',
    '639',
    '641',
    '652',
    '653',
    '663',
    '664',
    '665',
    '666',
    '669',
    '689',
    '852',
    '853',
    '855',
    '856',
    '857',
    '858',
    '859',
    'SUL',
];
