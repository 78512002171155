'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import PlanConstants from '../constants/PlanConstants';

var PlanActions = {
    loadPlansByDateRange: function(dateStart, dateEnd) {
        AppDispatcher.handleViewAction({
            actionType: PlanConstants.PLANS_LOAD_BY_DATE,
            dateStart: dateStart,
            dateEnd: dateEnd
        });
    },

    ensureDateRangeLoaded: (dateStart, dateEnd) => {
        AppDispatcher.handleViewAction({
            actionType: PlanConstants.PLANS_ENSURE_DATE_RANGE,
            dateStart, dateEnd
        });
    },

    upsertPlans: function(plans) {
        AppDispatcher.handleViewAction({
            actionType: PlanConstants.PLANS_UPSERT,
            plans,
        });
    },

    deletePlans: function(plans) {
        AppDispatcher.handleViewAction({
            actionType: PlanConstants.PLANS_DELETE,
            plans,
        });
    },

    hydratePlans: (plans) => {
        AppDispatcher.handleViewAction({
            actionType: PlanConstants.PLANS_HYDRATE,
            plans
        });
    },

    mealFeedRegen: () => {
        AppDispatcher.handleViewAction({actionType: PlanConstants.PLANS_FEED_REGEN});
    },

};

export default PlanActions;
