"use strict";

import React from "react";
import Modal from "react-modal";
import NutritionInfo from "../../Products/NutritionInfo.react";

import "./NutritionInfoModal.scss";

const NutritionInfoModal = ({
    nutrients,
    title = null,
    subtitle = null,
    profile = null,
    onClose = null,
}) => {
    const handleOnClose = () => {
        onClose && onClose();
    };

    return (
        <Modal
            isOpen={true}
            onRequestClose={handleOnClose}
            closeModal={handleOnClose}
            contentLabel="Nutrition Info"
            className="el-modal el-modal1"
            overlayClassName="el-modal-overlay"
            closeTimeoutMS={250}
        >
            <div className="el-modal-container el-modal1-container nutrition-info-modal-container" onClick={handleOnClose}>
                <header onClick={(ev) => ev.stopPropagation()}>
                    <button
                        className="el-modal-back-btn"
                        onClick={handleOnClose}
                    >
                        <span>Back</span>
                    </button>

                    {title ? <h2>{title}</h2> : <></>}
                </header>

                <div className="el-modal-body-container el-modal1-body-container el-fonts" onClick={(ev) => ev.stopPropagation()}>
                    {subtitle ? <h4>{subtitle}</h4> : <></>}
                    <NutritionInfo profile={profile} nutrients={nutrients} />
                </div>
            </div>
        </Modal>
    );
};

export default NutritionInfoModal;
