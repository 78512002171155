'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Modal from 'react-modal';
import uuidGen from 'uuid';
import omit from 'lodash.omit';

import Browser from '../Search/Browser.react';
import RecipeCard from '../Search/Results/RecipeCard.react';
import CollectionCard from '../Search/Results/CollectionCard.react';
import SearchKeywords from '../Search/Filters/SearchKeywords.react';

import UserStore from '../../stores/UserStore';
import AuthStore from '../../stores/AuthStore';
import { getConfig } from '../../utils/Env';
import { updateCachedDocuments } from '../../utils/Content';

import './AddToCollectionButton.scss';

export default class AddToCollectionButton extends Component {

    static propTypes = {
        recipe: PropTypes.object,
    };

    static contextTypes = {
        isMobile: PropTypes.bool,

        router: PropTypes.object,
        confirm: PropTypes.func,
    };

    static childContextTypes = {
        showCreateCustom: PropTypes.func,
        createCustomButtonCopy: PropTypes.node,

        showCollectionDetails: PropTypes.func,
        onSelectCollection: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            mode: 'search',

            params: {
                types: ['collection'],
                filters: {
                    library: true,
                    is_owner: true,
                }
            },
            dirty: false,
        };
    }

    getChildContext = () => {
        return {
            showCreateCustom: this.showCreateCustom,
            createCustomButtonCopy: "Create New Collection",

            showCollectionDetails: this.onSelectCollection,
            onSelectCollection: this.onSelectCollection,
        };
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    onChangeParams = (params) => {
        this.setState({params, dirty: true});
    }

    showCreateCustom = () => {
        // this.setState({position: 1, modalTitle: 'Create New Ingredient'});
        const { router } = this.context;
        const { recipe } = this.props;

        const query = {create: 'collection', 'startWith': recipe.uuid};
        router.push({pathname: '/favorites', query});
    }

    onSelectCollection = async (collection) => {
        const { recipe } = this.props;
        const { confirm, router } = this.context;
        const { working } = this.state;

        if ((collection.items || []).find(item => item.recipe_uuid == recipe.uuid)) {
            confirm(
                <div className="add-to-collection-confirm">
                    <p><em>{recipe.title}</em> is already in <em>{collection.title}</em></p>
                </div>,
                accept => false,
                reject => router.push({pathname: '/favorites', query: {clid: collection.uuid}}),
                {rejectText: 'Edit', acceptText: 'Close'}
            );
            return;
        }

        if ((collection.items || []).length >= 15) {
            confirm(
                'This collection already has the maximum number of recipes it can hold.',
                accept => false,
                reject => router.push({pathname: '/favorites', query: {clid: collection.uuid}}),
                {rejectText: 'Edit', acceptText: 'Close'}
            );
            return;
        }

        if (working) {
            return;
        }

        confirm(
            <div className="add-to-collection-confirm">
                <p><em>{recipe.title}</em> has been added to <em>{collection.title}</em></p>
            </div>,
            accept => this.closeModal(),
            reject => router.push({pathname: '/favorites', query: {clid: collection.uuid}}),
            {rejectText: 'Edit', acceptText: 'Close'}
        );

        this.setState({working: true});

        collection.items.push({
            id: uuidGen.v4().substring(0, 8),
            meal_type: 'fresh',
            recipe_uuid: recipe.uuid,
            details_uuid: recipe.details,
        });

        try {
            collection = await AuthStore.fetch(getConfig('recipe_api') + collection.links.self.href, {
                method: 'POST',
                headers: {'Content-Type': 'application/json; schema=collection/1'},
                body: JSON.stringify(omit(collection, 'links', 'uuid', 'type', 'status')),
            });
        } catch(exp) {
            confirm(
                `An error occurred adding the recipe to the collection: ${exp.message}`,
                accept => false,
                reject => false,
                {rejectText: ''}
            );

            return;
        }

        this.setState({working: false});

        updateCachedDocuments([collection]);
    }

    renderCollectionPickerModal = () => {
        const { params, dirty, position, isModalOpen } = this.state;
        const { recipe } = this.props;
        const { isMobile } = this.context;

        if (!isModalOpen) {
            return;
        }

        return (
            <Modal isOpen={true}
                onRequestClose={this.closeModal}
                closeModal={this.closeModal}
                contentLabel="Search for an ingredient"
                className="search-foods-modal"
                overlayClassName="feed-modal-overlay search-foods-modal-overlay"
                closeTimeoutMS={250}>

                <div className="feed-modal-container search-foods-modal-container" onClick={this.closeModal}>
                    <header onClick={(ev) => ev.stopPropagation()}>
                        <div className="top-half-circle">&nbsp;</div>
                        <div className="header-container">
                            <button className="close-modal-btn" onClick={this.closeModal}>
                                <i className="icon-chevron-left" />
                                {!isMobile ? <span>Search Your Collections</span> : null}
                            </button>

                            {isMobile ? <h1>Search Your Collections</h1> : null}
                        </div>
                    </header>

                    <div className="modal-scroll-container" onClick={(ev) => ev.stopPropagation()}>
                        <section className="inner-slider">
                            <div className="mode-switcher" data-position={position} ref={this.realizeModeSwitcher}>
                                <div className="tile search-foods-browser" data-dirty={dirty}>
                                    <header>
                                        <SearchKeywords params={params}
                                            onChangeParams={this.onChangeParams}
                                            className="full-browser-keyword-search"
                                            placeholder="Search Your Collections" />
                                    </header>

                                    <Browser contextName="Recipes"
                                        params={params}
                                        onChangeParams={this.onChangeParams}
                                        allowedTypes={['collection']}
                                        inhibitSearchOnMount={false}
                                        showTypePicker={true}
                                        hideLibraryFilter={true}
                                        isAdvancedFiltersOpen={false} />
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Modal>
        );
    }

    render = () => {
        return (
            <span>
                <button className="add-to-collection-btn" onClick={this.openModal}>
                    <i className="feather feather-layers" />
                    <span>Collection</span>
                </button>

                {this.renderCollectionPickerModal()}
            </span>
        );
    }
}
