'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { priceFormat, getInvoiceBreakdown, getInvoiceChargeCard } from '../../utils/Invoice';

import './Invoice.scss';

export default class Invoice extends Component {
    static propTypes = {
        invoice: PropTypes.object,
        isConfirmed: PropTypes.bool,
        isReceipt: PropTypes.bool,
        cardError: PropTypes.any,
    };

    static defaultProps = {
        allowAddCoupon: false,
        allowAddOnHourly: false,
        isConfirmed: false,
        isReceipt: false,
        cardError: null,
    };

    render = () => {
        const { invoice, cardError, isConfirmed, isReceipt } = this.props;

        const { charge } = invoice || {};
        const breakdown = invoice ? getInvoiceBreakdown(invoice) : null;
        const card = invoice ? getInvoiceChargeCard(invoice) : null;

        return (
            <div>
                {isConfirmed && !isReceipt && invoice.number ?
                    (<div>
                        <h1>Thank you! Your {breakdown.mode === 'refund' ? 'refund' : 'order'} has been confirmed.</h1>
                        <span className="confirmation-subtitle"><p>your</p> <b>order number is {invoice.number}, {moment().format("MMMM D, YYYY")}</b></span>
                        <span className="confirmation-subtitle"><p>you will also recieve an email confirmation</p></span>
                    </div>)

                : null}

                {cardError ? <p className="error-msg">{cardError}</p> : null}

                {breakdown.mode === 'refund' ?
                    <h1>EatLove started a refund of ${priceFormat(breakdown.amount_refunded)}</h1>
                : null}

                {breakdown.mode === 'default' ?
                    <h1>${priceFormat(breakdown.paid)} payment to EatLove</h1>
                : null}

                {breakdown.mode === 'preview' ?
                    <h1>${priceFormat(breakdown.total)} payment to EatLove</h1>
                : null}

                {card ?
                    <div className="charge-card-info">
                        <img alt={charge.source.brand} height="16" src={"https://stripe-images.s3.amazonaws.com/emails/receipt_assets/card/" + card.type + "-dark@2x.png"} width={card.w} />
                        <span>{charge.source.last4}</span>
                    </div>
                : null}
           </div>
        );
    }
}