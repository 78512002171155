'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import allNutrients from '../../../../tables/nutrients';
import nutrKeys from '../../../../tables/nutrient-order';
import { roundForHumans } from '../../../../utils/Math';
import { getNutrientsToDisplay } from '../../../utils/Patients';

import modalStyles from '../../../../jsx-styles/modals';
import './NutritionModal.scss';
import '../../Modals/Modals.scss';

export default class NutritionModal extends Component {
    static propTypes = {
    };

    render() {
        const { closeModal, profile, averages, nutrients } = this.props;

        const displayNutrs = getNutrientsToDisplay({...profile, portion: 1}, averages);
        const nutrNos = Object.keys(displayNutrs).sort((a, b) => {
            const aI = nutrKeys.indexOf(a),
                  bI = nutrKeys.indexOf(b);

            if (aI > bI) return 1;
            if (aI < bI) return -1;
            return 0;
        });

        const allDayRx = profile.prescriptions?.find(p => p.meal_type === 'all-day');

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="wide-modal customizer-nutrition-modal"
                style={modalStyles.largeSquareModal}
                contentLabel="Meal Plan Groceries"
                closeTimeoutMS={250}>

                <div className="wide-modal-container customizer-nutrition-container">
                    <header>
                        <button onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">

                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {Object.keys(nutrients).map(offset => {
                                return (
                                    <th key={offset}>Day {parseInt(offset)+1}</th>
                                );
                            })}
                            <th>Daily Average</th>
                            <th>Goal</th>
                            <th>%</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nutrNos.map(nutrNo => {
                            const nutrient = allNutrients[nutrNo];
                            const target = displayNutrs[nutrNo];
                            const range = allDayRx?.envelope ? allDayRx?.envelope[nutrNo] : null;

                            return (
                                <tr key={nutrNo}>
                                    <th className="nutrient-name">{nutrient.NutrDesc}</th>
                                    {Object.keys(nutrients).map(offset => {
                                        return (
                                            <td key={offset}>{roundForHumans((nutrients[offset]['all-day'][nutrNo] || 0))} <em>{nutrient.Units}</em></td>
                                        );
                                    })}
                                    <td>{roundForHumans((target.v || 0))} <em>{nutrient.Units}</em></td>
                                    <td>
                                        {(typeof range?.min === 'undefined' || range?.min == 0) && range?.max > 0 ?
                                            <p>{range.max} {nutrient.Units} MAX</p>
                                        : null}

                                        {(typeof range?.max === 'undefined' || range?.max === 0) && range?.min > 0 ?
                                            <p>{range.min} {nutrient.Units} MIN</p>
                                        : null}

                                        {range?.max > 0 && range?.min > 0 ?
                                            <p>{range.min} - {range.max} {nutrient.Units}</p>
                                        : null}
                                    </td>
                                    <td>{!target.trackedOnly && target.t > 0 ?
                                        <>{roundForHumans((target.v || 0) / target.t * 100)}% of {roundForHumans(target.t)} {nutrient.Units}</>
                                    : null}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                    </div>
                </div>
            </Modal>
        );
    }
}
