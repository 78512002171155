'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import BoardStore from '../../../stores/BoardStore';

import { isSingular } from '../../../utils/Math';
import { toHuman } from '../../../utils/Duration';

import './CollectionCard.scss';
import './RecipeCard.scss';

export default class CollectionCard extends Component {
    static propTypes = {
        collection: PropTypes.object,

        isLogging: PropTypes.bool,
    };

    static contextTypes = {
        showResultsFavoriteBtn: PropTypes.bool,
        showCollectionDetails: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.syncFromBoards(this.props.collection);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {

        if (nextProps.collection !== this.props.collection) {
            this.syncFromBoards(nextProps.collection);
        }
    }

    syncFromBoards = (collection) => {
        if (!(collection && collection.uuid)) {
            this.setState({content: null});
            return;
        }

        const boards = BoardStore.getBoardsByResourceId(collection.uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === collection.uuid
            );

            return carry.concat(bcs);
        }, []);

        const content = contents[0];

        this.setState({content});
    }

    onClick = (ev) => {
        const {  showCollectionDetails } = this.context;
        const { collection } = this.props;

        if (ev.button == 1 || ev.ctrlKey) {
            return;
        }

        ev.preventDefault();

        if (showCollectionDetails) {
            showCollectionDetails(collection);
        }
    }

    link = (element) => {
        const { collection } = this.props;

        return <Link to={`/collections/${collection.uuid}`} onClick={this.onClick}>{element}</Link>
    }

    render() {
        const { isPublisher, showResultsFavoriteBtn } = this.context;
        const { collection, onRemove } = this.props;
        const { content } = this.state;

        const imageProps = {};

        if (!collection) {
            return <span />
        }

        if (collection.image_thumb) {
            imageProps.style = {
                backgroundImage: 'url(data:image/jpeg;base64,' + collection.image_thumb + ')',
            };

            imageProps['data-has-thumb'] = true;
        }

        return (
            <div className="recipe-card collection-card">
                <div className="fan1"></div>
                <div className="fan2"></div>
                {this.link(
                    <ImgResized className="recipe-card-image collection-card-image" src={collection.image} width={300} height={300} {...imageProps} />
                )}

                {(collection.tags || []).includes('Grab & Go') ?
                    <div className="image-overlay" onClick={() => showCollectionDetails(collection)} >
                        Store Bought
                    </div>
                : null}

                {this.link(<div className="recipe-card-text">
                    <h2>Collection</h2>
                    <h3>{collection.title ? collection.title : <em>Untitled Collection</em>}</h3>
                    {content && content.author_name ? <h4>From {content.author_name}</h4> : <h4>&nbsp;</h4>}
                    {collection.items ? <h5><i className="icon-stacks" /> {collection.items.length} {isSingular(collection.items.length) ? 'recipe' : 'recipes'}</h5> : null}
                    {isPublisher ?
                        <div className={collection.status === 'live' ? 'publish-status live' : 'publish-status'}>
                            {collection.status === 'live' ? 'live' : 'draft'}
                        </div>
                    : null}
                </div>)}
                {isPublisher ?
                    <PublisherSubmenu resource={collection} />
                : null}
                {showResultsFavoriteBtn ?
                    <FavoriteButton collection={collection} />
                : null}
            </div>
        );
    }
}
