'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';
import PlanMealCounts from '../../Widgets/PlanMealCounts.react';
import ImgResized from '../../Widgets/ImgResized.react';

import './CollectionCard.scss';

export default class CollectionCard extends Component {
    static propTypes = {
        collection: PropTypes.object,


        showAdvancedMode: PropTypes.bool,
        shortMode: PropTypes.bool,
    };

    static defaultProps = {
        showAdvancedMode: false,
        shortMode: false,
    };

    static contextTypes = {
        showCollectionDetails: PropTypes.func,
    };

    onClick = (ev) => {
        const {  showCollectionDetails } = this.context;
        const { collection } = this.props;

        if (ev.button == 1 || ev.ctrlKey) {
            return;
        }

        ev.preventDefault();

        if (showCollectionDetails) {
            showCollectionDetails(collection);
        }
    }

    link = (element) => {
        const { collection } = this.props;

        return <Link to={`/collections/${collection.uuid}`} onClick={this.onClick}>{element}</Link>
    }

    render() {
        const { collection, showAdvancedMode, shortMode,
                onRemove } = this.props;

        if (!collection) {
            return <span />
        }

        const aspect = !shortMode
                     ? { w: 400, h: 500 }
                     : { w: 600, h: 600 };

        return (
            <div className="plan-card fan-collection-card" data-advanced={showAdvancedMode} data-short={shortMode}>
                <div className="plan-card-fan1"></div>
                <div className="plan-card-fan2"></div>
                <div className="plan-card-image-container">
                    {onRemove ?
                        <button className="delete-btn" onClick={onRemove} title="Remove meal plan">
                            <i className="icon-trash-can" />
                        </button>
                    : null}

                    {this.link(<ImgResized className="plan-card-image" src={collection.image} width={aspect.w} height={aspect.h} />)}

                    <div className="plan-card-contents">
                        {this.link(<div className="plan-card-text">
                            <div>
                                <h3>Recipe Collection</h3>

                                <section className="plan-size-info">
                                    {collection.title}
                                </section>
                            </div>
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}
