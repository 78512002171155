
'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import LocalStorage from 'store';
import assign from 'object-assign';
import indexBy from 'lodash.indexby';
import uuid from 'uuid';

import AuthStore from './AuthStore';
import UserStore from './UserStore';
import TaxonomyConstants from '../constants/TaxonomyConstants';

import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';
const LOCALSTORAGE_KEY = 'board-store-delta-queue';

let _store = {
    categories: {en: []},
    taxonomy: {en: []},
    index: {en: {}},
    keyIndex: {en: {}},
    loaded: {en: false},
    loading: {en: false},
};

function processLoadResponse(response, language = 'en') {
    if (!(response && response.elements)) {
        return;
    }

    _store.categories[language] = response.elements;
}

function loadTaxonomy(language = 'en') {
    return new Promise(async (accept, reject) => {
        const response = await AuthStore.fetch({url: getConfig('recipe_api') + '/categories', query: {size: 500, language}}, {}, true);

        processLoadResponse(response, language);

        accept();
    });
}

function updateIndex() {
    Object.keys(_store.categories).forEach(language => {
        const index = indexBy(_store.categories[language], 'uuid');
        const keyIndex = indexBy(_store.categories[language], 'category_key');
        const taxonomy = []; // contains only top level items.

        // Ensure each child has a children array
        // _store.categories[language].forEach(node => {
        //     node.children = [];
        // });

        _store.categories[language].forEach(node => {
            // Is this a top-level node? Put it in the taxonomy list.
            if (!node.parent_uuid) {

                if (!taxonomy.find(n => n.uuid === node.uuid)) {
                    taxonomy.push(node);
                }
            } else if (index[node.parent_uuid]) {
                // Find this node's parent and add it to the list of children.

                index[node.parent_uuid].children = index[node.parent_uuid].children || [];

                if (!index[node.parent_uuid].children.find(n => n.uuid === node.uuid)) {
                    index[node.parent_uuid].children.push(node);
                }
            }
        });

        const nodeSort = (a, b) => a.title.localeCompare(b.title);

        _store.categories[language].forEach(node => {
            if (!node.children) {
                return;
            }

            node.children.sort(nodeSort);
        })

        taxonomy.sort(nodeSort)

        _store.index[language] = index;
        _store.taxonomy[language] = taxonomy;
        _store.keyIndex[language] = keyIndex;
    });
}

var TaxonomyStore = assign({}, EventEmitter.prototype, {
    getCategories: function(language = 'en') {
        return _store.categories[language] || [];
    },

    getTaxonomy: function(language = 'en') {
        return _store.taxonomy[language] || [];
    },

    getUuidIndex: function(language = 'en') {
        return _store.index[language] || {};
    },

    getKeyIndex: function(language = 'en') {
        return _store.keyIndex[language] || {};
    },

    isLoaded: function(language = 'en') {
        return _store.loaded[language] || false;
    },

    isLoading: function(language = 'en') {
        return _store.loading[language] || false;
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
});

export default TaxonomyStore;

AppDispatcher.register((payload) => {
    let existing;

    const { actionType, language = 'en' } = payload.action;

    switch (actionType) {
        case TaxonomyConstants.TAXONOMY_ENSURE_LOADED:
            // Don't double-load, not necessary
            if (_store.loaded[language] || _store.loading[language]) {
                return;
            }

            _store.loading[language] = true;
            TaxonomyStore.emitChange();

            loadTaxonomy(language).then(() => {
                _store.loading[language] = false;
                _store.loaded[language] = true;
                updateIndex();
                TaxonomyStore.emitChange();
            });
            break;

        case TaxonomyConstants.TAXONOMY_UPSERT:
            const { category } = payload.action;
            _store.categories[language] = _store.categories[language] || [];
            existing = _store.categories[language].find(n => n.uuid === category.uuid);

            if (existing) {
                _store.categories[language][_store.categories[language].indexOf(existing)] = category;
            } else {
                _store.categories[language].push(category);
            }

            updateIndex();
            TaxonomyStore.emitChange();

            break;

        case TaxonomyConstants.TAXONOMY_DELETE:
            _store.categories[language] = _store.categories[language] || [];
            existing = _store.categories[language].find(n => n.uuid === category.uuid);

            if (existing) {
                _store.categories[language].splice(_store.categories[language].indexOf(existing), 1);
            }

            // Emit an event change (to update any listening clients about the 'deleted' flags)
            updateIndex();
            TaxonomyStore.emitChange();
            break;

    }
});
