'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';

import './NutrientEntry.scss';

export default class NutrientEntry extends Component {
    static propTypes = {
        disabled: PropTypes.bool,
        requiresUpgrade: PropTypes.bool,
        practice_type: PropTypes.string,
    };

    static defaultProps = {
        disabled: false,
        requiresUpgrade: false
    };

    static contextTypes = {
        showUpgradeForm: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            isDropdownVisible: false,
        };
    }

    container = null;
    dropdown = null;

    realizeContainer = (container) => {
        this.container = container;
    }

    realizeDropdown = (dropdown) => {
        this.dropdown = dropdown;
    }

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction  = (
            !this.container.contains(ev.target) &&
            !this.dropdown.contains(ev.target)
        );

        if (isOuterAction) {
            this.closeDropdown();
        }
    }

    closeDropdown = () => {
        this.setState({isDropdownVisible: false});

        if (!process.browser) {
            return;
        }

        window.removeEventListener('mousedown', this.onOuterAction);
        window.removeEventListener('touchstart', this.onOuterAction);
        window.removeEventListener('keyup', this.onOuterAction);
    }

    toggleDropdown = () => {
        const { isDropdownVisible } = this.state;

        this.setState({isDropdownVisible: !isDropdownVisible});

        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isDropdownVisible) {
            window.addEventListener('mousedown', this.onOuterAction);
            window.addEventListener('touchstart', this.onOuterAction);
            window.addEventListener('keyup', this.onOuterAction);

            this.refs.input.focus();
        } else {
            window.removeEventListener('mousedown', this.onOuterAction);
            window.removeEventListener('touchstart', this.onOuterAction);
            window.removeEventListener('keyup', this.onOuterAction);
        }
    }

    resolveConflict = (condition, value) => {
        const { resolveConflict } = this.props;

        resolveConflict(condition, value);

        this.closeDropdown();
    }

    renderDropdown = () => {
        const { conflict, label, nutrient, onChange } = this.props;

        if (!conflict) {
            return null;
        }

        return (
            <div className="drop-down" ref={this.realizeDropdown}>
                <ul>
                    {conflict.map((condition, i) => {
                        return (
                            <li key={i} onClick={() => this.resolveConflict(condition, condition[label])}>
                                <em>{condition.condition}</em> {condition[label]} {nutrient.Units}
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }

    render() {
        const { showUpgradeForm } = this.context;
        const { isDropdownVisible } = this.state;
        const { nutrient, value, label, className, onChange, conflict, tabIndex, isError, disabled, practice_type, requiresUpgrade } = this.props;

        const hasConflict = conflict && !value;
        const isEmpty = !value && value !== 0;

        return (
            <div className={classNames("entry-container nutrient-entry", className)}
                data-empty={isEmpty}
                data-conflict={hasConflict}
                data-dropdown={isDropdownVisible}
                ref={this.realizeContainer}
                onClick={requiresUpgrade ? () => showUpgradeForm({feature: 'edit_micros'}) : this.toggleDropdown}>
                <input type="number"
                    value={value}
                    ref="input"
                    min={0}
                    data-error={isError}
                    className="numeric-entry"
                    tabIndex={tabIndex}
                    disabled={disabled}
                    onChange={ev => onChange(ev.target.value)} />
                <span className="label">{label}</span>
                {this.renderDropdown()}
            </div>
        );
    }
}
