
'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import LocalStorage from 'store';
import assign from 'object-assign';
import debounce from 'lodash.debounce';
import omit from 'lodash.omit';
import moment from 'moment';
import chunk from 'lodash.chunk';
import indexBy from 'lodash.indexby';
import uuid from 'uuid';

import AuthStore from './AuthStore';
import UserStore from './UserStore';
import UserConstants from '../constants/UserConstants';
import BoardConstants from '../constants/BoardConstants';

import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';
const LOCALSTORAGE_KEY = 'board-store-delta-queue';

let _store = {
    boards: [],
    _indexByUuids: {},
    _resourceIndex: {},
    _loaded: false,
    _loading: false,
};

let _synchronizing = false;

function processLoadResponse(response) {
    if (!(response && response.elements)) {
        return;
    }

    response.elements.forEach(board => {
        // Is this board currently dirty in the stack? Don't overwrite it.
        const existingBoard = _store.boards.filter(m => m.uuid === board.uuid)[0];

        // Don't reload updating or dirty boards, that just means that the server hasn't gotten to them yet
        // or we haven't sent them to the server yet.
        if (existingBoard && existingBoard.updating) {
            return;
        }

        // Is this record currently being updated? Use that copy instead. Server will be updated later.
        const deltaQueue = (LocalStorage.get(LOCALSTORAGE_KEY) || []);
        const queuedUpdate = deltaQueue.filter(qboard => qboard.uuid === board.uuid)[0];

        if (queuedUpdate) {
            board = queuedUpdate;
        }

        // Re-index the boards and store them in memory
        if (typeof _store._indexByUuids[board.uuid] == 'number') {
            // Update the record
            _store.boards[_store._indexByUuids[board.uuid]] = board;
        } else if (existingBoard) {
            let i = _store.boards.indexOf(existingBoard);

            if (i != -1) { // This should never not be true
                _store.boards[i] = board;
                _store._indexByUuids[board.uuid] = i;
            }
        } else {
            // Store the record in the store and update the ID index.
            let len = _store.boards.push(board);

            _store._indexByUuids[board.uuid] = len - 1;
        }
    });
}

function loadBoards() {
    return AuthStore.fetch(UserStore.getLinks().boards).then(processLoadResponse);
}

function updateIndex() {
    const idx = {}, rIdx = {};

    _store.boards.forEach((board, i) => {
        idx[board.uuid] = i;

        (board.contents || []).forEach(content => {
            if (!rIdx[content.resource_id]) {
                rIdx[content.resource_id] = [];
            }

            rIdx[content.resource_id].push(board);
        });
    });

    _store._indexByUuids = idx;
    _store._resourceIndex = rIdx;
}

function sendBoardsToServer(boards) {
    const user = UserStore.getUser();

    // Don't try this in multi-threading land kids.
    if (_synchronizing || !user) {
        return;
    }

    _synchronizing = true;

    // Unset the dirty flag on all these right away (so if they change again
    // before we get a response back, they'll be updated again on the next bounce)
    boards.forEach(m => m.updating = true);

    const stripBoard = (board) => {
        return omit(board, 'links', 'created', 'last_updated', 'dietitian_recommendation', 'shared_with');
    };

    return AuthStore.fetch(UserStore.getLinks().boards, {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=collection/board/1'},
        body: JSON.stringify(boards.map(stripBoard))
    }).then(
        (results) => {
            // This must be turned off before firing the secondary syncs (because
            // they'll turn it back on). Thank gods for single threaded JS.
            _synchronizing = false;

            boards.forEach(m => delete m.updating);

            results = indexBy(results.elements, 'uuid');

            // Remove these boards from the delta queue, we don't want to send that update again
            let deltaQueue = LocalStorage.get(LOCALSTORAGE_KEY) || [];
            boards.forEach(board => {
                // We want to remove the FIRST instance of the board in the queue, not any more than that.
                const qboard = deltaQueue.filter(qboard => qboard.uuid === board.uuid)[0];

                if (qboard) {
                    deltaQueue.splice(deltaQueue.indexOf(qboard), 1);
                }

                // Update the local copies created & last_updated
                const i = _store._indexByUuids[board.uuid];
                _store.boards[i].links        = results[board.uuid].links;
                _store.boards[i].created      = results[board.uuid].created;
                _store.boards[i].last_updated = results[board.uuid].last_updated;
            });
            LocalStorage.set(LOCALSTORAGE_KEY, deltaQueue);

            // Are there any dirty board left in the queue? Immediately flush them (do not wait for debounce)
            realSynchronizeBoards();
        },
        (error) => {
            _synchronizing = false;

            // We're no longer updating the board, remove that flag.
            boards.forEach(m => delete m.updating);

            // Retry once after 5 seconds.
            setTimeout(realSynchronizeBoards, 5000);
        }
    );
}

function addItemsToBoard(board, items) {
    return AuthStore.fetch(getConfig('users_api') + board.links.self.href + '/contents', {
        method: 'POST',
        headers: {'Content-Type': 'application/json; schema=collection/boardContent/1'},
        body: JSON.stringify(items)
    });
}

function removeItemsFromBoard(board, items) {
    return AuthStore.fetch(getConfig('users_api') + board.links.self.href + '/contents', {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json; schema=multi/delete/1'},
        body: JSON.stringify({uuids: items.map(item => item.resource_id)}),
    });
}

function deleteBoardsByUuid(uuids) {
    const user = UserStore.getUser();

    if (!user) {
        return;
    }

    return AuthStore.fetch(UserStore.getLinks().boards, {
        method: 'DELETE',
        headers: {'Content-Type': 'application/json; schema=multi/delete/1'},
        body: JSON.stringify({uuids}),
    });
}

function realClearDeletedBoards() {
    // Remove these from our list
    _store.boards = _store.boards.filter(board => !board.deleted);

    // Update the index
    updateIndex();

    // Emit an event change
    BoardStore.emitChange();
}

function realSynchronizeBoards() {
    let dirtyBoards = (LocalStorage.get(LOCALSTORAGE_KEY) || []);

    if (dirtyBoards.length > 0) {
        sendBoardsToServer(dirtyBoards);
    }
}

function setDefaultBoard(uuid) {
    LocalStorage.set(LOCALSTORAGE_KEY + '-default', uuid);
}

function getDefaultBoard() {
    const boardUuid = LocalStorage.get(LOCALSTORAGE_KEY + '-default');

    if (_store._indexByUuids[boardUuid]) {
        return _store.boards[_store._indexByUuids[boardUuid]];
    }

    // Get the first board that isn't dietitian recommended
    const eligible = _store.boards.filter(board => !board.dietitian_recommendation);

    if (eligible[0]) {
        return eligible[0];
    }

    return {
        uuid: uuid.v4(),
        name: 'My Favorites',
        contents: [],
    };
}

const synchronizeBoards = debounce(realSynchronizeBoards, 500);
const clearDeletedBoards = debounce(realClearDeletedBoards, 5000);

var BoardStore = assign({}, EventEmitter.prototype, {
    getBoards: function() {
        return _store.boards;
    },

    isLoaded: function() {
        return _store.loaded;
    },

    isLoading: function() {
        return _store.loading;
    },

    getBoardById: function(boardUuid) {
        if (typeof _store._indexByUuids[boardUuid] !== 'undefined') {
            return _store.boards[_store._indexByUuids[boardUuid]];
        }

        return false;
    },

    getDefaultBoard: function() {
        return getDefaultBoard();
    },

    getBoardsByResourceId: function(resourceId) {
        return (_store._resourceIndex[resourceId] || []).filter(b => !b.deleted);
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener: function(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener: function(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    }
});

BoardStore.setMaxListeners(100);

export default BoardStore;

AppDispatcher.register((payload) => {
    let boards = [], board = {}, items = [];
    let promise = null;

    switch (payload.action.actionType) {
        case UserConstants.USER_LOGOUT:
            _store.loaded = false;
            _store.loading = false;
            _store.boards = [];
            BoardStore.emitChange();
            break;

        case UserConstants.USER_COMPLETE_LOGIN:
            if (boards = payload.action.boards) {
                _store.loaded = true;
                _store.loading = false;
                _store.boards = boards;
                updateIndex();
                BoardStore.emitChange();
            }
            break;

        case BoardConstants.BOARD_LOAD:
            // Don't double-load, not necessary
            if (_store.loaded || _store.loading) {
                return;
            }

            _store.loading = true;

            loadBoards().then(() => {
                _store.loading = false;
                _store.loaded = true;
                updateIndex();
                BoardStore.emitChange();
            });
            break;

        case BoardConstants.BOARD_UPSERT:
            boards = payload.action.boards;

            if (boards[0]) {
                setDefaultBoard(boards[0].uuid);
            }

            // And add them to the delta queue if they're not there already
            let deltaQueue = (LocalStorage.get(LOCALSTORAGE_KEY) || [])
            boards.forEach(board => {
                // Find this board by UUID in the deltaQueue
                const qboard = deltaQueue.filter(qboard => qboard.uuid === board.uuid)[0];

                // Append to queue if: not already queued OR queued and already sent to server
                if (!qboard || (qboard && qboard.updating)) {
                    deltaQueue.push(board);
                } else {
                    // Otherwise just update the existing entry (to be extra sure its in there)
                    const i = deltaQueue.indexOf(qboard);
                    deltaQueue[i] = board;
                }
            });
            LocalStorage.set(LOCALSTORAGE_KEY, deltaQueue);

            // Update the index
            updateIndex();

            // Prepend any new items to the boards store and update existing ones
            // (even if the object itself has changed)
            const boardsToAdd = [];
            boards.forEach(board => {
                if (typeof _store._indexByUuids[board.uuid] == 'number') {
                    _store.boards[_store._indexByUuids[board.uuid]] = board;
                } else {
                    boardsToAdd.push(board);
                }
            });
            _store.boards = boardsToAdd.concat(_store.boards);

            // Update the index
            updateIndex();

            // Emit an event change
            BoardStore.emitChange();

            // And trigger a sync to the server
            synchronizeBoards();
            break;

        case BoardConstants.BOARD_DELETE:
            boards = payload.action.boards;

            // Reduce to an array of board UUIDs
            const uuids = boards.map(b => b.uuid);

            const defaultBoard = getDefaultBoard();

            if (uuids.indexOf(defaultBoard.uuid) != -1) {
                setDefaultBoard(null);
            }

            // Set the 'deleted' flag
            boards.forEach(board => board.deleted = true);

            // Tell the server to delete immediately
            deleteBoardsByUuid(uuids);

            // After 5 seconds of not deleting boards, remove everything still deleted from the local list.
            clearDeletedBoards();

            // Emit an event change (to update any listening clients about the 'deleted' flags)
            updateIndex();
            BoardStore.emitChange();
            break;

        case BoardConstants.BOARD_ADD_ITEM:
            // Find the original board in our store
            board = _store.boards[_store._indexByUuids[payload.action.board.uuid]];

            // #1 - Fire the AJAX call to add the item to the board
            addItemsToBoard(board, payload.action.items);

            // #2 - Add the item to the boards contents locally
            const existingIds = (board.contents || []).map(content => content.resource_id);
            items = payload.action.items.filter(item => existingIds.indexOf(item.resource_id) === -1);

            board.contents = (board.contents || []).concat(items);
            setDefaultBoard(board.uuid);

            // #3 - fire emitChange();
            updateIndex();
            BoardStore.emitChange();

            break;

        case BoardConstants.BOARD_UPSERT_ITEM:
            // Find the original board in our store
            board = _store.boards[_store._indexByUuids[payload.action.board.uuid]];

            // #1 - Fire the AJAX call to upsert the items to the board
            addItemsToBoard(board, payload.action.items);

            // #2 - Remove the items from the board locally
            const itemsToAddIds = (payload.action.items || []).map(content => content.resource_id);
            board.contents = (board.contents || []).filter(item => itemsToAddIds.indexOf(item.resource_id) === -1);

            board.contents = (board.contents || []).concat(payload.action.items);
            setDefaultBoard(board.uuid);

            // #3 - fire emitChange();
            updateIndex();
            BoardStore.emitChange();

            break;

        case BoardConstants.BOARD_REMOVE_ITEM:
            // Find the original board in our store
            board = _store.boards[_store._indexByUuids[payload.action.board.uuid]];

            // #1 - Fire the AJAX call to add the item to the board
            removeItemsFromBoard(board, payload.action.items);

            const resourceIds = payload.action.items.map(item => item.resource_id);
            // #2 - Add the item to the boards contents locally
            board.contents = (board.contents || []).filter(item => resourceIds.indexOf(item.resource_id) === -1);

            // #3 - fire emitChange();
            updateIndex();
            BoardStore.emitChange();
            break;

        case BoardConstants.UPDATE_NOSYNC:
            // Do we have the board already in our store?
            boards = payload.action.boards || [];

            boards.forEach(updatedBoard => {
                const index = _store._indexByUuids[updatedBoard.uuid];
                const oldBoard = _store.boards[index];

                if (oldBoard) {
                    // Find the list of updatedBoard.contents that needs to be inserted into the existing contents
                    const toInsert = updatedBoard.contents.filter(c => {
                        return !oldBoard.contents.filter(d => c.resource_id == d.resource_id)[0];
                    });

                    _store.boards[index] = {
                        ...oldBoard,
                        ...updatedBoard,
                        contents: oldBoard.contents.concat(toInsert),
                    };
                } else {
                    _store.boards.push(updatedBoard);
                }
            });

            updateIndex();
            BoardStore.emitChange();
            break;
    }
});
