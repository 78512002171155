import { Component } from 'react';
import PropTypes from 'prop-types';
import { getNutrientsToDisplay } from "../../../pro/utils/Patients";
import { nutrNoSortCmp } from '../../../utils/Nutrition';
import { roundForHumans } from '../../../utils/Math';
import allNutrients from '../../../tables/nutrients';
import Modal from 'react-modal';
import './ConfirmNutritionModal.scss';

export default class ConfirmNutritionModal extends Component {
    static propTypes = {
        user: PropTypes.object,
        food: PropTypes.object,
        onRequestClose: PropTypes.func,
        onClose: PropTypes.func,
        onOk: PropTypes.func,
        onCancel: PropTypes.func,
        okBtnText: PropTypes.string,
        cancelBtnText: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
        }
    }


    onClickOkBtn = () => {
        const { onOk } = this.props;

        this.setState({loading: true});

        onOk();
    }

    render() {
        const { loading } = this.state;
        const { user, food, okBtnText, cancelBtnText, onCancel, onClose } = this.props;
        const { nutrients } = food;
        const { values } = nutrients;
        const toDisplay = getNutrientsToDisplay({...user, portion: 1}, values);
        const entered = Object.keys(toDisplay).filter(nutrNo => typeof toDisplay[nutrNo].v === 'number');
        const excludeNutrNos = ['208'];
        return (
            <Modal isOpen={true}
                onRequestClose={onClose}
                closeModal={onClose}
                contentLabel="Confirm"
                className="confirm-nutrition-modal"
                overlayClassName="feed-modal-overlay confirm-nutrition-overlay"
                closeTimeoutMS={250}>
                <div className="confirm-nutrition-modal-container">
                    <header>
                        <button className="close-modal-btn" onClick={onClose}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>
        
                    <section className="confirm-nutrition-body">
                        <div className='message-container'>
                            <p className='done-message'>You're almost done!</p>
                            <p className='compare-message'>Compare nutrition info with your food label. Click 'Edit Nutrition' to correct.</p>
                        </div>
                        <h3 data-testid="food-title">{food && food.brand_name ? <em>{food.brand_name}&nbsp;</em> : null}{food.name}</h3>
        
                        {entered.length == 0 ?
                            <div className="nutrients-in-portions">
                                <div className="cal">
                                    <h4>&mdash;</h4>
                                    <h5>calories</h5>
                                </div>
        
                                <ul className="rest">
                                    <li>This food's nutrition has not been entered yet.</li>
                                </ul>
                            </div>
                        : null}
        
                        {entered.length > 0 ?
                            <div className="nutrients-in-portions">
                                <div className="cal" data-testid="calories">
                                    <h4>{typeof values[208] === 'number' && values[208] !== Infinity && !Number.isNaN(values[208]) ?
                                        roundForHumans(values[208]) : <span>&mdash;</span>}</h4>
                                    <h5>calories</h5>
                                </div>
        
                                <ul className="rest">
                                    {Object.keys(toDisplay).filter(nutrNo => !excludeNutrNos.includes(nutrNo))
                                                           .sort(nutrNoSortCmp)
                                                           .map((nutrNo, i) => {
                                        const nutr = toDisplay[nutrNo];
        
                                        return (
                                            <li key={i} data-nutrno={nutrNo} data-testid={`nutrient${nutrNo}`}>
                                                <em>{allNutrients[nutrNo].NutrDesc}</em><span>{typeof nutr.v === 'number' && nutr.v !== Infinity && !Number.isNaN(nutr.v) ?
                                                roundForHumans(nutr.v) : <span>&mdash;</span>} {allNutrients[nutrNo].Units}</span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        : null}
                    </section>
        
                    <footer>
                        <button disabled={false} className="cancel-btn" onClick={onCancel}>
                            <span>{cancelBtnText}</span>
                        </button>
                        <button disabled={false} className="ok-btn" onClick={this.onClickOkBtn}>
                            {
                                <span>{loading ? <i className='icon-spinner'/> : okBtnText}</span>
                            }
                        </button>
                    </footer>
                </div>
            </Modal>
        )

    }
}