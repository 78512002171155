'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Browser from '../../Browser.react';
import SearchKeywords from '../../Filters/SearchKeywords.react';
import Analytics from '../../../../utils/Analytics';

import './Favorites.scss';
import './FullBrowser.scss';

export default class Favorites extends Component {

    static contextTypes = {
        user: PropTypes.object
    };

    static childContextTypes = {
        hideProductTypeButtons: PropTypes.bool,
        user: PropTypes.object
    };

    constructor(props, context) {
        super(props, context);

        const { user } = this.context;

        this.state = {
            isAdvancedFiltersOpen: false,
            params: {
                terms: "",
                sort_by: "favorited",
                types: props.allowedTypes.slice(),
                filters: {
                    library: true,
                },
                include_merchants: user?.features?.source_libraries || null,
                source: "Favorites"
            },
        };
    }

    getChildContext = () => {
        return {
            hideProductTypeButtons: true
        };
    }

    onShowAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: true});
    }

    onHideAdvancedFilters = () => {
        this.setState({isAdvancedFiltersOpen: false});
    }

    onExecuteSearch = (params) => {
        Analytics.favoritesSearch(params.terms, params.filters, params.sort_by);
    }

    onChangeParams = (params) => {
        const { onChangeParams } = this.props;

        this.setState({params});
        onChangeParams && onChangeParams(params);
    }

    render() {
        const { profile, allowedTypes } = this.props;
        const { params, isAdvancedFiltersOpen } = this.state;

        return (
            <div className="add-swap-full-browser add-swap-favorites">
                <header>
                    <SearchKeywords params={params} onChangeParams={this.onChangeParams} className="full-browser-keyword-search" placeholder="Search favorites" />
                    <button className="advanced-filters-btn" onClick={this.onShowAdvancedFilters}>
                        <i className="feather feather-filter" />
                    </button>
                </header>

                <Browser contextName="Favorites"
                    params={params} profile={profile}
                    onChangeParams={this.onChangeParams}
                    allowedTypes={allowedTypes}
                    showTypePicker={true}
                    hideLibraryFilter={true}
                    onExecuteSearch={this.onExecuteSearch}
                    onShowAdvancedFilters={this.onShowAdvancedFilters}
                    onHideAdvancedFilters={this.onHideAdvancedFilters}
                    isAdvancedFiltersOpen={isAdvancedFiltersOpen} />
            </div>
        );
    }
}
