'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';
import { toHuman } from '../../utils/Duration';
import UserStore from '../../stores/UserStore';

import './RecipeInstructions.scss';

export default class RecipeInstructions extends Component {
    static contextTypes = {
        isCordova: PropTypes.bool,
        showLoginForm: PropTypes.func,
    };

    static propTypes = {
        user: PropTypes.object,
        recipe: PropTypes.object,
        details: PropTypes.object,
        isMeal: PropTypes.bool,
    };

    renderInstruction = (instruction, j) => {
        return (
            <li key={j}>
                <div className="instruction-step-container">
                    <header>{j + 1}.</header>
                    <section>
                        {instruction.text}
                    </section>
                </div>
            </li>
        );
    }

    renderInstructionGroup = (group, i) =>  {
        return (
            <ol key={i}>
                {group.title ? <li><h5>{group.title}</h5></li> : ''}
                {group.items.map(this.renderInstruction)}
            </ol>
        );
    }


    renderSubrecipe = (subrecipe, i) => {
        const { subdetails } = this.props;
        const subdetail = subdetails && subdetails[subrecipe.details];

        if (!subdetail) {
            return;
        }

        return (
            <div className="subrecipe" key={i}>
                <h2>{subrecipe.title}</h2>

                {subdetail.preparation.map(this.renderInstructionGroup)}
            </div>
        );
    }

    render = () => {
        const { recipe, details, isMeal } = this.props;
        const { isCordova, showLoginForm } = this.context;

        const user = UserStore.getUser();
        const { capabilities = {} } = user || {};

        if (!(recipe && details && details.preparation)) {
            return <span />
        }

        if (!user && !(recipe.public_instructions && recipe.protection === 'public')) {
            return (
                <section className="subscribe-for-instructions">
                    <p>
                    Please sign in or sign up for a free trial to access your favorite recipes in our delicious meal plans.
                    </p>

                    <footer>
                        <Link to="/new-account"><button>Sign-Up</button></Link>

                        <p>Already have an account?</p>
                        <button className="sign-in" onClick={showLoginForm}>Sign-in</button>
                    </footer>
                </section>
            );
        }

        if (recipe.protection != 'public' && !capabilities.meal_planner) {
            if (user.my_dietitian) {
                return (
                    <section className="subscribe-for-instructions">
                        <p>We're very sorry, but you're account has expired.</p>
                        <p>Please contact your {user.practice_type === 'dietetics' ? 'dietitian' : 'personal trainer'} to proceed.</p>
                        <p>If you believe this to be in error, please email us at <a href="mailto:support@eatlove.is?subject=My account has expired">support@eatlove.is</a></p>
                    </section>
                );
            }

            return (
                <section className="subscribe-for-instructions">
                    <p>
                    Don&apos;t miss out on all the fantastic features at your finger tips. When you join EatLove you get access to our full library of meal plans complete with grocery list, nutrition insights.
                    </p>

                    <footer>
                        <Link to="/membership"><button>Subscribe to EatLove</button></Link>
                    </footer>
                </section>
            );
        }

        return (
            <div className="recipe-instructions-container">
                {isMeal ? 
                     <h6>METHOD</h6>
                : null}

                <div className="time-header">
                {recipe.hands_on_time ?
                    <p>prep: <strong>{toHuman(recipe.hands_on_time, true)}</strong></p>
                : null}
                {recipe.hands_off_timer ?
                    <p> cook: <strong>{toHuman(recipe.hands_off_timer, true)}</strong></p>
                : null}
                {recipe.overnight_time ?
                    <p> overnight: <strong>{toHuman(recipe.overnight_time, true)}</strong></p>
                : null}
                </div>
                {details.preparation.filter(group => group.items.some(item => item.text)).map(this.renderInstructionGroup)}

                {(recipe.subrecipes || []).map(this.renderSubrecipe)}
            </div>
        );
    }
}
