'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const totalTimeFilters = [
    {label: 'Any Time',             v: false},
    {label: 'Under 15 minutes',          v: {lte: 900}},
    {label: 'From 15 to 30 minutes', v: {gte: 901,  lte: 1800}},
    {label: 'From 30 to 45 minutes', v: {gte: 1801, lte: 2700}},
    {label: 'More than 45 minutes',      v: {gte: 2701}},
];

export default class totalTime extends Component {
    static propTypes = {
    };

    static contextTypes = {
        setParamsToRecipeOnly: PropTypes.func,
    };

    onChangeTotalTime = (totalTime) => {
        const { params, onChangeParams } = this.props;

        if (totalTime.v) {
            params.filters.total_time = totalTime.v;
        } else {
            delete params.filters.total_time;
        }

        onChangeParams(params);
    }

    getValueFromParams = (params) => {
        let index = 0, label = totalTimeFilters[0].label;

        if (!(params && params.filters && params.filters.total_time)) {
            return {index, label};
        }

        const totalTimeParam = params.filters.total_time || {};

        const selectedTotalTime = totalTimeFilters.forEach((totalTime, i) => {
            if (!totalTime.v) {
            } else if (totalTime.v.lte && totalTime.v.gte &&
                totalTime.v.lte == totalTimeParam.lte &&
                totalTime.v.gte == totalTimeParam.gte) {
                index = i;
                label = totalTime.label;
            } else if (totalTime.v.lte && !totalTime.v.gte &&
                !totalTimeParam.gte &&
                totalTimeParam.lte == totalTime.v.lte) {
                index = i;
                label = totalTime.label;
            } else if (!totalTime.v.lte && totalTime.v.gte &&
                totalTimeParam.gte == totalTime.v.gte &&
                !totalTimeParam.lte) {
                index = i;
                label = totalTime.label;
            }

        });

        return { index, label };
    }

    render() {
        const { params } = this.props;
        const { index, label } = this.getValueFromParams(params);

        return (
            <div className="global-search-tag-group">
                <ul>
                    {totalTimeFilters.map((totalTime, i) => {
                        return (
                            <li key={i}>
                                <label className="global-search-checkbox global-search-radio">
                                    <input type="checkbox" checked={i === index}
                                           onChange={e => this.onChangeTotalTime(totalTime)} />
                                    <span>{totalTime.label}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
