'use strict'

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { priceFormat, getInvoiceBreakdown, getInvoiceChargeCard } from '../../utils/Invoice';

import './Invoice.scss';

export default class Invoice extends Component {
    static propTypes = {
        invoice: PropTypes.object,
        confirmText: PropTypes.node,
        addTraining: PropTypes.func,
        removeTraining: PropTypes.func,
        allowAddOnHourly: PropTypes.bool,
        isConfirmed: PropTypes.bool,
        onChangeCoupon: PropTypes.func,
        onChangeCard: PropTypes.func,
        setAddCouponMode: PropTypes.func,
        addCouponMode: PropTypes.bool,
        coupon: PropTypes.any,
        creditCard: PropTypes.object,
    };

    static defaultProps = {
        allowAddCoupon: false,
        allowAddOnHourly: false,
        addCouponMode: false,
        isConfirmed: false,
    };


    renderAddOn = () => {
        const { addTraining, permit } = this.props;

        const addOn = {
            title: "Add One-on-One EatLove Pro Training session to your order.",
            description: "We'll show you how to integrate EatLove into your practice for maximum return on investment while learning our products best features to take your clients and business to the next level.",
            time: "1 Hour",
            price: "$" + Math.round(permit.hourly_training / 100),
        };

        return (
            <div className="add-on-container">
                <div>
                    <p>{addOn.title}</p>
                    <p>{addOn.description}</p>
                </div>
                <span className="bottom-add-on-container">
                    <header><p>{addOn.time}</p><p>x</p><p>{addOn.price}</p></header>
                    <footer><button onClick={addTraining} className="add-on-button">Add On</button></footer>
                </span>
            </div>
        );
    }


    displayCoupon = (coupon) => {
        if (!coupon) {
            return;
        }

        if (coupon.id === 'give20_get20') {
            return 'Referral Discount';
        }

        return coupon.name || coupon.id || 'Discount';
    }

    render = () => {
        const { invoice, removeTraining, isConfirmed, allowAddOnHourly, onAddCoupon, onChangeCoupon, onChangeCard, setAddCouponMode, addCouponMode, coupon, creditCard} = this.props;
        const { charge } = invoice || {};
        const breakdown = invoice ? getInvoiceBreakdown(invoice) : null;
        const card = invoice ? getInvoiceChargeCard(invoice) : null;

        return (
                <div>
                    {!isConfirmed && breakdown.mode === 'refund' ?
                        <p className="refund-confirmation">
                            This receipt is to confirm that your refund has been issued by EatLove. It can take 10+
                            days to appear on your statement, if it takes longer please contact your bank for assistance.
                        </p>
                    : null}

                    <table className="invoice-items">
                        <thead>
                            {!isConfirmed && charge ?
                                <tr className="invoice-ident">
                                    <th>{moment.unix(charge.created).format('MMMM Do, YYYY')}</th>
                                    <th className="text-right">
                                        {breakdown.receipt_number ? '#' + breakdown.receipt_number : null}
                                    </th>
                                </tr>
                            : null}
                            <tr>
                                <th>Description</th>
                                <th className="amount-col">Amount</th>
                            </tr>
                        </thead>

                        <tbody>

                        {invoice.lines && invoice.lines.data && invoice.lines.data.map((item, i) => (
                            <tr key={i}>
                                <td>
                                    {item.type === 'invoiceitem' ? `${item.quantity > 1 ? item.quantity + ' × ': ''}${item.description}`: null}
                                    {item.type === 'subscription' ? <span>{item.description}</span> : null}
                                    {!isConfirmed && item.price && item.price.id && item.price.id.startsWith('pro_training') ? <button onClick={removeTraining}>remove</button> : null}
                                </td>
                                <td className="amount-col">${priceFormat(item.amount, true)}</td>
                            </tr>
                        ))}
                        <tr/>
                        </tbody>


                        <tfoot>
                            {invoice.starting_balance ?
                                <tr>
                                    <td>{invoice.starting_balance < 0 ? 'Store Credit' : 'Previous Balance'}</td>
                                    <td>${priceFormat(invoice.starting_balance, true)}</td>
                                </tr>
                            : null}

                            {breakdown.subtotal && breakdown.subtotal != breakdown.total ?
                                <tr>
                                    <td>Subtotal</td>
                                    <td>${priceFormat(breakdown.subtotal, true)}</td>
                                </tr>
                            : null}

                            {breakdown.discount ?
                                <tr>
                                    <td>Discount</td>
                                    <td>${priceFormat(breakdown.discount, true)}</td>
                                </tr>
                            : null}

                            {breakdown.coupon ?
                                <tr>
                                    <td colSpan={2} className="coupon-breakdown">
                                        {this.displayCoupon(breakdown.coupon)}&nbsp;
                                        {breakdown.coupon.percent_off ?
                                            <span>({breakdown.coupon.percent_off}% off)</span>
                                        : null}
                                        {breakdown.coupon.amount_off ?
                                            <span>(${priceFormat(breakdown.coupon.amount_off)} off)</span>
                                        : null}
                                    </td>
                                </tr>
                            : null}

                            {breakdown.tax ?
                                <tr>
                                    <td>Tax</td>
                                    <td>${priceFormat(invoice.tax, true)}</td>
                                </tr>
                            : null}

                            <tr>
                                <td><em>Total</em></td>
                                <td>${priceFormat(breakdown.total, true)}</td>
                            </tr>

                            {breakdown.amount_due ?
                                <tr>
                                    <td><em>Amount Due</em></td>
                                    <td>${priceFormat(invoice.amount_due, true)}</td>
                                </tr>
                            : null}

                            {breakdown.amount_refunded ?
                                <tr>
                                    <td><em>Refund Amount</em></td>
                                    <td>${priceFormat(breakdown.amount_refunded, true)}</td>
                                </tr>
                            : null}

                            {breakdown.adjusted_total ?
                                <tr>
                                    <td><em>Adjusted Total</em></td>
                                    <td>${priceFormat(breakdown.adjusted_total, true)}</td>
                                </tr>
                            : null}

                            {breakdown.paid ?
                                <tr>
                                    <td><em>Amount Paid</em></td>
                                    <td className="big-number">${priceFormat(breakdown.paid, true)}</td>
                                </tr>
                            : null}

                            {!breakdown.coupon && !isConfirmed && !addCouponMode ?
                                <tr>
                                    <td colSpan={2} className="add-a-coupon">
                                        <button className="add-coupon-btn" onClick={setAddCouponMode}>Add a Coupon</button>
                                    </td>
                                </tr>
                            : null}

                            {addCouponMode ?
                                <tr>
                                    <td colSpan={2} className="add-a-coupon">
                                        <input type="text" value={coupon} onChange={onChangeCoupon} placeholder="Coupon Code" />
                                    </td>
                                </tr>
                            : null}
                            {creditCard ?
                                <tr>
                                    <td>
                                        <div className="card-outline">
                                            <div className="card-chip"/>
                                            <div className="card-strip"/>
                                        </div>
                                        <div className="paying-from">
                                            <p>{"Paying with "}</p>
                                            <p>{creditCard.brand} ending in {creditCard.last4}</p>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                         <span className="change-card-btn" onClick={onChangeCard}>Change</span>
                                        </div>
                                    </td>
                                </tr>
                            : null}
                        </tfoot>
                    </table>

                    {!isConfirmed && allowAddOnHourly ? this.renderAddOn() : null}

                </div>
        );
    }
}
