'use strict';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import Select from '../../../pro/components/Widgets/Select.react';
import Popup from '../../Widgets/Popup.react';
import EditInstructions from './EditInstructions.react';

import './EditInstructionGroup.scss';

export default class EditInstructionGroups extends Component {

    static propTypes = {
        recipe: PropTypes.object,
        details: PropTypes.object,

        group: PropTypes.object,
        groupIndex: PropTypes.number,

        onChangeRecipe: PropTypes.func,
        onChangeDetails: PropTypes.func,
    };

    static contextTypes = {
        confirm: PropTypes.func,
    };

    constructor(props) {
        super(props);
         this.state = {
            titleFocused: false,
        };
        this.instructionsDropdown = createRef();
    }


    onChangeInstructions = (group, i) => {
        const { details, onChangeDetails } = this.props;

        details.preparation[i] = group;

        onChangeDetails(details, true);
    }


    onChangeGroupTitle = (ev) => {
        const { groupIndex, details, onChangeDetails } = this.props;

        details.preparation[groupIndex].title = ev.target.value;

        onChangeDetails(details, true);
    }

    onAddInstructionGroup = () => {
        const { groupIndex, addInstructionGroup } = this.props;
        this.instructionsDropdown.current.closeDropdown();

        addInstructionGroup(groupIndex);
    }

    onDeleteIngredientGroup = () => {
        const { groupIndex, deleteInstructionGroup } = this.props;
        const { confirm } = this.context;

        this.instructionsDropdown.current.closeDropdown();

        confirm(
            <div>
                <h2>Are you sure you want to delete this instructions group?</h2>
                <p>This cannot be undone</p>
            </div>,
            accept => deleteInstructionGroup(groupIndex),
            reject => false,
            {acceptText: 'Delete Group', rejectText: 'Cancel'}
        );
    }

    onEditIngredientGroupName = () => {
        this.nameInput.focus();
        this.instructionsDropdown.current.closeDropdown();
    }

    render = () => {
        const { group, groupIndex, deleteInstructionGroup } = this.props;
        const { titleFocused } = this.state;

        return (
            <div className="edit-recipe-group">
                <header>
                    <input className="group-title" onFocus={() => this.setState({titleFocused: true})} onBlur={() => this.setState({titleFocused: false})} ref={(input) => { this.nameInput = input; }} type="text" value={group.title || ''} onChange={this.onChangeGroupTitle} placeholder={ titleFocused ?  "Enter instruction group name" : `Instructions ${groupIndex ?  groupIndex + 1 : ""}`} />

                    <Popup positionClassName="el-popup-right-top-center" className="group-menu" ref={this.instructionsDropdown} button={<i className="icon-ellipsis2" />}>
                        <div>
                            <h4>Instruction Group Options</h4>

                            <footer>
                                <button className="delete-group-btn" onClick={this.onAddInstructionGroup}>add instructions</button>
                                <button className="delete-group-btn" onClick={()=> {this.onEditIngredientGroupName()}}>edit name</button>
                                {deleteInstructionGroup ?
                                    <button className="delete-group-btn" onClick={this.onDeleteIngredientGroup}>delete instructions</button>
                                : null}
                            </footer>
                        </div>
                    </Popup>

                </header>

                <EditInstructions group={group}
                    onChangeInstructions={(group) => this.onChangeInstructions(group, groupIndex)} />
            </div>
        );
    }
}
