'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import Popup from '../../Widgets/Popup.react';
import Orders from '../../MealDetails/Orders.react';

import './OrdersPopup.scss';

export default class OrdersPopup extends Component {
    static propTypes = {
        orders: PropTypes.array.isRequired,
    };

    render = () => {
        const { orders, children } = this.props;

        return (
            <Popup {...this.props}>
                <Orders orders={orders}>
                    {children}
                </Orders>
            </Popup>
        );
    }
}
