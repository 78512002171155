'use strict'

// File generated by bin/sync-static-tables.php
// DO NOT EDIT! (Edit the original in recipe/config/table/diets.php)

export default [
    {
        "name": "Vegetarian (Lacto-ovo)",
        "prop": "vegetarian",
        "envelope": [],
        "description": "Dairy + eggs OK",
        "avoidances": [
            "beef",
            "game_meats",
            "fish",
            "lamb",
            "pork",
            "poultry",
            "shellfish"
        ]
    },
    {
        "name": "Lacto-Vegetarian",
        "prop": "lacto-vegetarian",
        "envelope": [],
        "description": "Dairy OK",
        "avoidances": [
            "beef",
            "game_meats",
            "eggs",
            "fish",
            "lamb",
            "pork",
            "poultry",
            "shellfish"
        ]
    },
    {
        "name": "Ovo-Vegetarian",
        "prop": "ovo-vegetarian",
        "envelope": [],
        "description": "Eggs OK",
        "avoidances": [
            "beef",
            "game_meats",
            "dairy",
            "fish",
            "lamb",
            "pork",
            "poultry",
            "shellfish"
        ]
    },
    {
        "name": "Vegan",
        "prop": "vegan",
        "envelope": [],
        "avoidances": [
            "beef",
            "game_meats",
            "eggs",
            "fish",
            "lamb",
            "dairy",
            "pork",
            "poultry",
            "shellfish"
        ]
    },
    {
        "name": "Pescatarian",
        "prop": "pescatarian",
        "envelope": [],
        "avoidances": [
            "beef",
            "game_meats",
            "lamb",
            "pork",
            "poultry"
        ]
    }
];
