'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import BoardStore from '../../../stores/BoardStore';

import { isSingular } from '../../../utils/Math';
import { toHuman } from '../../../utils/Duration';

import './CategoryCard.scss';
import './RecipeCard.scss';

export default class CategoryCard extends Component {
    static propTypes = {
        category: PropTypes.object,

        isLogging: PropTypes.bool,
    };

    static contextTypes = {
        showResultsFavoriteBtn: PropTypes.bool,
        showCategoryDetails: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        this.syncFromBoards(this.props.category);
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {

        if (nextProps.category !== this.props.category) {
            this.syncFromBoards(nextProps.category);
        }
    }

    syncFromBoards = (category) => {
        if (!(category && category.uuid)) {
            this.setState({content: null});
            return;
        }

        const boards = BoardStore.getBoardsByResourceId(category.uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === category.uuid
            );

            return carry.concat(bcs);
        }, []);

        const content = contents[0];

        this.setState({content});
    }

    onClick = (ev) => {
        const {  showCategoryDetails } = this.context;
        const { category } = this.props;

        if (ev.button == 1 || ev.ctrlKey) {
            return;
        }

        ev.preventDefault();

        if (showCategoryDetails) {
            showCategoryDetails(category);
        }
    }

    link = (element) => {
        const { category } = this.props;

        return <span onClick={this.onClick}>{element}</span>
    }

    render() {
        const { isPublisher, showResultsFavoriteBtn } = this.context;
        const { category, onRemove } = this.props;
        const { content } = this.state;

        const imageProps = {};

        if (!category) {
            return <span />
        }

        if (category.image_thumb) {
            imageProps.style = {
                backgroundImage: 'url(data:image/jpeg;base64,' + category.image_thumb + ')',
            };

            imageProps['data-has-thumb'] = true;
        }

        return (
            <div className="recipe-card category-card">
                {category.image
                    ? this.link(<ImgResized className="recipe-card-image category-card-image" src={category.image} width={300} height={300} {...imageProps} /> )
                    : <div className="recipe-card-image category-card-image">
                        <i className="icon-logo2"><span className="path1" /><span className="path2" /></i>
                      </div>
                }

                {this.link(<div className="recipe-card-text">
                    <h2>Category</h2>
                    <h3>{category.title ? category.title : <em>Untitled Category</em>}</h3>
                    {content && content.author_name ? <h4>From {content.author_name}</h4> : <h4>&nbsp;</h4>}
                    {isPublisher ?
                        <div className={category.status === 'live' ? 'publish-status live' : 'publish-status'}>
                            {category.status === 'live' ? 'live' : 'draft'}
                        </div>
                    : null}
                </div>)}

                {onRemove ?
                    <button className="delete-btn" onClick={onRemove} title="Remove meal plan">
                        <i className="icon-trash-can" />
                    </button>
                : null}

                {isPublisher ?
                    <PublisherSubmenu resource={category} />
                : null}
                {showResultsFavoriteBtn ?
                    <FavoriteButton category={category} />
                : null}
            </div>
        );
    }
}
