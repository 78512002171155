'use strict';

export default {
    'Anemia B12 Deficient': {
        heading: 'Vitamin B12 is a key nutrient required for energy production. We ensure you meet your needs through food.',
    },
    'Anemia Iron Deficient': {
        heading: 'Iron helps our bodies store and use oxygen. We ensure you meet your needs through food.',
    },
    'Celiac Disease': {
        heading: 'We design delicious and balanced plans free of any wheat, barley, and rye.',
    },
    'Diverticulosis': {
        heading: 'Fiber is essential for proper digestion and helps us feel full. We ensure a minimum of 25 grams/day.',
    },
    'High Cholesterol': {
        heading: 'We create plans low in saturated fat, and full of fiber and heart-healthy fats.',
    },
    'Osteoporosis': {
        heading: 'We create delicious and balanced plans with adequate calcium for optimal bone health.',
    },
    'Overweight\/Obesity': {
        heading: 'We design portion-controlled plans with nutrient-dense foods to match your energy needs for healthy weight loss.',
    },
    'Diabetes Type 1': {
        heading: 'Following the American Diabetes Association guidelines, we design carb-controlled plans with nutrient-dense foods to help manage your blood sugar.',
    },
    'Diabetes Type 2': {
        heading: 'Following the American Diabetes Association guidelines, we design carb-controlled plans with nutrient-dense foods to help manage your blood sugar.',
    },
    'Pregnancy': {
        heading: 'We provide adequate calories to ensure your energy needs are met based on your trimester. Our prenatal plans are also optimized to meet your folate requirement, a nutrient essential for fetal development.',
    },
    'Lactation': {
        heading: 'We provide adequate nutrition to support a healthy diet for you and baby.',
    },
    'Hypertension': {
        heading: 'We follow the guidelines set forth by the “DASH” diet (Dietary Approaches to Stop Hypertension) to decrease blood pressure through better dietary choices.',
    },
    'General Healthy Diet': {
        heading: 'We design delicious and portion-controlled plans with nutrient-dense foods, such as vegetables, fruit, and lean proteins.',
    },
    'Pre-Diabetes': {
        heading: 'We design carb-controlled plans with nutrient-dense foods, such as non-starchy vegetables, fruit, healthy fats and lean proteins to help manage your blood sugar.',
    },
    'Low Sodium': {
        heading: 'We follow the American Heart Association guidelines and limit sodium to 1500 mg/day.',
    },
    'Eat More Veggies': {
        heading: 'We ensure you meet the minimum requirement of 3 servings of non-starchy vegetables per day, and usually even more!',
    },
    'Low Carb': {
        heading: 'We design carb-controlled plans with nutrient-dense foods by limiting total carb intake to 100 grams per day or less.',
    },
    'Increase Energy': {
        heading: 'We focus on balancing lean protein with fiber-rich carbs to keep your energy elevated all day.',
    },
};
