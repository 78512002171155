'use strict';

import keymirror from 'keymirror';

const PlanConstants = keymirror({
    PLANS_LOAD_BY_DATE: null,
    PLANS_ENSURE_DATE_RANGE: null,
    PLANS_UPSERT: null,
    PLANS_DELETE: null,
    PLANS_HYDRATE: null,
    PLANS_FEED_REGEN: null,
});

export default PlanConstants;
