'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './Expander.scss';

export default class Expander extends Component {
    static propTypes = {
        isDefaultExpanded: PropTypes.bool,
        isCollapsible: PropTypes.bool,
    };

    static defaultProps = {
        isDefaultExpanded: false,
        isCollapsible: true,
    };

    static childContextTypes = {
        closeMenu: PropTypes.func,
        toggleMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isExpanderVisible: props.isDefaultExpanded,
        };
    }

    getChildContext = () => {
        return {
            closeMenu: this.closeExpander,
            toggleMenu: this.toggleExpander,
        };
    }

    toggleExpander = () => {
        const { isCollapsible } = this.props;
        const { isExpanderVisible } = this.state;

        if (!isCollapsible) {
            return;
        }

        this.setState({isExpanderVisible: !isExpanderVisible});
    }

    closeExpander = () => {
        this.setState({isExpanderVisible: false});
    }

    render() {
        const { isExpanderVisible } = this.state;
        const { button, isCollapsible } = this.props;

        return (
            <div className="expander-container" data-state={isExpanderVisible} data-collapsible={isCollapsible}>
                <button className="expander-btn" onClick={this.toggleExpander}>
                    {button}
                </button>

                <div className="expander-expander">
                    <div className="expander-content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}
