'use strict';

import allNutrients from './nutrients';

export default (function getGoodSources() {
    let goodSources = {};

    Object.keys(allNutrients).forEach(nutrNo => {
        if (allNutrients[nutrNo].GoodSource && allNutrients[nutrNo].Filter) {
            goodSources[allNutrients[nutrNo].Filter] = {
                label: allNutrients[nutrNo].NutrDesc,
                v: allNutrients[nutrNo].GoodSource,
                u: allNutrients[nutrNo].Units,
            };
        }
    });

    return goodSources;
})();
