'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import Helmet from 'react-helmet';

import Footer from './../components/Widgets/footer/Footer.react';

import './PrivacyPolicy.scss';

export default class PrivacyPolicy extends Component {
    static propTypes = {};

    renderCopy() {

        return (
            <div className="eula-content">
                <h1>Privacy Policy</h1>

                <p>THIS PRIVACY POLICY APPLIES TO PERSONAL INFORMATION COLLECTED BY EATLOVE FROM USERS OF THE EATLOVE PRO AND EATLOVE CONSUMER WEB APPLICATIONS (THE “APPLICATIONS”). “PERSONAL INFORMATION” INCLUDES ANY INFORMATION THAT CAN BE USED ON ITS OWN OR WITH OTHER INFORMATION TO IDENTIFY OR CONTACT A SINGLE PERSON OR TO IDENTIFY AN INDIVIDUAL IN CONTEXT. IF WE CAN LINK PARTICULAR INFORMATION (DIRECTLY OR INDIRECTLY) TO AN INDIVIDUAL, WE WILL CONSIDER THIS INFORMATION “PERSONAL INFORMATION,” AND WE WILL PROTECT IT. </p>
                <p>WE AT EATLOVE VALUE KEEPING YOUR PERSONAL INFORMATION CONFIDENTIAL AND USING IT SOLELY IN THE CONTEXT OF OUR MISSION TO HELP FAMILIES MANAGE THEIR NUTRITION BY OFFERING YOU A CONVENIENT AND HIGH QUALITY APPLICATION THAT WILL ALLOW YOU TO EASILY AND PURPOSEFULLY SHOP, SELECT, AND PREPARE FOOD, OR, IF YOU ARE A PROVIDER, TO HELP YOUR PATIENTS WITH THEIR NUTRITIONAL NEEDS.</p>
                <p>BECAUSE THE PERSONAL INFORMATION WE COLLECT AND TRANSMIT MAY INCLUDE HEALTHCARE INFORMATION, INCLUDING BILLING, INSURANCE, AND MEDICAL INFORMATION, OUR PRIVACY PRACTICES ARE INTENDED TO COMPLY WITH THE HEALTH INSURANCE PORTABILITY AND ACCOUNTABILITY ACT (“HIPAA“). WE WILL MAINTAIN THE PRIVACY OF YOUR HEALTH INFORMATION AS REQUIRED BY HIPAA AND THE REGULATIONS PROMULGATED UNDER THAT ACT. FOR ADDITIONAL INFORMATION RELATED TO YOUR HEALTHCARE INFORMATION, PLEASE CONTACT <a href="mailto:support@eatlove.is">support@eatlove.is</a>.  </p>
                <p>WE BELIEVE THAT TRANSPARENCY ABOUT THE USE OF YOUR PERSONAL INFORMATION IS IMPORTANT. IN THIS PRIVACY POLICY, WE PROVIDE YOU DETAILED INFORMATION ABOUT OUR COLLECTION, USE, MAINTENANCE, AND DISCLOSURE OF YOUR PERSONAL INFORMATION. THE POLICY EXPLAINS WHAT KIND OF INFORMATION WE COLLECT, WHEN AND HOW WE MIGHT USE THAT INFORMATION, HOW WE PROTECT THE INFORMATION, AND YOUR RIGHTS REGARDING YOUR PERSONAL INFORMATION.</p>

                <p>Please read the following carefully to understand our views and practices regarding your Personal Information and how we will treat it. For the purposes of Applicable Data Protection Laws including the European Economic Area data protection law, (the "Data Protection Law"):</p>

                <ul>
                    <li>Non-Provider Users: The data controller is: GYFS Inc. (d/b/a EatLove), 530 Divisadero St., #214, San Francisco, CA 94117.</li>
                    <li>Provider Users: The data controllers are YOUR healthcare provider and GYFS Inc. (d/b/a EatLove), 530 Divisadero St., #214, San Francisco, CA 94117.</li>
                    <li>Data Protection Officer: <a href="mailto:andrew@eatlove.is">Andrew Moenk &lt;andrew@eatlove.is&gt;</a></li>
                </ul>

                <p><em>BY SUBMITTING YOUR PERSONAL INFORMATION THROUGH THIS APPLICATION, YOU ARE ACKNOWLEDGING THAT YOU HAVE READ AND AGREE TO THE TERMS OF THIS POLICY. IF YOU DO NOT AGREE, PLEASE DO NOT LOG INTO OR ACCESS THIS APPLICATION AND DO NOT SUBMIT ANY INFORMATION TO US. </em></p>
                <p>PLEASE NOTE THAT WE OCCASIONALLY UPDATE THIS PRIVACY POLICY AND THAT IT IS YOUR RESPONSIBILITY TO STAY UP TO DATE WITH ANY AMENDED VERSIONS. IF WE MODIFY THE PRIVACY POLICY, WE WILL POST A LINK TO THE MODIFIED TERMS IN THE APPLICATION. WE WILL ALSO NOTIFY YOU VIA EMAIL. YOU CAN STORE THIS POLICY AND/OR ANY AMENDED VERSION(S) DIGITALLY, PRINT IT, OR SAVE IT IN ANY OTHER WAY. ANY CHANGES TO THIS PRIVACY POLICY WILL BE EFFECTIVE IMMEDIATELY UPON PROVIDING NOTICE, AND SHALL APPLY TO ALL INFORMATION WE MAINTAIN, USE, AND DISCLOSE. IF YOU CONTINUE TO USE THE APPLICATION FOLLOWING SUCH NOTICE, YOU ARE AGREEING TO THOSE CHANGES.   </p>
                <p>CAPITALIZED TERMS, IF NOT DEFINED IN THIS PRIVACY POLICY, ARE DEFINED IN THE TERMS OF SERVICE, WHICH IS ACCESSIBLE THROUGH THE APPLICATION.  </p>

                <p>In case you have any questions or concerns after reading this privacy statement, please do not hesitate to contact us at <a href="mailto:support@eatlove.is">support@eatlove.is</a>. We appreciate your feedback.</p>



                <h2>WHAT INFORMATION DO WE COLLECT AND WHY?</h2>

                <h3>Personal Information</h3>
                <p>The Personal Information we collect from you includes demographic information, such as your name, birth year, gender, phone number, postal and e-mail address. Primarily, the collection of your Personal Information assists us in creating your User Account and providing a platform whereby you can access recipes, shopping lists, and meal prep schedules. We may also receive information about you from your healthcare provider directly. This information includes your contact information, and we use that information for the purposes of reaching out to you to invite you to create a User Account. If you choose not to create a User Account, we will erase your information within ninety (90) days of receiving it.</p>

                <p>If you choose to create a User Account, we may also use your Personal Information to (1) communicate with you about and manage your User Account; (2) store data; (3) comply with the law; (4) respond to requests from public and government authorities; (5) to enforce our terms and conditions; (6) manage and improve our operations and applications; (7) provide additional functionality; (7) protect our rights, privacy, safety or property, and/or that of you or others; and (8) allow us to pursue available remedies or limit the damages that we may sustain. </p>

                <h3>Failure to Provide Data</h3>
                <p>Providing your Personal Information is not statutorily or contractually mandated. If you choose not to provide this information, we cannot create a User Account, and you will be unable to use our Services.</p>

                <h3 className="underline">For Consumers and Patients (non-Provider users)</h3>
                <p>In addition to demographic information, during the registration process, we will collect information regarding your health conditions, age, gender, weight, height. We collect this information to provide you a customized nutrition experience and to help you to communicate information with your healthcare providers. For patients, the Application will also collect communications between you and your provider.</p>

                <h3>Support Information</h3>
                <p>If you contact EatLove for support or to lodge a complaint, EatLove may collect technical or other information from you (e.g., IP address). Such information will be used for the purposes of troubleshooting, customer support, software updates, and improvement of the Application and related services in accordance with this Privacy Policy. Calls with EatLove may be recorded or monitored for training, quality assurance, customer service, and reference purposes. </p>

                <h3>Monitoring</h3>
                <p>EatLove and its affiliates and agents are permitted, but not obligated, to review and/or retain information and/or communications stored and/or transmitted using the Application (“User Content”). EatLove may monitor User Content for data collection purposes and/or to evaluate the quality of service you receive, your compliance with the Terms of Services, the security of the Application, or for other reasons.  Your authorized healthcare providers may also monitor User Content in order to monitor your progress and overall condition and to follow up with you, as they deem appropriate in their independent judgment as your healthcare providers.</p>

                <p>You agree that such monitoring activities, if in compliance with applicable privacy laws, will not entitle you to any cause of action or other right with respect to the manner in which EatLove or its affiliates or agents monitor your Communications and enforces or fails to enforce the Terms of this Privacy Policy. In no event will EatLove or any of its affiliates or agents be liable for any costs, damages, expenses, or any other liabilities incurred by you as a result of monitoring activities by EatLove or its affiliates or agents.</p>

                <h3>Device and ISP Data</h3>
                <p>We use common information-gathering tools, such as log files, cookies and similar technologies to automatically collect information, which may contain Personal Data, from your computer or mobile device as you navigate our websites or interact with emails we have sent you. As is true of most websites, we gather certain information automatically via log files. This collected information may include your Internet Protocol (IP) address (or proxy server), device and application identification numbers, your location, your browser type, your Internet service provider and/or mobile carrier, the pages and files you viewed, your searches, your operating system and system configuration information, and date/time stamps associated with your usage. This information is used to analyze overall trends, to help us provide and improve our websites and to guarantee their security and continued proper functioning. We also collect IP addresses from users when they log into the services as part of the Company’s security features.</p>



                <h2>WHERE IS MY PERSONAL INFORMATION STORED AND/OR PROCESSED</h2>

                <p>Information EatLove collects through our Application will be stored on secure U.S-based servers.  The Application is “native” to your device, meaning information you enter into our Application is also stored directly on the device you use to access and enter information into the Application.</p>

                <h2>WILL YOU SHARE MY PERSONAL INFORMATION WITH ANYONE ELSE?</h2>

                <h3>Yes, with members of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, who support our processing of Personal Information under this policy.</h3>
                <p>If any of these parties are using your information for direct marketing purposes, we will only transfer the information to them for that purpose with your prior consent.</p>

                <h3>(For Provider Users) Yes, with the healthcare provider(s)/group(s) with whom you choose to connect via the Application</h3>
                <p>We will share information you enter into EatLove, as well as any reports generated by the Application based on the information you enter, with the provider(s) and/or healthcare group(s) (such as a particular dietetics office) with whom you choose to share data via the Application. If you permit your provider (e.g., dietitian, coach, wellness professional) access to your account, that provider will also have access to your activity within the platform (e.g., if you are searching for recipes, scheduling meals, logging in, etc.). If, at any point, you want to deny access to one or more providers, you can do so by emailing us at <a href="mailto:support@eatlove.is?subject=do not track me">support@eatlove.is</a></p>

                <h3>Yes, with third parties that help us power our Application</h3>
                <p>EatLove has a limited number of service providers and other third parties (“Business Partners”) that help us run various aspects of our business. These Business Partners are contractually bound to protect your Personal Information and to use it only for the limited purpose(s) for which it is shared. Such third-party uses may include, but are not limited to, the provision of services such as data hosting, IT services, and billing management.</p>

                <h3>Yes, with third parties and the government when legal or enforcement issues arise</h3>
                <p>We may share your Personal Information, if reasonable and necessary, to (i) comply with legal processes or enforceable governmental requests, or as otherwise required by law; (ii) cooperate with third parties in investigating acts in violation of this Agreement; or (iii) bring legal action against someone who may be violating the Terms of Use or who may be causing intentional or unintentional injury or interference to the rights or property of EatLove or any third party, including other customers.</p>

                <h3>Yes, with third parties in the event of a reorganization, merger, sale, joint venture, assignment, transfer, or other disposition of all or any portion of EatLove’s corporate entity, assets, or stock (including in connection with any bankruptcy or similar proceedings).</h3>
                <p>If we share your Personal Information with a third party other than as provided above, you will be notified at the time of data collection or transfer, and you will have the option of not permitting the transfer.</p>

                <p>We may, from time to time, rent or sell aggregated data and/or other information that does <span className="italic">not</span> contain any personal identifiers (i.e., if the information has been anonymized by stripping out identifiers such as name, address, phone number, etc.).  The purpose of this type of disclosure is to provide healthcare professionals, research institutions, and other interested parties (e.g., pharmaceutical and/or device manufacturers) real-world information about the usefulness of engaging nutrition management.</p>



                <h2>HOW LONG WILL YOU RETAIN THE INFORMATION?</h2>

                <h3>Personal Information, Generally</h3>
                <p>We store your Personal Information for as long as you maintain a User Account and up to five (5) years after the account is closed. At the end of this five-year retention period, we will remove your Personal Information from our databases and will request that our Business Partners remove your Personal Information from their databases. However, once we disclose your Personal Information to third parties, we may not be able to access that Personal Information any longer and cannot force the deletion or modification of any such information by the parties to whom we have made those disclosures. Written requests for deletion of Personal Information other than as described should be directed to <a href="mailto:support@eatlove.is">support@eatlove.is</a>.  We retain anonymized data indefinitely. </p>



                <h2>What is your cookie policy?</h2>

                <p>Cookies allow a web server to transfer data to a computer for recordkeeping and other purposes.  We use cookies and other technologies to, among other things, better serve you with more tailored information, to facilitate efficient and secure access to the Application. We use two types of cookies: essential and non-essential cookies. Essential cookies are those necessary for use to provide services to you.  All other cookies are non-essential. We use two types of non-essential cookies: (1) cookies used to analyze your behavior on a website (“Analytics Cookies”); and (2) cookies used to provide you enhanced functionality (“Functional cookies”). We have provided, below, a full list of our cookies, categorized as described above. We have described the purpose of each, whether they are EatLove or Third Party cookies, and how to withdraw consent to their use. We have also indicated which cookies are “session cookies” (which last for as long as you keep your browser open) and “persistent cookies” (which remain on your hard drive until you delete them or they expire) If you enable a do not track (DNT) signal that prevents us from collecting cookies, you will need to re-enter your user name each time you visit the login page. We do not use cookies to collect and store any additional information or for any other purpose than described above. To learn more about cookies, please visit http://www.allaboutcookies.org/.</p>

                <p>We may also collect information using pixel tags, web beacons, clear GIFs or other similar technologies. These may be used in connection with some Site pages and HTML-formatted email messages to, among other things, track the actions of Site users and email recipients, and compile statistics about Site usage and response rates.</p>

                <h3>Essential Cookies</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Cookie Name, Who Controls It, Duration</th>
                            <th>Purpose</th>
                            <th>Information Collected</th>
                            <th>How to Withdraw Consent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><em>auth-tokens-token</em>, EatLove, 8 weeks</td>
                            <td>To authenticate you when you sign into the service.</td>
                            <td>A generated token that allows the server to identify you.</td>
                            <td>Do not use our Service if you do not want to receive this cookie.</td>
                        </tr>
                        <tr>
                            <td><em>auth-tokens-refresh</em>, EatLove, 16 weeks</td>
                            <td>To refresh your authentication token when it expires.</td>
                            <td>A generated token that lets you stay logged in.</td>
                            <td>Do not use our Service if you do not want to receive this cookie.</td>
                        </tr>
                        <tr>
                            <td><em>unsubscribe-token</em>, EatLove, 8 weeks</td>
                            <td>Used to temporarily store information needed to unsubscribe a user from EatLove emails.</td>
                            <td>A generated token that lets you unsubscribe from emails.</td>
                            <td>Do not use our service and do not unsubscribe from EatLove automated emails if you do not want this cookie.</td>
                        </tr>
                        <tr>
                            <td><em>Stripe cookies</em>, Stripe, various</td>
                            <td>Stripe, our payment card processor, sets various cookies on your browser to identify you to their servers.</td>
                            <td>Generated cookies uniquely identifying you to Stripe.</td>
                            <td>Do not use our Service if you do not want to receive these cookies.</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Functional Cookies</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Cookie Name, Who Controls It, Duration</th>
                            <th>Purpose</th>
                            <th>Information Collected</th>
                            <th>How to Withdraw Consent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><em>Facebook Cookies</em>, Facebook, various</td>
                            <td>EatLove integrates with Facebook for purposes of logging you in. Facebook uses cookies as part of this integration. EatLove will never post on your behalf or access your Facebook data other than name, birthdate and email address.</td>
                            <td>Email address, name, and birthdate from Facebook. <br /><br />No identifying information is transmitted TO Facebook, only FROM Facebook at the user’s explicit request. </td>
                            <td>Do not use EatLove or Facebook Connect if you do not wish to receive these cookies.</td>
                        </tr>
                    </tbody>
                </table>

                <h3>Analytics Cookies</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Cookie Name, Who Controls It, Duration</th>
                            <th>Purpose</th>
                            <th>Information Collected</th>
                            <th>How to Withdraw Consent</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><em>analytics</em>, EatLove, permanent</td>
                            <td>For correlating website visitors before and after they login or register for EatLove</td>
                            <td>Randomly generated code. No information is collected.</td>
                            <td>Do not use EatLove if you do not wish to receive this cookie. </td>
                        </tr>
                        <tr>
                            <td><em>UTC_</em>, EatLove, permanent</td>
                            <td>For tracking what marketing campaign a visitor to the site came from.</td>
                            <td>No information is collected or stored in these cookies.</td>
                            <td>Do not use EatLove if you do not wish to receive this cookie.</td>
                        </tr>
                    </tbody>
                </table>


                <h2>HOW DO YOU PROTECT MY PERSONAL INFORMATION?</h2>

                <p>EatLove is committed to protecting the security and confidentiality of your Personal Information. We use a combination of reasonable physical, technical, and administrative security controls to maintain the security and integrity of your Personal Information, to protect against any anticipated threats or hazards to the security or integrity of such information, and to protect against unauthorized access to or use of such information in our possession or control that could result in substantial harm or inconvenience to you. However, Internet data transmissions, whether wired or wireless, cannot be guaranteed to be 100% secure.  As a result, we cannot ensure the security of information you transmit to us. By using the Application, you are assuming this risk.</p>

                <h3>Safeguards</h3>

                <p>The information collected by EatLove and stored on secure servers, is protected by a combination of technical, administrative, and physical security safeguards, such as authentication, encryption, backups, and access controls. If EatLove learns of a security concern, we may attempt to notify you and provide information on protective steps, if available, through the e-mail address that you have provided to us or by an in-app notification. Depending on where you live, you may have a legal right to receive such notices in writing.</p>

                <p>You are solely responsible for protecting information entered or generated via the Application that is stored on your device and/or removable device storage.  EatLove has no access to or control over your device’s security settings, and it is up to you to implement any device-level security features and protections you feel are appropriate (e.g., password protection, encryption, remote wipe capability, etc.).  <em>We recommend that you take any and all appropriate steps to secure any device that you use to access our Application.</em></p>

                <h3>We recommend that you take any and all appropriate steps to secure any device that you use to access our Application.</h3>
                <p></p>
                <p>NOTWITHSTANDING ANY OF THE STEPS TAKEN BY US, IT IS NOT POSSIBLE TO GUARANTEE THE SECURITY OR INTEGRITY OF DATA TRANSMITTED OVER THE INTERNET. THERE IS NO GUARANTEE THAT YOUR INFORMATION WILL NOT BE ACCESSED, DISCLOSED, ALTERED, OR DESTROYED BY BREACH OF ANY OF OUR PHYSICAL, TECHNICAL, OR ADMINISTRATIVE SAFEGUARDS. THEREFORE, WE DO NOT AND CANNOT ENSURE OR WARRANT THE SECURITY OR INTEGRITY OF ANY INFORMATION YOU TRANSMIT TO US AND YOU TRANSMIT SUCH INFORMATION AT YOUR OWN RISK.</p>

                <h2>HOW DO I PROTECT MY PERSONAL INFORMATION?</h2>

                <p>In addition to securing your device, as discussed above, we will NEVER send you an e-mail requesting confidential information such as account numbers, usernames, passwords, or social security numbers, and you should NEVER respond to any e-mail requesting such information. If you receive such an e-mail purportedly from EatLove, DO NOT RESPOND to the e-mail and DO NOT click on any links and/or open any attachments in the e-mail, and notify EatLove support at <a href="mailto:support@eatlove.is">support@eatlove.is</a>. </p>
                <p>You are responsible for taking reasonable precautions to protect your user ID, password, and other User Account information from disclosure to third parties, and you are not permitted to circumvent the use of required encryption technologies. You should immediately notify EatLove at <a href="mailto:support@eatlove.is">support@eatlove.is</a> if you know of or suspect any unauthorized use or disclosure of your user ID, password, and/or other User Account information, or any other security concern.</p>


                <h1>EU CITIZEN RIGHTS</h1>
                <p>You have the right under certain circumstances:</p>
                <ul>
                    <li>to receive communications related to the processing of your personal data that are concise, transparent, intelligible and easily accessible;</li>
                    <li>to be provided with a copy of your personal data held by us or your healthcare provider;</li>
                    <li>to request the rectification or erasure of your personal data held by us or your healthcare provider without undue delay;</li>
                    <li>to request that we or your healthcare provider restrict the processing of your personal data (while we verify or investigate your concerns with this information, for example);</li>
                    <li>to object to the further processing of your personal data by us or your healthcare provider, including the right to object to marketing;</li>
                    <li>to request that your Personal Information be moved to a third party;</li>
                    <li>to receive your Personal Information in a structured, commonly used and machine-readable format</li>
                    <li>to lodge a complaint with a supervisory authority</li>
                </ul>

                <p>Where the processing of your personal information by us is based on consent, you have the right to withdraw that consent without detriment at any time by going here. You can also exercise the rights listed above at any time by contacting us at <a href="mailto:support@eatlove.is">support@eatlove.is.</a></p>


                <h2>HOW CAN I UPDATE, CORRECT OR DELETE MY PERSONAL INFORMATION?</h2>
                <p>You can change your e-mail address and other contact information by editing your profile in the Application. If you need to make changes or corrections to other information, you may e-mail <a href="mailto:support@eatlove.is">support@eatlove.is</a>. Please note that in order to comply with certain requests to limit use of your Personal Information we may need to terminate your account with us and your ability to access and use the Services, and you agree that we will not be liable to you for such termination or for any refunds of prepaid fees paid by you. Although we will use reasonable efforts to do so, you understand that it may not be technologically possible to remove from our systems every record of your Personal Information. The need to back up our systems to protect information from inadvertent loss means a copy of your Personal Information may exist in a non-erasable form that will be difficult or impossible for us to locate or remove. Backups of that data will remain associated with your account and in our archive servers. You can deactivate your account by emailing <a href="mailto:support@eatlove.is">support@eatlove.is</a>.</p>



                <h2>CAN I “OPT-OUT” OF RECEIVING COMMUNICATIONS FROM COMPANY?</h2>
                <p>We pledge not to market third party services to you. We only send e-mails to you regarding your EatLove account and services. You can choose to filter these e-mails using your e-mail client settings, but we do not provide an option for you to opt out of these e-mails. You can opt out of daily emails by emailing <a href="mailto:support@eatlove.is">support@eatlove.is</a>.</p>


                <h2>INFORMATION SUBMISSION BY MINORS</h2>
                <p>We do not knowingly collect personal information from individuals under the age of 18 and the Application is not directed to individuals under the age of 13. We request that these individuals not provide personal information through the Application. If you are aware of a user under the age of 13 using the Application, please contact us at <a href="mailto:support@eatlove.is">support@eatlove.is</a>.</p>


                <h2>CONTACT US</h2>
                <p>If you have any questions about this Privacy Policy, please contact us by email at <a href="mailto:support@eatlove.is">support@eatlove.is</a> or please write to: 530 Divisadero St. #214, San Francisco, CA 94117. Please note that email communications are not always secure; so please do not include sensitive information in your emails to us.</p>
            </div>
        );
    }

    render() {
        return(
            <div>
                <section className="privacy-policy">
                    <div className="privacy-policy-contents">
                        {this.renderCopy()}
                    </div>

                </section>
                <Footer />
            </div>
        );
    }
}
