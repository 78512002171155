'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

import FodmapEntryModal from './FodmapEntryModal.react';
import Select from '../../../../pro/components/Widgets/Select.react';

import allNutrients from '../../../../tables/nutrients';
import allRecommendations from '../../../../tables/recommendations';
import { scrollTextFieldIntoView } from '../../../../utils/Keyboard';
import TooltipText from '../../../Widgets/Common/TooltipText.react';

import './NutrientEditor.scss';

const dailyValues = allRecommendations['all-ages'];

const valueNutrients = [
    {
        title: 'Macros',
        nutrNos: [
            {nutrNo: '208'},                 // Calories
            {nutrNo: '204'},                 // Total Fat
            {nutrNo: '606'},                 // Saturated Fat (g)
            {nutrNo: '605'},                 // 605, indent, Trans Fat (g)
            {nutrNo: '646'},                 // 646, indent, Polyunsaturated Fat (g)
            {nutrNo: '645'},                 // Monounsaturated Fat (g)
            {nutrNo: '601'},                 // Cholesterol (mg)
            {nutrNo: '307'},                 // Sodium (mg)
            {nutrNo: '205'},                 // Carbohydrates (g)
            {nutrNo: '291'},                 // Fiber (g)
            {nutrNo: 'SFB'},                 // Soluable Fiber (g)
            {nutrNo: 'IFB'},                 // Insoluable Fiber (g)
            {nutrNo: '269'},                 // Sugar (g)
            {nutrNo: 'ASG'},                 // Sugar (Added)

            {nutrNo: '203'},                 // Protein (g)
            {nutrNo: 'FLU'},                 // Fluids (ml)
        ],
    },
    {
        title: 'Micro Nutrients',
        nutrNos: [
            {nutrNo: '324', dv: dailyValues['324']}, // Vitamin D - % x 400 IU (1 IU = 0.025 mcg)* (use 324)
            {nutrNo: '301', dv: dailyValues['301']}, // Calcium
            {nutrNo: '303', dv: dailyValues['303']}, // Iron - % x 18 mg
            {nutrNo: '306', dv: dailyValues['306']}, // Potassium (mg)

            {nutrNo: '318', dv: dailyValues['318']}, // Vitamin A - % x 5,000 IU (1 IU = 0.3 mg RAE) (use 318)
            {nutrNo: '415', dv: dailyValues['415']}, // Vitamin B6 - % x 2 mg
            {nutrNo: '418', dv: dailyValues['418']}, // Vitamin B12 - % x 6 ug
            {nutrNo: '401', dv: dailyValues['401']}, // Vitamin C - % x 60 mg
            {nutrNo: '323', dv: dailyValues['323']}, // Vitamin E - % x 30 IU (1 IU = 0.67 mg)
            {nutrNo: '430', dv: dailyValues['430']}, // Vitamin K
            {nutrNo: '404', dv: dailyValues['404']}, // Thiamin - % x 1.5 mg
            {nutrNo: '405', dv: dailyValues['405']}, // Riboflavin - % x 1.7 mg
            {nutrNo: '406', dv: dailyValues['406']}, // Niacin - % x 20 mg
            {nutrNo: '435', dv: dailyValues['435']}, // Folate (Folic Acid) - % x 400 ug
            {nutrNo: 'BIO', dv: dailyValues['BIO']}, // Biotin - % x 300 ug
            {nutrNo: '410', dv: dailyValues['410']}, // Pantothenic Acid - % x 10 mg
            {nutrNo: '305', dv: dailyValues['305']}, // Phosphorus - % x 1,000 mg
            {nutrNo: '304', dv: dailyValues['304']}, // Magnesium - % x 400 mg
            {nutrNo: '309', dv: dailyValues['309']}, // Zinc - % x 15 mg
            {nutrNo: '317', dv: dailyValues['317']}, // Selenium - % x 70 ug
            {nutrNo: '315', dv: dailyValues['315']}, // Manganese - % x 2 mg
            {nutrNo: 'MBD', dv: dailyValues['MBD']}, // Molybdenum - % x 75 ug

            {nutrNo: 'CHL', dv: dailyValues['CHL']}, // Chloride - % x 3,400 mg
            {nutrNo: '421', dv: dailyValues['421']}, // Choline, Total
            {nutrNo: '312', dv: dailyValues['312']}, // Copper - % x 2 mg (use 312)
            {nutrNo: 'IOD', dv: dailyValues['IOD']}, // Iodine - % x 150 ug
            {nutrNo: 'CHR'}, // Chromium, no daily recommended value
            {nutrNo: 'SUL'}, // Sulfites
        ],
    },
    {
        title: 'FODMAPs',
        fodmaps: true,
        nutrNos: [
            {nutrNo: 'OLF'}, // Oligos-fructans
            {nutrNo: 'OLG'}, // Oligos-GOS
            {nutrNo: 'FRC'}, // Fructose
            {nutrNo: 'LAC'}, // Lactose
            // {nutrNo: 'PLY'}, // Polyols
            {nutrNo: 'MAN'}, // Mannitol
            {nutrNo: 'SOR'}, // Sorbitol
        ],
    },
    {
        title: 'Fruits & Vegetable',
        nutrNos: [
            {nutrNo: 'FRU'}, // fruits
            {nutrNo: 'VEG'}, // non-starchy vegetables
            {nutrNo: 'SVG'}, // starchy vegetables
            {nutrNo: 'WHG'}, // whole grains
            {nutrNo: 'HLF'}, // healthy fats
            {nutrNo: 'LPR'}, // lean proteins
            {nutrNo: 'DAI'}, // dairy servings
            {nutrNo: 'LFY'}, // leafy greens
            {nutrNo: 'BER'}, // berries
            {nutrNo: 'NUT'}, // nuts/seeds
        ],
    },
    {
        title: "Sugars & Alcohols",
        nutrNos: [
            {nutrNo: '221'},                 // Alcohol
            {nutrNo: 'SAL'},                 // Sugar Alcohol
            {nutrNo: '210'},                 // Sucrose
            {nutrNo: '211'},                 // Glucose
            {nutrNo: '214'},                 // Maltose
            {nutrNo: '213'},                 // Lactose (g)
            {nutrNo: '287'},                 // Galactose
        ],
    },
    {
        title: 'Derived Values',
        nutrNos: [
            {nutrNo: 'RAL', disabled: true}, // Potential Renal Acid Load
            {nutrNo: 'NTC', disabled: true}, // Net Carbs (g)
        ],
    },
    {
        title: 'Omega 3 & 6 Fatty Acids',
        nutrNos: [
            {nutrNo: '619'}, // Alpha-linolenic acid (undifferentiated)
            {nutrNo: '621'}, // Omega-3 (DHA)
            {nutrNo: '629'}, // Omega-3 (EPA)
            {nutrNo: '851'}, // Omega-3 (ALA)

            {nutrNo: '618'}, // Linoleic acid (undifferentiated)
            {nutrNo: '672'}, // Omega 6 (Eicosadienoic acid)
            {nutrNo: '675'}, // Omega 6 (Linoleic acid)
            {nutrNo: '685'}, // Omega 6 (Gamma-linoleic acid)
            {nutrNo: '853'}, // Omega 6 (Eicosatrienoic acid)
            {nutrNo: '855'}, // Omega 6 (Eicosatetraenoic acid)
        ],
    },
    {
        title: 'Other Fatty Acids',
        nutrNos: [
            {nutrNo: '607'}, // Butyric acid
            {nutrNo: '608'}, // Caproic acid
            {nutrNo: '609'}, // Caprylic acid
            {nutrNo: '610'}, // Capric acid
            {nutrNo: '611'}, // Lauric acid
            {nutrNo: '612'}, // Myristic acid
            {nutrNo: '613'}, // Palmitic acid
            {nutrNo: '614'}, // Stearic acid
            {nutrNo: '615'}, // Arachidic acid
            {nutrNo: '617'}, // Oleic acid (undifferentiated)
            {nutrNo: '620'}, // Arachidonic acid (undifferentiated)
            {nutrNo: '624'}, // Behenic acid
            {nutrNo: '625'}, // Myristoleic acid
            {nutrNo: '626'}, // Palmitoleic acid
            {nutrNo: '627'}, // Stearidonic acid
            {nutrNo: '628'}, // Eicosenoic acid
            {nutrNo: '630'}, // Erucic acid (undifferentiated)
            {nutrNo: '653'}, // Margaric acid
            {nutrNo: '654'}, // Lignoceric acid
            {nutrNo: '662'}, // Trans-palmitoleic acid
            {nutrNo: '670'}, // Conjugated Linoleic Acids
            {nutrNo: '671'}, // Nervonic acid
            {nutrNo: '673'}, // Palmitoleic acid
            {nutrNo: '674'}, // Oleic acid
            {nutrNo: '676'}, // Erucic acid
            {nutrNo: '687'}, // Heptadecenoic acid
            {nutrNo: '693'}, // Trans-monoenoic Fat
            {nutrNo: '695'}, // Trans-polyenoic Fat
            {nutrNo: '696'}, // Tridecanoic acid
            {nutrNo: '697'}, // Pentadecenoic acid
        ],
    },
    {
        title: 'Amino Acids',
        nutrNos: [
            {nutrNo: '454'}, // Betaine
            {nutrNo: '501'}, // Tryptophan
            {nutrNo: '502'}, // Threonine
            {nutrNo: '503'}, // Isoleucine
            {nutrNo: '504'}, // Leucine
            {nutrNo: '505'}, // Lysine
            {nutrNo: '506'}, // Methionine
            {nutrNo: '507'}, // Cystine
            {nutrNo: '508'}, // Phenylalanine
            {nutrNo: '509'}, // Tyrosine
            {nutrNo: '510'}, // Valine
            {nutrNo: '511'}, // Arginine
            {nutrNo: '512'}, // Histidine
            {nutrNo: '513'}, // Alanine
            {nutrNo: '514'}, // Aspartic acid
            {nutrNo: '515'}, // Glutamic acid
            {nutrNo: '516'}, // Glycine
            {nutrNo: '517'}, // Proline
            {nutrNo: '518'}, // Serine
            {nutrNo: '521'}, // Hydroxyproline
        ],
    },

];

// dv stands for 'daily value'
const percentNutrients = [
];

export default class NutrientEditor extends Component {

    static propTypes = {
        values: PropTypes.object,
        available: PropTypes.object,
        isRecipeEditor: PropTypes.bool,
        onChangeServingSizeUnit: PropTypes.func,
        onShowMore: PropTypes.func,
        unitOpts: PropTypes.object,

        allowedNutrNos: PropTypes.array,
    };

    static defaultProps = {
        isRecipeEditor: false,
        onChangeServingSizeUnit: null,
        unitOpts: null,
        onShowMore: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            ...this.getStateFromProps(props),
            errored: {},
        };

        this.resetInhibitUpdate = debounce(this.resetInhibitUpdate, 250);
    }

    inhibitUpdate = false;

    resetInhibitUpdate = () => {
        this.inhibitUpdate = false;
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        if (!this.inhibitUpdate) {
            this.setState(this.getStateFromProps(nextProps, this.state.modes));
        }
    }

    getStateFromProps = ({values, available, allowedNutrNos}, modes = {}) => {
        let nutrients = JSON.parse(JSON.stringify(values || {}));

        valueNutrients.forEach(group => {
            group.nutrNos.forEach(def => {
                if (allowedNutrNos && !allowedNutrNos.includes(def.nutrNo)) {
                    return;
                }

                let value = available[def.nutrNo] ? values[def.nutrNo] : '';

                // If this nutrient is in percentage mode, convert the incoming value to a percentage of daily values
                if (modes[def.nutrNo] === '%') {
                    value = (value / def.dv * 100);
                }

                nutrients[def.nutrNo] = value;
            });
        });

        return {
            values: nutrients,
            available,
            modes,
        };
    }

    updateParent = () => {
        const { modes, available } = this.state;
        const { onChange } = this.props;

        const values = {};

        Object.keys(this.state.values).forEach(nutrNo => {
            const parsed = parseFloat(this.state.values[nutrNo]);

            if (typeof parsed !== 'number' || isNaN(parsed)) {
                available[nutrNo] = false;
                return;
            }

            values[nutrNo] = (modes[nutrNo] === '%')
                           ? (parsed / 100 * dailyValues[nutrNo])
                           : parsed;
        });

        this.inhibitUpdate = true;

        onChange({values, available});

        this.resetInhibitUpdate();
    }

    onNutrientValueChange = (nutrNo, value) => {
        const { values, errored, available, modes } = this.state;
        values[nutrNo] = value;

        if (value.length == 0) {
            available[nutrNo] = false;
        } else {
            available[nutrNo] = true;
            const parsed = value.length > 0 ? parseFloat(value) : 0;
            if (typeof parsed !== 'number' || isNaN(parsed)) {
                errored[nutrNo] = true;
            } else {
                delete errored[nutrNo];
            }
        }

        // if fiber is set to zero, set soluable and insoluable fiber to zero too
        if (nutrNo === '291' && value == 0 && available['291']) {
            values['SFB'] = 0;
            values['IFB'] = 0;
            available['SFB'] = available['IFB'] = true;
        }

        // If total sugar is set to zero, set added sugar to zero too
        if (nutrNo === '269' && value == 0 && available['269']) {
            values['ASG'] = 0;
            available['ASG'] = true;
        }

        // If the user entered 0 total fat, then a bunch of fatty values are also zero.
        if (nutrNo === '204' && value == 0 && available['204']) {
            values['606'] = 0; available['606'] = true; // Saturated Fat (g)
            values['605'] = 0; available['605'] = true; // Trans Fat (g)
            values['646'] = 0; available['646'] = true; // Polyunsaturated Fat (g)
            values['645'] = 0; available['645'] = true; // Monounsaturated Fat (g)
        }

        // If the user entered 0 total carbs, then fiber and sugars are all zero too.
        if (nutrNo === '205' && value == 0 && available['205']) {
            values['291'] = 0; available['291'] = true; // Fiber
            values['SFB'] = 0; available['SFB'] = true; // Soluble Fiber
            values['IFB'] = 0; available['IFB'] = true; // Insoluble Fiber
            values['269'] = 0; available['269'] = true; // Sugars, total
            values['ASG'] = 0; available['ASG'] = true; // Added Sugar
        }

        if (['621', '629', '851'].includes(nutrNo) && available['621'] && available['629'] && available['851']) {
            // also update DHA+EPA and DHA+EPA+ALA
            const dha = parseFloat(values[621]) || 0;
            const epa = parseFloat(values[629]) || 0;
            const ala = parseFloat(values[851]) || 0;

            values['OM3'] = dha + epa;       // Omega-3 (DHA+EPA)
            values['OMA'] = dha + epa + ala; // Omega-3 (DHA+EPA+ALA)
            available['OMA'] = available['OM3'] = true;
        } else {
            values['OM3'] = values['OMA'] = 0;
            available['OMA'] = available['OM3'] = false;
        }

        if (['291', '205', 'SAL'].includes(nutrNo) && available['291'] && available['205']) {
            // also update net carbs
            const cho = parseFloat(values[205]) || 0;
            const fiber = parseFloat(values[291]) || 0;
            const sal = parseFloat(values['SAL']) || 0;

            values['NTC'] = cho - fiber - sal; // Net Carbs
            available['NTC'] = available[205] && available[291] && available['SAL'];
        } else {
            values['NTC'] = 0;
            available['NTC'] = false;
        }

        // Update Potential Renal Acid Load
        if (['203', '305', '306', '304', '301'].includes(nutrNo)) {

            // If phos, mag, or cal are in %dv mode, then we need to convert to absolute mode for the formula
            const pro  = parseFloat(values['203']) || 0;
            const phos = (parseFloat(values['305']) || 0) * (modes['305'] == '%' ? dailyValues[305] / 100 : 1);
            const pot  = parseFloat(values['306']) || 0;
            const mag  = (parseFloat(values['304']) || 0) * (modes['304'] == '%' ? dailyValues[304] / 100 : 1);
            const cal  = (parseFloat(values['301']) || 0) * (modes['301'] == '%' ? dailyValues[301] / 100 : 1);

            values['RAL'] = (0.49 * pro) + (0.037 * phos) - (0.021 * pot) - (0.026 * mag) - (0.013 * cal);
            available['RAL'] = true;
        }

        this.setState({values, available, errored}, this.updateParent);
    }

    showFodmapEntry = () => {
        this.setState({isFodmapEntryOpen: true});
    }

    closeFodmapEntry = () => {
        this.setState({isFodmapEntryOpen: false});
    }

    toggleNutrMode = (nutrNo) => {
        const { values, modes } = this.state;

        const parsed = parseFloat(values[nutrNo]);

        if (modes[nutrNo] === '%') {
            modes[nutrNo] = 'v';

            if (typeof parsed === 'number' && !isNaN(parsed)) {
                values[nutrNo] = (parsed / 100 * dailyValues[nutrNo]);
            }
        } else {
            modes[nutrNo] = '%';

            if (typeof parsed === 'number' && !isNaN(parsed)) {
                values[nutrNo] = (parsed / dailyValues[nutrNo] * 100);
            }
        }

        this.setState({modes, values});
    }

    renderNutrientGroup = ({nutrNos, title, fodmaps = false}, i) => {
        const { allowedNutrNos, servingUnit, servingSize, isRecipeEditor } = this.props;
        const { modes, values, available, errored } = this.state;

        if (allowedNutrNos && allowedNutrNos.length > 0) {
            nutrNos = nutrNos.filter(def => allowedNutrNos.includes(def.nutrNo) && allNutrients[def.nutrNo]);
        }

        // If no nutrients in this group will be rendered, don't render the group at all.
        if (!nutrNos.length) {
            return null; // <p>No nutrients in group {title}</p>;
        }

        return (
            <div className="nutrient-group" key={i}>
                {!isRecipeEditor ? <h3>{title}</h3> : null}

                <ul>
                    {nutrNos.map(({nutrNo, disabled}) => {
                         const unitOpts = [
                            {label: allNutrients[nutrNo].Units, value: 'v'},
                            {label: '% DV', value: '%'},
                        ];

                        const indents = {
                            "606": "1",
                            "605": "1",
                            "291": "1",
                            "269": "1",
                            "ASG": "1"
                        }
                        return (
                            <li key={nutrNo} data-indent={indents[nutrNo] || 0}>
                                <label title={allNutrients[nutrNo].NutrDesc}>
                                    <TooltipText text={allNutrients[nutrNo].NutrDesc} />
                                </label>

                                <div className="input-flex">
                                    <input type="number" disabled={disabled} placeholder="--" data-error={errored[nutrNo]} min="0"
                                        value={values[nutrNo]} data-available={available[nutrNo]}
                                        onChange={(ev) => this.onNutrientValueChange(nutrNo, ev.target.value)}
                                        onFocus={scrollTextFieldIntoView} />

                                    {isRecipeEditor && (modes[nutrNo] == '%' || dailyValues[nutrNo]) ?
                                        <span>
                                            <Select dropdownIcon="icon-down-arrow" value={modes[nutrNo] == '%' ? '%' : 'v'} options={unitOpts} onChange={() => this.toggleNutrMode(nutrNo)} />
                                        </span>
                                    : null}

                                    {!isRecipeEditor && modes[nutrNo] == '%' ?
                                        <button tabIndex="-1" onClick={() => this.toggleNutrMode(nutrNo)}
                                            title="Percent of daily recommended value contained in one serving">% DV
                                        </button>
                                    : null}

                                    {!isRecipeEditor && modes[nutrNo] !== '%' && dailyValues[nutrNo] ?
                                        <button tabIndex="-1" onClick={() => this.toggleNutrMode(nutrNo)}>{allNutrients[nutrNo].Units}</button>
                                    : null}

                                    {modes[nutrNo] !== '%' && !dailyValues[nutrNo] ?
                                        <span className="no-dropdown">{allNutrients[nutrNo].Units}</span>
                                    : null}
                                </div>
                            </li>
                        )
                    })}
                </ul>

                {fodmaps && servingUnit && servingSize ?
                    <button className="sub-action-btn" onClick={this.showFodmapEntry}>FODMAP entry tool</button>
                : null}
            </div>
        );
    }

    onCompleteFodmaps = (values, available) => {
        this.setState({values, available, isFodmapEntryOpen: false}, this.updateParent);
    }

    onGoogleSearch = () => {
        const { barcode, name } = this.props;

        if(barcode && barcode.trim().length){
            window.open(`https://www.google.com/search?q=${barcode.trim()}`, '_blank');
        } else {
            window.open(`https://www.google.com/search?q=${name.trim()}`, '_blank');
        }
    }

    renderFodmapEntryTool = () => {
        const { isFodmapEntryOpen } = this.state;
        const { food, servingUnit, servingSize } = this.props;
        const { values, available } = this.state;

        if (!isFodmapEntryOpen) {
            return;
        }

        return <FodmapEntryModal food={food}
                    closeModal={this.closeFodmapEntry}
                    servingSize={servingSize} servingUnit={servingUnit}
                    values={values} available={available}
                    onComplete={this.onCompleteFodmaps} />
    }

    render = () => {
        const { sampleSize, sampleUnit } = this.state;
        const { isSampleSizeEditable, isRecipeEditor, barcode, name, image, onShowMore, showAll} = this.props;
        const hasImage = typeof image !== 'undefined';
        const showGoogleLink = !hasImage && ((barcode && barcode.trim().length) || (name && name.trim().length));


        return (
            <section className="admin-nutrition-editor" data-recipe={isRecipeEditor}>
                {showGoogleLink ?
                    <span className="google-link-text"><p onClick={this.onGoogleSearch}>Look up nutrition facts label</p> and enter them below</span>
                : null}
                {hasImage ?
                    <p>
                        <strong>Your photos have been scanned for nutrition data.</strong>
                        <br/>
                        Please edit any missing or incorrect information below. For better results, try uploading a clearer photo of the nutrition label.
                    </p>
                : null}

                {valueNutrients.map(this.renderNutrientGroup)}

                {!showAll && onShowMore ?
                    (<span className="show-more-toggle" onClick={onShowMore}>
                        <button className="sub-action-btn">Show more <i className="icon-chevron-down"></i></button>
                    </span>)
                : null}

                {this.renderFodmapEntryTool()}
            </section>
        );
    }
}
