'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import indexBy from 'lodash.indexby';

import allNutrients from '../../../../tables/nutrients';
import { roundForHumans } from '../../../../utils/Math';
import Dropdown from '../../Widgets/Dropdown.react';

import './PrescriptionMismatch.scss';

export default class PrescriptionMismatch extends Component {
    static propTypes = {
    };

    renderMismatch = (mismatch, key) => {
        const nutrient = allNutrients[mismatch.nutrNo];

        if (!nutrient) {
            return;
        }

        const { value, min, max } = mismatch;

        if (value < min) {
            return (
                <li key={key}>
                    {roundForHumans(min - value)} {nutrient.Units} <em>{nutrient.NutrDesc}</em> too few
                </li>
            );
        }

        if (value > max) {
            return (
                <li key={key}>
                    {roundForHumans(value - max)} {nutrient.Units} <em>{nutrient.NutrDesc}</em> too much
                </li>
            );
        }

        return null;
    }

    render() {
        const { mealType, mismatches } = this.props;

        if (mismatches.length <= 0) {
            return <span />;
        }

        return (
            <div className="customizer-prescription-mismatch-list">
                <div className="customizer-prescription-mismatch-part">
                    <p>{mealType}</p>

                    <ul>
                        {mismatches.map(this.renderMismatch)}
                    </ul>
                </div>
            </div>
        );
    }
}
