'use strict';

import { Component } from 'react';
import Select from '../../Widgets/Select.react';

import portionSizes from '../../../../tables/portions';

import './FamilyMember.scss';
import HouseholdMemberModal from '../../../../components/MyAccount/HouseholdMemberModal.react';

export default class FamilyMember extends Component {
    constructor(props) {
        super(props);

        const { member } = props;
        const {
            name, portion
        } = member;

        this.state = {
            name,
            portion,
            isExpanded: true,
            isEstimating: false,
            meal_types: member.meal_types || [],
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.member.uuid !== this.props.member.uuid) {
            this.setState({isExpanded: false, isEstimating: false});
        }

        if (nextProps.member.name !== this.state.name) {
            this.setState({name: nextProps.member.name});
        }
    }

    updateParent = () => {
        const { name, portion, meal_types } = this.state;
        const { member, onChange } = this.props;

        member.name = name;
        member.portion = portion;
        member.meal_types = meal_types;

        onChange && onChange(member);
    }

    onChangeMealType = (mealType) => {
        const { meal_types } = this.state;

        const existingIndex = meal_types.findIndex(p => p.name === mealType);
        if (existingIndex !== -1) {
            meal_types.splice(existingIndex, 1);
        } else {
            meal_types.push({ name: mealType, enabled: true });
        }

        this.setState({ meal_types, dirty: true }, this.updateParent);
    }

    onChangeName = (ev) => {
        this.setState({name: ev.target.value}, this.updateParent);
    }

    onChangePortion = (portion) => {
        this.setState({portion}, this.updateParent);
    }

    closeModal = () => {
        this.setState({isEstimating: false});
    }

    onChangeFamilyMember = (member) => {
        const { portion } = member;

        this.setState({member, portion}, this.updateParent);

        this.closeModal();
    }

    renderEnergyEstimatorModal = () => {
        const { isEstimating } = this.state;
        const { member, profile, onRemove } = this.props;

        if (!isEstimating) {
            return null;
        }

        return <HouseholdMemberModal
            profile={profile}
            member={member}
            onChange={this.onChangeFamilyMember}
            onDeleteFamilyMember={onRemove}
            closeModal={this.closeModal} />
    }

    render() {
        const { member, onRemove, profile } = this.props;
        const { error, name, portion, isExpanded, meal_types } = this.state;

        const { preferences } = profile;

        const childPortionOpts = [
            {label: '½ Serving', value: 0.5},
            {label: 'Single Serving (1)', value: 1},
        ];

        return (
            <div className="edit-family-member" data-expanded={isExpanded}>
                <div className="always-visible">
                    <div className="name with-label">
                        <label>Name</label>
                        <input data-testid={name} type="text" value={name} onChange={this.onChangeName} />
                    </div>

                    <button className="expand-btn" onClick={() => this.setState({isExpanded: !isExpanded})}>
                        <i className="icon-chevron-right" />
                    </button>
                </div>

                <div className="inset-menu">
                    <section className="with-label meal-types-container" data-error={error == 'meal-type'}>
                        <label>Include {name || 'Family Member'} in:</label>

                        {preferences ? preferences.meal_types
                            .filter(p => p.main_dish != 'Snack')
                            .map(p => (
                                <button key={member.uuid + "-" + p.main_dish}
                                    className="meal-type toggle-button"
                                    data-active={meal_types.some(q => q.name == p.name)}
                                    onClick={() => this.onChangeMealType(p.name)}>
                                    <i className={"icon-" + p.main_dish.toLowerCase()} />
                                    {p.name}
                                </button>
                            ))
                        : null}
                    </section>

                    {member.type !== 'child' ?
                        <div className="with-label portion-size">
                            <label>Portion Size</label>
                            <Select showAbove={true} options={portionSizes} value={portion} onChange={this.onChangePortion} />
                            <button onClick={() => this.setState({isEstimating: true})} className="sub-action-btn">help me choose</button>
                        </div>
                    : null}

                    {member.type === 'child' ?
                        <div className="with-label portion-size">
                            <label>Portion Size</label>
                            <Select showAbove={true} options={childPortionOpts} value={portion} onChange={this.onChangePortion} />
                        </div>
                    : null}

                    <footer>
                        {onRemove ? <button className="sub-action-btn remove-member-btn" onClick={() => onRemove(member)}>
                            <i className="icon-trash-can2" /> Remove {name || 'Family Member'}
                        </button> : null}
                    </footer>
                </div>

                {this.renderEnergyEstimatorModal()}
            </div>
        );
    }
}
