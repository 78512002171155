'use strict';

import React, { Component } from 'react';
import Modal from 'react-modal';
import FoodEditorModalContent from './FoodEditorModalContent.react';
import PropTypes from 'prop-types';

export default class FoodEditorModal extends Component {
    static propTypes = {
        handleBarcodeRetryCount: PropTypes.func,
        resetBarcodeRetryCount: PropTypes.func,
        displayError: PropTypes.bool,
        uuid: PropTypes.string,
        profile: PropTypes.object,
        modalTitle: PropTypes.string,
        contentLabel: PropTypes.string,
        introMessage: PropTypes.node,
        defaultBarcode: PropTypes.string,
        retryBarcodeScan: PropTypes.func,
        onSaveFood: PropTypes.func,
        setSaving: PropTypes.func,
        closeModal: PropTypes.func,
        className: PropTypes.string,
        food: PropTypes.object,
    };

    constructor(props) {
        super(props);
    }

    render() {
        const {
            uuid = null,
            food = null,
            profile = null,
            modalTitle = '',
            contentLabel = '',
            introMessage = null,
            defaultBarcode = '',
            className = '',
            closeModal = '',
            onSaveFood = '',
            retryBarcodeScan = () => {},
            resetBarcodeRetryCount = () => {},
            handleBarcodeRetryCount = () => {},
            displayError = false,
        } = this.props;

        return (
            <Modal
                isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="el-modal el-modal2"
                overlayClassName="el-modal-overlay"
                contentLabel={contentLabel}
            >
                <div className={`el-modal-container el-modal2-container ${className}`}>
                    <header>
                        <h2>{modalTitle}</h2>

                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <FoodEditorModalContent
                        handleBarcodeRetryCount={handleBarcodeRetryCount}
                        resetBarcodeRetryCount={resetBarcodeRetryCount}
                        displayError={displayError}
                        bodyClassName="el-modal2-body-container"
                        uuid={uuid}
                        food={food}
                        profile={profile}
                        introMessage={introMessage}
                        defaultBarcode={defaultBarcode}
                        closeModal={closeModal}
                        onSaveFood={onSaveFood}
                        retryBarcodeScan={retryBarcodeScan}
                    />
                </div>
            </Modal>
        );
    }
}
