'use strict';

import { getParticipantsForMeal, areParticipantsDifferent, getLoggedServingsOfRecipe } from '../utils/Meals';

export function generateOffsets(offset, leftovers) {
    const offsets = [];

    for (let i = parseInt(offset) + 1; i <= leftovers + parseInt(offset); i++) {
        offsets.push(i);
    }

    return offsets;
}

export function getMealAndLeftovers(meal, plan) {
    let parent = null;
    let allMeals = [];

    if (meal.meal_type == 'leftover') {
        parent = plan.items.find(item => item.id === meal.parent);
    } else {
        parent = meal;
    }

    allMeals = plan.items.filter(item => item.parent === parent.id && !item.deleted);
    allMeals.unshift(parent);

    return allMeals;        
}

export function getBatchInfo (offset, mealType, profile, plan) {

    const alreadyScheduledMeal = plan.items.find(m => m.meal === mealType && m.offset === offset && m.meal_type !== "leftover");
    if (alreadyScheduledMeal) {
        const allMeals = getMealAndLeftovers(alreadyScheduledMeal, plan);
        console.log("allMeals", allMeals)
        const totalDays = allMeals.length;
        const participants = getParticipantsForMeal(alreadyScheduledMeal, profile)
        const mealServingsNeededPerDay = participants.reduce((sum, participant) => sum + participant.portion, 0);
        const mealServingsNeeded = mealServingsNeededPerDay * totalDays
        return { participants, mealServingsNeeded, totalDays };
    }

    return;
}


export function recalculateScaling (dish, participants, recipe, totalDays, profile, plan)  {

    let allMeals = getMealAndLeftovers(dish, plan);
    const servings = (recipe.servings || 1);
    let totalNeeded, parent;

    if (dish) {

        if (dish.meal_type == 'leftover') {
            parent = plan.items.find(item => item.id === dish.parent)
        } else {
            parent = dish;
        }

        let baseAmount = 0;
        // Don't count logged amount from removed meals
        if (totalDays < allMeals.length) {
            allMeals = allMeals.slice(0, totalDays);
        }

        // Copy parent meals logged_amount to new meals
        if (totalDays > allMeals.length) {
            const loggedServings = getLoggedServingsOfRecipe(parent, recipe);
            baseAmount = (totalDays - allMeals.length) * loggedServings
        }

        const otherUsersMealServingsNeededPerDay = participants.reduce((sum, participant) => sum + (participant.uuid == profile.uuid ? 0 : participant.portion), 0);
        totalNeeded = (otherUsersMealServingsNeededPerDay * totalDays) + allMeals.reduce((sum, meal) => sum + getLoggedServingsOfRecipe(meal, recipe), baseAmount);
    } else {
        const neededPerMeal = participants.reduce((total, member) => total + member.portion, 0);
        totalNeeded = neededPerMeal * totalDays;       
    }

    let scaling = Math.ceil(totalNeeded / servings);

    return scaling;
}