'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import Modal from 'react-modal';

import ImgResized from '../../Widgets/ImgResized.react';
import Dropdown from '../../../pro/components/Widgets/Dropdown.react';
import Analytics from '../../../utils/Analytics';
import UserStore from '../../../stores/UserStore';

import modalStyles from '../../../jsx-styles/modals';
import defaultImages from '../../../tables/cover-images.js';
import { getConfig } from '../../../utils/Env';
import AuthStore from '../../../stores/AuthStore';

import './CollectionToPdfButton.scss';

const merchantWhitelist = [
    'a99b468d-b123-4f91-b956-44ea219a8507', // Everyday EatLove
    '1b2e20e2-ddbe-4314-85aa-d83bb3e54692', // Ranelle Kirchner, Chef RDN
    '86626fb6-fa10-4642-a627-db0c5c5d13e7', // Leanne Brown
    'b55d3fc7-c709-4ae9-a3a4-edaa713a7b27', // EatLove
];

export default class CollectionToPdfButton extends Component {

    static propTypes = {
        button: PropTypes.node,
        classes: PropTypes.string,
        collection: PropTypes.object,
    };

    static contextTypes = {
        isPro: PropTypes.bool,
        isCordova: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const { plan } = props;

        const params = {};

        let cover_images = defaultImages.slice();

        if (context.isCordova) {
            params.token = AuthStore.getToken();
        }

        this.state = {
            params,

            cover_images,
        }
    }

    toggleParam = (key) => {
        const { params } = this.state;

        if (params[key]) {
            delete params[key];
        } else {
            params[key] = 1;
        }

        this.setState({params});
    }

    setIngredientMode = (mode) => {
        const { params } = this.state;

        if (!mode) {
            delete params.ingr_mode;
        } else {
            params.ingr_mode = mode;
        }

        this.setState({params});
    }

    trackPdfDownload = () => {
        const { collection } = this.props;
        Analytics.downloadCollectionPDF({'Collection UUID': collection.uuid});
    }

    openModal = () => {
        this.setState({isModalOpen: true});
    }

    closeModal = () => {
        this.setState({isModalOpen: false});
    }

    setCoverImage = (url) => {
        const { params } = this.state;

        params.cover_image = url;

        this.setState({params});
    }

    renderPdfDialog = () => {
        const { params, cover_images, isModalOpen } = this.state;
        const { collection } = this.props;
        const { isPro } = this.context;

        if (!isModalOpen) {
            return null;
        }

        const using = params.cover_image || cover_images[0];
        const toPdfUrl = (`https://${getConfig('self_host')}/collections/${collection.uuid}/printable?`) + qs.stringify(params);

        return (
            <Modal isOpen={true}
                closeModal={this.closeModal}
                contentLabel="Meal Plan Print Options"
                style={modalStyles.largeSquareModal}
                className="dialog-form-modal plan-to-pdf-modal"
                overlayClassName="modal-confirmation-overlay"
                closeTimeoutMS={250}>

                <section className="modal-container plan-to-pdf-container">
                    <header>
                        <button onClick={this.closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">
                        <div className="plan-to-pdf-form collection-to-pdf-form">
                            <h1>Recipe Collection Print Options</h1>

                            <h2>Include:</h2>
                            <div className="toggle-group">
                                <button className="toggle-btn" data-selected={params.hide_cover ? false : true} onClick={() => this.toggleParam('hide_cover')}>
                                    Cover Page
                                </button>

                                <button className="toggle-btn" data-selected={params.hide_photos ? false : true} onClick={() => this.toggleParam('hide_photos')}>
                                    Recipe Photos
                                </button>
                            </div>

                            <h2>Ingredients Options:</h2>
                            <div className="toggle-group">
                                <button className="toggle-btn radio-btn" data-selected={params.ingr_mode == 'default' || !params.ingr_mode} onClick={() => this.setIngredientMode()}>
                                    Cups
                                </button>

                                <button className="toggle-btn radio-btn" data-selected={params.ingr_mode == 'grams'} onClick={() => this.setIngredientMode('grams')}>
                                    Grams
                                </button>

                                <button className="toggle-btn radio-btn" data-selected={params.ingr_mode == 'ounces'} onClick={() => this.setIngredientMode('ounces')}>
                                    Ounces
                                </button>
                            </div>

                            <h2>Cover Image:</h2>
                            <ul className="cover-image-picker">
                                {cover_images.map((url, i) => {
                                    return (
                                        <li key={i} data-selected={using === url} onClick={() => this.setCoverImage(url)}>
                                            <ImgResized src={url} width={225} />
                                        </li>
                                    );
                                })}
                            </ul>

                            {isPro ?
                                <p className="admonition">
                                WARNING: This recipe book may contain content, which is licensed for distribution only to&nbsp;
                                your current clients. If you'd like to use this content for other purposes, please contact&nbsp;
                                <a href="mailto:support@eatlove.is?subject=Licensing Recipes">support@eatlove.is</a><br /><br />
                                You may also enter and exclusively use your own recipe content for which you may share for any purpose.
                                </p>
                            : null}

                            {!isPro ?
                                <p className="admonition">
                                WARNING: This recipe book may contain content, which is licensed for your personal use only.&nbsp;
                                If you'd like to use this content for other purposes, please contact&nbsp;
                                <a href="mailto:support@eatlove.is?subject=Licensing Recipes">support@eatlove.is</a><br /><br />
                                You may also enter and exclusively use your own recipe content for which you may share for any purpose.
                                </p>
                            : null}
                        </div>
                    </div>

                    <footer className="modal-action-footer">
                        <button className="cancel-btn" onClick={this.closeModal}>Close</button>
                        <a target="_blank" onClick={this.trackPdfDownload} href={toPdfUrl}>
                            <button className="ok-btn">Generate</button>
                        </a>
                    </footer>
                </section>
            </Modal>
        );
    }


    render = () => {
        const { classes, button } = this.props;

        return (
            <>
                <button className={classes || "ok-btn"} onClick={this.openModal}>{button || <i className="icon-print" />}</button>
                {this.renderPdfDialog()}
            </>
        );
    }
}
