'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './ClearFilters.scss';

export default class ClearFilters extends Component {
    static propTypes = {
    };

    static contextTypes = {
        isAddSwap: PropTypes.bool,
    };

    isParamsClear = () => {
        const { params } = this.props;
        const { types = [], filters = {} } = params;

        const decision = (filters.tags || []).length === 0 &&
                         (filters['!tags'] || []).length === 0 &&
                         // (filters.ingredient_tags || []).length === 0 &&
                         (filters['!ingredient_tags'] || []).length === 0 &&
                         !filters.breakfasts &&
                         !filters.lunches &&
                         !filters.dinners;

        return decision;
    }

    resetFilters = () => {
        const { params, onChangeParams, allowedTypes } = this.props;
        const { isAddSwap } = this.context;

        const newParams = {
            terms: '',
            types: allowedTypes.slice(),
            filters: {
                library: false,
                tags: [],
            },
            from: 0,
            size: 12,
            sort_by: 'published',
        };

        // If there's a language parameter, we're going to keep it.
        if (params.language) {
            newParams.language = params.language;
        }

         // If there's a include_merchants parameter, we're going to keep it.
        if (params.include_merchants) {
            newParams.include_merchants = params.include_merchants;
        }

        onChangeParams(newParams, true);
    }

    render() {
        if (this.isParamsClear()) {
            return <span />
        }

        return (
            <div className="global-search-clear-filters" onClick={this.resetFilters}>CLEAR ALL</div>
        );
    }
}
