'use strict';

import indexBy from 'lodash.indexby';

import { fetchDocumentsById } from './Content';

export function getAssetsForBoards(boards) {
    // Get a list of uuids from the contents of the boards

    let uuids = [];

    boards.forEach(board => {
        uuids = uuids.concat((board.contents || []).map(content => content.resource_id));
    });

    return new Promise((resolve, reject) => {
        fetchDocumentsById(uuids).then(documents => {
            const recipes     = indexBy(documents.filter(doc => doc?.type === 'recipe'), 'uuid'),
                  plans       = indexBy(documents.filter(doc => doc?.type === 'plan'), 'uuid'),
                  combos      = indexBy(documents.filter(doc => doc?.type === 'combo'), 'uuid'),
                  foods       = indexBy(documents.filter(doc => doc?.type === 'food'), 'uuid'),
                  collections = indexBy(documents.filter(doc => doc?.type === 'collection'), 'uuid');

            resolve({recipes, plans, combos, foods, collections});
        });
    });
}

export function getImageForBoard(board, recipes, plans) {
    let contents = (board.contents || []).filter(c => recipes[c.resource_id] || plans[c.resource_id]);

    // Filter out items that don't have images
    contents = contents.map(c => {
        if (c.resource_type === 'recipe') {
            return recipes[c.resource_id].image;
        }

        if (c.resource_type === 'plan') {
            const plan = plans[c.resource_id];

            return plan.image;
        }

        return false;
    }).filter(image => image);

    if (!contents.length) {
        return false;
    }

    return contents[0];
}
