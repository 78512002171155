'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import Dropdown from '../../../pro/components/Widgets/Dropdown.react';

import './PublisherSubmenu.scss';

export default class PublisherSubmenu extends Component {
    static propTypes = {
        resource: PropTypes.object,
    };

    static contextTypes = {
        confirm: PropTypes.func,
        onDuplicate: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            isMenuOpen: false,
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        // Is this the same resource?
        const currentResource = this.props.resource;
        const newResource = nextProps.resource;

        if (!currentResource || !newResource) {
            return this.setState({isMenuOpen: false});
        }

        // Close the menu if the resource changed
        if (currentResource.uuid != newResource.uuid) {
            this.setState({isMenuOpen: false});
        }
    }

    toggleMenu = () => {
        this.setState({isMenuOpen: !this.state.isMenuOpen});
    }

    duplicate = () => {
        const { resource } = this.props;
        const { onDuplicate } = this.context;

        onDuplicate(resource);
    }

    render() {
        const { isMenuOpen } = this.state;
        const { onDuplicate } = this.context;

        const { resource } = this.props;

        if (!resource) {
            return <span />
        }

        const actions = [];

        if (resource.type === 'recipe') {
            actions.push(
                <Link key="prev" to={`/recipes/${resource.uuid}`}>
                    <button className="action-btn">Preview</button>
                </Link>
            );
        } else if (resource.type === 'plan') {
            actions.push(
                <Link key="prev" to={`/menus/${resource.uuid}/customize`}>
                    <button className="action-btn">Preview</button>
                </Link>
            );
        }

        if (onDuplicate) {
            actions.push(<button key="dup" className="action-btn" onClick={this.duplicate}>Duplicate</button>);
        }

        if (actions.length > 0) {
            return (
                <Dropdown className="publisher-submenu" button={<i className="icon-ellipsis1" />}>
                    {actions}
                </Dropdown>
            );
        }

        return <span />
    }
}
