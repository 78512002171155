'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import ChatConstants from '../constants/ChatConstants';

export default {
    connect: function() {
        AppDispatcher.handleViewAction({actionType: ChatConstants.CHAT_CONNECT});
    },

    disconnect: function() {
        AppDispatcher.handleViewAction({actionType: ChatConstants.CHAT_DISCONNECT});
    },

    sendPrivateMessage: function(recipient, messageText, attachments) {
        AppDispatcher.handleViewAction({
            actionType: ChatConstants.CHAT_SEND_PRIVATE_MESSAGE,
            recipient, messageText, attachments,
        });
    },

    loadMessagesBefore: function(recipient, beforeId = null) {
        setTimeout(() => {
            AppDispatcher.handleViewAction({actionType: ChatConstants.CHAT_LOAD_BEFORE, recipient, beforeId});
        });
    },

    markChatAsRead: function(jid, type) {
        AppDispatcher.handleViewAction({actionType: ChatConstants.CHAT_MARK_AS_READ, jid, type});
    },

    updateChat: function(chat) {
        AppDispatcher.handleViewAction({actionType: ChatConstants.CHAT_UPDATE_CHAT, chat});
    },
};
