'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

import './DownForMaintenanceModal.scss';
import modalStyle from '../../../jsx-styles/modals';

export default class DownForMaintenanceModal extends Component {

    render() {
        const { message } = this.props;

        return (
            <Modal isOpen={true}
                closeModal={() => false}
                style={modalStyle.confirmDialog}
                contentLabel="Down for Maintenance"
                className="modal-confirmation">

                <section className="modal-main down-for-maintenance">
                    <img src="https://static.chewba.info/images/logo-icon-2018-08.png" />
                    <h1>Service Unavailable</h1>

                    {message ? <pre>{message}</pre> : null}

                    <p>EatLove is currently making upgrades to bring you the best service we can!</p>
                    <p>Please be patient with our dust as we perform necessary upgrades to our systems.</p>
                </section>
            </Modal>
        );
    }
}
