'use strict';

export default {
    '19817ea2-2ae4-4602-8965-e8f4ef68ea1a': {
        name: 'Sunbasket',
        provider: 'sunbasket',
        logo: 'https://static.chewba.info/images/sunbasket-logo.svg',
        dark_logo: 'https://static.chewba.info/images/sunbasket-logo-white.svg',
    },
};
