'use strict';

export default {
    'advanced_avoidances': {
        options: ['all','active'],
        access_name: 'advanced avoidances',
        blurbs: {
            'dietetics': [
                'Highly customizable avoidance settings',
                'Option to select avoidances from 70+ foods and 400+ categories',
                'Advanced nutrition prescriptions',
            ],
            'fitness': [
                'Highly customizable avoidance settings',
                'Option to select avoidances from 70+ foods and 400+ categories',
                'Advanced nutrition templates',
            ],
            'consumer': [
                'Highly customizable avoidance settings',
                'Option to select avoidances from 70+ foods and 400+ categories',
                'Advanced nutrition templates',
            ],
        },
    },
    'create_content': {
        options: ['all','active'],
        access_name: 'creating content',
        blurbs: {
            'dietetics': [
                'Add your own recipes, auto-calculate nutrition',
                'Edit any of the 6000+ recipes available on EatLove',
                'Create recipe collections',
            ],
            'fitness': [
                'Add your own recipes, auto-calculate nutrition',
                'Edit any of the 6000+ recipes available on EatLove',
                'Create recipe collections',
            ],
            'consumer': [
                'Add your own recipes, auto-calculate nutrition',
                'Edit any of the 6000+ recipes available on EatLove',
                'Create recipe collections',
            ],
        },
    },
    'create_dietitian': {
        options: ['all','active','limited','limited_active'],
        limits: 'create_dietitian_limit',
        access_name: 'adding team members',
        blurbs: {
            'dietetics': [
                'Invite colleagues from your practice',
                'Share client records between practitioners',
                'Collaborate to provide services',
            ],
            'fitness': [
                'Invite colleagues from your practice',
                'Share client records between colleagues',
                'Collaborate to provide services',
            ],
        },
    },
    'create_patient': {
        options: ['all','active','limited','limited_active'],
        limits: 'create_patient_limit',
        access_name: 'inviting clients',
        blurbs: {
            'dietetics': [
                'Access additional patient seats',
                'Premium business toolkit content to help you grow your business',
                'Invitation to join the EatLove Insiders FB Group',
                'Receive new patient referrals',
            ],
            'fitness': [
                'Access additional client seats',
                'Premium business toolkit content to help you grow your business',
                'Invitation to join the EatLove Fitness FB Group',
            ],
        },
    },
    'edit_macros': {
        options: ['all','active'],
        access_name: 'the ability to edit macros',
        blurbs: {
            'dietetics': [
                'Fully customizable macro and micronutrients',
                'Advanced nutrition prescriptions',
                'Advanced avoidance settings',
            ],
            'fitness': [
                'Fully customizable macronutrients',
                'Advanced nutrition templates',
                'Advanced avoidance settings',
            ],
        },
    },
    'edit_micros': {
        options: ['all','active'],
        access_name: 'the ability to edit micros',
        blurbs: {
            'dietetics': [
                'Fully customizable macro and micro nutrients',
                'Advanced nutrition prescriptions',
                'Advanced avoidance settings',
            ],
            'fitness': [
                'Fully customizable micronutrients',
                'Advanced nutrition templates',
                'Advanced avoidance settings',
            ],
        },
    },
    'follow_clients': {
        options: ['all','active'],
        access_name: 'following clients',
    },
    'meal_planner': {
        options: ['all','active'],
        access_name: 'the meal planner',
        blurbs: {
            'dietetics': [
                'Schedule meal plans',
                'Access premium recipes',
                'Access detailed nutrition information on recipes',
            ],
            'fitness': [
                'Schedule meal plans',
                'Access premium recipes',
                'Access detailed nutrition information on recipes',
            ],
            'consumer': [
                'Daily meal recommendations personalized to to fit your nutrition profile',
                'Easily swap for smart meals or restaurant choices that keep you on track',
                'Access premium recipes with detailed nutrition information',
                'Grocery lists with optional online ordering',
            ],
        }
    },
    'messaging': {
        options: ['all','active'],
        access_name: 'messaging',
    },
    'native_app': {
        options: ['all','active'],
        access_name: 'the native app',
    },
    'nutrition_info': {
        options: ['all','active'],
        access_name: 'the nutrition education program',
        blurbs: {
            'dietetics': [
                'Out-of-the-box 23 week education program with weekly nutrition topics any client will enjoy',
                'Delivered weekly via email to your clients',
                'Developed by dietitians to streamline clients healthy habits one step at a time',
            ],
            'fitness': [
                'Out-of-the-box 23 week education program with weekly nutrition topics any client will enjoy',
                'Delivered weekly via email to your clients',
                'Developed by dietitians to streamline your client’s healthy habits one step at a time',
            ],
        },
    },
    'premium_conditions': {
        options: ['all','active'],
        access_name: 'premium health conditions',
        blurbs: {
            'dietetics': [
                'Access to premium MNT nutrition prescriptions designed to address medical conditions',
                'Advanced customization of over 95 nutrients',
                'Advanced avoidance settings',
            ],
            'fitness': [
                'Access to premium nutrition templates designed to address your client’s goals and preferences',
                'Advanced customization of over 95 nutrients',
                'Advanced avoidance settings',
            ],
        },
    },
    'premium_toolkit': {
        options: ['all','active'],
        access_name: 'premium toolkit resources',
        blurbs: {
            'dietetics': [
                'Access to our Premium Business Toolkit designed to help you grow your business',
                'Invitation to join EatLove Insiders FB Group',
                'Invitation to join weekly EatLove office hours to solve challenging business problems',
            ],
            'fitness': [
                'Access to our Premium Business Toolkit designed to help you grow your business',
                'Invitation to join EatLove Fitness FB Group',
                'Invitation to join Weekly EatLove Office Hours to solve challenging business problems',
            ],
        },
    },
    'whitelabel_handouts': {
        options: ['all','active'],
        access_name: 'whitelabel nutrition handouts',
        blurbs: {
            'dietetics': [
                'Promote your brand by white labeling our high quality nutrition handouts',
                'Access to our Premium Business Toolkit designed to help you grow your business',
                'Invitation to join weekly EatLove office hours to solve challenging business problems',
            ],
            'fitness': [
                'Promote your brand by white labeling our high quality nutrition handouts',
                'Access to our Premium Business Toolkit designed to help you grow your business',
                'Invitation to join Weekly EatLove Office Hours to solve challenging business problems',
            ],
        },
    },
    'print_collection': {
        options: ['all','active'],
        access_name: 'printing collections',
    },
    'print_plan': {
        options: ['all','active'],
        access_name: 'printing meal plans',
        blurbs: {
            'dietetics': [
                'Print beautifully designed, personalized meal plans as patient handouts',
                'Insert care plan, custom graphics, professional recipe images, cover page, grocery lists, and more.',
            ],
        },
    },
    'print_recipe': {
        options: ['all','active'],
        access_name: 'printing recipes',
        blurbs: {
            'dietetics': [
                'Print high quality recipes as patient handouts',
                'Professionally photographed recipes',
                '6000+ dietitian approved recipes',
            ],
            'fitness': [
                'Print high quality recipes as client handouts',
                'Professionally photographed recipes',
                '6000+ dietitian approved recipes',
            ],
            'consumer': [
                'Print high quality recipes',
                'Professionally photographed',
                '6000+ dietitian approved recipes',
            ],
        },
    },
    'purchase_membership': {
        options: ['all', 'expired'],
        access_name: 'purchasing memberships',
    },
    'recommend_collection': {
        options: ['all','cooldown','active'],
        access_name: 'recommending a collection',
        blurbs: {
            'dietetics': [
                'Share your collections with an unlimited number of patients',
                'Allow patients to favorite your collections for continued use',
                'Use collections to educate your patients on healthy choices',
            ],
            'fitness': [
                'Share your collections with an unlimited number of clients',
                'Allow clients to favorite your collections for continued use',
                'Use collections to educate your clients on healthy choices',
            ],
        },
    },
    'recommend_plan': {
        options: ['all','cooldown','active'],
        access_name: 'recommending a plan',
        blurbs: {
            'dietetics': [
                'Generate on-demand personalized meal plans',
                'Recommend meal plans to your patients for use in their daily meal feed',
                'Edit meal plans before recommending',
            ],
            'fitness': [
                'Generate on-demand personalized meal plans',
                'Recommend meal plans to your clients for use in their daily meal feed',
                'Edit meal plans before recommending',
            ],
        },
    },
    'recommend_recipe': {
        options: ['all','cooldown','active'],
        access_name: 'recommending a recipe',
        blurbs: {
            'dietetics': [
                'Share unlimited recipes with your patients',
                'Choose from 6000+ dietitian approved recipes',
                'Allow patients to favorite your recommendations for continued use',
            ],
            'fitness': [
                'Share unlimited recipes with your clients',
                'Choose from 6000+ dietitian approved recipes',
                'Allow clients to favorite your recommendations for continued use',
            ],
        },
    },
    'safe_mode': {
        options: ['all','active'],
        access_name: 'safe mode features',
        blurbs: {
            'dietetics': [
                'Access safe mode features for your patients',
                'Advanced customization of over 95 nutrients',
                'Advance avoidance settings',
            ],
            'fitness': [
                'Access safe mode features for your clients',
                'Advanced customization of over 95 nutrients',
                'Advance avoidance settings',
            ],
        },
    },

    'export_nutrition': {
        options: ['all','active'],
        access_name: 'export nutrition',
        blurbs: {
            'dietetics': [
                'Expert nutrition analysis report based on real-time data',
                'Additional client seats',
                'Advanced coaching features',
            ],
            'fitness': [
                'Expert nutrition analysis report based on real-time data',
                'Additional client seats',
                'Advanced coaching features',
            ],
            'consumer': [
                'Expert nutrition analysis report based on real-time data',
                'Additional client seats',
                'Advanced coaching features',
            ],
        },
    }
};
