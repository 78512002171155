'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import allTags from '../../../tables/tags';

import './SkillLevel.scss';

export default class SkillLevel extends Component {
    static propTypes = {
        params: PropTypes.object,
        onChangeParams: PropTypes.func,
    };

    setSkillLevel = (skillLevel) => {
        const { params, onChangeParams } = this.props;

        params.filters = params.filters || {};

        // Remove skill level tags from filter in and filter out tags
        params.filters['!tags'] = (params.filters['!tags'] || []).filter(tag => !allTags.skill.tags.includes(tag));
        params.filters.tags     = (params.filters.tags || []).filter(tag => !allTags.skill.tags.includes(tag));

        if (skillLevel == 'Beginner') {
            params.filters['!tags'].push('Intermediate');
            params.filters['!tags'].push('Advanced');
        } else if (skillLevel == 'Intermediate') {
            params.filters['!tags'].push('Advanced');
        } else if (skillLevel == 'Advanced') {
            params.filters.tags.push('Advanced');
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;

        const filterInTags  = (params.filters.tags || []);
        const filterOutTags = (params.filters['!tags'] || []);

        let skillType = false;

        if (filterOutTags.includes('Intermediate') && filterOutTags.includes('Advanced')) {
            skillType = 'Beginner';
        } else if (!filterOutTags.includes('Intermediate') && filterOutTags.includes('Advanced')){
            skillType = 'Intermediate';
        } else if (filterInTags.includes('Advanced')) {
            skillType = 'Advanced';
        }

        return (
            <div className="skill-level-filter">
                <ul>
                    <li>
                        <label className="global-search-checkbox global-search-radio">
                            <input type="checkbox" checked={skillType == false}
                                   onChange={e => this.setSkillLevel(false)} />
                            <span>All Skill Levels (default)</span>
                        </label>
                    </li>
                    <li>
                        <label className="global-search-checkbox global-search-radio">
                            <input type="checkbox" checked={skillType == 'Beginner'}
                                   onChange={e => this.setSkillLevel('Beginner')} />
                            <span>Beginner</span>
                        </label>
                    </li>
                    <li>
                        <label className="global-search-checkbox global-search-radio">
                            <input type="checkbox" checked={skillType == 'Intermediate'}
                                   onChange={e => this.setSkillLevel('Intermediate')} />
                            <span>Intermediate</span>
                        </label>
                    </li>
                    <li>
                        <label className="global-search-checkbox global-search-radio">
                            <input type="checkbox" checked={skillType == 'Advanced'}
                                   onChange={e => this.setSkillLevel('Advanced')} />
                            <span>Advanced</span>
                        </label>
                    </li>
                </ul>
            </div>
        );
    }
}
