'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import BoardStore from '../../../stores/BoardStore';
import ImgResized from '../../Widgets/ImgResized.react';

import './CollectionBanner.scss';

export default class CollectionBanner extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount = () => {
        const { collection } = this.props;

        const boards = BoardStore.getBoardsByResourceId(collection.uuid);
        const contents = boards.reduce((carry, board) => {
            const bcs = board.contents.filter(
                ({dietitian_recommendation, resource_id}) => dietitian_recommendation && resource_id === collection.uuid
            );

            return carry.concat(bcs);
        }, []);

        const content = contents[0];

        if (!content) {
            return;
        }

        this.setState({content});
    }

    render() {
        const { content } = this.state;

        if (!content) {
            return <span />;
        }

        return (
            <div className="editor-dietitian-banner collection-banner">
                {content.author_image ? <ImgResized debug={true} src={content.author_image} width="100" height="100" /> : null}
                <span>Your recipe collection from <em>{content.author_name}</em></span>
                {content.notes ? <p>&ldquo;{content.notes}&rdquo;</p> : null}
            </div>
        );
    }
}
