'use strict'

import uuid from 'uuid';
import moment from 'moment';

import AppDispatcher from '../dispatcher/AppDispatcher';
import GroceryConstants from '../constants/GroceryConstants';
import GroceryStore from '../stores/GroceryStore';
import UserStore from '../stores/UserStore';
import MealStore from '../stores/MealStore';
import {
    updateGroceryPackaging,
    getGroceriesForMeals,
    isMealInGroceries,
    isMealOrderPurchased
} from '../utils/Grocery';

import Analytics from '../utils/Analytics';
import { calculateQuantityByNewMeals, getAssetsForMeals } from '../utils/Meals';
import store from 'store';

const LOCALSTORAGE_KEY = 'grocery_review_submitted';

function extricateMealsFromGroceries(meals, groceries, recipes, details, foods) {
    let dirtyMeals = [], dirtyGroceries = [];

    // Subtract the meals grams from the groceries grams.
    // Remove the uuid from meal_uuids
    // If any of the grocery items is zero grams or less, delete it instead of updating it.
    meals.forEach(meal => {
        // Only consider fresh meals.
        if (!['fresh', 'food'].includes(meal.meal_type)) {
            return;
        }

        // Do not remove if the meal isn't actually in the groceries.
        if (!isMealInGroceries(meal, groceries, false)) {
            return;
        }

        const inverseMeal = {
            ...meal,
            scaling: -meal.scaling_added_to_groceries, // only remove what's been added
            logged_grams: meal.grams_added_to_groceries ? -meal.grams_added_to_groceries : 0,
            logged_milliliters: meal.milliliters_added_to_groceries ? -meal.milliliters_added_to_groceries : 0
        };

        let mealGroceries = getGroceriesForMeals([inverseMeal], {recipes, details, foods});

        mealGroceries = Object.keys(mealGroceries).map(key => mealGroceries[key])
        meal.scaling_added_to_groceries = 0;
        dirtyMeals.push(meal);

        // Now merge & accumulate grams
        mealGroceries.forEach(mealGrocery => {
            // Does this grocery exist in the list already? We'll find this one
            // ONLY by food_uuid (we don't want to accidently remove items the user added manually)
            let grocery = groceries.find(g => !g.deleted &&
                                              (g.food_uuid && g.food_uuid == mealGrocery.food_uuid ||
                                               g.product_uuid && g.product_uuid == mealGrocery.product_uuid ||
                                               g.grocery && g.grocery == mealGrocery.name));

            // If grocery is not found in the list, well, we can't remove that which isn't there.
            if (!grocery) {
                return;
            }

            // If the meal's uuid isn't in the list, well, we should not touch those either
            if ((grocery.meal_uuids || '').indexOf(meal.uuid) == -1) {
                return;
            }

            // Remove the uuid from the list
            let mealUuids = (grocery.meal_uuids || '').split(','),
                i = mealUuids.indexOf(meal.uuid);

            if (i != -1) {
                mealUuids.splice(i, 1);
            }

            grocery.meal_uuids = mealUuids.join(',');

            if (mealGrocery.grams_needed < 0) {
                grocery.grams_needed += mealGrocery.grams_needed;
            }

            if (mealGrocery.milliliters_needed < 0) {
                grocery.milliliters_needed += mealGrocery.milliliters_needed;
            }

            if (dirtyGroceries.indexOf(grocery) == -1) {
                dirtyGroceries.push(grocery);
            }
        });
    });

    return { dirtyMeals, dirtyGroceries };
}

const GroceryActions = {
    load: (keywords, filters) => {
        AppDispatcher.handleViewAction({
            actionType: GroceryConstants.GROCERIES_LOAD,
        });
    },

    upsertGroceries: (groceries) => {
        AppDispatcher.handleViewAction({
            actionType: GroceryConstants.GROCERIES_UPSERT,
            groceries,
        });
        if (store.get(LOCALSTORAGE_KEY)) {
            Analytics.onGroceryListChange();
            store.remove(LOCALSTORAGE_KEY);
        }
    },

    deleteGroceries: (groceries) => {
        AppDispatcher.handleViewAction({
            actionType: GroceryConstants.GROCERIES_DELETE,
            groceries,
        });
        if (store.get(LOCALSTORAGE_KEY)) {
            Analytics.onGroceryListChange();
            store.remove(LOCALSTORAGE_KEY);
        }
    },

    mealFeedRegen: () => {
        AppDispatcher.handleViewAction({actionType: GroceryConstants.GROCERIES_FEED_REGEN});
    },

    /**
     * Synchronizes meals to the grocery list and stores the new result
     * in the Grocery Store.
     *
     * @return array Of dirty meals (ones that had to be synced)
     */
    syncMealsToGroceries: (meals, recipes, details, foods) => {
        let groceries = GroceryStore.getGroceries();
        let user = UserStore.getUser();
        let dirtyGroceries = [];
        const allOrders = MealStore.getOrders();
        const allMeals = MealStore.getMeals();

        // Which of these meals are already in the grocery list?
        const mealsAlreadyInGroceries = meals.filter(meal => isMealInGroceries(meal, groceries));
        const mealsAlreadyPurchased = meals.filter(meal => isMealOrderPurchased(meal, allOrders));

        // Always ensure these meals are removed
        extricateMealsFromGroceries(meals, groceries, recipes, details, foods);
        // And then get their new values fresh
        let mealGroceries = getGroceriesForMeals(
            meals.filter(m => ['fresh', 'food'].includes(m.meal_type)),
            {recipes, details, foods}
        );

        mealGroceries = Object.keys(mealGroceries).map(key => mealGroceries[key]);

        // Set all the meals to the correct scaling
        meals.forEach(m => m.scaling_added_to_groceries = m.scaling);
        meals.forEach(m => m.grams_added_to_groceries = m.logged_grams);
        meals.forEach(m => m.milliliters_added_to_groceries = m.logged_milliliters);

        meals.forEach(meal => {
            if(foods[meal.food_uuid] && foods[meal.food_uuid].mapped_from) {
                meal.meal_kit_provider = foods[meal.food_uuid].mapped_from[0].provider;
                meal.cost = foods[meal.food_uuid].mapped_from[0].price * meal.scaling_added_to_groceries;
            }
        });

        // Strip out any grocery explicitly excluded from the list
        mealGroceries = mealGroceries.filter(g => !g.exclude_from_groceries);

        // Now merge & accumulate grams
        mealGroceries.forEach(mealGrocery => {
            // Does this grocery exist in the list already? We'll find it either
            // by food UUID or by case-insensitive name matching
            let grocery = groceries.find(g => !g.deleted &&
                                              (g.food_uuid && g.food_uuid == mealGrocery.food_uuid ||
                                               g.grocery && g.grocery == mealGrocery.name));

            const meal = mealGrocery.meals[0];

            if (!grocery) {
                // If this meal was already in the grocery list, but the food
                // item was not, the user deleted it. Don't re-add it.
                if (meal && mealsAlreadyInGroceries.indexOf(meal) != -1) {
                    return;
                }

                grocery = {
                    uuid: uuid.v4(),
                    created: moment().format(),
                    food_uuid: mealGrocery.food_uuid,
                    grocery: mealGrocery.name,
                    brand_name: mealGrocery.brand_name,
                    category: mealGrocery.category,
                    grams_needed: 0, // Will be added below
                    milliliters_needed: 0,
                    quantity: 0,
                };

                groceries.push(grocery);
            }

            const quantityRecalculated = calculateQuantityByNewMeals(
                mealGrocery.meals,
                grocery,
                foods,
                recipes,
                allMeals,
                user
            );

            grocery.quantity = quantityRecalculated;

            if (meal && mealsAlreadyPurchased.indexOf(meal) != -1) {
                grocery.status = 'purchased';
            }

            // If this grocery isn't already registered as dirty, register it as dirty
            if (dirtyGroceries.indexOf(grocery) == -1) {
                dirtyGroceries.push(grocery);
            }

            // That's not a confusing name or anything...
            // Add the reference to the meal to the grocery items meal uuids
            mealGrocery.meals.forEach(groceryMeal => {
                grocery.meal_uuids = grocery.meal_uuids || '';

                if (grocery.meal_uuids.indexOf(groceryMeal.uuid) == -1) {
                    let mealUuids = grocery.meal_uuids.split(',').filter(v => v);

                    mealUuids.push(groceryMeal.uuid);

                    // Shift off the front until the length is less than 100
                    while(mealUuids.length > 100) {
                        mealUuids.shift();
                    }

                    grocery.meal_uuids = mealUuids.join(',');
                }
            });

            if (mealGrocery.grams_needed) {
                grocery.grams_needed += mealGrocery.grams_needed;
            }

            if (mealGrocery.milliliters_needed) {
                grocery.milliliters_needed += mealGrocery.milliliters_needed;
            }

            // Negative mass? Don't think so, otherwise I'd just run to the
            // grocery store to fill up my warp starships tanks.
            if (grocery.grams_needed < 0) {
                grocery.grams_needed = 0;
            }

            if (grocery.milliliters_needed < 0) {
                grocery.milliliters_needed = 0;
            }
        });

        dirtyGroceries = updateGroceryPackaging(dirtyGroceries, foods, user.units_mode);

        GroceryActions.upsertGroceries(dirtyGroceries);

        return { dirtyMeals: meals, dirtyGroceries };
    },

    asyncAddMealsToGroceries(meals) {
        return new Promise((accept, reject) => {
            getAssetsForMeals(meals).then(({recipes, details, foods}) => {
                accept(GroceryActions.syncMealsToGroceries(meals, recipes, details, foods));
            });
        });
    },

    removeMealsFromGroceries: (meals, recipes, details, foods) => {
        let user = UserStore.getUser();
        let { dirtyGroceries, dirtyMeals } = extricateMealsFromGroceries(
            meals,
            GroceryStore.getGroceries(),
            recipes, details, foods
        );

        // Dirty groceries might contain stuff that has zero needed grams - delete them
        // instead of updating them.
        let deleteGroceries = dirtyGroceries.filter(dg => (dg.grams_needed <= 0.1 && dg.milliliters_needed <= 0.1) || !dg.meal_uuids);

        // Remove the deletable groceries from updates
        dirtyGroceries = dirtyGroceries.filter(dg => !deleteGroceries.includes(dg));

        // Update the grocery to the most appropriate package size
        dirtyGroceries = updateGroceryPackaging(dirtyGroceries, foods, user.units_mode);

        if (dirtyGroceries.length > 0) {
            GroceryActions.upsertGroceries(dirtyGroceries);
        }

        if (deleteGroceries.length > 0) {
            GroceryActions.deleteGroceries(deleteGroceries);
        }

        return { dirtyMeals, dirtyGroceries, deleteGroceries };
    },

    asyncRemoveMealsFromGroceries(meals) {
        return new Promise((accept, reject) => {
            getAssetsForMeals(meals).then(({recipes, details, foods}) => {
                accept(GroceryActions.removeMealsFromGroceries(meals, recipes, details, foods));
            });
        });
    },

    startRefresh() {
        AppDispatcher.handleViewAction({
            actionType: GroceryConstants.GROCERIES_REFRESH_START,
        });
    },

    stopRefresh() {
        AppDispatcher.handleViewAction({
            actionType: GroceryConstants.GROCERIES_REFRESH_STOP,
        });
    },

    hydrateGroceries(groceries) {
        AppDispatcher.handleViewAction({
            actionType: GroceryConstants.GROCERIES_HYDRATE,
            groceries,
        });
    }
};

export default GroceryActions;
