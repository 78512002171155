"use strict";

import React, { Component } from "react";

export default class CostPerServingParams extends Component {
    static propTypes = {};

    removeAvgCostPerServing = () => {
        const { params, onChangeParams } = this.props;

        delete params.filters.avg_cost_per_serving;

        onChangeParams(params);
    };

    render() {
        const { params } = this.props;
        if (!(params.filters && params.filters.avg_cost_per_serving)) {
            return <span />;
        }

        const { gte, lte } = params.filters.avg_cost_per_serving;

        let label = false;

        if (gte && lte) {
            label = `Between $${gte.toFixed(2)} and $${lte.toFixed(2)}`;
        } else if (gte && !lte) {
            label = `More than $${gte.toFixed(2)}`;
        } else if (!gte && lte) {
            label = `Less than $${lte.toFixed(2)}`;
        }

        if (!label) {
            return <span />;
        }

        return (
            <div className="param-item" onClick={this.removeAvgCostPerServing}>
                Cost Per Serving: {label} <i className="icon-close-x" />
            </div>
        );
    }
}
