'use strict';

import React, { Component } from 'react';
import UserStore from '../../../stores/UserStore';
import { conditionNamesToSpecialName } from '../../../utils/Conditions';
import PropTypes from 'prop-types';

export default class MyPreferences extends Component {

    static contextTypes = {
        nutritionPatterns: PropTypes.array
    };

    clearPreferences = (filterName) => {
        const { nutritionPatterns } = this.context;
        const { params, onChangeParams } = this.props;

        // Are there any avoidances associated with this prescription? Remove them from the parameters
        const user = UserStore.getUser();
        let removeAvoidances = [];

        // This adds all avoidances from the users conditions to the skip list, so we don't show them
        // in addition to the my prescription filter param.
        let { conditions = [] } = (user || {});
        conditions.map(c => nutritionPatterns.filter(cd => cd.name == c.name)[0]).filter(v => v)
                  .forEach(({avoidances = []}) => {
                      avoidances.forEach(avoidance => !removeAvoidances.includes(avoidance) && removeAvoidances.push(avoidance))
                  });

        // Remove all the avoidances that come from conditions from the avoidance list.
        params.filters['!ingredient_tags'] = (params.filters['!ingredient_tags'] || []).filter(avoids => !removeAvoidances.includes(avoids));

        // Finally, remove the prescription constraints themselves.
        delete params.filters[filterName];

        onChangeParams(params, true);
    }

    render() {
        const { params = {} } = this.props;
        const { filters = {} } = params;
        const user = UserStore.getUser();
        const conditions = user ? (user.conditions || []).map(c => c.name) : [];
        const { hide_nutrition = false } = user || {};
        const specialNames = conditionNamesToSpecialName(conditions);

        const items = [];

        if (user && filters.preferences) {
            items.push(
                <div className="param-item" onClick={() => this.clearPreferences('preferences')} key="preferences">
                    Fits {specialNames.length && !hide_nutrition ? specialNames.join(', ') : 'Your Prescription'} <i className="icon-close-x" />
                </div>
            );
        }

        if (user && filters.strict_preferences) {
            items.push(
                <div className="param-item" onClick={() => this.clearPreferences('strict_preferences')} key="strict_preferences">
                    Standalones Fitting Rx <i className="icon-close-x" />
                </div>
            );

        }

        return (
            <span>{items}</span>
        );
    }
}
