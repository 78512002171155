'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router';

import ImgResized from '../../Widgets/ImgResized.react';
import FavoriteButton from '../../Widgets/FavoriteButton.react';
import PublisherSubmenu from '../../Publisher/Dashboard/PublisherSubmenu.react';

import { toHuman } from '../../../utils/Duration';

import './RecipeCard.scss';

export default class RecipeCard extends Component {
   static propTypes = {
        recipe: PropTypes.object,
    }

    static contextTypes = {
        showRecipeDetails: PropTypes.func,
    };


    onClick = (ev) => {
        const { showRecipeDetails } = this.context;
        const { recipe } = this.props;

        if (ev.button == 1 || ev.ctrlKey) {
            return;
        }

        ev.preventDefault();

         if (showRecipeDetails) {
            showRecipeDetails(recipe);
        }
    }

    link = (element) => {
        const { recipe } = this.props;

        return <Link to={`/recipes/${recipe.uuid}`} onClick={this.onClick}>{element}</Link>
    }

    render() {
        const { recipe, shortMode, onRemove } = this.props;

        const imageProps = {};

        if (!recipe) {
            return <span />
        }

        const aspect = !shortMode
                     ? { w: 400, h: 500 }
                     : { w: 600, h: 600 };

        if (recipe.image_thumb) {
            imageProps.style = {
                backgroundImage: 'url(data:image/jpeg;base64,' + recipe.image_thumb + ')',

            };

            imageProps['data-has-thumb'] = true;
        }

        return (
            <div className="plan-card">
                <div className="plan-card-image-container">
                    {onRemove ?
                        <button className="delete-btn" onClick={onRemove} title="Remove recipe">
                            <i className="icon-trash-can" />
                        </button>
                    : null}
                    {this.link(<ImgResized className="plan-card-image" src={recipe.image} width={aspect.w} height={aspect.h} />)}
                    <div className="plan-card-contents">
                        {this.link(<div className="plan-card-text">
                            <h3>{recipe.title}</h3>
                        </div>)}
                    </div>
                </div>
            </div>
        );
    }
}