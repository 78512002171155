'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './UnitsMode.scss';

export default class UnitsMode extends Component {
    static propTypes = {
        className: PropTypes.string,
        metricLabel: PropTypes.string,
        imperialLabel: PropTypes.string,
    };

    static defaultProps = {
        className: 'units-mode',
        metricLabel: 'Metric',
        imperialLabel: 'Imperial',
    };

    render() {
        const { tabIndex, onClick, value, className, metricLabel, imperialLabel } = this.props;

        return (
            <button className={className}
                tabIndex={tabIndex}
                data-mode={value}
                onClick={onClick}>
                <span className="metric">{metricLabel}</span>
                <span className="english">{imperialLabel}</span>
            </button>
        );
    }
}
