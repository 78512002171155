'use strict';


import AppDispatcher from '../dispatcher/AppDispatcher';
import BoardConstants from '../constants/BoardConstants';

const BoardActions = {
    loadBoards: function() {
        AppDispatcher.handleViewAction({
            actionType: BoardConstants.BOARD_LOAD,
        });
    },

    upsertBoards: function(boards) {
        setTimeout(() => {
            AppDispatcher.handleViewAction({
                actionType: BoardConstants.BOARD_UPSERT,
                boards,
            });
        });
    },

    deleteBoards: function(boards) {
        AppDispatcher.handleViewAction({
            actionType: BoardConstants.BOARD_DELETE,
            boards,
        });
    },

    addToBoard: function(board, items) {
        AppDispatcher.handleViewAction({
            actionType: BoardConstants.BOARD_ADD_ITEM,
            board, items,
        });
    },

    upsetToBoard: function(board, items) {
        AppDispatcher.handleViewAction({
            actionType: BoardConstants.BOARD_UPSERT_ITEM,
            board, items,
        });
    },

    removeFromBoard: function(board, items) {
        AppDispatcher.handleViewAction({
            actionType: BoardConstants.BOARD_REMOVE_ITEM,
            board, items,
        });
    },

    updateNoSync: function(boards) {
        AppDispatcher.handleViewAction({
            actionType: BoardConstants.UPDATE_NOSYNC,
            boards,
        });
    }
};

export default BoardActions;
