'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './Sort.scss';

import allSortables from '../SortableFields';

export default class Sort extends Component {
    static propTypes = {
    };

    static contextTypes = {
        setParamsToPlanOnly: PropTypes.func,
        setParamsToRecipeOnly: PropTypes.func,
        isPublisher: PropTypes.bool,
    };

    setSort = (sortable) => {
        const { params, onChangeParams } = this.props;
        const { setParamsToPlanOnly, setParamsToRecipeOnly } = this.context;

        delete params.sort_by;
        delete params.sort_order;

        if (sortable.field) {
            params.sort_by = sortable.field;
        }

        if (sortable.order) {
            params.sort_order = sortable.order;
        }

        if (sortable.type) {
            if (sortable.type === 'plan') {
                setParamsToPlanOnly(params);
            }

            if (sortable.type === 'recipe') {
                setParamsToRecipeOnly(params);
            }
        }

        onChangeParams(params);
    }

    render() {
        const { params } = this.props;
        const { isPublisher } = this.context;

        let sortables = allSortables;

        if (!isPublisher) {
            sortables = sortables.filter(sort => !sort.publisherOnly);
        }

        return (
            <div className="global-search-tag-group global-search-sort-filter">
                <ul>
                    {sortables.map((sortable, i) => {
                        const isChecked = params.sort_by === sortable.field &&
                                          ((!sortable.order && !params.sort_order) || (sortable.order && sortable.order === params.sort_order));

                        const isActive = !sortable.type || (sortable.type && params.types.includes(sortable.type));

                        return (
                            <li key={i}>
                                <label className="global-search-checkbox global-search-radio" data-active={isActive}>
                                    <input type="checkbox" checked={isChecked}
                                           onChange={() => this.setSort(sortable)} />
                                    <span>{sortable.name}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
