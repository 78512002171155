'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import cookie from 'cookie-monster';
import classNames from 'classnames';

import Dropdown from '../../pro/components/Widgets/Dropdown.react';
import UserStore from '../../stores/UserStore';
import jwtDecode from 'jwt-decode';
import Popup from '../Widgets/Popup.react';

import './RecipeToPdfButton.scss';
import '../../pro/components/Plans/Editor/PlanToPdfButton.scss';

import Analytics from '../../utils/Analytics';
import { getConfig } from '../../utils/Env';
import AuthStore from '../../stores/AuthStore';

export default class RecipeToPdfButton extends Component {

    static propTypes = {
        button: PropTypes.node,

        recipe: PropTypes.object,
    };

    static contextTypes = {
        showUpgradeForm: PropTypes.func,
        isCordova: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);

        const { scaling } = props;
        const params = {};
        const user = UserStore.getUser();

        if (context.isCordova) {
            params.token = AuthStore.getToken();
        }

        if (scaling) {
            params.scaling = scaling;
        }

        this.state = {
            params,
            user,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        const { params } = this.state;

        if (nextProps.scaling && params.scaling != nextProps.scaling) {
            params.scaling = nextProps.scaling;

            this.setState({params});
        }
    }
    componentDidMount = () => {
        UserStore.addChangeListener(this.onUserStoreChange);
    }

    componentWillUnmount = () => {
        UserStore.removeChangeListener(this.onUserStoreChange);
    }

    onUserStoreChange = () => {
        const user = UserStore.getUser();

        this.setState({user});
    }

    toggleParam = (key) => {
        const { params } = this.state;

        if (params[key]) {
            delete params[key];
        } else {
            params[key] = 1;
        }

        this.setState({params});
    }

    setIngredientMode = (mode) => {
        const { params } = this.state;

        if (!mode) {
            delete params.ingr_mode;
        } else {
            params.ingr_mode = mode;
        }

        this.setState({params});
    }

    trackPdfDownload = () => {
        const params = {};

        const { recipe, meal } = this.props;

        if (recipe) {
            params.recipe = recipe.uuid;
        }

        if (meal) {
            params.date = meal.date;
            params.meal = meal.meal;
        }

        Analytics.downloadRecipePDF(params);
    }

    upgradeMembership = () => {
        const { showUpgradeForm } = this.context;

        showUpgradeForm({feature: 'print_recipe'});
    }

    render = () => {
        const { params, user } = this.state;
        const { plan, meal, recipe, button, className, buttonClassName, positionClassName } = this.props;

        let toPdfUrl;

        if (plan && meal) {
            toPdfUrl = `https://${getConfig('self_host')}/plan-meals/${plan.uuid}/${meal.offset}/${meal.meal}/printable?`;
        } else if (meal?.date) {
            toPdfUrl = `https://${getConfig('self_host')}/meals/${meal.date}/${meal.meal}/printable?`;
        } else if (recipe) {
            toPdfUrl = `https://${getConfig('self_host')}/recipes/${recipe.uuid}/printable?`;
        }

        toPdfUrl =  toPdfUrl + qs.stringify(params);

        const { capabilities = {} } = user || {};
        const { print_recipe = false } = capabilities;

        return (
            <Popup button={button} buttonTitle="print" className={classNames("recipe-to-pdf-dropdown", className)} positionClassName={positionClassName}>
                <div className="recipe-to-pdf-form">
                    <h2>Print Options:</h2>
                    <button className="el-checkbox" data-active={params.hide_photos ? false : true} onClick={() => this.toggleParam('hide_photos')}>
                        Include Recipe Photo
                    </button>

                    <h2>Ingredients Options:</h2>
                    <div className="toggle-group">
                        <button className="el-radio-btn" data-active={params.ingr_mode == 'default' || !params.ingr_mode} onClick={() => this.setIngredientMode()}>
                            Cups
                        </button>

                        <button className="el-radio-btn" data-active={params.ingr_mode == 'grams'} onClick={() => this.setIngredientMode('grams')}>
                            Grams
                        </button>

                        <button className="el-radio-btn" data-active={params.ingr_mode == 'ounces'} onClick={() => this.setIngredientMode('ounces')}>
                            Ounces
                        </button>
                    </div>
                </div>

                <footer>
                    {print_recipe
                        ? <a target="_blank" onClick={this.trackPdfDownload} href={toPdfUrl}>
                            <button className="el-medium-btn el-raspberry-outline-btn">Print</button>
                          </a>
                        : <button className="el-medium-btn el-raspberry-outline-btn" onClick={this.upgradeMembership}>
                            Print <i className="icon-lock" />
                          </button>
                    }
                </footer>
            </Popup>
        );
    }
}
