'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import { Link } from 'react-router';

import UserStore from '../../../stores/UserStore';

import allLanguages from '../../../tables/languages';

export default class LanguageFilter extends Component {

    static propTypes = {
        hideAllButton: PropTypes.bool,
    };

    static defaultProps = {
        hideAllButton: false,
    };

    onChangeLanguage = (language) => {
        const { params, onChangeParams } = this.props;

        params.language = language;

        onChangeParams(params);
    }

    removeLanguage = () => {
        const { params, onChangeParams } = this.props;

        delete params.language;

        onChangeParams(params);
    }

    render = () => {
        const { params: { language }, hideAllButton } = this.props;

        const user = UserStore.getUser();

        return (
            <div className="doc-type-params">
                <ul>
                    {!hideAllButton ? <li>
                        <button onClick={this.removeLanguage} data-active={language ? false : true}>all</button>
                    </li> : null}
                    {Object.keys(allLanguages).map(langCode => (
                        <li key={langCode}>
                            <button onClick={() => this.onChangeLanguage(langCode)} data-active={language === langCode}>
                                {allLanguages[langCode].title[(user && user.language) || 'en']}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}
