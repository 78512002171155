'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';

import Agreement from '../Licenses/Agreement.react';

import "./AgreementModal.scss";
import './Modals.scss';
import modalStyles from '../../../jsx-styles/modals';

export default class AgreementModal extends Component {
    static propTypes = {
        workingTitle: PropTypes.string,
        workingMessage: PropTypes.string,

        acceptText: PropTypes.string,
        rejectText: PropTypes.string,
    };

    static defaultProps = {
        workingTitle: "Saving patient...",
        workingMessage: "",

        acceptText: "Save",
        rejectText: "Cancel"
    };

    scrollable = null;

    realizeScrollable = (el) => {
        this.scrollable = el;
    }

    scrollTop = (top) => {
        if (this.scrollable) {
            this.scrollable.scrollTop = top;
        }
    }

    render() {
        const { closeModal } = this.props;

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                overlayClassName="agreement-modal-overlay"
                className="editor-modal agreement-modal"
                contentLabel="Business Associates Agreement"
                style={{...modalStyles.largeSquareModal}}
                closeTimeoutMS={250}>

                <div className="editor-modal-container">
                    <header className="modal-header">
                        <button className="close-btn" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <Agreement {...this.props} ref="agreement" onCancel={closeModal} />
                </div>
            </Modal>
        );
    }
}
