'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import store from 'store';

import { updateCachedDocuments } from '../../../utils/Content';
import { getConfig } from '../../../utils/Env';

import AuthStore from '../../../stores/AuthStore';

import './Merchant.scss';
import './Select.scss';

const MERCHANT_STORE_KEY = 'merchant-list-xf3as2';

export default class Merchant extends Component {
    static contextTypes = {
        user: PropTypes.object
    }

    static propTypes = {
    };

    constructor(props, context) {
        super(props, context);

        this.state = {
            merchants: [],
        };
    }

    componentDidMount = () => {
        this.loadMerchants();
    }

    loadMerchants = () => {
        const { user } = this.context;

        AuthStore.fetch(getConfig('recipe_api') + '/search', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=search/advanced/1'},
            body: JSON.stringify({
                types: ['merchant'],
                terms: "",
                size: 500,
                include_merchants: user?.features?.source_libraries || null
            }),
        }, true).then(results => {
            updateCachedDocuments(results.elements);

            const sorted = results.elements.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            if (sorted.length > 0) {

                this.setState({
                    merchants: sorted,
                });
            }
        });
    }

    onChangeMerchant = (ev) => {
        const { params, onChangeParams } = this.props;

        delete params.filters['merchant.uuid'];

        // Is there a value
        if (ev.target.value != 0) {
            params.filters['merchant.uuid'] = ev.target.value;
        }

        onChangeParams(params, true);
    }

    render() {
        const { merchants } = this.state;
        const { params } = this.props;

        let label = 'SELECT RECIPE SOURCE';
        let value = 0;

        // Do we have a merchant selected (and loaded)?
        if (params.filters['merchant.uuid']) {
            const merchant = merchants.filter(m => m.uuid === params.filters['merchant.uuid'])[0];

            if (merchant) {
                label = merchant.name;
                value = merchant.uuid;
            }
        }

        return (
            <div className="global-search-merchant-filter global-search-select">
                <select value={value} onChange={this.onChangeMerchant}>
                    <option value={0}>SELECT SOURCE</option>
                    { merchants.map((m) => {
                        return (
                            <option key={m.uuid} value={m.uuid}>
                                {m.name}
                            </option>
                        );
                    })}
                </select>
                <section>
                    {label}
                </section>
            </div>
        );
    }
}
