'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './Checkbox.scss';
import './DocumentType.scss';

export default class DocumentType extends Component {
    static propTypes = {
        allowedTypes: PropTypes.array,
    };

    static defaultProps = {
        allowedTypes: [],
    };

    static contextTypes = {
        stripPlanOnlyFilters: PropTypes.func,
        stripRecipeOnlyFilters: PropTypes.func,

        closeMenu: PropTypes.func,
    };

    toggleDocumentType = (type) => {
        let { params, onChangeParams, autoClose } = this.props;
        const { stripPlanOnlyFilters, stripRecipeOnlyFilters, closeMenu } = this.context;

        if (params.types.includes(type)) {
            if (params.types.length > 1) {
                params.types.splice(params.types.indexOf(type), 1);
            }
        } else {
            params.types.push(type);
        }

        if (!params.types.includes('plan')) {
            params = stripPlanOnlyFilters(params);
        }

        if (!params.types.includes('recipe')) {
            params = stripRecipeOnlyFilters(params);
        }

        onChangeParams && onChangeParams(params);
        autoClose && closeMenu && closeMenu();
    }

    render() {
        const { params, allowedTypes } = this.props;

        const availableTypes = [
            {type: 'recipe', label: 'Recipes'},
            {type: 'combo', label: 'Main + Side Dish'},
            {type: 'plan', label: 'Full Meal Plans'},
            {type: 'brand_product', label: 'Store Bought / Restaurant Foods'},
            {type: 'food', label: 'Ingredients'},
        ];

        return (
            <ul className="document-type-filter">
                {availableTypes.filter(type => allowedTypes.includes(type.type))
                               .map((type, i) => (
                    <li key={i}>
                        <label className="global-search-checkbox">
                            <input type="checkbox" checked={params.types.includes(type.type)}
                                   onChange={() => this.toggleDocumentType(type.type)} />
                            <span>{type.label}</span>
                        </label>
                    </li>
                ))}
            </ul>
        );
    }
}
