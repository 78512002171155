'use strict'

// File generated by bin/sync-static-tables.php
// DO NOT EDIT! (Edit the orginal in recipe/config/tables/nutrients.php)

export default {
    "FRU": {
        "NutrDesc": "Fruits",
        "Units": "servings",
        "Filter": "fruits",
        "GoodSource": 1,
        "envelope": "above-80p",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0,
                    "Lunch": 0.2,
                    "Dinner": 0
                },
                "ideal": {
                    "Breakfast": 0,
                    "Lunch": 0.5,
                    "Dinner": 0,
                    "Snack": 0.5
                },
                "max": {
                    "Breakfast": 0.5,
                    "Lunch": 1,
                    "Dinner": 0.25,
                    "Snack": 0.5
                }
            },
            {
                "min": {
                    "Breakfast": 0.3,
                    "Lunch": 0,
                    "Dinner": 0
                },
                "ideal": {
                    "Breakfast": 0.5,
                    "Lunch": 0,
                    "Dinner": 0,
                    "Snack": 0.5
                },
                "max": {
                    "Breakfast": 1,
                    "Lunch": 0.5,
                    "Dinner": 0.25,
                    "Snack": 0.5
                }
            }
        ]
    },
    "VEG": {
        "NutrDesc": "Vegetables",
        "Units": "servings",
        "Filter": "vegetables",
        "GoodSource": 1.25,
        "envelope": "above-80p",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0,
                    "Lunch": 0.3,
                    "Dinner": 0.3
                },
                "ideal": {
                    "Breakfast": 0,
                    "Lunch": 0.4,
                    "Dinner": 0.5,
                    "Snack": 0.1
                },
                "max": {
                    "Lunch": 0.7,
                    "Dinner": 1
                }
            }
        ]
    },
    "SVG": {
        "NutrDesc": "Starchy Vegetables",
        "Units": "servings",
        // Temporarily removed until starchy vegetables are added to foods db
        // "Filter": "starchy_vegetables",
        "envelope": "above-80p"
    },
    "WHG": {
        "NutrDesc": "Whole Grains",
        "Units": "servings",
        "Filter": "whole_grains",
        "GoodSource": 1.5,
        "envelope": "above-80p"
    },
    "HLF": {
        "NutrDesc": "Healthy Fats",
        "Units": "servings",
        "Filter": "healthy_fats",
        "GoodSource": 1.5,
        "envelope": "within-20p"
    },
    "LPR": {
        "NutrDesc": "Healthy Proteins",
        "Units": "servings",
        "Filter": "lean_proteins",
        "GoodSource": 1.375,
        "envelope": "within-20p"
    },
    "DAI": {
        "NutrDesc": "Dairy",
        "Units": "servings",
        "Filter": "dairy",
        "GoodSource": 1,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.2,
                    "Lunch": 0.3,
                    "Dinner": 0.3,
                    "Snack": 0.25
                }
            }
        ]
    },
    "LFY": {
        "NutrDesc": "Leafy Greens",
        "Units": "servings",
        "Filter": "leafy_greens"
    },
    "BER": {
        "NutrDesc": "Berries",
        "Units": "servings",
        "Filter": "berries"
    },
    "NUT": {
        "NutrDesc": "Nuts\/Seeds",
        "Units": "servings",
        "Filter": "nuts_seeds"
    },
    "NTC": {
        "NutrDesc": "Net Carbs",
        "Filter": "net_carbs",
        "Units": "g"
    },
    "203": {
        "NutrDesc": "Protein",
        "alts": [
            "protein"
        ],
        "Units": "g",
        "Filter": "protein",
        "envelope": "above-80p",
        "calories_per_gram": 4,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.13,
                    "Lunch": 0.3,
                    "Dinner": 0.25
                },
                "max": {
                    "Breakfast": 0.3,
                    "Lunch": 0.4,
                    "Dinner": 0.45
                }
            }
        ]
    },
    "204": {
        "NutrDesc": "Total Fat",
        "alts": [
            "total fat"
        ],
        "Units": "g",
        "Filter": "fat",
        "envelope": "within-20p",
        "calories_per_gram": 9,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.13,
                    "Lunch": 0.3,
                    "Dinner": 0.225
                },
                "max": {
                    "Breakfast": 0.3,
                    "Lunch": 0.4,
                    "Dinner": 0.475
                }
            }
        ]
    },
    "205": {
        "NutrDesc": "Carbs",
        "alts": [
            "total carbohydrate",
            "total carb",
            "total carb.",
            "total carbohydrate",
            "total carbohydrates",
            "total carbs",
            "total carbs.",
            "carbohydrates"
        ],
        "Units": "g",
        "Filter": "carbohydrates",
        "envelope": "within-20p",
        "calories_per_gram": 4,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.13,
                    "Lunch": 0.3,
                    "Dinner": 0.25
                },
                "max": {
                    "Breakfast": 0.3,
                    "Lunch": 0.4,
                    "Dinner": 0.45
                }
            }
        ]
    },
    "207": {
        "NutrDesc": "Ash",
        "Units": "g"
    },
    "208": {
        "NutrDesc": "Calories",
        "alts": [
            "calories",
            "calories per serving"
        ],
        "Units": "kcal",
        "Filter": "calories",
        "envelope": "within-20p",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.13,
                    "Lunch": 0.3,
                    "Dinner": 0.3
                },
                "max": {
                    "Breakfast": 0.3,
                    "Lunch": 0.4,
                    "Dinner": 0.4
                }
            }
        ]
    },
    "209": {
        "NutrDesc": "Starch",
        "Units": "g"
    },
    "210": {
        "NutrDesc": "Sucrose",
        "Filter": "sucrose",
        "Units": "g"
    },
    "211": {
        "NutrDesc": "Glucose",
        "Filter": "glucose",
        "Units": "g"
    },
    "212": {
        "NutrDesc": "Fructose",
        "Units": "g"
    },
    "213": {
        "NutrDesc": "Lactose",
        "Units": "g"
    },
    "214": {
        "NutrDesc": "Maltose",
        "Filter": "maltose",
        "Units": "g"
    },
    "221": {
        "NutrDesc": "Alcohol",
        "Filter": "alcohol",
        "Units": "g"
    },
    "255": {
        "NutrDesc": "Water",
        "Units": "g"
    },
    "257": {
        "NutrDesc": "Adjusted Protein",
        "Units": "g"
    },
    "262": {
        "NutrDesc": "Caffeine",
        "Filter": "caffeine",
        "Units": "mg"
    },
    "263": {
        "NutrDesc": "Theobromine",
        "Filter": "theobromine",
        "Units": "mg"
    },
    "268": {
        "NutrDesc": "Energy",
        "Filter": "energy",
        "Units": "kJ"
    },
    "269": {
        "NutrDesc": "Sugars, total",
        "alts": [
            "sugars",
            "sugar",
            "total sugars"
        ],
        "Units": "g",
        "Filter": "sugars_total",
        "envelope": "below-120p"
    },
    "287": {
        "NutrDesc": "Galactose",
        "Filter": "galactose",
        "Units": "g"
    },
    "291": {
        "NutrDesc": "Fiber",
        "alts": [
            "fiber",
            "dietary fiber",
            "fibre",
            "flber"
        ],
        "Units": "g",
        "Filter": "fiber",
        "GoodSource": 8.3333,
        "envelope": "above-80p",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.3,
                    "Lunch": 0.3,
                    "Dinner": 0.3,
                    "Snack": 0.15
                }
            }
        ]
    },
    "SFB": {
        "NutrDesc": "Soluble Fiber",
        "alts": [
            "Fiber, Water Soluble"
        ],
        "Units": "g"
    },
    "IFB": {
        "NutrDesc": "Insoluble Fiber",
        "alts": [
            "Fiber, Water-Insoluble"
        ],
        "Units": "g"
    },
    "301": {
        "NutrDesc": "Calcium",
        "alts": [
            "calcium"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "calcium",
        "GoodSource": 333,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.3,
                    "Lunch": 0.2,
                    "Dinner": 0.2,
                    "Snack": 0.2
                }
            }
        ]
    },
    "303": {
        "NutrDesc": "Iron",
        "alts": [
            "iron"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "iron",
        "GoodSource": 6,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.25,
                    "Lunch": 0.3,
                    "Dinner": 0.35,
                    "Snack": 0
                }
            }
        ]
    },
    "304": {
        "NutrDesc": "Magnesium",
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "magnesium",
        "GoodSource": 133,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.25,
                    "Lunch": 0.3,
                    "Dinner": 0.35,
                    "Snack": 0
                }
            }
        ]
    },
    "305": {
        "NutrDesc": "Phosphorus",
        "alts": [
            "phosphorus"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "phosphorus",
        "GoodSource": 33,
        "distributions": [
            {
                "ideal": {
                    "Breakfast": 0,
                    "Lunch": 0.4,
                    "Dinner": 0.5,
                    "Snack": 0.1
                },
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.3
                }
            }
        ]
    },
    "306": {
        "NutrDesc": "Potassium",
        "alts": [
            "potassium"
        ],
        "Units": "mg",
        "Filter": "potassium",
        "envelope": "above-80p",
        "GoodSource": 940,
        "distributions": [
            {
                "ideal": {
                    "Breakfast": 0,
                    "Lunch": 0.4,
                    "Dinner": 0.5,
                    "Snack": 0.1
                },
                "max": {
                    "Breakfast": 0.15,
                    "Lunch": 0.25,
                    "Dinner": 0.3
                }
            }
        ]
    },
    "307": {
        "NutrDesc": "Sodium",
        "alts": [
            "sodium",
            "sodlum",
            "Salt Equivalent"
        ],
        "Units": "mg",
        "Filter": "sodium",
        "envelope": "below-120p",
        "distributions": [
            {
                "max": {
                    "Dinner": 0.475
                }
            }
        ]
    },
    "309": {
        "NutrDesc": "Zinc",
        "alts": [
            "zinc"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "zinc",
        "GoodSource": 5
    },
    "312": {
        "NutrDesc": "Copper",
        "Units": "mg",
        "Filter": "copper",
        "envelope": "above-80p"
    },
    "313": {
        "NutrDesc": "Flouride",
        "Units": "",
        "Filter": "flouride",
        "envelope": "above-80p"
    },
    "315": {
        "NutrDesc": "Manganese",
        "Units": "mg",
        "Filter": "manganese",
        "envelope": "above-80p"
    },
    "317": {
        "NutrDesc": "Selenium",
        "alts": [
            "selenium",
            "selenium, total"
        ],
        "Units": "\u03bcg",
        "Filter": "selenium",
        "envelope": "above-80p"
    },
    "318": {
        "NutrDesc": "Vitamin A",
        "alts": [
            "vitamin a"
        ],
        "Units": "IU",
        "Filter": "vitamin_a",
        "envelope": "above-80p"
    },
    "319": {
        "NutrDesc": "Retinol",
        "Filter": "retinol",
        "Units": ""
    },
    "320": {
        "NutrDesc": "Vitamin A, RAE",
        "Units": "",
        "envelope": "above-80p",
        "Filter": "vitamin_a_rae",
        "GoodSource": 500
    },
    "321": {
        "NutrDesc": "Carotene, beta",
        "Filter": "carotene_beta",
        "Units": ""
    },
    "322": {
        "NutrDesc": "Carotene, alpha",
        "Filter": "carotene_alpha",
        "Units": ""
    },
    "323": {
        "NutrDesc": "Vitamin E",
        "alts": [
            "vitamin e"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "vitamin_e",
        "GoodSource": 6.7
    },
    "324": {
        "NutrDesc": "Vitamin D",
        "alts": [
            "vitamin d"
        ],
        "Units": "IU",
        "envelope": "above-80p",
        "Filter": "vitamin_d",
        "GoodSource": 133
    },
    "325": {
        "NutrDesc": "Vitamin D2",
        "Filter": "vitamin_d2",
        "Units": ""
    },
    "326": {
        "NutrDesc": "Vitamin D3",
        "Filter": "vitamin_d3",
        "Units": ""
    },
    "328": {
        "NutrDesc": "Vitamin D (D2 + D3)",
        "Filter": "vitamin_d23",
        "Units": ""
    },
    "334": {
        "NutrDesc": "Cryptoxanthin, beta",
        "Filter": "cryptoxanthin_beta",
        "Units": ""
    },
    "337": {
        "NutrDesc": "Lycopene",
        "Filter": "lycopene",
        "Units": ""
    },
    "338": {
        "NutrDesc": "Lutein + zeaxanthin",
        "Filter": "lutein_zeaxanthin",
        "Units": ""
    },
    "341": {
        "NutrDesc": "Tocopherol, beta",
        "Filter": "tocopherol_beta",
        "Units": "mg"
    },
    "342": {
        "NutrDesc": "Tocopherol, gamma",
        "Filter": "tocopherol_gamma",
        "Units": "mg"
    },
    "343": {
        "NutrDesc": "Tocopherol, delta",
        "Filter": "tocopherol_delta",
        "Units": "mg"
    },
    "344": {
        "NutrDesc": "Tocotrienol, alpha",
        "Filter": "tocotrienol_alpha",
        "Units": "mg"
    },
    "345": {
        "NutrDesc": "Tocotrienol, beta",
        "Filter": "tocotrienol_beta",
        "Units": "mg"
    },
    "346": {
        "NutrDesc": "Tocotrienol, gamma",
        "Filter": "tocotrienol_gamma",
        "Units": "mg"
    },
    "347": {
        "NutrDesc": "Tocotrienol, delta",
        "Filter": "tocotrienol_delta",
        "Units": "mg"
    },
    "401": {
        "NutrDesc": "Vitamin C",
        "alts": [
            "vitamin c"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "vitamin_c",
        "GoodSource": 20
    },
    "404": {
        "NutrDesc": "Thiamin",
        "Units": "mg",
        "Filter": "thiamin",
        "envelope": "above-80p",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.2,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0
                },
                "ideal": {
                    "Breakfast": 0.3,
                    "Lunch": 0.35,
                    "Dinner": 0.35,
                    "Snack": 0
                }
            }
        ]
    },
    "405": {
        "NutrDesc": "Riboflavin",
        "alts": [
            "riboflavin"
        ],
        "Units": "mg",
        "Filter": "riboflavin",
        "envelope": "above-80p"
    },
    "406": {
        "NutrDesc": "Niacin",
        "alts": [
            "niacin"
        ],
        "Units": "mg",
        "Filter": "niacin",
        "envelope": "above-80p",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0,
                    "Lunch": 0.2,
                    "Dinner": 0.25
                },
                "ideal": {
                    "Breakfast": 0,
                    "Lunch": 0.5,
                    "Dinner": 0.5,
                    "Snack": 0
                }
            }
        ]
    },
    "410": {
        "NutrDesc": "Pantothenic acid",
        "Units": "mg",
        "Filter": "pantothenic_acid",
        "envelope": "above-80p"
    },
    "415": {
        "NutrDesc": "Vitamin B-6",
        "alts": [
            "vitamin b6"
        ],
        "Units": "mg",
        "envelope": "above-80p",
        "Filter": "vitamin_b6",
        "GoodSource": 0.667
    },
    "417": {
        "NutrDesc": "Folate",
        "Units": "\u03bcg"
    },
    "418": {
        "NutrDesc": "Vitamin B-12",
        "alts": [
            "vitamin b12"
        ],
        "Units": "\u03bcg",
        "envelope": "above-80p",
        "Filter": "vitamin_b12",
        "GoodSource": 0.2,
        "distributions": [
            {
                "min": {
                    "Breakfast": 0,
                    "Lunch": 0.3,
                    "Dinner": 0.3
                },
                "ideal": {
                    "Breakfast": 0,
                    "Lunch": 0.5,
                    "Dinner": 0.5,
                    "Snack": 0
                }
            }
        ]
    },
    "421": {
        "NutrDesc": "Choline, total",
        "alts": [
            "choline"
        ],
        "Units": "mg",
        "Filter": "choline_total",
        "envelope": "above-80p"
    },
    "428": {
        "NutrDesc": "Menaquinone-4",
        "Units": ""
    },
    "429": {
        "NutrDesc": "Dihydrophylloquinone",
        "Units": ""
    },
    "430": {
        "NutrDesc": "Vitamin K",
        "alts": [
            "Vitamin K-1",
            "Vitamin K-2"
        ],
        "Units": "\u03bcg",
        "Filter": "vitamin_k",
        "envelope": "above-80p"
    },
    "431": {
        "NutrDesc": "Folic acid",
        "Units": ""
    },
    "432": {
        "NutrDesc": "Folate",
        "Units": "\u03bcg",
        "envelope": "above-80p"
    },
    "435": {
        "NutrDesc": "Folate",
        "alts": [
            "folate",
            "folate, total"
        ],
        "Filter": "folate_dfe",
        "Units": "DFE"
    },
    "454": {
        "NutrDesc": "Betaine",
        "Units": "mg"
    },
    "501": {
        "NutrDesc": "Tryptophan",
        "Filter": "tryptophan",
        "Units": "g"
    },
    "502": {
        "NutrDesc": "Threonine",
        "Units": "g"
    },
    "503": {
        "NutrDesc": "Isoleucine",
        "Filter": "isoleucine",
        "Units": "g"
    },
    "504": {
        "NutrDesc": "Leucine",
        "Filter": "leucine",
        "Units": "g"
    },
    "505": {
        "NutrDesc": "Lysine",
        "Filter": "lysine",
        "Units": "g"
    },
    "506": {
        "NutrDesc": "Methionine",
        "Filter": "methionine",
        "Units": "g"
    },
    "507": {
        "NutrDesc": "Cystine",
        "Filter": "cystine",
        "Units": "g"
    },
    "508": {
        "NutrDesc": "Phenylalanine",
        "Filter": "phenylalanine",
        "Units": "g",
        "envelope": "below-120p"
    },
    "509": {
        "NutrDesc": "Tyrosine",
        "Filter": "tyrosine",
        "Units": "g"
    },
    "510": {
        "NutrDesc": "Valine",
        "Filter": "valine",
        "Units": "g"
    },
    "511": {
        "NutrDesc": "Arginine",
        "Units": "g"
    },
    "512": {
        "NutrDesc": "Histidine",
        "Units": "g"
    },
    "513": {
        "NutrDesc": "Alanine",
        "Units": "g"
    },
    "514": {
        "NutrDesc": "Aspartic acid",
        "Units": "g"
    },
    "515": {
        "NutrDesc": "Glutamic acid",
        "Units": "g"
    },
    "516": {
        "NutrDesc": "Glycine",
        "Filter": "glycine",
        "Units": "g"
    },
    "517": {
        "NutrDesc": "Proline",
        "Units": "g"
    },
    "518": {
        "NutrDesc": "Serine",
        "Units": "g"
    },
    "521": {
        "NutrDesc": "Hydroxyproline",
        "Units": "g"
    },
    "573": {
        "NutrDesc": "Vitamin E, added",
        "Filter": "vitamin_e_added",
        "Units": "mg"
    },
    "578": {
        "NutrDesc": "Vitamin B-12, added",
        "Filter": "vitamin_b12_added",
        "Units": ""
    },
    "601": {
        "NutrDesc": "Cholesterol",
        "alts": [
            "cholesterol"
        ],
        "Units": "mg",
        "Filter": "cholesterol",
        "envelope": "below-120p"
    },
    "605": {
        "NutrDesc": "Trans Fat",
        "alts": [
            "trans fat",
            "trans",
            "transfat"
        ],
        "Filter": "trans_fat",
        "Units": "g"
    },
    "606": {
        "NutrDesc": "Saturated Fat",
        "alts": [
            "saturated fat",
            "saturated",
            "sat fat",
            "sat, fat",
            "sat. fat"
        ],
        "Units": "g",
        "Filter": "saturated_fat",
        "envelope": "below-120p",
        "distributions": [
            {
                "max": {
                    "Dinner": 0.475
                }
            }
        ]
    },
    "607": {
        "NutrDesc": "Butyric acid",
        "alts": [
            "4:0"
        ],
        "Units": "g",
        "Filter": "butyric_acid"
    },
    "608": {
        "NutrDesc": "Caproic acid",
        "alts": [
            "6:0"
        ],
        "Units": "g",
        "Filter": "caproic_acid"
    },
    "609": {
        "NutrDesc": "Caprylic acid",
        "alts": [
            "8:0"
        ],
        "Units": "g",
        "Filter": "caprylic_acid"
    },
    "610": {
        "NutrDesc": "Capric acid",
        "alts": [
            "10:0"
        ],
        "Units": "g",
        "Filter": "capric_acid"
    },
    "611": {
        "NutrDesc": "Lauric acid",
        "alts": [
            "12:0"
        ],
        "Units": "g",
        "Filter": "lauric_acid"
    },
    "612": {
        "NutrDesc": "Myristic acid",
        "alts": [
            "14:0"
        ],
        "Units": "g",
        "Filter": "myristic_acid"
    },
    "613": {
        "NutrDesc": "Palmitic acid",
        "alts": [
            "16:0"
        ],
        "Units": "g",
        "Filter": "palmitic_acid"
    },
    "614": {
        "NutrDesc": "Stearic acid",
        "alts": [
            "18:0"
        ],
        "Units": "g",
        "Filter": "stearic_acid"
    },
    "615": {
        "NutrDesc": "Arachidic acid",
        "alts": [
            "20:0"
        ],
        "Units": "g",
        "Filter": "arachidic_acid"
    },
    "617": {
        "NutrDesc": "Oleic acid (undifferentiated)",
        "alts": [
            "18:1 undifferentiated",
            "Octadecenoic acid"
        ],
        "Units": "g",
        "Filter": "oleic_acid_undifferentiated"
    },
    "618": {
        "NutrDesc": "Linoleic acid (undifferentiated)",
        "alts": [
            "18:2 undifferentiated"
        ],
        "Units": "g",
        "Filter": "linoleic_acid_undifferentiated"
    },
    "619": {
        "NutrDesc": "Alpha-linolenic acid (undifferentiated)",
        "alts": [
            "18:3 undifferentiated"
        ],
        "Units": "g",
        "Filter": "alpha_linolenic_acid_undifferentiated"
    },
    "620": {
        "NutrDesc": "Arachidonic acid (undifferentiated)",
        "alts": [
            "20:4 undifferentiated"
        ],
        "Units": "g",
        "Filter": "arachidonic_acid_undifferentiated"
    },
    "621": {
        "NutrDesc": "Omega 3 (DHA)",
        "alts": [
            "22:6 n-3 (DHA)",
            "Docosahexaenoic acid"
        ],
        "Filter": "omega3_dha",
        "Units": "g"
    },
    "624": {
        "NutrDesc": "Behenic acid",
        "alts": [
            "22:0"
        ],
        "Units": "g",
        "Filter": "behenic_acid"
    },
    "625": {
        "NutrDesc": "Myristoleic acid",
        "alts": [
            "14:1"
        ],
        "Units": "g",
        "Filter": "myristoleic_acid"
    },
    "626": {
        "NutrDesc": "Palmitoleic acid",
        "alts": [
            "16:1 undifferentiated"
        ],
        "Units": "g",
        "Filter": "plamitoleic_acid"
    },
    "627": {
        "NutrDesc": "Stearidonic acid",
        "alts": [
            "18:4"
        ],
        "Units": "g",
        "Filter": "stearidonic_acid"
    },
    "628": {
        "NutrDesc": "Eicosenoic acid",
        "alts": [
            "20:1"
        ],
        "Units": "g",
        "Filter": "eicosenoic_acid"
    },
    "629": {
        "NutrDesc": "Omega 3 (EPA)",
        "alts": [
            "20:5 n-3 (EPA)",
            "Eicosapentaenoic acid"
        ],
        "Filter": "omega3_epa",
        "Units": "g"
    },
    "630": {
        "NutrDesc": "Erucic acid (undifferentiated)",
        "alts": [
            "22:1 undifferentiated"
        ],
        "Units": "g",
        "Filter": "erucic_acid_undifferentiated"
    },
    "631": {
        "NutrDesc": "Omega 3 (DPA)",
        "alts": [
            "22:5 n-3 (DPA)",
            "Docosapentaenoic acid"
        ],
        "Units": "g",
        "Filter": "omega3_dpa"
    },
    "636": {
        "NutrDesc": "Phytosterols",
        "Filter": "phytosterols",
        "Units": "mg"
    },
    "638": {
        "NutrDesc": "Stigmasterol",
        "Filter": "stigmasterol",
        "Units": "mg"
    },
    "639": {
        "NutrDesc": "Campesterol",
        "Filter": "campesterol",
        "Units": "mg"
    },
    "641": {
        "NutrDesc": "Beta-sitosterol",
        "Filter": "beta_sitosterol",
        "Units": "mg"
    },
    "645": {
        "NutrDesc": "Monounsaturated Fat",
        "alts": [
            "monounsaturated fat"
        ],
        "Filter": "monounsaturated_fat",
        "Units": "g",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.25,
                    "Lunch": 0.35,
                    "Dinner": 0.35
                },
                "ideal": {
                    "Breakfast": 0.3,
                    "Lunch": 0.35,
                    "Dinner": 0.35,
                    "Snack": 0
                },
                "max": {
                    "Breakfast": 0.35,
                    "Lunch": 0.4,
                    "Dinner": 0.45,
                    "Snack": 0.1
                }
            }
        ]
    },
    "646": {
        "NutrDesc": "Polyunsaturated Fat",
        "alts": [
            "polyunsaturated fat"
        ],
        "Filter": "polyunsaturated_fat",
        "Units": "g",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0.25,
                    "Lunch": 0.35,
                    "Dinner": 0.35
                },
                "ideal": {
                    "Breakfast": 0.3,
                    "Lunch": 0.35,
                    "Dinner": 0.35,
                    "Snack": 0
                },
                "max": {
                    "Breakfast": 0.35,
                    "Lunch": 0.4,
                    "Dinner": 0.45,
                    "Snack": 0.1
                }
            }
        ]
    },
    "652": {
        "NutrDesc": "15:0",
        "Units": "g"
    },
    "653": {
        "NutrDesc": "Margaric acid",
        "alts": [
            "17:0",
            "heptadecanoic"
        ],
        "Units": "g",
        "Filter": "margaric_acid"
    },
    "654": {
        "NutrDesc": "Lignoceric acid",
        "alts": [
            "24:0"
        ],
        "Units": "g",
        "Filter": "lignoceric_acid"
    },
    "662": {
        "NutrDesc": "Trans-palmitoleic acid",
        "alts": [
            "16:1 t"
        ],
        "Units": "g",
        "Filter": "trans_palmitoleic_acid"
    },
    "663": {
        "NutrDesc": "18:1 t",
        "Units": "g"
    },
    "664": {
        "NutrDesc": "22:1 t",
        "Units": "g"
    },
    "665": {
        "NutrDesc": "18:2 t not further defined",
        "Units": "g"
    },
    "666": {
        "NutrDesc": "18:2 i",
        "Units": "g"
    },
    "669": {
        "NutrDesc": "18:2 t,t",
        "Units": "g"
    },
    "670": {
        "NutrDesc": "Conjugated Linoleic Acids",
        "alts": [
            "18:2 CLAs"
        ],
        "Units": "g",
        "Filter": "linoleic_acid_conjugated"
    },
    "671": {
        "NutrDesc": "Nervonic acid",
        "alts": [
            "24:1 c"
        ],
        "Units": "g",
        "Filter": "nervonic_acid"
    },
    "672": {
        "NutrDesc": "Omega 6 (Eicosadienoic acid)",
        "alts": [
            "20:2 n-6 c,c"
        ],
        "Units": "g",
        "Filter": "omega6_eicosadienoic_acid"
    },
    "673": {
        "NutrDesc": "Palmitoleic acid",
        "alts": [
            "16:1 c"
        ],
        "Units": "g",
        "Filter": "palmitoleic_acid"
    },
    "674": {
        "NutrDesc": "Oleic acid",
        "alts": [
            "18:1 c"
        ],
        "Units": "g",
        "filter": "oleic_acid"
    },
    "675": {
        "NutrDesc": "Omega 6 (Linoleic acid)",
        "alts": [
            "18:2 n-6 c,c"
        ],
        "Units": "g",
        "Filter": "omega6_linoleic_acid"
    },
    "676": {
        "NutrDesc": "Erucic acid",
        "alts": [
            "22:1 c"
        ],
        "Units": "g",
        "Filter": "erucic_acid"
    },
    "685": {
        "NutrDesc": "Omega 6 (Gamma-linoleic acid)",
        "alts": [
            "18:3 n-6 c,c,c",
            "Gamma-linoleic acid"
        ],
        "Units": "g",
        "Filter": "omega6_gamma_linoleic_acid"
    },
    "687": {
        "NutrDesc": "Heptadecenoic acid",
        "alts": [
            "17:1"
        ],
        "Units": "g",
        "Filter": "heptadecenoic_acid"
    },
    "689": {
        "NutrDesc": "20:3 undifferentiated",
        "Units": "g"
    },
    "693": {
        "NutrDesc": "Trans-monoenoic Fat",
        "Units": "g",
        "Filter": "trans_monoenoic_fat"
    },
    "695": {
        "NutrDesc": "Trans-polyenoic Fat",
        "Units": "g",
        "Filter": "trans_polyenoic_fat"
    },
    "696": {
        "NutrDesc": "Tridecanoic acid",
        "alts": [
            "13:0"
        ],
        "Units": "g",
        "Filter": "tridecanoic_acid"
    },
    "697": {
        "NutrDesc": "Pentadecenoic acid",
        "alts": [
            "15:1"
        ],
        "Units": "g",
        "Filter": "pentadecenoic_acid"
    },
    "851": {
        "NutrDesc": "Omega 3 (ALA)",
        "alts": [
            "18:3 n-3 c,c,c (ALA)",
            "Alpha-linolenic acid"
        ],
        "Units": "g",
        "Filter": "omega3_ala"
    },
    "852": {
        "NutrDesc": "20:3 n-3",
        "Units": "g"
    },
    "853": {
        "NutrDesc": "Omega 6 (Eicosatrienoic acid)",
        "alts": [
            "20:3 n-6",
            "Eicosatrienoic acid"
        ],
        "Units": "g",
        "Filter": "omega6_eicosatrienoic_acid"
    },
    "855": {
        "NutrDesc": "Omega 6 (Eicosatetraenoic acid)",
        "alts": [
            "20:4 n-6",
            "Eicosatetraenoic acid"
        ],
        "Units": "g",
        "Filter": "omega6_eicosatetraenoic_acid"
    },
    "856": {
        "NutrDesc": "18:3i",
        "Units": "g"
    },
    "857": {
        "NutrDesc": "21:5",
        "Units": "g"
    },
    "858": {
        "NutrDesc": "22:4",
        "Units": "g"
    },
    "859": {
        "NutrDesc": "18:1-11 t (18:1t n-7)",
        "Units": "g"
    },
    "ASG": {
        "NutrDesc": "Added Sugar",
        "alts": [
            "added sugar",
            "added sugars",
            "includes added sugars"
        ],
        "Filter": "added_sugar",
        "envelope": "below-120p",
        "Units": "g"
    },
    "BIO": {
        "NutrDesc": "Biotin",
        "Filter": "biotin",
        "Units": "\u03bcg"
    },
    "IOD": {
        "NutrDesc": "Iodine",
        "alts": [
            "Iodide"
        ],
        "Filter": "iodine",
        "Units": "\u03bcg"
    },
    "MBD": {
        "NutrDesc": "Molybdenum",
        "Filter": "molybdenum",
        "Units": "\u03bcg"
    },
    "CHL": {
        "NutrDesc": "Chloride",
        "Filter": "chloride",
        "Units": "\u03bcg"
    },
    "OM3": {
        "NutrDesc": "Omega 3 (DHA+EPA)",
        "Filter": "omega3_dhaepa",
        "Units": "g",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0,
                    "Lunch": 0.45,
                    "Dinner": 0.5,
                    "Snack": 0
                }
            }
        ]
    },
    "OMA": {
        "NutrDesc": "Omega 3 (DHA+EPA+ALA)",
        "Filter": "omega3_dhaepaala",
        "Units": "g",
        "distributions": [
            {
                "min": {
                    "Breakfast": 0,
                    "Lunch": 0.45,
                    "Dinner": 0.5,
                    "Snack": 0
                }
            }
        ]
    },
    "OLF": {
        "NutrDesc": "FODMAP Oligos-fructans",
        "Filter": "oligos_fructans",
        "Units": "",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "OLG": {
        "NutrDesc": "FODMAP Oligos-GOS",
        "Filter": "oligos_gos",
        "Units": "",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "FRC": {
        "NutrDesc": "FODMAP Fructose",
        "Filter": "fructose",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "LAC": {
        "NutrDesc": "FODMAP Lactose",
        "Filter": "lactose",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "PLY": {
        "NutrDesc": "FODMAP Total Polyols",
        "Units": "",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "SOR": {
        "NutrDesc": "FODMAP Sorbitol",
        "Filter": "sorbitol",
        "Units": "",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "MAN": {
        "NutrDesc": "FODMAP Mannitol",
        "Filter": "mannitol",
        "Units": "",
        "distributions": [
            {
                "max": {
                    "Breakfast": 0.25,
                    "Lunch": 0.25,
                    "Dinner": 0.25,
                    "Snack": 0.25
                }
            }
        ]
    },
    "SAL": {
        "NutrDesc": "Sugar Alcohol",
        "Filter": "sugar_alcohol",
        "Units": "g"
    },
    "RAL": {
        "NutrDesc": "PRAL",
        "Filter": "pral",
        "Units": ""
    },
    "CHR": {
        "NutrDesc": "Chromium",
        "Filter": "chromium",
        "Units": "\u03bcg"
    },
    "FLU": {
        "NutrDesc": "Fluids",
        "Filter": "fluids",
        "Units": "ml"
    },
    "SUL": {
        "NutrDesc": "Sulfites",
        "Filter": "sulfites",
        "Units": "mg"
    }
};
