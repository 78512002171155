'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

const prepTimeFilters = [
    {label: 'Any Prep Time',             v: false},
    {label: 'Under 15 minutes',          v: {lte: 900}},
    {label: 'From 15 to 30 minutes', v: {gte: 901,  lte: 1800}},
    {label: 'From 30 to 45 minutes', v: {gte: 1801, lte: 2700}},
    {label: 'More than 45 minutes',      v: {gte: 2701}},
];

export default class PrepTime extends Component {
    static propTypes = {
    };

    static contextTypes = {
        setParamsToRecipeOnly: PropTypes.func,
    };

    onChangePrepTime = (prepTime) => {
        const { params, onChangeParams } = this.props;

        if (prepTime.v) {
            params.filters.hands_on_time = prepTime.v;
        } else {
            delete params.filters.hands_on_time;
        }

        onChangeParams(params);
    }

    getValueFromParams = (params) => {
        let index = 0, label = prepTimeFilters[0].label;

        if (!(params && params.filters && params.filters.hands_on_time)) {
            return {index, label};
        }

        const handsOnTime = params.filters.hands_on_time || {};

        const selectedPrepTime = prepTimeFilters.forEach((prepTime, i) => {
            if (!prepTime.v) {
            } else if (prepTime.v.lte && prepTime.v.gte &&
                prepTime.v.lte == handsOnTime.lte &&
                prepTime.v.gte == handsOnTime.gte) {
                index = i;
                label = prepTime.label;
            } else if (prepTime.v.lte && !prepTime.v.gte &&
                !handsOnTime.gte &&
                handsOnTime.lte == prepTime.v.lte) {
                index = i;
                label = prepTime.label;
            } else if (!prepTime.v.lte && prepTime.v.gte &&
                handsOnTime.gte == prepTime.v.gte &&
                !handsOnTime.lte) {
                index = i;
                label = prepTime.label;
            }

        });

        return { index, label };
    }

    render() {
        const { params } = this.props;
        const { index, label } = this.getValueFromParams(params);

        return (
            <div className="global-search-tag-group">
                <ul>
                    {prepTimeFilters.map((prepTime, i) => {
                        return (
                            <li key={i}>
                                <label className="global-search-checkbox global-search-radio">
                                    <input type="checkbox" checked={i === index}
                                           onChange={e => this.onChangePrepTime(prepTime)} />
                                    <span>{prepTime.label}</span>
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}
