'use strict';

import keymirror from 'keymirror';

const AuthConstants = keymirror({
    CHAT_CONNECT: null,
    CHAT_DISCONNECT: null,
    CHAT_SEND_MESSAGE: null,
    CHAT_LOAD_BEFORE: null,
    CHAT_MARK_AS_READ: null,
    CHAT_UPDATE_CHAT: null,
    CHAT_SEND_PRIVATE_MESSAGE: null,
});

export default AuthConstants;
