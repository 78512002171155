'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import { pluralize, singularize } from 'inflected';
import indexBy from 'lodash.indexby';
import SmartFraction from '../Widgets/SmartFraction.react';
import ChangePortionsModal from './ChangePortionsModal.react';

import { roundForHumans } from '../../utils/Math';
import { getNutrientsForMeals } from '../../utils/Nutrition';
import { getPrimaryMeal } from '../../utils/Meals';
import { isSingular } from '../../utils/Math';

import './FoodUnitsSelectorModal.scss';

export default class FoodUnitsSelectorModal extends Component {
    static propTypes = {
        food: PropTypes.object,
        meal: PropTypes.object,
        howMuchQuestion: PropTypes.node,
        buttonText: PropTypes.string,
        replaceChevronText: PropTypes.string,
        nutrientsIndex: PropTypes.number,
        default_meal: PropTypes.bool
    };

    static defaultProps = {
        howMuchQuestion: "How much did you eat?",
    };

    static contextTypes = {
        user: PropTypes.object,
        location: PropTypes.object,
        router: PropTypes.object,
        isPro: PropTypes.bool
    };

    constructor(props) {
        super(props);

        this.state = {
            isModalOpen: false,
            nutrients: {},
        };
    }

    componentDidMount = () => {
        this.syncAssets();
    }

    syncAssets = () => {
        const { meal, food } = this.props;
        const { user } = this.context;

        const contents = indexBy([food], 'uuid');

        const { titles } = getPrimaryMeal([meal], contents, contents);

        const nutrients = getNutrientsForMeals([meal], contents, user?.portion || 1, null);

        this.setState({nutrients, titles});
    }

    UNSAFE_componentWillReceiveProps = (nextProps, nextContext) => {
        this.syncAssets();
    }

    openModal = () => {
        const { onChangeAmount, meal, nutrientsIndex } = this.props;

        if (onChangeAmount) {
            this.setState({isModalOpen: true});
        } else {
            const { location, router } = this.context;
            const { pathname, query, hash } = location;
            query.editPortionsMealUuid = meal.uuid;
            if (nutrientsIndex) {
                query.nutrientsIndex = nutrientsIndex;
            }
            router.push({pathname, query, hash});
        }

    }

    closeChangePortionsModal = () => {
        this.setState({isModalOpen: false});
    }

    renderModal = () => {
        const { howMuchQuestion, onChangeAmount, food, meal, profile } = this.props;
        const {isModalOpen} = this.state;

        if(!isModalOpen) {
            return;
        }

        return (
            <ChangePortionsModal
                howMuchQuestion={howMuchQuestion}
                profile={profile}
                meal={meal}
                food={food}
                onChangeAmount={onChangeAmount}
                closeModal={this.closeChangePortionsModal} />
        )
    }


    renderSpanContent = () => {
        const { food, buttonText } = this.props;
        let { logged_amount, logged_unit, logged_grams, logged_milliliters } = this.props.meal;
        const { user } = this.context;
        let suffix = null;

        if (logged_unit == 'serving') {
            if (food?.milliliters_per_serving && user?.units_mode === 'english') {
                suffix = ' (' + roundForHumans(logged_amount * food?.milliliters_per_serving / 29.5735) + ' fl oz.)';
            } else if (food?.milliliters_per_serving && user?.units_mode === 'metric') {
                suffix = ' (' + roundForHumans(logged_amount * food?.milliliters_per_serving) + ' ml)';
            } else if (food?.grams_per_serving && user?.units_mode === 'english') {
                suffix = ' (' + roundForHumans(logged_amount * food?.grams_per_serving / 28.3495) + ' oz)';
            } else if (food?.grams_per_serving && user?.units_mode === 'metric') {
                suffix = ' (' + roundForHumans(logged_amount * food?.grams_per_serving) + ' g)';
            }
        }

        logged_unit = isSingular(logged_amount) ? singularize(logged_unit) : pluralize(logged_unit)
        logged_unit = logged_unit || 'item';


        if (!buttonText) {
            if (logged_grams || logged_milliliters) {
                return (
                    <span>
                        <SmartFraction value={logged_amount} showZero={true} /> {logged_unit} {suffix}
                    </span>
                );
            } else if (logged_amount && !(logged_grams || logged_milliliters)) {
                return (
                    <span>
                        <SmartFraction value={logged_amount} showZero={true} />
                        {isSingular(logged_amount) ? 'serving' : 'servings'} {suffix}
                    </span>
                );
            }
        }
        return buttonText ? <span>{buttonText}</span> : null;
    }

    render = () => {
        const { onChangeAmount, buttonText, replaceChevronText, readOnly = false } = this.props;
        let { logged_amount, logged_grams, logged_milliliters } = this.props.meal;
        const { isPro } = this.context;

        if (!(logged_grams || logged_milliliters) && !logged_amount) {
            return <span />
        }

        return (
            <div className="food-units-selector-outer">
                {readOnly ? this.renderSpanContent() : null}

                {!readOnly ? (
                    <button className="open-modal-btn" onClick={this.openModal}>
                        {this.renderSpanContent()}
                        {!buttonText && !replaceChevronText && (!isPro || onChangeAmount) ? <i className="icon-chevron-down"/> : null}
                        {replaceChevronText ? <span className="replace-chevron-text">{replaceChevronText}</span> : null}
                    </button>
                ) : null}

                {!readOnly ? this.renderModal() : null}
            </div>
        );
    }
}
