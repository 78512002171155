
'use strict';

import AppDispatcher from '../dispatcher/AppDispatcher';
import { EventEmitter } from 'events';
import assign from 'object-assign';
import AuthStore from './AuthStore';
import NutritionPatternConstants from '../constants/NutritionPatternConstants';
import { getConfig } from '../utils/Env';

const CHANGE_EVENT = 'change';

let _store = {
    nutritionPatterns: [],
    loaded: false,
    loading: false,
};

async function loadNutritionPatterns() {
    const nutritionPatterns = await AuthStore.fetch(getConfig('users_api') + '/nutrition-patterns');
    _store.nutritionPatterns = nutritionPatterns.elements;
}

var NutritionPatternStore = assign({}, EventEmitter.prototype, {
    getNutritionPatterns: function() {
        return _store.nutritionPatterns || [];
    },

    isLoaded: function() {
        return _store.loaded || false;
    },

    isLoading: function() {
        return _store.loading || false;
    },

    emitChange: function() {
        this.emit(CHANGE_EVENT);
    },

    addChangeListener(callback) {
        this.on(CHANGE_EVENT, callback);
    },

    removeChangeListener(callback) {
        this.removeListener(CHANGE_EVENT, callback);
    },
});

export default NutritionPatternStore;

AppDispatcher.register((payload) => {
    const { actionType } = payload.action;

    switch (actionType) {
        case NutritionPatternConstants.NUTRITION_PATTERN_LOAD:
            if (_store.loaded || _store.loading) {
                return;
            }
            _store.loading = true;
            NutritionPatternStore.emitChange();
            loadNutritionPatterns().then(() => {
                _store.loading = false;
                _store.loaded = true;
                NutritionPatternStore.emitChange();
            });
            break;

        case NutritionPatternConstants.NUTRITION_PATTERN_RELOAD:
            _store.loading = true;
            NutritionPatternStore.emitChange();
            loadNutritionPatterns().then(() => {
                _store.loading = false;
                _store.loaded = true;
                NutritionPatternStore.emitChange();
            });
            break;
    }
});
