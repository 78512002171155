'use strict';

import keymirror from 'keymirror';

const AuthConstants = keymirror({
    AUTH_AUTHENTICATE: null,
    AUTH_DEAUTHORIZE: null
});

export default AuthConstants;
