'use strict';

import allDiets from '../tables/diets';
import avoidances from '../tables/avoidances';

export function getDietsFromTags(tags) {
    return (tags || []).filter(tag => allDiets.find(d => d.name === tag))
               .map(tag => allDiets.find(d => d.name === tag))
               .filter(v => v);
}

export function getAvoidancesFromProps(props) {
    return (props || []).filter(prop => avoidances.find(a => a.property === prop))
                .map(prop => avoidances.find(a => a.property === prop))
                .filter(v => v);
}
