'use strict'

// File generated by bin/sync-static-tables.php
// DO NOT EDIT! (Edit the original copy in recipe/config/table/languages.php)

export default {
    "en": {
        "title": {
            "en": "English",
            "fr": "Anglais",
            "es": "Ingl\u00e9s",
            "zh": "\u82f1\u8bed"
        }
    },
    "fr": {
        "title": {
            "en": "French",
            "fr": "Fran\u00e7ais",
            "es": "Franc\u00e9s",
            "zh": "\u6cd5\u6587"
        }
    },
    "es": {
        "title": {
            "en": "Spanish",
            "fr": "Espagnol",
            "es": "Espa\u00f1ol",
            "zh": "\u897f\u73ed\u7259\u6587"
        }
    },
    "zh": {
        "title": {
            "en": "Chinese",
            "fr": "Chinoise",
            "es": "China",
            "zh": "\u4e2d\u6587"
        }
    }
};
