'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';

import './Working.scss';

export default class Working extends Component {
    render() {
        const { title, message } = this.props;

        return (
            <div className="patient-editor-working">
                <h2>{title}</h2>

                <i className="icon-spinner" />

                <p>{message}</p>
            </div>
        );
    }
}
