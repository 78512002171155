'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import './Orders.scss';

import allMealKitBrands from '../../tables/meal-kit-brands';

export default class Orders extends Component {
    static propTypes = {
    };

    static contextTypes = {
        user: PropTypes.object,
    };

    renderOrderStatus(order) {
        const shipment = order.shipments ? order.shipments[0] : null;

        switch(true) {
            case (order.status === 'ACCEPTED'):
                return 'ORDER PLACED';

            case (['IN_PROCESS', 'PROCESSED'].includes(order.status) && !shipment):
                return 'IN PROCESS';

            case (order.status === 'PROCESSED' &&
                  shipment?.status == 'CREATED' &&
                  ['PRE_TRANSIT', 'IN_TRANSIT'].includes(shipment?.delivery_status)):
                return (<>
                    SHIPPED
                    {shipment.tracking_url ?
                        <>&nbsp;&nbsp;<a target="_blank" href={shipment.tracking_url}>track</a></>
                    : null}
                </>);

            case (order.status === 'PROCESSED' &&
                  shipment?.status === 'CREATED' &&
                  shipment?.delivery_status === 'OUT_FOR_DELIVERY'):
                return (<>
                    OUT FOR DELIVERY
                    {shipment.tracking_url ?
                        <>&nbsp;&nbsp;<a target="_blank" href={shipment.tracking_url}>track</a></>
                    : null}
                </>);

            case (order.status === 'PROCESSED' &&
                  shipment?.status === 'CREATED' &&
                  shipment?.delivery_status === 'DELIVERED'):
                return (<>
                    DELIVERED
                    {shipment.tracking_url ?
                        <>&nbsp;&nbsp;<a target="_blank" href={shipment.tracking_url}>track</a></>
                    : null}
                </>);

            case (['CANCELLED', 'ERROR_CANCELLED'].includes(order.status)):
                return (<>
                    CANCELLED&nbsp;&nbsp;<a target="_blank" href="https://sunbasket.com/help/">contact Sunbasket</a>
                </>);

            case (order.status === 'ERROR'):
                return (<>
                    ERROR&nbsp;&nbsp;<a target="_blank" href="https://sunbasket.com/help/">contact Sunbasket</a>
                </>);

            default:
                return (<>
                    {order.status}&nbsp;&nbsp;<a target="_blank" href="https://sunbasket.com/help/">contact Sunbasket</a>
                </>);
        }
    }

    renderOrder = (order, i) => {
        const brandUuid = Object.keys(allMealKitBrands).find(uuid => {
            const brand = allMealKitBrands[uuid];

            if (brand.provider === order.provider) {
                return true;
            }
        });

        const brand = allMealKitBrands[brandUuid];

        return (
            <div className="order-info" key={i}>
                <p className="t4 id"><em>{brand.name} Order #{order.provider_order_id}</em></p>
                <p className="t5 placed"><span>Order Placed: </span><em>{moment(order.created).format('ddd, MMM Do YYYY')}</em></p>
                <p className="t5 status"><span>Status: </span><em>{this.renderOrderStatus(order)}</em></p>
                <p className="t5 delivery"><span>Delivery Date: </span><em>{moment(order.delivery_date).format('ddd, MMM Do YYYY')}</em></p>
            </div>
        );
    }

    render() {
        const { orders, children } = this.props;

        return (
            <div className="orders-container el-fonts">
                {orders.map(this.renderOrder)}
                {children}
            </div>
        );
    }
}
