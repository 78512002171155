'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import debounce from 'lodash.debounce';

import './Select.scss';

/**
 * The built-in browser drop-downs are inconsistent pieces of crap.
 *
 * This class replaces and abstracts them where necessary. It can be
 * styled and controlled much more easily, and I think it very closely
 * matches the good behaviors of the built-in, without some of its nastier
 * downsides.
 */
export default class Select extends Component {
    static propTypes = {
        button: PropTypes.node,
        children: PropTypes.node,
        tabIndex: PropTypes.number,
        options: PropTypes.array.isRequired,
        showSearch: PropTypes.bool,
        showAbove: PropTypes.bool,
        dropdownIcon: PropTypes.string,
        disabled: PropTypes.bool,
        searchPlaceholder: PropTypes.string,
        disableTypeAhead: PropTypes.bool,
        defaultClassName: PropTypes.string,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        showSearch: false,
        showAbove: false,
        disabled: false,
        dropdownIcon: 'icon-chevron-down',
        searchPlaceholder: 'Search by condition',
        defaultClassName: 'select-container',
        disableTypeAhead: false,
    };

    static contextTypes = {
        isMobile: PropTypes.bool,
        iPad: PropTypes.bool,
        iPhone: PropTypes.bool,
        viewportWidth: PropTypes.number,
        router: PropTypes.object,
    };

    static childContextTypes = {
        toggleMenu: PropTypes.func,
        closeMenu: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.clearTypeAhead = debounce(this.clearTypeAhead, 1000);

        this.inputRef = React.createRef();

        this.state = {
            isDropdownVisible: false,
            isFocused: false,
            typeAhead: '',

            search: '',
        };
    }

    getChildContext = () => {
        return {
            toggleMenu: this.toggleDropdown,
            closeMenu: this.closeDropdown,
        };
    };

    onOuterAction = (ev) => {
        if (!this.container || !this.dropdown) {
            return;
        }

        const isOuterAction = !this.container.contains(ev.target) && !this.dropdown.contains(ev.target);

        if (isOuterAction) {
            this.closeDropdown();
        }
    };

    closeDropdown = () => {
        this.setState({ isDropdownVisible: false });

        if (!process.browser) {
            return;
        }

        window.removeEventListener('mousedown', this.onOuterAction);
        window.removeEventListener('touchstart', this.onOuterAction);
        window.removeEventListener('keyup', this.onOuterAction);
    };

    toggleDropdown = (ev) => {
        const { isDropdownVisible } = this.state;

        ev && typeof ev.preventDefault === 'function' && ev.preventDefault();

        this.setState({ isDropdownVisible: !isDropdownVisible, search: '' }, () => {
            if (!isDropdownVisible) {
                this.scrollOptionIntoView(this.props.value);
            }
        });

        if (!process.browser) {
            return;
        }

        // Are we transitioning from hidden to visible? Start listening to touch events everywhere else.
        if (!isDropdownVisible) {
            window.addEventListener('mousedown', this.onOuterAction);
            window.addEventListener('touchstart', this.onOuterAction);
            window.addEventListener('keyup', this.onOuterAction);

            this.inputRef.current && this.inputRef.current.focus();
        } else {
            window.removeEventListener('mousedown', this.onOuterAction);
            window.removeEventListener('touchstart', this.onOuterAction);
            window.removeEventListener('keyup', this.onOuterAction);
        }
    };

    container = null;
    dropdown = null;
    scrollable = null;

    realizeContainer = (container) => {
        this.container = container;
    };

    realizeDropdown = (dropdown) => {
        this.dropdown = dropdown;
    };

    realizeScrollable = (scrollable) => {
        this.scrollable = scrollable;
    };

    onFocus = () => {
        this.setState({ isFocused: true, typeAhead: '' });
    };

    onBlur = (ev) => {
        this.setState({ isFocused: false });
    };

    clearTypeAhead = () => {
        this.setState({ typeAhead: '' });
    };

    scrollOptionIntoView = (value) => {
        const { scrollable, dropdown } = this;
        const { options } = this.props;

        if (!process.browser || !scrollable || !dropdown || !options) {
            return;
        }

        const $scrollable = $(scrollable);
        const $dropdown = $(dropdown);

        const index = options.indexOf(options.find((o) => o.value === value));

        if (index === -1) {
            return;
        }

        // Find the currently selected elements element.
        const $child = $scrollable.find(`[data-index="${index}"]`);

        // Element value not found
        if (!$child.length) {
            return;
        }

        const currentScrollTop = dropdown.scrollTop,
            viewportHeight = $dropdown.innerHeight(),
            scrollerHeight = $scrollable.innerHeight(),
            itemHeight = $child.innerHeight(),
            dropdownTop = $dropdown.offset().top,
            itemTop = $child.offset().top - dropdownTop + currentScrollTop;

        // Make sure that we're scrolled into view. Scroll into view if not.
        if (itemTop + itemHeight > currentScrollTop + viewportHeight) {
            dropdown.scrollTop = itemTop + itemHeight + 5 - viewportHeight;
        } else if (itemTop < currentScrollTop) {
            dropdown.scrollTop = itemTop;
        }

        // Otherwise, the element is currently visible through the viewport, no action is needed.
    };

    onChange = (value, hard = false) => {
        const { onChange, onHardChange } = this.props;

        this.scrollOptionIntoView(value);

        if (hard && onHardChange) {
            onHardChange(value);
            return;
        }

        onChange && onChange(value);
    };

    onChangeTypeAhead = (ev) => {
        const { value, options } = this.props;
        const typeAhead = String(ev.target.value).toLowerCase();

        let newValue = null;

        // Search through the opt keys to find the first one that matches the typeAhead
        options.forEach((option) => {
            if (newValue !== null) {
                return;
            }

            let { value, label, hint } = option;

            // If typeAhead matches the first part of the value
            if (String(value).toLowerCase().indexOf(typeAhead) === 0) {
                newValue = value;
            } else if (typeof label === 'string' && label.toLowerCase().indexOf(typeAhead) === 0) {
                newValue = value;
            } else if (typeof hint === 'string' && hint.toLowerCase().indexOf(typeAhead) === 0) {
                newValue = value;
            }
        });

        if (newValue !== null) {
            this.onChange(newValue);
        }

        // Make sure type-ahead is cleared after a second
        this.setState({ typeAhead }, this.clearTypeAhead);
    };

    onKeyUp = (ev) => {
        if (['ArrowDown', 'ArrowUp'].includes(ev.key)) {
            const { options, value } = this.props;

            // What index are we at now?
            let option = options.filter((o) => o.value === value)[0];
            let index = options.indexOf(option);

            if (ev.key === 'ArrowDown') {
                index++;
            }

            if (ev.key === 'ArrowUp') {
                index--;
            }

            if (options[index] && options[index].value !== value) {
                this.onChange(options[index].value);
            }
        }

        if (ev.key === 'Enter') {
            this.toggleDropdown();
        }
    };

    onClickOption = (option) => {
        this.onChange(option.value, true);
        this.closeDropdown();
        this.inputRef.current && this.inputRef.current.focus();
    };

    onBuiltinChange = (ev) => {
        if (ev.target.value === 'null-value') {
            return;
        }

        this.onChange(ev.target.value, true);
    };

    onSearchChange = (ev) => {
        this.setState({ search: ev.target.value });
    };

    searchFilter = (option) => {
        let { search } = this.state;

        if (!search) {
            return true;
        }

        let label = option.label && typeof option.label === 'string' ? option.label.toLowerCase() : '';
        let value = option.value && typeof option.value === 'string' ? option.value.toLowerCase() : '';
        search = search.toLowerCase();

        if (!label && !value) {
            return false;
        }

        if (label.indexOf(search) != -1) {
            return true;
        }

        if (value.indexOf(search) != -1) {
            return true;
        }

        return false;
    };

    render() {
        const { iPad, iPhone, viewportWidth } = this.context;
        const { typeAhead, isDropdownVisible, isFocused, search } = this.state;
        const {
            value,
            options,
            className,
            children,
            placeholder,
            tabIndex,
            prefix,
            disabled,
            showSearch,
            showAbove,
            dropdownIcon,
            searchPlaceholder,
            disableTypeAhead,
            defaultClassName,
            optionClassName,
        } = this.props;
        const option = options.find((o) => o.value == value);

        const isMobile = iPad || iPhone || viewportWidth < 751;

        return (
            <span
                className={[defaultClassName, className].filter((v) => v).join(' ')}
                data-disabled={disabled}
                data-state={isDropdownVisible}
                data-focus={isFocused}
                data-error={this.props['data-error']}
                data-above={showAbove}
                ref={this.realizeContainer}
            >
                {!isMobile && !disableTypeAhead ? (
                    <input
                        type="text"
                        className="type-ahead"
                        ref={this.inputRef}
                        autoComplete="none"
                        data-testid={placeholder || 'type-ahead-input'}
                        tabIndex={tabIndex}
                        value={typeAhead}
                        disabled={disabled}
                        onKeyUp={this.onKeyUp}
                        onChange={this.onChangeTypeAhead}
                        onClick={this.toggleDropdown}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                    />
                ) : (
                    <button className="active-dropdown-btn" onClick={this.toggleDropdown}></button>
                )}

                <button className="chevron" onClick={disabled ? null : this.toggleDropdown} tabIndex="-1">
                    <i className={dropdownIcon} />
                </button>

                {option ? (
                    <p className="value" data-testid="selected-value">
                        {prefix}
                        {option.selectedLabel || option.label}
                    </p>
                ) : null}
                {!option ? (
                    <p className="placeholder" data-testid="select-placeholder">
                        {placeholder}&nbsp;
                    </p>
                ) : null}

                <div className="select-dropdown" ref={this.realizeDropdown}>
                    <div className="dropdown-content">
                        {showSearch ? (
                            <input
                                type="text"
                                className="search"
                                name="search"
                                placeholder={searchPlaceholder || 'search-input'}
                                value={search}
                                tabIndex="-1"
                                onChange={this.onSearchChange}
                            />
                        ) : null}

                        {children}

                        <ul ref={this.realizeScrollable}>
                            {options.filter(this.searchFilter).map((opt, i) => {
                                return (
                                    <li
                                        className={'option ' + optionClassName}
                                        key={i}
                                        onClick={() => !opt.disabled && this.onClickOption(opt)}
                                        data-testid={opt.label}
                                        data-index={i}
                                        data-disabled={opt.disabled}
                                        data-highlighted={opt.highlight}
                                        data-selected={opt.value == value}
                                    >
                                        <span>{opt.label}</span>
                                        {opt.sub_label ? <label>{opt.sub_label}</label> : null}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </span>
        );
    }
}
