'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import debounce from 'lodash.debounce';
import store from 'store';
import TryItOutForm from '../../../../components/TryItOut/TryItOutForm.react';
import Prescription from '../../../components/Patients/Editor/Prescription.react';
import UserActions from '../../../../actions/UserActions';
import UserStore from '../../../../stores/UserStore';
import allConditions from '../../../../tables/conditions.js';
import { processVirtualPlans } from '../../../../utils/Plans';
import Analytics from '../../../../utils/Analytics';
import { getConfig } from '../../../../utils/Env';
import AuthStore from '../../../../stores/AuthStore';
import { getConfigurationIssues, getParamsForProfile } from '../../../utils/Patients';
import './TryItOutModal.scss';
import '../../Modals/Modals.scss';
import modalStyles from '../../../../jsx-styles/modals';
import MealDetails from '../../Patients/Editor/MealDetails.react.js';

const needCalorieEstimate = ['Pregnancy', 'Lactation', 'Overweight\/Obesity'];

export default class TryItOutModal extends Component {
    static propTypes = {
        page: PropTypes.string,
        profile: PropTypes.object,
    };

    static contextTypes = {
        router: PropTypes.object,
        location: PropTypes.object,
    };

    static defaultProps = {
        page: 'try_it_out'
    };

    constructor(props) {
        super(props);
        this.tryItOut = React.createRef();
        this.mealDetails = React.createRef();
        this.prescription = React.createRef();
        const { uuid } = UserStore.getUser();

        this.state = {
            profile: props.profile || null,
            error: false,
            working: false,
            flash: 0,
            page: props.page,
            user_uuid: uuid,
        }

        this.tickFlash = debounce(this.tickFlash, 200);
    }

    onGenerateMealPlan = (plan, altUser) => {
        UserActions.setAlternateUser(altUser);

        this.context.router.push({pathname: `/menus/${plan.uuid}/customize`, query: {virtual: 1, altPatient: 1}});
    }

    onReviewMealDetails = () => {
        const profile = this.tryItOut.current.getProfileFromState();

        this.setState({profile, page: 'meal_details'});
    }

    onReviewPrescription = () => {
        const { profile } = this.state;

        const updatedProfile = this.mealDetails.current.mutate(profile);

        this.setState({profile: updatedProfile, page: 'prescription'});
    }

    tickFlash = () => {
        let { flash } = this.state;

        flash++;

        if (flash > 2) {
            return this.setState({flash: 0});
        }

        this.setState({flash}, this.tickFlash);
    }

    validate = (altUser) => {

        // Configuration error
        const { warnings, errors } = getConfigurationIssues(altUser);

        if (errors.length) {
            Analytics.tryItOutError(errors[0]);
            return {error: errors[0]};
        }

        return false;
    }

    getMealPlan = (skipLoadEstimator = false) => {

        let { profile, user_uuid } = this.state;

        if (skipLoadEstimator !== true && needCalorieEstimate.includes(profile.conditions[0])) {
            return this.tryItOut.current.showEnergyEstimator(true);
        }

        let alert = false;

        if (false !== (alert = this.validate(profile))) {
            this.setState(alert);
            return;
        }

        // Generate a meal plan for that alternate user
        this.setState({error: false, working: true});

        if (this.prescription && this.prescription.current.mutate) {
            profile = this.prescription.current.mutate(profile);
        }

        const params = getParamsForProfile(profile, 'generator');

        params.size = 1;

        AuthStore.fetch(getConfig('recipe_api') + '/plan-generator', {
            method: 'POST',
            headers: {'Content-Type': 'application/json; schema=vplan/parameters/1'},
            body: JSON.stringify(params),
        }, true).then(
            response => {
                if (!(response.elements && response.elements.length)) {
                    Analytics.tryItOutError('Meal Plan could not be generated');
                    this.setState({error: 'A meal plan could not be generated. Please revise your selections and try again.', working: false});
                }

                // Lastly, redirect to the generated meal plan and tell the customizer to use the alternate user.
                const plans = processVirtualPlans(response.elements);

                store.remove(`pro-try-it-out-${user_uuid}`);

                this.onGenerateMealPlan(plans[0], profile);


            },
            error => {
                Analytics.tryItOutError(error.message);
                this.setState({error: error.message, working: false});
            }
        );
    }

    handleBackBtn = () => {
        const {profile, page} = this.state;
        const isEmptyStateDashboard = this.props.page == 'prescription';

        if (isEmptyStateDashboard) {
            return this.props.closeModal();
        }

        if (profile && page == 'prescription') {
            return this.setState({page: "meal_details"});
        }

        if (profile && page == 'meal_details') {
            return this.setState({page: "try_it_out"});
        }
    }

    handleOkBtn = () => {
        const { profile, page } = this.state;

        if (page == "try_it_out") {
            return this.onReviewMealDetails();
        }
        
        if (profile && page == "meal_details") {
            return this.onReviewPrescription();
        }
        
        if (profile && page == "prescription") {
            return this.getMealPlan();
        }
    }

    getCtaText = (profile, page) => {

        if (page == 'try_it_out') {
            return "NEXT: REVIEW MEAL DETAILS";
        }

        if (profile && page == 'meal_details') {
            return "NEXT: REVIEW PRESCRIPTION";
        }

        if (profile && page == 'prescription') {
            return "GENERATE MEAL PLAN";
        }

        return "";
    }

    render() {
        const { closeModal } = this.props;
        const { location } = this.context;
        const { error, flash, profile, page, working, user_uuid } = this.state;

        const ctaText = this.getCtaText(profile, page);

        let conditionsList = allConditions.filter(c => c.on_pro);

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="el-modal el-modal2"
                contentLabel="Try It Out"
                style={modalStyles.largeSquareModal}
                closeTimeoutMS={250}>

                <div className="el-modal-container el-modal2-container try-it-out-modal-container">
                    <div className="modal-badge">
                        Meal Plan Wizard
                    </div>

                    <header className="modal-header">
                        <h2>{page == 'try_it_out'  ? "Show Me a Meal Plan For" : "Review Nutrition Prescription"}</h2>
                        <button className="el-modal-close-x" onClick={closeModal}>
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="el-modal-body-container el-modal2-body-container el-fonts" data-prescription={page == 'prescription'}>
                        {profile && page == 'prescription' && <Prescription ref={this.prescription} isMealPlanWizard={true} patient={profile}/>}
                        {profile && page == 'meal_details' && <MealDetails ref={this.mealDetails} patient={profile} /> }
                        {(!profile || page == 'try_it_out') &&
                            <TryItOutForm working={working} location={location} ref={this.tryItOut} conditionsList={conditionsList}
                                needCalorieEstimate={needCalorieEstimate} storageKey={`pro-try-it-out-${user_uuid}`} tryItMode="pro" />
                        }
                    </div>

                    <footer>
                        {error ?
                            <p className="error-msg">{error}</p>
                        : null}
                        {profile && page != 'try_it_out' ?
                            <button className="el-modal-cancel-btn" onClick={this.handleBackBtn}>
                                BACK
                            </button>
                        : null}
                        <button data-flash={flash} className="el-modal-ok-btn" onClick={this.handleOkBtn}>
                             {working ? <span>Calculating <i className="icon-spinner" /></span> : ctaText}
                        </button>
                    </footer>
                </div>
            </Modal>
        );
    }
}
