'use strict';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

//import './AvoidanceParams.scss';
import UserStore from '../../../stores/UserStore';

import allConditions from '../../../tables/conditions';

import { getDietsFromTags } from '../../../utils/Diets';

export default class AvoidanceParams extends Component {
    removeAvoidances = () => {
        const { params, onChangeParams } = this.props;

        params.filters['!ingredient_tags'] = [];
        params.filters['!foods'] = [];

        onChangeParams(params);
    }

    render() {
        const { params = {} } = this.props;
        let ingredientTags = (params.filters && params.filters['!ingredient_tags']) || [];
        let exFood = params.filters && params.filters['!foods'] || [];

        if (!ingredientTags.length && !exFood.length) {
            return <span />
        }

        return (
            <div className="param-item" onClick={this.removeAvoidances}>
                Excluding Allergies &amp; Dislikes <i className="icon-close-x" />
            </div>
        );
    }
}
