'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
// import ImgResized from '../../Widgets/ImgResized.react';
import Select from '../../../pro/components/Widgets/Select.react';

import './EditServings.scss';

export default class EditServings extends Component {

    static propTypes = {
        servings: PropTypes.number,

        onChangeServings: PropTypes.func,
    };

    static defaultProps = {
    }

    constructor(props) {
        super(props);

        this.state = {
            mode: 'select',
        };
    }

    onSelectServings = (servings) => {
        const { onChangeServings } = this.props;

        if (servings === -1) {
            this.setState({
                mode: 'enter',
                value: (this.props.servings || ''),
            });

            return;
        }

        onChangeServings(servings);
    }

    onChangeServings = (ev) => {
        const { onChangeServings } = this.props;

        const value = ev.target.value;

        this.setState({value}, () => {
            const servings = parseInt(value);

            if (typeof servings === 'number' &&
                !isNaN(servings) &&
                servings > 0 && servings < 999) {
                onChangeServings(servings);
            }
        });
    }

    onBlurInput = (ev) => {
        const { onChangeServings } = this.props;

        const value = ev.target.value;
        const servings = parseInt(value);

        if (typeof servings === 'number' &&
            !isNaN(servings) && servings > 0) {
            onChangeServings(servings);

            this.setState({mode: 'select'});
        }
    }

    render = () => {
        const { servings } = this.props;
        const { mode, value } = this.state;

        let servingsOpts = [
            {label: '1 serving',  value: 1},
            {label: '2 servings', value: 2},
            {label: '3 servings', value: 3},
            {label: '4 servings', value: 4},
            {label: '5 servings', value: 5},
            {label: '6 servings', value: 6},
            {label: '7 servings', value: 7},
            {label: '8 servings', value: 8},
        ];

        if (servings > 8) {
            servingsOpts.push({label: `${servings} servings`, value: servings});
        }

        servingsOpts.push({label: 'more...',    value: -1});

        return (
            <section className="edit-recipe-servings">
                {mode === 'select' ?
                    <Select dropdownIcon={"icon-down-arrow"} disableTypeAhead={true} placeholder="select" options={servingsOpts} value={servings} onChange={this.onSelectServings} />
                : null}
                {mode === 'enter'  ?
                    <div className="with-units">
                        <input type="number" max="999" min="1" value={value} onChange={this.onChangeServings} onBlur={this.onBlurInput} />
                        <label>servings</label>
                    </div>
                : null}
            </section>
        );
    }
}
