'use strict';

import keymirror from 'keymirror';

const UserConstants = keymirror({
    USER_COMPLETE_LOGIN: null,
    USER_CHANGE_PASSWORD: null,
    USER_LOGOUT: null,
    USER_REGISTER: null,
    USER_SUBSCRIBE: null,
    USER_UPDATE_META: null,
    USER_CHANGE_EMAIL: null,
    USER_SET_PREFERENCES: null,
    USER_SET_ALTERNATE_USER: null,
    USER_HYDRATE_LOCATION: null,
    USER_HYDRATE_PROFILE_KEY: null,
});

export default UserConstants;
