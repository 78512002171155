'use strict';

import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from 'react-modal';
import uuidGen from 'uuid';

import ConfirmOverwriteModal from './ConfirmOverwriteModal.react';

import { getParticipantsForMeal, updateMealLeftovers, getPrimaryMeal } from '../../../../utils/Meals';

import './MealRepeatModal.scss';

export default class MealRepeatModal extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
        meals: PropTypes.array,
        onModifyMeals: PropTypes.func,

        allMealTypes: PropTypes.array,
    };

    static defaultProps = {
        allMealTypes: ['Breakfast', 'Lunch', 'Snack', 'Dinner'],
    };

    static contextTypes = {
        profile: PropTypes.object,
        plan: PropTypes.object,
        recipes: PropTypes.object,
        foods: PropTypes.object,

        confirm: PropTypes.func,
    };

    constructor(props, context) {
        super(props, context);

        const { meals, plan } = props;
        const { recipes, foods } = context;
        const { primary, titles } = getPrimaryMeal(meals, recipes, foods);

        const ogOffset = primary.offset;
        const ogMealType = primary.meal;
        const idsToRepeat = meals.map(m => m.id);

        const ogLeftovers = (plan.items || []).filter(m => !m.deleted && idsToRepeat.includes(m.parent));
        let ogLeftoverOffsets = ogLeftovers.map(m => m.offset);
        ogLeftoverOffsets = ogLeftoverOffsets.filter((d, i) => ogLeftoverOffsets.indexOf(d) === i);

        this.state = {
            selection: [],
            titles,
            primary,

            idsToRepeat,
            ogOffset, // original "gansta" offset
            ogMealType,
            ogLeftovers,
            ogLeftoverOffsets,
        };
    }

    generateMealId() {
        return uuidGen.v4().substring(0, 8);
    }

    isOgMeal = (offset, mealType) => {
        return (offset == this.state.ogOffset && this.state.ogMealType === mealType);
    }

    selectMealTypeOffset = (offset, mealType) => {
        let { selection } = this.state;

        let selected = selection.find(sel => sel.offset == offset && sel.mealType === mealType);

        if (selected) {
            selection.splice(selection.indexOf(selected), 1);
        } else {
            selection.push({offset, mealType});
        }

        this.setState({selection});
    }

    isMealTypeOffsetSelected = (offset, mealType) => {
        const { selection } = this.state;

        const selected = selection.find(sel => sel.offset == offset && sel.mealType === mealType);

        return selected ? true : false;
    }

    isSelectionLeftover = (offset, mealType) => {
        const { selection, ogLeftoverOffsets } = this.state;

        if (ogLeftoverOffsets.length === 0) {
            return false;
        }

        let isSelectionLeftover = false;

        selection.forEach(sel => {
            if (sel.mealType === mealType && sel.offset < offset && sel.offset + ogLeftoverOffsets.length >= offset) {
                isSelectionLeftover = true;
            }
        });

        return isSelectionLeftover;
    }

    range(min, max) {
        let arr = [];

        for(var i = min; i <= max; i++) {
            arr.push(i);
        }

        return arr;
    }

    getRepeatOverlaps = (mealsToRepeat, selection) => {
        const { plan, recipes, profile } = this.context;
        const { ogLeftoverOffsets, idsToRepeat } = this.state;

        const overlaps = [], clears = [];

        selection.forEach(({offset, mealType}) => {
            const sourceMeals = mealsToRepeat.map(m => ({
                ...m,
                id: this.generateMealId(),
            }));

            const isDestMeal = m => (!m.deleted && m.meal === mealType && m.offset == offset && !idsToRepeat.includes(m.parent));
            const destMeals = (plan.items || []).filter(isDestMeal);

            if (destMeals.length) {
                overlaps.push({
                    mealType,
                    offset,
                    destMeals,
                    sourceMeals,
                });
            } else {
                clears.push({
                    mealType,
                    offset,
                    sourceMeals,
                });
            }

            const { primary, recipe } = getPrimaryMeal(sourceMeals, recipes);

            if (primary.meal_type === 'fresh' && recipe) {
                const participants = getParticipantsForMeal(primary, profile);
                const neededPerMeal = participants.reduce((total, member) => total + (member.portion || 1), 0);

                // How many days of leftovers did mealsToRepeat have?
                ogLeftoverOffsets.forEach((dummy, i) => {
                    const leftoverOffset = offset + i + 1;

                    const destMeals = (plan.items || []).filter(m => !m.deleted &&
                                                            m.meal === mealType &&
                                                            leftoverOffset == m.offset &&
                                                            !idsToRepeat.includes(m.parent));

                    // Create a leftover record for each sourceMeal
                    const sourceLeftovers = sourceMeals.map(sourceMeal => ({
                        ...sourceMeal,
                        id: this.generateMealId(),
                        meal_type: 'leftover',
                        parent: sourceMeal.id,
                        leftover_servings: neededPerMeal,
                    }));

                    if (destMeals.length) {
                        overlaps.push({
                            mealType,
                            offset: leftoverOffset,
                            sourceMeals: sourceLeftovers,
                            destMeals,
                        });
                    } else {
                        clears.push({
                            mealType,
                            offset: leftoverOffset,
                            sourceMeals: sourceLeftovers,
                        });
                    }
                });
            }
        });

        return { overlaps, clears };
    }

    completeRepeatMeals = (clears) => {
        const { onModifyMeals, closeModal } = this.props;

        let dirtyMeals = [];

        clears.forEach(({sourceMeals, mealType, offset}) => {
            sourceMeals.forEach(meal => {
                meal.meal = mealType;
                meal.offset = offset;

                if (dirtyMeals.indexOf(meal) === -1) {
                    dirtyMeals.push(meal);
                }
            });
        });

        onModifyMeals(dirtyMeals);

        closeModal(true);
    }

    onRepeatMeal = () => {
        const { meals } = this.props;
        const { selection } = this.state;

        if (!selection.length) {
            this.setState({error: 'Please select where you\'d like to repeat this meal'});
            return;
        }

        const { overlaps, clears } = this.getRepeatOverlaps(meals, selection);

        // If there are no overlaps, just reschedule the meal right now
        if (!overlaps.length) {
            return this.completeRepeatMeals(clears);
        }

        // There are overlaps. We need to display the confirm overwrite modal. It will
        // perform all the additional meal operations based upon the users answers.
        const newState = {
            isConfirmOverwriteOpen: true,
            confirmOverlaps: overlaps,
            confirmClears: clears,
        };

        this.setState(newState);
    }

    closeModal = (allDone) => {
        const { closeModal } = this.props;

        if (allDone === true) {
            return closeModal();
        }

        this.setState({isConfirmOverwriteOpen: false});
    }

    renderConfirmOverwriteModal = () => {
        const { isConfirmOverwriteOpen, confirmOverlaps, confirmClears } = this.state;
        const { meals, onModifyMeals } = this.props;

        if (!isConfirmOverwriteOpen) {
            return null;
        }

        return <ConfirmOverwriteModal meals={meals}
                    onModifyMeals={onModifyMeals}
                    overlaps={confirmOverlaps}
                    clears={confirmClears}
                    isRepeatMeal={true}
                    closeModal={this.closeModal} />
    }

    render() {
        const { error, titles, ogOffset, ogMealType } = this.state;
        const { meals, closeModal, maxOffset, allMealTypes } = this.props;
        const { plan, recipes, foods } = this.context;

        const ogIDs = meals.map(m => m.id);

        return (
            <Modal isOpen={true}
                onRequestClose={closeModal}
                closeModal={closeModal}
                className="meal-repeat-modal"
                contentLabel="Reschedule Meal"
                overlayClassName="feed-modal-overlay"
                closeTimeoutMS={250}>
                <div className="edit-meal-modal-container meal-repeat-modal-container">
                    <header>
                        <button onClick={closeModal} className="close-btn">
                            <i className="icon-close-x" />
                            <span className="assistive-text">Close Modal</span>
                        </button>
                    </header>

                    <div className="modal-scroll-container">
                        <header>
                            <h1>Repeat Meal</h1>
                            <h3>{titles.join(' + ')}</h3>
                        </header>

                        <div className="upcoming-dates-picker">
                            {this.range(0, maxOffset).map(offset => {
                                return (
                                    <div key={offset}>
                                        <h4>Day {offset + 1}</h4>
                                        <ul>
                                            {allMealTypes.map(mealType => {
                                                const mealTypeDateMeals = (plan.items || []).filter(m => m.meal === mealType && m.offset == offset);

                                                const { primary, titles } = getPrimaryMeal(mealTypeDateMeals, recipes, foods);
                                                const isOgMeal = offset == ogOffset && mealType == ogMealType;
                                                const isOgLeftover = mealTypeDateMeals.filter(m => ogIDs.includes(m.parent)).length > 0;
                                                const isSelectionLeftover = this.isSelectionLeftover(offset, mealType);
                                                let disabled = isOgMeal || isOgLeftover || isSelectionLeftover;

                                                return (
                                                    <li key={mealType}>
                                                        <button className={"meal-toggle-btn"}
                                                            onClick={() => this.selectMealTypeOffset(offset, mealType)}
                                                            disabled={disabled}
                                                            data-selected={isOgMeal || isSelectionLeftover || this.isMealTypeOffsetSelected(offset, mealType)}>
                                                            {isOgMeal ? <span>CURRENT &mdash; </span> : null}
                                                            <em>{mealType}</em>
                                                            {mealTypeDateMeals.length > 0 ?
                                                                <span>{primary?.meal_type === 'leftover' ? 'LEFTOVER: ' : null}{titles.join(' + ')}</span>
                                                            : null}
                                                        </button>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <footer>
                        {error ? <p className="error-msg">{error}</p> : null}
                        <button className="ok-btn" onClick={this.onRepeatMeal}>Save</button>
                    </footer>

                    {this.renderConfirmOverwriteModal()}
                </div>
            </Modal>
        );
    }
}
