

'use strict';

export default [
    {
        label: 'Restaurant Meal',
        icon: 'icon-restaurant-meals',
    },
    {
        label: 'Produce',
        fresh: true,
        advance_prep: [
            {'prep_step': 'peel', 'earliest': 345600},
            {'prep_step': 'slice', 'earliest': 345600},
            {'prep_step': 'chop', 'earliest': 345600},
            {'prep_step': 'dice', 'earliest': 345600},
        ],
        icon: 'icon-produce2',
        grocery: {shelves: [
            'Fresh Produce',
        ]}
    },
    {
        label: 'Produce - Fruit',
        fresh: true,
        advance_prep: [
            {'prep_step': 'peel', 'earliest': 345600},
            {'prep_step': 'slice', 'earliest': 345600},
            {'prep_step': 'chop', 'earliest': 345600},
            {'prep_step': 'dice', 'earliest': 345600},
        ],
        icon: 'icon-produce2',
        grocery: {shelves: [
            'Fresh Produce',
        ]}
    },
    {
        label: 'Produce - Vegetables',
        fresh: true,
        advance_prep: [
            {'prep_step': 'peel', 'earliest': 345600},
            {'prep_step': 'slice', 'earliest': 345600},
            {'prep_step': 'chop', 'earliest': 345600},
            {'prep_step': 'dice', 'earliest': 345600},
        ],
        icon: 'icon-produce2',
        grocery: {shelves: [
            'Fresh Produce',
        ]}
    },
    {
        label: 'Appetizers & Sides',
        icon: 'icon-logo'
    },
    {
        label: 'Baby Food',
        icon: 'icon-baby-bottle'
    },
    {
        label: 'Bakery',
        fresh: true,
        icon: 'icon-bakery',
        grocery: {shelves: [
            'Bakery & Bread',
        ]},
    },
    {
        label: 'Baking Goods',
        icon: 'icon-baking3',
        grocery: {shelves: [
            'Baking',
            'Pantry',
        ]}
    },
    {
        label: 'Beverages',
        icon: 'icon-beverages2',
        grocery: {shelves: [
            'Beverages',
            'Coffee',
        ]}
    },
    {
        label: 'Brand Name Food',
        icon: 'icon-logo'
    },
    {
        label: 'Burgers',
        icon: 'icon-logo',
        grocery: {shelves: [
            'Meat / Beef / Ground Beef & Patties',
        ]}
    },
    {
        label: 'Canned / Jar Goods',
        icon: 'icon-canned-goods',
        grocery: {shelves: [
            'Pantry',
        ]}
    },
    {
        label: 'Cereals',
        fresh: true,
        icon: 'icon-cereals',
        grocery: {shelves: [
            'Breakfast & Cereal',
            'Bakery & Bread',
        ]}
    },
    {
        label: 'Dairy',
        fresh: true,
        icon: 'icon-dairy2',
        grocery: {shelves: [
            'Dairy & Eggs',
        ]}
    },
    {
        label: 'Deli',
        icon: 'icon-deli',
        fresh: true,
        grocery: {shelves: [
            'Deli',
        ]}
    },
    {
        label: 'Desserts',
        icon: 'icon-logo',
        grocery: {shelves: [
            // 'Frozen',
            // 'Pantry / Baking / Baking and Dessert Mixes'
        ]},
    },
    {
        label: 'Entrees',
        icon: 'icon-logo',
        grocery: {shelves: []}
    },
    {
        label: 'Fast Foods',
        icon: 'icon-restaurant-meals',
        grocery: {shelves: []}
    },
    {
        label: 'Frozen Foods',
        fresh: true,
        icon: 'icon-frozen-foods',
        grocery: {shelves: [
            'Frozen Foods',
            'Beverages'
        ]},
    },
    {
        label: 'International / Ethnic',
        icon: 'icon-international',
        grocery: {shelves: [
            'Pantry',
        ]},
    },
    {
        label: 'Liquor',
        icon: 'icon-liquor3',
        grocery: {shelves: [
            'Alcohol',
        ]},
    },
    {
        label: 'Meats / Seafood',
        fresh: true,
        icon: 'icon-seafood',
        grocery: {shelves: [
            'Meat & Seafood',
        ]}
    },
    {
        label: 'Pasta / Rice / Beans',
        icon: 'icon-pasta-rice-beans',
        grocery: {shelves: [
            'Pantry'
        ]}
    },
    {
        label: 'Pet Products',
        icon: 'icon-logo',
        grocery: {shelves: []}
    },
    {
        label: 'Pizza',
        icon: 'icon-logo',
        grocery: {shelves: []}
    },
    {
        label: 'Salads',
        icon: 'icon-logo',
        grocery: {shelves: [
            'Deli',
        ]}
    },
    {
        label: 'Sandwiches',
        icon: 'icon-logo',
        grocery: {shelves: [
        ]}
    },
    {
        label: 'Snacks',
        icon: 'icon-snacks2',
        grocery: {shelves: [
            'Snacks, Cookies & Chips',
        ]}
    },
    // {
    //     label: 'Soup',
    //     icon: 'icon-logo',
    //     grocery: {shelves: []},
    // },
    {
        label: 'Soups / Sauces / Gravies',
        icon: 'icon-soup-pot',
        grocery: {shelves: [
            'Pantry',
        ]},
    },
    {
        label: 'Spices / Condiments',
        icon: 'icon-condiment2',
        grocery: {shelves: [
            'Pantry',
            'Baking',
        ]},
    },
    {
        label: 'Toppings & Ingredients',
        icon: 'icon-logo',
        grocery: {shelves: []},
    },
    {
        label: 'Supplements',
        icon: 'icon-blender',
        grocery: {shelves: []},
    },
    {
        label: 'Fresh & Ready',
        grocery: {shelves: []},
    },
    {
        label: 'Meal Kit',
        grocery: {shelves: []},
    },
    {
        label: 'Ready Made Meal',
        grocery: {shelves: []},
    },
];
