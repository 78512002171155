'use strict'

import AppDispatcher from '../dispatcher/AppDispatcher';
import MealConstants from '../constants/MealConstants';
import MealStore from '../stores/MealStore';
import GroceryActions from '../actions/GroceryActions';

const MealActions = {
    loadMealsByIds: (uuids) => {
        if (!(uuids && uuids.length)) {
            return;
        }

        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_LOAD_BY_IDS,
            uuids,
        });
    },

    upsertMeals: (meals) => {
        if (!(meals && meals.length)) {
            return;
        }

        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_UPSERT,
            meals,
        });
    },

    upsertMealsWithoutQueue: (meals) => {
        if (!(meals && meals.length)) {
            return;
        }

        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_UPSERT_WITHOUT_QUEUE,
            meals,
        });
    },

    /**
     *  Deleting meals causes the MealStore to inform the server that they're deleted, and leaves them
     *  in deleted state for 5 seconds.
     *
     * @param  {[type]} meals [description]
     * @return {[type]}       [description]
     */
    deleteMeals: (meals) => {
        if (!(meals && meals.length)) {
            return;
        }

        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_DELETE,
            meals,
        });
    },

    /**
     * Clearing meals just removes the specified meals from the internal array, and updating any subscribers. It
     * does not fire ajax because its know that using this function means the server has already deleted them.
     *
     * @param  {[type]} meals [description]
     * @return {[type]}       [description]
     */
    clearMeals: (meals) => {
        if (!(meals && meals.length)) {
            return;
        }

        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_CLEAR,
            meals,
        });
    },

    mealFeedRegen: (ignoreFirst = [], dateStart, dateEnd) => {
        const mealsToDelete = MealStore.getMeals().filter(m => {
            if (dateStart.isBefore(m.date, 'day')) {
                return true;
            }

            if (dateStart.isSame(m.date, 'day') && !ignoreFirst.includes(m.meal)) {
                return true;
            }

            return false;
        });

        GroceryActions.asyncRemoveMealsFromGroceries(mealsToDelete);

        AppDispatcher.handleViewAction({actionType: MealConstants.MEALS_FEED_REGEN, mealsToDelete});
    },

    ensureDateRangeLoaded: (dateStart, dateEnd) => {
        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_ENSURE_DATE_RANGE,
            dateStart, dateEnd
        });
    },

    hydrateMeals: (meals = [], orders = []) => {
        AppDispatcher.handleViewAction({
            actionType: MealConstants.MEALS_HYDRATE,
            meals, orders
        });
    }
};

export default MealActions;
