'use strict';

import { Component } from 'react';
import Expander from '../../Widgets/Expander.react';
import NutritionPatternStore from '../../../../stores/NutritionPatternStore';
import Select from '../../Widgets/Select.react';
import store from 'store';
import MealTypesInfo from './MealTypesInfo.react';
import NutritionPatternActions from '../../../../actions/NutritionPatternActions';
import AuthStore from '../../../../stores/AuthStore';
import { getConfig } from '../../../../utils/Env';
import './MealDetails.scss';
import './PatientForm.scss';

const LOCALSTORAGE_KEY = 'eating-pattern';

export default class MealDetails extends Component {
    static contextTypes = {
    };

    static propTypes = {
    };

    constructor(props) {
        super(props);

        const { patient } = this.props;
        const { preferences } = patient;

        const selectedEatingPattern = store.get(LOCALSTORAGE_KEY);

        this.state = {
            loading: false,
            nutritionPatterns: NutritionPatternStore.getNutritionPatterns(),
            availableEatingPatterns: null,
            preferences: {
                ...preferences,
                meal_types: this.setParticipants(preferences.meal_types)
            },
            selectedEatingPattern,
            error: null,
        };
        this.mealType = {};
    }

    componentDidMount = () => {
        const { nutritionPatterns } = this.state

        if (nutritionPatterns.length) {
            this.setEatingPattern(nutritionPatterns);
        }

        NutritionPatternActions.load();
        NutritionPatternStore.addChangeListener(this.onNutritionPatternStoreChange);
    }

    componentWillUnmount = () => {
        NutritionPatternStore.removeChangeListener(this.onNutritionPatternStoreChange);
    }

    onNutritionPatternStoreChange = () => {
        const nutritionPatterns = NutritionPatternStore.getNutritionPatterns();

        if (nutritionPatterns.length) {
            this.setEatingPattern(nutritionPatterns);
        };

        this.setState({
            nutritionPatterns,
            loading: NutritionPatternStore.isLoading(),
        });
    }

    setEatingPattern = async (nutritionPatterns) => {
        const { selectedEatingPattern } = this.state;
        const {patient} = this.props;
        const {gender, birthdate, weight_kg, target_energy_kcal, conditions} = patient;
        const pattern = nutritionPatterns.find((pattern) => pattern.uuid === conditions[0].uuid);
        const query = {
            gender,
            birthdate,
            weight_kg,
            target_energy_kcal,
            calculate_implicit: true
        };
        this.setState({loading: true});
        const {preferences} = await this.resolvePattern(query, pattern);
        const availableEatingPatterns = preferences[0].available_eating_patterns;
        const defaultEatingPattern = availableEatingPatterns[0];
        
        this.setState({selectedEatingPattern: selectedEatingPattern ?? defaultEatingPattern.name, availableEatingPatterns, loading: false});
    }

    resolvePattern = async (query, pattern) => {
    
        // If we can't resolve this profile, don't bother.
        if (!pattern.links.resolve) {
            return;
        }
    
        const response = await AuthStore.fetch({url: getConfig('users_api') + pattern.links.resolve.href, query});

        return response;
    }

    toggleEatingPattern = (name) => {
        const { availableEatingPatterns, preferences } = this.state;
        const eatingPattern = availableEatingPatterns.find((pattern) => name === pattern.name);
        const mealTypes = [...eatingPattern.meal_types];
        const mealTypesWithParticipants = this.setParticipants(mealTypes);
        const updatedPreferences = {...preferences, meal_types: mealTypesWithParticipants};
        this.setState({selectedEatingPattern: eatingPattern.name, preferences: updatedPreferences, dirty: true});
    }

    setParticipants = (mealTypes) => {
        const { patient } = this.props;
        const { family = [] } = patient;
        const defaultParticipants = [patient.uuid].concat(family.map((member) => member.uuid));
        const updatedMealTypes = mealTypes.map((mealType) => ({
            ...mealType,
            participants: mealType.participants || defaultParticipants
        }));
        return updatedMealTypes;
    }

    isDirty = () => {
        return this.state.dirty;
    }

    validate = () => {
        const {preferences} = this.state;
        const { meal_types } = preferences;
        const maxCostMinimumValue = {
            'Breakfast': 3.25,
            'Lunch': 5,
            'Snack': 3,
            'Dinner': 5
        }

        let error;
        for(let mealType of meal_types) {
            if(mealType.max_cost && mealType.max_cost < maxCostMinimumValue[mealType.main_dish]) {
                error = {
                    name: `${mealType.name}-max-cost`,
                    message: `Max cost must be greater than ${maxCostMinimumValue[mealType.main_dish]} for ${mealType.name}`,
                };
                break;
            }
        };

        if(error) {
            this.setState({error: error.name});
            return error.message;
        }

        this.setState({error: null});
        return true;
    }

    mutate = (patient) => {
        const { preferences, selectedEatingPattern } = this.state;

        store.set(LOCALSTORAGE_KEY, selectedEatingPattern);

        patient.preferences = {...patient.preferences, ...preferences};

        if (!patient.completed.includes('mealDetails')) {
            patient.completed.push("mealDetails");
        }

        return patient;
    }

    onChangeMealType = (mealType, index) => {
        const { preferences } = this.state;
        const mealTypes = [...preferences.meal_types];
        mealTypes[index] = mealType;
        const updatedPreferences = {...preferences, meal_types: mealTypes};
        this.setState({preferences: updatedPreferences, dirty: true});
    }

    onChangeMealTypeName = (event, index) => {
        const { preferences } = this.state;
        const mealTypes = [...preferences.meal_types];
        const mealType = mealTypes[index];
        const updatedMealType = {...mealType, name: event.target.value};
        mealTypes[index] = updatedMealType;
        const updatedPreferences = {...preferences, meal_types: mealTypes};
        this.setState({preferences: updatedPreferences, dirty: true});
    }

    getSubLabel = (mealTypes) => {
        const counts = mealTypes.reduce((acc, mealType) => {
            acc[mealType.main_dish] = (acc[mealType.main_dish] || 0) + 1;
            return acc;
        }, {});
  
        const resultString = Object.entries(counts)
            .map(([main_dish, count]) => `${count} ${main_dish.charAt(0).toUpperCase() + main_dish.slice(1)}`)
            .join(' ');

        return resultString;
    }

    render() {
        const { selectedEatingPattern, availableEatingPatterns, loading, preferences, error } = this.state;
        const { patient } = this.props;

        if (!availableEatingPatterns || !preferences || loading) {
            return (
                <div className="meal-types">
                    <div className="meal-types-loader">
                        <i className="icon-spinner2" />
                    </div>
                </div>
            );
        }

        const mealTypes = preferences.meal_types;

        const eatingPatternOptions = availableEatingPatterns.map((pattern) => ({
            value: pattern.name,
            label: pattern.name?.length ? pattern.name : "Default",
            sub_label: this.getSubLabel(pattern.meal_types)
        }));

        return (
            <div className="meal-details patient-form">
                <div className='with-label eating-pattern'>
                    <label>Eating Pattern:</label>
                    <Select value={selectedEatingPattern} options={eatingPatternOptions} onChange={(value) => this.toggleEatingPattern(value)} /></div>
                {
                    mealTypes.map((mealType, index) => {
                        return (
                            <Expander
                                button={
                                    <span className="meal-type-expand">
                                        <input
                                            className="meal-type-name"
                                            ref={(input) => {
                                                this.mealType[mealType.name] = input;
                                            }}
                                            type="text"
                                            value={mealType.name}
                                            onChange={(event) => this.onChangeMealTypeName(event, index)}
                                        />
                                        <i className="icon-pencil2" onClick={() => this.mealType[mealType.name].focus()}/>
                                    </span>
                                }
                                isCollapsible={true}
                            >
                                <MealTypesInfo
                                    key={`${index}-${selectedEatingPattern}`}
                                    mealType={mealType}
                                    patient={patient}
                                    onChange={(mealType) =>
                                        this.onChangeMealType(mealType, index)
                                    }
                                    error={error}
                                />
                            </Expander>
                        );
                    })
                }
            </div>
        );
    }
}
